import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import CloseSm from '../../assets/images/icons/close-sm.svg';
import ScrollArea from "react-scrollbar";
import Button from 'react-bootstrap/esm/Button';
import DummyThumb from '../../assets/images/src-dummy.jpg'
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { useSelector,useDispatch,shallowEqual } from "react-redux";
import {shareSingleQuery,usersListQueryShared,UserListSaveQuery} from './../../modules/results/services'
import ProfileDummy from '../../assets/images/profile-dummy.png'
import {savedQueriesList} from './../../modules/profile/services'
import { _removeSingleQuery,_removeSingleSavedQuery,_removeAccessQuery } from '../httpservice/apis';
import { Formik } from "formik";

function StatusPopup(props) {
    const result = useSelector((state)=>state?.results?.userListShareQuery?.response?.data?.data?.element)

    const sharedListMessage = useSelector((state)=>state?.results?.userListQuerySharedTo?.response?.data?.data?.message)

    const type = typeof(sharedListMessage)
   
    const file = useSelector((state) => state?.results?.file);
    const file_path = useSelector((state)=>state?.results?.keyword_search?.response?.data?.data?.file_path)

    const loading = useSelector((state)=>state?.results?.userListQuerySharedTo?.loading)
    const [userList,setUserList] = useState()
  
    const searchTerm = useSelector((state)=>state?.results?.search)
    const dispatch = useDispatch()
    const token = useSelector((store)=>store?.auth?.signin?.response?.data?.data?.token,shallowEqual)
    const tokenSso = useSelector(
        (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token,shallowEqual
      );

    const errorShareQuery = useSelector((state)=>state?.results?.shareSingleQuery?.hasError)  
      const [clicked,setClicked] = useState(true)
      const [removeClicked,setRemoveClicked] = useState(true)
    useEffect(()=>{
        if(result && (type==="undefined"||type==="string")){       
            const userOptions = result.map((item) => ({
                value: item.id,
                label: item.first_name + " " + item.last_name,
              }));
              setUserList(userOptions)
        }else if(result && type==="object") {
            const id = sharedListMessage?.map((item)=>({id:item?.user}))

            const myArray = result.filter(ar => !id.find(rm => (rm.id === ar.id) ))

            const userFiltered = myArray.map((item)=>({
                value: item.id,
                label: item.first_name + " " + item.last_name,
            }))
            setUserList(userFiltered)
        }
        
    },[result,sharedListMessage,removeClicked,type])  
    const [delet,setDelete] = useState(false)
    const [select,setSelect] = useState([])  
    const userMap = select.map((item) => ({ value: item.value }));

    var userValues = userMap.map((i) => {
      return i.value;
    });

    const Option = (props) => {
        return (
          <div>
            <components.Option {...props}>
              <input
                className="react-multi-select-item"
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null}
              />{" "}
              <label>{props.label}</label>
            </components.Option>
          </div>
        );
      };
      const querySharedWith = ()=>{
        if(props?.qterm!==""&&props?.qfile===undefined){
            const data = {
                // "query" : {
                //     "category":props?.category,
                //     "qterm":props?.qterm
                // },
                "query":props?.query,
                "category" : props?.category
            }

            if(token!==undefined){
                dispatch(usersListQueryShared(data,token))
            }else if(tokenSso!==undefined){
                dispatch(usersListQueryShared(data,tokenSso))
            }
        }else if(props?.qfile!==""&&props?.qterm===undefined){
            const data = {
                // "query" : {
                //     "category":props?.category,
                //     "qfile":props?.qfile
                // },
                "query":props?.query,
                "category" : props?.category
            }
            if(token!==undefined){
                dispatch(usersListQueryShared(data,token))
            }else if(tokenSso!==undefined){
                dispatch(usersListQueryShared(data,tokenSso))
            }
        }
            
        
       
       }

       useEffect(()=>{
        querySharedWith()
       },[clicked,delet])



      const shareQuery =(values)=>{
        if(props?.qfile===undefined&&props?.qterm!==""){
            if(sharedListMessage==="Query not shared to anyone"||sharedListMessage==="Query not found for this user"){
                const data = {
                    "title":props.title,
                    // "query":{
                    //     "category":props.category,
                    //     "qterm":props?.qterm
                    // },
                    "query":props?.query,
                    "category" : props.category,
                    "users" : userValues
                }
                // dispatch(shareSingleQuery(data,token)).then(()=>{
                //     if(!errorShareQuery){
                //         setClicked(!clicked)
                //     }
                // })
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token)).then(()=>{
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }else if(tokenSso!==undefined){
                    dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }
            }
            else{
                    const data = {
                        "title":sharedListMessage[0]?.title,
                        "query":
                            sharedListMessage[0]?.get_parameters
                        ,
                        "category" : sharedListMessage[0]?.category,
                        "users" : userValues
                    }
                    // dispatch(shareSingleQuery(data,token)).then(()=>{
                    //     if(!errorShareQuery){
                    //         setClicked(!clicked)
                    //     }
                    // })
                    if(token!==undefined){
                        dispatch(shareSingleQuery(data,token)).then(()=>{
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        })
                    }else if(tokenSso!==undefined){
                        dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        })
                    }
                
                
            }
        }else if(props?.qterm===undefined&&props?.qfile!==""){
            if(sharedListMessage==="Query not shared to anyone"||sharedListMessage==="Query not found for this user"){
                const data = {
                    "title":props.title,
                    // "query":{
                    //     "category":props.category,
                    //     "qfile":props?.qfile
                    // },
                    "query":props?.query,
                    "category" : props.category,
                    "users" : userValues
                }
                // dispatch(shareSingleQuery(data,token)).then(()=>{
                //     if(!errorShareQuery){
                //         setClicked(!clicked)
                //     }
                // })
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token)).then(()=>{
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }else if(tokenSso!==undefined){
                    dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }
            }
            else{
                    const data = {
                        "title":sharedListMessage[0]?.title,
                        "query":
                            sharedListMessage[0]?.get_parameters
                        ,
                        "category" : sharedListMessage[0]?.category,
                        "users" : userValues
                    }
                    // dispatch(shareSingleQuery(data,token)).then(()=>{
                    //     if(!errorShareQuery){
                    //         setClicked(!clicked)
                    //     }
                    // })
                    if(token!==undefined){
                        dispatch(shareSingleQuery(data,token)).then(()=>{
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        })
                    }else if(tokenSso!==undefined){
                        dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        })
                    }
                
                
            }
        }
        
      }

      const savedQueries =()=>{
        if(token!==undefined){
        dispatch(savedQueriesList(token,""))
        }else if(tokenSso!==undefined){
            dispatch(savedQueriesList(tokenSso,"")) 
        }
    }

      const removeSingleQuery =(ids,user)=>{
        if(token!==undefined){
            return _removeSingleQuery(ids,token).then(()=>{
                setDelete(!delet)
            })
        }else if(tokenSso!==undefined){
            return _removeSingleQuery(ids,tokenSso).then(()=>{
                setDelete(!delet)
            })
        }
    }
    const removeUser =(sharedId)=>{
        if(token!==undefined){
            return _removeSingleSavedQuery(sharedId,token).then(()=>{
                setDelete(!delet)
            })
        }else if(tokenSso!==undefined){
            return _removeSingleSavedQuery(sharedId,tokenSso).then(()=>{
                setDelete(!delet)
            })
        }
    }

    const removeAccessUser =(id)=>{
        if(token!==undefined){
            return _removeAccessQuery(token,id).then(()=>{
                setDelete(!delet)
                dispatch(savedQueries())
                setRemoveClicked(!removeClicked)
            })
        }else if (tokenSso!==undefined){
            return _removeAccessQuery(tokenSso,id).then(()=>{
                setDelete(!delet)
                dispatch(savedQueries())
                setRemoveClicked(!removeClicked)
            })
        }
    }
      
    return (
        <div className="country-pop-items manage-access-popup">
            {loading ? <div> <div className="loader loader-3">
        <div className="dot dot1">&nbsp;</div>
        <div className="dot dot2">&nbsp;</div>
        <div className="dot dot3">&nbsp;</div>
    </div></div> :
            <div className="country-filter status-searchontop">
                <div className="selected-view-box-share">
                    {/* <h4 className="share-title">Share</h4> */}
                    <Formik
                     initialValues={{
                        title: "",
                      }}
                      onSubmit={shareQuery}
                    >
                         {({ handleSubmit, handleChange, values, errors, touched }) => (
                    <Form className="w-100" onSubmit={handleSubmit}>
                        <div className="country-header-search">
                            {sharedListMessage==="Query is not shared to anyone" || sharedListMessage==="Query not found for this user" ?
                        <div className="form-input-wrap m-b-30">
                                <Form.Label>Query name</Form.Label>
                                <Form.Control type="text" placeholder="Title....." className="input-item" value={values.title} name="title" onChange={handleChange} />
                            </div>
                            :null}
                            <div className="form-input-wrap m-b-30">
                                <Form.Label>Username</Form.Label>
                                {/* <Form.Control type="text" placeholder="Username" className="input-item" /> */}
                                <ReactSelect
                        options={userList}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                          Option,
                        }}
                        allowSelectAll={true}
                        classNamePrefix="react-select-2-option"
                        className="react-multi-select"
                        placeholder='Search and select'
                        selected={select}
                        onChange={setSelect}
                        // values={formik.values.sector}
                        name="userlist"
                        required
                      />

                            </div>
                           
                        </div>
                        <div className="button-wrap d-flex justify-content-end m-t-20">
                    <Button disabled={select?.length===0} type='submit'>Share</Button>
                </div>
                    </Form>
                     )}
                    </Formik>
                    {/* <ScrollArea
                        speed={0.8}
                        className="pop-selected-items-scroll"
                        contentClassName="content"
                        horizontal={false}
                    >
                        <div className="selected-item-show-wrap">
                            {[...Array(9)].map((index) => (
                                <div className="selected-item-show">
                                    <span className="selected-name">Paddy O'Furniture</span><span className="selected-close"><img src={CloseSm} alt="closearrow" /></span>
                                </div>
                            ))}
                        </div>
                    </ScrollArea> */}

                </div>
                {type!=="string"  ? <div className="access-box m-b-30">
                 
                        <div className="sharedwith-wrap">
                            <h4 className="share-title">Shared with </h4>
                            <ScrollArea
                        speed={0.8}
                        className="pop-checkboxscroll"
                        contentClassName="content"
                        horizontal={false}
                    >
                            <div className="shared-name-wrap d-flex">
                                {type!== "string" && sharedListMessage&& sharedListMessage.map((item,i) => (
                                    <div className="shared-name-box" key={i}>
                                        <div className="shared-name-box-item d-flex align-items-center justify-content-between">
                                            <div className="shared-access-left d-flex align-items-center">
                                                <div className="shared-img-wrap user-img">
                                                    <img src={item?.profile_pic_path!==null?item?.profile_pic_path:ProfileDummy} />
                                                </div>
                                                <div className="shared-man-name">
                                                    {item?.username}
                                                </div>
                                            </div>
                                            <div className="shared-access-right">
                                                <div className="shared-remove-btn" onClick={()=>{
                                                        removeAccessUser(item?.id)
                                                        setSelect([])
                                                        // removeSingleQuery(item?.id)
                                                        // removeUser(item?.id)
                                                    }} style={{cursor:"pointer"}}>
                                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M2.62155 12.875C2.33822 12.875 2.0903 12.7687 1.8778 12.5562C1.6653 12.3437 1.55905 12.0958 1.55905 11.8125V1.71875H0.833008V0.65625H4.16217V0.125H8.83717V0.65625H12.1663V1.71875H11.4403V11.8125C11.4403 12.0958 11.334 12.3437 11.1215 12.5562C10.909 12.7687 10.6611 12.875 10.3778 12.875H2.62155ZM10.3778 1.71875H2.62155V11.8125H10.3778V1.71875ZM4.49863 10.2896H5.56113V3.22396H4.49863V10.2896ZM7.43822 10.2896H8.50072V3.22396H7.43822V10.2896ZM2.62155 1.71875V11.8125V1.71875Z" fill="#5B5B5B" />
                                                    </svg>
                                                    <span >Remove</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                ))}
                            </div>
                            </ScrollArea>
                        </div>
                 
                </div>
 :null}
                
                
               
            </div> }
        </div>
    )
}

export default StatusPopup;