import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import SlideImg1 from '../../../../../assets/images/slider-icons/client-logo-1.png';
import SlideImg2 from '../../../../../assets/images/slider-icons/client-logo-2.png';
import SlideImg3 from '../../../../../assets/images/slider-icons/client-logo-3.png';
import SlideImg4 from '../../../../../assets/images/slider-icons/client-logo-4.png';
import SlideImg5 from '../../../../../assets/images/slider-icons/client-logo-5.png';

const options = {
    responsive: {
        0: {
            items: 1,
        },
        768: {
            items: 3,
        },
     
        1000: {
            items: 5,
        },
     
    },
};

function LandSection8() {
    return (
        <div className="section landing-section-8 client-slider-section">
            <Container className="text-center">
                <h3 className="landing-common-title">We know exactly where you want us to look.</h3>

                <OwlCarousel
                    className='owl-theme financing-slider'
                    items="5"
                    margin={0}
                    nav={true}
                    dots={false}
                    autoplay={true}
                    loop={true}
                    {...options}
                    navClass={['land-owl-prev', 'land-owl-next']}
                >
                    <div className="slider-item">
                        <div className="client-logo-wrap">
                            <img src={SlideImg1} alt="slider-logo" /></div>
                    </div>
                    <div className="slider-item">
                        <div className="client-logo-wrap">
                            <img src={SlideImg2} alt="slider-logo" /></div>
                    </div>
                    <div className="slider-item">
                        <div className="client-logo-wrap">
                            <img src={SlideImg3} alt="slider-logo" /></div>
                    </div>
                    <div className="slider-item">
                        <div className="client-logo-wrap">
                            <img src={SlideImg4} alt="slider-logo" /></div>
                    </div>
                   
                    <div className="slider-item">
                        <div className="client-logo-wrap">
                            <img src={SlideImg5} alt="slider-logo" /></div>
                    </div>
                    <div className="slider-item">
                        <div className="client-logo-wrap">
                            <div className='client-logo-text'>and many more...</div>
                            </div>
                    </div>
                    {/* <div className="slider-item">
                        <div className="client-logo-wrap">
                            <img src={SlideImg1} alt="slider-logo" /></div>
                    </div> */}


                </OwlCarousel>
            </Container>
        </div>
    )
}

export default LandSection8;