import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import CareersImg1 from '../../../../../assets/images/icons/careers-icon-1.svg';
import CareersImg2 from '../../../../../assets/images/icons/careers-icon-2.svg';
import CareersImg3 from '../../../../../assets/images/icons/careers-icon-3.svg';
import CareersImg4 from '../../../../../assets/images/icons/careers-icon-4.svg';
import CareerDescImg from '../../../../../assets/images/career-desc-img.svg';

function CareersContent() {
    return (
        <div className="careers-content-section-wrap">

            <div className="career-content-sec career-content-sec-1">
                <Container>
                    <div className="career-main-content text-center">
                        <img src={CareerDescImg} alt=""/>
                        <h6>Presently, <b>DevDiscovery</b> does not have any open positions, however please send your latest resume to&nbsp;
                            <a href="mailto: support@devdiscovery.com" target="_blank"> support@devdiscovery.com</a> and we will reach out to you whenever we have any relevant opportunities. Thank you for
                            your interest!</h6>
                    </div>
                </Container>
            </div>

            <div className="career-content-sec career-content-sec-2">
                <Container className="text-center">
                    <h3 className="landing-common-title">Working at DevDiscovery</h3>
                    <p className="landing-common-description">Come join our team of development professionals, researchers, AI/ML experts, software engineers, and others who are working to
                        empower our clients to deliver more. </p>
                    <Row className="technology-show-row text-left">
                        <Col md={6} className="careers-show-box-left">
                            <div className="technology-show-box d-flex align-items-end">
                                <div className="technology-cont-icon">
                                    <img src={CareersImg1} alt="technology-1" />
                                </div>
                                <div className="technology-cont-text">
                                    <h4>Research</h4>
                                    <p>Aiming to help development professionals, as a researcher at DevDiscovery you will work on socio-economic research, addressing a variety of development issues and challenges.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} className="careers-show-box-right">
                            <div className="technology-show-box d-flex align-items-end">
                                <div className="technology-cont-icon">
                                    <img src={CareersImg2} alt="technology-1" />
                                </div>
                                <div className="technology-cont-text">
                                    <h4>Data Science</h4>
                                    <p>AI/ML is critical to every aspect of our platform. As a data scientist at DevDiscovery, you will apply the latest in AI/ML to real world problems involving both, structured and unstructured data.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} className="careers-show-box-left">
                            <div className="technology-show-box d-flex align-items-end">
                                <div className="technology-cont-icon">
                                    <img src={CareersImg3} alt="technology-1" />
                                </div>
                                <div className="technology-cont-text">
                                    <h4>Software development</h4>
                                    <p>Our platform uses a very contemporary tech-stack. Our
                                        team of software developers consists of experts in a
                                        variety of frontend and backend technologies.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} className="careers-show-box-right">
                            <div className="technology-show-box d-flex align-items-end">
                                <div className="technology-cont-icon">
                                    <img src={CareersImg4} alt="technology-1" />
                                </div>
                                <div className="technology-cont-text">
                                    <h4>Other</h4>
                                    <p>We also need those amazing people who can keep others on
                                        track and remind them of the ground realities of running a
                                        business - finance, accounting, and administration.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default CareersContent;