import axios from "axios";

/*global BASE_URL */
// /*eslint no-undef: "error"*/
const API = axios.create({
  baseURL: BASE_URL,
  timeout: 75000,
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
  },
  mode: "no-cors",
});

export default API;
