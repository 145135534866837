import { createElement } from 'react';
import {createAction,handleActions} from 'redux-actions';
import ActionTypes from '~shared/constants/actionTypes';

// import {store} from  '../../../redux/store';

const initialState = {
    data_org_filter : [],
    data_org_data : [],
    data_org_handler : [],
    data_country_filter : [],
    data_country_data : [],
    data_country_handler : [],
    data_region_filter : [],
    data_region_data : [],
    data_region_handler : [],
    data_sector_filter : [],
    data_sector_data : [],
    data_sector_handler : [],
    data_topic_filter : [],
    data_topic_data : [],
    data_topic_handler : [],
    data_resource_filter : [],
    data_resource_data : [],
    data_resource_handler : [],
    data_home_flag : true,
    data_search_country : [],
    date_var_data : true
    
    
  }

//Econ 
const setDataOrgArray = createAction(ActionTypes.SET_DATA_ORG_FILTER, (data) => data);

const setDataCountryArray = createAction(ActionTypes.SET_DATA_COUNTRY_FILTER)

export const setDataCountryFilter = (data) => (dispatch) => {
  dispatch(setDataCountryArray(data))
}

export const setDataOrgFilter = (data) => (dispatch) => {
  dispatch(setDataOrgArray(data));
};

const setDataCountry = createAction(ActionTypes.SET_DATA_COUNTRY_DATA)

export const setDataCountryData = (data) => (dispatch) => {
  dispatch(setDataCountry(data))
}

const setDataCountryHandlerArray = createAction(ActionTypes.SET_DATA_COUNTRY_HANDLER)

export const setDataCountryHandler = (data) => (dispatch) =>{
  dispatch(setDataCountryHandlerArray(data))
}

const setDataOrg = createAction(ActionTypes.SET_DATA_ORG_DATA)

export const setDataOrgData = (data) => (dispatch) => {
  dispatch(setDataOrg(data))
}

const setDataOrgHandlerArray = createAction(ActionTypes.SET_DATA_ORG_HANDLER)

export const setDataOrgHandler = (data) => (dispatch) => {
  dispatch(setDataOrgHandlerArray(data))
}

const setDataRegionArray =  createAction(ActionTypes.SET_DATA_REGION_FILTER)

export const setDataRegionFilter = (data) =>(dispatch) =>{
  dispatch(setDataRegionArray(data))
}

const setDataRegionAction = createAction(ActionTypes.SET_DATA_REGION_DATA)

export const setDataRegionData = (data) => (dispatch) =>{
  dispatch(setDataRegionAction(data))
}

const setDataRegionHandlerAction = createAction(ActionTypes.SET_DATA_REGION_HANDLER)

export const setDataRegionHandler = (data) =>(dispatch)=>{
  dispatch(setDataRegionHandlerAction(data))
}

const setDataSectorArray =  createAction(ActionTypes.SET_DATA_SECTOR_FILTER)

export const setDataSectorFilter = (data) =>(dispatch) =>{
  dispatch(setDataSectorArray(data))
}

const setDataSectorAction = createAction(ActionTypes.SET_DATA_SECTOR_DATA)

export const setDataSectorData = (data) => (dispatch) =>{
  dispatch(setDataSectorAction(data))
}

const setDataSectorHandlerAction = createAction(ActionTypes.SET_DATA_SECTOR_HANDLER)

export const setDataSectorHandler = (data) =>(dispatch)=>{
  dispatch(setDataSectorHandlerAction(data))
}

const setDataTopicArray =  createAction(ActionTypes.SET_DATA_TOPIC_FILTER)

export const setDataTopicFilter = (data) =>(dispatch) =>{
  dispatch(setDataTopicArray(data))
}

const setDataTopicAction = createAction(ActionTypes.SET_DATA_TOPIC_DATA)

export const setDataTopicData = (data) => (dispatch) =>{
  dispatch(setDataTopicAction(data))
}

const setDataTopicHandlerAction = createAction(ActionTypes.SET_DATA_TOPIC_HANDLER)

export const setDataTopicHandler = (data) =>(dispatch)=>{
  dispatch(setDataTopicHandlerAction(data))
}

const setDataResourceArray =  createAction(ActionTypes.SET_DATA_RESOURCE_FILTER)

export const setDataResourceFilter = (data) =>(dispatch) =>{
  dispatch(setDataResourceArray(data))
}

const setDataResourceAction = createAction(ActionTypes.SET_DATA_RESOURCE_DATA)

export const setDataResourceData = (data) => (dispatch) =>{
  dispatch(setDataResourceAction(data))
}

const setDataResourceHandlerAction = createAction(ActionTypes.SET_DATA_RESOURCE_HANDLER)

export const setDataResourceHandler = (data) =>(dispatch)=>{
  dispatch(setDataResourceHandlerAction(data))
}

const setDataHomeFlagAction = createAction(ActionTypes.DATA_HOME_FLAG)

export const setDataHomeFlagFunction = (data) =>(dispatch)=>{
  dispatch(setDataHomeFlagAction(data))
}

const setDataSearchCountryAction = createAction(ActionTypes.DATA_SEARCH_COUNTRY)

export const setDataSearchCountry = (data) =>(dispatch)=>{
  dispatch(setDataSearchCountryAction(data))
}

const setDateTriggerActionData = createAction(ActionTypes.SET_DATE_TRIGGER_DATA)
export const setDateTriggerData = (data)=>(dispatch)=>{
  dispatch(setDateTriggerActionData(data))
}


const reducer = handleActions({
   
[ActionTypes.SET_DATA_ORG_FILTER]: (state, action) => ({
        ...state,
        data_org_filter: action.payload,
      }),
[ActionTypes.SET_DATA_COUNTRY_FILTER]: (state, action) => ({
        ...state,
        data_country_filter : action.payload,
      }),
[ActionTypes.SET_DATA_COUNTRY_DATA]: (state, action) => ({
        ...state,
        data_country_data : action.payload,
      }),
  [ActionTypes.SET_DATA_COUNTRY_HANDLER]: (state, action) => ({
        ...state,
        data_country_handler : action.payload,
      }),   
  [ActionTypes.SET_DATA_ORG_DATA]: (state, action) => ({
        ...state,
        data_org_data : action.payload,
      }),            
  [ActionTypes.SET_DATA_ORG_HANDLER]: (state, action) => ({
        ...state,
        data_org_handler : action.payload,
      }),
  [ActionTypes.SET_DATA_REGION_FILTER]: (state, action) => ({
        ...state,
        data_region_filter : action.payload,
      }),  
  [ActionTypes.SET_DATA_REGION_DATA]: (state, action) => ({
        ...state,
        data_region_data : action.payload,
      }),                     
  [ActionTypes.SET_DATA_REGION_HANDLER]: (state, action) => ({
        ...state,
        data_region_handler : action.payload,
      }),
  [ActionTypes.SET_DATA_SECTOR_FILTER]: (state, action) => ({
        ...state,
        data_sector_filter : action.payload,
      }),  
  [ActionTypes.SET_DATA_SECTOR_DATA]: (state, action) => ({
        ...state,
        data_sector_data : action.payload,
      }),                     
  [ActionTypes.SET_DATA_SECTOR_HANDLER]: (state, action) => ({
        ...state,
        data_sector_handler : action.payload,
      }), 
  [ActionTypes.SET_DATA_TOPIC_FILTER]: (state, action) => ({
        ...state,
        data_topic_filter : action.payload,
      }),  
  [ActionTypes.SET_DATA_TOPIC_DATA]: (state, action) => ({
        ...state,
        data_topic_data : action.payload,
      }),                     
  [ActionTypes.SET_DATA_TOPIC_HANDLER]: (state, action) => ({
        ...state,
        data_topic_handler : action.payload,
      }),
      [ActionTypes.SET_DATA_RESOURCE_FILTER]: (state, action) => ({
        ...state,
        data_resource_filter : action.payload,
      }),  
  [ActionTypes.SET_DATA_RESOURCE_DATA]: (state, action) => ({
        ...state,
        data_resource_data : action.payload,
      }),                     
  [ActionTypes.SET_DATA_RESOURCE_HANDLER]: (state, action) => ({
        ...state,
        data_resource_handler : action.payload,
      }), 
  [ActionTypes.DATA_HOME_FLAG]: (state, action) => ({
        ...state,
        data_home_flag : action.payload,
      }), 
  [ActionTypes.DATA_SEARCH_COUNTRY]: (state, action) => ({
        ...state,
        data_search_country : action.payload,
      }),     
  [ActionTypes.SET_DATE_TRIGGER_DATA]: (state, action) => ({
        ...state,
        date_var_data : action.payload,
      }),                                             
},initialState);

export default reducer;
