import React from "react";

function CloseIcon() {
  return (
    <span className="icon-wrap">
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
        <g clip-path="url(#clip0_3_13638)">
          <path d="M26.8 24L37.4 13.5C37.7465 13.1459 37.9566 12.6807 37.9932 12.1866C38.0298 11.6925 37.8905 11.2014 37.6 10.8C37.4382 10.559 37.2232 10.3583 36.9717 10.2133C36.7202 10.0683 36.4388 9.98287 36.1491 9.96356C35.8595 9.94425 35.5692 9.99157 35.3007 10.1019C35.0321 10.2122 34.7924 10.3826 34.6 10.6L24 21.2L13.4 10.6C13.2076 10.3826 12.9679 10.2122 12.6994 10.1019C12.4309 9.99157 12.1406 9.94425 11.8509 9.96356C11.5613 9.98287 11.2799 10.0683 11.0283 10.2133C10.7768 10.3583 10.5619 10.559 10.4 10.8C10.1095 11.2014 9.97024 11.6925 10.0068 12.1866C10.0434 12.6807 10.2536 13.1459 10.6 13.5L21.2 24L10.6 34.5C10.2536 34.8542 10.0434 35.3194 10.0068 35.8135C9.97024 36.3076 10.1095 36.7987 10.4 37.2C10.5619 37.441 10.7768 37.6417 11.0283 37.7867C11.2799 37.9318 11.5613 38.0172 11.8509 38.0365C12.1406 38.0558 12.4309 38.0085 12.6994 37.8982C12.9679 37.7878 13.2076 37.6174 13.4 37.4L24 26.8L34.6 37.4C34.7924 37.6174 35.0321 37.7878 35.3007 37.8982C35.5692 38.0085 35.8595 38.0558 36.1491 38.0365C36.4388 38.0172 36.7202 37.9318 36.9717 37.7867C37.2232 37.6417 37.4382 37.441 37.6 37.2C37.8905 36.7987 38.0298 36.3076 37.9932 35.8135C37.9566 35.3194 37.7465 34.8542 37.4 34.5L26.8 24Z" fill="black" />
        </g>
        <defs>
          <clipPath id="clip0_3_13638">
            <rect width="48" height="48" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
}

export default CloseIcon;
