
import React, { useState, useEffect } from 'react';
import Pagination from 'react-bootstrap/Pagination';

const CustomPagination = ({total,current,onChangePage,onChangeOffset}) => {
    console.log(current,"current");
    // let active = 1;
    let items = [];
    if(current>1){
        items.push(<Pagination.First key="prev" onClick={()=>onChangePage(current-1)}/>)
    }
    for (let page = 1; page <= total; page++) {
        items.push(
            <Pagination.Item key={page} active={page === current} onClick={()=>onChangePage(page)}>
                {page}
            </Pagination.Item>,
        );
    }
    if(current<total){
        items.push(<Pagination.Last key="next" onClick={()=>{onChangePage(current+1)}}/>)
    }
    useEffect(()=>{
       onChangeOffset((current-1)*3)
    },[current])
    return (
        <>
            <div className="d-flex justify-content-center">
                <Pagination size="sm" className="custom-pagination">
                    {/* <Pagination.First key="prev" onClick={()=>onChangePage(current-1)}/> */}
                    {items}
                    {/* <Pagination.Last key="next" onClick={()=>{onChangePage(current+1)}}/> */}

                </Pagination>
            </div>

        </>



    )
}
export default CustomPagination
