import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const options = {
    responsive: {
        0: {
            items: 1,
        },
        768: {
            items: 2,
        },
     
        1000: {
            items: 3,
        },
     
    },
};
function LandSection7() {
    return (
        <div className="section landing-section-7">
            <Container className="text-center">
                <h3 className="landing-common-title">Experience the power of AI custom built for you.</h3>
                <p className="landing-common-description">We’ve developed algorithms trained on hundreds of thousands of project documents and research publications, to organize our content better, so
that you can find exactly what you need. </p>
<div className="land-experience-slider-wrapper">

<OwlCarousel
                className='owl-theme land-experience-slider'
                items="3"
                margin={10}
                nav={true}
                dots={false}
                autoplay={true}
                loop={true}
                {...options}
                navClass= {['land-owl-prev','land-owl-next']}
            >
                <div className="slider-item">
                  <div className="experience-slider-box">
                      <h4>Topic Identification</h4>
                      <p>Leverages the World Bank Group's topical taxonomy to identify topics for each document in our repositories.</p>
                  </div>
                </div>
                <div className="slider-item">
                <div className="experience-slider-box">
                      <h4>Text Classification</h4>
                      <p>Deep learning models trained on tens of thousands of documents to classify and organize content for better search accuracy.</p>
                  </div>
                </div>
                <div className="slider-item">
                <div className="experience-slider-box">
                      <h4>Query Interpretation</h4>
                      <p>Custom processing of user query based on the nature of the query-keyword, paragraph, or document.</p>
                  </div>
                </div>
                <div className="slider-item">
                <div className="experience-slider-box">
                      <h4>Document Enrichment</h4>
                      <p>Identify entities and geographical references in documents to standardize document metadata across sources.</p>
                  </div>
                </div>
                <div className="slider-item">
                <div className="experience-slider-box">
                      <h4>Personalization</h4>
                      <p>Leverages user's profile and search history to recommend relevant content from across our knowledge categories.</p>
                  </div>
                </div>

            </OwlCarousel>  
            </div>
            </Container>
        </div>
    )
}

export default LandSection7;