import React, { useState, useEffect,useRef } from "react";
import ProfileDummy from "./../../../../assets/images/profile-dummy.png";
import { Modal } from "react-bootstrap";
import background from "../../../../assets/images/profile-pic-bg.png";
import Cropcontent from "./components/cropcontent";
import {useSelector,useDispatch,shallowEqual} from 'react-redux'
import {setProfileImage} from './../../../results/services'

function ProfilePicEdit() {
    const dispatch = useDispatch()
    const state = useSelector((state)=>state?.results)
    const profileImage =  useSelector((state)=>state?.profile?.userInfo?.response?.data?.data?.profile_pic_path,shallowEqual)
    const [show, setShow] = useState(false);
    const [imageFile,setImageFile] = useState()
    const [files,setFiles] = useState()
    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () => {
        setShow(true);
    };

    const [images, setImages] = useState(profileImage);
    useEffect(() => {
        if (imageFile !== undefined) {
          const file = new File([imageFile], "filename.png", {
            type: imageFile.type,
            lastModified: new Date().getTime(),
          });
          setFiles(file);
        }
      }, [imageFile]);

      useEffect(()=>{
        if(imageFile!==undefined){
            dispatch(setProfileImage(files))
        }
      },[imageFile,files])
      const deleteProfileImage =()=>{
        setImages(null)
        dispatch(setProfileImage("delete"))
      }
      const [image,setImage] = useState()
    //   console.log(image,"image");
      const onChange = (e) => {
        setImage(null)
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
          files = e.dataTransfer.files;
        } else if (e.target) {
          files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
          setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
      };
      useEffect(()=>{
        if(image&&image!==undefined){
          setShow(true)
        }
      },[image])
      const pickerRef = useRef(null);
      const selectImage = () => {
        if (pickerRef.current) pickerRef.current.click();
      };

    return (
        <div className="profile-inner-box">
            <div className="profile-top-bg" style={{ backgroundImage: `url(${background})` }}>
                &nbsp;
            </div>
            <div className="profile-stat-contentbox text-center">
                <div className="profile-page-img croped-img-wrap">
                    <img src={images===null? ProfileDummy : images} />
                    <div>
                      
                        <input
                            className="btn btn-outline-primary upload-btn m-t-20 d-none"
                            type="file"
                            id="imageUpload"
                            accept="image/*"
                            onChange={onChange}
                            ref={pickerRef}
                            onClick={(event) => {
                                event.target.value = null;
                              }}
                        />
                            
                            <label  htmlFor="imageUpload" className="btn btn-outline-primary upload-btn m-t-20">
                            Upload Photo
                            </label>
                       
                      
                       
                    </div>
                    {images!==null&&
                    <button className="btn btn-outline-primary upload-btn m-t-20" onClick={()=>{
                        deleteProfileImage()
                    }}>
                        Delete Photo
                    </button>
                    }
                    
                </div>
            </div>
            <Modal
                show={show}
                onHide={handleClose}
                className="cropModal common-modal"
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Cropcontent
                        onHide={handleClose}
                        setImages={setImages}
                        setShow={setShow}
                      setImageFile={setImageFile}
                      proImage = {image}
                    />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ProfilePicEdit;
