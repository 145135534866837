import React, { useState,useEffect } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Button from 'react-bootstrap/Button';
import defaultImg from '../../../../../../../assets/images/profile-dummy.png';
// import { propTypes } from "react-bootstrap/esm/Image";
import PropTypes from "prop-types";


export const Cropcontent = ({setImages,setShow,setImageFile,proImage}) => {
  const [image, setImage] = useState();
  const [cropData, setCropData] = useState(defaultImg);
  const [cropper, setCropper] = useState();
  
  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = async () => {
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());
    }
  };
  function b64toBlob(dataURI) {
    
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
}


  const saveImage =()=>{
    setImages(cropData)
    setShow(false)
    var blob = b64toBlob(cropData);
    setImageFile(blob)
  }

  useEffect(()=>{
    if(cropData!==defaultImg){
      setImages(cropData);
    setShow(false);
    var blob = b64toBlob(cropData);   
    setImageFile(blob);
    }
  },[cropData])
 

  return (
    <div className="text-center">
      <div style={{ width: "100%" }}>
        {/* <input type="file" accept="image/*" className="d-none" id="imageUpload" onChange={onChange} />
        <label htmlFor="imageUpload" className="btn btn-primary">Upload Photo</label> */}
        <Cropper
          className="image-crop-area m-t-20"
          zoomTo={0}
          initialAspectRatio={1}
          preview=".img-preview"
          src={proImage}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={true} // https://github.com/fengyuanchen/cropperjs/issues/671
          onInitialized={(instance) => {
            setCropper(instance);
          }}
          guides={true}
        />
      </div>
      <div>
        <div className="box" >
          {/* <Button onClick={getCropData}>
            Crop Image
          </Button> */}
          {/* <div
            className="img-preview"
            style={{ width: "100%", float: "left", height: "300px" }}
          /> */}
          {/* <div className="croped-img-wrap"><img style={{ width: "100%" }} src={cropData} alt="cropped" /></div> */}
          <Button onClick={getCropData}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};
Cropcontent.propTypes = {
 setImages:PropTypes.any,
 setShow:PropTypes.any
};
export default Cropcontent;

