import {createAction,handleActions} from 'redux-actions';
import createTransform from 'redux-persist/es/createTransform';
import ActionTypes from '~shared/constants/actionTypes';
import {_userProfile,_userInfo,_listSavedQuery,_shareMultipleQuery,
    _removeMultipleQuery,_listReceivedQuery,_reshareQuery,_listBookmarks,_shareMultipleRecords,_listReceivedBookmarks
    ,_reshareBookmark, _notificationList,_markReadSingleNotification,_userTierInfo,_userTierInfoDoc
 } from '../../../shared/httpservice/apis';


 const initialState = {
   
    userProfile : { loading: false, response: {}, hasError: false, error: {} },
    userInfo : { loading: false, response: {}, hasError: false, error: {} },
    savedQueryList : { loading: false, response: {}, hasError: false, error: {} },
    shareMultipleQuery : { loading: false, response: {}, hasError: false, error: {} },
    removeBulkQueries : { loading: false, response: {}, hasError: false, error: {} },
    listReceivedQuery : { loading: false, response: {}, hasError: false, error: {} },
    reshareQuery : { loading: false, response: {}, hasError: false, error: {} },
    listBookmarks : { loading: false, response: {}, hasError: false, error: {} },
    shareMulipleRecords : { loading: false, response: {}, hasError: false, error: {} },
    listReceivedBookmarks : { loading: false, response: {}, hasError: false, error: {} },
    reshareBookmarks : { loading: false, response: {}, hasError: false, error: {} },
    list_notification : { loading: false, response: {}, hasError: false, error: {} },
    single_notification_read : { loading: false, response: {}, hasError: false, error: {} },
    user_tier_info : { loading: false, response: {}, hasError: false, error: {} },
    user_tier_info_doc : { loading: false, response: {}, hasError: false, error: {} },
    
  }


export const userProfileStart = createAction(ActionTypes.USER_PROFILE);
export const userProfileSuccess = createAction(ActionTypes.USER_PROFILE_SUCCESS);
export const userProfileError = createAction(ActionTypes.USER_PROFILE_ERROR);

export const userProfileInfo = (token)=>async(dispatch)=>{
    dispatch(userProfileStart())
    return _userProfile(token).then((response)=>{
        dispatch(userProfileSuccess(response))
    }).catch((error)=>{
        dispatch(userProfileError(error))
    })
}

export const userInfoStart = createAction(ActionTypes.USER_INFO);
export const userInfoSuccess = createAction(ActionTypes.USER_INFO_SUCCESS);
export const userInfoError = createAction(ActionTypes.USER_INFO_ERROR);

export const usersInfo = (token) =>async(dispatch)=>{
    dispatch(userInfoStart())
    return _userInfo(token).then((response)=>{
        dispatch(userInfoSuccess(response))
    }).catch((error)=>{
        dispatch(userInfoError(error))
    })
}

export const listSavedQueryStart = createAction(ActionTypes.LIST_SAVED_QUERY);
export const listSavedQuerySuccess = createAction(ActionTypes.LIST_SAVED_QUERY_SUCCESS);
export const listSavedQueryError = createAction(ActionTypes.LIST_SAVED_QUERY_ERROR);

export const savedQueriesList = (token,title,page) =>async(dispatch)=>{
    dispatch(listSavedQueryStart())
    return _listSavedQuery(token,title,page).then((response)=>{
        dispatch(listSavedQuerySuccess(response))
    }).catch((error)=>{
        dispatch(listSavedQueryError(error))
    })
}

export const shareMultipleQueryStart = createAction(ActionTypes.SHARE_MULTIPLE_QUERY);
export const shareMultipleQuerySuccess = createAction(ActionTypes.SHARE_MULTIPLE_QUERY_SUCCESS)
export const shareMultipleQueryError = createAction(ActionTypes.SHARE_MULTIPLE_QUERY_ERROR)

export const shareMultipleQuery = (data,token) =>async(dispatch)=>{
    dispatch(shareMultipleQueryStart())
    return _shareMultipleQuery(data,token).then((response)=>{
        dispatch(shareMultipleQuerySuccess(response))
    }).catch((error)=>{
        dispatch(shareMultipleQueryError(error))
    })
}

export const removeMultipleQueryStart = createAction(ActionTypes.REMOVE_MULTIPLE_QUERY)
export const removeMultipleQuerySuccess = createAction(ActionTypes.REMOVE_MULTIPLE_QUERY_SUCCESS)
export const removeMultipleQueryError = createAction(ActionTypes.REMOVE_MULTIPLE_QUERY_ERROR)

export const removeMultipleQuery = (data,token)=>async(dispatch)=>{
    dispatch(removeMultipleQueryStart())
    return _removeMultipleQuery(data,token).then((response)=>{
        dispatch(removeMultipleQuerySuccess(response))
    }).catch((error)=>{
        dispatch(removeMultipleQueryError(error))
    })
}

export const listReceivedQueryStart = createAction(ActionTypes.LIST_RECEIVED_QUERY)
export const listReceivedQuerySuccess = createAction(ActionTypes.LIST_RECEIVED_QUERY_SUCCESS)
export const listReceivedQueryError = createAction(ActionTypes.LIST_RECEIVED_QUERY_ERROR)

export const receivedQueries = (token,title,page)=>async(dispatch)=>{
    dispatch(listReceivedQueryStart())
    return _listReceivedQuery(token,title,page).then((response)=>{
        dispatch(listReceivedQuerySuccess(response))
    }).catch((error)=>{
        dispatch(listReceivedQueryError(error))
    })
}

export const reshareQueryStart = createAction(ActionTypes.RESHARE_QUERY)
export const reshareQuerySuccess = createAction(ActionTypes.RESHARE_QUERY_SUCCESS)
export const reshareQueryError = createAction(ActionTypes.RESHARE_QUERY_ERROR)

export const reshareQueries = (data,flag,token)=>async(dispatch)=>{
    dispatch(reshareQueryStart())
    return _reshareQuery(data,flag,token).then((response)=>{
        dispatch(reshareQuerySuccess(response))
    }).catch((error)=>{
        dispatch(reshareQueryError(error))
    })
}

export const listBookmarkStart =createAction(ActionTypes.LIST_BOOKMARK)
export const listBookmarkSuccess = createAction(ActionTypes.LIST_BOOKMARK_SUCCESS)
export const listBookmarkError = createAction(ActionTypes.LIST_BOOKMARK_ERROR)

export const listBookmarkedRecords = (token,title,page)=>async(dispatch)=>{
    dispatch(listBookmarkStart())
    return _listBookmarks(token,title,page).then((response)=>{
        dispatch(listBookmarkSuccess(response))
    }).catch((error)=>{
        dispatch(listBookmarkError(error))
    })
}

export const shareMultipleRecordStart = createAction(ActionTypes.SHARE_MULTIPLE_RECORDS)
export const shareMulipleRecordSuccess = createAction(ActionTypes.SHARE_MULTIPLE_RECORDS_SUCCESS)
export const shareMulipleRecordError = createAction(ActionTypes.SHARE_MULTIPLE_RECORDS_ERROR)

export const shareMulipleRecords = (data,token) =>async(dispatch)=>{
    dispatch(shareMultipleRecordStart())
    return _shareMultipleRecords(data,token).then((response)=>{
        dispatch(shareMulipleRecordSuccess(response))
    }).catch((error)=>{
        dispatch(shareMulipleRecordError(error))
    })
}

export const listReceivedBookmarkStart = createAction(ActionTypes.LIST_RECEIVED_BOOKMARKS)
export const listReceivedBookmarkSuccess = createAction(ActionTypes.LIST_RECEIVED_BOOKMARKS_SUCCESS)
export const listReceivedBookmarkError = createAction(ActionTypes.LIST_RECEIVED_BOOKMARKS_ERROR)

export const receivedBookmark = (token,title,page)=>async(dispatch)=>{
    dispatch(listReceivedBookmarkStart())
    return _listReceivedBookmarks(token,title,page).then((response)=>{
        dispatch(listReceivedBookmarkSuccess(response))
    }).catch((error)=>{
        dispatch(listReceivedBookmarkError(error))
    })
}

export const reshareBookmarkStart = createAction(ActionTypes.RESHARE_BOOKMARKS)
export const reshareBookmarkSuccess = createAction(ActionTypes.RESHARE_BOOKMARKS_SUCCESS)
export const reshareBookmarkError = createAction(ActionTypes.RESHARE_BOOKMARKS_ERROR)

export const reshareBookmarks = (data,token)=>async(dispatch)=>{
    dispatch(reshareBookmarkStart())
    return _reshareBookmark(data,token).then((response)=>{
        dispatch(reshareBookmarkSuccess(response))
    }).catch((error)=>{
        dispatch(reshareBookmarkError(error))
    })
}

export const listNotificationStart = createAction(ActionTypes.NOTIFICATION_LIST)
export const listNotificationSuccess = createAction(ActionTypes.NOTIFICATION_LIST_SUCCESS)
export const listNotificationError = createAction(ActionTypes.NOTIFICATION_LIST_ERROR)

export const listNotification = (token)=>async(dispatch)=>{
    dispatch(listNotificationStart())
    return _notificationList(token).then((response)=>{
        dispatch(listNotificationSuccess(response))
    }).catch((error)=>{
        dispatch(listNotificationError(error))
    })
}

export const readSingleNotificationStart = createAction(ActionTypes.NOTIFICATION_SINGLE_READ)
export const readSingleNotificationSuccess = createAction(ActionTypes.NOTIFICATION_SINGLE_READ_SUCCESS)
export const readSingleNotificationError = createAction(ActionTypes.NOTIFICATION_SINGLE_READ_ERROR)

export const readSingleNotification = (token,id,data)=>async(dispatch)=>{
    dispatch(readSingleNotificationStart())
    return _markReadSingleNotification(token,id,data).then((response)=>{
        dispatch(readSingleNotificationSuccess(response))
    }).catch((error)=>{
        dispatch(readSingleNotificationError(error))
    })
}

export const userTierInfoStart = createAction(ActionTypes.USER_TIER_INFO_START)
export const userTierInfoSuccess = createAction(ActionTypes.USER_TIER_INFO_SUCCESS)
export const userTierInfoError = createAction(ActionTypes.USER_TIER_INFO_ERROR)

export const userTierInfo = (token) => async (dispatch) =>{
    dispatch(userTierInfoStart())
    return _userTierInfo(token).then((response)=>{
        dispatch(userTierInfoSuccess(response))
    }).catch((error)=>{
        dispatch(userTierInfoError(error))
    })
}

export const userTierInfoDocStart = createAction(ActionTypes.USER_TIER_INFO_DOC_START)
export const userTierInfoDocSuccess = createAction(ActionTypes.USER_TIER_INFO_DOC_SUCCESS)
export const userTierInfoDocError = createAction(ActionTypes.USER_TIER_INFO_DOC_ERROR)

export const userTierInfoDoc = (token,data) => async (dispatch) =>{
    dispatch(userTierInfoDocStart())
    return _userTierInfoDoc(token,data).then((response)=>{
        dispatch(userTierInfoDocSuccess(response))
    }).catch((error)=>{
        dispatch(userTierInfoDocError(error))
    })
}



const reducer = handleActions({
    
    [ActionTypes.USER_PROFILE]: (state) => ({
        ...state,
        userProfile: {
            ...state. userProfile,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.USER_PROFILE_SUCCESS]: (state, action) => ({
        ...state,
        userProfile: {
            ...state.  userProfile,
            response: action.payload, loading: false, hasError: false, error: {}
        },
  
    }),
    [ActionTypes.USER_PROFILE_ERROR]: (state, action) => ({
        ...state,
        userProfile: {
            ...state.  userProfile,
            response: {}, loading: false, hasError: true, error: action.payload
        },
  
    }),
    [ActionTypes.USER_INFO]: (state) => ({
        ...state,
        userInfo: {
            ...state. userInfo,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.USER_INFO_SUCCESS]: (state, action) => ({
        ...state,
        userInfo: {
            ...state.  userInfo,
            response: action.payload, loading: false, hasError: false, error: {}
        },
  
    }),
    [ActionTypes.USER_INFO_ERROR]: (state, action) => ({
        ...state,
        userInfo: {
            ...state.  userInfo,
            response: {}, loading: false, hasError: true, error: action.payload
        },
  
    }),
    [ActionTypes.LIST_SAVED_QUERY]: (state) => ({
        ...state,
        savedQueryList: {
            ...state. savedQueryList,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.LIST_SAVED_QUERY_SUCCESS]: (state, action) => ({
        ...state,
        savedQueryList: {
            ...state.  savedQueryList,
            response: action.payload, loading: false, hasError: false, error: {}
        },
  
    }),
    [ActionTypes.LIST_SAVED_QUERY_ERROR]: (state, action) => ({
        ...state,
        savedQueryList: {
            ...state.  savedQueryList,
            response: {}, loading: false, hasError: true, error: action.payload
        },
  
    }),
    [ActionTypes.SHARE_MULTIPLE_QUERY]: (state) => ({
        ...state,
        shareMultipleQuery: {
            ...state.shareMultipleQuery,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.SHARE_MULTIPLE_QUERY_SUCCESS]: (state, action) => ({
        ...state,
        shareMultipleQuery: {
            ...state. shareMultipleQuery,
            response: action.payload, loading: false, hasError: false, error: {}
        },
  
    }),
    [ActionTypes.SHARE_MULTIPLE_QUERY_ERROR]: (state, action) => ({
        ...state,
        shareMultipleQuery: {
            ...state.  shareMultipleQuery,
            response: {}, loading: false, hasError: true, error: action.payload
        },
  
    }),
    [ActionTypes.REMOVE_MULTIPLE_QUERY]: (state) => ({
        ...state,
        removeBulkQueries: {
            ...state.removeBulkQueries,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.REMOVE_MULTIPLE_QUERY_SUCCESS]: (state, action) => ({
        ...state,
        removeBulkQueries: {
            ...state. removeBulkQueries,
            response: action.payload, loading: false, hasError: false, error: {}
        },
  
    }),
    [ActionTypes.REMOVE_MULTIPLE_QUERY_ERROR]: (state, action) => ({
        ...state,
        removeBulkQueries: {
            ...state.  removeBulkQueries,
            response: {}, loading: false, hasError: true, error: action.payload
        },
  
    }),
    [ActionTypes.LIST_RECEIVED_QUERY]: (state) => ({
        ...state,
        listReceivedQuery: {
            ...state.listReceivedQuery,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.LIST_RECEIVED_QUERY_SUCCESS]: (state, action) => ({
        ...state,
        listReceivedQuery: {
            ...state. listReceivedQuery,
            response: action.payload, loading: false, hasError: false, error: {}
        },
  
    }),
    [ActionTypes.LIST_RECEIVED_QUERY_ERROR]: (state, action) => ({
        ...state,
        listReceivedQuery: {
            ...state.  listReceivedQuery,
            response: {}, loading: false, hasError: true, error: action.payload
        },
  
    }),
    [ActionTypes.RESHARE_QUERY]: (state) => ({
        ...state,
        reshareQuery: {
            ...state.reshareQuery,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.RESHARE_QUERY_SUCCESS]: (state, action) => ({
        ...state,
        reshareQuery: {
            ...state. reshareQuery,
            response: action.payload, loading: false, hasError: false, error: {}
        },
  
    }),
    [ActionTypes.RESHARE_QUERY_ERROR]: (state, action) => ({
        ...state,
        reshareQuery: {
            ...state.  reshareQuery,
            response: {}, loading: false, hasError: true, error: action.payload
        },
  
    }),
    [ActionTypes.LIST_BOOKMARK]: (state) => ({
        ...state,
        listBookmarks: {
            ...state.listBookmarks,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.LIST_BOOKMARK_SUCCESS]: (state, action) => ({
        ...state,
        listBookmarks: {
            ...state. listBookmarks,
            response: action.payload, loading: false, hasError: false, error: {}
        },
  
    }),
    [ActionTypes.LIST_BOOKMARK_ERROR]: (state, action) => ({
        ...state,
        listBookmarks: {
            ...state.  listBookmarks,
            response: {}, loading: false, hasError: true, error: action.payload
        },
  
    }),
    [ActionTypes.SHARE_MULTIPLE_RECORDS]: (state) => ({
        ...state,
        shareMulipleRecords: {
            ...state.shareMulipleRecords,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.SHARE_MULTIPLE_RECORDS_SUCCESS]: (state, action) => ({
        ...state,
        shareMulipleRecords: {
            ...state. shareMulipleRecords,
            response: action.payload, loading: false, hasError: false, error: {}
        },
  
    }),
    [ActionTypes.SHARE_MULTIPLE_RECORDS_ERROR]: (state, action) => ({
        ...state,
        shareMulipleRecords: {
            ...state. shareMulipleRecords,
            response: {}, loading: false, hasError: true, error: action.payload
        },
  
    }),
    [ActionTypes.LIST_RECEIVED_BOOKMARKS]: (state) => ({
        ...state,
        listReceivedBookmarks: {
            ...state.listReceivedBookmarks,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.LIST_RECEIVED_BOOKMARKS_SUCCESS]: (state, action) => ({
        ...state,
        listReceivedBookmarks: {
            ...state. listReceivedBookmarks,
            response: action.payload, loading: false, hasError: false, error: {}
        },
  
    }),
    [ActionTypes.LIST_RECEIVED_BOOKMARKS_ERROR]: (state, action) => ({
        ...state,
        listReceivedBookmarks: {
            ...state. listReceivedBookmarks,
            response: {}, loading: false, hasError: true, error: action.payload
        },
  
    }),
    [ActionTypes.RESHARE_BOOKMARKS]: (state) => ({
        ...state,
        reshareBookmarks: {
            ...state.reshareBookmarks,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.RESHARE_BOOKMARKS_SUCCESS]: (state, action) => ({
        ...state,
        reshareBookmarks: {
            ...state. reshareBookmarks,
            response: action.payload, loading: false, hasError: false, error: {}
        },
  
    }),
    [ActionTypes.RESHARE_BOOKMARKS_ERROR]: (state, action) => ({
        ...state,
        reshareBookmarks: {
            ...state. reshareBookmarks,
            response: {}, loading: false, hasError: true, error: action.payload
        },
        
  
    }),
    [ActionTypes.NOTIFICATION_LIST]: (state) => ({
        ...state,
        list_notification: {
            ...state.list_notification,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.NOTIFICATION_LIST_SUCCESS]: (state, action) => ({
        ...state,
        list_notification: {
            ...state. list_notification,
            response: action.payload, loading: false, hasError: false, error: {}
        },
  
    }),
    [ActionTypes.NOTIFICATION_LIST_ERROR]: (state, action) => ({
        ...state,
        list_notification: {
            ...state. list_notification,
            response: {}, loading: false, hasError: true, error: action.payload
        },
        
  
    }),
    [ActionTypes.NOTIFICATION_SINGLE_READ]: (state) => ({
        ...state,
        single_notification_read: {
            ...state.single_notification_read,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.NOTIFICATION_SINGLE_READ_SUCCESS]: (state, action) => ({
        ...state,
        single_notification_read: {
            ...state. single_notification_read,
            response: action.payload, loading: false, hasError: false, error: {}
        },
  
    }),
    [ActionTypes.NOTIFICATION_SINGLE_READ_ERROR]: (state, action) => ({
        ...state,
        single_notification_read: {
            ...state. single_notification_read,
            response: {}, loading: false, hasError: true, error: action.payload
        },
        
  
    }),

    [ActionTypes.USER_TIER_INFO_START]: (state) => ({
        ...state,
        user_tier_info: {
            ...state.user_tier_info,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.USER_TIER_INFO_SUCCESS]: (state, action) => ({
        ...state,
        user_tier_info: {
            ...state.user_tier_info ,
            response: action.payload, loading: false, hasError: false, error: {}
        },
  
    }),
    [ActionTypes.USER_TIER_INFO_ERROR]: (state, action) => ({
        ...state,
        user_tier_info: {
            ...state. user_tier_info,
            response: {}, loading: false, hasError: true, error: action.payload
        },
        
  
    }),

    [ActionTypes.USER_TIER_INFO_DOC_START]: (state) => ({
        ...state,
        user_tier_info_doc: {
            ...state.user_tier_info_doc,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.USER_TIER_INFO_DOC_SUCCESS]: (state, action) => ({
        ...state,
        user_tier_info_doc: {
            ...state.user_tier_info_doc,
            response: action.payload, loading: false, hasError: false, error: {}
        },
  
    }),
    [ActionTypes.USER_TIER_INFO_DOC_ERROR]: (state, action) => ({
        ...state,
        user_tier_info_doc: {
            ...state.user_tier_info_doc,
            response: {}, loading: false, hasError: true, error: action.payload
        },
        
  
    }),
    
},initialState);

export default reducer;