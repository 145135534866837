export default {
    SSO_LOGIN: 'SSO_LOGIN',
    SSO_LOGIN_SUCCESS: 'SSO_LOGIN_SUCCESS',
    SSO_LOGIN_ERROR: 'SSO_LOGIN_ERROR',

    SIGNIN: 'SIGNIN',
    SIGNIN_SUCCESS: 'SIGNIN_SUCCESS',
    SIGNIN_ERROR: 'SIGNIN_ERROR',

    USER_SIGNIN : 'USER_SIGNIN',
    USER_SIGNIN_SUCCESS : 'USER_SIGNIN_SUCCESS',
    USER_SIGNIN_ERROR: 'USER_SIGNIN_ERROR',

    USER_LOGOUT : "USER_LOGOUT",
    USER_LOGOUT_SUCCESS : "USER_LOGOUT_SUCCESS",
    USER_LOGOUT_ERROR :"USER_LOGOUT_ERROR",

    PROFILE_UPDATE : "PROFILE_UPDATE",
    PROFILE_UPDATE_SUCCESS : "PROFILE_UPDATE_SUCCESS",
    PROFILE_UPDATE_ERROR : "PROFILE_UPADTE_ERROR",

    SET_SEARCH : "SEARCH/SET_SEARCH",
    SET_FILE : "SET_FILE",
    SET_SOURCE : "SOURCE/SET_SOURCE",
    SET_REGION : "REGION/SET_REGION",
    SET_COUNTRY: "COUNTRY/SET_COUNTRY",
    SET_TOPIC  : "TOPIC/SET_TOPIC",
    SET_SECTOR : "SECTOR/SET_SECTOR",
    SET_START_DATE:"DATE/SET_START_DATE",
    SET_END_DATE : "DATE/SET_END_DATE",
    SET_PROFILE_PIC : "PROFILE/PROFILE_PIC",

    SET_FSOURCE : "SET_FSOURCE",
    SET_FREGION : "SET_FREGION",
    SET_FCOUNTRY : "SET_FCOUNTRY",
    SET_FTOPIC : "SET_FTOPIC",
    SET_FSECTOR : "SET_FSECTOR",
    SET_FSDATE : "SET_FSDATE",
    SET_FEDATE : "SET_FEDATE",

    SET_DSOURCE : "SET_DSOURCE",
    SET_DREGION : "SET_DREGION",
    SET_DCOUNTRY : "SET_DCOUNTRY",
    SET_DTOPIC : "SET_DTOPIC",
    SET_DSECTOR : "SET_DSECTOR",
    SET_DSDATE : "SET_DSDATE",
    SET_DEDATE : "SET_DEDATE",

    SET_OSOURCE : "SET_OSOURCE",
    SET_OREGION : "SET_OREGION",
    SET_OCOUNTRY : "SET_OCOUNTRY",
    SET_OTOPIC : "SET_OTOPIC",
    SET_OSECTOR : "SET_OSECTOR",
    SET_OSDATE : "SET_OSDATE",
    SET_OEDATE : "SET_OEDATE",

    SET_DATASOURCE : "SET_DATASOURCE",
    SET_DATAREGION : "SET_DATAREGION",
    SET_DATACOUNTRY : "SET_DATACOUNTRY",
    SET_DATATOPIC : "SET_DATATOPIC",
    SET_DATARESOURCE : "SET_DATARESOURCE",
    SET_DATASECTOR : "SET_DATASECTOR",
    SET_DATASDATE : "SET_DATASDATE",
    SET_DATAEDATE : "SET_DATAEDATE",

    SET_ECONSOURCE : "SET_ECONSOURCE",
    SET_ECONREGION : "SET_ECONREGION",
    SET_ECONCOUNTRY : "SET_ECONCOUNTRY",
    SET_ECONTOPIC : "SET_ECONTOPIC",
    SET_ECONSECTOR : "SET_ECONSECTOR",
    SET_ECONSDATE : "SET_ECONSDATE",
    SET_ECONEDATE : "SET_ECONEDATE",

    SET_MULSOURCE : "SET_MULSOURCE",
    SET_MULREGION : "SET_MULREGION",
    SET_MULCOUNTRY : "SET_MULCOUNTRY",
    SET_MULTOPIC : "SET_MULTOPIC",
    SET_MULSECTOR : "SET_MULSECTOR",
    SET_MULSDATE : "SET_MULSDATE",
    SET_MULEDATE : "SET_MULEDATE",

    SET_EVALSOURCE : "SET_EVALSOURCE",
    SET_EVALREGION : "SET_EVALREGION",
    SET_EVALCOUNTRY : "SET_EVALCOUNTRY",
    SET_EVALTOPIC : "SET_EVALTOPIC",
    SET_EVALSECTOR : "SET_EVALSECTOR",
    SET_EVALSDATE : "SET_EVALSDATE",
    SET_EVALEDATE : "SET_EVALEDATE",

    SET_VIEW_SIMILAR_ID : "SET_VIEW_SIMILAR_ID",
    SET_CURRENT_CATEGORY : "SET_CURRENT_CATEGORY",

    KEYWORD_SEARCH : "KEYWORD_SEARCH",
    KEYWORD_SEARCH_SUCCESS : "KEYWORD_SEARCH_SUCCESS",
    KEYWORD_SEARCH_ERROR : "KEYWORD_SEARCH_ERROR",

    SAVE_QUERY : "SAVE_QUERY",
    SAVE_QUERY_SUCCESS : "SAVE_QUERY_SUCCESS",
    SAVE_QUERY_ERROR : "SAVE_QUERY_ERROR",

    RESET_STORE : "RESET_STORE",

    RECORD_DETAILS :"RECORD_DETAILS",
    RECORD_DETAILS_SUCCESS : "RECORD_DETAILS_SUCCESS",
    RECORD_DETAILS_ERROR : "RECORD_DETAILS_ERROR",

    VOTE_UP : "VOTE_UP",
    VOTE_UP_SUCCESS : "VOTE_UP_SUCCESS",
    VOTE_UP_ERROR : "VOTE_UP_ERROR",

    USER_LIST : "USER_LIST",
    USER_LIST_SUCCESS : "USER_LIST_SUCCES",
    USER_LIST_ERROR : "USER_LIST_ERROR",

    USER_LIST_QUERY_SHARED : "USER_LIST_QUERY_SHARED",
    USER_LIST_QUERY_SHARED_SUCCESS : "USER_LIST_QUERY_SHARED_SUCCESS",
    USER_LIST_QUERY_SHARED_ERROR : "USER_LIST_QUERY_SHARED_ERROR",

    SHARE_SINGLE_QUERY : "SHARE_SINGLE_QUERY",
    SHARE_SINGLE_QUERY_SUCCESS : "SHARE_SINGLE_QUERY_SUCCESS",
    SHARE_SINGLE_QUERY_ERROR : "SHARE_SINGLE_QUERY_ERROR",

    BOOKMARK : "BOOKMARK",
    BOOKMARK_SUCCESS : "BOOKMARK_SUCCESS",
    BOOKMARK_ERROR : "BOOKMARK_ERROR",

    LIST_USERS_RECORD : "LIST_USERS_RECORD",
    LIST_USERS_RECORD_SUCCESS : "LIST_USERS_RECORD_SUCCESS",
    LIST_USERS_RECORD_ERROR : "LIST_USERS_RECORD_ERROR",

    SHARE_RECORD : "SHARE_RECORD",
    SHARE_RECORD_SUCCESS : "SHARE_RECORD_SUCCESS",
    SHARE_RECORD_ERROR : "SHARE_RECORD_ERROR",

    USER_RECOMENDATIONS : "USER_RECOMENDATIONS",
    USER_RECOMENDATIONS_SUCCESS : "USER_RECOMENDATIONS_SUCCESS",
    USER_RECOMENDATIONS_ERROR : "USER_RECOMENDATIONS_ERROR",

    USER_PROFILE : "USER_PROFILE",
    USER_PROFILE_SUCCESS : "USER_PROFILE_SUCCESS",
    USER_PROFILE_ERROR : "USER_PROFILE_ERROR",

    USER_INFO : "USER_INFO",
    USER_INFO_SUCCESS : "USER_INFO_SUCCESS",
    USER_INFO_ERROR : "USER_INFO_ERROR",

    LIST_SECTORS: "LIST_SECTORS",
    LIST_SECTORS_SUCCESS : "LIST_SECTORS_SUCCESS",
    LIST_SECTORS_ERROR : "LIST_SECTORS_ERROR",

    LIST_TOPIC : "LIST_TOPIC",
    LIST_TOPIC_SUCCESS : "LIST_TOPIC_SUCCESS",
    LIST_TOPIC_ERROR : "LIST_TOPIC_ERROR",

    LIST_COUNTRY : "LIST_COUNTRY",
    LIST_COUNTRY_SUCCESS : "LIST_COUNTRY_SUCCESS",
    LIST_COUNTRY_ERROR : "LIST_COUNTRY_ERROR",

    LIST_REGION : "LIST_REGION",
    LIST_REGION_SUCCESS : "LIST_REGION_SUCCESS",
    LIST_REGION_ERROR : "LIST_REGION_ERROR",

    LIST_SAVED_QUERY : "LIST_SAVED_QUERY",
    LIST_SAVED_QUERY_SUCCESS : "LIST_SAVED_QUERY_SUCCESS",
    LIST_SAVED_QUERY_ERROR : "LIST_SAVED_QUERY_ERROR",

    SHARE_MULTIPLE_QUERY : "SHARE_MULTIPLE_QUERY",
    SHARE_MULTIPLE_QUERY_SUCCESS : "SHARE_MULTIPLE_QUERY_SUCCESS",
    SHARE_MULTIPLE_QUERY_ERROR : "SHARE_MULTIPLE_QUERY_ERROR",

    REMOVE_MULTIPLE_QUERY : "REMOVE_MULTIPLE_QUERY",
    REMOVE_MULTIPLE_QUERY_SUCCESS : "REMOVE_MULTIPLE_QUERY_SUCCESS",
    REMOVE_MULTIPLE_QUERY_ERROR : "REMOVE_MULTIPLE_QUERY_ERROR",

    LIST_RECEIVED_QUERY : "LIST_RECEIVED_QUERY",
    LIST_RECEIVED_QUERY_SUCCESS : "LIST_RECEIVED_QUERY_SUCCESS",
    LIST_RECEIVED_QUERY_ERROR : "LIST_RECEIVED_QUERY_ERROR",

    RESHARE_QUERY : "RESHARE_QUERY",
    RESHARE_QUERY_SUCCESS : "RESHARE_QUERY_SUCCESS",
    RESHARE_QUERY_ERROR : "RESHARE_QUERY_ERROR",

    DELETE_RESHARE_BULK : "DELETE_RESHARE_BULK",
    DELETE_RESHARE_BULK_SUCCESS : "DELETE_RESHARE_BULK_SUCCESS",
    DELETE_RESHARE_BULK_ERROR : "DELETE_RESHARE_BULK_ERROR",

    LIST_BOOKMARK : "LIST_BOOKMARK",
    LIST_BOOKMARK_SUCCESS : "LIST_BOOKMARK_SUCCESS",
    LIST_BOOKMARK_ERROR : "LIST_BOOKMARK_ERROR",

    SHARE_MULTIPLE_RECORDS : "SHARE_MULTIPLE_RECORDS",
    SHARE_MULTIPLE_RECORDS_SUCCESS : "SHARE_MULTIPLE_RECORDS_SUCCESS",
    SHARE_MULTIPLE_RECORDS_ERROR : "SHARE_MULTIPLE_RECORDS_ERROR",

    LIST_RECEIVED_BOOKMARKS : "LIST_RECEIVED_BOOKMARKS",
    LIST_RECEIVED_BOOKMARKS_SUCCESS : "LIST_RECEIVED_BOOKMARKS_SUCCESS",
    LIST_RECEIVED_BOOKMARKS_ERROR : "LIST_RECEIVED_BOOKMARKS_ERROR",

    RESHARE_BOOKMARKS : "RESHARE_BOOKMARKS",
    RESHARE_BOOKMARKS_SUCCESS : "RESHARE_BOOKMARKS_SUCCESS",
    RESHARE_BOOKMARKS_ERROR : "RESHARE_BOOKMARKS_ERROR",

    LIST_FAQ : "LIST_FAQ",
    LIST_FAQ_SUCCESS : "LIST_FAQ_SUCCESS",
    LIST_FAQ_ERROR : "LIST_FAQ_ERROR",

    ADD_FAQ : "ADD_FAQ",
    ADD_FAQ_SUCCESS : "ADD_FAQ_SUCCESS",
    ADD_FAQ_ERROR : "ADD_FAQ_ERROR",

    VIEW_SIMILAR : "VIEW_SIMILAR",
    VIEW_SIMILAR_SUCCESS : "VIEW_SIMILAR_SUCCESS",
    VIEW_SIMILAR_ERROR : "VIEW_SIMILAR_ERROR",

    CURRENT_PASSWORD : "CURRENT_PASSWORD",
    CURRENT_PASSWORD_SUCCESS : "CURRENT_PASSWORD_SUCCESS",
    CURRENT_PASSWORD_ERROR : "CURRENT_PASSWORD_ERROR",

    DOC_SEARCH : "DOC_SEARCH",
    DOC_SEARCH_SUCCESS : "DOC_SEARCH_SUCCESS",
    DOC_SEARCH_ERROR : "DOC_SEARCH_ERROR",

    SET_FINANCE_ORG_FILTER : "SET_FINANCE_ORG_FILTER",
    SET_FINANCE_ORG_DATA : "SET_FINANCE_ORG_DATA",
    SET_FINANCE_ORG_HANDLER : "SET_FINANCE_ORG_HANDLER",
    
    SET_FINANCE_COUNTRY_FILTER : "SET_FINANCE_COUNTRY_FILTER",
    SET_FINANCE_COUNTRY_DATA : "SET_FINANCE_COUNTRY_DATA",

    STATIC_COUNT:"STATIC_COUNT",
    STATIC_COUNT_SUCCESS:" STATIC_COUNT_SUCCESS",
    STATIC_COUNT_ERROR:" STATIC_COUNT_ERROR",
    
    SET_FINANCE_COUNTRY_HANDLER : "SET_FINANCE_COUNTRY_HANDLER",

    SET_FINANCE_REGION_FILTER : "SET_FINANCE_REGION_FILTER",
    SET_FINANCE_REGION_DATA : "SET_FINANCE_REGION_DATA",
    SET_FINANCE_REGION_HANDLER : "SET_FINANCE_REGION_HANDLER",

    SET_FINANCE_SECTOR_FILTER : "SET_FINANCE_SECTOR_FILTER",
    SET_FINANCE_SECTOR_DATA : "SET_FINANCE_SECTOR_DATA",
    SET_FINANCE_SECTOR_HANDLER : "SET_FINANCE_SECTOR_HANDLER",

    SET_FINANCE_TOPIC_FILTER : "SET_FINANCE_TOPIC_FILTER",
    SET_FINANCE_TOPIC_DATA : "SET_FINANCE_TOPIC_DATA",
    SET_FINANCE_TOPIC_HANDLER : "SET_FINANCE_TOPIC_HANDLER",

    SET_ECON_ORG_FILTER : "SET_ECON_ORG_FILTER",
    SET_ECON_ORG_DATA : "SET_ECON_ORG_DATA",
    SET_ECON_ORG_HANDLER : "SET_ECON_ORG_HANDLER",
    
    SET_ECON_COUNTRY_FILTER : "SET_ECON_COUNTRY_FILTER",
    SET_ECON_COUNTRY_DATA : "SET_ECON_COUNTRY_DATA",
    SET_ECON_COUNTRY_HANDLER : "SET_ECON_COUNTRY_HANDLER",

    SET_ECON_REGION_FILTER : "SET_ECON_REGION_FILTER",
    SET_ECON_REGION_DATA : "SET_ECON_REGION_DATA",
    SET_ECON_REGION_HANDLER : "SET_ECON_REGION_HANDLER",

    SET_ECON_SECTOR_FILTER : "SET_ECON_SECTOR_FILTER",
    SET_ECON_SECTOR_DATA : "SET_ECON_SECTOR_DATA",
    SET_ECON_SECTOR_HANDLER : "SET_ECON_SECTOR_HANDLER",

    SET_ECON_TOPIC_FILTER : "SET_ECON_TOPIC_FILTER",
    SET_ECON_TOPIC_DATA : "SET_ECON_TOPIC_DATA",
    SET_ECON_TOPIC_HANDLER : "SET_ECON_TOPIC_HANDLER",

    SET_ECON_POPUP : "SET_ECON_POPUP",
    SET_ECON_POPUP_DATA : "SET_ECON_POPUP_DATA",
    SET_INITIAL_COUNTRIES : "SET_INITIAL_COUNTRIES",

    SET_HOME_FLAG : "SET_HOME_FLAG",
    DATA_HOME_FLAG : "DATA_HOME_FLAG",
    DEV_HOME_FLAG : "DEV_HOME_FLAG",
    ECON_HOME_FLAG : "ECON_HOME_FLAG",
    EVAL_HOME_FLAG : "EVAL_HOME_FLAG",
    MUL_HOME_FLAG : "MUL_HOME_FLAG",
    OTHER_HOME_FLAG : "OTHER_HOME_FLAG",

    SET_EVAL_ORG_FILTER : "SET_EVAL_ORG_FILTER",
    SET_EVAL_ORG_DATA : "SET_EVAL_ORG_DATA",
    SET_EVAL_ORG_HANDLER : "SET_EVAL_ORG_HANDLER",
    
    SET_EVAL_COUNTRY_FILTER : "SET_EVAL_COUNTRY_FILTER",
    SET_EVAL_COUNTRY_DATA : "SET_EVAL_COUNTRY_DATA",
    SET_EVAL_COUNTRY_HANDLER : "SET_EVAL_COUNTRY_HANDLER",

    SET_EVAL_REGION_FILTER : "SET_EVAL_REGION_FILTER",
    SET_EVAL_REGION_DATA : "SET_EVAL_REGION_DATA",
    SET_EVAL_REGION_HANDLER : "SET_EVAL_REGION_HANDLER",

    SET_EVAL_SECTOR_FILTER : "SET_EVAL_SECTOR_FILTER",
    SET_EVAL_SECTOR_DATA : "SET_EVAL_SECTOR_DATA",
    SET_EVAL_SECTOR_HANDLER : "SET_EVAL_SECTOR_HANDLER",

    SET_EVAL_TOPIC_FILTER : "SET_EVAL_TOPIC_FILTER",
    SET_EVAL_TOPIC_DATA : "SET_EVAL_TOPIC_DATA",
    SET_EVAL_TOPIC_HANDLER : "SET_EVAL_TOPIC_HANDLER",

    //DEV

    SET_DEV_ORG_FILTER : "SET_DEV_ORG_FILTER",
    SET_DEV_ORG_DATA : "SET_DEV_ORG_DATA",
    SET_DEV_ORG_HANDLER : "SET_DEV_ORG_HANDLER",
    
    SET_DEV_COUNTRY_FILTER : "SET_DEV_COUNTRY_FILTER",
    SET_DEV_COUNTRY_DATA : "SET_DEV_COUNTRY_DATA",
    SET_DEV_COUNTRY_HANDLER : "SET_DEV_COUNTRY_HANDLER",

    SET_DEV_REGION_FILTER : "SET_DEV_REGION_FILTER",
    SET_DEV_REGION_DATA : "SET_DEV_REGION_DATA",
    SET_DEV_REGION_HANDLER : "SET_DEV_REGION_HANDLER",

    SET_DEV_SECTOR_FILTER : "SET_DEV_SECTOR_FILTER",
    SET_DEV_SECTOR_DATA : "SET_DEV_SECTOR_DATA",
    SET_DEV_SECTOR_HANDLER : "SET_DEV_SECTOR_HANDLER",

    SET_DEV_TOPIC_FILTER : "SET_DEV_TOPIC_FILTER",
    SET_DEV_TOPIC_DATA : "SET_DEV_TOPIC_DATA",
    SET_DEV_TOPIC_HANDLER : "SET_DEV_TOPIC_HANDLER",

    //OTHER

    SET_OTHER_ORG_FILTER : "SET_OTHER_ORG_FILTER",
    SET_OTHER_ORG_DATA : "SET_OTHER_ORG_DATA",
    SET_OTHER_ORG_HANDLER : "SET_OTHER_ORG_HANDLER",
    
    SET_OTHER_COUNTRY_FILTER : "SET_OTHER_COUNTRY_FILTER",
    SET_OTHER_COUNTRY_DATA : "SET_OTHER_COUNTRY_DATA",
    SET_OTHER_COUNTRY_HANDLER : "SET_OTHER_COUNTRY_HANDLER",

    SET_OTHER_REGION_FILTER : "SET_OTHER_REGION_FILTER",
    SET_OTHER_REGION_DATA : "SET_OTHER_REGION_DATA",
    SET_OTHER_REGION_HANDLER : "SET_OTHER_REGION_HANDLER",

    SET_OTHER_SECTOR_FILTER : "SET_OTHER_SECTOR_FILTER",
    SET_OTHER_SECTOR_DATA : "SET_OTHER_SECTOR_DATA",
    SET_OTHER_SECTOR_HANDLER : "SET_OTHER_SECTOR_HANDLER",

    SET_OTHER_TOPIC_FILTER : "SET_OTHER_TOPIC_FILTER",
    SET_OTHER_TOPIC_DATA : "SET_OTHER_TOPIC_DATA",
    SET_OTHER_TOPIC_HANDLER : "SET_OTHER_TOPIC_HANDLER",

    //MUL

    SET_MUL_ORG_FILTER : "SET_MUL_ORG_FILTER",
    SET_MUL_ORG_DATA : "SET_MUL_ORG_DATA",
    SET_MUL_ORG_HANDLER : "SET_MUL_ORG_HANDLER",
    
    SET_MUL_COUNTRY_FILTER : "SET_MUL_COUNTRY_FILTER",
    SET_MUL_COUNTRY_DATA : "SET_MUL_COUNTRY_DATA",
    SET_MUL_COUNTRY_HANDLER : "SET_MUL_COUNTRY_HANDLER",

    SET_MUL_REGION_FILTER : "SET_MUL_REGION_FILTER",
    SET_MUL_REGION_DATA : "SET_MUL_REGION_DATA",
    SET_MUL_REGION_HANDLER : "SET_MUL_REGION_HANDLER",

    SET_MUL_SECTOR_FILTER : "SET_MUL_SECTOR_FILTER",
    SET_MUL_SECTOR_DATA : "SET_MUL_SECTOR_DATA",
    SET_MUL_SECTOR_HANDLER : "SET_MUL_SECTOR_HANDLER",

    SET_MUL_TOPIC_FILTER : "SET_MUL_TOPIC_FILTER",
    SET_MUL_TOPIC_DATA : "SET_MUL_TOPIC_DATA",
    SET_MUL_TOPIC_HANDLER : "SET_MUL_TOPIC_HANDLER",

    //rec download

    REC_DOWNLOAD: "REC_DOWNLOAD",
    REC_DOWNLOAD_SUCCESS : "REC_DOWNLOAD_SUCCESS",
    REC_DOWNLOAD_ERROR : "REC_DOWNLOAD_ERROR",

    //DATA

    SET_DATA_ORG_FILTER : "SET_DATA_ORG_FILTER",
    SET_DATA_ORG_DATA : "SET_DATA_ORG_DATA",
    SET_DATA_ORG_HANDLER : "SET_DATA_ORG_HANDLER",
    
    SET_DATA_COUNTRY_FILTER : "SET_DATA_COUNTRY_FILTER",
    SET_DATA_COUNTRY_DATA : "SET_DATA_COUNTRY_DATA",
    SET_DATA_COUNTRY_HANDLER : "SET_DATA_COUNTRY_HANDLER",

    SET_DATA_REGION_FILTER : "SET_DATA_REGION_FILTER",
    SET_DATA_REGION_DATA : "SET_DATA_REGION_DATA",
    SET_DATA_REGION_HANDLER : "SET_DATA_REGION_HANDLER",

    SET_DATA_SECTOR_FILTER : "SET_DATA_SECTOR_FILTER",
    SET_DATA_SECTOR_DATA : "SET_DATA_SECTOR_DATA",
    SET_DATA_SECTOR_HANDLER : "SET_DATA_SECTOR_HANDLER",

    SET_DATA_TOPIC_FILTER : "SET_DATA_TOPIC_FILTER",
    SET_DATA_TOPIC_DATA : "SET_DATA_TOPIC_DATA",
    SET_DATA_TOPIC_HANDLER : "SET_DATA_TOPIC_HANDLER",

    SET_DATA_RESOURCE_FILTER : "SET_DATA_RESOURCE_FILTER",
    SET_DATA_RESOURCE_DATA : "SET_DATA_RESOURCE_DATA",
    SET_DATA_RESOURCE_HANDLER : "SET_DATA_RESOURCE_HANDLER",

    SET_SEARCH_STATE : "SET_SEARCH_STATE",
    SET_VIEW_ALL : "SET_VIEW_ALL",

    NOTIFICATION_LIST:"NOTIFICATION_LIST",
    NOTIFICATION_LIST_SUCCESS : "NOTIFICATION_LIST_SUCCESS",
    NOTIFICATION_LIST_ERROR : "NOTIFICATION_LIST_ERROR",

    NOTIFICATION_SINGLE_READ : "NOTIFICATION_SINGLE_READ",
    NOTIFICATION_SINGLE_READ_SUCCESS : "NOTIFICATION_SINGLE_READ_SUCCESS",
    NOTIFICATION_SINGLE_READ_ERROR : "NOTIFICATION_SINGLE_READ_ERROR",

    VERIFY_INVITE : "VERIFY_INVITE",
    VERIFY_INVITE_SUCCESS : "VERIFY_INVITE_SUCCESS",
    VERIFY_INVITE_ERROR : "VERIFY_INVITE_ERROR",

    SET_FINANCE_SEARCH_COUNTRIES : "SET_FINANCE_SEARCH_COUNTRIES",
    SET_DEV_SEARCH_COUNTRY : "SET_DEV_SEARCH_COUNTRY",
    SET_OTHER_SEARCH_COUNTRY : "SET_OTHER_SEARCH_COUNTRY",
    SET_MUL_SEARCH_COUNTRY : "SET_MUL_SEARCH_COUNTRY",
    SET_EVAL_SEARCH_COUNTRY : "SET_EVAL_SEARCH_COUNTRY",
    DATA_SEARCH_COUNTRY : "DATA_SEARCH_COUNTRY",

    SET_RESULT_TITLE : "SET_RESULT_TITLE",

    USER_TIER_INFO_START : "USER_TIER_INFO_START",
    USER_TIER_INFO_SUCCESS : "USER_TIER_INFO_SUCCESS",
    USER_TIER_INFO_ERROR : "USER_TIER_INFO_ERROR",

    USER_TIER_INFO_DOC_START : "USER_TIER_INFO_START",
    USER_TIER_INFO_DOC_SUCCESS : "USER_TIER_INFO_SUCCESS",
    USER_TIER_INFO_DOC_ERROR : "USER_TIER_INFO_ERROR",

    SET_FILE_PATH : "SET_FILE_PATH",
    SET_CURRENT_TAB : "SET_CURRENT_TAB",

    SET_EXPERTS_ORG_FILTER : "SET_EXPERTS_ORG_FILTER",
    SET_EXPERTS_COUNTRY_FILTER : "SET_EXPERTS_COUNTRY_FILTER",
    SET_EXPERTS_COUNTRY_DATA : "SET_EXPERTS_COUNTRY_DATA",
    SET_EXPERTS_COUNTRY_HANDLER : "SET_EXPERTS_COUNTRY_HANDLER",
    SET_EXPERTS_ORG_DATA : "SET_EXPERTS_ORG_DATA",
    SET_EXPERTS_ORG_HANDLER : "SET_EXPERTS_ORG_HANDLER",
    SET_EXPERTS_REGION_FILTER : "SET_EXPERTS_REGION_FILTER",
    SET_EXPERTS_REGION_DATA : "SET_EXPERTS_REGION_DATA",
    SET_EXPERTS_REGION_HANDLER : "SET_EXPERTS_REGION_HANDLER",
    SET_EXPERTS_SECTOR_FILTER : "SET_EXPERTS_SECTOR_FILTER",
    SET_EXPERTS_SECTOR_DATA : "SET_EXPERTS_SECTOR_DATA",
    SET_EXPERTS_SECTOR_HANDLER : "SET_EXPERTS_SECTOR_HANDLER",
    SET_EXPERTS_TOPIC_FILTER : "SET_EXPERTS_TOPIC_FILTER",
    SET_EXPERTS_TOPIC_DATA : "SET_EXPERTS_TOPIC_DATA",
    SET_EXPERTS_TOPIC_HANDLER : "SET_EXPERTS_TOPIC_HANDLER",
    SET_EXPERTS_RESOURCE_FILTER : "SET_EXPERTS_RESOURCE_FILTER",
    SET_EXPERTS_RESOURCE_DATA : "SET_EXPERTS_RESOURCE_DATA",
    SET_EXPERTS_RESOURCE_HANDLER : "SET_EXPERTS_RESOURCE_HANDLER",
    EXPERTS_HOME_FLAG : "EXPERTS_HOME_FLAG",
    EXPERTS_SEARCH_COUNTRY : "EXPERTS_SEARCH_COUNTRY",
    EXPERTS_IGNORE_QUERY : "EXPERTS_IGNORE_QUERY",

    SET_ECON_IGNORE_QUERY : "SET_ECON_IGNORE_QUERY",

    SET_FILE_VAR : "SET_FILE_VAR",

    KEYWORD_SEARCH_EVAL : "KEYWORD_SEARCH_EVAL",
    KEYWORD_SEARCH_EVAL_SUCCESS : "KEYWORD_SEARCH_EVAL_SUCCESS",
    KEYWORD_SEARCH_EVAL_ERROR : "KEYWORD_SEARCH_EVAL_ERROR",

    KEYWORD_SEARCH_FIN : "KEYWORD_SEARCH_FIN",
    KEYWORD_SEARCH_FIN_SUCCESS : "KEYWORD_SEARCH_FIN_SUCCESS",
    KEYWORD_SEARCH_FIN_ERROR : "KEYWORD_SEARCH_FIN_ERROR",

    KEYWORD_SEARCH_DEV : "KEYWORD_SEARCH_DEV",
    KEYWORD_SEARCH_DEV_SUCCESS : "KEYWORD_SEARCH_DEV_SUCCESS",
    KEYWORD_SEARCH_DEV_ERROR : "KEYWORD_SEARCH_DEV_ERROR",

    KEYWORD_SEARCH_OTHER : "KEYWORD_SEARCH_OTHER",
    KEYWORD_SEARCH_OTHER_SUCCESS : "KEYWORD_SEARCH_OTHER_SUCCESS",
    KEYWORD_SEARCH_OTHER_ERROR : "KEYWORD_SEARCH_OTHER_ERROR",

    KEYWORD_SEARCH_DATA : "KEYWORD_SEARCH_DATA",
    KEYWORD_SEARCH_DATA_SUCCESS : "KEYWORD_SEARCH_DATA_SUCCESS",
    KEYWORD_SEARCH_DATA_ERROR : "KEYWORD_SEARCH_DATA_ERROR",

    KEYWORD_SEARCH_NEWS : "KEYWORD_SEARCH_NEWS",
    KEYWORD_SEARCH_NEWS_SUCCESS : "KEYWORD_SEARCH_NEWS_SUCCESS",
    KEYWORD_SEARCH_NEWS_ERROR : "KEYWORD_SEARCH_NEWS_ERROR",

    KEYWORD_SEARCH_ECON : "KEYWORD_SEARCH_ECON",
    KEYWORD_SEARCH_ECON_SUCCESS : "KEYWORD_SEARCH_ECON_SUCCESS",
    KEYWORD_SEARCH_ECON_ERROR : "KEYWORD_SEARCH_ECON_ERROR",

    KEYWORD_SEARCH_EXPERTS: "KEYWORD_SEARCH_EXPERTS",
    KEYWORD_SEARCH_EXPERTS_SUCCESS : "KEYWORD_SEARCH_EXPERTS_SUCCESS",
    KEYWORD_SEARCH_EXPERTS_ERROR : "KEYWORD_SEARCH_EXPERTS_ERROR",

    KEYWORD_SEARCH_MUL : "KEYWORD_SEARCH_MUL",
    KEYWORD_SEARCH_MUL_SUCCESS : "KEYWORD_SEARCH_MUL_SUCCESS",
    KEYWORD_SEARCH_MUL_ERROR : "KEYWORD_SEARCH_MUL_ERROR",

    SET_QUERY_FLAG : "SET_QUERY_FLAG",
    SET_DATE_TRIGGER : "SET_DATE_TRIGGER",
    SET_DOWNLOAD_FILE_PATH : "SET_DOWNLOAD_FILE_PATH",
    SET_DATE_TRIGGER_DATA : "SET_DATE_TRIGGER_DATA",
    SET_DATE_TRIGGER_DEV : "SET_DATE_TRIGGER_DEV",
    SET_DATE_TRIGGER_ECON : "SET_DATE_TRIGGER_ECON",
    SET_DATE_TRIGGER_EVAL : "SET_DATE_TRIGGER_EVAL",
    SET_DATE_TRIGGER_MUL : "SET_DATE_TRIGGER_MUL",
    SET_DATE_TRIGGER_OTHER : "SET_DATE_TRIGGER_OTHER",

    SET_FINANCE_START_DATE : "SET_FINANCE_START_DATE",
    SET_FINANCE_END_DATE : "SET_FINANCE_END_DATE",

    SET_DEV_START_DATE : "SET_DEV_START_DATE",
    SET_DEV_END_DATE : "SET_DEV_END_DATE",

    SET_ECON_START_DATE : "SET_ECON_START_DATE",
    SET_ECON_END_DATE : "SET_ECON_END_DATE",

    SET_EVAL_START_DATE : "SET_EVAL_START_DATE",
    SET_EVAL_END_DATE : "SET_EVAL_END_DATE",

    SET_MUL_START_DATE : "SET_MUL_START_DATE",
    SET_MUL_END_DATE : "SET_END_START_DATE",

    SET_OTHER_START_DATE : "SET_OTHER_START_DATE",
    SET_OTHER_END_DATE : "SET_OTHER_END_DATE",

    SET_NOTIFICATION_CATEGORY : "SET_NOTIFICATION_CATEGORY",
    SET_SAVED_TAB : "SET_SAVED_TAB",
    SET_BOOKMARK_TAB : "SET_BOOKMARK_TAB",
    SET_NEW_CHAT : "SET_NEW_CHAT",
    SET_CHAT_RESPONSE : "SET_CHAT_RESPONSE",
    SET_CLEAR_CHAT : "SET_CLEAR_CHAT",
    SET_LOADING : "SET_LOADING",
    SET_PERSONALITY : "SET_PERSONALITY",
    SET_CONVERSATION_ID : "SET_CONVERSATION_ID",
    SET_SEARCH_QUERY : "SET_SEARCH_QUERY",
    SET_TAB_ID : "SET_TAB_ID",
    SET_CHAT_HISTORY : "SET_CHAT_HISTORY",
    SET_HISTORY_LOADING : "SET_HISTORY_LOADING",
    SET_CHAT_COUNTRY : "SET_CHAT_COUNTRY",
    SET_CHAT_TOPIC : "SET_CHAT_TOPIC",
    SET_CHAT_REGION : "SET_CHAT_REGION",
    SET_PREVIOUS_TEXT : "SET_PREVIOUS_TEXT",
    SET_PAGINATE_COUNT : "SET_PAGINATE_COUNT",
    SET_GENERATE_MORE : "SET_GENERATE_MORE",
    LIST_FAQ_CHAT : "LIST_FAQ_CHAT",
    LIST_FAQ_SUCCESS_CHAT : "LIST_FAQ_SUCCESS_CHAT",
    LIST_FAQ_ERROR_CHAT : "LIST_FAQ_ERROR_CHAT",
    SET_SAVED_TAB_FAQ : "SET_SAVED_TAB_FAQ",
    SET_IS_FEEDBACK : "SET_IS_FEEDBACK",
    SET_CANCEL_API : "SET_CANCEL_API",
    SET_FAQ_SEARCH : "SET_FAQ_SEARCH",
    SET_CHAT_PERMISSION : "SET_CHAT_PERMISSION",
    SET_UPLOADDOC_LOADING:"SET_UPLOADDOC_LOADING",
    SET_DOCSUBMIT_LOADING:"SET_DOCSUBMIT_LOADING",
    SET_UPLOADEDDOC:"SET_UPLOADEDDOC",
    SET_DOC_URL:"SET_DOC_URL"

    
}