import React, { useEffect, useState } from "react";
import { Container,Modal } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Form from "react-bootstrap/Form";
import srchIco from "../../../../assets/images/icons/srch.png";
import DocImg from "../../../../assets/images/icons/document-icon.png";
import Button from "react-bootstrap/Button";
import { useDropzone } from "react-dropzone";
import {
  _keyWordSearch,
  _docSearch,
} from "../../../../shared/httpservice/apis";
import upgradeImg from '../../../../assets/images/icons/upgrade-icon.png'
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setChatCountry,setChatRegion,setChatTopic } from "../../../chatai/services";
import {
  keywordSearch,
  setSearchKey,
  setCurrentCategory,
  setFileKey,
  setDCountryKey, setDRegionKey, setDSectorKey, setDTopicKey, setDSourceKey,setDStartDateKey,setDEndDateKey,
  setDataCountryKey, setDataRegionKey, setDataSectorKey, setDataTopicKey, setDataSourceKey, setDataStartDateKey, setDataEndDateKey, setDataResourceKey,
  setEconCountryKey,
  setEconRegionKey,
  setEconSectorKey,
  setEconTopicKey,
  setEconSourceKey,
  setEconStartDateKey,
  setEconEndDateKey,
  setEvalCountryKey, setEvalRegionKey, setEvalSectorKey, setEvalTopicKey, setEvalSourceKey,setEvalStartDateKey,setEvalEndDateKey,
  setMulCountryKey,setMulRegionKey,setMulSectorKey,setMulTopicKey,setMulSourceKey,setMulStartDateKey,setMulEndDateKey,
  setOCountryKey, setORegionKey, setOSectorKey, setOTopicKey, setOSourceKey, setOStartDateKey, setOEndDateKey,
  setFCountryKey,
  setFRegionKey,
  setFSectorKey,
  setFTopicKey,
  setFSourceKey,
  setFStartDateKey,
  setFEndDateKey,
  setFilePathFn,
  setCurrentTabFn,
  setCountryKey,
  setRegionKey,
  setSectorKey,
  setTopicKey,
  setSourceKey,
  setFileVar
} from "../../../results/services";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { userProfileInfo,
  userTierInfoDoc 
} from "./../../../profile/services";
import axios from "axios";
import { setHomeFlagFunction,setDataHomeFlagFunction,setEvalHomeFlagFunction, setEconHomeFlagFunction, setFinanceSearchCountry,setDateTrigger,setFinanceStartDate,setFinanceEndDate } from "../../../results/filterServices";
import {
  setDevOrgFilter,
  setDevOrgData,
  setDevOrgHandler,
  setDevCountryFilter,
  setDevCountryData,
  setDevCountryHandler,
  setDevRegionFilter,
  setDevRegionData,
  setDevRegionHandler,
  setDevSectorData,
  setDevSectorFilter,
  setDevSectorHandler,
  setDevTopicFilter,
  setDevTopicData,
  setDevTopicHandler,
  setDevSearchCountry,
  setDateTriggerDev,
  setDevEndDate,
  setDevStartDate
} from "./../../../results/components/devpubsresults/devpubsfiltersevices";
import {
  setDataOrgFilter,
  setDataOrgData,
  setDataOrgHandler,
  setDataCountryFilter,
  setDataCountryData,
  setDataCountryHandler,
  setDataRegionFilter,
  setDataRegionData,
  setDataRegionHandler,
  setDataSectorData,
  setDataSectorFilter,
  setDataSectorHandler,
  setDataTopicFilter,
  setDataTopicData,
  setDataTopicHandler,
  setDataResourceFilter,
  setDataResourceData,
  setDataResourceHandler,
  setDataSearchCountry
  
  
} from "./../../../results/components/dataresults/datafilterservices";
import {
  setEconOrgFilter,
  setEconOrgData,
  setEconOrgHandler,
  setEconCountryFilter,
  setEconCountryData,
  setEconCountryHandler,
  setEconRegionFilter,
  setEconRegionData,
  setEconRegionHandler,
  setEconSectorData,
  setEconSectorFilter,
  setEconSectorHandler,
  setEconTopicFilter,
  setEconTopicData,
  setEconTopicHandler,
  setEconPopupFunction,
  setEconIntialCountry,
  setEconIgnoreQuery,
  setDateTriggerEcon,
  setEconEndDate,
  setEconStartDate
} from "./../../../results/components/econpubsresults/econfilterservices";
import {
  setEvalOrgFilter,
  setEvalOrgData,
  setEvalOrgHandler,
  setEvalCountryFilter,
  setEvalCountryData,
  setEvalCountryHandler,
  setEvalRegionFilter,
  setEvalRegionData,
  setEvalRegionHandler,
  setEvalSectorData,
  setEvalSectorFilter,
  setEvalSectorHandler,
  setEvalTopicFilter,
  setEvalTopicData,
  setEvalTopicHandler,
  setEvalSearchCountry,
  setDateTriggerEval,
  setEvalEndDate,
  setEvalStartDate
} from "./../../../results/components/evaluationsresults/evaluationfilterservices";
import {
  setMulOrgFilter,
  setMulOrgData,
  setMulOrgHandler,
  setMulCountryFilter,
  setMulCountryData,
  setMulCountryHandler,
  setMulRegionFilter,
  setMulRegionData,
  setMulRegionHandler,
  setMulSectorData,
  setMulSectorFilter,
  setMulSectorHandler,
  setMulTopicFilter,
  setMulTopicData,
  setMulTopicHandler,
  setMulSearchCountry,
  setDateTriggerMul,
  setMulEndDate,
  setMulStartDate
} from "./../../../results/components/multimediaresults/multimediafilterservices";
import {
  setOtherOrgFilter,
  setOtherOrgData,
  setOtherOrgHandler,
  setOtherCountryFilter,
  setOtherCountryData,
  setOtherCountryHandler,
  setOtherRegionFilter,
  setOtherRegionData,
  setOtherRegionHandler,
  setOtherSectorData,
  setOtherSectorFilter,
  setOtherSectorHandler,
  setOtherTopicFilter,
  setOtherTopicData,
  setOtherTopicHandler,
  setOtherSearchCountry,
  setDateTriggerOther,
  setOtherEndDate,
  setOtherStartDate
} from "./../../../results/components/otherpubsresults/otherpubsfilterservices";
import {
  setExpertsOrgFilter,
  setExpertsOrgData,
  setExpertsOrgHandler,
  setExpertsCountryFilter,
  setExpertsCountryData,
  setExpertsCountryHandler,
  setExpertsRegionFilter,
  setExpertsRegionData,
  setExpertsRegionHandler,
  setExpertsSectorData,
  setExpertsSectorFilter,
  setExpertsSectorHandler,
  setExpertsTopicFilter,
  setExpertsTopicData,
  setExpertsTopicHandler,
  setExpertsResourceFilter,
  setExpertsResourceData,
  setExpertsResourceHandler,
  setExpertsSearchCountry,
  setExpertsIgnoreQuery
  
} from "./../../../results/components/expertsresults/expertsfilterservices";
import {
  setFinanceOrgFilter,
  setFinanceOrgData,
  setFinanceOrgHandler,
  setFinanceCountryFilter,
  setFinanceCountryData,
  setFinanceCountryHandler,
  setFinanceRegionFilter,
  setFinanceRegionData,
  setFinanceRegionHandler,
  setFinanceSectorData,
  setFinanceSectorFilter,
  setFinanceSectorHandler,
  setFinanceTopicFilter,
  setFinanceTopicData,
  setFinanceTopicHandler,
} from "../../../results/filterServices";
const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
};

const thumb = {
  display: "flex",
  borderRadius: 2,
  width: 52,
  height: 52,
  padding:0,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

function Banner() {
  const [UpgradeShow, UpgradeSetShow] = useState(false);
  const UpgradehandleClose = () => UpgradeSetShow(false);
  const UpgradehandleShow = () => UpgradeSetShow(true);

  const [UpgradeShows, UpgradeSetShows] = useState(false);
  const UpgradehandleCloses = () => UpgradeSetShows(false);
  const UpgradehandleShows = () => UpgradeSetShows(true);

  const [clear,setClear] = useState(false)
  useEffect(()=>{
    if(clear===true){
      setClear(false)
    }
  },[clear])
  const token = useSelector(
    (store) => store?.auth?.signin?.response?.data?.data?.token,
    shallowEqual
  );
  const tokenSso = useSelector(
    (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token,
    shallowEqual
  );
  const file = useSelector((state) => state?.results?.file);
  const state = useSelector((state) => state?.results);
  const tier_info_state = useSelector((state)=>state?.profile?.user_tier_info_doc)
  const searchTerm = useSelector((state) => state?.results?.search);
  // console.log(searchTerm,"search term");
  const qfile = useSelector((state) => state?.results?.file);
  const current_tab = useSelector((state)=>state?.results?.current_tab)
  const [tab,setTab] = useState(currentTab)
  const [userToken, setUserToken] = useState("");
  useEffect(() => {
    if (tokenSso === undefined) {
      setUserToken(token);
    } else {
      setUserToken(tokenSso);
    }
  }, [1]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  // console.log(files);
  // console.log(files[0]?.size,"file length");
  const [sizeExceeded,setSizeExceeded] = useState()
  // console.log(sizeExceeded,"flag");
  useEffect(()=>{
    if(files[0]?.size>1042157){
      setSizeExceeded(true)
    }else{
      setSizeExceeded(false)
    }
  },[files[0]?.size])
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      // "text/html": [".txt", ".doc", ".pdf", ".docx"],
      "application/pdf" : [".txt",".doc",".pdf",".docx"]
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const removeFile =()=>{
    setFiles([])
  }

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          //  src={file.preview}
          src={DocImg}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  const [currentTab, setCurrentTab] = useState("text");
  useEffect(()=>{
    if(token!==undefined){
      dispatch(userTierInfoDoc(token,"document_search"))
    }else if(tokenSso!==undefined){
      dispatch(userTierInfoDoc(tokenSso,"document_search"))
    }
  },[currentTab])

  // useEffect(()=>{
  //   if(currentTab==='document'){
  //     if(token!==undefined){
  //       dispatch(userTierInfoDoc(token,"document_search")).then(()=>{
  //         if(tier_info_state?.error?.response?.status===400){
  //           UpgradehandleShows()
  //         }
  //       })
  //     }
  //   }
  // },[currentTab])

  const handleSelect = (key) => {
    if (key === "text") {
      setCurrentTab("text");
      dispatch(setCurrentTabFn("text"))
    } else if (key === "document") {
      setCurrentTab("document");
      dispatch(setCurrentTabFn("document"))
    }
  };

  
  

  const onSearchValueChange = (e) => {
    e.preventDefault();
    dispatch(setCurrentCategory("financing"));
    const value = e.target.value;
    dispatch(setSearchKey(value || ""));
    if (value) {
      navigate("/results");
    }
  };
//clears all the selected filters
const clearChatFilters = ()=>{
  dispatch(setChatCountry(""))
  dispatch(setChatRegion(""))
  dispatch(setChatTopic(""))
}
  const clearDevFilters =()=>{
    dispatch(setDSourceKey(""))
        dispatch(setDCountryKey(""))
        dispatch(setDRegionKey(""))
        dispatch(setDTopicKey(""))
        dispatch(setDSectorKey(""))
        dispatch(setDStartDateKey(""))
        dispatch(setDEndDateKey(""))
        dispatch(setDevCountryData([]));
        dispatch(setDevOrgData([]));
        dispatch(setDevCountryHandler([]));
        dispatch(setDevOrgHandler([]));
        dispatch(setDevRegionData([]));
        dispatch(setDevRegionHandler([]));
        dispatch(setDevSectorHandler([]));
        dispatch(setDevSectorData([]));
        dispatch(setDevTopicData([]));
        dispatch(setDevTopicHandler([]));
        dispatch(setDevCountryFilter([]))
        dispatch(setDevOrgFilter([]))
        dispatch(setDevRegionFilter([]))
        dispatch(setDevSectorFilter([]))
        dispatch(setDevTopicFilter([]))
        dispatch(setDevSearchCountry([]))
        dispatch(setDateTriggerDev(true))
        dispatch(setDevStartDate(null))
        dispatch(setDevEndDate(null))
  }

  const clearDataFilters =()=>{
    dispatch(setDataSourceKey(""))
    dispatch(setDataCountryKey(""))
    dispatch(setDataRegionKey(""))
    dispatch(setDataTopicKey(""))
    dispatch(setDataSectorKey(""))
    dispatch(setDataResourceKey(""))
        dispatch(setDataOrgData([]));
        dispatch(setDataCountryHandler([]));
        dispatch(setDataOrgHandler([]));
        dispatch(setDataRegionData([]));
        dispatch(setDataRegionHandler([]));
        dispatch(setDataSectorHandler([]));
        dispatch(setDataSectorData([]));
        dispatch(setDataTopicData([]));
        dispatch(setDataTopicHandler([]));
        dispatch(setDataCountryFilter([]))
        dispatch(setDataOrgFilter([]))
        dispatch(setDataRegionFilter([]))
        dispatch(setDataSectorFilter([]))
        dispatch(setDataTopicFilter([]))
        dispatch(setDataCountryData([]))
        dispatch(setDataResourceFilter([]))
        dispatch(setDataResourceData([]))
        dispatch(setDataResourceHandler([]))
        dispatch(setDataSearchCountry([]))
  }

  const clearEconFilters =()=>{
        dispatch(setEconSourceKey(""))
        dispatch(setEconCountryKey(""))
        dispatch(setEconRegionKey(""))
        dispatch(setEconTopicKey(""))
        dispatch(setEconSectorKey(""))
        dispatch(setEconStartDateKey(""))
        dispatch(setEconEndDateKey(""))
        dispatch(setEconCountryData([]));
        dispatch(setEconOrgData([]));
        dispatch(setEconCountryHandler([]));
        dispatch(setEconOrgHandler([]));
        dispatch(setEconRegionData([]));
        dispatch(setEconRegionHandler([]));
        dispatch(setEconSectorHandler([]));
        dispatch(setEconSectorData([]));
        dispatch(setEconTopicData([]));
        dispatch(setEconTopicHandler([]));
        dispatch(setEconCountryFilter([]))
        dispatch(setEconOrgFilter([]))
        dispatch(setEconRegionFilter([]))
        dispatch(setEconSectorFilter([]))
        dispatch(setEconTopicFilter([]))
        dispatch(setEconIntialCountry([]))
        dispatch(setEconIgnoreQuery(false))
        dispatch(setDateTriggerEcon(true))
        dispatch(setEconStartDate(null))
        dispatch(setEconEndDate(null))
  }

  const clearEvalFilters =()=>{
        dispatch(setEvalSourceKey(""))
        dispatch(setEvalCountryKey(""))
        dispatch(setEvalRegionKey(""))
        dispatch(setEvalTopicKey(""))
        dispatch(setEvalSectorKey(""))
        dispatch(setEvalStartDateKey(""))
        dispatch(setEvalEndDateKey(""))
        dispatch(setEvalCountryData([]));
        dispatch(setEvalOrgData([]));
        dispatch(setEvalCountryHandler([]));
        dispatch(setEvalOrgHandler([]));
        dispatch(setEvalRegionData([]));
        dispatch(setEvalRegionHandler([]));
        dispatch(setEvalSectorHandler([]));
        dispatch(setEvalSectorData([]));
        dispatch(setEvalTopicData([]));
        dispatch(setEvalTopicHandler([]));
        dispatch(setEvalCountryFilter([]))
        dispatch(setEvalOrgFilter([]))
        dispatch(setEvalRegionFilter([]))
        dispatch(setEvalSectorFilter([]))
        dispatch(setEvalTopicFilter([]))
        dispatch(setEvalSearchCountry([]))
        dispatch(setDateTriggerEcon(true))
        dispatch(setEvalStartDate(null))
        dispatch(setEvalEndDate(null))
  }
  
  const clearMulFilters =()=>{
        dispatch(setMulSourceKey(""))
        dispatch(setMulCountryKey(""))
        dispatch(setMulRegionKey(""))
        dispatch(setMulTopicKey(""))
        dispatch(setMulSectorKey(""))
        dispatch(setMulStartDateKey(""))
        dispatch(setMulEndDateKey(""))
        dispatch(setMulCountryData([]));
        dispatch(setMulOrgData([]));
        dispatch(setMulCountryHandler([]));
        dispatch(setMulOrgHandler([]));
        dispatch(setMulRegionData([]));
        dispatch(setMulRegionHandler([]));
        dispatch(setMulSectorHandler([]));
        dispatch(setMulSectorData([]));
        dispatch(setMulTopicData([]));
        dispatch(setMulTopicHandler([]));
        dispatch(setMulCountryFilter([]))
        dispatch(setMulOrgFilter([]))
        dispatch(setMulRegionFilter([]))
        dispatch(setMulSectorFilter([]))
        dispatch(setMulTopicFilter([]))
        dispatch(setMulSearchCountry([]))
        dispatch(setDateTriggerMul(true))
        dispatch(setMulStartDate(null))
        dispatch(setMulEndDate(null))
  }

  const clearOtherFilters =()=>{
    dispatch(setOSourceKey(""))
    dispatch(setOCountryKey(""))
    dispatch(setORegionKey(""))
    dispatch(setOTopicKey(""))
    dispatch(setOSectorKey(""))
    dispatch(setOStartDateKey(""))
    dispatch(setOEndDateKey(""))
    dispatch(setOtherCountryData([]));
    dispatch(setOtherOrgData([]));
    dispatch(setOtherCountryHandler([]));
    dispatch(setOtherOrgHandler([]));
    dispatch(setOtherRegionData([]));
    dispatch(setOtherRegionHandler([]));
    dispatch(setOtherSectorHandler([]));
    dispatch(setOtherSectorData([]));
    dispatch(setOtherTopicData([]));
    dispatch(setOtherTopicHandler([]));
    dispatch(setOtherCountryFilter([]))
    dispatch(setOtherOrgFilter([]))
    dispatch(setOtherRegionFilter([]))
    dispatch(setOtherSectorFilter([]))
    dispatch(setOtherTopicFilter([]))
    dispatch(setOtherSearchCountry([]))
    dispatch(setDateTriggerMul(true))
    dispatch(setOtherStartDate(null))
    dispatch(setOtherEndDate(null))
  }

  const clearFinanceFilters = ()=>{
    dispatch(setFSourceKey(""))
    dispatch(setFCountryKey(""))
    dispatch(setFRegionKey(""))
    dispatch(setFTopicKey(""))
    dispatch(setFSectorKey(""))
    dispatch(setFStartDateKey(""))
    dispatch(setFEndDateKey(""))
    dispatch(setFinanceCountryData([]));
    dispatch(setFinanceOrgData([]));
    dispatch(setFinanceCountryHandler([]));
    dispatch(setFinanceOrgHandler([]));
    dispatch(setFinanceRegionData([]));
    dispatch(setFinanceRegionHandler([]));
    dispatch(setFinanceSectorHandler([]));
    dispatch(setFinanceSectorData([]));
    dispatch(setFinanceTopicData([]));
    dispatch(setFinanceTopicHandler([]));
    dispatch(setFinanceCountryFilter([]))
    dispatch(setFinanceOrgFilter([]))
    dispatch(setFinanceRegionFilter([]))
    dispatch(setFinanceSectorFilter([]))
    dispatch(setFinanceTopicFilter([]))
    dispatch(setFinanceSearchCountry([]));
    dispatch(setFinanceStartDate(null))
    dispatch(setFinanceEndDate(null))
  }

  const clearExpertsFilters =()=>{
    dispatch(setSourceKey(""))
    dispatch(setCountryKey(""))
    dispatch(setRegionKey(""))
    dispatch(setTopicKey(""))
    dispatch(setSectorKey(""))
    dispatch(setExpertsIgnoreQuery(false))
    dispatch(setExpertsOrgFilter([]));
    dispatch(setExpertsOrgData([]));
    dispatch(setExpertsOrgHandler([]));
    dispatch(setExpertsCountryFilter([]));
    dispatch(setExpertsCountryData([]));
    dispatch(setExpertsCountryHandler([]));
    dispatch(setExpertsRegionFilter([]));
    dispatch(setExpertsRegionData([]));
    dispatch(setExpertsRegionHandler([]));
    dispatch(setExpertsSectorFilter([]));
    dispatch(setExpertsSectorData([]));
    dispatch(setExpertsSectorHandler([]));
    dispatch(setExpertsTopicFilter([]));
    dispatch(setExpertsTopicData([]));
    dispatch(setExpertsTopicHandler([]));
    
    dispatch(setExpertsSearchCountry([]));
  }

  const [searchValue, setSearchValue] = useState(searchTerm);
  useEffect(()=>{
    setSearchValue(searchTerm)
  },[searchTerm])
  useEffect(()=>{
    if(url?.includes("home")){
      setSearchValue("")
    }
  },[])
  //on submit fn
  const onSubmit = () => {
    if(searchValue?.length>6000&&currentTab==="text"){
      UpgradehandleShow()
    }
    // if(!searchValue?.trim()?.length>0&&currentTab==="text"&&currentTab!=="document"){
    //   toast.error("Enter some valid characters")
    //   setSearchValue("")
    // }
    if (
      currentTab === "text" &&
      searchValue !== "" &&
      searchValue !== undefined&&
      searchValue?.length<6000&&
      searchValue?.trim()?.length>0
    ) {
      clearChatFilters()
      clearExpertsFilters()
      clearDevFilters()
      clearDataFilters()
      clearEconFilters()
      clearEvalFilters()
      clearMulFilters()
      clearOtherFilters()
      clearFinanceFilters()
      dispatch(setDateTrigger(true))
      dispatch(setFileKey(""));
      dispatch(setCurrentCategory("financing"));
      const value = searchValue;
      dispatch(setSearchKey(value || ""));
      setClear(true)
      if (value) {
        navigate("/results");
        // setSearchValue("")
      }
    } else if (current_tab === "document" && files?.length > 0) {
      if(tier_info_state?.error?.response?.status===403){
        UpgradehandleShows()
      }else if(files[0]?.size<=1042157) {    
        setSearchValue("")  
        dispatch(setFileVar(""))
        clearExpertsFilters()
        clearDevFilters()
        clearDataFilters()
        clearEconFilters()
        clearEvalFilters()
        clearMulFilters()
        clearOtherFilters()
        clearFinanceFilters()
        dispatch(setDateTrigger(true))
        dispatch(setSearchKey(""));
        dispatch(setFilePathFn(true))
        dispatch(setCurrentCategory("financing"))
        dispatch(setCurrentTabFn("document"))
        dispatch(setFileKey(files[0] || ""));
        // if(files[0]?.size>10000){
        //   toast.info("File size is larger")
        // }
        setFiles([])
        navigate("/results");

      }
    
    }
  };
  //handle enter
  const handleKeyDown = (e) => {
    // e.target.style.height = "100px";
    // e.target.style.height = `${e.target.scrollHeight}px`;
    if (e.keyCode === 13&&currentTab === "text"&& !e.shiftKey) {
      if(searchValue?.length>6000){
        UpgradehandleShow()
      }
      // if(!searchValue?.trim()?.length>0){
      //   setSearchValue("")
      //   toast.error("Enter some valid characters")
        
      // }
      if (
        currentTab === "text" &&
        searchValue !== "" &&
        searchValue !== undefined&&
        searchValue?.length<6000&&
        searchValue?.trim()?.length>0
      ){
        clearChatFilters()
        clearExpertsFilters()
        clearDevFilters()
        clearDataFilters()
        clearEconFilters()
        clearEvalFilters()
        clearMulFilters()
        clearOtherFilters()
        clearFinanceFilters()
        dispatch(setDateTrigger(true))
        dispatch(setFileKey(""));
        dispatch(setFilePathFn(""))
        dispatch(setCurrentCategory("financing"));
        event.preventDefault();
        const value = e.target.value;
        dispatch(setSearchKey(value || ""));
        if (value) {
          navigate("/results");
          // setSearchValue("")
        }
      }
        
    }
  };
  const url = window.location.href;
  const homeFlag = () => {
    if (url?.includes("home")) {
      dispatch(setHomeFlagFunction(true))
      dispatch(setDataHomeFlagFunction(true))
      dispatch(setEvalHomeFlagFunction(true))
      dispatch(setEconHomeFlagFunction(true))
    }
  };

  const homeFl = useSelector((state)=>state?.filter?.from_home)
  
  const dataFl = useSelector((state)=>state?.filter?.data_home_flag)
useEffect(()=>{
    setTimeout(()=>{
        if(!url?.includes("home")){
            dispatch(setHomeFlagFunction(false));
        }
    },4000)
    
},[])
  return (
    <div className="banner-wrap">
      <Container>
        <div className="banner-content-box">
          <Form
            onSubmit={(e) => {
              e.preventDefault();             
            }}
          >
            <Tabs
              activeKey={current_tab===""?"text":current_tab}
              // defaultActiveKey="text"
              id="justify-tab-example"
              onSelect={handleSelect}
            >
              <Tab eventKey="text" title="Text" className="banner-tab">
                <div className="banner-textarea-wrap">
                   <Form.Control
                   as="textarea"
                   placeholder="Keywords, Abstract or Program Description"
                   onKeyDown={handleKeyDown}
                   onChange={(e) => {
                     setSearchValue(e.target.value);
                   }}
                   value={searchValue}
                 />                 
                </div>
              </Tab>
              <Tab eventKey="document" title="Document" className="banner-tab">
              <div className="fileuploader">
              <section className="p-relative">
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <section className="dragand-drop-style">
                    <div style={thumbsContainer}>{thumbs}</div>
                    <p className="filename-uploaded">
                        {files?.length > 0 ? (files[0]?.path?.length>90?files[0]?.path?.substring(0,89)+"...":files[0]?.path) : null}
                      </p>
                      {sizeExceeded?
                       <div className="length-exceeded">Your file size exceeded the limit. Maximum upload file size: 1MB</div>:null  
                    }
                     
                      {files?.length===0&&
                    <div className="d-lg-flex justify-content-center flex-column">
                      <span className="drop-upload-icon">
                        <svg
                          width="23"
                          height="17"
                          viewBox="0 0 23 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.48864 17C3.99015 17 2.70076 16.4502 1.62045 15.3505C0.540151 14.2509 0 12.9384 0 11.4131C0 10.0297 0.43125 8.81038 1.29375 7.75509C2.15625 6.69979 3.26705 6.06573 4.62614 5.85289C4.97462 4.1325 5.79356 2.72692 7.08295 1.63615C8.37235 0.545383 9.86212 0 11.5523 0C13.5212 0 15.1722 0.722744 16.5051 2.16823C17.8381 3.61372 18.5045 5.34742 18.5045 7.36933V8.00782C19.7591 7.97235 20.822 8.38472 21.6932 9.24491C22.5644 10.1051 23 11.1914 23 12.5039C23 13.7277 22.5644 14.783 21.6932 15.6698C20.822 16.5566 19.7852 17 18.583 17H12.2841C11.8659 17 11.5 16.8404 11.1864 16.5211C10.8727 16.2019 10.7159 15.8294 10.7159 15.4038V8.53991L8.54659 10.748L7.42273 9.60407L11.5 5.45383L15.5773 9.60407L14.4534 10.748L12.2841 8.53991V15.4038H18.583C19.367 15.4038 20.0379 15.12 20.5955 14.5524C21.153 13.9849 21.4318 13.302 21.4318 12.5039C21.4318 11.7058 21.153 11.023 20.5955 10.4554C20.0379 9.88785 19.367 9.60407 18.583 9.60407H16.9364V7.36933C16.9364 5.79082 16.4093 4.43401 15.3551 3.2989C14.3009 2.1638 12.9985 1.59624 11.4477 1.59624C9.89697 1.59624 8.59015 2.1638 7.52727 3.2989C6.46439 4.43401 5.93295 5.79082 5.93295 7.36933H5.43636C4.35606 7.36933 3.44129 7.75509 2.69205 8.5266C1.9428 9.29812 1.56818 10.2514 1.56818 11.3865C1.56818 12.4862 1.95151 13.4306 2.71818 14.2199C3.48485 15.0091 4.40833 15.4038 5.48864 15.4038H9.14773V17H5.48864Z"
                            fill="#808080"
                          />
                        </svg>
                      </span>
                      <>
                       <p>Drop file here to upload, or click to browse</p>
                       <p>(.txt/.pdf/.doc/.docx) (max: 1mb)</p>
                       </>
                     
                    </div>}
                  </section>
                </div>
                {files?.length>0&&
                <button onClick={removeFile} className="remove-uploaded-btn btn btn-sm btn-secondary">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/>
                </svg>Remove File</button>
                }
                 
              </section>
             
            </div>
                
              </Tab>
            </Tabs>
            
            <div className="d-flex justify-content-end banner-btn-wrap">
              <Button
                onClick={() => {
                  onSubmit();
                  homeFlag();
                }}
              >
                <span>
                  <img src={srchIco} alt="" />
                </span>
                Search
              </Button>
              {/* {currentTab === "text"&&
               <p className="banner-info-text">Shift+Enter to add a new line</p>
              } */}
             
            </div>
          </Form>
        </div>
      </Container>
      <Modal show={UpgradeShow} size="md" centered className='common-modal'>
          <Modal.Body>
            <div className="upgrade-pop-wrap">
              <div className='upgrade-img'>
                <img src={upgradeImg} alt="" />
              </div>
              <h3 className='upgrade-title'>Character Limit Exceeded!</h3>
              <p>Looks like the query text you added exceeded the character limit.<br/>Please reduce the text size or opt for the Document upload to continue the search.</p>
              <p></p>
              <div className='upgrade-btn'>
                <Button onClick={UpgradehandleClose}>OK</Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={UpgradeShows} size="md" centered className='common-modal'>
          <Modal.Body>
            <div className="upgrade-pop-wrap">
              <div className='upgrade-img'>
                <img src={upgradeImg} alt="" />
              </div>
              <h3 className='upgrade-title'>Premium feature!</h3>
              <p>Please contact your admin to enable this feature.</p>
              <div className='upgrade-btn'>
                <Button onClick={UpgradehandleCloses}>OK</Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>  
    </div>
  );
}

export default Banner;
