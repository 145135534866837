import React, { useState } from "react";
import ChatTextarea from "../components/chattextarea";
import ChatContent from "../components/chatcontent/container";

function ChatFeed() {
  return (
    <div className="chat-feed-wrap h-100 p-relative">
      <ChatContent />
      <div className="feed-section">
        <ChatTextarea />
      </div>
    </div>
  );
}

export default ChatFeed;
