import React from "react";

function CopyIcon() {
  return (
    <span className="icon-wrap">
      <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 3.51875H0.95V3.56875V14.875C0.95 15.1921 1.09076 15.4678 1.36468 15.7006C1.63858 15.9334 1.95893 16.05 2.32353 16.05H12.7794H12.8294V16V14.875V14.825H12.7794H2.37353V3.56875V3.51875H2.32353H1ZM4.01174 13.4506C4.28564 13.6834 4.60599 13.8 4.97059 13.8H14.6765C15.0411 13.8 15.3614 13.6834 15.6353 13.4506C15.9092 13.2178 16.05 12.9421 16.05 12.625V2.125C16.05 1.80794 15.9092 1.53223 15.6353 1.2994C15.3614 1.06659 15.0411 0.95 14.6765 0.95H4.97059C4.60599 0.95 4.28564 1.06659 4.01174 1.2994C3.73782 1.53223 3.59706 1.80794 3.59706 2.125V12.625C3.59706 12.9421 3.73782 13.2178 4.01174 13.4506ZM14.6265 2.175V12.575H5.02059V2.175H14.6265Z"
          fill="#373737"
          stroke="#373737"
          stroke-width="0.1"
        />
      </svg>
    </span>
  );
}

export default CopyIcon;
