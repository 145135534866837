import React, { useEffect } from "react";
import { Outlet, Navigate,useNavigate } from "react-router-dom";
import { isAuthenticated } from "../utils";
import PageWrapper from "../pagewrapper/pagewrapper";
import { useSelector,shallowEqual,useDispatch } from "react-redux";
import { userLogOut, resetStore } from './../../modules/auth/services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const PrivateRoute = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const status = localStorage.getItem("change")
  const token = useSelector((store)=>store?.auth?.signin?.response?.data?.data?.token,shallowEqual)

  const tokenSso = useSelector(
        (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token,shallowEqual
      );
  const notState = useSelector((state)=>state?.profile?.list_notification?.error?.response?.statusText,shallowEqual) 
  const invalid = useSelector((state)=>state?.profile?.list_notification?.error?.response?.data?.message,shallowEqual) 
  const networkError = useSelector((state)=>state?.profile?.list_notification?.error?.response?.status)
  const enconaborted = useSelector((state)=>state?.profile?.list_notification?.error?.code)

  const testError = useSelector((state)=>state?.profile?.list_notification?.error)
  const testMessage = useSelector((state)=>state?.profile?.list_notification?.error?.response?.data)
  const testResponse = useSelector((state)=>state?.profile?.list_notification?.error?.response)
  // console.log(testError,"error object");
  // console.log(testMessage,"error message");
  // console.log(testResponse,"error response");
  // console.log(invalid,"invalid");

//   useEffect(() => {
//     const handleContextmenu = e => {
//         e.preventDefault()
//     }
//     document.addEventListener('contextmenu', handleContextmenu)
//     return function cleanup() {
//         document.removeEventListener('contextmenu', handleContextmenu)
//     }
// }, [ ])
  
  useEffect(()=>{
    if(invalid==="Invalid token."){
      navigate("/signin")
      toast.info("You have been logged out")
      localStorage.removeItem("persist:kush.ai");
      localStorage.setItem("change",true)
      dispatch(resetStore())
      }
  },[invalid])

  useEffect(()=>{
    if(networkError===502||enconaborted==="ENCONNABORTED"){
      navigate('/servererror')
    }
  },[networkError,enconaborted])

  // useEffect(()=>{
  //   var hours = 10; // to clear the localStorage after 2 days
  //   // (if someone want to clear after 8hrs simply change hours=8)
  //   var now = new Date().getTime();
  //   var login_time = sessionStorage.getItem('login_time');
  //   if(token!==undefined){
  //     if (login_time == null) {
  //     sessionStorage.setItem('login_time', `${now}`)
  //     } else {
  //     // 1*60*1000  expire after 1 minute
  //     if(now-login_time > hours*60*60*1000) {
  //     toast.error('Session has expired !!!!!')
  //     setTimeout(()=>{
  //       localStorage.removeItem("persist:kush.ai");
  //       localStorage.setItem("change",true)
  //       dispatch(resetStore())
  //     },100)

  //   }
  //   }
  //   }else if(tokenSso!==undefined){
  //     if (login_time == null) {
  //     sessionStorage.setItem('login_time', `${now}`)
  //     } else {
  //     // 1*60*1000  expire after 1 minute
  //     if(now-login_time > hours*60*60*1000) {
  //     toast.error('Session has expired !!!!!')
  //     setTimeout(()=>{
  //       localStorage.removeItem("persist:kush.ai");
  //       localStorage.setItem("change",true)
  //       dispatch(resetStore())
  //     },100)

  //   }
  //   }
  //   }
  // },[token,tokenSso])
   
  
  return isAuthenticated() ? (
    <>
      <PageWrapper />
      <Outlet />
    </>
  ) : (
    <>{status?<Navigate to="/signin"/>:<Navigate to="/"/>}</>
    
  );
};

export default PrivateRoute;
