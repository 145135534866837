import React from "react";

function LikeIcon(props) {
  return (
    <span className="icon-wrap">
      <svg
        width="16"
        height="15"
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.3311 4.875H10.6649L10.8172 3.6375C11.0458 1.8 10.6649 0.3375 9.44604 0H9.33177C9.20583 0.0217719 9.08748 0.0744046 8.98764 0.153047C8.88779 0.231689 8.80966 0.333811 8.76044 0.45C8.76044 0.45 6.09422 6 4.95155 6H0.761777C0.559741 6 0.36598 6.07902 0.223119 6.21967C0.0802584 6.36032 0 6.55109 0 6.75V12.75C0 12.9489 0.0802584 13.1397 0.223119 13.2803C0.36598 13.421 0.559741 13.5 0.761777 13.5H5.82759C5.91014 13.4931 5.99324 13.5029 6.07182 13.5286C6.15041 13.5544 6.22283 13.5957 6.28466 13.65C6.8179 14.025 8.60808 15 9.52221 15H11.4267C13.6739 15 15.6164 13.5 15.8069 10.5375L15.9973 7.5C16.0136 7.15103 15.9557 6.80255 15.8275 6.47682C15.6993 6.15109 15.5035 5.85528 15.2526 5.60825C15.0017 5.36123 14.7012 5.16844 14.3703 5.04219C14.0395 4.91593 13.6856 4.85899 13.3311 4.875ZM1.52355 7.5H3.80889V12H1.52355V7.5ZM14.2833 10.425C14.131 12.375 13.0645 13.5 11.4267 13.5H9.52221C9.06515 13.4625 7.69395 12.7875 7.19879 12.45C6.80591 12.1529 6.32298 11.9944 5.82759 12H5.33244V7.5C6.05613 7.35 7.23688 6.675 9.33177 2.7C9.33641 2.96303 9.32368 3.22609 9.29368 3.4875L9.14132 4.725L8.95088 6.375H13.3311C13.4821 6.35789 13.635 6.37393 13.779 6.42198C13.9229 6.47003 14.0542 6.54889 14.1635 6.65288C14.2727 6.75687 14.3572 6.88342 14.4109 7.02341C14.4645 7.1634 14.486 7.31336 14.4738 7.4625L14.2833 10.425Z"
          fill={props?.iconColor}
        />
      </svg>
    </span>
  );
}

export default LikeIcon;
