import React, { useState, useEffect } from "react";
import ProfileDummy from "./../../../../assets/images/profile-dummy.png";
import background from "../../../../assets/images/profile-pic-bg.png";
import OrganisationImg from '../../../../assets/images/icons/organisation.svg';
import MailIcon from '../../../../assets/images/icons/mailicon.svg';
import JobIcon from '../../../../assets/images/icons/job-icon.svg';
import LocationIcon from '../../../../assets/images/icons/location.svg';
import {useSelector,useDispatch,shallowEqual} from 'react-redux'

function ProfilePic() {
    const state = useSelector((state)=>state?.profile?.userProfile?.response?.data?.data?.results[0])

    return (
        <div className="profile-inner-box">
            <div className="profile-top-bg" style={{ backgroundImage: `url(${background})` }}>
                &nbsp;
            </div>
            <div className="profile-stat-contentbox text-center">
                <div className="profile-page-img croped-img-wrap">
                    <img src={state?.profile_pic_path ? state?.profile_pic_path :ProfileDummy} />
                </div>
                <div className="profile-quick-details m-t-15">
                    <h3>{state?.first_name} {state?.last_name}</h3>
                    <div className="d-flex experts-pers-info-wrap">
                        <span className="experts-pers-info-icon"> <img src={MailIcon} alt="" /></span>
                        <span className="experts-pers-info-text">{state?.email}</span>
                    </div>
                    <div className="d-flex experts-pers-info-wrap">
                        <span className="experts-pers-info-icon"> <img src={OrganisationImg} alt="" /></span>
                        <span className="experts-pers-info-text">{state?.organisation_name}</span>
                    </div>
                    <div className="d-flex experts-pers-info-wrap">
                        <span className="experts-pers-info-icon"> <img src={JobIcon} alt="" /></span>
                        <span className="experts-pers-info-text">{state?.designation}</span>
                    </div>
  <div className="d-flex experts-pers-info-wrap">
                        <span className="experts-pers-info-icon"> <img src={LocationIcon} alt="" /></span>
                        <span className="experts-pers-info-text">{state?.country} - {state?.state} - {state?.city} </span>
                    </div>
                </div>
            </div>


        </div>
    );
};

export default ProfilePic;
