import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Logo from '../../../../assets/images/logo.png';
import FgtImg from '../../../../assets/images/forgotpwd-img.png';
import background from "../../../../assets/images/signin-left-bg.png";
import {_forgotPassword} from './../../../../shared/httpservice/apis'
import {Formik} from 'formik'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Helmet} from 'react-helmet'
// import './login.css';


function ForgotPassword() {
    const navigate = useNavigate()
    const handleSubmit =async (values) => {
        let formData = new FormData();
        formData.append('email', values.email);
        return _forgotPassword(formData).then((response)=>{
            toast.info("Please check your email for password reset link",{
                hideProgressBar: true,
            })
            }).catch((error)=>{
            toast.error("Email not registered",{
                hideProgressBar: true,
            })
        })             
       };

       const validate =(value)=>{
        const errors={}
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if(!value.email){
           errors.email="*please enter your email"
        }else if(!regex.test(value.email)){
           errors.email="*enter a valid email"
        }
        return errors
  
     }
    return (
        <>
        <Helmet>
            <title>
              DevDiscovery | Forgot password
            </title>
        </Helmet>
        <div className="auth-page-wrap">
            <div className="container">
                <div className="auth-container d-flex">
                    <div className="auth-left" style={{ backgroundImage: `url(${background})` }}>
                        <div className="auth-logo-wrap">
                            <img src={Logo} alt="" />
                        </div>
                        <div className="auth-signin-img">
                            <img src={FgtImg} alt="" />
                        </div>
                    </div>
                    <div className="auth-right">
                        <div className="auth-right-content">
                            <h3 className="sec-title m-b-60">Forgot Password</h3>
                            <Formik
                            initialValues={{
                                email:""
                            }}
                            validate={validate}
                            onSubmit={handleSubmit}
                            >
                                  {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors,
                            touched
                            }) => (
                            <Form className="w-100" onSubmit={handleSubmit}>
                                <div className="form-input-wrap m-b-25">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control type="text" placeholder="Allie Grater@gmail.com" className="input-item"
                                     name="email"
                                     value={values.email}
                                     onChange={handleChange}
                                    />
                                    {errors.email && touched.email ? <span style={{color:'red'}}>{errors.email}</span>:null}
                                </div>
                                <div className="button-wrap">
                                    <Button type="submit">Submit</Button>
                                </div>

                            </Form>
                             )}
                            </Formik>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ToastContainer/>
        </>
    );
}


export default ForgotPassword;
