import React, { useState } from "react";
import ArticleImg from "../../../../../../../../../../assets/images/small-img.jpg";
import Tippy from "@tippy.js/react";
import {_countryListUser} from '../../../../../../../../../../shared/httpservice/apis'
import "tippy.js/dist/tippy.css";
import {
  setChatCountry,
  setChatRegion,
  setChatTopic,
} from "../../../../../../../../services";
import {
  setSearchKey,
  setCurrentCategory,
  setDCountryKey,
  setDRegionKey,
  setDSectorKey,
  setDTopicKey,
  setDSourceKey,
  setDStartDateKey,
  setDEndDateKey,
  setDataCountryKey,
  setDataRegionKey,
  setDataSectorKey,
  setDataTopicKey,
  setDataSourceKey,
  setDataResourceKey,
  setEconCountryKey,
  setEconRegionKey,
  setEconSectorKey,
  setEconTopicKey,
  setEconSourceKey,
  setEconStartDateKey,
  setEconEndDateKey,
  setEvalCountryKey,
  setEvalRegionKey,
  setEvalSectorKey,
  setEvalTopicKey,
  setEvalSourceKey,
  setEvalStartDateKey,
  setEvalEndDateKey,
  setMulCountryKey,
  setMulRegionKey,
  setMulSectorKey,
  setMulTopicKey,
  setMulSourceKey,
  setMulStartDateKey,
  setMulEndDateKey,
  setOCountryKey,
  setORegionKey,
  setOSectorKey,
  setOTopicKey,
  setOSourceKey,
  setOStartDateKey,
  setOEndDateKey,
  setFCountryKey,
  setFRegionKey,
  setFSectorKey,
  setFTopicKey,
  setFSourceKey,
  setFStartDateKey,
  setFEndDateKey,
  setFilePathFn,
  setCurrentTabFn,
  setCountryKey,
  setRegionKey,
  setSectorKey,
  setTopicKey,
  setSourceKey,
} from "../../../../../../../../../results/services";
import {
  setDevOrgFilter,
  setDevOrgData,
  setDevOrgHandler,
  setDevCountryFilter,
  setDevCountryData,
  setDevCountryHandler,
  setDevRegionFilter,
  setDevRegionData,
  setDevRegionHandler,
  setDevSectorData,
  setDevSectorFilter,
  setDevSectorHandler,
  setDevTopicFilter,
  setDevTopicData,
  setDevTopicHandler,
  setDevSearchCountry,
  setDateTriggerDev,
  setDevEndDate,
  setDevStartDate,
} from "../../../../../../../../../results/components/devpubsresults/devpubsfiltersevices";
import {
  setDataOrgFilter,
  setDataOrgData,
  setDataOrgHandler,
  setDataCountryFilter,
  setDataCountryData,
  setDataCountryHandler,
  setDataRegionFilter,
  setDataRegionData,
  setDataRegionHandler,
  setDataSectorData,
  setDataSectorFilter,
  setDataSectorHandler,
  setDataTopicFilter,
  setDataTopicData,
  setDataTopicHandler,
  setDataResourceFilter,
  setDataResourceData,
  setDataResourceHandler,
  setDataSearchCountry,
} from "../../../../../../../../../results/components/dataresults/datafilterservices";
import {
  setEconOrgFilter,
  setEconOrgData,
  setEconOrgHandler,
  setEconCountryFilter,
  setEconCountryData,
  setEconCountryHandler,
  setEconRegionFilter,
  setEconRegionData,
  setEconRegionHandler,
  setEconSectorData,
  setEconSectorFilter,
  setEconSectorHandler,
  setEconTopicFilter,
  setEconTopicData,
  setEconTopicHandler,
  setEconIntialCountry,
  setEconIgnoreQuery,
  setDateTriggerEcon,
  setEconEndDate,
  setEconStartDate,
} from "../../../../../../../../../results/components/econpubsresults/econfilterservices";
import {
  setEvalOrgFilter,
  setEvalOrgData,
  setEvalOrgHandler,
  setEvalCountryFilter,
  setEvalCountryData,
  setEvalCountryHandler,
  setEvalRegionFilter,
  setEvalRegionData,
  setEvalRegionHandler,
  setEvalSectorData,
  setEvalSectorFilter,
  setEvalSectorHandler,
  setEvalTopicFilter,
  setEvalTopicData,
  setEvalTopicHandler,
  setEvalSearchCountry,
  setEvalEndDate,
  setEvalStartDate,
} from "../../../../../../../../../results/components/evaluationsresults/evaluationfilterservices";
import {
  setMulOrgFilter,
  setMulOrgData,
  setMulOrgHandler,
  setMulCountryFilter,
  setMulCountryData,
  setMulCountryHandler,
  setMulRegionFilter,
  setMulRegionData,
  setMulRegionHandler,
  setMulSectorData,
  setMulSectorFilter,
  setMulSectorHandler,
  setMulTopicFilter,
  setMulTopicData,
  setMulTopicHandler,
  setMulSearchCountry,
  setDateTriggerMul,
  setMulEndDate,
  setMulStartDate,
} from "../../../../../../../../../results/components/multimediaresults/multimediafilterservices";
import {
  setOtherOrgFilter,
  setOtherOrgData,
  setOtherOrgHandler,
  setOtherCountryFilter,
  setOtherCountryData,
  setOtherCountryHandler,
  setOtherRegionFilter,
  setOtherRegionData,
  setOtherRegionHandler,
  setOtherSectorData,
  setOtherSectorFilter,
  setOtherSectorHandler,
  setOtherTopicFilter,
  setOtherTopicData,
  setOtherTopicHandler,
  setOtherSearchCountry,
  setOtherEndDate,
  setOtherStartDate,
} from "../../../../../../../../../results/components/otherpubsresults/otherpubsfilterservices";
import {
  setExpertsOrgFilter,
  setExpertsOrgData,
  setExpertsOrgHandler,
  setExpertsCountryFilter,
  setExpertsCountryData,
  setExpertsCountryHandler,
  setExpertsRegionFilter,
  setExpertsRegionData,
  setExpertsRegionHandler,
  setExpertsSectorData,
  setExpertsSectorFilter,
  setExpertsSectorHandler,
  setExpertsTopicFilter,
  setExpertsTopicData,
  setExpertsTopicHandler,
  setExpertsSearchCountry,
  setExpertsIgnoreQuery,
} from "../../../../../../../../../results/components/expertsresults/expertsfilterservices";
import {
  setFinanceOrgFilter,
  setFinanceOrgData,
  setFinanceOrgHandler,
  setFinanceCountryFilter,
  setFinanceCountryData,
  setFinanceCountryHandler,
  setFinanceRegionFilter,
  setFinanceRegionData,
  setFinanceRegionHandler,
  setFinanceSectorData,
  setFinanceSectorFilter,
  setFinanceSectorHandler,
  setFinanceTopicFilter,
  setFinanceTopicData,
  setFinanceTopicHandler,
  setFinanceSearchCountry,
  setFinanceStartDate,
  setFinanceEndDate,
} from "../../../../../../../../../results/filterServices";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
function RelatedArticles(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const RelatedArticles = props?.results?.slice(0, 3);
  const country = props?.country?.join("^");
  const region = props?.region?.join("^");
  const topic = props?.major_concepts?.join("^");
  const org = props?.org?.join("^");


  const clearDevFilters = () => {
    dispatch(setDSourceKey(""));
    dispatch(setDCountryKey(""));
    dispatch(setDRegionKey(""));
    dispatch(setDTopicKey(""));
    dispatch(setDSectorKey(""));
    dispatch(setDStartDateKey(""));
    dispatch(setDEndDateKey(""));
    dispatch(setDevCountryData([]));
    dispatch(setDevOrgData([]));
    dispatch(setDevCountryHandler([]));
    dispatch(setDevOrgHandler([]));
    dispatch(setDevRegionData([]));
    dispatch(setDevRegionHandler([]));
    dispatch(setDevSectorHandler([]));
    dispatch(setDevSectorData([]));
    dispatch(setDevTopicData([]));
    dispatch(setDevTopicHandler([]));
    dispatch(setDevCountryFilter([]));
    dispatch(setDevOrgFilter([]));
    dispatch(setDevRegionFilter([]));
    dispatch(setDevSectorFilter([]));
    dispatch(setDevTopicFilter([]));
    dispatch(setDevSearchCountry([]));
    dispatch(setDateTriggerDev(true));
    dispatch(setDevStartDate(null));
    dispatch(setDevEndDate(null));
  };

  const clearDataFilters = () => {
    dispatch(setDataSourceKey(""));
    dispatch(setDataCountryKey(""));
    dispatch(setDataRegionKey(""));
    dispatch(setDataTopicKey(""));
    dispatch(setDataSectorKey(""));
    dispatch(setDataResourceKey(""));
    dispatch(setDataOrgData([]));
    dispatch(setDataCountryHandler([]));
    dispatch(setDataOrgHandler([]));
    dispatch(setDataRegionData([]));
    dispatch(setDataRegionHandler([]));
    dispatch(setDataSectorHandler([]));
    dispatch(setDataSectorData([]));
    dispatch(setDataTopicData([]));
    dispatch(setDataTopicHandler([]));
    dispatch(setDataCountryFilter([]));
    dispatch(setDataOrgFilter([]));
    dispatch(setDataRegionFilter([]));
    dispatch(setDataSectorFilter([]));
    dispatch(setDataTopicFilter([]));
    dispatch(setDataCountryData([]));
    dispatch(setDataResourceFilter([]));
    dispatch(setDataResourceData([]));
    dispatch(setDataResourceHandler([]));
    dispatch(setDataSearchCountry([]));
  };

  const clearEconFilters = () => {
    dispatch(setEconSourceKey(""));
    dispatch(setEconCountryKey(""));
    dispatch(setEconRegionKey(""));
    dispatch(setEconTopicKey(""));
    dispatch(setEconSectorKey(""));
    dispatch(setEconStartDateKey(""));
    dispatch(setEconEndDateKey(""));
    dispatch(setEconCountryData([]));
    dispatch(setEconOrgData([]));
    dispatch(setEconCountryHandler([]));
    dispatch(setEconOrgHandler([]));
    dispatch(setEconRegionData([]));
    dispatch(setEconRegionHandler([]));
    dispatch(setEconSectorHandler([]));
    dispatch(setEconSectorData([]));
    dispatch(setEconTopicData([]));
    dispatch(setEconTopicHandler([]));
    dispatch(setEconCountryFilter([]));
    dispatch(setEconOrgFilter([]));
    dispatch(setEconRegionFilter([]));
    dispatch(setEconSectorFilter([]));
    dispatch(setEconTopicFilter([]));
    dispatch(setEconIntialCountry([]));
    dispatch(setEconIgnoreQuery(false));
    dispatch(setDateTriggerEcon(true));
    dispatch(setEconStartDate(null));
    dispatch(setEconEndDate(null));
  };

  const clearEvalFilters = () => {
    dispatch(setEvalSourceKey(""));
    dispatch(setEvalCountryKey(""));
    dispatch(setEvalRegionKey(""));
    dispatch(setEvalTopicKey(""));
    dispatch(setEvalSectorKey(""));
    dispatch(setEvalStartDateKey(""));
    dispatch(setEvalEndDateKey(""));
    dispatch(setEvalCountryData([]));
    dispatch(setEvalOrgData([]));
    dispatch(setEvalCountryHandler([]));
    dispatch(setEvalOrgHandler([]));
    dispatch(setEvalRegionData([]));
    dispatch(setEvalRegionHandler([]));
    dispatch(setEvalSectorHandler([]));
    dispatch(setEvalSectorData([]));
    dispatch(setEvalTopicData([]));
    dispatch(setEvalTopicHandler([]));
    dispatch(setEvalCountryFilter([]));
    dispatch(setEvalOrgFilter([]));
    dispatch(setEvalRegionFilter([]));
    dispatch(setEvalSectorFilter([]));
    dispatch(setEvalTopicFilter([]));
    dispatch(setEvalSearchCountry([]));
    dispatch(setDateTriggerEcon(true));
    dispatch(setEvalStartDate(null));
    dispatch(setEvalEndDate(null));
  };

  const clearMulFilters = () => {
    dispatch(setMulSourceKey(""));
    dispatch(setMulCountryKey(""));
    dispatch(setMulRegionKey(""));
    dispatch(setMulTopicKey(""));
    dispatch(setMulSectorKey(""));
    dispatch(setMulStartDateKey(""));
    dispatch(setMulEndDateKey(""));
    dispatch(setMulCountryData([]));
    dispatch(setMulOrgData([]));
    dispatch(setMulCountryHandler([]));
    dispatch(setMulOrgHandler([]));
    dispatch(setMulRegionData([]));
    dispatch(setMulRegionHandler([]));
    dispatch(setMulSectorHandler([]));
    dispatch(setMulSectorData([]));
    dispatch(setMulTopicData([]));
    dispatch(setMulTopicHandler([]));
    dispatch(setMulCountryFilter([]));
    dispatch(setMulOrgFilter([]));
    dispatch(setMulRegionFilter([]));
    dispatch(setMulSectorFilter([]));
    dispatch(setMulTopicFilter([]));
    dispatch(setMulSearchCountry([]));
    dispatch(setDateTriggerMul(true));
    dispatch(setMulStartDate(null));
    dispatch(setMulEndDate(null));
  };

  const clearOtherFilters = () => {
    dispatch(setOSourceKey(""));
    dispatch(setOCountryKey(""));
    dispatch(setORegionKey(""));
    dispatch(setOTopicKey(""));
    dispatch(setOSectorKey(""));
    dispatch(setOStartDateKey(""));
    dispatch(setOEndDateKey(""));
    dispatch(setOtherCountryData([]));
    dispatch(setOtherOrgData([]));
    dispatch(setOtherCountryHandler([]));
    dispatch(setOtherOrgHandler([]));
    dispatch(setOtherRegionData([]));
    dispatch(setOtherRegionHandler([]));
    dispatch(setOtherSectorHandler([]));
    dispatch(setOtherSectorData([]));
    dispatch(setOtherTopicData([]));
    dispatch(setOtherTopicHandler([]));
    dispatch(setOtherCountryFilter([]));
    dispatch(setOtherOrgFilter([]));
    dispatch(setOtherRegionFilter([]));
    dispatch(setOtherSectorFilter([]));
    dispatch(setOtherTopicFilter([]));
    dispatch(setOtherSearchCountry([]));
    dispatch(setDateTriggerMul(true));
    dispatch(setOtherStartDate(null));
    dispatch(setOtherEndDate(null));
  };

  const clearFinanceFilters = () => {
    dispatch(setFSourceKey(""));
    dispatch(setFCountryKey(""));
    dispatch(setFRegionKey(""));
    dispatch(setFTopicKey(""));
    dispatch(setFSectorKey(""));
    dispatch(setFStartDateKey(""));
    dispatch(setFEndDateKey(""));
    dispatch(setFinanceCountryData([]));
    dispatch(setFinanceOrgData([]));
    dispatch(setFinanceCountryHandler([]));
    dispatch(setFinanceOrgHandler([]));
    dispatch(setFinanceRegionData([]));
    dispatch(setFinanceRegionHandler([]));
    dispatch(setFinanceSectorHandler([]));
    dispatch(setFinanceSectorData([]));
    dispatch(setFinanceTopicData([]));
    dispatch(setFinanceTopicHandler([]));
    dispatch(setFinanceCountryFilter([]));
    dispatch(setFinanceOrgFilter([]));
    dispatch(setFinanceRegionFilter([]));
    dispatch(setFinanceSectorFilter([]));
    dispatch(setFinanceTopicFilter([]));
    dispatch(setFinanceSearchCountry([]));
    dispatch(setFinanceStartDate(null));
    dispatch(setFinanceEndDate(null));
  };

  const clearExpertsFilters = () => {
    dispatch(setSourceKey(""));
    dispatch(setCountryKey(""));
    dispatch(setRegionKey(""));
    dispatch(setTopicKey(""));
    dispatch(setSectorKey(""));
    dispatch(setExpertsIgnoreQuery(false));
    dispatch(setExpertsOrgFilter([]));
    dispatch(setExpertsOrgData([]));
    dispatch(setExpertsOrgHandler([]));
    dispatch(setExpertsCountryFilter([]));
    dispatch(setExpertsCountryData([]));
    dispatch(setExpertsCountryHandler([]));
    dispatch(setExpertsRegionFilter([]));
    dispatch(setExpertsRegionData([]));
    dispatch(setExpertsRegionHandler([]));
    dispatch(setExpertsSectorFilter([]));
    dispatch(setExpertsSectorData([]));
    dispatch(setExpertsSectorHandler([]));
    dispatch(setExpertsTopicFilter([]));
    dispatch(setExpertsTopicData([]));
    dispatch(setExpertsTopicHandler([]));
    dispatch(setExpertsSearchCountry([]));
  };
  const [addCountries,setAddCountries] = useState([])
  const [filterCountries,setFilterCountries] = useState([])

  const listCountry = async () => {
    const response = await _countryListUser();
    setAddCountries(response?.data?.data?.element);
  };
  useEffect(()=>{
    listCountry()
  },[])
  useEffect(()=>{
    if(addCountries?.length>0&&Array.isArray(props?.country)){
      const country = addCountries.filter((item)=>props?.country?.includes(item?.name))
      const convertedArray = country?.map(item => ({
        label: item.name,
        value: item.code
      }));
      setFilterCountries(convertedArray)
    }
  },[addCountries])
  const redirect = () => {
    dispatch(setSearchKey(props?.query));
    clearExpertsFilters();
    clearDevFilters();
    clearDataFilters();
    clearEconFilters();
    clearEvalFilters();
    clearMulFilters();
    clearOtherFilters();
    clearFinanceFilters();
    if (
      props.personality === "financing" 
    ) {
      dispatch(setCurrentCategory("financing"));
      if (
        Array.isArray(props?.org) &&
        Array.isArray(props?.facets?.org_name)
      ) {
        dispatch(setFSourceKey(props?.org?.join("^")))
        dispatch(setFinanceOrgFilter( props?.facets?.org_name?.map((org) =>
        props?.org?.includes(org)
      )))
        dispatch(setFinanceOrgData(props?.facets?.org_name))
        dispatch(setFinanceOrgHandler(props?.org))
      }
      if (
        Array.isArray(props?.region) &&
        Array.isArray(props?.facets?.region)
      ) {
        dispatch(setFRegionKey(props?.region?.join("^")));
        dispatch(
          setFinanceRegionFilter(
            props?.facets?.region?.map((region) =>
              props?.region?.includes(region)
            )
          )
        );
        dispatch(setFinanceRegionData(props?.facets?.region));
        dispatch(setFinanceRegionHandler(props?.region));
      }
      if (
        Array.isArray(props?.country) &&
        Array.isArray(props?.facets?.country)
      ) {
        dispatch(setFCountryKey(props?.country?.join("^")));
        dispatch(
          setFinanceCountryFilter(
            props?.facets?.country?.map((country) =>
              props?.country?.includes(country)
            )
          )
        );
        dispatch(setFinanceCountryData(props?.facets?.country));
        dispatch(setFinanceCountryHandler(props?.country));
      }
      if (
        Array.isArray(props?.major_concepts) &&
        Array.isArray(props?.facets?.topic)
      ) {
        dispatch(setFTopicKey(props?.major_concepts?.join("^")))
        dispatch(setFinanceTopicFilter( props?.facets?.topic?.map((topic) =>
        props?.major_concepts?.includes(topic)
      )))
        dispatch(setFinanceTopicData(props?.facets?.topic))
        dispatch(setFinanceTopicHandler(props?.major_concepts))
      }
    } else if (props.personality === "economy") {
      dispatch(setCurrentCategory("econpubs"));
      if (
        Array.isArray(props?.org) &&
        Array.isArray(props?.facets?.org_name)
      ) {
        dispatch(setEconSourceKey(props?.org?.join("^")))
        dispatch(setEconOrgFilter( props?.facets?.org_name?.map((org) =>
        props?.org?.includes(org)
      )))
        dispatch(setEconOrgData(props?.facets?.org_name))
        dispatch(setEconOrgHandler(props?.org))
      }
      if(filterCountries?.length>0){
        dispatch(setEconIntialCountry(filterCountries))
        dispatch(setEconCountryKey(props?.country?.join("^")))
      }
      if (
        Array.isArray(props?.major_concepts) &&
        Array.isArray(props?.facets?.topic)
      ) {
        dispatch(setEconTopicKey(props?.major_concepts?.join("^")))
        dispatch(setEconTopicFilter( props?.facets?.topic?.map((topic) =>
        props?.major_concepts?.includes(topic)
      )))
        dispatch(setEconTopicData(props?.facets?.topic))
        dispatch(setEconTopicHandler(props?.major_concepts))
      }
      if (
        Array.isArray(props?.region) &&
        Array.isArray(props?.facets?.region)
      ) {
        dispatch(setEconRegionKey(props?.region?.join("^")));
        dispatch(
          setEconRegionFilter(
            props?.facets?.region?.map((region) =>
              props?.region?.includes(region)
            )
          )
        );
        dispatch(setEconRegionData(props?.facets?.region));
        dispatch(setEconRegionHandler(props?.region));
      }

    } else if (props.personality === "sector"||
    props.personality === "devpubs" ||
    props.personality === "") {
      dispatch(setCurrentCategory("devpubs"));
      if (
        Array.isArray(props?.org) &&
        Array.isArray(props?.facets?.org_name)
      ) {
        dispatch(setDSourceKey(props?.org?.join("^")))
        dispatch(setDevOrgFilter( props?.facets?.org_name?.map((org) =>
        props?.org?.includes(org)
      )))
        dispatch(setDevOrgData(props?.facets?.org_name))
        dispatch(setDevOrgHandler(props?.org))
      }
      if (
        Array.isArray(props?.region) &&
        Array.isArray(props?.facets?.region)
      ) {
        dispatch(setDRegionKey(props?.region?.join("^")));
        dispatch(
          setDevRegionFilter(
            props?.facets?.region?.map((region) =>
              props?.region?.includes(region)
            )
          )
        );
        dispatch(setDevRegionData(props?.facets?.region));
        dispatch(setDevRegionHandler(props?.region));
      }
      if (
        Array.isArray(props?.country) &&
        Array.isArray(props?.facets?.country)
      ) {
        dispatch(setDCountryKey(props?.country?.join("^")));
        dispatch(
          setDevCountryFilter(
            props?.facets?.country?.map((country) =>
              props?.country?.includes(country)
            )
          )
        );
        dispatch(setDevCountryData(props?.facets?.country));
        dispatch(setDevCountryHandler(props?.country));
      }
      if (
        Array.isArray(props?.major_concepts) &&
        Array.isArray(props?.facets?.topic)
      ) {
        dispatch(setDTopicKey(props?.major_concepts?.join("^")))
        dispatch(setDevTopicFilter( props?.facets?.topic?.map((topic) =>
        props?.major_concepts?.includes(topic)
      )))
        dispatch(setDevTopicData(props?.facets?.topic))
        dispatch(setDevTopicHandler(props?.major_concepts))
      }
    } else if (props.personality === "factoids") {
      dispatch(setCurrentCategory("news"));
    }
    dispatch(setChatCountry(country));
    dispatch(setChatRegion(region));
    dispatch(setChatTopic(topic));
    navigate("/results");
  };

  return (
    <>
      <div className="output-relatedarticle-wrap p-relative">
        <h4 className="m-b-15">
          <a target="_blank" className="article-related-title">
            Articles related to{" "}
            <span>
              "
              {props?.query?.length > 75
                ? props?.query?.substring(0, 75) + "..."
                : props?.query}
              "
            </span>
          </a>
        </h4>
        {props?.personality !== "factoids" ? (
          <div className="rel-articles-row d-flex">
            {RelatedArticles &&
              RelatedArticles?.map((RelatedArticle) => (
                <div key={RelatedArticle?.title} className="rel-article-item d-flex flex-column justify-content-between">
                  <div className="rel-article-conten-row d-flex">
                    {/* <div className="rel-article-img">
                <img src={ArticleImg} alt="" />
              </div> */}
                    <div className="rel-article-text">
                      <Tippy content={RelatedArticle?.title}>
                        <a href={RelatedArticle?.link_html} target="_blank">
                          {RelatedArticle?.title?.length > 65
                            ? RelatedArticle?.title?.substring(0, 65) + "..."
                            : RelatedArticle?.title}
                        </a>
                      </Tippy>
                    </div>
                  </div>
                  <div className="d-flex ai-relatedarticle-label-wrap align-items-center flex-wrap">
                    <Tippy content={RelatedArticle?.org_name}>
                      <label className="ai-related-article-bubble">
                        {RelatedArticle?.org_name?.length > 36
                          ? RelatedArticle?.org_name.substring(0, 35) + "..."
                          : RelatedArticle?.org_name}
                      </label>
                    </Tippy>
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div className="rel-articles-row d-flex">
            {RelatedArticles &&
              RelatedArticles?.map((RelatedArticle) => (
                <div className="rel-article-item">
                  <div className="rel-article-conten-row d-flex">
                    {RelatedArticle?.thunbnailUrl ? (
                      <div className="rel-article-img">
                        <img src={RelatedArticle?.thunbnailUrl} alt="" />
                      </div>
                    ) : null}

                    <div className="rel-article-text">
                      <a href={RelatedArticle?.url} target="_blank">
                        {RelatedArticle?.name}
                      </a>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}

        <div className="rel-article-footer text-end">
          <span onClick={redirect} className="more-article">
            {props?.count - 3} More
          </span>
        </div>
      </div>
    </>
  );
}

export default RelatedArticles;
