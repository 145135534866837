import React, { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Logo from "../../../../assets/images/logo.png";
import UploadIcons from "../../../../assets/images/icons/uploadicon.png";
import TickIcon from "../../../../assets/images/icons/tick-icon.png";
import Eye from "../../../../assets/images/icons/eye.png";
import HiddenEye from "../../../../assets/images/icons/hidden.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
// import ProfilePic from "./components/profilepic/container";
import Cropcontent from "./components/profilepic/container/cropcontent";
import background from "../../../../assets/images/signup-left-bg.png";
import { Modal, Alert } from "react-bootstrap";
import tickIcon from "../../../../assets/images/icons/tick-icon.png";
import Tippy from "@tippy.js/react";
import "tippy.js/dist/tippy.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Compress from "react-image-file-resizer";
import {Helmet} from 'react-helmet'

import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { verifyInvite } from "../../services";
import {
  _ssoLogin,
  _ssoLoginCallback,
  _tokenUser,
  _countryListUser,
  _topicListUser,
  _regionListUser,
  _profileUpdate,
  _profileRegistration,
  _signUpVerify,
} from "~shared/httpservice/apis";
import { Formik, useFormik, ErrorMessage, Field } from "formik";
import {setProfileImage} from './../../../results/services'

// import './login.css';
import ProfileDummy from "./../../../../assets/images/profile-dummy.png";
function UserSignUp() {
  const navigate = useNavigate();
  const [fileName, setFileName] = useState();
  const [imageFile, setImageFile] = useState();
  const [files, setFiles] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };
  const [images, setImages] = useState(ProfileDummy);
  const [sho, setSho] = useState(false);
  const handleClos = () => setSho(false);
  const handleSho = () => setSho(true);
  const queryString = location.search;
  const params = new URLSearchParams(queryString);
  const codeVar = params?.get("code");
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState([]);
  const [country, setCountry] = useState([]);
  const [sectors, setSector] = useState([]);
  const [topic, setTopic] = useState([]);
  const [region, setRegion] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [upload, setUpload] = useState("Upload files");
  const [passwordShown, setPasswordShown] = useState(false);
  const [cpasswordShown, setCPasswordShown] = useState(false);
  const [fileSize,setFileSize] = useState()
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const toggleCPassword = () => {
    setCPasswordShown(!cpasswordShown);
  };
  // const states = useSelector(
  //   (state) => state?.auth?.verify_invite?.response?.data?.data
  // );
  // console.log(states,"test");
  const invite_state = useSelector((state) => state?.auth?.verify_invite);
  const verify_invite_ref = useRef(invite_state);
  verify_invite_ref.current = invite_state;
  const [states,setStates] = useState(null)
  const signUpVerify = async () => {
    // console.log(verify_invite_ref?.current,"error log");
    // if (params?.get("code")?.length) {
    //   let code = params?.get("code");
    //   dispatch(verifyInvite(code)).then(() => {
    //     if (verify_invite_ref?.current?.hasError) {
    //       navigate("/linkexpired");
    //     }
    //   });
    // }
    if(params?.get("code")?.length){
      let code = params?.get("code");
      return _signUpVerify(code).then((response)=>{
        // console.log(response?.data?.data);
        setStates(response?.data?.data)
      }).catch((error)=>{
        if(error?.response?.status===404){
          navigate('/invalidlink')
        }else if(error?.response?.status===400){
          navigate("/linkexpired")
        }
      })
    }
  };
  var userEmail = localStorage.getItem("useremail");
  var orgname = localStorage.getItem("orgname");
  var orgId = localStorage.getItem("org");
  const token1 = localStorage.getItem("token");
  const token = useSelector(
    (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token
  );
  const listCountry = () => {
    return _countryListUser().then((response) => {
      setCountry(response?.data?.data?.element);
    });
  };
  const listSectors = () => {
    return _tokenUser().then((response) => {
      setSector(response?.data?.data?.element);
    });
  };
  const topicList = () => {
    return _topicListUser().then((response) => {
      setTopic(response?.data?.data?.element);
    });
  };
  const regionList = async () => {
    return _regionListUser().then((response) => {
      setRegion(response?.data?.data?.element);
    });
  };
  useEffect(() => {
    if (codeVar) signUpVerify();
  }, [codeVar]);

  useEffect(() => {
    listCountry();
  }, [1]);
  useEffect(() => {
    topicList(), listSectors(), regionList();
  }, [1]);

  const user = useSelector(
    (state) => state?.auth?.sso_login_callback?.response?.data?.data,
    shallowEqual
  );
  const userId = user?.user_id;
  useEffect(() => {
    if (user) setUserDetails(user);
  }, [user]);

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            className="react-multi-select-item"
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const sectorOptions = sectors.map((item) => ({
    value: item.id,
    label: item.name,
  }));
  const topics = topic.map((item) => ({ value: item.id, label: item.name }));
  const regions = region.map((item) => ({ value: item.id, label: item.name }));
  const cou = country.map((item) => ({ value: item.code, label: item.name }));

  const countries = country.map((item, i) => {
    return (
      <option key={i} value={item.code}>
        {item.name}
      </option>
    );
  });
  const [select, setSelect] = useState([]);
  const [focus, setFocus] = useState([]);
  const newFocus = focus.map((item) => ({ value: item.value }));
  var focusValues = newFocus.map((i) => {
    return i.value;
  });
  const [reg, setReg] = useState([]);
  const newReg = reg.map((item) => ({ value: item.value }));
  var regionValues = newReg.map((i) => {
    return i.value;
  });
  const newSector = select.map((item) => ({ value: item.value }));
  var sectorValues = newSector.map((i) => {
    return i.value;
  });
  const [mulcou, setMulcou] = useState([]);
  const newMulcou = mulcou.map((item) => ({ value: item.value }));
  var countryValues = newMulcou.map((i) => {
    return i.value;
  });
  function b64toBlob(dataURI) {
    var byteString = atob(dataURI.split(",")[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: "image/jpeg" });
  }
  useEffect(() => {
    if (imageFile !== undefined) {
      const file = new File([imageFile], "filename.png", {
        type: imageFile.type,
        lastModified: new Date().getTime(),
      });
      Compress.imageFileResizer(
        file, // the file from input
        480, // width
        480, // height
        "JPEG", // compress format WEBP, JPEG, PNG
        70, // quality
        0, // rotation
        (uri) => {
          // You upload logic goes here
          var blob = b64toBlob(uri)
          const files = new File([blob], "filename.png", {
            type: "image/jpeg",
            lastModified: new Date().getTime(),
          });
          setFiles(files)
        },
        "bolb" // blob or base64 default base64
      );
    }
  }, [imageFile]);

  const [sub, setSub] = useState(false);
  const [sectorError, setSectorError] = useState("");
  const [focusError, setFocusError] = useState("");
  const [regionError, setRegionError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [checkedError, setCheckedError] = useState("")
  const [resumeError,setResumeError] = useState("")
  const onSignUp = () => {
    if (select.length === 0) {
      setSectorError("*required");
    }

    if (focus.length === 0) {
      setFocusError("*required");
    }
    if (reg.length === 0) {
      setRegionError("*required");
    }
    if (mulcou.length === 0) {
      setCountryError("*required");
    }
    if (termsChecked[0] === false) {
      setCheckedError("*required")
    }
    if(fileSize!==undefined&&fileSize>4000000){
      setResumeError("File size greater than 4 mb")

    }
  };
  useEffect(()=>{
    setResumeError("")
    if(fileSize!==undefined&&fileSize>4000000){
      setResumeError("File size greater than 4 mb")
    }
  },[fileSize])
  useEffect(() => {
    if (termsChecked[0] === true) {
      setCheckedError("")
    }
  })
  useEffect(() => {
    setSectorError("");
    if (select.length === 0 && sub) {
      setSectorError("*required");
    }
  }, [select]);

  useEffect(() => {
    setFocusError("");
    if (focus.length === 0 && sub) {
      setFocusError("*required");
    }
  }, [focus]);
  useEffect(() => {
    setRegionError("");
    if (reg.length === 0 && sub) {
      setRegionError("*required");
    }
  }, [reg]);

  useEffect(() => {
    setCountryError("");
    if (mulcou.length === 0 && sub) {
      setCountryError("*required");
    }
  }, [mulcou]);

  const termsCheck = ["I agree to the"]
  const [termsChecked, setTermsChecked] = useState([false])
  const handleOnChangeTerms = (position) => {
    const updatedCheckedState = termsChecked.map((item, index) =>
      index === position ? !item : item
    );
    setTermsChecked(updatedCheckedState);
  }

  const onSubmit = async (values) => {
    const form = new FormData();
    form.append("username", formik?.values?.country);
    form.append("first_name", formik?.values?.firstName);
    form.append("last_name", formik?.values?.lastName);
    form.append("email", formik?.values?.email);
    form.append("country", formik?.values?.country);
    form.append("state", formik?.values?.state);
    form.append("organisation", states?.organisation_id);
    form.append("designation", formik?.values?.jobTitle);
    form.append("resume_path", formik?.values?.resume || "");
    form.append("linkedin_resume",values?.linkedin)
    sectorValues.forEach((sectorValue) =>
      form.append("pref_sector", sectorValue)
    );
    countryValues.forEach((countryValue) =>
      form.append("pref_country", countryValue)
    );
    focusValues.forEach((focusValue) => form.append("pref_topic", focusValue));
    regionValues.forEach((regionValue) =>
      form.append("pref_region", regionValue)
    );
    form.append("is_profile_set", true);
    {images!==null&&images!==ProfileDummy&&
      form.append("profile_pic_path", files || "");
    }
    // {images===null||images===ProfileDummy&&
    //   form.append("profile_pic_path", "");
    // }
    
    form.append("password", formik?.values?.password);
    form.append("city", formik?.values?.city);
    form.append("username", formik?.values?.email);
    if (!sectorError && !focusError && !regionError && !countryError && termsChecked[0] === true &&!resumeError) {
      return _profileRegistration(form).then((response) => {
        handleSho();
        setTimeout(() => {
          navigate("/signin");
        }, 3000);
      });
    }
  };

  const validate = (values) => {
    const errors = {};
    const regex =
      /(ftp|http|https):\/\/?(?:www\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    const mediumRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[+!@#$%^&*])(?=.{8,})"
    );
    const nameRegex = new RegExp("^[a-zA-Z 0-9]*$");
    if (!values.firstName) {
      errors.firstName = "*required";
    } else if (!nameRegex.test(values.firstName)) {
      errors.firstName = "*No special characters";
    }
    if (!values.lastName) {
      errors.lastName = "*required";
    } else if (!nameRegex.test(values.lastName)) {
      errors.lastName = "* No special characters";
    }
    if (!values.country) {
      errors.country = "*required";
    }
    if (!values.state) {
      errors.state = "*required";
    }
    if (!values.city) {
      errors.city = "*required";
    }
    if (!values.password) {
      errors.password = "*required";
    } else if (!mediumRegex.test(values.password)) {
      errors.password =
        "*password must contain atleast 8 characters and must include special characters,uppercases and numbers";
    }
    if (!values.cpassword) {
      errors.cpassword = "*required";
    } else if (!mediumRegex.test(values.cpassword)) {
      errors.cpassword =
        "*password must contain atleast 8 characters and must include special characters,uppercases and numbers";
    }
    if (values.password !== values.cpassword) {
      errors.password = "*password doesnt match";
      errors.cpassword = "*password doesnt match";
    }
    if(!values?.jobTitle){
      errors.jobTitle = "*required"
    }
    if (values.linkedin) {
      if (!regex.test(values.linkedin)) {
        errors.linkedin = "*enter a valid link";
      }
    }
    if(fileSize&&fileSize>4000000){
      errors.resume = "*file size greater than 5 mb"
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      ...userInfo,
      ...states,
      userEmail,
      orgname,
      firstName: "",
      lastName: "",
      country: "",
      state: "",
      city: "",
      orgName: states?.organisation_name,
      jobTitle: "",
      focusArea: [],
      countries: [],
      region: [],
      resume: null,
      linkedin: "",
      sector: "",
      email: states?.email,
      profile: null,
      password: "",
      cpassword: "",
    },
    onSubmit: onSubmit,
    validate,
    enableReinitialize: true,
  });

  const deleteProfileImage =()=>{
    setImages(ProfileDummy)
    // setImage(null)
    // dispatch(setProfileImage("delete"))
  }
  const [image,setImage] = useState()
  const [clicked,setClicked] = useState(false)
    // console.log(image,"image");
    const onChange = (e) => {
      setImage(null)
      e.preventDefault();
      let files;
      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
      }
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(files[0]);
      setClicked(!clicked)
    };
    useEffect(()=>{
      if(image&&image!==undefined){
        setShow(true)
      }
    },[image])

  return (
    <div className="auth-page-wrap signup-page-wrap">
      <Helmet>
        <title>
        DevDiscovery | User registration
        </title>
      </Helmet>
      <Container>
        <div className="auth-container d-flex">
          <div
            className="auth-signup-left"
            style={{ backgroundImage: `url(${background})` }}
          >
            <div className="signup-logo-wrap">
              <img src={Logo} alt="" />
            </div>
            <div className="profile-stat-box">
              <div className="profile-stat-contentbox d-flex">
                <div className="profile-img croped-img-wrap">
                  <img src={images} />
                  {/* <button
                    className="btn btn-white-outline m-t-20"
                    onClick={handleShow}
                  >
                    Upload Photo
                  </button> */}
                    <input
                            className="btn btn-outline-primary upload-btn m-t-20 d-none"
                            type="file"
                            id="imageUpload"
                            accept="image/*"
                            onChange={onChange}
                            onClick={(event) => {
                              event.target.value = null;
                            }}
                        />
                            
                            <label htmlFor="imageUpload" className="btn btn-outline-primary upload-btn m-t-20">
                            Upload Photo
                            </label>
                  {images!==null&&images!==ProfileDummy&&
                    <button className="btn btn-outline-primary upload-btn m-t-20" onClick={()=>{
                        deleteProfileImage()
                    }}>
                        Delete Photo
                    </button>
                    }
                </div>

              </div>
              <Modal
                show={show}
                onHide={handleClose}
                className="cropModal common-modal"
              >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  <Cropcontent
                    onHide={handleClose}
                    setImages={setImages}
                    setShow={setShow}
                    setImageFile={setImageFile}
                    proImage = {image}
                  />
                </Modal.Body>
              </Modal>
            </div>
          </div>
          <div className="auth-signup-right">
            <div className="auth-right-content">
              <h3 className="sec-title m-b-40">Sign Up</h3>

              <Form className="w-100" onSubmit={formik.handleSubmit}>
                <Row>
                  <Col md={6}>
                    <div className="form-input-wrap">
                      <Form.Label htmlFor="name">
                        First name<span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Allie"
                        value={formik?.values?.firstName}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik?.errors.firstName && formik?.touched.firstName
                        }
                        className="input-item"
                        name="firstName"
                      />
                      {formik?.errors.firstName && formik?.touched.firstName ? (
                        <span style={{ color: "red" }}>
                          {formik.errors.firstName}
                        </span>
                      ) : null}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-input-wrap">
                      <Form.Label>
                        Last name<span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        name="lastName"
                        type="text"
                        placeholder="Grater"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik?.errors.lastName && formik?.touched.lastName
                        }
                        className="input-item"
                      />
                      {formik?.errors.lastName && formik?.touched.lastName ? (
                        <span style={{ color: "red" }}>
                          {formik.errors.lastName}
                        </span>
                      ) : null}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="form-input-wrap">
                      <Form.Label>
                        Country<span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Select
                        name="country"
                        aria-label="Default select example"
                        className="select-item"
                        onChange={formik.handleChange}
                        value={formik.values.country}
                        isInvalid={
                          formik?.errors.country && formik?.touched.country
                        }
                      >
                        <option disabled={true} value="">
                          Select...
                        </option>
                        {countries}
                      </Form.Select>
                      {formik?.errors.country && formik?.touched.country ? (
                        <span style={{ color: "red" }}>
                          {formik.errors.country}
                        </span>
                      ) : null}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="form-input-wrap">
                      <Form.Label>
                        State<span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        name="state"
                        type="text"
                        value={formik.values.state}
                        onChange={formik.handleChange}
                        className="input-item"
                        isInvalid={
                          formik?.errors.state && formik?.touched.state
                        }
                      />
                      {formik?.errors.state && formik?.touched.state ? (
                        <span style={{ color: "red" }}>
                          {formik.errors.state}
                        </span>
                      ) : null}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="form-input-wrap">
                      <Form.Label>
                        City<span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        name="city"
                        type="text"
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        className="input-item"
                        isInvalid={formik?.errors.city && formik?.touched.city}
                      />
                      {formik?.errors.city && formik?.touched.city ? (
                        <span style={{ color: "red" }}>
                          {formik.errors.city}
                        </span>
                      ) : null}
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="form-input-wrap">
                      <Form.Label>Organisation Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="orgName"
                        value={formik.values.orgName}
                        onChange={formik.handleChange}
                        className="input-item"
                        disabled
                      />
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="form-input-wrap">
                      <Form.Label>
                        Job Title<span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Job Title"
                        className="input-item"
                        name="jobTitle"
                        value={formik.values.jobTitle}
                        onChange={formik.handleChange}
                      />
                         {formik?.errors.jobTitle && formik?.touched.jobTitle ? (
                      <span style={{ color: "red" }}>
                        {formik?.errors.jobTitle}
                      </span>
                    ) : null}
                    </div>
                  </Col>
                  <Col md={12}>
                  <div className="box-bg">
                <Row>
                  <Col md={12}>
                    <p className='box-bg-infotext'>[ Your interest inputs help the system make better recommendations! ]</p>
                  </Col>
                <Col md={12}>
                    <div className="form-input-wrap">
                      <Form.Label>
                      Sectors of interest
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <ReactSelect
                        options={sectorOptions}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                          Option,
                        }}
                        allowSelectAll={true}
                        classNamePrefix="react-select-2-option"
                        className="react-multi-select"
                        selected={select}
                        onChange={setSelect}
                        values={formik.values.sector}
                        name="sector"
                        blurInputOnSelect={false}
                      />
                      <span style={{ color: "red" }}>{sectorError}</span>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="form-input-wrap">
                      <Form.Label>
                      Topics of interest
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <ReactSelect
                        options={topics}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                          Option,
                        }}
                        allowSelectAll={true}
                        classNamePrefix="react-select-2-option"
                        className="react-multi-select"
                        selected={focus}
                        onChange={setFocus}
                        values={formik.values.focusArea}
                        name="focusAreas"
                        blurInputOnSelect={false}
                      />
                      <span style={{ color: "red" }}>{focusError}</span>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="form-input-wrap">
                      <Form.Label>
                      Countries of interest
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <ReactSelect
                        options={cou}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                          Option,
                        }}
                        allowSelectAll={true}
                        classNamePrefix="react-select-2-option"
                        className="react-multi-select"
                        selected={mulcou}
                        onChange={setMulcou}
                        values={formik.values.countries}
                        name="countries"
                        blurInputOnSelect={false}
                      />
                      <span style={{ color: "red" }}>{countryError}</span>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="form-input-wrap">
                      <Form.Label>
                      Regions of interest
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <ReactSelect
                        options={regions}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                          Option,
                        }}
                        allowSelectAll={true}
                        classNamePrefix="react-select-2-option"
                        className="react-multi-select"
                        selected={reg}
                        onChange={setReg}
                        values={formik.values.region}
                        name="region"
                        blurInputOnSelect={false}
                      />
                      <span style={{ color: "red" }}>{regionError}</span>
                    </div>
                    </Col>
               </Row>
               </div>
                </Col>
                  <Col md={12} className="p-relative">
                    <div className="d-flex form-input-wrap ">
                      <div className="upload-btn-wrap">
                        <Form.Label>Resume/CV
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="InfoText">
                                Supported files are <br/>Pdf, Doc, Docx
                              </Tooltip>
                            }
                          >
                            <span className="infoicon">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z"/>
                                </svg>
                                </span>
                          </OverlayTrigger>
                        </Form.Label>
                        <div>
                          <label
                            htmlFor="fileUpload"
                            className="btn btn-outline-primary upload-btn p-relative"
                          >
                            <img
                              src={
                                upload === "Upload files"
                                  ? UploadIcons
                                  : TickIcon
                              }
                              alt=""
                              style={{ width: "14px" }}
                            />
                            {upload}
                        
                          </label>
                          <input
                            className="d-none"
                            type="file"
                            accept="application/pdf,application/msword,"
                            id="fileUpload"
                            name="resume"
                            onChange={(event) => {
                              formik.setFieldValue(
                                "resume",
                                event.currentTarget.files[0],
                                setFileName(event.currentTarget.files[0]?.name),
                                setFileSize(event.currentTarget.files[0]?.size)
                              );
                              setUpload("File uploaded");
                            }}
                            onClick={(event) => {
                              event.target.value = null;
                            }}
                          />                     
                        </div>
                      </div>
                      <div className="or-text"></div>
                      <div className="Linkedinurl flex-grow-1">
                        <Form.Label>linkedin url</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Linkedin url"
                          className="input-item"
                          name="linkedin"
                          value={formik.values.linkedin}
                          onChange={formik.handleChange}
                        />
                        {formik?.errors.linkedin && formik?.touched.linkedin ? (
                          <span style={{ color: "red" }}>
                            {formik.errors.linkedin}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <p className="file-uploaded-text">{fileName ? fileName : null}
                          {upload!=="Upload files"&&
                             <span className="resume-remove-icon" onClick={()=>{
                              formik.setFieldValue("resume","")
                              setFileName()
                              setFileSize()
                              setUpload("Upload files")
                            }} >
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                            <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/>
                          </svg></span>
                            }
                          </p>
                      <span style={{ color: "red" }}>{resumeError}</span>      
                  </Col>
                  <Col md={12}>
                    <div className="form-input-wrap">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Email Address"
                        className="input-item"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        disabled
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-input-wrap  p-relative eye-icon-inputwrap">
                      <Form.Label>
                        Password<span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type={passwordShown ? "text" : "password"}
                        placeholder="* * * * * * * *"
                        className="input-item"
                        name="password"
                        value={formik?.values?.password}
                        onChange={formik.handleChange}
                      // isInvalid={
                      //   formik?.errors.password && formik?.touched.password
                      // }
                      />
                      {passwordShown ? (
                        <span className="eye-hidden" onClick={togglePassword}>
                          <img src={HiddenEye} alt="" />
                        </span>
                      ) : (
                        <span className="eye-show" onClick={togglePassword}>
                          <img src={Eye} alt="" />
                        </span>
                      )}
                      {formik?.errors.password === "weak password" ? (
                        formik?.errors.password && formik?.touched.password ? (
                          <Tippy
                            content={
                              "Health,nutrition and population,Water and Sanitation"
                            }
                          >
                            <label style={{ color: "red" }}>
                              {formik.errors.password}
                            </label>
                          </Tippy>
                        ) : null
                      ) : formik?.errors.password &&
                        formik?.touched.password ? (
                        <span style={{ color: "red" }}>
                          {formik.errors.password}
                        </span>
                      ) : null}
                      {/* {formik?.errors.password && formik?.touched.password ? (
                        <span style={{ color: "red" }}>
                          {formik.errors.password}
                        </span>
                      ) : null} */}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-input-wrap p-relative eye-icon-inputwrap">
                      <Form.Label>
                        Confirm Password<span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type={cpasswordShown ? "text" : "password"}
                        placeholder="* * * * * * * *"
                        className="input-item"
                        name="cpassword"
                        value={formik?.values?.cpassword}
                        onChange={formik.handleChange}
                      // isInvalid={
                      //   formik?.errors.cpassword && formik?.touched.cpassword
                      // }
                      />
                      {cpasswordShown ? (
                        <span className="eye-hidden" onClick={toggleCPassword}>
                          <img src={HiddenEye} alt="" />
                        </span>
                      ) : (
                        <span className="eye-show" onClick={toggleCPassword}>
                          <img src={Eye} alt="" />
                        </span>
                      )}
                      {formik?.errors.cpassword && formik?.touched.cpassword ? (
                        <span style={{ color: "red" }}>
                          {formik.errors.cpassword}
                        </span>
                      ) : null}
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="form-input-wrap">
                      <div className="custom-checkbox agree-checkbox-wrap">
                        {termsCheck?.map((item, i) => (
                          <Form.Check className="d-flex align-items-center">
                            <Form.Check.Input type="checkbox"
                              value={item}
                              checked={termsChecked[i]}
                              onChange={() => {
                                handleOnChangeTerms(i)
                              }} />
                            <Form.Check.Label>
                              {item}{" "}
                              <Link to="/termsofuse">Terms of services</Link> and{" "}
                              <Link to="/privacynotice">Privacy Policy</Link>
                            </Form.Check.Label>
                          </Form.Check>
                        ))}
                        <span style={{ color: "red" }}>{checkedError}</span>
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="button-wrap">
                      <Button
                        type="submit"
                        onClick={() => {
                          onSignUp(), setSub(true);
                        }}
                      >
                        Sign up
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </Container>
      <Modal
        show={sho}
        size="sm"
        centered
        className="cropModal common-modal"
        onHide={handleClos}
      >
        <Modal.Header>
          <Modal.Body>
            <div className="success-content-wrap text-center">
              <div className="tick-icon">
                <img src={tickIcon} />
              </div>
              <h3>Success</h3>
              <p>
                Congratulations! Your account has been successfully created.
              </p>
            </div>
          </Modal.Body>
        </Modal.Header>
      </Modal>
    </div>
  );
}

export default UserSignUp;
