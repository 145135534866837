import React, { useState } from "react";
import PreviousChat from "../components/previouschat/previouschat";
import SidebarHeader from "../components/sidebarheader/sidebarheader";
import MobileMenuIcon from "../../../../../shared/icons/mobilemenu";

function ChatSidebar({onClose}) {

  const [isActive, setActive] = useState(false);
  const [search,setSearch] = useState("")
  const getText = (value) =>{
    setSearch(value)
  }
  const setFalse = (value) =>{
    onClose(value)
  }
  return (
    <div className="chat-sidebar h-100">
      <div className="chat-sidebar-header-wrap">
        <SidebarHeader getText={getText} />
      </div>
      <div className="previous-chat-wrap">
        <PreviousChat onClose={setFalse} search = {search} />
      </div>
    </div>
  );
}

export default ChatSidebar;
