import React, { useState, useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import Banner from "../../home/components/banner/banner";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import FinancingResults from "../components/financingresults/financingresult";
import MultimediaResults from "../components/multimediaresults/multimediaresults";
import DevPubsResults from "../components/devpubsresults/financingresult";
import OtherPubsResults from "../components/otherpubsresults/financingresult";
import EconPubsResults from "../components/econpubsresults/financingresult";
import NewsResults from "../components/newsresults/newsresults";
import ExpertsResults from "../components/expertsresults/expertsresults";
import EvaluationsResults from "../components/evaluationsresults/financingresult";
import DataResults from "../components/dataresults/dataresults";
import {
  _keyWordSearch,
  _saveQuery,
  _userTierInfo,
} from "../../../shared/httpservice/apis";
import {
  keywordSearch,
  saveQuery,
  UserListSaveQuery,
  usersListQueryShared,
} from "../services";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import upgradeImg from "../../../assets/images/icons/upgrade-icon.png";
import CountryPopup from "../components/countrypopup/countrypopup";
import CatogoryPopup from "../components/choosecatogory/choosecatogory";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik } from "formik";
import {
  setCountryKey,
  setRegionKey,
  setSectorKey,
  setTopicKey,
  setSourceKey,
  setStartDateKey,
  setEndDateKey,
} from "../services";
import Button from "react-bootstrap/esm/Button";
import ManageAccess from "../../../shared/manageaccesspopup/manageaccesspopup";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { Helmet } from "react-helmet";
import { userTierInfo } from "./../../profile/services";
import Tooltip from "react-bootstrap/Tooltip";
import Tippy from "@tippy.js/react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import InfoImg from '../../../assets/images/icons/infoicon.png'

const Results = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [Countryshow, CountrysetShow] = useState(false);
  const CountryhandleClose = () => CountrysetShow(false);
  const CountryhandleShow = () => CountrysetShow(true);

  const [DwldCountryshow, DwldCountrysetShow] = useState(false);
  const DwldCountryhandleClose = () => DwldCountrysetShow(false);
  const DwldCountryhandleShow = () => DwldCountrysetShow(true);

  const [AccessShow, AccessSetShow] = useState(false);
  const AccesshandleClose = () => AccessSetShow(false);
  const AccesshandleShow = () => AccessSetShow(true);

  const [UpgradeShow, UpgradeSetShow] = useState(false);
  const UpgradehandleClose = () => UpgradeSetShow(false);
  const UpgradehandleShow = () => UpgradeSetShow(true);

  const [Downloadshow, DownloadsetShow] = useState(false);
  const DownloadhandleClose = () => DownloadsetShow(false);
  const DownloadhandleShow = () => DownloadsetShow(true);
  const filterService = useSelector((state) => state?.filter);
  const filterServiceDev = useSelector((state) => state?.devFilter);
  const filterServiceOther = useSelector((state) => state?.otherFilter);
  const filterServiceData = useSelector((state) => state?.dataFilter);
  const filterServiceMul = useSelector((state) => state?.mulFilter);
  const filterServiceEval = useSelector((state) => state?.evalFilter);
  const filterServiceExperts = useSelector((state) => state?.expertsFilter);
  const filterServiceEcon = useSelector((state) => state?.econFilter);
  const searchTerm = useSelector((state) => state?.results?.search);
  const state = useSelector((state) => state);
  const results_state = useSelector((state) => state?.results);
  const results_ref = useRef(results_state);
  results_ref.current = results_state;
  const save_query_state = useSelector((state) => state?.results?.saveQuery);
  const save_query_ref = useRef(save_query_state);
  save_query_ref.current = save_query_state;
  const tier_info_state = useSelector(
    (state) => state?.profile?.user_tier_info
  );
  const tier_info_ref = useRef(tier_info_state);
  tier_info_ref.current = tier_info_state;
  const currentCategory = useSelector(
    (state) => state?.results?.currentCategory
  );
  const resultTitle = useSelector((state) => state?.results?.result_title);
  const file = useSelector((state) => state?.results?.file);
  const file_path_fin = useSelector(
    (state) =>
      state?.results?.keyword_search_fin?.response?.data?.data?.file_path
  );
  const file_path_dev = useSelector(
    (state) =>
      state?.results?.keyword_search_dev?.response?.data?.data?.file_path
  );
  const file_path_other = useSelector(
    (state) =>
      state?.results?.keyword_search_other?.response?.data?.data?.file_path
  );
  const file_path_data = useSelector(
    (state) =>
      state?.results?.keyword_search_data?.response?.data?.data?.file_path
  );
  const file_path_eval = useSelector(
    (state) =>
      state?.results?.keyword_search_eval?.response?.data?.data?.file_path
  );
  const file_path_news = useSelector(
    (state) =>
      state?.results?.keyword_search_news?.response?.data?.data?.file_path
  );
  const file_path_mul = useSelector(
    (state) =>
      state?.results?.keyword_search_mul?.response?.data?.data?.file_path
  );
  const file_path_econ = useSelector(
    (state) =>
      state?.results?.keyword_search_econ?.response?.data?.data?.file_path
  );
  const file_path_experts = useSelector(
    (state) =>
      state?.results?.keyword_search_experts?.response?.data?.data?.file_path
  );

  const loadingfin = useSelector(
    (state) => state?.results?.keyword_search_fin?.loading
  );

  const loadingdev = useSelector(
    (state) => state?.results?.keyword_search_dev?.loading
  );
  const loadingother = useSelector(
    (state) => state?.results?.keyword_search_other?.loading
  );
  const loadingdata = useSelector(
    (state) => state?.results?.keyword_search_data?.loading
  );
  const loadingnews = useSelector(
    (state) => state?.results?.keyword_search_news?.loading
  );
  const loadingeval = useSelector(
    (state) => state?.results?.keyword_search_eval?.loading
  );
  const loadingecon = useSelector(
    (state) => state?.results?.keyword_search_econ?.loading
  );
  const loadingexperts = useSelector(
    (state) => state?.results?.keyword_search_experts?.loading
  );
  const loadingmul = useSelector(
    (state) => state?.results?.keyword_search_mul?.loading
  );

  const dispatch = useDispatch();
  useEffect(() => {
    const header = document.getElementById("FixedTab");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);

  const [category, setCategory] = useState(currentCategory);

  useEffect(() => {
    setCategory(currentCategory);
  }, [currentCategory]);

  const handleSelect = (key) => {
    if (key === "financing") {
      setCategory("financing");
    } else if (key === "devpubs") {
      setCategory("devpubs");
      setDev(!dev);
    } else if (key === "otherpubs") {
      setCategory("otherpubs");
    } else if (key === "data") {
      setCategory("data");
    } else if (key === "econpubs") {
      if (filterServiceEcon?.econ_filter_popup_countries === 0) {
        // CountryhandleShow()
      }
      setCategory("econpubs");
    } else if (key === "multimedia") {
      setCategory("multimedia");
    } else if (key === "news") {
      setCategory("news");
    } else if (key === "evaluations") {
      setCategory("evaluations");
    } else if (key === "experts") {
      setCategory("experts");
    }
  };

  const [keyword, setKeyword] = useState("");

  const tokenSso = useSelector(
    (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token,
    shallowEqual
  );

  const token = useSelector(
    (store) => store?.auth?.signin?.response?.data?.data?.token,
    shallowEqual
  );
  
  //save query fn which checks the category and filters of the corresponding query
  const saveQueries = (values) => {
    if (category === "financing") {
      if (searchTerm !== "" && file === "") {
        const data = {
          title: values?.title,
          query: {
            category: category,
            qterm: searchTerm,
            org_name: results_state?.financeSource,
            region: results_state?.financeRegion,
            country: results_state?.financeCountry,
            sector: results_state?.financeSector,
            topic: results_state?.financeTopic,
            start_date: results_state?.financeSdate,
            end_date: results_state?.financeEdate,
          },
          category: category,
          filter_parameters: {
            financefilterarrayregion: filterService?.finance_region_filter,
            financehandlerregion: filterService?.finance_region_handler,
            financedataregion: filterService?.finance_region_data,
            financefilterregion: results_state?.financeRegion,

            financefilterarrayorg: filterService?.finance_org_filter,
            financehandlerorg: filterService?.finanace_org_handler,
            financedataorg: filterService?.finance_org_data,
            financefilterorg: results_state?.financeSource,

            financefilterarraycountry: filterService?.finance_country_filter,
            financehandlercountry: filterService?.finance_country_handler,
            financedatacountry: filterService?.finance_country_data,
            financefiltercountry: results_state?.financeCountry,

            financefilterarraytopic: filterService?.finance_topic_filter,
            financehandlertopic: filterService?.finance_topic_handler,
            financedatatopic: filterService?.finance_topic_data,
            financefiltertopic: results_state?.financeTopic,

            financefilterarraysector: filterService?.finance_sector_filter,
            financehandlersector: filterService?.finance_sector_handler,
            financedatasector: filterService?.finance_sector_data,
            financefiltersector: results_state?.financeSector,

            financestartdate: results_state?.financeSdate,
            financeenddate: results_state?.financeEdate,
            financeSdate: filterService?.finance_start_date_handler,
            financeEdate: filterService?.finance_end_date_handler,
          },
        };
        if (token !== undefined) {
          dispatch(saveQuery(data, token)).then(() => {
            if (!save_query_ref?.current?.hasError) {
              toast.success("Query saved successfully");
            } else if (
              save_query_ref?.current?.error?.response?.data?.message ===
              "Query title already exist"
            ) {
              toast.error(
                save_query_ref?.current?.error?.response?.data?.message
              );
            }
          });
        } else if (tokenSso !== undefined) {
          dispatch(saveQuery(data, tokenSso)).then(() => {
            if (!save_query_ref?.current?.hasError) {
              toast.success("Query saved successfully");
            } else if (
              save_query_ref?.current?.error?.response?.data?.message ===
              "Query title already exist"
            ) {
              toast.error(
                save_query_ref?.current?.error?.response?.data?.message
              );
            }
          });
        }
      } else if (file !== "" && searchTerm === "") {
        const data = {
          title: values?.title,
          query: {
            category: category,
            qfile: file_path_fin,
            org_name: results_state?.financeSource,
            region: results_state?.financeRegion,
            country: results_state?.financeCountry,
            sector: results_state?.financeSector,
            topic: results_state?.financeTopic,
            start_date: results_state?.financeSdate,
            end_date: results_state?.financeEdate,
          },
          category: category,
          filter_parameters: {
            financefilterarrayregion: filterService?.finance_region_filter,
            financehandlerregion: filterService?.finance_region_handler,
            financedataregion: filterService?.finance_region_data,
            financefilterregion: results_state?.financeRegion,

            financefilterarrayorg: filterService?.finance_org_filter,
            financehandlerorg: filterService?.finance_org_handler,
            financedataorg: filterService?.finance_org_data,
            financefilterorg: results_state?.financeSource,

            financefilterarraycountry: filterService?.finance_country_filter,
            financehandlercountry: filterService?.finance_country_handler,
            financedatacountry: filterService?.finance_country_data,
            financefiltercountry: results_state?.financeCountry,

            financefilterarraytopic: filterService?.finance_topic_filter,
            financehandlertopic: filterService?.finance_topic_handler,
            financedatatopic: filterService?.finance_topic_data,
            financefiltertopic: results_state?.financeTopic,

            financefilterarraysector: filterService?.finance_sector_filter,
            financehandlersector: filterService?.finance_sector_handler,
            financedatasector: filterService?.finance_sector_data,
            financefiltersector: results_state?.financeSector,

            financestartdate: results_state?.financeSdate,
            financeenddate: results_state?.financeEdate,
            financeSdate: filterService?.finance_start_date_handler,
            financeEdate: filterService?.finance_end_date_handler,
          },
        };
        if (token !== undefined) {
          dispatch(saveQuery(data, token)).then(() => {
            if (!save_query_ref?.current?.hasError) {
              toast.success("Query saved successfully");
            } else if (
              save_query_ref?.current?.error?.response?.data?.message ===
              "Query title already exist"
            ) {
              toast.error(
                save_query_ref?.current?.error?.response?.data?.message
              );
            }
          });
        } else if (tokenSso !== undefined) {
          dispatch(saveQuery(data, tokenSso)).then(() => {
            if (!save_query_ref?.current?.hasError) {
              toast.success("Query saved successfully");
            } else if (
              save_query_ref?.current?.error?.response?.data?.message ===
              "Query title already exist"
            ) {
              toast.error(
                save_query_ref?.current?.error?.response?.data?.message
              );
            }
          });
        }
      }
    } else if (category === "devpubs") {
      if (searchTerm !== "" && file === "") {
        const data = {
          title: values?.title,
          query: {
            category: category,
            qterm: searchTerm,
            org_name: results_state?.devSource,
            region: results_state?.devRegion,
            country: results_state?.devCountry,
            sector: results_state?.devSector,
            topic: results_state?.devTopic,
            start_date: results_state?.devSdate,
            end_date: results_state?.devEdate,
          },
          category: category,
          filter_parameters: {
            devfilterarrayregion: filterServiceDev?.dev_region_filter,
            devhandlerregion: filterServiceDev?.dev_region_handler,
            devdataregion: filterServiceDev?.dev_region_data,
            devfilterregion: results_state?.devRegion,

            devfilterarrayorg: filterServiceDev?.dev_org_filter,
            devhandlerorg: filterServiceDev?.dev_org_handler,
            devdataorg: filterServiceDev?.dev_org_data,
            devfilterorg: results_state?.devSource,

            devfilterarraycountry: filterServiceDev?.dev_country_filter,
            devhandlercountry: filterServiceDev?.dev_country_handler,
            devdatacountry: filterServiceDev?.dev_country_data,
            devfiltercountry: results_state?.devCountry,

            devfilterarraytopic: filterServiceDev?.dev_topic_filter,
            devhandlertopic: filterServiceDev?.dev_topic_handler,
            devdatatopic: filterServiceDev?.dev_topic_data,
            devfiltertopic: results_state?.devTopic,

            devfilterarraysector: filterServiceDev?.dev_sector_filter,
            devhandlersector: filterServiceDev?.dev_sector_handler,
            devdatasector: filterServiceDev?.dev_sector_data,
            devfiltersector: results_state?.devSector,

            devstartdate: results_state?.devSdate,
            devenddate: results_state?.devEdate,
            devSdate: filterServiceDev?.dev_start_date_handler,
            devEdate: filterServiceDev?.dev_end_date_handler,
          },
        };
        if (token !== undefined) {
          dispatch(saveQuery(data, token)).then(() => {
            if (!save_query_ref?.current?.hasError) {
              toast.success("Query saved successfully");
            } else if (
              save_query_ref?.current?.error?.response?.data?.message ===
              "Query title already exist"
            ) {
              toast.error(
                save_query_ref?.current?.error?.response?.data?.message
              );
            }
          });
        } else if (tokenSso !== undefined) {
          dispatch(saveQuery(data, tokenSso)).then(() => {
            if (!save_query_ref?.current?.hasError) {
              toast.success("Query saved successfully");
            } else if (
              save_query_ref?.current?.error?.response?.data?.message ===
              "Query title already exist"
            ) {
              toast.error(
                save_query_ref?.current?.error?.response?.data?.message
              );
            }
          });
        }
      } else if (file !== "" && searchTerm === "") {
        const data = {
          title: values?.title,
          query: {
            category: category,
            qfile: file_path_dev,
            org_name: results_state?.devSource,
            region: results_state?.devRegion,
            country: results_state?.devCountry,
            sector: results_state?.devSector,
            topic: results_state?.devTopic,
            start_date: results_state?.devSdate,
            end_date: results_state?.devEdate,
          },
          category: category,
          filter_parameters: {
            devfilterarrayregion: filterServiceDev?.dev_region_filter,
            devhandlerregion: filterServiceDev?.dev_region_handler,
            devdataregion: filterServiceDev?.dev_region_data,
            devfilterregion: results_state?.devRegion,

            devfilterarrayorg: filterServiceDev?.dev_org_filter,
            devhandlerorg: filterServiceDev?.dev_org_handler,
            devdataorg: filterServiceDev?.dev_org_data,
            devfilterorg: results_state?.devSource,

            devfilterarraycountry: filterServiceDev?.dev_country_filter,
            devhandlercountry: filterServiceDev?.dev_country_handler,
            devdatacountry: filterServiceDev?.dev_country_data,
            devfiltercountry: results_state?.devCountry,

            devfilterarraytopic: filterServiceDev?.dev_topic_filter,
            devhandlertopic: filterServiceDev?.dev_topic_handler,
            devdatatopic: filterServiceDev?.dev_topic_data,
            devfiltertopic: results_state?.devTopic,

            devfilterarraysector: filterServiceDev?.dev_sector_filter,
            devhandlersector: filterServiceDev?.dev_sector_handler,
            devdatasector: filterServiceDev?.dev_sector_data,
            devfiltersector: results_state?.devSector,

            devstartdate: results_state?.devSdate,
            devenddate: results_state?.devEdate,
            devSdate: filterServiceDev?.dev_start_date_handler,
            devEdate: filterServiceDev?.dev_end_date_handler,
          },
        };
        if (token !== undefined) {
          dispatch(saveQuery(data, token)).then(() => {
            if (!save_query_ref?.current?.hasError) {
              toast.success("Query saved successfully");
            } else if (
              save_query_ref?.current?.error?.response?.data?.message ===
              "Query title already exist"
            ) {
              toast.error(
                save_query_ref?.current?.error?.response?.data?.message
              );
            }
          });
        } else if (tokenSso !== undefined) {
          dispatch(saveQuery(data, tokenSso)).then(() => {
            if (!save_query_ref?.current?.hasError) {
              toast.success("Query saved successfully");
            } else if (
              save_query_ref?.current?.error?.response?.data?.message ===
              "Query title already exist"
            ) {
              toast.error(
                save_query_ref?.current?.error?.response?.data?.message
              );
            }
          });
        }
      }
    } else if (category === "otherpubs") {
      if (searchTerm !== "" && file === "") {
        const data = {
          title: values?.title,
          query: {
            category: category,
            qterm: searchTerm,
            org_name: results_state?.otherSource,
            region: results_state?.otherRegion,
            country: results_state?.otherCountry,
            sector: results_state?.otherSector,
            topic: results_state?.otherTopic,
            start_date: results_state?.otherSdate,
            end_date: results_state?.otherEdate,
          },
          category: category,
          filter_parameters: {
            otherfilterarrayregion: filterServiceOther?.other_region_filter,
            otherhandlerregion: filterServiceOther?.other_region_handler,
            otherdataregion: filterServiceOther?.other_region_data,
            otherfilterregion: results_state?.otherRegion,

            otherfilterarrayorg: filterServiceOther?.other_org_filter,
            otherhandlerorg: filterServiceOther?.other_org_handler,
            otherdataorg: filterServiceOther?.other_org_data,
            otherfilterorg: results_state?.otherSource,

            otherfilterarraycountry: filterServiceOther?.other_country_filter,
            otherhandlercountry: filterServiceOther?.other_country_handler,
            otherdatacountry: filterServiceOther?.other_country_data,
            otherfiltercountry: results_state?.otherCountry,

            otherfilterarraytopic: filterServiceOther?.other_topic_filter,
            otherhandlertopic: filterServiceOther?.other_topic_handler,
            otherdatatopic: filterServiceOther?.other_topic_data,
            otherfiltertopic: results_state?.otherTopic,

            otherfilterarraysector: filterServiceOther?.other_sector_filter,
            otherhandlersector: filterServiceOther?.other_sector_handler,
            otherdatasector: filterServiceOther?.other_sector_data,
            otherfiltersector: results_state?.otherSector,

            otherstartdate: results_state?.otherSdate,
            otherenddate: results_state?.otherEdate,
            otherSdate: filterServiceOther?.other_start_date_handler,
            otherEdate: filterServiceOther?.other_end_date_handler,
          },
        };
        if (token !== undefined) {
          dispatch(saveQuery(data, token)).then(() => {
            if (!save_query_ref?.current?.hasError) {
              toast.success("Query saved successfully");
            } else if (
              save_query_ref?.current?.error?.response?.data?.message ===
              "Query title already exist"
            ) {
              toast.error(
                save_query_ref?.current?.error?.response?.data?.message
              );
            }
          });
        } else if (tokenSso !== undefined) {
          dispatch(saveQuery(data, tokenSso)).then(() => {
            if (!save_query_ref?.current?.hasError) {
              toast.success("Query saved successfully");
            } else if (
              save_query_ref?.current?.error?.response?.data?.message ===
              "Query title already exist"
            ) {
              toast.error(
                save_query_ref?.current?.error?.response?.data?.message
              );
            }
          });
        }
      } else if (file !== "" && searchTerm === "") {
        const data = {
          title: values?.title,
          query: {
            category: category,
            qfile: file_path_other,
            org_name: results_state?.otherSource,
            region: results_state?.otherRegion,
            country: results_state?.otherCountry,
            sector: results_state?.otherSector,
            topic: results_state?.otherTopic,
            start_date: results_state?.otherSdate,
            end_date: results_state?.otherEdate,
          },
          category: category,
          filter_parameters: {
            otherfilterarrayregion: filterServiceOther?.other_region_filter,
            otherhandlerregion: filterServiceOther?.other_region_handler,
            otherdataregion: filterServiceOther?.other_region_data,
            otherfilterregion: results_state?.otherRegion,

            otherfilterarrayorg: filterServiceOther?.other_org_filter,
            otherhandlerorg: filterServiceOther?.other_org_handler,
            otherdataorg: filterServiceOther?.other_org_data,
            otherfilterorg: results_state?.otherSource,

            otherfilterarraycountry: filterServiceOther?.other_country_filter,
            otherhandlercountry: filterServiceOther?.other_country_handler,
            otherdatacountry: filterServiceOther?.other_country_data,
            otherfiltercountry: results_state?.otherCountry,

            otherfilterarraytopic: filterServiceOther?.other_topic_filter,
            otherhandlertopic: filterServiceOther?.other_topic_handler,
            otherdatatopic: filterServiceOther?.other_topic_data,
            otherfiltertopic: results_state?.otherTopic,

            otherfilterarraysector: filterServiceOther?.other_sector_filter,
            otherhandlersector: filterServiceOther?.other_sector_handler,
            otherdatasector: filterServiceOther?.other_sector_data,
            otherfiltersector: results_state?.otherSector,

            otherstartdate: results_state?.otherSdate,
            otherenddate: results_state?.otherEdate,
            otherSdate: filterServiceOther?.other_start_date_handler,
            otherEdate: filterServiceOther?.other_end_date_handler,
          },
        };
        if (token !== undefined) {
          dispatch(saveQuery(data, token)).then(() => {
            if (!save_query_ref?.current?.hasError) {
              toast.success("Query saved successfully");
            } else if (
              save_query_ref?.current?.error?.response?.data?.message ===
              "Query title already exist"
            ) {
              toast.error(
                save_query_ref?.current?.error?.response?.data?.message
              );
            }
          });
        } else if (tokenSso !== undefined) {
          dispatch(saveQuery(data, tokenSso)).then(() => {
            if (!save_query_ref?.current?.hasError) {
              toast.success("Query saved successfully");
            } else if (
              save_query_ref?.current?.error?.response?.data?.message ===
              "Query title already exist"
            ) {
              toast.error(
                save_query_ref?.current?.error?.response?.data?.message
              );
            }
          });
        }
      }
    } else if (category === "data") {
      if (searchTerm !== "" && file === "") {
        const data = {
          title: values?.title,
          query: {
            category: category,
            qterm: searchTerm,
            org_name: results_state?.dataSource,
            region: results_state?.dataRegion,
            country: results_state?.dataCountry,
            sector: results_state?.dataSector,
            topic: results_state?.dataTopic,
            resource: results_state?.dataResource,
          },
          category: category,
          filter_parameters: {
            datafilterarrayregion: filterServiceData?.data_region_filter,
            datahandlerregion: filterServiceData?.data_region_handler,
            datadataregion: filterServiceData?.data_region_data,
            datafilterregion: results_state?.dataRegion,

            datafilterarrayorg: filterServiceData?.data_org_filter,
            datahandlerorg: filterServiceData?.data_org_handler,
            datadataorg: filterServiceData?.data_org_data,
            datafilterorg: results_state?.dataSource,

            datafilterarraycountry: filterServiceData?.data_country_filter,
            datahandlercountry: filterServiceData?.data_country_handler,
            datadatacountry: filterServiceData?.data_country_data,
            datafiltercountry: results_state?.dataCountry,

            datafilterarraytopic: filterServiceData?.data_topic_filter,
            datahandlertopic: filterServiceData?.data_topic_handler,
            datadatatopic: filterServiceData?.data_topic_data,
            datafiltertopic: results_state?.dataTopic,

            datafilterarraysector: filterServiceData?.data_sector_filter,
            datahandlersector: filterServiceData?.data_sector_handler,
            datadatasector: filterServiceData?.data_sector_data,
            datafiltersector: results_state?.dataSector,

            datafilterarrayresource: filterServiceData?.data_resource_filter,
            datahandlerresource: filterServiceData?.data_resource_handler,
            datadataresource: filterServiceData?.data_resource_data,
            datafilterresource: results_state?.dataresource,
          },
        };
        if (token !== undefined) {
          dispatch(saveQuery(data, token)).then(() => {
            if (!save_query_ref?.current?.hasError) {
              toast.success("Query saved successfully");
            } else if (
              save_query_ref?.current?.error?.response?.data?.message ===
              "Query title already exist"
            ) {
              toast.error(
                save_query_ref?.current?.error?.response?.data?.message
              );
            }
          });
        } else if (tokenSso !== undefined) {
          dispatch(saveQuery(data, tokenSso)).then(() => {
            if (!save_query_ref?.current?.hasError) {
              toast.success("Query saved successfully");
            } else if (
              save_query_ref?.current?.error?.response?.data?.message ===
              "Query title already exist"
            ) {
              toast.error(
                save_query_ref?.current?.error?.response?.data?.message
              );
            }
          });
        }
      } else if (file !== "" && searchTerm === "") {
        const data = {
          title: values?.title,
          query: {
            category: category,
            qfile: file_path_data,
            org_name: results_state?.dataSource,
            region: results_state?.dataRegion,
            country: results_state?.dataCountry,
            sector: results_state?.dataSector,
            topic: results_state?.dataTopic,
            resource: results_state?.dataResource,
          },
          category: category,
          filter_parameters: {
            datafilterarrayregion: filterServiceData?.data_region_filter,
            datahandlerregion: filterServiceData?.data_region_handler,
            datadataregion: filterServiceData?.data_region_data,
            datafilterregion: results_state?.dataRegion,

            datafilterarrayorg: filterServiceData?.data_org_filter,
            datahandlerorg: filterServiceData?.data_org_handler,
            datadataorg: filterServiceData?.data_org_data,
            datafilterorg: results_state?.dataSource,

            datafilterarraycountry: filterServiceData?.data_country_filter,
            datahandlercountry: filterServiceData?.data_country_handler,
            datadatacountry: filterServiceData?.data_country_data,
            datafiltercountry: results_state?.dataCountry,

            datafilterarraytopic: filterServiceData?.data_topic_filter,
            datahandlertopic: filterServiceData?.data_topic_handler,
            datadatatopic: filterServiceData?.data_topic_data,
            datafiltertopic: results_state?.dataTopic,

            datafilterarraysector: filterServiceData?.data_sector_filter,
            datahandlersector: filterServiceData?.data_sector_handler,
            datadatasector: filterServiceData?.data_sector_data,
            datafiltersector: results_state?.dataSector,

            datafilterarrayresource: filterServiceData?.data_resource_filter,
            datahandlerresource: filterServiceData?.data_resource_handler,
            datadataresource: filterServiceData?.data_resource_data,
            datafilterresource: results_state?.dataresource,
          },
        };
        if (token !== undefined) {
          dispatch(saveQuery(data, token)).then(() => {
            if (!save_query_ref?.current?.hasError) {
              toast.success("Query saved successfully");
            } else if (
              save_query_ref?.current?.error?.response?.data?.message ===
              "Query title already exist"
            ) {
              toast.error(
                save_query_ref?.current?.error?.response?.data?.message
              );
            }
          });
        } else if (tokenSso !== undefined) {
          dispatch(saveQuery(data, tokenSso)).then(() => {
            if (!save_query_ref?.current?.hasError) {
              toast.success("Query saved successfully");
            } else if (
              save_query_ref?.current?.error?.response?.data?.message ===
              "Query title already exist"
            ) {
              toast.error(
                save_query_ref?.current?.error?.response?.data?.message
              );
            }
          });
        }
      }
    } else if (category === "multimedia") {
      if (searchTerm !== "" && file === "") {
        const data = {
          title: values?.title,
          query: {
            category: category,
            qterm: searchTerm,
            org_name: results_state?.mulSource,
            region: results_state?.mulRegion,
            country: results_state?.mulCountry,
            sector: results_state?.mulSector,
            topic: results_state?.mulTopic,
            start_date: results_state?.mulSdate,
            end_date: results_state?.mulEdate,
          },
          category: category,
          filter_parameters: {
            mulfilterarrayregion: filterServiceMul?.mul_region_filter,
            mulhandlerregion: filterServiceMul?.mul_region_handler,
            muldataregion: filterServiceMul?.mul_region_data,
            mulfilterregion: results_state?.mulRegion,

            mulfilterarrayorg: filterServiceMul?.mul_org_filter,
            mulhandlerorg: filterServiceMul?.mul_org_handler,
            muldataorg: filterServiceMul?.mul_org_data,
            mulfilterorg: results_state?.mulSource,

            mulfilterarraycountry: filterServiceMul?.mul_country_filter,
            mulhandlercountry: filterServiceMul?.mul_country_handler,
            muldatacountry: filterServiceMul?.mul_country_data,
            mulfiltercountry: results_state?.mulCountry,

            mulfilterarraytopic: filterServiceMul?.mul_topic_filter,
            mulhandlertopic: filterServiceMul?.mul_topic_handler,
            muldatatopic: filterServiceMul?.mul_topic_data,
            mulfiltertopic: results_state?.mulTopic,

            mulfilterarraysector: filterServiceMul?.mul_sector_filter,
            mulhandlersector: filterServiceMul?.mul_sector_handler,
            muldatasector: filterServiceMul?.mul_sector_data,
            mulfiltersector: results_state?.mulSector,

            mulstartdate: results_state?.mulSdate,
            mulenddate: results_state?.mulEdate,
            mulSdate: filterServiceMul?.mul_start_date_handler,
            mulEdate: filterServiceMul?.mul_end_date_handler,
          },
        };
        if (token !== undefined) {
          dispatch(saveQuery(data, token)).then(() => {
            if (!save_query_ref?.current?.hasError) {
              toast.success("Query saved successfully");
            } else if (
              save_query_ref?.current?.error?.response?.data?.message ===
              "Query title already exist"
            ) {
              toast.error(
                save_query_ref?.current?.error?.response?.data?.message
              );
            }
          });
        } else if (tokenSso !== undefined) {
          dispatch(saveQuery(data, tokenSso)).then(() => {
            if (!save_query_ref?.current?.hasError) {
              toast.success("Query saved successfully");
            } else if (
              save_query_ref?.current?.error?.response?.data?.message ===
              "Query title already exist"
            ) {
              toast.error(
                save_query_ref?.current?.error?.response?.data?.message
              );
            }
          });
        }
      } else if (file !== "" && searchTerm === "") {
        const data = {
          title: values?.title,
          query: {
            category: category,
            qfile: file_path_mul,
            org_name: results_state?.mulSource,
            region: results_state?.mulRegion,
            country: results_state?.mulCountry,
            sector: results_state?.mulSector,
            topic: results_state?.mulTopic,
            start_date: results_state?.mulSdate,
            end_date: results_state?.mulEdate,
          },
          category: category,
          filter_parameters: {
            mulfilterarrayregion: filterServiceMul?.mul_region_filter,
            mulhandlerregion: filterServiceMul?.mul_region_handler,
            muldataregion: filterServiceMul?.mul_region_data,
            mulfilterregion: results_state?.mulRegion,

            mulfilterarrayorg: filterServiceMul?.mul_org_filter,
            mulhandlerorg: filterServiceMul?.mul_org_handler,
            muldataorg: filterServiceMul?.mul_org_data,
            mulfilterorg: results_state?.mulSource,

            mulfilterarraycountry: filterServiceMul?.mul_country_filter,
            mulhandlercountry: filterServiceMul?.mul_country_handler,
            muldatacountry: filterServiceMul?.mul_country_data,
            mulfiltercountry: results_state?.mulCountry,

            mulfilterarraytopic: filterServiceMul?.mul_topic_filter,
            mulhandlertopic: filterServiceMul?.mul_topic_handler,
            muldatatopic: filterServiceMul?.mul_topic_data,
            mulfiltertopic: results_state?.mulTopic,

            mulfilterarraysector: filterServiceMul?.mul_sector_filter,
            mulhandlersector: filterServiceMul?.mul_sector_handler,
            muldatasector: filterServiceMul?.mul_sector_data,
            mulfiltersector: results_state?.mulSector,

            mulstartdate: results_state?.mulSdate,
            mulenddate: results_state?.mulEdate,
            mulSdate: filterServiceMul?.mul_start_date_handler,
            mulEdate: filterServiceMul?.mul_end_date_handler,
          },
        };
        if (token !== undefined) {
          dispatch(saveQuery(data, token)).then(() => {
            if (!save_query_ref?.current?.hasError) {
              toast.success("Query saved successfully");
            } else if (
              save_query_ref?.current?.error?.response?.data?.message ===
              "Query title already exist"
            ) {
              toast.error(
                save_query_ref?.current?.error?.response?.data?.message
              );
            }
          });
        } else if (tokenSso !== undefined) {
          dispatch(saveQuery(data, tokenSso)).then(() => {
            if (!save_query_ref?.current?.hasError) {
              toast.success("Query saved successfully");
            } else if (
              save_query_ref?.current?.error?.response?.data?.message ===
              "Query title already exist"
            ) {
              toast.error(
                save_query_ref?.current?.error?.response?.data?.message
              );
            }
          });
        }
      }
    } else if (category === "evaluations") {
      if (searchTerm !== "" && file === "") {
        const data = {
          title: values?.title,
          query: {
            category: category,
            qterm: searchTerm,
            org_name: results_state?.evalSource,
            region: results_state?.evalRegion,
            country: results_state?.evalCountry,
            sector: results_state?.evalSector,
            topic: results_state?.evalTopic,
            start_date: results_state?.evalSdate,
            end_date: results_state?.evalEdate,
          },
          category: category,
          filter_parameters: {
            evalfilterarrayregion: filterServiceEval?.eval_region_filter,
            evalhandlerregion: filterServiceEval?.eval_region_handler,
            evaldataregion: filterServiceEval?.eval_region_data,
            evalfilterregion: results_state?.evalRegion,

            evalfilterarrayorg: filterServiceEval?.eval_org_filter,
            evalhandlerorg: filterServiceEval?.eval_org_handler,
            evaldataorg: filterServiceEval?.eval_org_data,
            evalfilterorg: results_state?.evalSource,

            evalfilterarraycountry: filterServiceEval?.eval_country_filter,
            evalhandlercountry: filterServiceEval?.eval_country_handler,
            evaldatacountry: filterServiceEval?.eval_country_data,
            evalfiltercountry: results_state?.evalCountry,

            evalfilterarraytopic: filterServiceEval?.eval_topic_filter,
            evalhandlertopic: filterServiceEval?.eval_topic_handler,
            evaldatatopic: filterServiceEval?.eval_topic_data,
            evalfiltertopic: results_state?.evalTopic,

            evalfilterarraysector: filterServiceEval?.eval_sector_filter,
            evalhandlersector: filterServiceEval?.eval_sector_handler,
            evaldatasector: filterServiceEval?.eval_sector_data,
            evalfiltersector: results_state?.evalSector,

            evalstartdate: results_state?.evalSdate,
            evalenddate: results_state?.evalEdate,
            evalSdate: filterServiceEval?.eval_start_date_handler,
            evalEdate: filterServiceEval?.eval_end_date_handler,
          },
        };
        if (token !== undefined) {
          dispatch(saveQuery(data, token)).then(() => {
            if (!save_query_ref?.current?.hasError) {
              toast.success("Query saved successfully");
            } else if (
              save_query_ref?.current?.error?.response?.data?.message ===
              "Query title already exist"
            ) {
              toast.error(
                save_query_ref?.current?.error?.response?.data?.message
              );
            }
          });
        } else if (tokenSso !== undefined) {
          dispatch(saveQuery(data, tokenSso)).then(() => {
            if (!save_query_ref?.current?.hasError) {
              toast.success("Query saved successfully");
            } else if (
              save_query_ref?.current?.error?.response?.data?.message ===
              "Query title already exist"
            ) {
              toast.error(
                save_query_ref?.current?.error?.response?.data?.message
              );
            }
          });
        }
      } else if (file !== "" && searchTerm === "") {
        const data = {
          title: values?.title,
          query: {
            category: category,
            qfile: file_path_eval,
            org_name: results_state?.evalSource,
            region: results_state?.evalRegion,
            country: results_state?.evalCountry,
            sector: results_state?.evalSector,
            topic: results_state?.evalTopic,
            start_date: results_state?.evalSdate,
            end_date: results_state?.evalEdate,
          },
          category: category,
          filter_parameters: {
            evalfilterarrayregion: filterServiceEval?.eval_region_filter,
            evalhandlerregion: filterServiceEval?.eval_region_handler,
            evaldataregion: filterServiceEval?.eval_region_data,
            evalfilterregion: results_state?.evalRegion,

            evalfilterarrayorg: filterServiceEval?.eval_org_filter,
            evalhandlerorg: filterServiceEval?.eval_org_handler,
            evaldataorg: filterServiceEval?.eval_org_data,
            evalfilterorg: results_state?.evalSource,

            evalfilterarraycountry: filterServiceEval?.eval_country_filter,
            evalhandlercountry: filterServiceEval?.eval_country_handler,
            evaldatacountry: filterServiceEval?.eval_country_data,
            evalfiltercountry: results_state?.evalCountry,

            evalfilterarraytopic: filterServiceEval?.eval_topic_filter,
            evalhandlertopic: filterServiceEval?.eval_topic_handler,
            evaldatatopic: filterServiceEval?.eval_topic_data,
            evalfiltertopic: results_state?.evalTopic,

            evalfilterarraysector: filterServiceEval?.eval_sector_filter,
            evalhandlersector: filterServiceEval?.eval_sector_handler,
            evaldatasector: filterServiceEval?.eval_sector_data,
            evalfiltersector: results_state?.evalSector,

            evalstartdate: results_state?.evalSdate,
            evalenddate: results_state?.evalEdate,
            evalSdate: filterServiceEval?.eval_start_date_handler,
            evalEdate: filterServiceEval?.eval_end_date_handler,
          },
        };
        if (token !== undefined) {
          dispatch(saveQuery(data, token)).then(() => {
            if (!save_query_ref?.current?.hasError) {
              toast.success("Query saved successfully");
            } else if (
              save_query_ref?.current?.error?.response?.data?.message ===
              "Query title already exist"
            ) {
              toast.error(
                save_query_ref?.current?.error?.response?.data?.message
              );
            }
          });
        } else if (tokenSso !== undefined) {
          dispatch(saveQuery(data, tokenSso)).then(() => {
            if (!save_query_ref?.current?.hasError) {
              toast.success("Query saved successfully");
            } else if (
              save_query_ref?.current?.error?.response?.data?.message ===
              "Query title already exist"
            ) {
              toast.error(
                save_query_ref?.current?.error?.response?.data?.message
              );
            }
          });
        }
      }
    } else if (category === "experts") {
      if (searchTerm !== "" && file === "") {
        const data = {
          title: values?.title,
          query: {
            category: category,
            qterm: searchTerm,
            org_name: results_state?.source,
            region: results_state?.region,
            country: results_state?.country,
            sector: results_state?.sector,
            topic: results_state?.topic,
            ignore_query: filterServiceExperts?.experts_ignore_query,
          },
          category: category,
          filter_parameters: {
            expertsfilterarrayregion:
              filterServiceExperts?.experts_region_filter,
            expertshandlerregion: filterServiceExperts?.experts_region_handler,
            expertsdataregion: filterServiceExperts?.experts_region_data,
            expertsfilterregion: results_state?.region,

            expertsfilterarrayorg: filterServiceExperts?.experts_org_filter,
            expertshandlerorg: filterServiceExperts?.experts_org_handler,
            expertsdataorg: filterServiceExperts?.experts_org_data,
            expertsfilterorg: results_state?.source,

            expertsfilterarraycountry:
              filterServiceExperts?.experts_country_filter,
            expertshandlercountry:
              filterServiceExperts?.experts_country_handler,
            expertsdatacountry: filterServiceExperts?.experts_country_data,
            expertsfiltercountry: results_state?.country,

            expertsfilterarraytopic: filterServiceExperts?.experts_topic_filter,
            expertshandlertopic: filterServiceExperts?.experts_topic_handler,
            expertsdatatopic: filterServiceExperts?.experts_topic_data,
            expertsfiltertopic: results_state?.topic,

            expertsfilterarraysector:
              filterServiceExperts?.experts_sector_filter,
            expertshandlersector: filterServiceExperts?.experts_sector_handler,
            expertsdatasector: filterServiceExperts?.experts_sector_data,
            expertsfiltersector: results_state?.sector,

            ignore_query: filterServiceExperts?.experts_ignore_query,
          },
        };
        if (token !== undefined) {
          dispatch(saveQuery(data, token)).then(() => {
            if (!save_query_ref?.current?.hasError) {
              toast.success("Query saved successfully");
            } else if (
              save_query_ref?.current?.error?.response?.data?.message ===
              "Query title already exist"
            ) {
              toast.error(
                save_query_ref?.current?.error?.response?.data?.message
              );
            }
          });
        } else if (tokenSso !== undefined) {
          dispatch(saveQuery(data, tokenSso)).then(() => {
            if (!save_query_ref?.current?.hasError) {
              toast.success("Query saved successfully");
            } else if (
              save_query_ref?.current?.error?.response?.data?.message ===
              "Query title already exist"
            ) {
              toast.error(
                save_query_ref?.current?.error?.response?.data?.message
              );
            }
          });
        }
      } else if (file !== "" && searchTerm === "") {
        const data = {
          title: values?.title,
          query: {
            category: category,
            qfile: file_path_experts,
            org_name: results_state?.source,
            region: results_state?.region,
            country: results_state?.country,
            sector: results_state?.sector,
            topic: results_state?.topic,
            ignore_query: filterServiceExperts?.experts_ignore_query,
          },
          category: category,
          filter_parameters: {
            expertsfilterarrayregion:
              filterServiceExperts?.experts_region_filter,
            expertshandlerregion: filterServiceExperts?.experts_region_handler,
            expertsdataregion: filterServiceExperts?.experts_region_data,
            expertsfilterregion: results_state?.region,

            expertsfilterarrayorg: filterServiceExperts?.experts_org_filter,
            expertshandlerorg: filterServiceExperts?.experts_org_handler,
            expertsdataorg: filterServiceExperts?.experts_org_data,
            expertsfilterorg: results_state?.source,

            expertsfilterarraycountry:
              filterServiceExperts?.experts_country_filter,
            expertshandlercountry:
              filterServiceExperts?.experts_country_handler,
            expertsdatacountry: filterServiceExperts?.experts_country_data,
            expertsfiltercountry: results_state?.country,

            expertsfilterarraytopic: filterServiceExperts?.experts_topic_filter,
            expertshandlertopic: filterServiceExperts?.experts_topic_handler,
            expertsdatatopic: filterServiceExperts?.experts_topic_data,
            expertsfiltertopic: results_state?.topic,

            expertsfilterarraysector:
              filterServiceExperts?.experts_sector_filter,
            expertshandlersector: filterServiceExperts?.experts_sector_handler,
            expertsdatasector: filterServiceExperts?.experts_sector_data,
            expertsfiltersector: results_state?.sector,

            ignore_query: filterServiceExperts?.experts_ignore_query,
          },
        };
        if (token !== undefined) {
          dispatch(saveQuery(data, token)).then(() => {
            if (!save_query_ref?.current?.hasError) {
              toast.success("Query saved successfully");
            } else if (
              save_query_ref?.current?.error?.response?.data?.message ===
              "Query title already exist"
            ) {
              toast.error(
                save_query_ref?.current?.error?.response?.data?.message
              );
            }
          });
        } else if (tokenSso !== undefined) {
          dispatch(saveQuery(data, tokenSso)).then(() => {
            if (!save_query_ref?.current?.hasError) {
              toast.success("Query saved successfully");
            } else if (
              save_query_ref?.current?.error?.response?.data?.message ===
              "Query title already exist"
            ) {
              toast.error(
                save_query_ref?.current?.error?.response?.data?.message
              );
            }
          });
        }
      }
    } else if (category === "econpubs") {
      if (searchTerm !== "" && file === "") {
        const data = {
          title: values?.title,
          query: {
            category: category,
            qterm: searchTerm,
            org_name: results_state?.econSource,
            region: results_state?.econRegion,
            country: results_state?.econCountry,
            sector: results_state?.econSector,
            topic: results_state?.econTopic,
            ignore_query: filterServiceEcon?.econ_ignore_query,
            start_date: results_state?.econSdate,
            end_date: results_state?.econEdate,
          },
          category: category,
          filter_parameters: {
            econfilterarrayregion: filterServiceEcon?.econ_region_filter,
            econhandlerregion: filterServiceEcon?.econ_region_handler,
            econdataregion: filterServiceEcon?.econ_region_data,
            econfilterregion: results_state?.econRegion,

            econfilterarrayorg: filterServiceEcon?.econ_org_filter,
            econhandlerorg: filterServiceEcon?.econ_org_handler,
            econdataorg: filterServiceEcon?.econ_org_data,
            econfilterorg: results_state?.econSource,

            econfilterarraycountry: filterServiceEcon?.econ_country_filter,
            econhandlercountry: filterServiceEcon?.econ_country_handler,
            econdatacountry: filterServiceEcon?.econ_country_data,
            econfiltercountry: results_state?.econCountry,

            econfilterarraytopic: filterServiceEcon?.econ_topic_filter,
            econhandlertopic: filterServiceEcon?.econ_topic_handler,
            econdatatopic: filterServiceEcon?.econ_topic_data,
            econfiltertopic: results_state?.econTopic,

            econfilterarraysector: filterServiceEcon?.econ_sector_filter,
            econhandlersector: filterServiceEcon?.econ_sector_handler,
            econdatasector: filterServiceEcon?.econ_sector_data,
            econfiltersector: results_state?.econSector,

            ignore_query: filterServiceEcon?.econ_ignore_query,
            econinitialcountry: filterServiceEcon?.econ_filter_popup_countries,
            start_date: results_state?.econSdate,
            end_date: results_state?.econEdate,
            econSdate: filterServiceEcon?.econ_start_date_handler,
            econEdate: filterServiceEcon?.econ_end_date_handler,
          },
        };
        if (token !== undefined) {
          dispatch(saveQuery(data, token)).then(() => {
            if (!save_query_ref?.current?.hasError) {
              toast.success("Query saved successfully");
            } else if (
              save_query_ref?.current?.error?.response?.data?.message ===
              "Query title already exist"
            ) {
              toast.error(
                save_query_ref?.current?.error?.response?.data?.message
              );
            }
          });
        } else if (tokenSso !== undefined) {
          dispatch(saveQuery(data, tokenSso)).then(() => {
            if (!save_query_ref?.current?.hasError) {
              toast.success("Query saved successfully");
            } else if (
              save_query_ref?.current?.error?.response?.data?.message ===
              "Query title already exist"
            ) {
              toast.error(
                save_query_ref?.current?.error?.response?.data?.message
              );
            }
          });
        }
      } else if (file !== "" && searchTerm === "") {
        const data = {
          title: values?.title,
          query: {
            category: category,
            qfile: file_path_econ,
            org_name: results_state?.econSource,
            region: results_state?.econRegion,
            country: results_state?.econCountry,
            sector: results_state?.econSector,
            topic: results_state?.econTopic,
            ignore_query: filterServiceEcon?.econ_ignore_query,
            start_date: results_state?.econSdate,
            end_date: results_state?.econEdate,
          },
          category: category,
          filter_parameters: {
            econfilterarrayregion: filterServiceEcon?.econ_region_filter,
            econhandlerregion: filterServiceEcon?.econ_region_handler,
            econdataregion: filterServiceEcon?.econ_region_data,
            econfilterregion: results_state?.econRegion,

            econfilterarrayorg: filterServiceEcon?.econ_org_filter,
            econhandlerorg: filterServiceEcon?.econ_org_handler,
            econdataorg: filterServiceEcon?.econ_org_data,
            econfilterorg: results_state?.econSource,

            econfilterarraycountry: filterServiceEcon?.econ_country_filter,
            econhandlercountry: filterServiceEcon?.econ_country_handler,
            econdatacountry: filterServiceEcon?.econ_country_data,
            econfiltercountry: results_state?.econCountry,

            econfilterarraytopic: filterServiceEcon?.econ_topic_filter,
            econhandlertopic: filterServiceEcon?.econ_topic_handler,
            econdatatopic: filterServiceEcon?.econ_topic_data,
            econfiltertopic: results_state?.econTopic,

            econfilterarraysector: filterServiceEcon?.econ_sector_filter,
            econhandlersector: filterServiceEcon?.econ_sector_handler,
            econdatasector: filterServiceEcon?.econ_sector_data,
            econfiltersector: results_state?.econSector,

            ignore_query: filterServiceEcon?.econ_ignore_query,
            econinitialcountry: filterServiceEcon?.econ_filter_popup_countries,
            start_date: results_state?.econSdate,
            end_date: results_state?.econEdate,
            econSdate: filterServiceEcon?.econ_start_date_handler,
            econEdate: filterServiceEcon?.econ_end_date_handler,
          },
        };
        if (token !== undefined) {
          dispatch(saveQuery(data, token)).then(() => {
            if (!save_query_ref?.current?.hasError) {
              toast.success("Query saved successfully");
            } else if (
              save_query_ref?.current?.error?.response?.data?.message ===
              "Query title already exist"
            ) {
              toast.error(
                save_query_ref?.current?.error?.response?.data?.message
              );
            }
          });
        } else if (tokenSso !== undefined) {
          dispatch(saveQuery(data, tokenSso)).then(() => {
            if (!save_query_ref?.current?.hasError) {
              toast.success("Query saved successfully");
            } else if (
              save_query_ref?.current?.error?.response?.data?.message ===
              "Query title already exist"
            ) {
              toast.error(
                save_query_ref?.current?.error?.response?.data?.message
              );
            }
          });
        }
      }
    } else if (category === "news") {
      if (searchTerm !== "" && file === "") {
        const data = {
          title: values?.title,
          query: {
            category: category,
            qterm: searchTerm,
          },
          category: category,
        };
        if (token !== undefined) {
          dispatch(saveQuery(data, token)).then(() => {
            if (!save_query_ref?.current?.hasError) {
              toast.success("Query saved successfully");
            } else if (
              save_query_ref?.current?.error?.response?.data?.message ===
              "Query title already exist"
            ) {
              toast.error(
                save_query_ref?.current?.error?.response?.data?.message
              );
            }
          });
        } else if (tokenSso !== undefined) {
          dispatch(saveQuery(data, tokenSso)).then(() => {
            if (!save_query_ref?.current?.hasError) {
              toast.success("Query saved successfully");
            } else if (
              save_query_ref?.current?.error?.response?.data?.message ===
              "Query title already exist"
            ) {
              toast.error(
                save_query_ref?.current?.error?.response?.data?.message
              );
            }
          });
        }
      } else if (file !== "" && searchTerm === "") {
        const data = {
          title: values?.title,
          query: {
            category: category,
            qfile: file_path_news,
          },
          category: category,
        };
        if (token !== undefined) {
          dispatch(saveQuery(data, token)).then(() => {
            if (!save_query_ref?.current?.hasError) {
              toast.success("Query saved successfully");
            } else if (
              save_query_ref?.current?.error?.response?.data?.message ===
              "Query title already exist"
            ) {
              toast.error(
                save_query_ref?.current?.error?.response?.data?.message
              );
            }
          });
        } else if (tokenSso !== undefined) {
          dispatch(saveQuery(data, tokenSso)).then(() => {
            if (!save_query_ref?.current?.hasError) {
              toast.success("Query saved successfully");
            } else if (
              save_query_ref?.current?.error?.response?.data?.message ===
              "Query title already exist"
            ) {
              toast.error(
                save_query_ref?.current?.error?.response?.data?.message
              );
            }
          });
        }
      }
    }
  };

  const querySharedWith = () => {
    if (searchTerm !== "" && file === "") {
      const data = {
        query: {
          category: category,
          qterm: searchTerm,
        },
        category: category,
      };
      if (token !== undefined) {
        dispatch(usersListQueryShared(data, token));
      } else if (tokenSso !== undefined) {
        dispatch(usersListQueryShared(data, tokenSso));
      }
    } else if (file !== "" && searchTerm === "") {
      const data = {
        query: {
          category: category,
          // "qfile":file_path
        },
        category: category,
      };
      if (token !== undefined) {
        dispatch(usersListQueryShared(data, token));
      } else if (tokenSso !== undefined) {
        dispatch(usersListQueryShared(data, tokenSso));
      }
    }
  };

  const userList = () => {
    if (token !== undefined) {
      dispatch(UserListSaveQuery(token, "", "", "", "", "False"));
    } else if (tokenSso !== undefined) {
      dispatch(UserListSaveQuery(tokenSso, "", "", "", "", "False"));
    }
  };

  const [dev, setDev] = useState(false);

  const econPopupStatus = useSelector((state) => state?.econFilter?.econ_popup);

  useEffect(() => {
    if (
      category === "econpubs" &&
      filterServiceEcon?.econ_filter_popup_countries?.length === 0
    ) {
      CountryhandleShow();
    }
  }, [filterServiceEcon?.econ_filter_popup_countries, category]);

  const [title, setTitle] = useState();
 

  useEffect(() => {
    if (searchTerm !== "" && file === "") {
      if (countWords(searchTerm) < 4) {
        setTitle(searchTerm);
      } else {
        setTitle(searchTerm?.slice(0, thirdOccurance(searchTerm)));
      }
    } else if (searchTerm === "" && file !== "") {
      if (file?.path) {
        setTitle(file?.path);
      } else {
        setTitle(resultTitle);
      }
    }
  }, [searchTerm, file]);

  const countWords = (str) => {
    const arr = str?.split(" ");

    return arr.filter((word) => word !== "").length;
  };
  const thirdOccurance = (str) => {
    var pos1 = str?.indexOf(" ");
    var pos2 = str?.indexOf(" ", pos1 + 1);
    var pos3 = str?.indexOf(" ", pos2 + 1);
    return pos3;
  };

  //fn that checks the tier info of a particular user
  const tierInfo = async () => {
    if (token !== undefined) {
      try {
        const response = await _userTierInfo(token);
        if (response?.data?.data?.message === true) {
          DownloadhandleShow();
        }
      } catch (error) {
        if (error?.response?.status === 403) {
          UpgradehandleShow();
        }
      }
    } else if (tokenSso !== undefined) {
      try {
        const response = await _userTierInfo(tokenSso);
        if (response?.data?.data?.message === true) {
          DownloadhandleShow();
        }
      } catch (error) {
        if (error?.response?.status === 403) {
          UpgradehandleShow();
        }
      }
    }
  };
  const ignore_query_state = useSelector(
    (state) => state?.expertsFilter?.experts_ignore_query
  );
  const ignore_query_state_econ = useSelector(
    (state) => state?.econFilter?.econ_ignore_query
  );
  // console.log(ignore_query_state_econ,"ignore query state");
  const [strikeOffExperts, setStrikeOffExperts] = useState(false);
  const [strikeOffEcon, setStrikeOffEcon] = useState(false);
  useEffect(() => {
    if (category === "experts" && ignore_query_state === true) {
      setStrikeOffExperts(true);
    } else {
      setStrikeOffExperts(false);
    }
  }, [category, ignore_query_state]);

  useEffect(() => {
    if (category === "econpubs" && ignore_query_state_econ === true) {
      setStrikeOffEcon(true);
    } else {
      setStrikeOffEcon(false);
    }
  }, [category, strikeOffEcon]);

  return (
    <div className="main-container-wrap">
      <Helmet>
        <title>DevDiscovery | Results</title>
      </Helmet>
      <Banner setKeyword={setKeyword} category={category} />
      <div className="home-content-area result-content-area">
        <Container>
          <p className="results-text">Showing results for:</p>
          <div className="results-header d-flex justify-content-between align-items-start">
            {category !== "econpubs" && category !== "experts" && (
              <h4>
                {searchTerm !== ""
                  ? searchTerm?.length > 90
                    ? searchTerm.substring(0, 89) + "..."
                    : searchTerm
                  : file !== "" && file?.path !== undefined
                  ? file?.path?.length > 90
                    ? file?.path?.substring(0, 80) + "..."
                    : file?.path
                  : resultTitle?.length > 90
                  ? resultTitle?.substring(0, 80) + "..."
                  : resultTitle}
              </h4>
            )}
            {category === "econpubs" && ignore_query_state_econ === false ? (
              <h4>
                {searchTerm !== ""
                  ? searchTerm?.length > 90
                    ? searchTerm.substring(0, 89) + "..."
                    : searchTerm
                  : file !== "" && file?.path !== undefined
                  ? file?.path?.length > 90
                    ? file?.path?.substring(0, 80) + "..."
                    : file?.path
                  : resultTitle?.length > 90
                  ? resultTitle?.substring(0, 80) + "..."
                  : resultTitle}
              </h4>
            ) : (
              category === "econpubs" &&
              ignore_query_state_econ === true && (
                <Tippy content="Your input is not used to generate the below results since you've chosen for the search query to be ignored">
                  <h4 style={{ cursor: "pointer" }}>
                    <s>
                      {searchTerm !== ""
                        ? searchTerm?.length > 90
                          ? searchTerm.substring(0, 89) + "..."
                          : searchTerm
                        : file !== "" && file?.path !== undefined
                        ? file?.path?.length > 90
                          ? file?.path?.substring(0, 80) + "..."
                          : file?.path
                        : resultTitle?.length > 90
                        ? resultTitle?.substring(0, 80) + "..."
                        : resultTitle}
                    </s>
                  </h4>
                </Tippy>
              )
            )}
            {category === "experts" && strikeOffExperts === false ? (
              <h4>
                {searchTerm !== ""
                  ? searchTerm?.length > 90
                    ? searchTerm.substring(0, 89) + "..."
                    : searchTerm
                  : file !== "" && file?.path !== undefined
                  ? file?.path?.length > 90
                    ? file?.path?.substring(0, 80) + "..."
                    : file?.path
                  : resultTitle?.length > 90
                  ? resultTitle?.substring(0, 80) + "..."
                  : resultTitle}
              </h4>
            ) : (
              category === "experts" &&
              strikeOffExperts === true && (
                <Tippy content="Your input is not used to generate the below results since you've chosen for the search query to be ignored">
                  <h4 style={{ cursor: "pointer" }}>
                    <s>
                      {searchTerm !== ""
                        ? searchTerm?.length > 90
                          ? searchTerm.substring(0, 89) + "..."
                          : searchTerm
                        : file !== "" && file?.path !== undefined
                        ? file?.path?.length > 90
                          ? file?.path?.substring(0, 80) + "..."
                          : file?.path
                        : resultTitle?.length > 90
                        ? resultTitle?.substring(0, 80) + "..."
                        : resultTitle}
                    </s>
                  </h4>
                </Tippy>
              )
            )}

            {/* {searchTerm===""&&file?.path===undefined&&<h4>{resultTitle}</h4>} */}
            <div className="results-btns double-btn">
              <button
                className="btn grey-border-btn btn-sm"
                onClick={() => {
                  if (
                    !loadingfin &&
                    !loadingdev &&
                    !loadingdata &&
                    !loadingecon &&
                    !loadingexperts &&
                    !loadingmul &&
                    !loadingother &&
                    !loadingeval &&
                    !loadingnews
                  ) {
                    userList();
                    AccesshandleShow();
                    // querySharedWith()
                  }
                }}
              >
                <svg
                  width="14"
                  height="13"
                  viewBox="0 0 14 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.29472 2.96139L12.4142 6.53611L9.29472 10.0747V7.51103L7.95781 7.58325C5.79328 7.69157 4.13806 7.94433 2.83298 8.44984C3.9789 7.07773 5.79328 5.99448 8.1488 5.74173L9.29472 5.6334V2.96139ZM8.37161 0.000516431C8.32139 0.00506508 8.27245 0.0208406 8.22762 0.0469341C8.1828 0.0730277 8.14296 0.108924 8.11042 0.152555C8.07787 0.196186 8.05326 0.24669 8.03799 0.301158C8.02273 0.355627 8.01711 0.412983 8.02147 0.469924V4.2974C3.18312 4.83902 0.0636625 8.59428 0 12.3856C0 12.6023 0.0636624 12.7467 0.190987 12.7467C0.318312 12.7467 0.413806 12.6384 0.541131 12.3495C1.30508 10.3997 3.02397 9.28033 8.02147 9.02758V12.5301C8.01711 12.587 8.02273 12.6444 8.03799 12.6988C8.05326 12.7533 8.07787 12.8038 8.11042 12.8474C8.14296 12.8911 8.1828 12.927 8.22762 12.9531C8.27245 12.9792 8.32139 12.9949 8.37161 12.9995C8.42147 13.0027 8.47121 12.991 8.51587 12.9657C8.56054 12.9404 8.5986 12.9022 8.62626 12.855L13.8784 6.89719C13.9567 6.79789 14 6.66934 14 6.53611C14 6.40288 13.9567 6.27433 13.8784 6.17503L8.62626 0.144949C8.5986 0.097785 8.56054 0.0596438 8.51587 0.0343085C8.47121 0.00897325 8.42147 -0.00267587 8.37161 0.000516431Z"
                    fill="#1A73E8"
                  />
                </svg>
                Share Query
              </button>
              <button
                className="btn grey-border-btn btn-sm"
                onClick={() => {
                  if (
                    !loadingfin &&
                    !loadingdev &&
                    !loadingdata &&
                    !loadingecon &&
                    !loadingexperts &&
                    !loadingmul &&
                    !loadingother &&
                    !loadingeval &&
                    !loadingnews
                  ) {
                    handleShow();
                  }
                }}
              >
                <svg
                  width="13"
                  height="15"
                  viewBox="0 0 13 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.1875 12.9688V1.5625H2.64062V0.625H11.1875C11.4375 0.625 11.6562 0.71875 11.8438 0.90625C12.0312 1.09375 12.125 1.3125 12.125 1.5625V12.9688H11.1875ZM1.8125 12.9531L5.5625 11.3438L9.3125 12.9531V3.4375H1.8125V12.9531ZM0.875 14.375V3.4375C0.875 3.1875 0.96875 2.96875 1.15625 2.78125C1.34375 2.59375 1.5625 2.5 1.8125 2.5H9.3125C9.5625 2.5 9.78385 2.59375 9.97656 2.78125C10.1693 2.96875 10.2656 3.1875 10.2656 3.4375V14.375L5.5625 12.3906L0.875 14.375ZM1.8125 3.4375H9.3125H5.5625H1.8125Z"
                    fill="#1A73E8"
                  />
                </svg>
                Save Query
              </button>
              <button
                className="btn grey-border-btn btn-sm"
                onClick={() => {
                  // DownloadhandleShow()
                  if (
                    !loadingfin &&
                    !loadingdev &&
                    !loadingdata &&
                    !loadingecon &&
                    !loadingexperts &&
                    !loadingmul &&
                    !loadingother &&
                    !loadingeval &&
                    !loadingnews
                  ) {
                    tierInfo();
                  }
                }}
              >
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.35449 12.8334C1.03783 12.8334 0.760742 12.7147 0.523242 12.4772C0.285742 12.2397 0.166992 11.9626 0.166992 11.6459V8.81571H1.35449V11.6459H11.6462V8.81571H12.8337V11.6459C12.8337 11.9626 12.7149 12.2397 12.4774 12.4772C12.2399 12.7147 11.9628 12.8334 11.6462 12.8334H1.35449ZM6.50033 9.80529L2.68053 5.9855L3.53158 5.13446L5.90658 7.50946V0.166748H7.09408V7.50946L9.46908 5.13446L10.3201 5.9855L6.50033 9.80529Z"
                    fill="#1A73E8"
                  />
                </svg>
                Download
              </button>
            </div>
          </div>
          <div className="results-tab-wrap m-t-15" id="FixedTab">
            <Tabs
              activeKey={category === "" ? "financing" : category}
              id="justify-tab-example"
              onSelect={handleSelect}
            >
              <Tab
                eventKey="financing"
                title="Financing"
                className="results-tab"
              >
                <FinancingResults category={category} />
              </Tab>
              <Tab eventKey="devpubs" title="Publications" className="results-tab">
                <DevPubsResults category={category} />
              </Tab>
              <Tab
                eventKey="otherpubs"
                title="Scholarly"
                className="results-tab"
              >
                <OtherPubsResults category={category} />
              </Tab>
              <Tab eventKey="data" title="Data" className="results-tab">
                <DataResults category={category} />
              </Tab>
              <Tab
                eventKey="multimedia"
                title="Multimedia"
                className="results-tab"
              >
                <MultimediaResults category={category} />
              </Tab>
              <Tab eventKey="news" title="News" className="results-tab">
                <NewsResults category={category} />
              </Tab>
              <Tab
                eventKey="evaluations"
                title="Evaluations"
                className="results-tab"
              >
                <EvaluationsResults category={category} />
              </Tab>
              <Tab
                eventKey="econpubs"
                title="Economics"
                className="results-tab result-tab-seperate"
              >
                <EconPubsResults category={category} />
              </Tab>
              <Tab eventKey="experts" title="Experts" className="results-tab">
                <ExpertsResults category={category} />
              </Tab>
            </Tabs>
          </div>
        </Container>
      </div>
      <Modal
        show={Downloadshow}
        size="md"
        centered
        className="common-modal catogory-popup"
      >
        <Modal.Header
          closeButton
          onClick={DownloadhandleClose}
          className="m-b-15"
        >
          <Modal.Title>Choose categories</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="w-100">
            <div className="choose-catogory-wrap">
              <CatogoryPopup onClose={DownloadhandleClose} />
            </div>
            {/* <div className="btn-wrap d-flex justify-content-end m-t-10">
                            <Button onClick={DwldCountryhandleShow}>Download</Button>
                        </div> */}
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={show} size="md" centered className="common-modal">
        <Modal.Body>
          <Formik
            initialValues={{
              title: title,
            }}
            onSubmit={saveQueries}
          >
            {({ handleSubmit, handleChange, values, errors, touched }) => (
              <Form className="w-100" onSubmit={handleSubmit}>
                <div className="calendar-pop-header">Save Query</div>
                <div className="savequery-wrap">
                  <div className="form-input-wrap">
                    <Form.Label>Query name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Title"
                      className="input-item"
                      value={values.title}
                      name="title"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="savequery-pop-footer">
                  <div className="d-flex justify-content-end double-btn m-t-15">
                    <button
                      type="reset"
                      className="btn grey-border-btn"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={handleClose}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <Modal show={Countryshow} size="md" className="common-modal side-popup country-pop-with-toolitip">

        <Modal.Header closeButton onClick={CountryhandleClose}>
        </Modal.Header>
        <div className="country-tooltip-outer">
        <Modal.Title>
            <div className="country-title-tooltipwrap"><span className="with-tooltip-title no-events">Choose countries<span className="country-modal-inotext">
        <OverlayTrigger  placement="bottom" 
              overlay={
                <Tooltip id="InfoText">
                  You might want to find content for your country on interest in this category.
                </Tooltip>
              }
            >
              <img src={InfoImg} />

            </OverlayTrigger>
        </span></span>
      
            </div>
            </Modal.Title>
            </div>
        <Modal.Body>
          <div className="country-pop-wraper">
            <CountryPopup countryClose={CountryhandleClose} />
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer />
      <Modal show={AccessShow} size="md" className="common-modal side-popup">
        <Modal.Header closeButton onClick={AccesshandleClose}>
          <Modal.Title>Manage access</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="country-pop-wraper">
            <ManageAccess category={category} />
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={UpgradeShow} size="md" centered className="common-modal">
        <Modal.Body>
          <div className="upgrade-pop-wrap">
            <div className="upgrade-img">
              <img src={upgradeImg} alt="" />
            </div>
            <h3 className="upgrade-title">Premium feature!</h3>
            <p>Please contact your admin to enable this feature.</p>
            <div className="upgrade-btn">
              <Button onClick={UpgradehandleClose}>OK</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Results;
