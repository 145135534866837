import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import CloseSm from "../../../../assets/images/icons/close-sm.svg";
import ScrollArea from "react-scrollbar";
import Button from "react-bootstrap/esm/Button";
import { listCountry } from "./../../../auth/services";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { setCountryKey, setEconCountryKey } from "./../../services";
import {
  setEconPopupFunction,
  setEconPopupDataFunction,
  setEconIntialCountry,
} from "./../econpubsresults/econfilterservices";

function CountryPopup({ countryClose }) {
  const dispatch = useDispatch();
  const initialCountry = useSelector(
    (state) => state?.econFilter?.econ_filter_popup_countries
  );
  const country = useSelector(
    (store) => store?.auth?.country?.response?.data?.data?.element,
    shallowEqual
  );
  const token = useSelector(
    (store) => store?.auth?.signin?.response?.data?.data?.token,
    shallowEqual
  );
  const tokenSso = useSelector(
    (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token, shallowEqual
  );
  const listCountries = () => {
    if(token!==undefined){
      dispatch(listCountry(token));
    } else if(tokenSso!==undefined){
      dispatch(listCountry(tokenSso));
    }
    
  };
  useEffect(() => {
      listCountries();
  }, [token]);
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            className="react-multi-select-item"
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const [countryOptions, setCountryOptions] = useState([]);
  useEffect(() => {
    if (country) {
      const countryOptions = country.map((item) => ({
        label: item.name,
        value: item.code,
      }));
      setCountryOptions(countryOptions);
    }
  }, [country]);

  const [countrySelect, setCountrySelect] = useState([]);
  useEffect(() => {
    if (initialCountry) {
      setCountrySelect(initialCountry);
    }
  }, [initialCountry]);
  const newMulcou = countrySelect.map((item) => ({ value: item.label }));
  var countryValues = newMulcou.map((i) => {
    return i.value;
  });
  const [countries, setCountries] = useState("");
  useEffect(() => {
    var countries = countryValues?.join("^");
    setCountries(countries);
  });
  const submit = () => {
    dispatch(setEconCountryKey(countries));
    dispatch(setEconPopupDataFunction(countryValues));
    dispatch(setEconPopupFunction(false));
    dispatch(setEconIntialCountry(countrySelect));
    countryClose();
  };
  return (
    <div className="country-pop-items">
      <div className="country-filter">
        <Form className="w-100">
          <ReactSelect
            options={countryOptions}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            blurInputOnSelect={false}
            components={{
              Option,
            }}
            allowSelectAll={true}
            classNamePrefix="react-select-2-option"
            className="react-multi-select"
            placeholder='Search and select'
            name="countries"
            selected={countrySelect}
            onChange={setCountrySelect}
            value={countrySelect}
          />
        </Form>

        <div className="button-wrap d-flex justify-content-end m-t-20">
          {countrySelect?.length > 0 ? (
            <Button
              onClick={() => {
                submit();
              }}
            >
              Apply
            </Button>
          ) : (
            <Button
              disabled
              onClick={() => {
                submit();
              }}
            >
              Apply
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default CountryPopup;
