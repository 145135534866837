import React, { useState, useRef, useCallback, useEffect } from "react";
import AiResultImg from "../../../../../../../../../../assets/images/recieved-img.jpg";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { CSVLink, CSVDownload } from "react-csv";
import Tippy from "@tippy.js/react";
import "tippy.js/dist/tippy.css";
import { toJpeg } from "html-to-image";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltips from "react-bootstrap/Tooltip";
function ImageResults({ indicators }) {
  const formated =
    Array.isArray(indicators) &&
    indicators?.length &&
    indicators?.slice(0, 2)?.map((item) => {
      const years = [];
      const values = [];

      for (const key in item) {
        if (
          key === "Country Name" ||
          key === "Indicator Name" ||
          key === "Source"
        ) {
          continue;
        } else {
          years.push(key);
          values.push(item[key]);
        }
      }

      return {
        "Country Name": item["Country Name"],
        "Indicator Name": item["Indicator Name"],
        Source: item["Source"],
        years: years,
        values: values,
      };
    });

  const [converted, setConverted] = useState([]);

  useEffect(() => {
    if (Array.isArray(indicators) && indicators?.length) {
      const keys = Object.keys(indicators[0]);
      const result = [keys];

      indicators?.forEach((item) => {
        const values = keys.map((key) => item[key]);
        result.push(values);
      });
      const reorderedArrayOfArrays = result?.map((innerArray) => {
        const nonNumericValues = [];
        const numericValues = [];
        const nullValues = [];

        innerArray?.forEach((item) => {
          if (item === null) {
            nullValues.push(item);
          } else if (!isNaN(parseFloat(item))) {
            numericValues.push(item);
          } else {
            nonNumericValues.push(item);
          }
        });

        return [...nonNumericValues, ...numericValues, ...nullValues];
      });
      setConverted(reorderedArrayOfArrays);
    }
  }, [indicators]);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  let chartRef = Array.isArray(formated) && formated?.map(() => useRef(null));
  const downloadImage = useCallback(async (name, chartRef) => {
    const link = document.createElement("a");
    link.download = name + ".png";
    link.href = chartRef.current.toBase64Image();
    link.click();
  }, []);

  return (
    <>
      <div className="output-image-show-boxes">
        {/* <div className="output-img-item p-relative"> */}
        {/* <img src={AiResultImg} /> */}
        {Array.isArray(formated) &&
          formated?.map((item, index) => (
            <div className="p-relative graph-result-box">
              <Line  
                style={{"maxHeight":"400px","maxWidth":"100%","width":"100%","height":"100%"}}
                key={index}
                ref={chartRef[index]}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      position: "top",
                    },
                    title: {
                      display: true,
                      text:
                        item?.["Indicator Name"] +
                        "(Source:" +
                        item?.Source +
                        ")",
                    },
                  },
                }}
                data={{
                  labels: item?.years,
                  datasets: [
                    {
                      label: item?.["Country Name"],
                      data: item?.values,
                      borderColor: "rgb(255, 99, 132)",
                      backgroundColor: "rgba(255, 99, 132, 0.5)",
                    },
                  ],
                }}
              />
              {/* <OverlayTrigger
                overlay={<Tooltips id="InfoText">Download</Tooltips>}
              > */}
                <Tippy content="Download">
                <span
                  onClick={() => {
                    downloadImage(item?.["Indicator Name"], chartRef[index]);
                  }}
                  className="download-icon"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                  >
                    <rect
                      width="21"
                      height="21"
                      rx="3"
                      fill="white"
                      fill-opacity="0.63"
                    />
                    <path
                      d="M10.6667 14.1458L6.64583 10.125L7.54167 9.22917L10.0417 11.7292V4H11.2917V11.7292L13.7917 9.22917L14.6875 10.125L10.6667 14.1458ZM5.25 17.3333C4.91667 17.3333 4.625 17.2083 4.375 16.9583C4.125 16.7083 4 16.4167 4 16.0833V13.1042H5.25V16.0833H16.0833V13.1042H17.3333V16.0833C17.3333 16.4167 17.2083 16.7083 16.9583 16.9583C16.7083 17.2083 16.4167 17.3333 16.0833 17.3333H5.25Z"
                      fill="black"
                    />
                  </svg>
                </span>
              {/* </OverlayTrigger> */}
              </Tippy>
            </div>
          ))}
        {Array.isArray(indicators) && indicators?.length > 0 ? (
          <div className="dowload-csvbtn d-flex justify-content-end m-t-10">
            <CSVLink
              data={converted}
              filename={"indicators.csv"}
              className="csv-link-btn"
            >
              Export to CSV
            </CSVLink>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default ImageResults;
