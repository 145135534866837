import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LinkExpiredImg from '../../../assets/images/link-expired.jpg';
// import BackArrow from '../../../assets/images/icons/backarrow.svg';
import {Helmet} from 'react-helmet'
function LinkExpired() {
    return (
        <div className="error-pages-wrap pagenot-found">
            <Helmet>
                <title>
                DevDiscovery | Link expired
                </title>
            </Helmet>
            <Container>
                <div className="whitebox padding-box d-flex align-items-center justify-content-center text-center m-t-40 p-relative">
                    {/* <div className="backarrow-wrap positioned-backarrow">
                        <Link to="#"><span><img src={BackArrow} /></span></Link>
                    </div> */}
                    <div className="errorpages-content link-expired">
                        <div className="error-image">
                            <img src={LinkExpiredImg} alt="404" />
                        </div>
                        <h3 className="error-title m-t-30">Sign in link expired</h3>
                        <p>Hi, your invite login link has expired, because it’s looks like you have already Signed up.<br />
                            Please use the below button to Sign in.</p>
                        <div className="btn-wrap text-center m-t-30">
                            <Link to="/signin" className="btn btn-primary link-btn">Back to Sign in</Link>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default LinkExpired;
