import React from "react";

function NewChatIcon() {
  return (
    <span className="icon-wrap">
      <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.7076 10.6937C25.0502 3.14885 17.5773 -1.51465 10.1099 0.449651C1.30107 2.7682 -2.63936 12.7081 1.89574 20.3804C1.96481 20.4792 2.00593 20.5948 2.01474 20.715C2.02356 20.8352 1.99975 20.9556 1.94582 21.0634C1.32657 22.5095 0.734642 23.9666 0.124498 25.4173C-0.0576344 25.8562 -0.0303146 26.2651 0.282043 26.6248C0.5944 26.9845 0.970503 27.0555 1.4231 26.9499C3.35371 26.4991 5.28886 26.0666 7.21947 25.6158C7.38214 25.5775 7.55324 25.6012 7.69939 25.6823C9.50442 26.5495 11.4813 26.9999 13.4839 27C14.5058 26.997 15.5242 26.8818 16.521 26.6567C23.6433 25.0721 28.2731 17.8187 26.7076 10.6937ZM17.7595 14.5931C16.8051 14.5931 15.8471 14.6059 14.8982 14.5868C14.6323 14.5813 14.5858 14.6624 14.5904 14.9064C14.6049 15.8599 14.5986 16.8133 14.5949 17.7677C14.5949 18.458 14.1396 18.9516 13.5094 18.9516C12.8792 18.9516 12.4166 18.4598 12.4166 17.7677C12.4166 16.8024 12.4057 15.8371 12.4221 14.8727C12.4266 14.625 12.351 14.5786 12.1197 14.5822C11.1663 14.5959 10.2128 14.5895 9.25935 14.5868C8.5463 14.5868 8.04817 14.1269 8.05545 13.484C8.06274 12.8556 8.55632 12.4094 9.25297 12.4076C10.2064 12.4076 11.1599 12.3957 12.1134 12.4148C12.3802 12.4203 12.4312 12.3393 12.4212 12.0943C12.4011 11.6071 12.4157 11.1181 12.4157 10.6299C12.4157 10.1418 12.4102 9.65371 12.4157 9.1656C12.4248 8.52813 12.8984 8.04821 13.5021 8.04731C13.6473 8.04728 13.7909 8.07617 13.9248 8.13228C14.0586 8.18839 14.18 8.2706 14.2817 8.37411C14.3834 8.47762 14.4635 8.60035 14.5173 8.73515C14.5711 8.86995 14.5975 9.01411 14.5949 9.15922C14.6031 10.1464 14.6068 11.1344 14.5949 12.1225C14.5949 12.3693 14.666 12.4167 14.8963 12.413C15.8498 12.3994 16.8042 12.4057 17.7576 12.4076C18.4707 12.4076 18.9688 12.8683 18.9615 13.5113C18.9433 14.1451 18.4507 14.5913 17.7595 14.5931Z" fill="#1A73E8" />
      </svg>
    </span>
  );
}

export default NewChatIcon;
