import React from 'react';
import { Container } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import MyQueries from '../components/myqueries/myqueries';
import ReceivedQueries from '../components/receivedqueries/receivedqueries';
import {Helmet} from 'react-helmet'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {setSavedTab} from './../../results/filterServices'
import {useSelector,useDispatch} from 'react-redux'
function SavedQueries() {
    const dispatch = useDispatch()
    const handleSelect = (key) =>{
        if(key==="MyQueries"){
            dispatch(setSavedTab("MyQueries"))
        }else if(key==="ReceivedQueries"){
            dispatch(setSavedTab("ReceivedQueries"))
        }
    }
    const active = useSelector((state)=>state?.filter?.saved_tab)
    return (
        <div className="innerwrapper savedquery-wrap">
            <Helmet>
                <title>
                DevDiscovery | Saved Queries
                </title>
            </Helmet>
            <Container>
                <div className="whitebox padding-box results-tab-wrap">
                    <Tabs 
                    // defaultActiveKey="MyQueries" 
                    activeKey={active!==""?active:"MyQueries"}
                    id="justify-tab-example"
                    onSelect={handleSelect}
                    >
                        <Tab eventKey="MyQueries" title="My Queries"  >
                            <MyQueries />
                        </Tab>
                        <Tab eventKey="ReceivedQueries" title="Received Queries"  >
                            <ReceivedQueries/>
                        </Tab>
                    </Tabs>
                </div>
            </Container>
            <ToastContainer/>
        </div>
    );
};

export default SavedQueries;
