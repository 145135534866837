import React, { useState } from "react";
import { Container } from "react-bootstrap";
import ChatSidebar from "../components/sidebar/container";
import ChatFeed from "../components/feed/container";
import { Link } from "react-router-dom";
import MobileMenuIcon from "../../../shared/icons/mobilemenu";
import CloseButton from "react-bootstrap/CloseButton";
import { Helmet } from "react-helmet";

function ChatAi() {
  const ToggleClass = () => {
    setActive(!isActive);
  };
  const [isActive, setActive] = useState(false);
  const onClose = (value) => {
    setActive(value);
  };
  return (
    <div className="chat-section-whole-wrap">
      <Helmet>
        <title>DevDiscovery | Chat</title>
      </Helmet>
      <Container className="h-100 full-width-container">
        <div className="mobil-header justify-content-between align-items-center">
          <div className="mobile-chat-logo">
            <h3>Chat</h3>
            <div className="poweredby-text">By DevDiscovery</div>
          </div>
          <span
            onClick={ToggleClass}
            className={
              isActive ? "mobile-menu-close-button" : "mobile-menu-view-button"
            }
          >
            <span className="mob-open-btn">
              <MobileMenuIcon />
            </span>
            <span className="mob-close-btn">&times;</span>
          </span>
        </div>
        <div className="d-flex chat-sec-wrap h-100">
          <div
            className={
              !isActive
                ? "mobil-menu-slide mobilemenu-hidden"
                : "mobil-menu-slide ai-mobilemenu-active"
            }
          >
            <div className="chat-sidebar-box h-100">
              <ChatSidebar onClose={onClose} />
            </div>
          </div>
          <div className="chat-feed h-100">
            <ChatFeed />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default ChatAi;
