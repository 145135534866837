import React,{useEffect,useState} from 'react';
import { Link } from 'react-router-dom';
import {userProfileInfo,usersInfo} from './../../services'
import {useSelector,useDispatch,shallowEqual} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {_userInfo} from './../../../../shared/httpservice/apis'
function ProfileDetails() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const token = useSelector((store)=>store?.auth?.signin?.response?.data?.data?.token,shallowEqual)
    useEffect(()=>{
        if(token!==undefined){
            dispatch(userProfileInfo(token))
        }else if(tokenSso!==undefined){
            dispatch(userProfileInfo(tokenSso))
        }
        
    },[])
    // const state = useSelector((state)=>state?.profile?.userProfile?.response?.data?.data?.results[0])
    const states = useSelector((state)=>state?.profile)
    const userId =  useSelector((state)=>state?.profile?.userProfile?.response?.data?.data?.results[0]?.user_id)
    const sector = useSelector((state)=>state?.profile?.userProfile?.response?.data?.data?.results[0]?.pref_sector)
    const topic = useSelector((state)=>state?.profile?.userProfile?.response?.data?.data?.results[0]?.pref_topic)
    const region = useSelector((state)=>state?.profile?.userProfile?.response?.data?.data?.results[0]?.pref_region)
    const country = useSelector((state)=>state?.profile?.userProfile?.response?.data?.data?.results[0]?.pref_country)
    const tokenSso = useSelector(
        (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token,shallowEqual
      );
    const userDetails =()=>{
        if(token!== undefined){
            dispatch(usersInfo(token)).then(()=>{
                navigate("/profile-edit")
            })
        }else if(tokenSso!==undefined){
            dispatch(usersInfo(tokenSso)).then(()=>{
                navigate("/profile-edit")
            })
        }
     
       
    }

    return (
        <div className="my-profile-wrap whitebox p-30">
            <div className="my-profile-header">
                <h3 className="inner-title">My Profile</h3>
                <div className="d-flex justify-content-between align-items-center m-b-20">
                    <h4 className="prof-sub-header">User Information</h4>
                    <div className="btn-wrap">
                        <button className="btn btn-primary link-btn" onClick={userDetails}>Edit Profile</button>
                    </div>
                </div>
            </div>
            <div className="signup-content-profile p-30 p-b-0">
                <div className="profile-det-box-wrap d-flex flex-wrap">
                    <div className="profile-det-box border-detail">
                        <h4>Sectors of interest</h4>
                        {sector&&sector.map((item)=>(
                            <p>{item}</p>
                        ))}
                        {/* <p>Education</p>
                        <p>Human Services Human ServicesHuman Services</p>
                        <p>Infrastructure</p> */}
                    </div>
                    <div className="profile-det-box border-detail">
                        <h4>Topics of interest</h4>
                        {topic&&topic.map((item)=>(
                            <p>{item}</p>
                        ))}
                        {/* <p>Financing</p>
                        <p>Economics</p>
                        <p>Social interaction</p> */}
                    </div>
                    <div className="profile-det-box">
                        <h4>Countries of interest</h4>
                        {country&&country.map((item)=>(
                            <p>{item}</p>
                        ))}
                        {/* <p>USA</p>
                        <p>India</p>
                        <p>Australia</p> */}
                    </div>
                    <div className="profile-det-box">
                        <h4>Regions of interest</h4>
                        {region&&region.map((item)=>(
                            <p>{item}</p>
                        ))}
                        {/* <p>Education</p>
                        <p>Human Services Human ServicesHuman Services</p>
                        <p>Infrastructure</p> */}
                    </div>
                </div>
            </div>

        </div>
    );
};

export default ProfileDetails;
