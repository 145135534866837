import React, { useEffect, useRef, useState } from "react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  ChatContainer,
  MessageList,
  Message,
  TypingIndicator,
  MainContainer,
} from "@chatscope/chat-ui-kit-react";
import { useSelector,shallowEqual,useDispatch } from "react-redux";
import { Tooltip } from "react-tooltip";
import ProfileDummmy from "../../../../../../../../assets/images/profile-dummy.png";
import RelatedArticles from "./components/relatedarticles";
import {_likeDislikeChat,_getHistory} from '../../../../../../../../shared/httpservice/apis'
import {addChatResponse,clearChat,getChatHistory} from '../../../../../../services'
import SideQueryDoc from "../../../../../../../../assets/images/icons/sidedocicon.svg";
import ContentSection from "./components/contentSection";

function ChatResults() {
  const dispatch = useDispatch()
  const chatResponses = useSelector((state) => state?.chat?.chatResponse);
  const [clicked,setClicked] = useState(false)
  const [chatResponse,setChatResponse] = useState(chatResponses)
  useEffect(()=>{
    setChatResponse(chatResponses)
  },[chatResponses,clicked])
  const personality = useSelector((state) => state?.chat?.personality);
  const search_query = useSelector((state)=>state?.chat?.search_query)
  const userInfo = useSelector((state) => state?.profile?.userProfile?.response?.data?.data?.results[0]);
  const tokenSso = useSelector(
    (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token,
    shallowEqual
  );
  const token = useSelector(
    (store) => store?.auth?.signin?.response?.data?.data?.token,
    shallowEqual
  );



  const getHistory = async () => {
    try {
      if (token !== undefined) {
        const chatData = await _getHistory(token);
        dispatch(getChatHistory(chatData?.data?.data));
      } else if (tokenSso !== undefined) {
        const chatData = await _getHistory(tokenSso);
        dispatch(getChatHistory(chatData?.data?.data));
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const onLikeClick = (item) =>{
    item.like = true 
    item.dislike = false
    setClicked(!clicked)
    dispatch(clearChat())
    chatResponse?.map((item)=>{
      dispatch(addChatResponse(item))
    })
   const data = {
      "conversation": item?.id,
      "like_dislike": 1
      }
    if(token!==undefined){
      return _likeDislikeChat(data,token).then(()=>{
        getHistory()
      })
    }else if(tokenSso!==undefined){
      return _likeDislikeChat(data,tokenSso).then(()=>{
        getHistory()
      })
    }
  }

  const onDislikeClick = (item) =>{
    item.dislike = true 
    item.like = false
    setClicked(!clicked)
    dispatch(clearChat())
    chatResponse?.map((item)=>{
      dispatch(addChatResponse(item))
    })
    const data = {
      "conversation": item?.id,
      "like_dislike": 0
      }
    if(token!==undefined){
      return _likeDislikeChat(data,token).then(()=>{
        getHistory()
      })
    }else if(tokenSso!==undefined){
      return _likeDislikeChat(data,tokenSso).then(()=>{
        getHistory()
      })
    }
  }


  return (
    <div className="feed-whole-box h-100">
       <Tooltip
        id="my-tooltip-click"
        content="Copied"
        openOnClick={["Copied"]}
        data-tooltip-offset={10}
        delayHide={1000}
        place="right"
      />
      <ChatContainer>
        <MessageList className="chatbot-message-list h-100">
          {chatResponse &&
            chatResponse?.map((item) => (
              <Message>
                {item?.sender === "user" ? (
                  <Message.CustomContent className="query-input-wrap">
                    <div className="query-input-row d-flex">
                      <div className="query-user-logo user-img">
                      <img  src={userInfo?.profile_pic_path ? userInfo?.profile_pic_path : ProfileDummmy} alt="" />
                      </div>
                      <div className="query-input-message">
                        {item?.message && (
                           <p className="m-0" >{item?.message}</p>
                        )}
                       {item?.files?.length && item?.files?.map((file) => (
                           <span className="d-flex align-center me-3"><img className="me-1" src={SideQueryDoc} alt="doc" />{file?.name ? file.name : file}</span>
                       ))}
                      </div>
                    </div>
                  </Message.CustomContent>
                ) : (
                  <Message.CustomContent>
                    <div className="query-output-wrap">
                      <ContentSection item={item} onDislikeClick={onDislikeClick} onLikeClick={onLikeClick}/>
                    </div>
                    {item?.search_results?<RelatedArticles results={item?.search_results} facets={item?.facets} count={item?.total_results} query={item?.search_query} personality={personality} country={item?.countries} 
                    region = {item?.regions} major_concepts={item?.major_concepts} org = {item?.org}/>:null}
                    
                  </Message.CustomContent>
                )}
              </Message>
            ))}
        </MessageList>
      </ChatContainer>
    </div>
  );
}

export default ChatResults;
