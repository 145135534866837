import { createElement } from 'react';
import {createAction,handleActions} from 'redux-actions';
import ActionTypes from '~shared/constants/actionTypes';

// import {store} from  '../../../redux/store';

const initialState = {
    mul_org_filter : [],
    mul_org_data : [],
    mul_org_handler : [],
    mul_country_filter : [],
    mul_country_data : [],
    mul_country_handler : [],
    mul_region_filter : [],
    mul_region_data : [],
    mul_region_handler : [],
    mul_sector_filter : [],
    mul_sector_data : [],
    mul_sector_handler : [],
    mul_topic_filter : [],
    mul_topic_data : [],
    mul_topic_handler : [],
    mul_search_country : [],
    date_var_mul : true,
    mul_start_date_handler : null,
    mul_end_date_handler : null,
    
    
  }

//Econ 
const setMulOrgArray = createAction(ActionTypes.SET_MUL_ORG_FILTER, (data) => data);

const setMulCountryArray = createAction(ActionTypes.SET_MUL_COUNTRY_FILTER)

export const setMulCountryFilter = (data) => (dispatch) => {
  dispatch(setMulCountryArray(data))
}

export const setMulOrgFilter = (data) => (dispatch) => {
  dispatch(setMulOrgArray(data));
};

const setMulCountry = createAction(ActionTypes.SET_MUL_COUNTRY_DATA)

export const setMulCountryData = (data) => (dispatch) => {
  dispatch(setMulCountry(data))
}

const setMulCountryHandlerArray = createAction(ActionTypes.SET_MUL_COUNTRY_HANDLER)

export const setMulCountryHandler = (data) => (dispatch) =>{
  dispatch(setMulCountryHandlerArray(data))
}

const setMulOrg = createAction(ActionTypes.SET_MUL_ORG_DATA)

export const setMulOrgData = (data) => (dispatch) => {
  dispatch(setMulOrg(data))
}

const setMulOrgHandlerArray = createAction(ActionTypes.SET_MUL_ORG_HANDLER)

export const setMulOrgHandler = (data) => (dispatch) => {
  dispatch(setMulOrgHandlerArray(data))
}

const setMulRegionArray =  createAction(ActionTypes.SET_MUL_REGION_FILTER)

export const setMulRegionFilter = (data) =>(dispatch) =>{
  dispatch(setMulRegionArray(data))
}

const setMulRegionAction = createAction(ActionTypes.SET_MUL_REGION_DATA)

export const setMulRegionData = (data) => (dispatch) =>{
  dispatch(setMulRegionAction(data))
}

const setMulRegionHandlerAction = createAction(ActionTypes.SET_MUL_REGION_HANDLER)

export const setMulRegionHandler = (data) =>(dispatch)=>{
  dispatch(setMulRegionHandlerAction(data))
}

const setMulSectorArray =  createAction(ActionTypes.SET_MUL_SECTOR_FILTER)

export const setMulSectorFilter = (data) =>(dispatch) =>{
  dispatch(setMulSectorArray(data))
}

const setMulSectorAction = createAction(ActionTypes.SET_MUL_SECTOR_DATA)

export const setMulSectorData = (data) => (dispatch) =>{
  dispatch(setMulSectorAction(data))
}

const setMulSectorHandlerAction = createAction(ActionTypes.SET_MUL_SECTOR_HANDLER)

export const setMulSectorHandler = (data) =>(dispatch)=>{
  dispatch(setMulSectorHandlerAction(data))
}

const setMulTopicArray =  createAction(ActionTypes.SET_MUL_TOPIC_FILTER)

export const setMulTopicFilter = (data) =>(dispatch) =>{
  dispatch(setMulTopicArray(data))
}

const setMulTopicAction = createAction(ActionTypes.SET_MUL_TOPIC_DATA)

export const setMulTopicData = (data) => (dispatch) =>{
  dispatch(setMulTopicAction(data))
}

const setMulTopicHandlerAction = createAction(ActionTypes.SET_MUL_TOPIC_HANDLER)

export const setMulTopicHandler = (data) =>(dispatch)=>{
  dispatch(setMulTopicHandlerAction(data))
}

const setMulSearchCountryAction = createAction(ActionTypes.SET_MUL_SEARCH_COUNTRY)

export const setMulSearchCountry = (data) =>(dispatch)=>{
  dispatch(setMulSearchCountryAction(data))
}

const setDateTriggerActionMul = createAction(ActionTypes.SET_DATE_TRIGGER_MUL)
export const setDateTriggerMul = (data)=>(dispatch)=>{
  dispatch(setDateTriggerActionMul(data))
}

const setMulStartDateAction = createAction(ActionTypes.SET_MUL_START_DATE)
export const setMulStartDate = (data)=>(dispatch)=>{
  dispatch(setMulStartDateAction(data))
}

const setMulEndDateAction = createAction(ActionTypes.SET_MUL_END_DATE)
export const setMulEndDate = (data)=>(dispatch)=>{
  dispatch(setMulEndDateAction(data))
}


const reducer = handleActions({
   
[ActionTypes.SET_MUL_ORG_FILTER]: (state, action) => ({
        ...state,
        mul_org_filter: action.payload,
      }),
[ActionTypes.SET_MUL_COUNTRY_FILTER]: (state, action) => ({
        ...state,
        mul_country_filter : action.payload,
      }),
[ActionTypes.SET_MUL_COUNTRY_DATA]: (state, action) => ({
        ...state,
        mul_country_data : action.payload,
      }),
  [ActionTypes.SET_MUL_COUNTRY_HANDLER]: (state, action) => ({
        ...state,
        mul_country_handler : action.payload,
      }),   
  [ActionTypes.SET_MUL_ORG_DATA]: (state, action) => ({
        ...state,
        mul_org_data : action.payload,
      }),            
  [ActionTypes.SET_MUL_ORG_HANDLER]: (state, action) => ({
        ...state,
        mul_org_handler : action.payload,
      }),
  [ActionTypes.SET_MUL_REGION_FILTER]: (state, action) => ({
        ...state,
        mul_region_filter : action.payload,
      }),  
  [ActionTypes.SET_MUL_REGION_DATA]: (state, action) => ({
        ...state,
        mul_region_data : action.payload,
      }),                     
  [ActionTypes.SET_MUL_REGION_HANDLER]: (state, action) => ({
        ...state,
        mul_region_handler : action.payload,
      }),
  [ActionTypes.SET_MUL_SECTOR_FILTER]: (state, action) => ({
        ...state,
        mul_sector_filter : action.payload,
      }),  
  [ActionTypes.SET_MUL_SECTOR_DATA]: (state, action) => ({
        ...state,
        mul_sector_data : action.payload,
      }),                     
  [ActionTypes.SET_MUL_SECTOR_HANDLER]: (state, action) => ({
        ...state,
        mul_sector_handler : action.payload,
      }), 
  [ActionTypes.SETother_TOPIC_FILTER]: (state, action) => ({
        ...state,
        mul_topic_filter : action.payload,
      }),  
  [ActionTypes.SET_MUL_TOPIC_DATA]: (state, action) => ({
        ...state,
        mul_topic_data : action.payload,
      }),                     
  [ActionTypes.SET_MUL_TOPIC_HANDLER]: (state, action) => ({
        ...state,
        mul_topic_handler : action.payload,
      }),
  [ActionTypes.SET_MUL_SEARCH_COUNTRY]: (state, action) => ({
        ...state,
        mul_search_country : action.payload,
      }),     
  [ActionTypes.SET_DATE_TRIGGER_MUL]: (state, action) => ({
        ...state,
        date_var_mul : action.payload,
      }),    
  [ActionTypes.SET_MUL_START_DATE]: (state, action) => ({
        ...state,
        mul_start_date_handler : action.payload,
      }),
  [ActionTypes.SET_MUL_END_DATE]: (state, action) => ({
        ...state,
        mul_end_date_handler : action.payload,
      }),                             
},initialState);

export default reducer;