import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import CloseSm from '../../assets/images/icons/close-sm.svg';
import ScrollArea from "react-scrollbar";
import Button from 'react-bootstrap/esm/Button';
import DummyThumb from '../../assets/images/src-dummy.jpg'
import ProfileDummy from '../../assets/images/profile-dummy.png'
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { useSelector,useDispatch,shallowEqual } from "react-redux";
import {shareSingleQuery,usersListQueryShared,shareRecords,userListRecordShared} from './../../modules/results/services'
import { Formik } from "formik";
import {_removeAccessRecord} from './../../shared/httpservice/apis'

function ShareRecordPopup(props) {
    const result = useSelector((state)=>state?.results?.userListShareQuery?.response?.data?.data?.element)

    const sharedListMessage = useSelector((state)=>state?.results?.listUsersRecord?.response?.data?.data?.element)

    const type = typeof(sharedListMessage)

    const loading = useSelector((state)=>state?.results?.listUsersRecord?.loading)
    const [userList,setUserList] = useState()
    const searchTerm = useSelector((state)=>state?.results?.search)
    const dispatch = useDispatch()
    const token = useSelector((store)=>store?.auth?.signin?.response?.data?.data?.token,shallowEqual)
    const tokenSso = useSelector(
        (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token,shallowEqual
      );
    const errorShareQuery = useSelector((state)=>state?.results?.shareRecord?.hasError)  
      const [clicked,setClicked] = useState(true)
    useEffect(()=>{
        if(result && type==="undefined"){
            const userOptions = result.map((item) => ({
                value: item.id,
                label: item.first_name + " " + item.last_name,
              }));
              setUserList(userOptions)
        }
        else {
            const id = sharedListMessage.map((item)=>({id:item?.user}))

            const myArray = result.filter(ar => !id.find(rm => (rm.id === ar.id) ))

            const userFiltered = myArray.map((item)=>({
                value: item.id,
                label: item.first_name + " " + item.last_name,
            }))
            setUserList(userFiltered)
        }
        
    },[result,sharedListMessage])  
    const [select,setSelect] = useState([])  
    const userMap = select.map((item) => ({ value: item.value }));

    var userValues = userMap.map((i) => {
      return i.value;
    });

    const Option = (props) => {
        return (
          <div>
            <components.Option {...props}>
              <input
                className="react-multi-select-item"
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null}
              />{" "}
              <label>{props.label}</label>
            </components.Option>
          </div>
        );
      };
      const querySharedWith = ()=>{
        // const data = {
        //     "query" : {
        //         "category":props?.category,
        //         "qterm":searchTerm
        //     },
        //     "category" : props?.category
        // }
        if(token!==undefined){
            dispatch(userListRecordShared(props?.projectId,token))
        }else if(tokenSso!==undefined){
            dispatch(userListRecordShared(props?.projectId,tokenSso))
        }
       }

       useEffect(()=>{
        querySharedWith()
       },[clicked])

      const shareQuery =(values)=>{
        if(sharedListMessage==="Query not found for this user"){
            const data = {
                "title":values?.title,
                "query":{
                    "category":props.category,
                    "qterm":searchTerm
                },
                "category" : props.category,
                "users" : userValues
            }
            dispatch(shareSingleQuery(data,token)).then(()=>{
                if(!errorShareQuery){
                    setClicked(!clicked)
                }
            })
        }
        else{
                const data = {
                    "title":sharedListMessage[0]?.title,
                    "query":
                        sharedListMessage[0]?.get_parameters
                    ,
                    "category" : sharedListMessage[0]?.category,
                    "users" : userValues
                }
                dispatch(shareSingleQuery(data,token)).then(()=>{
                    if(!errorShareQuery){
                        setClicked(!clicked)
                    }
                })
            
            
        }
      }

      const shareRecord = ()=>{
        const data = {
            "project_id": props?.projectId,
            "title":props?.title,
            "abstract" : props?.abstract,
            "link_html" : props?.link,
            "category": props?.category,
            "organisation_id": props?.orgId,
            "user" : userValues
        }
        if(token!==undefined){
            dispatch(shareRecords(data,token)).then(()=>{
                if(!errorShareQuery){
                    setClicked(!clicked)
                    localStorage.setItem("shared",Date.now())
                    setSelect([])
                }
            })
        }else if(tokenSso!==undefined){
            dispatch(shareRecords(data,tokenSso)).then(()=>{
                if(!errorShareQuery){
                    setClicked(!clicked)
                    localStorage.setItem("shared",Date.now())
                    setSelect([])
                }
            })
        }

       
      }
      const removeAccessUser=(id)=>{
        if(token!==undefined){
            return _removeAccessRecord(token,id).then(()=>{
                setClicked(!clicked)
            })
        }else if(tokenSso!==undefined){
            return _removeAccessRecord(tokenSso,id).then(()=>{
                setClicked(!clicked)
            })
        }
      }
      
    return (
        <div className="country-pop-items manage-access-popup">
            {loading ? <div> <div className="loader loader-3">
        <div className="dot dot1">&nbsp;</div>
        <div className="dot dot2">&nbsp;</div>
        <div className="dot dot3">&nbsp;</div>
    </div></div> :
            <div className="country-filter">
                <div className="selected-view-box-share">
                    {/* <h4 className="share-title">Share</h4> */}
                    <Formik
                     initialValues={{
                        title: "",
                      }}
                      onSubmit={shareRecord}
                    >
                         {({ handleSubmit, handleChange, values, errors, touched }) => (
                    <Form className="w-100" onSubmit={handleSubmit}>
                        <div className="country-header-search">
                            {/* {sharedListMessage==="Query not found for this user" &&
                        <div className="form-input-wrap m-b-30">
                                <Form.Label>Querie Name</Form.Label>
                                <Form.Control type="text" placeholder="Title....." className="input-item" value={values.title} name="title" onChange={handleChange} />
                            </div>
                            } */}
                            <div className="form-input-wrap m-b-30">
                                <Form.Label>Username</Form.Label>
                                {/* <Form.Control type="text" placeholder="Username" className="input-item" /> */}
                                <ReactSelect
                        options={userList}
                        isMulti
                        closeMenuOnSelect={false}
                        blurInputOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                          Option,
                        }}
                        allowSelectAll={true}
                        classNamePrefix="react-select-2-option"
                        className="react-multi-select"
                        placeholder='Search and select'
                        selected={select}
                        onChange={setSelect}
                        // values={formik.values.sector}
                        name="userlist"
                        required
                      />

                            </div>
                           
                        </div>
                        <div className="button-wrap d-flex justify-content-end m-t-20">
                    <Button disabled={select?.length==0} type='submit'>Share</Button>
                </div>
                    </Form>
                     )}
                    </Formik>
                    {/* <ScrollArea
                        speed={0.8}
                        className="pop-selected-items-scroll"
                        contentClassName="content"
                        horizontal={false}
                    >
                        <div className="selected-item-show-wrap">
                            {[...Array(9)].map((index) => (
                                <div className="selected-item-show">
                                    <span className="selected-name">Paddy O'Furniture</span><span className="selected-close"><img src={CloseSm} alt="closearrow" /></span>
                                </div>
                            ))}
                        </div>
                    </ScrollArea> */}

                </div>
                {type==="object" ? <div className="access-box m-b-30">
                    <ScrollArea
                        speed={0.8}
                        className="pop-checkboxscroll"
                        contentClassName="content"
                        horizontal={false}
                    >
                        <div className="sharedwith-wrap">
                            <h4 className="share-title">Shared with </h4>
                            <div className="shared-name-wrap d-flex">
                                {type!== "string"&&sharedListMessage&& sharedListMessage.map((item,i) => (
                                    <div className="shared-name-box" key={i}>
                                        <div className="shared-name-box-item d-flex align-items-center justify-content-between">
                                            <div className="shared-access-left d-flex align-items-center">
                                                <div className="shared-img-wrap user-img">
                                                <img src={item?.profile_pic_path!==null?item?.profile_pic_path:ProfileDummy} />
                                                </div>
                                                <div className="shared-man-name">
                                                    {item?.username}
                                                </div>
                                            </div>
                                            <div className="shared-access-right">
                                                <div className="shared-remove-btn" style={{cursor:"pointer"}} onClick={()=>{
                                                        removeAccessUser(item?.id)
                                                        setSelect([])
                                                    }}>
                                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M2.62155 12.875C2.33822 12.875 2.0903 12.7687 1.8778 12.5562C1.6653 12.3437 1.55905 12.0958 1.55905 11.8125V1.71875H0.833008V0.65625H4.16217V0.125H8.83717V0.65625H12.1663V1.71875H11.4403V11.8125C11.4403 12.0958 11.334 12.3437 11.1215 12.5562C10.909 12.7687 10.6611 12.875 10.3778 12.875H2.62155ZM10.3778 1.71875H2.62155V11.8125H10.3778V1.71875ZM4.49863 10.2896H5.56113V3.22396H4.49863V10.2896ZM7.43822 10.2896H8.50072V3.22396H7.43822V10.2896ZM2.62155 1.71875V11.8125V1.71875Z" fill="#5B5B5B" />
                                                    </svg>
                                                    <span >Remove</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </ScrollArea>
                </div>
 :null}
                
                
               
            </div> }
        </div>
    )
}

export default ShareRecordPopup;