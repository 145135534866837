import React from 'react';

function ShimmerLoading () {
    return (
<div className="shimmer-loading-box">
<div class="shimmer-card">
        <div class="p-32">
          <div class="shimmerBG title-line"></div>
          <div class="shimmerBG title-line end"></div>
          <div class="shimmerBG content-line m-t-24"></div>
          <div class="shimmerBG content-line"></div>
           <div class="shimmerBG content-line end"></div>
        </div>
      </div>
</div>
    )
}

export default ShimmerLoading;