import { createElement } from 'react';
import {createAction,handleActions} from 'redux-actions';
import ActionTypes from '~shared/constants/actionTypes';

// import {store} from  '../../../redux/store';

const initialState = {
    dev_org_filter : [],
    dev_org_data : [],
    dev_org_handler : [],
    dev_country_filter : [],
    dev_country_data : [],
    dev_country_handler : [],
    dev_region_filter : [],
    dev_region_data : [],
    dev_region_handler : [],
    dev_sector_filter : [],
    dev_sector_data : [],
    dev_sector_handler : [],
    dev_topic_filter : [],
    dev_topic_data : [],
    dev_topic_handler : [],
    dev_search_country_array : [],
    date_var_dev : true,
    dev_start_date_handler : null,
    dev_end_date_handler : null,
    
    
  }

//Econ 
const setDevOrgArray = createAction(ActionTypes.SET_DEV_ORG_FILTER, (data) => data);

const setDevCountryArray = createAction(ActionTypes.SET_DEV_COUNTRY_FILTER)

export const setDevCountryFilter = (data) => (dispatch) => {
  dispatch(setDevCountryArray(data))
}

export const setDevOrgFilter = (data) => (dispatch) => {
  dispatch(setDevOrgArray(data));
};

const setDevCountry = createAction(ActionTypes.SET_DEV_COUNTRY_DATA)

export const setDevCountryData = (data) => (dispatch) => {
  dispatch(setDevCountry(data))
}

const setDevCountryHandlerArray = createAction(ActionTypes.SET_DEV_COUNTRY_HANDLER)

export const setDevCountryHandler = (data) => (dispatch) =>{
  dispatch(setDevCountryHandlerArray(data))
}

const setDevOrg = createAction(ActionTypes.SET_DEV_ORG_DATA)

export const setDevOrgData = (data) => (dispatch) => {
  dispatch(setDevOrg(data))
}

const setDevOrgHandlerArray = createAction(ActionTypes.SET_DEV_ORG_HANDLER)

export const setDevOrgHandler = (data) => (dispatch) => {
  dispatch(setDevOrgHandlerArray(data))
}

const setDevRegionArray =  createAction(ActionTypes.SET_DEV_REGION_FILTER)

export const setDevRegionFilter = (data) =>(dispatch) =>{
  dispatch(setDevRegionArray(data))
}

const setDevRegionAction = createAction(ActionTypes.SET_DEV_REGION_DATA)

export const setDevRegionData = (data) => (dispatch) =>{
  dispatch(setDevRegionAction(data))
}

const setDevRegionHandlerAction = createAction(ActionTypes.SET_DEV_REGION_HANDLER)

export const setDevRegionHandler = (data) =>(dispatch)=>{
  dispatch(setDevRegionHandlerAction(data))
}

const setDevSectorArray =  createAction(ActionTypes.SET_DEV_SECTOR_FILTER)

export const setDevSectorFilter = (data) =>(dispatch) =>{
  dispatch(setDevSectorArray(data))
}

const setDevSectorAction = createAction(ActionTypes.SET_DEV_SECTOR_DATA)

export const setDevSectorData = (data) => (dispatch) =>{
  dispatch(setDevSectorAction(data))
}

const setDevSectorHandlerAction = createAction(ActionTypes.SET_DEV_SECTOR_HANDLER)

export const setDevSectorHandler = (data) =>(dispatch)=>{
  dispatch(setDevSectorHandlerAction(data))
}

const setDevTopicArray =  createAction(ActionTypes.SET_DEV_TOPIC_FILTER)

export const setDevTopicFilter = (data) =>(dispatch) =>{
  dispatch(setDevTopicArray(data))
}

const setDevTopicAction = createAction(ActionTypes.SET_DEV_TOPIC_DATA)

export const setDevTopicData = (data) => (dispatch) =>{
  dispatch(setDevTopicAction(data))
}

const setDevTopicHandlerAction = createAction(ActionTypes.SET_DEV_TOPIC_HANDLER)

export const setDevTopicHandler = (data) =>(dispatch)=>{
  dispatch(setDevTopicHandlerAction(data))
}

const setDevSearchCountryAction = createAction(ActionTypes.SET_DEV_SEARCH_COUNTRY)

export const setDevSearchCountry = (data) =>(dispatch)=>{
  dispatch(setDevSearchCountryAction(data))
}

const setDateTriggerActionDev = createAction(ActionTypes.SET_DATE_TRIGGER_DEV)
export const setDateTriggerDev = (data)=>(dispatch)=>{
  dispatch(setDateTriggerActionDev(data))
}

const setDevStartDateAction = createAction(ActionTypes.SET_DEV_START_DATE)
export const setDevStartDate = (data)=>(dispatch)=>{
  dispatch(setDevStartDateAction(data))
}

const setDevEndDateAction = createAction(ActionTypes.SET_DEV_END_DATE)
export const setDevEndDate = (data)=>(dispatch)=>{
  dispatch(setDevEndDateAction(data))
}


const reducer = handleActions({
   
[ActionTypes.SET_DEV_ORG_FILTER]: (state, action) => ({
        ...state,
        dev_org_filter: action.payload,
      }),
[ActionTypes.SET_DEV_COUNTRY_FILTER]: (state, action) => ({
        ...state,
        dev_country_filter : action.payload,
      }),
[ActionTypes.SET_DEV_COUNTRY_DATA]: (state, action) => ({
        ...state,
        dev_country_data : action.payload,
      }),
  [ActionTypes.SET_DEV_COUNTRY_HANDLER]: (state, action) => ({
        ...state,
        dev_country_handler : action.payload,
      }),   
  [ActionTypes.SET_DEV_ORG_DATA]: (state, action) => ({
        ...state,
        dev_org_data : action.payload,
      }),            
  [ActionTypes.SET_DEV_ORG_HANDLER]: (state, action) => ({
        ...state,
        dev_org_handler : action.payload,
      }),
  [ActionTypes.SET_DEV_REGION_FILTER]: (state, action) => ({
        ...state,
        dev_region_filter : action.payload,
      }),  
  [ActionTypes.SET_DEV_REGION_DATA]: (state, action) => ({
        ...state,
        dev_region_data : action.payload,
      }),                     
  [ActionTypes.SET_DEV_REGION_HANDLER]: (state, action) => ({
        ...state,
        dev_region_handler : action.payload,
      }),
  [ActionTypes.SET_DEV_SECTOR_FILTER]: (state, action) => ({
        ...state,
        dev_sector_filter : action.payload,
      }),  
  [ActionTypes.SET_DEV_SECTOR_DATA]: (state, action) => ({
        ...state,
        dev_sector_data : action.payload,
      }),                     
  [ActionTypes.SET_DEV_SECTOR_HANDLER]: (state, action) => ({
        ...state,
        dev_sector_handler : action.payload,
      }), 
  [ActionTypes.SET_DEV_TOPIC_FILTER]: (state, action) => ({
        ...state,
        dev_topic_filter : action.payload,
      }),  
  [ActionTypes.SET_DEV_TOPIC_DATA]: (state, action) => ({
        ...state,
        dev_topic_data : action.payload,
      }),                     
  [ActionTypes.SET_DEV_TOPIC_HANDLER]: (state, action) => ({
        ...state,
        dev_topic_handler : action.payload,
      }),  
  [ActionTypes.SET_DEV_SEARCH_COUNTRY]: (state, action) => ({
        ...state,
        dev_search_country_array : action.payload,
      }),   
  [ActionTypes.SET_DATE_TRIGGER_DEV]: (state, action) => ({
        ...state,
        date_var_dev : action.payload,
      }),  
  [ActionTypes.SET_DEV_START_DATE]: (state, action) => ({
        ...state,
        dev_start_date_handler : action.payload,
      }),
  [ActionTypes.SET_DEV_END_DATE]: (state, action) => ({
        ...state,
        dev_end_date_handler : action.payload,
      }),                                       
},initialState);

export default reducer;
