import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import SideBg from '../../../../../../assets/images/chat-side-bg.svg';
import { useSelector, shallowEqual, useDispatch } from "react-redux";

function SidebarHeader({getText}) {
  const boxStyle = {
    backgroundImage: `url(${SideBg})`,
  };
  let timer;
  const handleChange =(e)=>{
    if(timer){
      clearTimeout(timer)
    }
    timer = setTimeout(()=>{
      getText(e.target.value)
    },1000)
  }
  return (
    <div className="chat-sidebar-header">
      <div className="chat-sidebar-logo-box" style={boxStyle}>
        <h3>Chat</h3>
        <div className="poweredby-text">By DevDiscovery</div>
      </div>
      <div className="chat-sidebar-search-wrap">
        <Form className="w-100">
          <div className="search-input-wrap p-relative">
            <Form.Control
              type="text"
              placeholder="Search"
              className="input-item"
              onChange={(e)=>{
                handleChange(e)
              }}
            />
            <span className="search-svg-wrap">
              <svg
                width="15"
                height="14"
                viewBox="0 0 15 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.9355 14L9.99193 10.6633C9.50806 11.0678 8.94355 11.3828 8.29839 11.6083C7.65323 11.8339 6.96774 11.9467 6.24194 11.9467C4.5 11.9467 3.02419 11.3633 1.81452 10.1967C0.604839 9.03 0 7.62222 0 5.97333C0 4.32444 0.604839 2.91667 1.81452 1.75C3.02419 0.583333 4.49194 0 6.21774 0C7.92742 0 9.38306 0.583333 10.5847 1.75C11.7863 2.91667 12.3871 4.32444 12.3871 5.97333C12.3871 6.64222 12.2742 7.28778 12.0484 7.91C11.8226 8.53222 11.4839 9.11556 11.0323 9.66L15 12.9733L13.9355 14ZM6.21774 10.5467C7.52419 10.5467 8.6371 10.0994 9.55645 9.205C10.4758 8.31055 10.9355 7.23333 10.9355 5.97333C10.9355 4.71333 10.4758 3.63611 9.55645 2.74167C8.6371 1.84722 7.52419 1.4 6.21774 1.4C4.89516 1.4 3.77016 1.84722 2.84274 2.74167C1.91532 3.63611 1.45161 4.71333 1.45161 5.97333C1.45161 7.23333 1.91532 8.31055 2.84274 9.205C3.77016 10.0994 4.89516 10.5467 6.21774 10.5467Z"
                  fill="#A5A5A5"
                />
              </svg>
            </span>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default SidebarHeader;
