import React, { useState, useEffect, useCallback } from "react";
import DataTable from "react-data-table-component";
import Pagination from "react-bootstrap/Pagination";
import { tableData } from "./dummyTableData";
import deleteIcon from "../../../../assets/images/icons/delete-icon.svg";
import ShareIcon from "../../../../assets/images/icons/share-icon.png";
import SharedIcon from "../../../../assets/images/icons/shared.svg";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Modal } from "react-bootstrap";
import CustomPagination from "../../../../shared/pagination/pagination";
import TablePaginate from "./../../../../shared/tablePaginate";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { listBookmarkedRecords } from "../../../profile/services";
import { useNavigate } from "react-router-dom";
import {
  _removeSingleBookmark,
  _deleteMultipleRecords,
} from "../../../../shared/httpservice/apis";
import MultipleRecordPopup from "../../../../shared/multipleRecordPopup";
import IndividualRecord from "../../../../shared/individualrecordstatuspop";
import Paginate from "../../../../shared/dataTablePagination";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function MyBookmark() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(
    (store) => store?.auth?.signin?.response?.data?.data?.token,
    shallowEqual
  );
  const tokenSso = useSelector(
    (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token,
    shallowEqual
  );
  const bookmarks = useSelector(
    (state) => state?.profile?.listBookmarks?.response?.data?.data?.results
  );
  const count = useSelector(
    (state) => state?.profile?.listBookmarks?.response?.data?.data?.count
  );
  const loading = useSelector(
    (state) => state?.profile?.listBookmarks?.loading
  );

  const [queryId, setQueryId] = useState();
  useEffect(() => {
    if (bookmarks) {
      setData(bookmarks);
    }
  }, [bookmarks]);
  const [page, setPage] = useState(1);

  const [offset, setOffset] = useState(0);

  const handleChangePage = useCallback((page) => {
    setPage(page);
  }, []);
  const handleOffsetChange = (offset) => {
    setOffset(offset);
  };

  const [currentPage, setCurrentPage] = useState(0);

  const handlePage = (data) => {
    setOffset(data?.selected * 10);
    setCurrentPage(data?.selected);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [shows, setShows] = useState(false);
  const handleCloses = () => setShows(false);
  const handleShows = () => setShows(true);

  const [deleteShow, setDeleteShow] = useState(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = () => setDeleteShow(true);

  const [recordShow, setRecordShow] = useState(false);

  const handleRecordClose = () => setRecordShow(false);
  const handleRecordShow = () => setRecordShow(true);

  const [clear, setClear] = useState(false);
  const [shareState, setShareState] = useState(false);

  const [selected, setSelected] = useState([]);
  const handleChange = ({ selectedRows }) => {
    setSelected(selectedRows);
  };
  const rows = selected.map((item) => ({ value: item.id }));
  var rowId = rows.map((i) => {
    return i.value;
  });
  const [projectId, setProjectId] = useState();
  const [savedRecordId, setSavedRecId] = useState();

  //fn to list bookmark records
  const bookmarkedRecords = () => {
    if (token !== undefined) {
      dispatch(listBookmarkedRecords(token, "", offset));
    } else if (tokenSso !== undefined) {
      dispatch(listBookmarkedRecords(tokenSso, "", offset));
    }
  };

  //list bookmarked records on page load and change in offset
  useEffect(() => {
    bookmarkedRecords();
  }, [offset]);

  //fn to remove single bookmark
  const removeSingleBookmark = () => {
    if (token !== undefined) {
      return _removeSingleBookmark(queryId, token).then(() => {
        dispatch(listBookmarkedRecords(token, ""));
      });
    } else if (tokenSso !== undefined) {
      return _removeSingleBookmark(queryId, tokenSso).then(() => {
        dispatch(listBookmarkedRecords(tokenSso, ""));
      });
    }
  };

  //fn to remove multiple bookmarked records
  const removeMultipleRecords = () => {
    if (token !== undefined) {
      return _deleteMultipleRecords(rowId, token).then(() => {
        dispatch(listBookmarkedRecords(token, ""));
        setSelected([])
        setClear(true)
      });
    } else if (tokenSso !== undefined) {
      return _deleteMultipleRecords(rowId, tokenSso).then(() => {
        dispatch(listBookmarkedRecords(tokenSso, ""));
        setSelected([])
        setClear(true)
      });
    }
  };
  const [search,setSearch] = useState("")

  //fn to search a particular record using the search bar
  const onSearchValueChange = (e) => {
    const value = e.target.value;
    setSearch(value)
    if (value) {
      if (token !== undefined) {
        dispatch(listBookmarkedRecords(token, value, offset));
      } else if (tokenSso !== undefined) {
        dispatch(listBookmarkedRecords(tokenSso, value, offset));
      }
    } else {
      if (token !== undefined) {
        dispatch(listBookmarkedRecords(token, "", offset));
      } else if (tokenSso !== undefined) {
        dispatch(listBookmarkedRecords(tokenSso, "", offset));
      }
    }
  };

  const setShareFunction = () => {
    if (shareState === true) {
      setClear(true);
      setShareState(!shareState);
      setSelected([]);
    }
  };

  const onChangeShare = (state) => {
    setShareState(state);
  };

  useEffect(() => {
    if (clear === true) {
      setClear(false);
    }
  }, [clear]);

  //fn to return category
  const categoryTitle=(string)=> {
    if(string==="financing"){
      return "Financing";
    }
    else if(string==="devpubs"){
      return "DevPubs";
    }else if(string==="otherpubs"){
      return "OtherPubs";
    }else if(string==="data"){
      return "Data"
    }else if(string==="multimedia"){
      return "Multimedia"
    }else if(string==="news"){
      return "News"
    }else if(string==="experts"){
      return "Experts"
    }else if(string==="econpubs"){
      return "EconPubs"
    }else if(string==="evaluations"){
      return "Evaluations"
    }
  }
  const tableColumns = [
    {
      name: "Name",
      selector: (row) => `${row.title}`,
      sortable: true,
      center: false,
      cell: (row) => (
        <a className="column-title-link"
          href={row.link_html}
          target="_blank"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <span>
            {row?.title?.length > 100 ? row?.title?.slice(0, 100) : row?.title}
          </span>
        </a>
      ),
      grow: 6,
    },
    {
      name: "Category",
      selector: (row) => row.category
      ,
      sortable: true,
      center: false,
      grow: 2,
      cell: (row) => <span>{categoryTitle(row.category)}</span>,
    },
    {
      name: "Date",
      selector: (row) => row.created,
      sortable: true,
      center: false,
      grow: 2,
      cell: (row) => <span>{row.created.slice(0, 10)}</span>,
    },
    {
      name: "Status",
      selector: (row) =>
        `${row.is_shared}``${row.id}``${row.title}``${row.project_id}`,
      sortable: false,
      center: false,
      cell: (row) => (
        <a className="share-icon-cell flex align-items-center">
          {row.is_shared ? (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleRecordShow();
                setProjectId(row.project_id);
                setSavedRecId(row.id);
              }}
            >
              <img src={SharedIcon} />
              Shared
            </span>
          ) : (
            <span
              onClick={() => {
                handleRecordShow();
                setProjectId(row.project_id);
                setSavedRecId(row.id);
              }}
              style={{ cursor: "pointer" }}
            >
              <img src={ShareIcon} />
              Private
            </span>
          )}
        </a>
      ),
      grow: 2,
    },
    {
      name: "",
      selector: (row) => row.id,
      sortable: false,
      center: false,
      cell: (row) => (
        <div data-tag="allowRowEvents" className="d-flex">
          <div
            className="action-icon-cell"
            onClick={() => {
              handleShow();
              setQueryId(row.id);
            }}
          >
            <OverlayTrigger overlay={<Tooltip id="InfoText">Remove</Tooltip>}>
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.62155 12.875C2.33822 12.875 2.0903 12.7687 1.8778 12.5562C1.6653 12.3437 1.55905 12.0958 1.55905 11.8125V1.71875H0.833008V0.65625H4.16217V0.125H8.83717V0.65625H12.1663V1.71875H11.4403V11.8125C11.4403 12.0958 11.334 12.3437 11.1215 12.5562C10.909 12.7687 10.6611 12.875 10.3778 12.875H2.62155ZM10.3778 1.71875H2.62155V11.8125H10.3778V1.71875ZM4.49863 10.2896H5.56113V3.22396H4.49863V10.2896ZM7.43822 10.2896H8.50072V3.22396H7.43822V10.2896ZM2.62155 1.71875V11.8125V1.71875Z"
                  fill="#5B5B5B"
                />
              </svg>
            </OverlayTrigger>
          </div>{" "}
        </div>
      ),
      grow: 1,
    },
  ];

  const [data, setData] = useState([]);
  useEffect(()=>{
    setOffset(0)
    setCurrentPage(0)
  },[search])

  return (
    <div className="datatable-wrap checkbox-table">
      <div className="datatable-header-area d-flex m-b-20">
        <div className="datatable-hedaer-left d-flex align-items-center">
          {/* <div className="selectall-wrap">
                        <div className="checkbox-wrapper">
                            <input id="Check1" type="checkbox" />
                            <label htmlFor="Check1">
                                <span>Select all</span>
                            </label>
                        </div>
                    </div> */}
          <div className="table-buttons-wrap double-btn d-flex">
            <button
              disabled={rowId?.length === 0}
              className="btn grey-border-btn btn-sm"
              onClick={handleShows}
            >
              <svg
                width="14"
                height="13"
                viewBox="0 0 14 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.29472 2.96139L12.4142 6.53611L9.29472 10.0747V7.51103L7.95781 7.58325C5.79328 7.69157 4.13806 7.94433 2.83298 8.44984C3.9789 7.07773 5.79328 5.99448 8.1488 5.74173L9.29472 5.6334V2.96139ZM8.37161 0.000516431C8.32139 0.00506508 8.27245 0.0208406 8.22762 0.0469341C8.1828 0.0730277 8.14296 0.108924 8.11042 0.152555C8.07787 0.196186 8.05326 0.24669 8.03799 0.301158C8.02273 0.355627 8.01711 0.412983 8.02147 0.469924V4.2974C3.18312 4.83902 0.0636625 8.59428 0 12.3856C0 12.6023 0.0636624 12.7467 0.190987 12.7467C0.318312 12.7467 0.413806 12.6384 0.541131 12.3495C1.30508 10.3997 3.02397 9.28033 8.02147 9.02758V12.5301C8.01711 12.587 8.02273 12.6444 8.03799 12.6988C8.05326 12.7533 8.07787 12.8038 8.11042 12.8474C8.14296 12.8911 8.1828 12.927 8.22762 12.9531C8.27245 12.9792 8.32139 12.9949 8.37161 12.9995C8.42147 13.0027 8.47121 12.991 8.51587 12.9657C8.56054 12.9404 8.5986 12.9022 8.62626 12.855L13.8784 6.89719C13.9567 6.79789 14 6.66934 14 6.53611C14 6.40288 13.9567 6.27433 13.8784 6.17503L8.62626 0.144949C8.5986 0.097785 8.56054 0.0596438 8.51587 0.0343085C8.47121 0.00897325 8.42147 -0.00267587 8.37161 0.000516431Z"
                  fill="#1A73E8"
                />
              </svg>
              Share Bookmark
            </button>
            <button
              disabled={rowId?.length === 0}
              className="btn grey-border-btn btn-sm"
              onClick={handleDeleteShow}
            >
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.62155 12.875C2.33822 12.875 2.0903 12.7687 1.8778 12.5562C1.6653 12.3437 1.55905 12.0958 1.55905 11.8125V1.71875H0.833008V0.65625H4.16217V0.125H8.83717V0.65625H12.1663V1.71875H11.4403V11.8125C11.4403 12.0958 11.334 12.3437 11.1215 12.5562C10.909 12.7687 10.6611 12.875 10.3778 12.875H2.62155ZM10.3778 1.71875H2.62155V11.8125H10.3778V1.71875ZM4.49863 10.2896H5.56113V3.22396H4.49863V10.2896ZM7.43822 10.2896H8.50072V3.22396H7.43822V10.2896ZM2.62155 1.71875V11.8125V1.71875Z"
                  fill="#5B5B5B"
                />
              </svg>{" "}
              Remove
            </button>
          </div>
        </div>
        <div className="datatable-hedaer-right">
          <Form
            className="w-100"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="search-input-wrap p-relative">
              <Form.Control
                type="text"
                placeholder="Search"
                className="input-item"
                onChange={onSearchValueChange}
              />
              <span className="search-svg-wrap">
                <svg
                  width="15"
                  height="14"
                  viewBox="0 0 15 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.9355 14L9.99193 10.6633C9.50806 11.0678 8.94355 11.3828 8.29839 11.6083C7.65323 11.8339 6.96774 11.9467 6.24194 11.9467C4.5 11.9467 3.02419 11.3633 1.81452 10.1967C0.604839 9.03 0 7.62222 0 5.97333C0 4.32444 0.604839 2.91667 1.81452 1.75C3.02419 0.583333 4.49194 0 6.21774 0C7.92742 0 9.38306 0.583333 10.5847 1.75C11.7863 2.91667 12.3871 4.32444 12.3871 5.97333C12.3871 6.64222 12.2742 7.28778 12.0484 7.91C11.8226 8.53222 11.4839 9.11556 11.0323 9.66L15 12.9733L13.9355 14ZM6.21774 10.5467C7.52419 10.5467 8.6371 10.0994 9.55645 9.205C10.4758 8.31055 10.9355 7.23333 10.9355 5.97333C10.9355 4.71333 10.4758 3.63611 9.55645 2.74167C8.6371 1.84722 7.52419 1.4 6.21774 1.4C4.89516 1.4 3.77016 1.84722 2.84274 2.74167C1.91532 3.63611 1.45161 4.71333 1.45161 5.97333C1.45161 7.23333 1.91532 8.31055 2.84274 9.205C3.77016 10.0994 4.89516 10.5467 6.21774 10.5467Z"
                    fill="#A5A5A5"
                  />
                </svg>
              </span>
            </div>
          </Form>
        </div>
      </div>
      {clear === false && (
        <div className="data-table-responsive">
          <DataTable
            data={data}
            columns={tableColumns}
            // paginationComponent={CustomPagination}
            selectableRows
            center={true}
            noHeader={true}
            onSelectedRowsChange={handleChange}
            noDataComponent={
              loading ? (
                <div className="loader-middle-align">
                  <div className="loader loader-3">
                    <div className="dot dot1">&nbsp;</div>
                    <div className="dot dot2">&nbsp;</div>
                    <div className="dot dot3">&nbsp;</div>
                  </div>
                </div>
              ) : (
                search===""?
                <div className="loader-middle-align">
                  No records have been bookmarked yet.
                </div>:<div className="loader-middle-align">
                Nothing turned up! You may want to try using different keywords, or checking for typos please.
                </div>
              )
            }
          />

          {/* <TablePaginate total={(Math.ceil(count/10))} current={page} onChangePage={handleChangePage} onChangeOffset={handleOffsetChange}/> */}
          { data?.length>0&&<Paginate
            pageCount={Math.ceil(count / 10)}
            onPageChange={handlePage}
            initialPage={currentPage}
          />

          }
         
        </div>
      )}

      <Modal show={show} size="sm" centered className="common-modal">
        <Modal.Header closeButton onClick={handleClose} className="m-b-15">
          <Modal.Title>Remove</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-confirm-wrap">
            <p>Are you sure you want to remove this item?</p>
          </div>
          <div className="savequery-pop-footer">
            <div className="d-flex justify-content-end double-btn m-t-15">
              <button className="btn grey-border-btn" onClick={handleClose}>
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                onClick={() => {
                  removeSingleBookmark();
                  handleClose();
                }}
              >
                Remove
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={deleteShow} size="sm" centered className="common-modal">
        <Modal.Header
          closeButton
          onClick={handleDeleteClose}
          className="m-b-15"
        >
          <Modal.Title>Remove</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-confirm-wrap">
            <p>Are you sure you want to remove these items?</p>
          </div>
          <div className="savequery-pop-footer">
            <div className="d-flex justify-content-end double-btn m-t-15">
              <button
                className="btn grey-border-btn"
                onClick={handleDeleteClose}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                onClick={() => {
                  removeMultipleRecords();
                  handleDeleteClose();
                }}
              >
                Remove
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={shows} size="md" className="common-modal side-popup">
        <Modal.Header
          closeButton
          onClick={() => {
            setShareFunction();
            handleCloses();
          }}
        >
          <Modal.Title>Manage access</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="country-pop-wraper">
            <MultipleRecordPopup
              onChangeShare={onChangeShare}
              rowId={rowId}
              CountryhandleCloses={()=>{
                handleCloses()
              }}
              offset={offset}
            />
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={recordShow} size="md" className="common-modal side-popup">
        <Modal.Header closeButton onClick={handleRecordClose}>
          <Modal.Title>Manage access</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="country-pop-wraper">
            <IndividualRecord
              rowId={savedRecordId}
              projectId={projectId}
              CountryhandleCloses={handleRecordClose}
            />
          </div>
        </Modal.Body>
      </Modal>
      {/* <ToastContainer/> */}
    </div>
  );
}

export default MyBookmark;
