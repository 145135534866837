import React,{useState,useEffect,useCallback,useRef} from 'react';
import { Formik } from 'formik';
import Paginate from "../../../../shared/dataTablePagination";
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import {listFaqs,addFaq} from './../../services'
import {useDispatch,useSelector,shallowEqual} from 'react-redux'
import Form from 'react-bootstrap/Form';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SearchEngineFaq({search}) {
    const dispatch = useDispatch()
    const active = useSelector((state)=>state?.faq?.saved_tab)
    const token = useSelector((store)=>store?.auth?.signin?.response?.data?.data?.token,shallowEqual)
    const tokenSso = useSelector(
        (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token,shallowEqual
      );
    const result = useSelector((state)=>state?.faq?.list_faq?.response?.data?.data?.results,shallowEqual)  
    const data = useSelector((state)=>state?.faq?.list_faq?.response?.data?.data,shallowEqual)
    const loading = useSelector((state)=>state?.faq?.list_faq?.loading)  
    const add_faq_state = useSelector((state)=>state?.faq?.add_faq)
    const add_faq_state_ref = useRef(add_faq_state)
    add_faq_state_ref.current = add_faq_state
    const [faq,setFaq] = useState([])

    
    const [offset,setOffset] = useState(0)
    useEffect(()=>{
        if(result){
            setFaq(result?.filter((item)=>item?.is_chat===false))
        }
    },[result])

    //list faqs on page load and change in offset
    useEffect(()=>{
        if(token!==undefined){
            dispatch(listFaqs(token,search,offset))
        }else if(tokenSso!==undefined){
            dispatch(listFaqs(tokenSso,search,offset))
        }
    },[offset])  

      const validate =(values)=>{
        const errors = {}
        if(!values.questions){
            errors.questions = '*please enter your question'
        }
        return errors
      }
      const handleSubmit =(values,{ resetForm })=>{
        const data = {
            "query" : values.questions
        }
        if(token!==undefined){
            dispatch(addFaq(token,data)).then(()=>{
                if(!add_faq_state_ref.current?.hasError){
                    toast.success("Your query has been successfully sent")
                    resetForm()
                }else {
                    toast.error("something went wrong")
                }
            })
        }else if(tokenSso!==undefined){
            dispatch(addFaq(tokenSso,data)).then(()=>{
                if(!add_faq_state_ref.current?.hasError){
                    toast.success("Your query has been successfully sent")
                     resetForm()
                }else{
                    toast.error("something went wrong")
                }
            })
        }
      }
      const [currentPage, setCurrentPage] = useState(0);

      const handlePage = (data) => {
        setOffset(data?.selected * 3);
        setCurrentPage(data?.selected);
      };
      const [selectedItem, setSelectedItem] = useState(null)
      const handleClick = id => {
        setSelectedItem(id)
      }

      useEffect(()=>{
        setOffset(0)
        setCurrentPage(0)
      },[search])
  return (
    <div>
    <div className="faq-accordian custom-accordian">
      {loading?   <div className="loader-middle-align">
              <div className="loader loader-3">
                <div className="dot dot1">&nbsp;</div>
                <div className="dot dot2">&nbsp;</div>
                <div className="dot dot3">&nbsp;</div>
              </div>
            </div>:
              <Accordion>
              {faq.length>0?faq.map((item,i)=>(
                  <Accordion.Item eventKey={i}>
                  <Accordion.Header>
                      <div className="custom-accordian-header">
                          <span className="faq-index">{(currentPage)*3 + (i+1)}.</span>
                          <h4>{item?.question}</h4>
                      </div>
                  </Accordion.Header>
                  <Accordion.Body
                  open={item.id === selectedItem}
                  onClick={()=>{
                      handleClick(item.id)
                  }}
                  >
                    {item?.answer}
                  </Accordion.Body>
              </Accordion.Item>
              )):!loading?<p className="no-results-wraper">No results found</p>: <div className="loader-middle-align">
              <div className="loader loader-3">
                <div className="dot dot1">&nbsp;</div>
                <div className="dot dot2">&nbsp;</div>
                <div className="dot dot3">&nbsp;</div>
              </div>
            </div>}
              
          </Accordion>}
      
    </div>
    <div className="pagination-wrap m-t-20">
        {/* <CustomPagination total={(data?.count/3)} current={page} onChangePage={handleChangePage} onChangeOffset={handleOffsetChange}/> */}
        {data?.count>0&&
        <Paginate
        pageCount={Math.ceil(data?.count / 3)}
        onPageChange={handlePage}
        initialPage={currentPage}
        />
        }
        
    </div>
    <div className="faq-footer-sec m-t-50">
        <h3>Didn’t find the answer you are looking for?</h3>
        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas venenatis</p> */}
        <Formik initialValues={{
            questions:""
        }}
        onSubmit={handleSubmit}
        validate={validate}
        >
        {({ handleSubmit, handleChange, values, errors, touched}) => (
        <Form className="w-100 m-t-20" onSubmit={handleSubmit}>
            <Form.Control as="textarea" className="textarea-item" placeholder="share your questions with us....."
            name='questions' 
            value={values.questions}
           onChange={handleChange}/>
            {errors.questions && touched.questions ? (
        <span style={{ color: "red" }}>
          {errors.questions}
        </span>
      ) : null}
             <div className="btn-wrap d-flex justify-content-end m-t-20">
            <Button type='submit'>Send</Button>
        </div>
        </Form>
          )}
        </Formik>        

       
    </div>
    </div>
  );
}

export default SearchEngineFaq;
