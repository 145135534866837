import { createElement } from 'react';
import {createAction,handleActions} from 'redux-actions';
import ActionTypes from '~shared/constants/actionTypes';

// import {store} from  '../../../redux/store';

const initialState = {
    econ_org_filter : [],
    econ_org_data : [],
    econ_org_handler : [],
    econ_country_filter : [],
    econ_country_data : [],
    econ_country_handler : [],
    econ_region_filter : [],
    econ_region_data : [],
    econ_region_handler : [],
    econ_sector_filter : [],
    econ_sector_data : [],
    econ_sector_handler : [],
    econ_topic_filter : [],
    econ_topic_data : [],
    econ_topic_handler : [],
    econ_popup : true,
    econ_popup_data : [],
    econ_filter_popup_countries : [],
    econ_ignore_query : false,
    date_var_econ : true,
    econ_start_date_handler : null,
    econ_end_date_handler : null,
    
  }

//Econ 
const setEconOrgArray = createAction(ActionTypes.SET_ECON_ORG_FILTER, (data) => data);

const setEconCountryArray = createAction(ActionTypes.SET_ECON_COUNTRY_FILTER)

export const setEconCountryFilter = (data) => (dispatch) => {
  dispatch(setEconCountryArray(data))
}

export const setEconOrgFilter = (data) => (dispatch) => {
  dispatch(setEconOrgArray(data));
};

const setEconCountry = createAction(ActionTypes.SET_ECON_COUNTRY_DATA)

export const setEconCountryData = (data) => (dispatch) => {
  dispatch(setEconCountry(data))
}

const setEconCountryHandlerArray = createAction(ActionTypes.SET_ECON_COUNTRY_HANDLER)

export const setEconCountryHandler = (data) => (dispatch) =>{
  dispatch(setEconCountryHandlerArray(data))
}

const setEconOrg = createAction(ActionTypes.SET_ECON_ORG_DATA)

export const setEconOrgData = (data) => (dispatch) => {
  dispatch(setEconOrg(data))
}

const setEconOrgHandlerArray = createAction(ActionTypes.SET_ECON_ORG_HANDLER)

export const setEconOrgHandler = (data) => (dispatch) => {
  dispatch(setEconOrgHandlerArray(data))
}

const setEconRegionArray =  createAction(ActionTypes.SET_ECON_REGION_FILTER)

export const setEconRegionFilter = (data) =>(dispatch) =>{
  dispatch(setEconRegionArray(data))
}

const setEconRegionAction = createAction(ActionTypes.SET_ECON_REGION_DATA)

export const setEconRegionData = (data) => (dispatch) =>{
  dispatch(setEconRegionAction(data))
}

const setEconRegionHandlerAction = createAction(ActionTypes.SET_ECON_REGION_HANDLER)

export const setEconRegionHandler = (data) =>(dispatch)=>{
  dispatch(setEconRegionHandlerAction(data))
}

const setEconSectorArray =  createAction(ActionTypes.SET_ECON_SECTOR_FILTER)

export const setEconSectorFilter = (data) =>(dispatch) =>{
  dispatch(setEconSectorArray(data))
}

const setEconSectorAction = createAction(ActionTypes.SET_ECON_SECTOR_DATA)

export const setEconSectorData = (data) => (dispatch) =>{
  dispatch(setEconSectorAction(data))
}

const setEconSectorHandlerAction = createAction(ActionTypes.SET_ECON_SECTOR_HANDLER)

export const setEconSectorHandler = (data) =>(dispatch)=>{
  dispatch(setEconSectorHandlerAction(data))
}

const setEconTopicArray =  createAction(ActionTypes.SET_ECON_TOPIC_FILTER)

export const setEconTopicFilter = (data) =>(dispatch) =>{
  dispatch(setEconTopicArray(data))
}

const setEconTopicAction = createAction(ActionTypes.SET_ECON_TOPIC_DATA)

export const setEconTopicData = (data) => (dispatch) =>{
  dispatch(setEconTopicAction(data))
}

const setEconTopicHandlerAction = createAction(ActionTypes.SET_ECON_TOPIC_HANDLER)

export const setEconTopicHandler = (data) =>(dispatch)=>{
  dispatch(setEconTopicHandlerAction(data))
}

const setEconPopupAction = createAction(ActionTypes.SET_ECON_POPUP)

export const setEconPopupFunction = (data) =>(dispatch)=>{
  dispatch(setEconPopupAction(data))
}

const setEconPopupDataAction = createAction(ActionTypes.SET_ECON_POPUP_DATA)

export const setEconPopupDataFunction = (data) =>(dispatch) =>{
  dispatch(setEconPopupDataAction(data))
}

const setEconInitalCountryAction = createAction(ActionTypes.SET_INITIAL_COUNTRIES)
export const setEconIntialCountry = (data)=>(dispatch)=>{
  dispatch(setEconInitalCountryAction(data))
}

const setEconIgnoreQueryAction = createAction(ActionTypes.SET_ECON_IGNORE_QUERY)
export const setEconIgnoreQuery = (data) => (dispatch) =>{
  dispatch(setEconIgnoreQueryAction(data))
}

const setDateTriggerActionEcon = createAction(ActionTypes.SET_DATE_TRIGGER_ECON)
export const setDateTriggerEcon = (data)=>(dispatch)=>{
  dispatch(setDateTriggerActionEcon(data))
}

const setEconStartDateAction = createAction(ActionTypes.SET_ECON_START_DATE)
export const setEconStartDate = (data)=>(dispatch)=>{
  dispatch(setEconStartDateAction(data))
}

const setEconEndDateAction = createAction(ActionTypes.SET_ECON_END_DATE)
export const setEconEndDate = (data)=>(dispatch)=>{
  dispatch(setEconEndDateAction(data))
}

const reducer = handleActions({
   
[ActionTypes.SET_ECON_ORG_FILTER]: (state, action) => ({
        ...state,
        econ_org_filter: action.payload,
      }),
[ActionTypes.SET_ECON_COUNTRY_FILTER]: (state, action) => ({
        ...state,
        econ_country_filter : action.payload,
      }),
[ActionTypes.SET_ECON_COUNTRY_DATA]: (state, action) => ({
        ...state,
        econ_country_data : action.payload,
      }),
  [ActionTypes.SET_ECON_COUNTRY_HANDLER]: (state, action) => ({
        ...state,
        econ_country_handler : action.payload,
      }),   
  [ActionTypes.SET_ECON_ORG_DATA]: (state, action) => ({
        ...state,
        econ_org_data : action.payload,
      }),            
  [ActionTypes.SET_ECON_ORG_HANDLER]: (state, action) => ({
        ...state,
        econ_org_handler : action.payload,
      }),
  [ActionTypes.SET_ECON_REGION_FILTER]: (state, action) => ({
        ...state,
        econ_region_filter : action.payload,
      }),  
  [ActionTypes.SET_ECON_REGION_DATA]: (state, action) => ({
        ...state,
        econ_region_data : action.payload,
      }),                     
  [ActionTypes.SET_ECON_REGION_HANDLER]: (state, action) => ({
        ...state,
        econ_region_handler : action.payload,
      }),
  [ActionTypes.SET_ECON_SECTOR_FILTER]: (state, action) => ({
        ...state,
        econ_sector_filter : action.payload,
      }),  
  [ActionTypes.SET_ECON_SECTOR_DATA]: (state, action) => ({
        ...state,
        econ_sector_data : action.payload,
      }),                     
  [ActionTypes.SET_ECON_SECTOR_HANDLER]: (state, action) => ({
        ...state,
        econ_sector_handler : action.payload,
      }), 
  [ActionTypes.SET_ECON_TOPIC_FILTER]: (state, action) => ({
        ...state,
        econ_topic_filter : action.payload,
      }),  
  [ActionTypes.SET_ECON_TOPIC_DATA]: (state, action) => ({
        ...state,
        econ_topic_data : action.payload,
      }),                     
  [ActionTypes.SET_ECON_TOPIC_HANDLER]: (state, action) => ({
        ...state,
        econ_topic_handler : action.payload,
      }),   
  [ActionTypes.SET_ECON_POPUP] : (state,action) =>({
    ...state,
    econ_popup : action.payload,
  }),
  [ActionTypes.SET_ECON_POPUP_DATA] : (state,action) =>({
    ...state,
    econ_popup_data : action.payload,
  }),
  [ActionTypes.SET_INITIAL_COUNTRIES] : (state,action) =>({
    ...state,
    econ_filter_popup_countries : action.payload,
  }),
  [ActionTypes.SET_ECON_IGNORE_QUERY] : (state,action) =>({
    ...state,
    econ_ignore_query : action.payload,
  }) ,
  [ActionTypes.SET_DATE_TRIGGER_ECON]: (state, action) => ({
    ...state,
    date_var_econ : action.payload,
  }), 
  [ActionTypes.SET_ECON_START_DATE]: (state, action) => ({
    ...state,
    econ_start_date_handler : action.payload,
  }),
[ActionTypes.SET_ECON_END_DATE]: (state, action) => ({
    ...state,
    econ_end_date_handler : action.payload,
  }),                      
},initialState);

export default reducer;