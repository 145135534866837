import { createElement } from 'react';
import {createAction,handleActions} from 'redux-actions';
import ActionTypes from '~shared/constants/actionTypes';

// import {store} from  '../../../redux/store';

const initialState = {
    other_org_filter : [],
    other_org_data : [],
    other_org_handler : [],
    other_country_filter : [],
    other_country_data : [],
    other_country_handler : [],
    other_region_filter : [],
    other_region_data : [],
    other_region_handler : [],
    other_sector_filter : [],
    other_sector_data : [],
    other_sector_handler : [],
    other_topic_filter : [],
    other_topic_data : [],
    other_topic_handler : [],
    other_search_country_array : [],
    date_var_other : true,
    other_start_date_handler : null,
    other_end_date_handler : null,
    
    
  }

//Econ 
const setOtherOrgArray = createAction(ActionTypes.SET_OTHER_ORG_FILTER, (data) => data);

const setOtherCountryArray = createAction(ActionTypes.SET_OTHER_COUNTRY_FILTER)

export const setOtherCountryFilter = (data) => (dispatch) => {
  dispatch(setOtherCountryArray(data))
}

export const setOtherOrgFilter = (data) => (dispatch) => {
  dispatch(setOtherOrgArray(data));
};

const setOtherCountry = createAction(ActionTypes.SET_OTHER_COUNTRY_DATA)

export const setOtherCountryData = (data) => (dispatch) => {
  dispatch(setOtherCountry(data))
}

const setOtherCountryHandlerArray = createAction(ActionTypes.SET_OTHER_COUNTRY_HANDLER)

export const setOtherCountryHandler = (data) => (dispatch) =>{
  dispatch(setOtherCountryHandlerArray(data))
}

const setOtherOrg = createAction(ActionTypes.SET_OTHER_ORG_DATA)

export const setOtherOrgData = (data) => (dispatch) => {
  dispatch(setOtherOrg(data))
}

const setOtherOrgHandlerArray = createAction(ActionTypes.SET_OTHER_ORG_HANDLER)

export const setOtherOrgHandler = (data) => (dispatch) => {
  dispatch(setOtherOrgHandlerArray(data))
}

const setOtherRegionArray =  createAction(ActionTypes.SET_OTHER_REGION_FILTER)

export const setOtherRegionFilter = (data) =>(dispatch) =>{
  dispatch(setOtherRegionArray(data))
}

const setOtherRegionAction = createAction(ActionTypes.SET_OTHER_REGION_DATA)

export const setOtherRegionData = (data) => (dispatch) =>{
  dispatch(setOtherRegionAction(data))
}

const setOtherRegionHandlerAction = createAction(ActionTypes.SET_OTHER_REGION_HANDLER)

export const setOtherRegionHandler = (data) =>(dispatch)=>{
  dispatch(setOtherRegionHandlerAction(data))
}

const setOtherSectorArray =  createAction(ActionTypes.SET_OTHER_SECTOR_FILTER)

export const setOtherSectorFilter = (data) =>(dispatch) =>{
  dispatch(setOtherSectorArray(data))
}

const setOtherSectorAction = createAction(ActionTypes.SET_OTHER_SECTOR_DATA)

export const setOtherSectorData = (data) => (dispatch) =>{
  dispatch(setOtherSectorAction(data))
}

const setOtherSectorHandlerAction = createAction(ActionTypes.SET_OTHER_SECTOR_HANDLER)

export const setOtherSectorHandler = (data) =>(dispatch)=>{
  dispatch(setOtherSectorHandlerAction(data))
}

const setOtherTopicArray =  createAction(ActionTypes.SET_OTHER_TOPIC_FILTER)

export const setOtherTopicFilter = (data) =>(dispatch) =>{
  dispatch(setOtherTopicArray(data))
}

const setOtherTopicAction = createAction(ActionTypes.SET_OTHER_TOPIC_DATA)

export const setOtherTopicData = (data) => (dispatch) =>{
  dispatch(setOtherTopicAction(data))
}

const setOtherTopicHandlerAction = createAction(ActionTypes.SET_OTHER_TOPIC_HANDLER)

export const setOtherTopicHandler = (data) =>(dispatch)=>{
  dispatch(setOtherTopicHandlerAction(data))
}

const setOtherSearchCountryAction = createAction(ActionTypes.SET_OTHER_SEARCH_COUNTRY)

export const setOtherSearchCountry = (data) =>(dispatch)=>{
  dispatch(setOtherSearchCountryAction(data))
}

const setDateTriggerActionOther = createAction(ActionTypes.SET_DATE_TRIGGER_OTHER)
export const setDateTriggerOther = (data)=>(dispatch)=>{
  dispatch(setDateTriggerActionOther(data))
}

const setOtherStartDateAction = createAction(ActionTypes.SET_OTHER_START_DATE)
export const setOtherStartDate = (data)=>(dispatch)=>{
  dispatch(setOtherStartDateAction(data))
}

const setOtherEndDateAction = createAction(ActionTypes.SET_OTHER_END_DATE)
export const setOtherEndDate = (data)=>(dispatch)=>{
  dispatch(setOtherEndDateAction(data))
}


const reducer = handleActions({
   
[ActionTypes.SET_OTHER_ORG_FILTER]: (state, action) => ({
        ...state,
        other_org_filter: action.payload,
      }),
[ActionTypes.SET_OTHER_COUNTRY_FILTER]: (state, action) => ({
        ...state,
        other_country_filter : action.payload,
      }),
[ActionTypes.SET_OTHER_COUNTRY_DATA]: (state, action) => ({
        ...state,
        other_country_data : action.payload,
      }),
  [ActionTypes.SET_OTHER_COUNTRY_HANDLER]: (state, action) => ({
        ...state,
        other_country_handler : action.payload,
      }),   
  [ActionTypes.SET_OTHER_ORG_DATA]: (state, action) => ({
        ...state,
        other_org_data : action.payload,
      }),            
  [ActionTypes.SET_OTHER_ORG_HANDLER]: (state, action) => ({
        ...state,
        other_org_handler : action.payload,
      }),
  [ActionTypes.SET_OTHER_REGION_FILTER]: (state, action) => ({
        ...state,
        other_region_filter : action.payload,
      }),  
  [ActionTypes.SET_OTHER_REGION_DATA]: (state, action) => ({
        ...state,
        other_region_data : action.payload,
      }),                     
  [ActionTypes.SET_OTHER_REGION_HANDLER]: (state, action) => ({
        ...state,
        other_region_handler : action.payload,
      }),
  [ActionTypes.SET_OTHER_SECTOR_FILTER]: (state, action) => ({
        ...state,
        other_sector_filter : action.payload,
      }),  
  [ActionTypes.SET_OTHER_SECTOR_DATA]: (state, action) => ({
        ...state,
        other_sector_data : action.payload,
      }),                     
  [ActionTypes.SET_OTHER_SECTOR_HANDLER]: (state, action) => ({
        ...state,
        other_sector_handler : action.payload,
      }), 
  [ActionTypes.SETother_TOPIC_FILTER]: (state, action) => ({
        ...state,
        other_topic_filter : action.payload,
      }),  
  [ActionTypes.SET_OTHER_TOPIC_DATA]: (state, action) => ({
        ...state,
        other_topic_data : action.payload,
      }),                     
  [ActionTypes.SET_OTHER_TOPIC_HANDLER]: (state, action) => ({
        ...state,
        other_topic_handler : action.payload,
      }),
  [ActionTypes.SET_OTHER_SEARCH_COUNTRY]: (state, action) => ({
        ...state,
        other_search_country_array : action.payload,
      }),    
  [ActionTypes.SET_DATE_TRIGGER_OTHER]: (state, action) => ({
        ...state,
        date_var_other : action.payload,
      }),     
  [ActionTypes.SET_OTHER_START_DATE]: (state, action) => ({
        ...state,
        other_start_date_handler : action.payload,
      }),
  [ActionTypes.SET_OTHER_END_DATE]: (state, action) => ({
        ...state,
        other_end_date_handler : action.payload,
      }),                             
},initialState);

export default reducer;