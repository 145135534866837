import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/esm/Button';
import background from "../../../../../assets/images/landing-banner-bg.jpg";
import {Link as Li} from 'react-scroll';
import Typewriter from 'typewriter-effect';



function LandSection1() {
    return (
        <div className="landing-section-1 p-relative">
            <div className="landing-section-bg landing-section-bg-right" style={{ backgroundImage: `url(${background})` }}>
                &nbsp;
            </div>
            <Container>
                <Row className="bg-over-text land-section-1-row">
                    <Col md={6} className="d-flex align-items-center justify-content-center">
                        <div className="sec1-content">
                            <h4>Transforming development research.</h4>
                            <h2 className="d-flex justify-content-center justify-content-md-start">
                                <Typewriter
                                    options={{
                                        strings: ['Search  l  Chat  l  Generate'],
                                        autoStart: true,
                                        loop: true,
                                        pauseFor:10000000000000000,
                                        // deleteSpeed:50,
                                        delay:40,
                                    }}
                                />
                            </h2>
                            <p>One-stop knowledge discovery platform designed for development professionals.</p>
                            <div className="btn-wrap">
                            <Li to='read-more'> <button className="btn border-btn">Read more<span className="svg-down-arrow">
                                    <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 0.736842L5.5 6L0 0.736842L0.78375 0L5.5 4.51316L10.2163 0L11 0.736842Z" fill="white" />
                                    </svg>
                                </span></button></Li>
                            </div>

                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default LandSection1;