import React from "react";

function TickIcon() {
  return (
    <span className="icon-wrap">
     <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
<g clip-path="url(#clip0_3_13480)">
<path d="M14.0999 37.9L6.09991 30C5.75343 29.6459 5.54332 29.1806 5.50672 28.6865C5.47012 28.1925 5.60939 27.7013 5.89991 27.3C6.06177 27.059 6.27671 26.8583 6.52822 26.7133C6.77974 26.5683 7.06113 26.4829 7.35081 26.4635C7.64048 26.4442 7.93073 26.4916 8.19927 26.6019C8.4678 26.7122 8.70748 26.8826 8.89991 27.1L15.4999 33.7L39.0999 10.1C39.485 9.81116 39.9615 9.67092 40.4417 9.70505C40.9219 9.73917 41.3737 9.94538 41.7141 10.2858C42.0545 10.6262 42.2607 11.078 42.2949 11.5582C42.329 12.0385 42.1888 12.5149 41.8999 12.9L16.8999 37.9C16.7219 38.094 16.5056 38.2489 16.2646 38.3548C16.0236 38.4608 15.7632 38.5155 15.4999 38.5155C15.2366 38.5155 14.9763 38.4608 14.7352 38.3548C14.4942 38.2489 14.2779 38.094 14.0999 37.9Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_3_13480">
<rect width="48" height="48" fill="white"/>
</clipPath>
</defs>
</svg>
    </span>
  );
}

export default TickIcon;
