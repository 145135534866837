// import React, { useState,useEffect } from 'react';
// import { Container } from 'react-bootstrap';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import ResultFilter from './components/filters';
// import ShowMoreText from "react-show-more-text";
// import { useSelector,shallowEqual } from "react-redux";
// import Tippy from "@tippy.js/react";
// import 'tippy.js/dist/tippy.css'
// import { FadeLoader } from "react-spinners";
// import {voteUp} from './../services'
// import {useDispatch} from 'react-redux';
// import BackArrow from '../../../assets/images/icons/backarrow.svg';
// import { Link } from 'react-router-dom';
// import {viewSimilar,bookmarkRecords, userListRecordShared,UserListSaveQuery} from './../../results/services'
// import ShareRecordPopup from "./../../../shared/shareRecordPopup";
// import { Modal } from 'react-bootstrap';
// import ReactPlayer from 'react-player'
// function EconpubsRecomendation() {

//     const state = useSelector((state)=>state?.results?.view_similar?.response?.data?.data?.items)
//     console.log(state);
//     const [AccessShow, AccessSetShow] = useState(false);
//     const AccesshandleClose = () => AccessSetShow(false);
//     const AccesshandleShow = () => AccessSetShow(true);
//     const [liked, setLiked] = useState(true);
//     const [bookmarked, setBookmarked] = useState(true);
//     const viewSimilarId = useSelector((state)=>state?.results?.viewSimilarId)
//     console.log(viewSimilarId,"project id of selected record");
//     const dispatch = useDispatch()
//     const result = useSelector((state)=>state?.results?.keyword_search?.response?.data?.data?.items)
//     const loading = useSelector((state)=>state?.results?.view_similar?.loading)
//     const token = useSelector((store)=>store?.auth?.signin?.response?.data?.data?.token,shallowEqual)
//     const tokenSso = useSelector(
//         (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token,
//         shallowEqual
//       );
//     console.log(loading,"loading");
//     ;
//     const [projId, setProjId] = useState();
//     const [projTitle, setProjTitle] = useState();
//     const [proAbs, setProjAbs] = useState();
//     const [projLink, setProjLink] = useState();
//     const [projOrgId, setProOrgId] = useState();

//     const [filterOrg,setFilterOrg] = useState("")
//     console.log(filterOrg,"filter for source");
//     const fetchOrgFilter =(data)=>{
//         setFilterOrg(data)
//     }
//     const [filterRegion,SetFilterRegion] = useState("")
//     const fetchRegionFilter =(data)=>{
//       SetFilterRegion(data)
//     }
//     const [filterCountry,setFilterCountry] = useState("")
//     const fetchCountryFilter =(data)=>{
//       setFilterCountry(data)
//     }
//     const [sectorFilter,setSectorFilter] = useState("")
//     const fetchSectorFilter =(data)=>{
//       setSectorFilter(data)
//     }
//     const [filterTopic,setFilterTopic] = useState("")
//     const fetchTopicFilter =(data)=>{
//       setFilterTopic(data)
//     }
//     const [filterStartDate,setFilterStartDate] = useState("")
//     console.log(filterStartDate,"start date recomendations");
//     const fetchStartDateFilter =(data)=>{
//       setFilterStartDate(data)
//     }
//     const [filterEndDate,setFilterEndDate] = useState("")
//     const fetchEndFilterDate =(data)=>{
//       setFilterEndDate(data)
//     }
//     const toggleVote = (
//         project_id,
//         name,
//         short_description,
//         project_link,
//         category,
//         org_id,
//         like
//       ) => {
//         const data = {
//           project_id: project_id,
//           title: name,
//           abstract: short_description,
//           link_html: project_link,
//           category: category,
//           like: like,
//           organisation_id: org_id,
//         };
//         if (token !== undefined) {
//           dispatch(voteUp(data, token)).then(() => {
//             setLiked(!liked);
//           });
//         } else if (tokenSso !== undefined) {
//           dispatch(voteUp(data, tokenSso)).then(() => {
//             setLiked(!liked);
//           });
//         }
//       };
//     const toggleBookmark = (
//         project_id,
//         title,
//         abstract,
//         link_html,
//         category,
//         bookmarks,
//         organisation_id
//       ) => {
//         const data = {
//           project_id: project_id,
//           title: title,
//           abstract: abstract,
//           link_html: link_html,
//           category: category,
//           bookmark: bookmarks,
//           organisation_id: organisation_id,
//         };
//         if (token !== undefined) {
//           dispatch(bookmarkRecords(data, token)).then(() => {
//             setBookmarked(!bookmarked);
//           });
//         } else if (tokenSso !== undefined) {
//           dispatch(bookmarkRecords(data, tokenSso)).then(() => {
//             setBookmarked(!bookmarked);
//           });
//         }
//       };
//       const userList = () => {
//         if (token !== undefined) {
//           dispatch(UserListSaveQuery(token, "", "", "", ""));
//         } else if (tokenSso !== undefined) {
//           dispatch(UserListSaveQuery(tokenSso, "", "", "", ""));
//         }
//       };
//       const recordUserList = (projectId) => {
//         if (token !== undefined) {
//           dispatch(userListRecordShared(projectId, token));
//         } else if (tokenSso !== undefined) {
//           dispatch(userListRecordShared(projectId, tokenSso));
//         }
//       };
//     useEffect(()=>{
//         if(viewSimilarId!==""){
//             if(token!==undefined){
//                 dispatch(viewSimilar(token,"multimedia",viewSimilarId,filterOrg,filterRegion,filterCountry,sectorFilter,filterTopic,filterStartDate,filterEndDate))
//             }else if(tokenSso!==undefined){
//                 dispatch(viewSimilar(tokenSso,"multimedia",viewSimilarId,filterOrg,filterRegion,filterCountry,sectorFilter,filterTopic,filterStartDate,filterEndDate))
//             }
//         }

//     },[filterOrg,filterRegion,filterCountry,sectorFilter,filterTopic,filterStartDate,filterEndDate])

//     return (
//         <div className="financerecomendation-wrap">
//           <Container>
//               <div className="recomendation-header m-t-30 m-b-40">
//               <div className="backarrow-wrap d-flex align-items-center">
//                             <Link to="/home"><span><img src={BackArrow}/></span></Link><h4>Showing recommendations for Multimedia</h4>
//                         </div>
//               </div>
//           <Row>
//                 <Col md={3}>
//                     <ResultFilter
//                     fetchOrgFilter={fetchOrgFilter}
//                     fetchRegionFilter={fetchRegionFilter}
//                     fetchCountryFilter={fetchCountryFilter}
//                     fetchSectorFilter={fetchSectorFilter}
//                     fetchTopicFilter={fetchTopicFilter}
//                     fetchStartDateFilter={fetchStartDateFilter}
//                     fetchEndFilterDate={fetchEndFilterDate}
//                     />
//                 </Col>

//                 <Col lg={9}>

//                     <div className="multi-result-contents">
//                         <Row>
//                         {loading? <div className="loader-middle-align">
//               <div className="loader loader-3">
//                 <div className="dot dot1">&nbsp;</div>
//                 <div className="dot dot2">&nbsp;</div>
//                 <div className="dot dot3">&nbsp;</div>
//               </div>
//             </div>:
//                 <>
//                             { state ? state.map((item,i) => (
//                                 <Col md={4}>
//                                     <div className="sourcebox source-1" key={i}>
//                                         <div className="video-frame-wrap m-b-20">


//                                             <ReactPlayer width="100%" height="100%" url={item?.link_html}
//                                             light={item?.snippet_thumbnails_medium_url
//                                             }
//                                             />
//                                         </div>
//                                         <div className="source-content">
//                                             <div className="d-flex multi-label-wrap m-b-20">
//                                                 <label className="source-label">{item.org_name}</label>
//                                             </div>
//                                         </div>
//                                         <a
//                         target="_blank"
//                         href={item?.link_html}
//                         style={{ textDecoration: "none", color: "inherit" }}
//                       >
//                         <h3>{item.title}</h3>
//                       </a>
//                                         <div className="source-footer m-t-25">

//                                  <button
//                           type="button"
//                           className="icon-button liked"
//                           onClick={() => {
//                             item?.record_details?.vote_up
//                               ? (toggleVote(
//                                   item?.project_id,
//                                   item?.title,
//                                   item?.abstract,
//                                   item?.link_html,
//                                   "multimedia",
//                                   item?.org_id,
//                                   0
//                                 ),
//                                 (item.record_details.vote_up = false),
//                                 (item.record_details.vote_up_count =
//                                   item.record_details.vote_up_count - 1))
//                               : (toggleVote(
//                                   item?.project_id,
//                                   item?.title,
//                                   item?.abstract,
//                                   item?.link_html,
//                                   "multimedia",
//                                   item?.org_id,
//                                   1
//                                 ),
//                                 (item.record_details.vote_up = true),
//                                 (item.record_details.vote_up_count =
//                                   item.record_details.vote_up_count + 1));
//                           }}
//                         >
//                           <span className="like-count">
//                             {item?.record_details?.vote_up_count}
//                           </span>
//                           {item.record_details?.vote_up ? (
//                             <svg
//                               width="22"
//                               height="22"
//                               viewBox="0 0 22 22"
//                               xmlns="http://www.w3.org/2000/svg"
//                               style={{ fill: "#1A73E8" }}
//                             >
//                               <path d="M18.3279 7.15H14.6623L14.8718 5.335C15.186 2.64 14.6623 0.495 12.9866 0H12.8295C12.6564 0.0319322 12.4937 0.109127 12.3564 0.224469C12.2192 0.339811 12.1117 0.48959 12.0441 0.66C12.0441 0.66 9.05923 7.26 7.33117 8.58V19.8H8.01192C8.1254 19.7899 8.23965 19.8042 8.34769 19.842C8.45574 19.8798 8.5553 19.9404 8.64031 20.02C9.37343 20.57 11.8346 22 13.0914 22H15.7097C18.7992 22 21.4699 19.8 21.7317 15.455L21.9935 11.055C22.0235 10.5387 21.9496 10.0215 21.7767 9.53721C21.6037 9.05287 21.3357 8.6122 20.9899 8.24385C20.6442 7.8755 20.2284 7.58774 19.7698 7.39928C19.3112 7.21082 18.8199 7.12588 18.3279 7.15ZM0 9.9V18.7C0 18.9917 0.110341 19.2715 0.30675 19.4778C0.503159 19.6841 0.769546 19.8 1.04731 19.8H5.23655V8.8H1.04731C0.769546 8.8 0.503159 8.91589 0.30675 9.12218C0.110341 9.32847 0 9.60826 0 9.9Z" />
//                             </svg>
//                           ) : (
//                             <svg
//                               width="23"
//                               height="22"
//                               viewBox="0 0 23 22"
//                               fill="none"
//                               xmlns="http://www.w3.org/2000/svg"
//                             >
//                               <path
//                                 d="M19.1635 7.15H15.3308L15.5498 5.335C15.8783 2.64 15.3308 0.495 13.5787 0H13.4144C13.2334 0.0319322 13.0633 0.109127 12.9197 0.224469C12.7762 0.339811 12.6639 0.48959 12.5931 0.66C12.5931 0.66 8.76044 8.8 7.11786 8.8H1.09505C0.804628 8.8 0.526097 8.91589 0.320734 9.12218C0.115371 9.32847 0 9.60826 0 9.9V18.7C0 18.9917 0.115371 19.2715 0.320734 19.4778C0.526097 19.6841 0.804628 19.8 1.09505 19.8H8.37717C8.49582 19.7899 8.61528 19.8042 8.72825 19.842C8.84121 19.8798 8.94532 19.9404 9.0342 20.02C9.80074 20.57 12.3741 22 13.6882 22H16.4258C19.6562 22 22.4486 19.8 22.7224 15.455L22.9961 11C23.0195 10.4882 22.9364 9.97707 22.752 9.49934C22.5677 9.02161 22.2862 8.58774 21.9255 8.22544C21.5649 7.86314 21.133 7.58038 20.6574 7.39521C20.1818 7.21003 19.673 7.12652 19.1635 7.15ZM2.19011 11H5.47527V17.6H2.19011V11ZM20.5323 15.29C20.3133 18.15 18.7802 19.8 16.4258 19.8H13.6882C13.0311 19.745 11.0601 18.755 10.3483 18.26C9.78349 17.8242 9.08929 17.5918 8.37717 17.6H7.66538V11C8.70568 10.78 10.403 9.79 13.4144 3.96C13.4211 4.34578 13.4028 4.7316 13.3597 5.115L13.1407 6.93L12.8669 9.35H19.1635C19.3805 9.3249 19.6004 9.34844 19.8073 9.41891C20.0141 9.48938 20.2029 9.60503 20.36 9.75756C20.5171 9.91008 20.6385 10.0957 20.7157 10.301C20.7928 10.5063 20.8237 10.7263 20.806 10.945L20.5323 15.29Z"
//                                 fill="#373737"
//                               />
//                             </svg>
//                           )}
//                         </button>
//                         <button
//                           type="button"
//                           className="icon-button"
//                           onClick={() => {
//                             AccesshandleShow(),
//                               recordUserList(item?.project_id),
//                               setProjId(item?.project_id);
//                             setProjTitle(item?.title);
//                             setProjAbs(item?.abstract);
//                             setProjLink(item?.link_html);
//                             setProOrgId(item?.org_id);
//                             userList();
//                           }}
//                         >
//                           <svg
//                             width="22"
//                             height="20"
//                             viewBox="0 0 22 20"
//                             fill="none"
//                             xmlns="http://www.w3.org/2000/svg"
//                           >
//                             <path
//                               d="M14.606 4.55599L19.508 10.0556L14.606 15.4996V11.5554L12.5051 11.6665C9.10373 11.8332 6.50267 12.222 4.45182 12.9998C6.25256 10.8888 9.10373 9.22228 12.8052 8.83343L14.606 8.66677V4.55599ZM13.1554 0.000794509C13.0765 0.00779243 12.9996 0.0320624 12.9291 0.0722063C12.8587 0.11235 12.7961 0.167575 12.7449 0.2347C12.6938 0.301825 12.6551 0.379523 12.6311 0.463321C12.6071 0.547118 12.5983 0.635359 12.6052 0.722959V6.61138C5.00205 7.44465 0.100041 13.222 0 19.0548C0 19.3881 0.100041 19.6103 0.300123 19.6103C0.500205 19.6103 0.650267 19.4437 0.850349 18.9993C2.05084 15.9995 4.75195 14.2774 12.6052 13.8886V19.277C12.5983 19.3646 12.6071 19.4529 12.6311 19.5367C12.6551 19.6205 12.6938 19.6982 12.7449 19.7653C12.7961 19.8324 12.8587 19.8876 12.9291 19.9278C12.9996 19.9679 13.0765 19.9922 13.1554 19.9992C13.2337 20.0041 13.3119 19.9862 13.3821 19.9472C13.4523 19.9082 13.5121 19.8496 13.5556 19.777L21.8089 10.6111C21.932 10.4583 22 10.2605 22 10.0556C22 9.85059 21.932 9.65281 21.8089 9.50004L13.5556 0.222999C13.5121 0.150438 13.4523 0.0917596 13.3821 0.0527823C13.3119 0.013805 13.2337 -0.00411673 13.1554 0.000794509Z"
//                               fill="#373737"
//                             />
//                           </svg>
//                         </button>
//                         <button
//                           type="button"
//                           className="icon-button"
//                           onClick={() => {
//                             item?.record_details?.bookmark
//                               ? (toggleBookmark(
//                                   item?.project_id,
//                                   item?.title,
//                                   item?.abstract,
//                                   item?.link_html,
//                                   "multimedia",
//                                   0,
//                                   item?.org_id
//                                 ),
//                                 (item.record_details.bookmark = false))
//                               : (toggleBookmark(
//                                   item?.project_id,
//                                   item?.title,
//                                   item?.abstract,
//                                   item?.link_html,
//                                   "multimedia",
//                                   1,
//                                   item?.org_id
//                                 ),
//                                 (item.record_details.bookmark = true));
//                           }}
//                         >
//                           {item?.record_details?.bookmark ? (
//                             <svg
//                               id="Group_2176"
//                               data-name="Group 2176"
//                               xmlns="http://www.w3.org/2000/svg"
//                               width="22"
//                               height="22"
//                               viewBox="0 0 22 22"
//                             >
//                               <g
//                                 id="icons_Q2"
//                                 data-name="icons Q2"
//                                 transform="translate(-61 -9)"
//                               >
//                                 <path
//                                   id="Path_2366"
//                                   data-name="Path 2366"
//                                   d="M23.895,7.316V23.526l-4.4-3.358-1.389-1.042-1.389,1.042-4.4,3.358V7.316H23.895M11.158,5A1.158,1.158,0,0,0,10,6.158V25.842A1.158,1.158,0,0,0,11.158,27a1.389,1.389,0,0,0,.811-.289l6.137-4.689,6.137,4.689a1.389,1.389,0,0,0,.811.289,1.158,1.158,0,0,0,1.158-1.158V6.158A1.158,1.158,0,0,0,25.053,5Z"
//                                   transform="translate(54 4)"
//                                   fill="#0D6EFD"
//                                 />
//                                 <path
//                                   id="Path_2367"
//                                   data-name="Path 2367"
//                                   d="M-126.668-6515.948v18.35l6.819-5.212,6.819,5.212v-18.35Z"
//                                   transform="translate(192 6527)"
//                                   fill="#0D6EFD"
//                                 />
//                               </g>
//                               <rect
//                                 id="Rectangle_2567"
//                                 data-name="Rectangle 2567"
//                                 width="22"
//                                 height="22"
//                                 fill="none"
//                               />
//                             </svg>
//                           ) : (
//                             <svg
//                               id="Group_2175"
//                               data-name="Group 2175"
//                               xmlns="http://www.w3.org/2000/svg"
//                               width="22"
//                               height="22"
//                               viewBox="0 0 22 22"
//                             >
//                               <g
//                                 id="icons_Q2"
//                                 data-name="icons Q2"
//                                 transform="translate(-61 -9)"
//                               >
//                                 <path
//                                   id="Path_2366"
//                                   data-name="Path 2366"
//                                   d="M23.895,7.316V23.526l-4.4-3.358-1.389-1.042-1.389,1.042-4.4,3.358V7.316H23.895M11.158,5A1.158,1.158,0,0,0,10,6.158V25.842A1.158,1.158,0,0,0,11.158,27a1.389,1.389,0,0,0,.811-.289l6.137-4.689,6.137,4.689a1.389,1.389,0,0,0,.811.289,1.158,1.158,0,0,0,1.158-1.158V6.158A1.158,1.158,0,0,0,25.053,5Z"
//                                   transform="translate(54 4)"
//                                 />
//                               </g>
//                               <rect
//                                 id="Rectangle_2567"
//                                 data-name="Rectangle 2567"
//                                 width="22"
//                                 height="22"
//                                 fill="none"
//                               />
//                             </svg>
//                           )}
//                         </button>

//                                         </div>
//                                     </div>
//                                 </Col>
//                             )) : <p className="no-results-wraper">No results found</p>}
//                             </>}
//                         </Row>
//                     </div>

//                 </Col>

//             </Row>
//           </Container>
//           <Modal show={AccessShow} size="md" className="common-modal side-popup">
//         <Modal.Header closeButton onClick={AccesshandleClose}>
//           <Modal.Title>Manage access</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <div className="country-pop-wraper">
//             <ShareRecordPopup
//               projectId={projId}
//               title={projTitle}
//               abstract={proAbs}
//               link={projLink}
//               orgId={projOrgId}
//             />
//           </div>
//         </Modal.Body>
//       </Modal>
//         </div>
//     );
// };

// export default EconpubsRecomendation;


import React, { CSSProperties, useEffect, useState, useCallback, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReactPaginate from "react-paginate";
import ResultFilter from "./components/filters";
import { Container } from 'react-bootstrap';
import ShowMoreText from "react-show-more-text";
import { useSelector, shallowEqual } from "react-redux";
import Tippy from "@tippy.js/react";
import "tippy.js/dist/tippy.css";
import BackArrow from '../../../assets/images/icons/backarrow.svg';
import { Link } from 'react-router-dom';
import Spinner from "react-bootstrap/Spinner";
import ClipLoader from "react-spinners/ClipLoader";
import { FadeLoader } from "react-spinners";
import { voteUp } from "./../../results/services";
import ReactPlayer from 'react-player'
import { useDispatch } from "react-redux";
import {
  recordDetails,
  bookmarkRecords,
  userListRecordShared,
  UserListSaveQuery,
  keywordSearch,
  setFCountryKey,
  setFRegionKey,
  setFSectorKey,
  setFTopicKey,
  setFSourceKey,
  setFStartDateKey,
  setFEndDateKey,
  viewSimilar
} from "./../../../modules/results/services";

import ShareRecordPopup from "../../../shared/shareRecordPopup";
import InfiniteScroll from "react-infinite-scroll-component";
import { Modal } from "react-bootstrap";

import Paginate from "../../../shared/reactpagination";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet'
import moment from "moment";

function MultimediaRecomendation({ category }) {
  const results_state = useSelector((state) => state?.results);


  const bookmark_state = useSelector((state) => state?.results?.bookmark)
  const bookmark_ref = useRef(bookmark_state)
  bookmark_ref.current = bookmark_state



  const homeFlag = useSelector((state) => state?.filter?.from_home);


  const [AccessShow, AccessSetShow] = useState(false);
  const AccesshandleClose = () => AccessSetShow(false);
  const AccesshandleShow = () => AccessSetShow(true);
  const dispatch = useDispatch();
  const [liked, setLiked] = useState(true);
  const [bookmarked, setBookmarked] = useState(true);
  const result = useSelector(
    (state) => state?.results?.view_similar?.response?.data?.data?.items,
    shallowEqual
  );
  const count = useSelector(
    (state) =>
      state?.results?.view_similar?.response?.data?.data?.total_results
  );
  const req =
    useSelector(
      (state) => state?.results?.view_similar?.response?.request?.responseURL
    ) || "";

  const loading = useSelector(
    (state) => state?.results?.view_similar?.loading
  );

  const token = useSelector(
    (store) => store?.auth?.signin?.response?.data?.data?.token,
    shallowEqual
  );
  useEffect(()=>{
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
  },[skip])

  const shared = localStorage.getItem("shared");

  const [projId, setProjId] = useState();
  const [projTitle, setProjTitle] = useState();
  const [proAbs, setProjAbs] = useState();
  const [projLink, setProjLink] = useState();
  const [projOrgId, setProOrgId] = useState();

  const tokenSso = useSelector(
    (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token,
    shallowEqual
  );

  const userList = () => {
    if (token !== undefined) {
      dispatch(UserListSaveQuery(token, "", "", "", "","False"));
    } else if (tokenSso !== undefined) {
      dispatch(UserListSaveQuery(tokenSso, "", "", "", "","False"));
    }
  };

  const toggleVote = (
    project_id,
    name,
    short_description,
    project_link,
    category,
    org_id,
    like
  ) => {
    const data = {
      project_id: project_id,
      title: name,
      abstract: short_description,
      link_html: project_link,
      category: category,
      like: like,
      organisation_id: org_id,
    };
    if (token !== undefined) {
      dispatch(voteUp(data, token)).then(() => {
        setLiked(!liked);
      });
    } else if (tokenSso !== undefined) {
      dispatch(voteUp(data, tokenSso)).then(() => {
        setLiked(!liked);
      });
    }
  };

  const toggleBookmark = (
    project_id,
    title,
    abstract,
    link_html,
    category,
    bookmarks,
    organisation_id
  ) => {
    const data = {
      project_id: project_id,
      title: title,
      abstract: abstract,
      link_html: link_html,
      category: category,
      bookmark: bookmarks,
      organisation_id: organisation_id,
    };
    if (token !== undefined) {
      dispatch(bookmarkRecords(data, token)).then(() => {
        setBookmarked(!bookmarked);
        if (!bookmark_ref?.current?.hasError && bookmark_ref?.current?.response?.data?.data?.bookmark === "Record Bookmarked" && req.includes("mul")) {
          toast.success("Bookmarked successfully, please find it in the Bookmarks tab!")
        }
      });
    } else if (tokenSso !== undefined) {
      dispatch(bookmarkRecords(data, tokenSso)).then(() => {
        setBookmarked(!bookmarked);
        if (!bookmark_ref?.current?.hasError && bookmark_ref?.current?.response?.data?.data?.bookmark === "Record Bookmarked" && req.includes("mul")) {
          toast.success("Bookmarked successfully, please find it in the Bookmarks tab!")
        }
      });
    }
  };
  const recordUserList = (projectId) => {
    if (token !== undefined) {
      dispatch(userListRecordShared(projectId, token));
    } else if (tokenSso !== undefined) {
      dispatch(userListRecordShared(projectId, tokenSso));
    }
  };
  const [page, setPage] = useState(1);

  const viewSimilarId = useSelector((state) => state?.results?.viewSimilarId)



  const handleChangePage = useCallback((page) => {
    setPage(page);
  }, []);
  const handleOffsetChange = (offset) => {
    setSkip(offset);
  };
  const [skip, setSkip] = useState(0);


  const [currentPage, setCurrentPage] = useState(0)

  const fileType = typeof (file)

  const handlePage = (data) => {
    setSkip((data?.selected) * 12)
    setCurrentPage(data?.selected)
  }

  useEffect(() => {
    if (viewSimilarId !== "") {
      if (token !== undefined) {
        dispatch(viewSimilar(token, "multimedia", viewSimilarId, results_state?.financeSource,
          results_state?.financeRegion,
          results_state?.financeCountry,
          results_state?.financeSector,
          results_state?.financeTopic,
          results_state?.financeSdate,
          results_state?.financeEdate, 12, 0))
        setCurrentPage(0)
      } else if (tokenSso !== undefined) {
        dispatch(viewSimilar(tokenSso, "multimedia", viewSimilarId, results_state?.financeSource,
          results_state?.financeRegion,
          results_state?.financeCountry,
          results_state?.financeSector,
          results_state?.financeTopic,
          results_state?.financeSdate,
          results_state?.financeEdate, 12, 0))
        setCurrentPage(0)
      }
    }

  }, [results_state?.financeSource,
  results_state?.financeRegion,
  results_state?.financeCountry,
  results_state?.financeSector,
  results_state?.financeTopic,
  results_state?.financeSdate,
  results_state?.financeEdate])

  useEffect(() => {
    if (viewSimilarId !== "") {
      if (token !== undefined) {
        dispatch(viewSimilar(token, "multimedia", viewSimilarId, results_state?.financeSource,
          results_state?.financeRegion,
          results_state?.financeCountry,
          results_state?.financeSector,
          results_state?.financeTopic,
          results_state?.financeSdate,
          results_state?.financeEdate, 12, skip))
      } else if (tokenSso !== undefined) {
        dispatch(viewSimilar(tokenSso, "multimedia", viewSimilarId, results_state?.financeSource,
          results_state?.financeRegion,
          results_state?.financeCountry,
          results_state?.financeSector,
          results_state?.financeTopic,
          results_state?.financeSdate,
          results_state?.financeEdate, 12, skip))
      }
    }

  }, [skip])

  const [playVideoId,setPlayVideoId] = useState(null)
  const play =(playerId)=>{
    setPlayVideoId(playerId)
  }

  useEffect(()=>{
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
  },[skip])


  return (
    <div className="financerecomendation-wrap">
      <Helmet> <title>DevDiscovery | Recomendation | MultiMedia</title></Helmet>
      <Container>
        <div className="recomendation-header m-t-30 m-b-40">
          <div className="backarrow-wrap d-flex align-items-center">
            <Link to="/home"><span><img src={BackArrow} /></span></Link><h4>Showing recommendations for Multimedia</h4>
          </div>
        </div>
        <Row>
          <Col md={4} lg={3}>
            <ResultFilter />
          </Col>
          <Col md={8} lg={9}>
          <div className="recomendation-main-outer">

            <div className="multi-result-contents">
              <Row>
                {loading ?
                  <div className="loader-middle-align">
                    <div className="loader loader-3">
                      <div className="dot dot1">&nbsp;</div>
                      <div className="dot dot2">&nbsp;</div>
                      <div className="dot dot3">&nbsp;</div>
                    </div></div> :
                  <>
                    {req.includes("mul") && result ? result.map((item, i) => (
                      <Col lg={4} md={6} className="mutimedia-results-repeatbox">
                        <div className="sourcebox source-1 d-flex justify-content-between flex-column" key={i}>
                          <div className="flex-seperation-vertical">
                            <div className="video-frame-wrap m-b-20">


                              <ReactPlayer width="100%" height="100%" url={item?.link_html}
                                light={item?.snippet_thumbnails_medium_url
                                }
                                playing={playVideoId===item?.link_html}
                                // onPause={() => pause(item?.link_html)}
                                // onEnded={() => pause(item?.link_html)}
                                onPlay={() => play(item?.link_html)}
                                onClickPreview={() => play(item?.link_html)}
                                // playing={true}
                              // loop={true} 
                              />
                            </div>
                            <div className="source-content">
                              <div className="d-flex multi-label-wrap m-b-20">
                              {item?.org_name && (
                            <Tippy content={item?.org_name}>
                              <label className="source-label">
                                {item?.org_name}
                              </label>
                            </Tippy>
                          )}
                              </div>

                              {/* <h3>{item.title}
                                        </h3> */}
                              <a
                                target="_blank"
                                href={item?.link_html}
                                style={{ textDecoration: "none", color: "inherit" }}
                              >
                                <h3>{item?.title.length > 55 ? item.title.substring(0, 55) + "..." : item?.title}</h3>
                              </a> </div>
                          </div>
                          <div className="source-footer m-t-25 d-flex justify-content-between">
                            <div className="sorce-footer-button-container">

                              <button
                                type="button"
                                className="icon-button liked"
                                onClick={() => {
                                  item?.record_details?.vote_up
                                    ? (toggleVote(
                                      item?.project_id,
                                      item?.title,
                                      item?.abstract,
                                      item?.link_html,
                                      "multimedia",
                                      item?.org_id,
                                      0
                                    ),
                                      (item.record_details.vote_up = false),
                                      (item.record_details.vote_up_count =
                                        item.record_details.vote_up_count - 1))
                                    : (toggleVote(
                                      item?.project_id,
                                      item?.title,
                                      item?.abstract,
                                      item?.link_html,
                                      "multimedia",
                                      item?.org_id,
                                      1
                                    ),
                                      (item.record_details.vote_up = true),
                                      (item.record_details.vote_up_count =
                                        item.record_details.vote_up_count + 1));
                                }}
                              >
                                <span className="like-count">
                                  {item?.record_details?.vote_up_count}
                                </span>
                                {item.record_details?.vote_up ? (
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="InfoText">
                                        Vote up
                                      </Tooltip>
                                    }
                                  >
                                    <svg
                                      width="22"
                                      height="22"
                                      viewBox="0 0 22 22"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{ fill: "#1A73E8" }}
                                    >
                                      <path d="M18.3279 7.15H14.6623L14.8718 5.335C15.186 2.64 14.6623 0.495 12.9866 0H12.8295C12.6564 0.0319322 12.4937 0.109127 12.3564 0.224469C12.2192 0.339811 12.1117 0.48959 12.0441 0.66C12.0441 0.66 9.05923 7.26 7.33117 8.58V19.8H8.01192C8.1254 19.7899 8.23965 19.8042 8.34769 19.842C8.45574 19.8798 8.5553 19.9404 8.64031 20.02C9.37343 20.57 11.8346 22 13.0914 22H15.7097C18.7992 22 21.4699 19.8 21.7317 15.455L21.9935 11.055C22.0235 10.5387 21.9496 10.0215 21.7767 9.53721C21.6037 9.05287 21.3357 8.6122 20.9899 8.24385C20.6442 7.8755 20.2284 7.58774 19.7698 7.39928C19.3112 7.21082 18.8199 7.12588 18.3279 7.15ZM0 9.9V18.7C0 18.9917 0.110341 19.2715 0.30675 19.4778C0.503159 19.6841 0.769546 19.8 1.04731 19.8H5.23655V8.8H1.04731C0.769546 8.8 0.503159 8.91589 0.30675 9.12218C0.110341 9.32847 0 9.60826 0 9.9Z" />
                                    </svg>
                                  </OverlayTrigger>
                                ) : (
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="InfoText">
                                        Vote up
                                      </Tooltip>
                                    }
                                  >
                                    <svg
                                      width="23"
                                      height="22"
                                      viewBox="0 0 23 22"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M19.1635 7.15H15.3308L15.5498 5.335C15.8783 2.64 15.3308 0.495 13.5787 0H13.4144C13.2334 0.0319322 13.0633 0.109127 12.9197 0.224469C12.7762 0.339811 12.6639 0.48959 12.5931 0.66C12.5931 0.66 8.76044 8.8 7.11786 8.8H1.09505C0.804628 8.8 0.526097 8.91589 0.320734 9.12218C0.115371 9.32847 0 9.60826 0 9.9V18.7C0 18.9917 0.115371 19.2715 0.320734 19.4778C0.526097 19.6841 0.804628 19.8 1.09505 19.8H8.37717C8.49582 19.7899 8.61528 19.8042 8.72825 19.842C8.84121 19.8798 8.94532 19.9404 9.0342 20.02C9.80074 20.57 12.3741 22 13.6882 22H16.4258C19.6562 22 22.4486 19.8 22.7224 15.455L22.9961 11C23.0195 10.4882 22.9364 9.97707 22.752 9.49934C22.5677 9.02161 22.2862 8.58774 21.9255 8.22544C21.5649 7.86314 21.133 7.58038 20.6574 7.39521C20.1818 7.21003 19.673 7.12652 19.1635 7.15ZM2.19011 11H5.47527V17.6H2.19011V11ZM20.5323 15.29C20.3133 18.15 18.7802 19.8 16.4258 19.8H13.6882C13.0311 19.745 11.0601 18.755 10.3483 18.26C9.78349 17.8242 9.08929 17.5918 8.37717 17.6H7.66538V11C8.70568 10.78 10.403 9.79 13.4144 3.96C13.4211 4.34578 13.4028 4.7316 13.3597 5.115L13.1407 6.93L12.8669 9.35H19.1635C19.3805 9.3249 19.6004 9.34844 19.8073 9.41891C20.0141 9.48938 20.2029 9.60503 20.36 9.75756C20.5171 9.91008 20.6385 10.0957 20.7157 10.301C20.7928 10.5063 20.8237 10.7263 20.806 10.945L20.5323 15.29Z"
                                        fill="#373737"
                                      />
                                    </svg>
                                  </OverlayTrigger>
                                )}
                              </button>
                              <button
                                type="button"
                                className="icon-button"
                                onClick={() => {
                                  AccesshandleShow(),
                                    recordUserList(item?.project_id),
                                    setProjId(item?.project_id);
                                  setProjTitle(item?.title);
                                  setProjAbs(item?.abstract);
                                  setProjLink(item?.link_html);
                                  setProOrgId(item?.org_id);
                                  userList();
                                }}
                              >
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="InfoText">
                                      Share
                                    </Tooltip>
                                  }
                                >
                                  <svg
                                    width="22"
                                    height="20"
                                    viewBox="0 0 22 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M14.606 4.55599L19.508 10.0556L14.606 15.4996V11.5554L12.5051 11.6665C9.10373 11.8332 6.50267 12.222 4.45182 12.9998C6.25256 10.8888 9.10373 9.22228 12.8052 8.83343L14.606 8.66677V4.55599ZM13.1554 0.000794509C13.0765 0.00779243 12.9996 0.0320624 12.9291 0.0722063C12.8587 0.11235 12.7961 0.167575 12.7449 0.2347C12.6938 0.301825 12.6551 0.379523 12.6311 0.463321C12.6071 0.547118 12.5983 0.635359 12.6052 0.722959V6.61138C5.00205 7.44465 0.100041 13.222 0 19.0548C0 19.3881 0.100041 19.6103 0.300123 19.6103C0.500205 19.6103 0.650267 19.4437 0.850349 18.9993C2.05084 15.9995 4.75195 14.2774 12.6052 13.8886V19.277C12.5983 19.3646 12.6071 19.4529 12.6311 19.5367C12.6551 19.6205 12.6938 19.6982 12.7449 19.7653C12.7961 19.8324 12.8587 19.8876 12.9291 19.9278C12.9996 19.9679 13.0765 19.9922 13.1554 19.9992C13.2337 20.0041 13.3119 19.9862 13.3821 19.9472C13.4523 19.9082 13.5121 19.8496 13.5556 19.777L21.8089 10.6111C21.932 10.4583 22 10.2605 22 10.0556C22 9.85059 21.932 9.65281 21.8089 9.50004L13.5556 0.222999C13.5121 0.150438 13.4523 0.0917596 13.3821 0.0527823C13.3119 0.013805 13.2337 -0.00411673 13.1554 0.000794509Z"
                                      fill="#373737"
                                    />
                                  </svg>
                                </OverlayTrigger>
                              </button>
                              <button
                                type="button"
                                className="icon-button"
                                onClick={() => {
                                  item?.record_details?.bookmark
                                    ? (toggleBookmark(
                                      item?.project_id,
                                      item?.title,
                                      item?.abstract,
                                      item?.link_html,
                                      "multimedia",
                                      0,
                                      item?.org_id
                                    ),
                                      (item.record_details.bookmark = false))
                                    : (toggleBookmark(
                                      item?.project_id,
                                      item?.title,
                                      item?.abstract,
                                      item?.link_html,
                                      "multimedia",
                                      1,
                                      item?.org_id
                                    ),
                                      (item.record_details.bookmark = true));
                                }}
                              >
                                {item?.record_details?.bookmark ? (
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="InfoText">
                                        Bookmark
                                      </Tooltip>
                                    }
                                  >
                                    <svg
                                      id="Group_2176"
                                      data-name="Group 2176"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="22"
                                      height="22"
                                      viewBox="0 0 22 22"
                                    >
                                      <g
                                        id="icons_Q2"
                                        data-name="icons Q2"
                                        transform="translate(-61 -9)"
                                      >
                                        <path
                                          id="Path_2366"
                                          data-name="Path 2366"
                                          d="M23.895,7.316V23.526l-4.4-3.358-1.389-1.042-1.389,1.042-4.4,3.358V7.316H23.895M11.158,5A1.158,1.158,0,0,0,10,6.158V25.842A1.158,1.158,0,0,0,11.158,27a1.389,1.389,0,0,0,.811-.289l6.137-4.689,6.137,4.689a1.389,1.389,0,0,0,.811.289,1.158,1.158,0,0,0,1.158-1.158V6.158A1.158,1.158,0,0,0,25.053,5Z"
                                          transform="translate(54 4)"
                                          fill="#0D6EFD"
                                        />
                                        <path
                                          id="Path_2367"
                                          data-name="Path 2367"
                                          d="M-126.668-6515.948v18.35l6.819-5.212,6.819,5.212v-18.35Z"
                                          transform="translate(192 6527)"
                                          fill="#0D6EFD"
                                        />
                                      </g>
                                      <rect
                                        id="Rectangle_2567"
                                        data-name="Rectangle 2567"
                                        width="22"
                                        height="22"
                                        fill="none"
                                      />
                                    </svg>
                                  </OverlayTrigger>
                                ) : (
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="InfoText">
                                        Bookmark
                                      </Tooltip>
                                    }
                                  >
                                    <svg
                                      id="Group_2175"
                                      data-name="Group 2175"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="22"
                                      height="22"
                                      viewBox="0 0 22 22"
                                    >
                                      <g
                                        id="icons_Q2"
                                        data-name="icons Q2"
                                        transform="translate(-61 -9)"
                                      >
                                        <path
                                          id="Path_2366"
                                          data-name="Path 2366"
                                          d="M23.895,7.316V23.526l-4.4-3.358-1.389-1.042-1.389,1.042-4.4,3.358V7.316H23.895M11.158,5A1.158,1.158,0,0,0,10,6.158V25.842A1.158,1.158,0,0,0,11.158,27a1.389,1.389,0,0,0,.811-.289l6.137-4.689,6.137,4.689a1.389,1.389,0,0,0,.811.289,1.158,1.158,0,0,0,1.158-1.158V6.158A1.158,1.158,0,0,0,25.053,5Z"
                                          transform="translate(54 4)"
                                        />
                                      </g>
                                      <rect
                                        id="Rectangle_2567"
                                        data-name="Rectangle 2567"
                                        width="22"
                                        height="22"
                                        fill="none"
                                      />
                                    </svg>
                                  </OverlayTrigger>
                                )}
                              </button>
                            </div>
                            <div className="date-text">{moment(item?.date).format("MMM YYYY")}</div>

                          </div>
                        </div>
                      </Col>
                    )) : <p className="no-results-wraper">No results found</p>}
                  </>}
              </Row>
            </div>
            {!loading && req.includes("mul") &&
              result && (<Paginate

                pageCount={(Math.ceil(count / 12))}
                onPageChange={handlePage}
                initialPage={currentPage}

              />)}
</div>
          </Col>
        </Row>
      </Container>
      <Modal show={AccessShow} size="md" className="common-modal side-popup">
        <Modal.Header closeButton onClick={AccesshandleClose}>
          <Modal.Title>Manage access</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="country-pop-wraper">
            <ShareRecordPopup
              projectId={projId}
              title={projTitle}
              abstract={proAbs}
              link={projLink}
              orgId={projOrgId}
              category="multimedia"
            />
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </div>
  );
}

export default MultimediaRecomendation;