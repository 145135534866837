import React, { useState, useEffect, useRef } from "react";
import TextareaAutosize from "react-textarea-autosize";
import SendBtn from "../../../../../../assets/images/icons/send.svg";
import ClipIcon from "../../../../../../assets/images/icons/upload-clip-icon.png";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  addChatResponse,
  clearChat,
  setLoading,
  setPersonality,
  setConversationId,
  setTabId,
  setSearchQuery,
  getChatHistory,
  setNewChat,
  setPaginateCount,
  setPreviousText,
  setGenerateMore,
  setCancelApi,
  setUploadedDocs,
  setDocurl
} from "./../../../../services";
import NewChatIcon from "../../../../../../shared/icons/newchaticon";
import {
  _chatRelatedSearch,
  _chatresults,
  _getHistory,
} from "./../../../../../../shared/httpservice/apis";
import axios from "axios";
import { DateRange } from "react-date-range";
import RefreshIcon from "../../../../../../shared/icons/refreshicon";
import FileUploadModal from "../documentupload";
import {stopWords, personalities, relatedArticlesCategory} from '../../../../chatConfig'
import { toast } from 'react-toastify';

function ChatTextarea() {
  const dispatch = useDispatch();
  const CancelToken = axios.CancelToken;
  const cancelSource = React.useRef(null);
  const personality = useSelector((state) => state?.chat?.personality);
  const previousText = useSelector((state) => state?.chat?.previous_text);
  const paginateCount = useSelector((state) => state?.chat?.paginate_count);
  const isCancelApi = useSelector((state) => state?.chat?.cancel_api);
  const isNewChat = useSelector((state) => state?.chat?.new_chat);
  const isGenerateMore = useSelector((state) => state?.chat?.generate_more);
  const loading = useSelector((state) => state?.chat?.loading);
  const client_id = useSelector((state) => state?.chat?.conversation_id);
  const chat_id = useSelector((state) => state?.chat?.tab_id);
  const historyLoading = useSelector((state) => state?.chat?.history_loading);
  const tokenSso = useSelector(
    (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token,
    shallowEqual
  );
  const token = useSelector(
    (store) => store?.auth?.signin?.response?.data?.data?.token,
    shallowEqual
  );

  const {docUrl, uploadedDocs} = useSelector((store) => store?.chat)


  const [text, setText] = useState("");
  const [limt, setLimit] = useState(4000)
  const textareaRef = useRef(null);

  const btnStyle = {
    backgroundImage: `url(${SendBtn})`, // Use the imported image
  };


  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (isCancelApi) {
      cancelSource?.current?.cancel();
      dispatch(setCancelApi(false))
    }
  }, [isCancelApi])


  // useEffect(() => {
  //   if (personality?.value === "factoids") {
  //     setLimit(300)
  //   } else {
  //     setLimit(4000)
  //   }
  // }, [personality])



  const getTimestamp = () => {
    const currentDate = new Date();
    const dateAsString = currentDate.toISOString();
    return dateAsString;
  };
 
  const onTextareaChange = (e) => {
    const inputText = e.target.value
    if (inputText.length <= limt) {
      setText(inputText);
    }
  };

  const handlePaste = (event) => {
    event.preventDefault(); // Prevent the default paste behavior
    const pastedText = event.clipboardData.getData('text/plain');
    const inputText = text;

    // Get the selected text
    const selectedText = inputText.slice(textareaRef.current.selectionStart, textareaRef.current.selectionEnd);

    // Replace the selected text with the pasted text
    const newText = inputText.slice(0, textareaRef.current.selectionStart) + pastedText + inputText.slice(textareaRef.current.selectionEnd);

    if (newText.length <= limt) {
      setText(newText);
    } else {
      // Trim the text to the character limit
      const trimmedText = newText.slice(0, limt);
      setText(trimmedText);
    }
  };

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
      dispatch(setUploadedDocs([]));
      dispatch(setDocurl(''));
      setShowModal(true);
  };

  const handleCloseModal = () => {
      setShowModal(false);
  };




  const getHistory = async () => {
    try {
      if (token !== undefined) {
        const chatData = await _getHistory(token);
        dispatch(getChatHistory(chatData?.data?.data));
      } else if (tokenSso !== undefined) {
        const chatData = await _getHistory(tokenSso);
        dispatch(getChatHistory(chatData?.data?.data));
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const generateBotResponse = async () => {
    const answer = {};
    dispatch(setLoading(true));
    const data = {};
    data.personality = personality.title
    data.message_category = personality.value
    data.question = text;
    if(docUrl && personality.value === "document") {
      data.user_file = docUrl;
    }
    if (client_id !== null) {
      data.conversation_id = client_id;
      data.chat = chat_id;
      data.skip = 0;
    } else {
      data.conversation_id = null;
      data.skip = 0;
    }
    if(uploadedDocs.length > 0  && personality.value === "document") {
      data.file_names = uploadedDocs.map((item) => item.name);
    }
    if (token !== undefined || tokenSso !== undefined) {
      cancelSource.current = CancelToken.source();
      try {
        const chatData = await _chatresults(data, cancelSource, token||tokenSso).then(
          async (response) => {
            
            answer.message = response?.data?.data?.answer;
            answer.sender = "bot";
            answer.search_query = response?.data?.data?.search_query;
            answer.countries = response?.data?.data?.additional_data?.countries;
            answer.regions = response?.data?.data?.additional_data?.regions;
            answer.org = response?.data?.data?.additional_data?.org_names;
            answer.major_concepts =
              response?.data?.data?.additional_data?.major_concepts;
            answer.references = response?.data?.data?.references;
            answer.id = response?.data?.data?.id;
            answer.chat_response = response?.data?.data?.chat_responses;
            answer.indicators = response?.data?.data?.indicators;
            answer.like = false;
            answer.dislike = false;
            answer.is_answer_links_valid = response?.data?.data?.is_answer_links_valid || false ;
            dispatch(
              setConversationId(
                response?.data?.data?.chat?.client_conversation_id
              )
            );
            dispatch(setTabId(response?.data?.data?.chat?.id));
            dispatch(setSearchQuery(response?.data?.data?.search_query));
            getHistory();
            if(relatedArticlesCategory[response?.data?.data?.chat?.category] && response?.data?.data?.search_query){
            try {
              const form = new FormData();
 
              form.append("category", relatedArticlesCategory[personality.value]);

              form.append("qterm", answer?.search_query);
              if (
                Array.isArray(answer.org) &&
                answer.org?.length > 0
              ) {
                form.append("org_name", answer.org?.join("^"));
              }
              if (
                Array.isArray(answer.countries) &&
                answer.countries?.length > 0
              ) {
                form.append("country", answer.countries?.join("^"));
              }
              if (Array.isArray(answer.regions) && answer.regions?.length > 0) {
                form.append("region", answer.regions?.join("^"));
              }
              if (
                Array.isArray(answer.major_concepts) &&
                answer.major_concepts?.length > 0
              ) {
                form.append("topic", answer.major_concepts?.join("^"));
              }
              form.append("rows", 10);
              form.append("skip", 0);
              const searchData = await _chatRelatedSearch(form, token||tokenSso);
              answer.search_results = searchData?.data?.data?.items;
              answer.facets = searchData?.data?.data?.facets;
              answer.total_results = searchData?.data?.data?.total_results;
            
            } catch (error) {
             console.log(error)
            }
          }
          dispatch(addChatResponse(answer));
          dispatch(setLoading(false));
          }
        );
      } catch (error) {
        console.log(error)
        if (error?.message !== "canceled") {
          answer.message =
            "Server Error: We apologize, but our service is currently experiencing technical difficulties. Please try again later.";
          answer.sender = "bot";
          dispatch(addChatResponse(answer));
        }
        dispatch(setLoading(false));
      }
    }
  };

  const generateMoreResponse = async () => {
    let partNumber;
     if (personality.value === "economy" || personality.value === "sector") {
      partNumber = paginateCount / 15 + 1
    } else {
      partNumber = paginateCount / 10 + 1
    }
    dispatch(
      addChatResponse({
        message: previousText + ` (part ${partNumber})`,
        sender: "user",
        time: getTimestamp(),
      })
    );
    const answer = {};
    dispatch(setLoading(true));
    const data = {};
    data.personality = personality.title
    data.message_category = personality.value
    data.question = previousText + `(part ${partNumber})`;
    data.skip = paginateCount;
    if (client_id !== null) {
      data.conversation_id = client_id;
      data.chat = chat_id;
    } else {
      data.conversation_id = null;
    }
    if (token !== undefined || tokenSso !== undefined) {
      cancelSource.current = CancelToken.source();
      try {
        const chatData = await _chatresults(data, cancelSource, token || tokenSso).then(
          async (response) => {
            answer.message = response?.data?.data?.answer;
            answer.sender = "bot";
            answer.search_query = response?.data?.data?.search_query;
            answer.countries = response?.data?.data?.additional_data?.countries;
            answer.org = response?.data?.data?.additional_data?.org_names;
            answer.regions = response?.data?.data?.additional_data?.regions;
            answer.major_concepts =
              response?.data?.data?.additional_data?.major_concepts;
            answer.references = response?.data?.data?.references;
            answer.id = response?.data?.data?.id;
            answer.chat_response = response?.data?.data?.chat_responses;
            answer.indicators = response?.data?.data?.indicators;
            answer.like = false;
            answer.dislike = false;
            answer.is_answer_links_valid  = response?.data?.data?.is_answer_links_valid  || false ;
            dispatch(
              setConversationId(
                response?.data?.data?.chat?.client_conversation_id
              )
            );
            dispatch(setTabId(response?.data?.data?.chat?.id));
            dispatch(setSearchQuery(response?.data?.data?.search_query));
            getHistory();
            if(relatedArticlesCategory[response?.data?.data?.chat?.category] && response?.data?.data?.search_query){
            try {
              const form = new FormData();

              form.append("category", relatedArticlesCategory[personality.value]);

              form.append("qterm", answer?.search_query);
              if (
                Array.isArray(answer.org) &&
                answer.org?.length > 0
              ) {
                form.append("org_name", answer.org?.join("^"));
              }
              if (
                Array.isArray(answer.countries) &&
                answer.countries?.length > 0
              ) {
                form.append("country", answer.countries?.join("^"));
              }
              if (Array.isArray(answer.regions) && answer.regions?.length > 0) {
                form.append("region", answer.regions?.join("^"));
              }
              if (
                Array.isArray(answer.major_concepts) &&
                answer.major_concepts?.length > 0
              ) {
                form.append("topic", answer.major_concepts?.join("^"));
              }
              form.append("rows", 10);
              form.append("skip", 0);
              const searchData = await _chatRelatedSearch(form, token || tokenSso);
              answer.search_results = searchData?.data?.data?.items;
              answer.facets = searchData?.data?.data?.facets
              answer.total_results = searchData?.data?.data?.total_results;
            
            } catch (error) {
              console.log(error)

            }
          }
          dispatch(addChatResponse(answer));
          dispatch(setLoading(false));
          }
        );
      } catch (error) {
        console.log(error);
        if (error?.message !== "canceled") {
          answer.message =
            "Server Error: We apologize, but our service is currently experiencing technical difficulties. Please try again later.";
          answer.sender = "bot";
          dispatch(addChatResponse(answer));
        }
        dispatch(setLoading(false));
      }
    } 

    if (personality.value === "economy" || personality.value === "sector") {
      dispatch(setPaginateCount(paginateCount + 15))
    } else {
      dispatch(setPaginateCount(paginateCount + 10))
    }
  }

  function isStringOnlySpaces(inputString) {
    return /^\s*$/.test(inputString);
  }

  const onMessageSent = (event) => {
    event.preventDefault();
   // check if document chat and user hasnt selected file
    if(personality.value === "document" && isNewChat){
      toast.error("Please upload document to continue",{
        hideProgressBar: true,
      })
      return true
    }
    dispatch(setPreviousText(text))
    if(!(personality.value === "document" || personality.value === "open")){
      dispatch(setGenerateMore(true))
    }
     if (personality.value === "economy" || personality.value === "sector") {
       dispatch(setPaginateCount(15));
     } else {
       dispatch(setPaginateCount(10)); 
     }
    if (
      !isStringOnlySpaces(text) &&
      !stopWords.includes(text.toLocaleLowerCase())
    ) {
      dispatch(setNewChat(false));
      dispatch(
        addChatResponse({
          message: text,
          sender: "user",
          time: getTimestamp(),
        })
      );
      generateBotResponse();
      setText("");
    } else if (isStringOnlySpaces(text)) {
      dispatch(setNewChat(false));
      dispatch(
        addChatResponse({
          message: text,
          sender: "user",
          time: getTimestamp(),
        })
      );
      dispatch(setLoading(true));
      setTimeout(() => {
        dispatch(
          addChatResponse({
            message:
              "It seems like your message is empty. If you have any questions or need assistance with something, please feel free to ask, and I'll be happy to help!",
            sender: "bot",
            time: getTimestamp(),
          })
        );
        dispatch(setLoading(false));
      }, 1000);
      setText("");
    } else if (stopWords.includes(text.toLocaleLowerCase())) {
      dispatch(setNewChat(false));
      dispatch(
        addChatResponse({
          message: text,
          sender: "user",
          time: getTimestamp(),
        })
      );
      dispatch(setLoading(true));
      setTimeout(() => {
        dispatch(
          addChatResponse({
            message:
              "It seems like your message is incomplete. How can I assist you today? Please provide more details or let me know what you'd like to discuss or ask about, and I'll be happy to help.",
            sender: "bot",
            time: getTimestamp(),
          })
        );
        dispatch(setLoading(false));
      }, 1000);
      setText("");
    }
  };
  const onClearChat = () => {
    cancelSource?.current?.cancel();
    dispatch(setGenerateMore(false));
    dispatch(setNewChat(true));
    dispatch(setLoading(false));
    dispatch(clearChat());
    dispatch(setPersonality(personalities[0]));
    dispatch(setConversationId(null));
    dispatch(setTabId(null));
    dispatch(setPaginateCount(0));
    dispatch(setPreviousText(""));
  };


  const handleEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (text !== "") {
        onMessageSent(e);
      }
    }
  };
  return (
    <div className="chat-textarea-wrap">
      <div className="chat-textarea-form-outer">
        <div className="d-flex w-100%">
          <div className="chat-textarea-new-chat-wrap">
            <button onClick={onClearChat} className="new-chat-btn">
              <span className="new-chat-btn-img">
                <NewChatIcon />
              </span>
              <span className="new-chat-btn-text">New Chat</span>
            </button>
          </div>
          <div className="textarea-form-wrap flex-1 p-relative">
            {loading || historyLoading ? (
              <div className="generate-loading generate-loading-anim generate-more-seperate-loading d-flex align-items-center" style={{left:'auto', right:0}}>
                 <div class="dot-elastic"></div>
                <span>Generating Response</span>
              </div>
            ) : null}
            <form onSubmit={onMessageSent} className="chat-textarea-form d-flex">
              
            {!loading && isGenerateMore === true ? (
              <div onClick={generateMoreResponse} className="generate-loading generate-more-btn generate-more-seperate d-flex align-items-center" style={{left:'auto', right:0}}>
                <RefreshIcon /><span>Generate More</span>
              </div>
            ) : null}
            {personality?.title && (
                <div  className="generate-loading generate-more-btn d-flex align-items-center" style={{ cursor: 'default' }}>
                <span>{personality?.title}</span>
               </div>
            )
            }
            
              {
               isNewChat && (
                  <div className="upload-clip-btn" onClick={handleOpenModal} style={{ cursor: 'pointer' }}>
                  <img src={ClipIcon} alt="upload" />
                </div>
                )
              }
            
              <TextareaAutosize
                className="textarea-item"
                placeholder="Ask Me Anything!"
                value={text}
                ref={textareaRef}
                onChange={onTextareaChange}
                onPaste={handlePaste}
                onKeyDown={handleEnter}
                disabled={loading}
              />
              <button
                disabled={text === "" || loading}
                type="submit"
                className="send-btn"
                style={btnStyle}
              >
                click
              </button>
              <span className="text-length-show">
                {text.length}/{limt}
              </span>
            </form>
          </div>
        </div>
        <p className="info-text-chat-textarea m-0">
        AI can make mistakes. Verify critical information.
        </p>
      </div>
      <FileUploadModal show={showModal} handleClose={handleCloseModal} />
    </div>
  );
}

export default ChatTextarea;
