import React, { useEffect, useState, useRef } from 'react';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import UploadIcons from "../../../../assets/images/icons/uploadicon.png";
import Button from "react-bootstrap/Button";
import { Link } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { userProfileInfo, usersInfo } from './../../../profile/services'
import { Formik, useFormik, ErrorMessage, Field, useField } from "formik";
import { listSector, listCountry, listRegion, listTopic } from '../../../auth/services';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import TickIcon from '../../../../assets/images/icons/tick-icon.png'
import 'react-toastify/dist/ReactToastify.css';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Compressor from 'compressorjs';
import Compress from "react-image-file-resizer";
// import SelectField from "./CustomSelect";
import {
  _ssoLogin,
  _ssoLoginCallback,
  _token,
  _countryList,
  _topicList,
  _regionList,
  _profileUpdate,
} from "~shared/httpservice/apis";

function ProfileDetailsEdit() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const profileImage = useSelector((state) => state?.profile?.userInfo?.response?.data?.data?.profile_pic_path, shallowEqual)
  const token = useSelector((store) => store?.auth?.signin?.response?.data?.data?.token, shallowEqual)
  const newProfileImage = useSelector((store) => store?.results?.profilePic, shallowEqual)
  const state = useSelector((store) => store?.auth?.sectors?.response?.data?.data?.element, shallowEqual)
  const country = useSelector((store) => store?.auth?.country?.response?.data?.data?.element, shallowEqual)
  const region = useSelector((store) => store?.auth?.region?.response?.data?.data?.element, shallowEqual)
  const topic = useSelector((store) => store?.auth?.topic?.response?.data?.data?.element, shallowEqual)
  const user = useSelector((state) => state?.profile?.userInfo?.response?.data?.data, shallowEqual)
  const prefferedTopics = useSelector((state) => state?.profile?.userInfo?.response?.data?.data?.pref_topic, shallowEqual)
  const prefferedCountries = useSelector((state) => state?.profile?.userInfo?.response?.data?.data?.pref_country, shallowEqual)
  const prefferedRegions = useSelector((state) => state?.profile?.userInfo?.response?.data?.data?.pref_region, shallowEqual)
  const [upload, setUpload] = useState('Upload Files')
  const [fileName, setFileName] = useState()
  const [fileSize,setFileSize] = useState()
  const [resumeFile,setResumeFiles] = useState()
  const tokenSso = useSelector(
    (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token, shallowEqual
  );
  const userId = useSelector((state) => state?.profile?.userProfile?.response?.data?.data?.results[0]?.user_id)
  const userDetails = () => {
    if (token !== undefined) {
      dispatch(usersInfo(token)).then(() => {
        dispatch(listSector(token))
        dispatch(listCountry(token))
        dispatch(listRegion(token))
        dispatch(listTopic(token))
      })
    } else if (tokenSso !== undefined) {
      dispatch(usersInfo(tokenSso)).then(() => {
        dispatch(listSector(tokenSso))
        dispatch(listCountry(tokenSso))
        dispatch(listRegion(tokenSso))
        dispatch(listTopic(tokenSso))
      })
    }


  }

  useEffect(() => {
    userDetails()
  }, [])
  const prefferedSector = useSelector((state) => state?.profile?.userInfo?.response?.data?.data?.pref_sector)
  const [pref, setPref] = useState([])
  useEffect(() => {
    if (prefferedSector) {
      const prefs = prefferedSector.map((item) => ({
        value: item,
        label: item,
      }));
      setPref(prefs)
    }
  }, [prefferedSector])
  const [prefTopic, setPrefTopic] = useState([])
  useEffect(() => {
    if (prefferedTopics) {
      const prefTopics = prefferedTopics.map((item) => ({
        value: item,
        label: item,
      }));
      setPrefTopic(prefTopics)
    }

  }, [prefferedTopics])
  const [prefCountries, setPrefCountries] = useState([])
  useEffect(() => {
    if (prefferedCountries) {
      const prefCountries = prefferedCountries.map((item) => ({
        value: item,
        label: item,
      }))
      setPrefCountries(prefCountries)
    }
  }, [prefferedCountries])

  const [prefRegion, setPrefRegion] = useState([])
  useEffect(() => {
    if (prefferedRegions) {
      const prefRegion = prefferedRegions.map((item) => ({
        value: item,
        label: item,
      }))
      setPrefRegion(prefRegion)
    }
  }, [prefferedRegions])


  const listSec = () => {
    if (token !== undefined) {
      dispatch(listSector(token))
    } else if (tokenSso !== undefined) {
      dispatch(listSector(tokenSso))
    }

  }
  const listCountries = () => {
    if (token !== undefined) {
      dispatch(listCountry(token))
    } else if (tokenSso !== undefined) {
      dispatch(listCountry(tokenSso))
    }
  }
  const listTopics = () => {
    if (token !== undefined) {
      dispatch(listTopic(token))
    } else if (tokenSso !== undefined) {
      dispatch(listTopic(tokenSso))
    }
  }
  const listRegions = () => {
    if (token !== undefined) {
      dispatch(listRegion(token))
    } else if (tokenSso !== undefined) {
      dispatch(listRegion(tokenSso))
    }
  }
  useEffect(() => {
    if (token) { listSec(), listCountries(), listTopics(), listRegions() }
  }, [token]);
  useEffect(() => {
    if (tokenSso) { listSec(), listCountries(), listTopics(), listRegions() }
  }, [tokenSso]);
  const [sectorOptions, setSectorOptions] = useState([])
  useEffect(() => {
    if (state) {
      const sectorOptions = state.map((item) => ({
        label: item.name,
        value: item.id

      }));
      setSectorOptions(sectorOptions)
    }
    const initialSector = sectorOptions.filter((el1) => pref.find((el2) => el1.value === el2.value))
    setSelect(initialSector)
  }, [state])

  const [topicOptions, setTopicOptions] = useState([])
  useEffect(() => {
    if (topic) {
      const topicOptions = topic.map((item) => ({
        label: item.name,
        value: item.id
      }));
      setTopicOptions(topicOptions)
      const initialTopics = topicOptions.filter((el1) => prefTopic.find((el2) => el1.value === el2.value))
      setTopicSelect(initialTopics)
    }
  }, [topic])
  const [countryOptions, setCountryOptions] = useState([])
  useEffect(() => {
    if (country) {
      const countryOptions = country.map((item) => ({
        label: item.name,
        value: item.code
      }));
      setCountryOptions(countryOptions)
      const initialCountries = countryOptions.filter((el1) => prefCountries.find((el2) => el1.value === el2.value))
      setCountrySelect(initialCountries)
    }
  }, [country])
  const [regionOptions, setRegionOptions] = useState([])
  useEffect(() => {
    if (region) {
      const regionOptions = region.map((item) => ({
        label: item.name,
        value: item.id
      }))
      setRegionOptions(regionOptions)
      const initialRegions = regionOptions.filter((el1) => prefRegion.find((el2) => el1.value === el2.value))
      setRegionSelect(initialRegions)
    }
  }, [region])



  //   const initialSectors = sectorOptions.filter((el1)=>pref.find((el2)=>el1.value===el2.value))


  //   const array1 = [
  //     { name: "Sector1", id: 1 },
  //     { name: "Sector2", id: 2 },
  // ];

  const [select, setSelect] = useState([]);
  const newSector = select.map((item) => ({ value: item.value }));
  var sectorValues = newSector.map((i) => {
    return i.value;
  });
  const [topicSelect, setTopicSelect] = useState([])
  const newFocus = topicSelect.map((item) => ({ value: item.value }));
  var focusValues = newFocus.map((i) => {
    return i.value;
  });
  const [countrySelect, setCountrySelect] = useState([])
  const newCountry = countrySelect.map((item) => ({ value: item.value }));
  var countryValues = newCountry.map((i) => {
    return i.value;
  });
  const [regionSelect, setRegionSelect] = useState([])
  const newRegion = regionSelect.map((item) => ({ value: item.value }));
  var regionValues = newRegion.map((i) => {
    return i.value;
  });
  const [files, setFiles] = useState()
  function b64toBlob(dataURI) {
    var byteString = atob(dataURI.split(",")[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: "image/jpeg" });
  }
  useEffect(() => {
    if (newProfileImage !== null) {
      const file = new File([newProfileImage], "filename.png", {
        type: "image/jpeg",
        lastModified: new Date().getTime(),
      });
      Compress.imageFileResizer(
        file, // the file from input
        480, // width
        480, // height
        "JPEG", // compress format WEBP, JPEG, PNG
        70, // quality
        0, // rotation
        (uri) => {
          // setFiles(uri)
          // You upload logic goes here
          var blob = b64toBlob(uri)
          const files = new File([blob], "filename.png", {
            type: "image/jpeg",
            lastModified: new Date().getTime(),
          });
          setFiles(files)
        },
        "bolb" // blob or base64 default base64
      );

      // setFiles(file);

    }
  }, [newProfileImage]);
  useEffect(() => {
    if (user?.resume_path) {
      setUpload("Resume Uploaded")
      setFileName(user?.resume_path?.slice(53, user?.resume_path?.length))
    }
  }, [])
  const [file, setFile] = useState()
  //   useEffect(() => {
  //     if (profileImage !== undefined) {
  //       const file = new File([profileImage], "filename.png", {
  //         type: profileImage.type,
  //         lastModified: new Date().getTime(),
  //       });
  //       setFile(file);
  //     }
  //   }, [profileImage]);

  const [sub, setSub] = useState(false);
  const [sectorError, setSectorError] = useState("");
  const [focusError, setFocusError] = useState("");
  const [regionError, setRegionError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [resumeError,setResumeError] = useState("")

  const onSignUp = () => {
    if(fileSize!==undefined&&fileSize>5000000){
      setResumeError("File size greater than 5 mb")

    }

    if (select.length === 0) {
      setSectorError("*required");
    }

    if (topicSelect.length === 0) {
      setFocusError("*required");
    }
    if (regionSelect.length === 0) {
      setRegionError("*required");
    }
    if (countrySelect.length === 0) {
      setCountryError("*required");
    }
  };

  useEffect(()=>{
    setResumeError("")
    if(fileSize!==undefined&&fileSize>4000000){
      setResumeError("File size greater than 5 mb")
    }
  },[fileSize])

  useEffect(() => {
    setSectorError("");
    if (select.length === 0 && sub) {
      setSectorError("*required");
    }
  }, [select]);

  useEffect(() => {
    setFocusError("");
    if (topicSelect.length === 0 && sub) {
      setFocusError("*required");
    }
  }, [topicSelect]);
  useEffect(() => {
    setRegionError("");
    if (regionSelect.length === 0 && sub) {
      setRegionError("*required");
    }
  }, [regionSelect]);

  useEffect(() => {
    setCountryError("");
    if (countrySelect.length === 0 && sub) {
      setCountryError("*required");
    }
  }, [countrySelect]);

  const validate = (values) => {
    const errors = {};
    const regex = /(ftp|http|https):\/\/?(?:www\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    const mediumRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[+!@#\$%\^&\*])(?=.{8,})")
    // const nameRegex = new RegExp("^[a-zA-Z ]*$");
    const nameRegex=new RegExp("^[a-zA-Z 0-9]*$");
    if (!values.firstName) {
      errors.firstName = "*required";
    } else if (!nameRegex.test(values.firstName)) {
      errors.firstName = "*Name must contain only alphabets and no special characters"
    }
    if (!values.lastName) {
      errors.lastName = "*required";
    } else if (!nameRegex.test(values.lastName)) {
      errors.lastName = "*Name must contain only alphabets and no special characters"
    }
    if (!values.country) {
      errors.country = "*required";
    }
    if (!values.state) {
      errors.state = "*required";
    }
    if (!values.city) {
      errors.city = "*required";
    }
    if (values.linkedin) {
      if (!regex.test(values.linkedin)) {
        errors.linkedin = "*enter a valid link"
      }
    }
    if(!values?.jobTitle){
      errors.jobTitle = "*required"
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      ...user,
      firstName: user?.first_name,
      lastName: user?.last_name,
      country: user?.country,
      state: user?.state,
      city: user?.state,
      orgName: user?.organisation_name,
      jobTitle: user?.designation,
      resume: user?.resume,
      linkedin: user?.linkedin_resume,
      sector: "",
      email: user?.email,
    },
    onSubmit: register,
    enableReinitialize: true,

  });
  const register = async (values) => {
    const form = new FormData();
    form.append("username", values?.email);
    form.append("first_name", values?.firstName);
    form.append("last_name", values?.lastName);
    form.append("email", values?.email);
    form.append("country", values?.country);
    form.append("state", values?.state);
    form.append("organisation", user?.organisation);
    form.append("designation", values?.jobTitle);
    form.append("linkedin_resume",values?.linkedin)
    if (upload!=="Resume Uploaded") {
      form.append("resume_path", resumeFile || "");
    }

    if (upload === "Upload Files") {
      form.append("resume_path", "");
    }

    form.append("city", values?.city)

    sectorValues.forEach((sectorValue) =>
      form.append("pref_sector", sectorValue)
    );
    countryValues.forEach((countryValue) =>
      form.append("pref_country", countryValue)
    );
    focusValues.forEach((focusValue) => form.append("pref_topic", focusValue));
    regionValues.forEach((regionValue) =>
      form.append("pref_region", regionValue)
    );
    form.append("is_profile_set", true);
    if (newProfileImage !== null&&newProfileImage!=="delete") {
      form.append("profile_pic_path", files);
    }
    if(newProfileImage==="delete"){
      form.append("profile_pic_path","")
    }

    form.append("city", values?.city);


    if (token !== undefined) {
      if (!sectorError && !focusError && !regionError && !countryError &&!resumeError) {
        return _profileUpdate(token, form).then(() => {
          dispatch(userProfileInfo(token))
          toast.success("Profile saved")
          setTimeout(() => {
            navigate('/profile')
          }, 2000)


        })
      }
    } else if (tokenSso !== undefined) {
      if (!sectorError && !focusError && !regionError && !countryError) {
        return _profileUpdate(tokenSso, form).then(() => {
          dispatch(userProfileInfo(tokenSso))
          toast.success("Profile saved")
          setTimeout(() => {
            navigate('/profile')
          }, 2000)
        })
      }
    }
  }

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            className="react-multi-select-item"
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  return (
    <div className="my-profile-wrap whitebox p-30">
      <div className="my-profile-header">
        <h3 className="inner-title">My Profile</h3>
        <div className="d-flex justify-content-between align-items-center m-b-20 subheader-min-height">
          <h4 className="prof-sub-header m-b-10">User Information</h4>
            </div>
      </div>
      <div className="signup-content-profile p-30">
        <Formik
          initialValues={{
            ...user,
            firstName: user?.first_name,
            lastName: user?.last_name,
            country: user?.country,
            state: user?.state,
            city: user?.city,
            orgName: user?.organisation_name,
            jobTitle: user?.designation,
            // resume: user?.resume,
            linkedin: user?.linkedin_resume,
            sector: "",
            email: user?.email,
          }}
          onSubmit={register}
          validate={validate}
        >

          {({ handleSubmit, handleChange, values, errors, touched, setFieldValue }) => (
            <Form className="w-100" onSubmit={handleSubmit}>

              <Row>
                <Col md={6}>
                  <div className="form-input-wrap">
                    <Form.Label htmlFor="name">First name<span style={{color:"red"}}>*</span></Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Allie"
                      className="input-item"
                      name="firstName"
                      value={values?.firstName}
                      onChange={handleChange}
                    // isInvalid={
                    // formik?.errors.firstName && formik?.touched.firstName
                    // }
                    />
                    {errors.firstName && touched.firstName ? (
                      <span style={{ color: "red" }}>
                        {errors.firstName}
                      </span>
                    ) : null}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-input-wrap">
                    <Form.Label>Last name<span style={{color:"red"}}>*</span></Form.Label>
                    <Form.Control
                      name="lastName"
                      type="text"
                      placeholder="Grater"
                      className="input-item"
                      value={values.lastName}
                      onChange={handleChange}
                    />
                    {errors.lastName && touched.lastName ? (
                      <span style={{ color: "red" }}>
                        {errors.lastName}
                      </span>
                    ) : null}
                  </div>
                </Col>
                <Col md={4}>
                  <div className="form-input-wrap">
                    <Form.Label>Country<span style={{color:"red"}}>*</span></Form.Label>
                    <Form.Select
                      name="country"
                      aria-label="Default select example"
                      className="select-item"
                      onChange={handleChange}
                      value={values.country}
                    // isInvalid={
                    //   formik?.errors.country && formik?.touched.country
                    // }
                    >
                      {country && country.map((item, i) => {
                        return (
                          <option key={i} value={item.code}>
                            {item.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                    {errors.country && touched.country ? (
                      <span style={{ color: "red" }}>
                        {errors.country}
                      </span>
                    ) : null}

                  </div>
                </Col>
                <Col md={4}>
                  <div className="form-input-wrap">
                    <Form.Label>State<span style={{color:"red"}}>*</span></Form.Label>
                    <Form.Control
                      name="state"
                      type="text"
                      className="input-item"
                      value={values.state}
                      onChange={handleChange}
                    // isInvalid={
                    //     formik?.errors.state && formik?.touched.state
                    //   }
                    />
                    {errors.state && touched.state ? (
                      <span style={{ color: "red" }}>
                        {errors.state}
                      </span>
                    ) : null}
                  </div>
                </Col>
                <Col md={4}>
                  <div className="form-input-wrap">
                    <Form.Label>City<span style={{color:"red"}}>*</span></Form.Label>
                    <Form.Control
                      name="city"
                      type="text"
                      className="input-item"
                      value={values.city}
                      onChange={handleChange}
                    // isInvalid={formik?.errors.city && formik?.touched.city}
                    />
                    {errors.city && touched.city ? (
                      <span style={{ color: "red" }}>
                        {errors.city}
                      </span>
                    ) : null}
                  </div>
                </Col>
               
                <Col md={12}>
                  <div className="form-input-wrap">
                    <Form.Label>Organisation Name<span style={{color:"red"}}>*</span></Form.Label>
                    <Form.Control
                      type="text"
                      name="orgName"
                      className="input-item"
                      value={values.orgName}
                      onChange={handleChange}
                      disabled
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="form-input-wrap">
                    <Form.Label>Job Title<span style={{color:"red"}}>*</span></Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Job Title"
                      className="input-item"
                      name="jobTitle"
                      value={values.jobTitle}
                      onChange={handleChange}
                    />
                     {errors.jobTitle && touched.jobTitle ? (
                      <span style={{ color: "red" }}>
                        {errors.jobTitle}
                      </span>
                    ) : null}
                  </div>
                </Col>
                <Col md={12}>
                  <div className="box-bg">
                <Row>
                  <Col md={12}>
                    <p className='box-bg-infotext'>[ Your interest inputs help the system make better recommendations! ]</p>
                  </Col>
                <Col md={12}>
                  <div className="form-input-wrap">
                    <Form.Label>Sectors of interest<span style={{color:"red"}}>*</span></Form.Label>
                    <ReactSelect
                      options={sectorOptions}
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      components={{
                        Option,
                      }}
                      allowSelectAll={true}
                      classNamePrefix="react-select-2-option"
                      className="react-multi-select"
                      name="sector"
                      required
                      // value={formik?.values?.sector}
                      selected={select}
                      onChange={setSelect}
                      value={select}
                      blurInputOnSelect={false}
                    />
                    <span style={{ color: "red" }}>{sectorError}</span>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="form-input-wrap">
                    <Form.Label>Topics of interest<span style={{color:"red"}}>*</span></Form.Label>
                    <ReactSelect
                      options={topicOptions}
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      components={{
                        Option,
                      }}
                      allowSelectAll={true}
                      classNamePrefix="react-select-2-option"
                      className="react-multi-select"
                      selected={topicSelect}
                      onChange={setTopicSelect}
                      value={topicSelect}
                      name="focusAreas"
                      blurInputOnSelect={false}
                    />
                    <span style={{ color: "red" }}>{focusError}</span>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="form-input-wrap">
                    <Form.Label>Countries of interest<span style={{color:"red"}}>*</span></Form.Label>
                    <ReactSelect
                      options={countryOptions}
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      components={{
                        Option,
                      }}
                      allowSelectAll={true}
                      classNamePrefix="react-select-2-option"
                      className="react-multi-select"
                      name="countries"
                      selected={countrySelect}
                      onChange={setCountrySelect}
                      value={countrySelect}
                      blurInputOnSelect={false}
                    />
                    <span style={{ color: "red" }}>{countryError}</span>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="form-input-wrap">
                    <Form.Label>Regions of interest<span style={{color:"red"}}>*</span></Form.Label>
                    <ReactSelect
                      options={regionOptions}
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      components={{
                        Option,
                      }}
                      allowSelectAll={true}
                      classNamePrefix="react-select-2-option"
                      className="react-multi-select"
                      name="region"
                      selected={regionSelect}
                      onChange={setRegionSelect}
                      value={regionSelect}
                      blurInputOnSelect={false}
                    />
                    <span style={{ color: "red" }}>{regionError}</span>
                  </div>
                </Col>
               </Row>
               </div>
                </Col>
                <Col md={12} className="p-relative file-upload-container">
                  <div className="d-flex form-input-wrap ">
                    <div className="upload-btn-wrap">
                      <Form.Label>Resume/CV
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="InfoText">
                              Supported files are <br />Pdf, Doc, Docx
                            </Tooltip>
                          }
                        >
                          <span className="infoicon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                              <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z" />
                            </svg>
                          </span>
                        </OverlayTrigger>
                      </Form.Label>
                      <div>
                        <input
                          className="d-none"
                          type="file"
                          id="fileUpload"
                          name="resume"
                          accept="application/pdf,application/msword,"
                          // onChange={(event) => {
                          //   setFieldValue(
                          //     "resume",
                          //     event.currentTarget.files[0],
                          //     setFileName(event.currentTarget.files[0]?.name),
                          //     setFileSize(event?.currentTarget?.files[0]?.size)
                        
                          //   );
                          //   setUpload('File Uploaded')
                          // }}
                          onChange={(e)=>{
                            setResumeFiles(e.target.files[0]),
                            setFileName(e.target.files[0]?.name),
                            setFileSize(e.target.files[0]?.size),
                            setUpload('File Uploaded')
                          }}
                          onClick={(event) => {
                            event.target.value = null;
                          }}
                        />
                        <label
                          htmlFor="fileUpload"
                          className="btn btn-outline-primary upload-btn p-relative"
                        >
                          <img src={upload === 'Upload Files' ? UploadIcons : TickIcon} alt="" style={{ width: "14px" }} />
                          {upload}
      
                          
                        </label>
                      </div>
               
                    </div>
                    <div className="or-text"></div>
                    <div className="Linkedinurl flex-grow-1">
                      <Form.Label>Linkedin url</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Linkedin url"
                        className="input-item"
                        name="linkedin"
                        value={values.linkedin}
                        onChange={handleChange}
                      />
                      {errors.linkedin && touched.linkedin ? (
                        <span style={{ color: "red" }}>
                          {errors.linkedin}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <p className="file-uploaded-text">{fileName ? fileName : null}
                      {upload!=="Upload Files"&&
                          <span className="resume-remove-icon" onClick={() => {
                            // formik.setFieldValue("resume", "")
                            setResumeFiles("")
                            setFileName()
                            setFileSize()
                            setUpload("Upload Files")
                          }} >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                              <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/>
                            </svg>
                          </span>
                          }
                      </p>
                      <span style={{ color: "red" }}>{resumeError}</span>
                </Col>
                <Col md={12}>
                  <div className="form-input-wrap">
                    <Form.Label>Email Address<span style={{color:"red"}}>*</span></Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Email Address"
                      className="input-item"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      disabled
                    />
                  </div>
                </Col>
              </Row>
              <div className="button-wrap double-btn">
                <Link to="/profile" className="btn grey-border-btn link-btn">Cancel</Link>
                <Button type='submit'
                  onClick={() => {
                    onSignUp(), setSub(true);
                  }}
                >Save</Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ProfileDetailsEdit;
