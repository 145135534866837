import React, { useEffect, useRef } from "react";
import { store } from './../../redux/store'
import { useSelector,shallowEqual } from 'react-redux'

export function isAuthenticated() {

    const token = useSelector((store)=>store?.auth?.signin?.response?.data?.data?.token,shallowEqual)
    const tokenSso = useSelector(
        (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token,shallowEqual
      );

      return token!== undefined || tokenSso !== undefined
  
}