import React, { useLayoutEffect, useRef, useState } from "react";
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';


function ResultFilter() {
    const Fixeddiv = useRef();
    useLayoutEffect(() => {
        const divAnimate = Fixeddiv.current.getBoundingClientRect().top;
        const onScroll = () => {
            if (divAnimate < window.scrollY) {
                Fixeddiv.current.style.position = "sticky";
                Fixeddiv.current.style.top = "80px";
                Fixeddiv.current.style.left = 0;
            } else {
                Fixeddiv.current.style.position = "inherit";
            }
        };
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    return (
        <div className="main-container" ref={Fixeddiv}>
            <div className="result-filter-box">
                <h4>Filters</h4>
                <div className="filter-accordian-wrap">
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>organisation</Accordion.Header>
                            <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                aliquip ex ea commodo consequat. Duis aute irure dolor in
                                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                culpa qui officia deserunt mollit anim id est laborum.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Region</Accordion.Header>
                            <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                aliquip ex ea commodo consequat. Duis aute irure dolor in
                                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                culpa qui officia deserunt mollit anim id est laborum.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Country</Accordion.Header>
                            <Accordion.Body>
                                <div className="country-filter">
                                    <div className="country-header-search">
                                        <Form className="w-100">
                                            <Form.Control type="text" placeholder="Search Country" className="input-item country-search" />
                                            <span className="search-icon">
                                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.9355 14L9.99193 10.6633C9.50806 11.0678 8.94355 11.3828 8.29839 11.6083C7.65323 11.8339 6.96774 11.9467 6.24194 11.9467C4.5 11.9467 3.02419 11.3633 1.81452 10.1967C0.604839 9.03 0 7.62222 0 5.97333C0 4.32444 0.604839 2.91667 1.81452 1.75C3.02419 0.583333 4.49194 0 6.21774 0C7.92742 0 9.38306 0.583333 10.5847 1.75C11.7863 2.91667 12.3871 4.32444 12.3871 5.97333C12.3871 6.64222 12.2742 7.28778 12.0484 7.91C11.8226 8.53222 11.4839 9.11556 11.0323 9.66L15 12.9733L13.9355 14ZM6.21774 10.5467C7.52419 10.5467 8.6371 10.0994 9.55645 9.205C10.4758 8.31055 10.9355 7.23333 10.9355 5.97333C10.9355 4.71333 10.4758 3.63611 9.55645 2.74167C8.6371 1.84722 7.52419 1.4 6.21774 1.4C4.89516 1.4 3.77016 1.84722 2.84274 2.74167C1.91532 3.63611 1.45161 4.71333 1.45161 5.97333C1.45161 7.23333 1.91532 8.31055 2.84274 9.205C3.77016 10.0994 4.89516 10.5467 6.21774 10.5467Z" fill="#A5A5A5" />
                                                </svg>
                                            </span>
                                        </Form>
                                    </div>
                                    <div className="checkbox-box">
                                        <div className="checkbox-wrapper">
                                            <input id="Check1" type="checkbox" />
                                            <label htmlFor="Check1">
                                                <span>Afghanistan</span>
                                            </label>
                                        </div>
                                        <div className="checkbox-wrapper">
                                            <input id="Check2" type="checkbox" />
                                            <label htmlFor="Check2">
                                                <span>Afghanistan</span>
                                            </label>
                                        </div>
                                        <div className="checkbox-wrapper">
                                            <input id="Check3" type="checkbox" />
                                            <label htmlFor="Check3">
                                                <span>Afghanistan</span>
                                            </label>
                                        </div>
                                        <div className="checkbox-wrapper">
                                            <input id="Check4" type="checkbox" />
                                            <label htmlFor="Check4">
                                                <span>Afghanistan</span>
                                            </label>
                                        </div>
                                        <div className="checkbox-wrapper">
                                            <input id="Check5" type="checkbox" />
                                            <label htmlFor="Check5">
                                                <span>Afghanistan</span>
                                            </label>
                                        </div>
                                        <div className="view-more-check">102 MORE</div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Sector</Accordion.Header>
                            <Accordion.Body>
                                dd
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Topic</Accordion.Header>
                            <Accordion.Body>
                                dd
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>
        </div>
    )
}

export default ResultFilter;