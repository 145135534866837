import React, { useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SlickNext from '../../../../../../../../../assets/images/icons/arrow-right-white.png';
import SlickPrev from '../../../../../../../../../assets/images/icons/arrow-left-white.png';

import PersIcon1 from '../../../../../../../../../assets/images/icons/PersIcon1.svg';
import PersIcon2 from '../../../../../../../../../assets/images/icons/PersIcon2.svg';
import PersIcon3 from '../../../../../../../../../assets/images/icons/PersIcon3.svg';
import PersIcon4 from '../../../../../../../../../assets/images/icons/PersIcon4.svg';
import PersIcon5 from '../../../../../../../../../assets/images/icons/PersIcon5.svg';
import PersIcon6 from '../../../../../../../../../assets/images/icons/PersIcon6.svg';
import PersIcon7 from '../../../../../../../../../assets/images/icons/PersIcon7.svg';
import PersIcon8 from '../../../../../../../../../assets/images/icons/PersIcon8.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {setPersonality} from '../../../../../../../services/index'
import { personalities, stopWordss } from '../../../../../../../chatConfig';

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`pers-slick-next-wrap ${className}`}
      style={{ ...style }}
      onClick={onClick}
    >
      <img src={SlickNext} alt="Next" className="pers-slick-next" />
    </div>
  );
};

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`pers-slick-prev-wrap ${className}`}
      style={{ ...style }}
      onClick={onClick}
    >
      <img src={SlickPrev} alt="Prev" className="pers-slick-prev" />
    </div>
  );
};

function FeedTiles() {

  const images = {
    web : <img src={PersIcon1} alt="Icon 1" />,
    financing: <img src={PersIcon2} alt="Icon 1" />,
    devpubs: <img src={PersIcon3} alt="Icon 1" />,
    open: <img src={PersIcon4} alt="Icon 1" />,
    economy: <img src={PersIcon5} alt="Icon 1" />,
    evaluations: <img src={PersIcon6} alt="Icon 1" />,
    sector: <img src={PersIcon7} alt="Icon 1" />,
    document: <img src={PersIcon8} alt="Icon 1" />,
  }


 
  // State to keep track of the active item


  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const dispatch = useDispatch();
  const personality = useSelector((state) => state?.chat?.personality);

  const setActivePersonality = (item) => {
    dispatch(setPersonality(item));
  }

  useEffect(() => {
    setActivePersonality(personalities[0]);
  }, []);

  return (
    <div className="personality-slider-wrap">
      <div className="personality-slider-container">
        <h3 className='pers-slider-title'>Personality</h3>
        <Slider {...settings} className="personality-slider">
          {personalities?.map((item, index) => (
            <div
              key={index}
              className={`slider-item ${item?.value === personality?.value ? 'active' : ''}`}
              onClick={() => setActivePersonality(item)}
            >
              <div className="personality-item-box">
                <div className="pers-slider-icon">{images[item?.value]}</div>
                <h3>{item?.title}</h3>
                <p>{item?.description}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default FeedTiles;
