import React, { useState } from "react";
import Tippy from "@tippy.js/react";
import "tippy.js/dist/tippy.css";
function LearnMore(props) {
  const initialItemsToShow = 3; // Change this number to control how many items to show initially
  const [showItems, setShowItems] = useState(initialItemsToShow);
  const items = props?.references
  const handleViewMore = () => {
    setShowItems(items?.length); // Show all items
  };

  const handleViewLess = () => {
    setShowItems(initialItemsToShow); // Show initial number of items
  };
  return (
    <>
    {items&&items?.length>0? <div className="output-learnmore-wrap p-relative">
        <div className="learnmore-child bold-text">References:</div>
        <div className="d-flex output-learnmore-content flex-wrap">
          <ul className="output-learnmore-content-list">
            {items?.slice(0, showItems)?.map((item, index) => (
               <Tippy content={item?.title}>
              <li key={index}><a target="_blank" href={item?.link_html}>{index+1}. {item?.title?.length>20?item?.title?.slice(0,20)+"...":item?.title}</a></li>
              </Tippy>
            ))}
            {items?.length>3?<>
              {showItems < items?.length ? (
            <li className="no-bg"><button onClick={handleViewMore} className="view-more-item">+{items?.length-3}</button></li>
          ) : (
            <li className="no-bg"><button onClick={handleViewLess} className="view-more-item">— See less</button></li>
          )}
            </>:null}
            
          </ul>
         
        </div>
        <span className="items-showing-count">{items?.length>showItems?showItems:items?.length} of {items?.length}</span>
      </div>:null}
     
    </>
  );
}

export default LearnMore;