import { createElement } from 'react';
import {createAction,handleActions} from 'redux-actions';
import ActionTypes from '~shared/constants/actionTypes';

// import {store} from  '../../../redux/store';

const initialState = {
    experts_org_filter : [],
    experts_org_data : [],
    experts_org_handler : [],
    experts_country_filter : [],
    experts_country_data : [],
    experts_country_handler : [],
    experts_region_filter : [],
    experts_region_data : [],
    experts_region_handler : [],
    experts_sector_filter : [],
    experts_sector_data : [],
    experts_sector_handler : [],
    experts_topic_filter : [],
    experts_topic_data : [],
    experts_topic_handler : [],
    experts_resource_filter : [],
    experts_resource_data : [],
    experts_resource_handler : [],
    experts_home_flag : true,
    experts_search_country : [],
    experts_ignore_query : false
    
    
  }

//Econ 
const setExpertsOrgArray = createAction(ActionTypes.SET_EXPERTS_ORG_FILTER, (data) => data);

const setExpertsCountryArray = createAction(ActionTypes.SET_EXPERTS_COUNTRY_FILTER)

export const setExpertsCountryFilter = (data) => (dispatch) => {
  dispatch(setExpertsCountryArray(data))
}

export const setExpertsOrgFilter = (data) => (dispatch) => {
  dispatch(setExpertsOrgArray(data));
};

const setExpertsCountry = createAction(ActionTypes.SET_EXPERTS_COUNTRY_DATA)

export const setExpertsCountryData = (data) => (dispatch) => {
  dispatch(setExpertsCountry(data))
}

const setExpertsCountryHandlerArray = createAction(ActionTypes.SET_EXPERTS_COUNTRY_HANDLER)

export const setExpertsCountryHandler = (data) => (dispatch) =>{
  dispatch(setExpertsCountryHandlerArray(data))
}

const setExpertsOrg = createAction(ActionTypes.SET_EXPERTS_ORG_DATA)

export const setExpertsOrgData = (data) => (dispatch) => {
  dispatch(setExpertsOrg(data))
}

const setExpertsOrgHandlerArray = createAction(ActionTypes.SET_EXPERTS_ORG_HANDLER)

export const setExpertsOrgHandler = (data) => (dispatch) => {
  dispatch(setExpertsOrgHandlerArray(data))
}

const setExpertsRegionArray =  createAction(ActionTypes.SET_EXPERTS_REGION_FILTER)

export const setExpertsRegionFilter = (data) =>(dispatch) =>{
  dispatch(setExpertsRegionArray(data))
}

const setExpertsRegionAction = createAction(ActionTypes.SET_EXPERTS_REGION_DATA)

export const setExpertsRegionData = (data) => (dispatch) =>{
  dispatch(setExpertsRegionAction(data))
}

const setExpertsRegionHandlerAction = createAction(ActionTypes.SET_EXPERTS_REGION_HANDLER)

export const setExpertsRegionHandler = (data) =>(dispatch)=>{
  dispatch(setExpertsRegionHandlerAction(data))
}

const setExpertsSectorArray =  createAction(ActionTypes.SET_EXPERTS_SECTOR_FILTER)

export const setExpertsSectorFilter = (data) =>(dispatch) =>{
  dispatch(setExpertsSectorArray(data))
}

const setExpertsSectorAction = createAction(ActionTypes.SET_EXPERTS_SECTOR_DATA)

export const setExpertsSectorData = (data) => (dispatch) =>{
  dispatch(setExpertsSectorAction(data))
}

const setExpertsSectorHandlerAction = createAction(ActionTypes.SET_EXPERTS_SECTOR_HANDLER)

export const setExpertsSectorHandler = (data) =>(dispatch)=>{
  dispatch(setExpertsSectorHandlerAction(data))
}

const setExpertsTopicArray =  createAction(ActionTypes.SET_EXPERTS_TOPIC_FILTER)

export const setExpertsTopicFilter = (data) =>(dispatch) =>{
  dispatch(setExpertsTopicArray(data))
}

const setExpertsTopicAction = createAction(ActionTypes.SET_EXPERTS_TOPIC_DATA)

export const setExpertsTopicData = (data) => (dispatch) =>{
  dispatch(setExpertsTopicAction(data))
}

const setExpertsTopicHandlerAction = createAction(ActionTypes.SET_EXPERTS_TOPIC_HANDLER)

export const setExpertsTopicHandler = (data) =>(dispatch)=>{
  dispatch(setExpertsTopicHandlerAction(data))
}

const setExpertsResourceArray =  createAction(ActionTypes.SET_EXPERTS_RESOURCE_FILTER)

export const setExpertsResourceFilter = (data) =>(dispatch) =>{
  dispatch(setExpertsResourceArray(data))
}

const setExpertsResourceAction = createAction(ActionTypes.SET_EXPERTS_RESOURCE_DATA)

export const setExpertsResourceData = (data) => (dispatch) =>{
  dispatch(setExpertsResourceAction(data))
}

const setExpertsResourceHandlerAction = createAction(ActionTypes.SET_EXPERTS_RESOURCE_HANDLER)

export const setExpertsResourceHandler = (data) =>(dispatch)=>{
  dispatch(setExpertsResourceHandlerAction(data))
}

const setExpertsHomeFlagAction = createAction(ActionTypes.EXPERTS_HOME_FLAG)

export const setDataHomeFlagFunction = (data) =>(dispatch)=>{
  dispatch(setExpertsHomeFlagAction(data))
}

const setExpertsSearchCountryAction = createAction(ActionTypes.EXPERTS_SEARCH_COUNTRY)

export const setExpertsSearchCountry = (data) =>(dispatch)=>{
  dispatch(setExpertsSearchCountryAction(data))
}

const setExpertsIgnoreQueryAction = createAction(ActionTypes.EXPERTS_IGNORE_QUERY)

export const setExpertsIgnoreQuery = (data) =>(dispatch)=>{
    dispatch(setExpertsIgnoreQueryAction(data))
}


const reducer = handleActions({
   
[ActionTypes.SET_EXPERTS_ORG_FILTER]: (state, action) => ({
        ...state,
        experts_org_filter: action.payload,
      }),
[ActionTypes.SET_EXPERTS_COUNTRY_FILTER]: (state, action) => ({
        ...state,
        experts_country_filter : action.payload,
      }),
[ActionTypes.SET_EXPERTS_COUNTRY_DATA]: (state, action) => ({
        ...state,
        experts_country_data : action.payload,
      }),
  [ActionTypes.SET_EXPERTS_COUNTRY_HANDLER]: (state, action) => ({
        ...state,
        experts_country_handler : action.payload,
      }),   
  [ActionTypes.SET_EXPERTS_ORG_DATA]: (state, action) => ({
        ...state,
        experts_org_data : action.payload,
      }),            
  [ActionTypes.SET_EXPERTS_ORG_HANDLER]: (state, action) => ({
        ...state,
        experts_org_handler : action.payload,
      }),
  [ActionTypes.SET_EXPERTS_REGION_FILTER]: (state, action) => ({
        ...state,
        experts_region_filter : action.payload,
      }),  
  [ActionTypes.SET_EXPERTS_REGION_DATA]: (state, action) => ({
        ...state,
        experts_region_data : action.payload,
      }),                     
  [ActionTypes.SET_EXPERTS_REGION_HANDLER]: (state, action) => ({
        ...state,
        experts_region_handler : action.payload,
      }),
  [ActionTypes.SET_EXPERTS_SECTOR_FILTER]: (state, action) => ({
        ...state,
        experts_sector_filter : action.payload,
      }),  
  [ActionTypes.SET_EXPERTS_SECTOR_DATA]: (state, action) => ({
        ...state,
        experts_sector_data : action.payload,
      }),                     
  [ActionTypes.SET_EXPERTS_SECTOR_HANDLER]: (state, action) => ({
        ...state,
        experts_sector_handler : action.payload,
      }), 
  [ActionTypes.SET_EXPERTS_TOPIC_FILTER]: (state, action) => ({
        ...state,
        experts_topic_filter : action.payload,
      }),  
  [ActionTypes.SET_EXPERTS_TOPIC_DATA]: (state, action) => ({
        ...state,
        experts_topic_data : action.payload,
      }),                     
  [ActionTypes.SET_EXPERTS_TOPIC_HANDLER]: (state, action) => ({
        ...state,
        experts_topic_handler : action.payload,
      }),
      [ActionTypes.SET_EXPERTS_RESOURCE_FILTER]: (state, action) => ({
        ...state,
        experts_resource_filter : action.payload,
      }),  
  [ActionTypes.SET_EXPERTS_RESOURCE_DATA]: (state, action) => ({
        ...state,
        experts_resource_data : action.payload,
      }),                     
  [ActionTypes.SET_EXPERTS_RESOURCE_HANDLER]: (state, action) => ({
        ...state,
        experts_resource_handler : action.payload,
      }), 
  [ActionTypes.EXPERTS_HOME_FLAG]: (state, action) => ({
        ...state,
        experts_home_flag : action.payload,
      }), 
  [ActionTypes.EXPERTS_SEARCH_COUNTRY]: (state, action) => ({
        ...state,
        experts_search_country : action.payload,
      }),  
[ActionTypes.EXPERTS_IGNORE_QUERY]: (state, action) => ({
        ...state,
        experts_ignore_query : action.payload,
      }),                                                 
},initialState);

export default reducer;
