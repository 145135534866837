import React, { useState, useEffect,useRef } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ResultFilter from './components/filter';
import Filter from './components/filters'
import ExpertImg from '../../../../assets/images/avatar-dummy.png';
import ProfileDummy from '../../../../assets/images/profile-dummy.png'
import MailIcon from '../../../../assets/images/icons/mailicon.svg';
import OrganisationImg from '../../../../assets/images/icons/organisation.svg';
import SubjectImg from '../../../../assets/images/icons/subject.svg';
import { useSelector, shallowEqual } from 'react-redux'
import { FadeLoader } from "react-spinners";
import { useDispatch } from 'react-redux'
import { recordDetails, bookmarkRecords,
  setSourceKey,setRegionKey,setCountryKey,setTopicKey,setSectorKey,voteUp,setFileVar, keywordSearchExperts} from './../../services';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Paginate from '../../../../shared/reactpagination';


function ExpertsResults({ category }) {
  const results_state = useSelector((state) => state?.results);
  const searchTerm = useSelector((state) => state?.results?.search);
  const file = useSelector((state) => state?.results?.file);
  const file_path = useSelector((state)=>state?.results?.keyword_search_experts?.response?.data?.data?.file_path)
  const filePathVar = useSelector((state)=>state?.results?.file_var)
  const [initialFile, setInitialFile] = useState(file);
  const [AccessShow, AccessSetShow] = useState(false);
  const AccesshandleClose = () => AccessSetShow(false);
  const AccesshandleShow = () => AccessSetShow(true);
  const dispatch = useDispatch();
  const [liked, setLiked] = useState(true);
  const [bookmarked, setBookmarked] = useState(true);
  useEffect(() => {
    if (initialFile !== file) {
      setInitialFile(file);
    }
  }, [file]);
  useEffect(()=>{
    if(file_path!==undefined&&file_path!==null&&file_path!==""){
      dispatch(setFileVar(file_path))
    }
  },[file_path])
  const result = useSelector(
    (state) => state?.results?.keyword_search_experts?.response?.data?.data?.items
  );
  const loading = useSelector(
    (state) => state?.results?.keyword_search_experts?.loading
  );
  const req =
    useSelector(
      (state) => state?.results?.keyword_search_experts?.response?.request?.responseURL
    ) || "";
  
  const token = useSelector(
    (store) => store?.auth?.signin?.response?.data?.data?.token,
    shallowEqual
  );

  const bookmark_state = useSelector((state)=>state?.results?.bookmark)
  const bookmark_ref = useRef(bookmark_state)
  bookmark_ref.current = bookmark_state
  
  
  
  const tokenSso = useSelector(
    (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token,
    shallowEqual
  );

  const shared = localStorage.getItem("shared");

  const count = useSelector(
    (state) =>
      state?.results?.keyword_search_experts?.response?.data?.data?.total_results
  );

  const [skip, setSkip] = useState(0);
 

  const [currentPage,setCurrentPage] = useState(0)

  const handlePage =(data)=>{
    setSkip((data?.selected)*10)
    setCurrentPage(data?.selected)
  }

  const ignore_query_state = useSelector((state) => state?.expertsFilter?.experts_ignore_query);
 

  
  const searchFunction = () => {
      const form = new FormData()
      form.append("category","experts")
      form.append("qterm",searchTerm)
      if(file!==""&&filePathVar===""){
        form.append("qfile",file)
        form.append("is_file",true)
      }else if(file!==""&&filePathVar!==""){
        form.append("qfile",filePathVar)
        form.append("is_file",true)
      }
      form.append("org_name",results_state?.source)
      form.append("region",results_state?.region)
      form.append("country",results_state?.country)
      form.append("sector",results_state?.sector)
      form.append("topic",results_state?.topic)
      form.append("ignore_query",ignore_query_state)
      form.append("start_date","")
      form.append("end_date","")
      form.append("rows",10)
      form.append("skip",skip)
    if (token !== undefined) {
      
      dispatch(
        keywordSearchExperts(
         form,token,"experts"
        )
      );
    } else if (tokenSso !== undefined) {
      dispatch(
        keywordSearchExperts(
         form,tokenSso,"experts"
        )
      );
    }
  };

  useEffect(() => {
    if (searchTerm && category === "experts"){
       searchFunction()
       setSkip(0)
       setCurrentPage(0)      
      }
  }, [
    results_state?.source,
    results_state?.region,
    results_state?.country,
    results_state?.sector,
    results_state?.topic,
    ignore_query_state,
    "",
    "",
  ]);

  useEffect(() => {
    if (searchTerm && category === "experts"){
       searchFunction()
            
      }
  }, [
    skip,
    category,
  ]);
  useEffect(() => {
    if (file!=="" && category === "experts") {searchFunction()
      setSkip(0)
       setCurrentPage(0)  
    };
  }, [
    results_state?.source,
    results_state?.region,
    results_state?.country,
    results_state?.sector,
    results_state?.topic,
    results_state?.resource,
    ignore_query_state,
    "",
    "",
  ]);

  useEffect(() => {
    if (file!=="" && category === "experts") searchFunction();
  }, [
    skip,
    category,
  ]);

  useEffect(()=>{
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
  },[skip])

  useEffect(()=>{
    if (searchTerm && category === "experts"){
      setSkip(0)
       setCurrentPage(0)   
      // dispatch(setSourceKey(""))
      //   dispatch(setCountryKey(""))
      //   dispatch(setRegionKey(""))
      //   dispatch(setTopicKey(""))
      //   dispatch(setSectorKey(""))
        const form = new FormData()
        form.append("category","experts")
        form.append("qterm",searchTerm)
        if(file!==""&&filePathVar===""){
          form.append("qfile",file)
          form.append("is_file",true)
        }else if(file!==""&&filePathVar!==""){
          form.append("qfile",filePathVar)
          form.append("is_file",true)
        }
        form.append("org_name",results_state?.source)
        form.append("region",results_state?.region)
        form.append("country",results_state?.country)
        form.append("sector",results_state?.sector)
        form.append("topic",results_state?.topic)
        form.append("start_date","")
        form.append("end_date","")
        form.append("ignore_query",ignore_query_state)
        form.append("rows",10)
        form.append("skip",skip)    
    if (token !== undefined) {
      dispatch(
        keywordSearchExperts(
         form,token,"experts"
        )
      );
    } else if (tokenSso !== undefined) {
      dispatch(
        keywordSearchExperts(
         form,tokenSso,"experts"
        )
      );
    }
    }
        
  },[searchTerm])
  useEffect(()=>{
    if (file!=="" && searchTerm===""&& category === "experts"){
      setSkip(0)
       setCurrentPage(0)   
      // dispatch(setSourceKey(""))
      //   dispatch(setCountryKey(""))
      //   dispatch(setRegionKey(""))
      //   dispatch(setTopicKey(""))
      //   dispatch(setSectorKey(""))
        const form = new FormData()
        form.append("category","experts")
        form.append("qterm",searchTerm)
        if(file!==""&&filePathVar===""){
          form.append("qfile",file)
          form.append("is_file",true)
        }else if(file!==""&&filePathVar!==""){
          form.append("qfile",filePathVar)
          form.append("is_file",true)
        }
        form.append("org_name",results_state?.source)
        form.append("region",results_state?.region)
        form.append("country",results_state?.country)
        form.append("sector",results_state?.sector)
        form.append("topic",results_state?.topic)
        form.append("start_date","")
        form.append("end_date","")
        form.append("ignore_query",ignore_query_state)
        form.append("rows",10)
        form.append("skip",skip)    
    if (token !== undefined) {
      dispatch(
        keywordSearchExperts(
         form,token,"experts"
        )
      );
    } else if (tokenSso !== undefined) {
      dispatch(
        keywordSearchExperts(
         form,tokenSso,"experts"
        )
      );
    }
    }
        
  },[file])

  const toggleVote = (
    project_id,
    name,
    category,
    org_id,
    like
  ) => {
    const data = {
      project_id: project_id,
      title: name,
      category: category,
      like: like,
      organisation_id: org_id,
    };
    if (token !== undefined) {
      dispatch(voteUp(data, token)).then(() => {
        setLiked(!liked);
      });
    } else if (tokenSso !== undefined) {
      dispatch(voteUp(data, tokenSso)).then(() => {
        setLiked(!liked);
      });
    }
  };


  const toggleBookmark = (
    project_id,
    title,
    category,
    bookmarks,
    organisation_id
  ) => {
    const data = {
      project_id: project_id,
      title: title,
      category: category,
      bookmark: bookmarks,
      organisation_id: organisation_id,
    };
    if (token !== undefined) {
      dispatch(bookmarkRecords(data, token)).then(() => {
        setBookmarked(!bookmarked);
        if(!bookmark_ref?.current?.hasError&&bookmark_ref?.current?.response?.data?.data?.bookmark==="Record Bookmarked"&&req.includes("experts")){
          toast.success("Bookmarked successfully, please find it in the Bookmarks tab!")
        }
      });
    } else if (tokenSso !== undefined) {
      dispatch(bookmarkRecords(data, tokenSso)).then(() => {
        setBookmarked(!bookmarked);
        if(!bookmark_ref?.current?.hasError&&bookmark_ref?.current?.response?.data?.data?.bookmark==="Record Bookmarked"&&req.includes("experts")){
          toast.success("Bookmarked successfully, please find it in the Bookmarks tab!")
        }
      });
    }
  };
  



  return (
    <div className="tab-content-wrap finance-container">
      <Row>
        <Col md={4} lg={3}>
          {/* <ResultFilter /> */}
          <Filter />
        </Col>
        <Col md={8} lg={9}>

          <div className="multi-result-contents">
            <Row>
              {loading ?
                <div className="loader-middle-align">
                  <div className="loader loader-3">
                    <div className="dot dot1">&nbsp;</div>
                    <div className="dot dot2">&nbsp;</div>
                    <div className="dot dot3">&nbsp;</div>
                  </div></div> :
                <>
                  {req.includes("experts") && result ? result.map((item, i) => (
                    <Col xl={6}>
                      <div className="experts-box" key={i}>
                        <div className="experts-box-left">
                          <div className="expert-left-img">
                            <img src={item?.profile_pic_path?item?.profile_pic_path:ProfileDummy} alt="" />
                          </div>
                        </div>
                        <div className="experts-right">
                          <div className="experts-right-content">
                            <h3>{item.name}</h3>
                            <div className="d-flex experts-pers-info-wrap">
                              <span className="experts-pers-info-icon"> <img src={MailIcon} alt="" /></span>
                              <span className="experts-pers-info-text">{item.email}</span>
                            </div>
                            <div className="d-flex experts-pers-info-wrap">
                              <span className="experts-pers-info-icon"> <img src={OrganisationImg} alt="" /></span>
                              <span className="experts-pers-info-text">{item.org_name}</span>
                            </div>
                            <div className="d-flex experts-pers-info-wrap">
                              <span className="experts-pers-info-icon"> <img src={SubjectImg} alt="" /></span>
                              <span className="experts-pers-info-text">{item.designation}</span>
                            </div>
                            <div className="experts-footer">

                              {/* <span className="like-count">
                                22
                              </span> */}
                              {/* <button
                                type="button"
                                className="icon-button liked"

                              >
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="InfoText">
                                      Vote up
                                    </Tooltip>
                                  }
                                >
                                  <svg
                                    width="22"
                                    height="22"
                                    viewBox="0 0 22 22"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ fill: "#1A73E8" }}
                                  >
                                    <path d="M18.3279 7.15H14.6623L14.8718 5.335C15.186 2.64 14.6623 0.495 12.9866 0H12.8295C12.6564 0.0319322 12.4937 0.109127 12.3564 0.224469C12.2192 0.339811 12.1117 0.48959 12.0441 0.66C12.0441 0.66 9.05923 7.26 7.33117 8.58V19.8H8.01192C8.1254 19.7899 8.23965 19.8042 8.34769 19.842C8.45574 19.8798 8.5553 19.9404 8.64031 20.02C9.37343 20.57 11.8346 22 13.0914 22H15.7097C18.7992 22 21.4699 19.8 21.7317 15.455L21.9935 11.055C22.0235 10.5387 21.9496 10.0215 21.7767 9.53721C21.6037 9.05287 21.3357 8.6122 20.9899 8.24385C20.6442 7.8755 20.2284 7.58774 19.7698 7.39928C19.3112 7.21082 18.8199 7.12588 18.3279 7.15ZM0 9.9V18.7C0 18.9917 0.110341 19.2715 0.30675 19.4778C0.503159 19.6841 0.769546 19.8 1.04731 19.8H5.23655V8.8H1.04731C0.769546 8.8 0.503159 8.91589 0.30675 9.12218C0.110341 9.32847 0 9.60826 0 9.9Z" />
                                  </svg>
                                </OverlayTrigger>
                              </button> */}
                              <button
                          type="button"
                          className="icon-button liked"
                          onClick={() => {
                            item?.record_details?.vote_up
                              ? (toggleVote(
                                item?.user_id,
                                item?.email,
                                "experts",
                                0,
                                item?.org_id
                                ),
                                (item.record_details.vote_up = false),
                                (item.record_details.vote_up_count =
                                  item.record_details.vote_up_count - 1))
                              : (toggleVote(
                                item?.user_id,
                                item?.email,
                                "experts",
                                1,
                                item?.org_id
                                ),
                                (item.record_details.vote_up = true),
                                (item.record_details.vote_up_count =
                                  item.record_details.vote_up_count + 1));
                          }}
                        >
                          <span className="like-count">
                            {item?.record_details?.vote_up_count}
                          </span>
                          {item.record_details?.vote_up ? (
                              <OverlayTrigger
                              overlay={
                                <Tooltip id="InfoText">
                                 Vote up
                                </Tooltip>
                              }
                            >
                            <svg
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ fill: "#1A73E8" }}
                            >
                              <path d="M18.3279 7.15H14.6623L14.8718 5.335C15.186 2.64 14.6623 0.495 12.9866 0H12.8295C12.6564 0.0319322 12.4937 0.109127 12.3564 0.224469C12.2192 0.339811 12.1117 0.48959 12.0441 0.66C12.0441 0.66 9.05923 7.26 7.33117 8.58V19.8H8.01192C8.1254 19.7899 8.23965 19.8042 8.34769 19.842C8.45574 19.8798 8.5553 19.9404 8.64031 20.02C9.37343 20.57 11.8346 22 13.0914 22H15.7097C18.7992 22 21.4699 19.8 21.7317 15.455L21.9935 11.055C22.0235 10.5387 21.9496 10.0215 21.7767 9.53721C21.6037 9.05287 21.3357 8.6122 20.9899 8.24385C20.6442 7.8755 20.2284 7.58774 19.7698 7.39928C19.3112 7.21082 18.8199 7.12588 18.3279 7.15ZM0 9.9V18.7C0 18.9917 0.110341 19.2715 0.30675 19.4778C0.503159 19.6841 0.769546 19.8 1.04731 19.8H5.23655V8.8H1.04731C0.769546 8.8 0.503159 8.91589 0.30675 9.12218C0.110341 9.32847 0 9.60826 0 9.9Z" />
                            </svg>
                            </OverlayTrigger>
                          ) : (
                            <OverlayTrigger
                            overlay={
                              <Tooltip id="InfoText">
                               Vote up
                              </Tooltip>
                            }
                          >
                            <svg
                              width="23"
                              height="22"
                              viewBox="0 0 23 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19.1635 7.15H15.3308L15.5498 5.335C15.8783 2.64 15.3308 0.495 13.5787 0H13.4144C13.2334 0.0319322 13.0633 0.109127 12.9197 0.224469C12.7762 0.339811 12.6639 0.48959 12.5931 0.66C12.5931 0.66 8.76044 8.8 7.11786 8.8H1.09505C0.804628 8.8 0.526097 8.91589 0.320734 9.12218C0.115371 9.32847 0 9.60826 0 9.9V18.7C0 18.9917 0.115371 19.2715 0.320734 19.4778C0.526097 19.6841 0.804628 19.8 1.09505 19.8H8.37717C8.49582 19.7899 8.61528 19.8042 8.72825 19.842C8.84121 19.8798 8.94532 19.9404 9.0342 20.02C9.80074 20.57 12.3741 22 13.6882 22H16.4258C19.6562 22 22.4486 19.8 22.7224 15.455L22.9961 11C23.0195 10.4882 22.9364 9.97707 22.752 9.49934C22.5677 9.02161 22.2862 8.58774 21.9255 8.22544C21.5649 7.86314 21.133 7.58038 20.6574 7.39521C20.1818 7.21003 19.673 7.12652 19.1635 7.15ZM2.19011 11H5.47527V17.6H2.19011V11ZM20.5323 15.29C20.3133 18.15 18.7802 19.8 16.4258 19.8H13.6882C13.0311 19.745 11.0601 18.755 10.3483 18.26C9.78349 17.8242 9.08929 17.5918 8.37717 17.6H7.66538V11C8.70568 10.78 10.403 9.79 13.4144 3.96C13.4211 4.34578 13.4028 4.7316 13.3597 5.115L13.1407 6.93L12.8669 9.35H19.1635C19.3805 9.3249 19.6004 9.34844 19.8073 9.41891C20.0141 9.48938 20.2029 9.60503 20.36 9.75756C20.5171 9.91008 20.6385 10.0957 20.7157 10.301C20.7928 10.5063 20.8237 10.7263 20.806 10.945L20.5323 15.29Z"
                                fill="#373737"
                              />
                            </svg>
                            </OverlayTrigger>
                          )}
                        </button>

                              {/* <button
                                type="button"
                                className="icon-button"

                              >
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="InfoText">
                                      Bookmark
                                    </Tooltip>
                                  }
                                >
                                  <svg
                                    id="Group_2176"
                                    data-name="Group 2176"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="22"
                                    viewBox="0 0 22 22"
                                  >
                                    <g
                                      id="icons_Q2"
                                      data-name="icons Q2"
                                      transform="translate(-61 -9)"
                                    >
                                      <path
                                        id="Path_2366"
                                        data-name="Path 2366"
                                        d="M23.895,7.316V23.526l-4.4-3.358-1.389-1.042-1.389,1.042-4.4,3.358V7.316H23.895M11.158,5A1.158,1.158,0,0,0,10,6.158V25.842A1.158,1.158,0,0,0,11.158,27a1.389,1.389,0,0,0,.811-.289l6.137-4.689,6.137,4.689a1.389,1.389,0,0,0,.811.289,1.158,1.158,0,0,0,1.158-1.158V6.158A1.158,1.158,0,0,0,25.053,5Z"
                                        transform="translate(54 4)"
                                        fill="#0D6EFD"
                                      />
                                      <path
                                        id="Path_2367"
                                        data-name="Path 2367"
                                        d="M-126.668-6515.948v18.35l6.819-5.212,6.819,5.212v-18.35Z"
                                        transform="translate(192 6527)"
                                        fill="#0D6EFD"
                                      />
                                    </g>
                                    <rect
                                      id="Rectangle_2567"
                                      data-name="Rectangle 2567"
                                      width="22"
                                      height="22"
                                      fill="none"
                                    />
                                  </svg>
                                </OverlayTrigger>

                              </button> */}
                              <button
                          type="button"
                          className="icon-button"
                          onClick={() => {
                            item?.record_details?.bookmark
                              ? (toggleBookmark(
                                  item?.user_id,
                                  item?.email,
                                  "experts",
                                  0,
                                  item?.org_id
                                ),
                                (item.record_details.bookmark = false))
                              : (toggleBookmark(
                                  item?.user_id,
                                  item?.email,
                                  "experts",
                                  1,
                                  item?.org_id
                                ),
                                (item.record_details.bookmark = true));
                          }}
                        >
                          {item?.record_details?.bookmark ? (
                              <OverlayTrigger
                              overlay={
                                <Tooltip id="InfoText">
                              Bookmark
                                </Tooltip>
                              }
                            >
                            <svg
                              id="Group_2176"
                              data-name="Group 2176"
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                            >
                              <g
                                id="icons_Q2"
                                data-name="icons Q2"
                                transform="translate(-61 -9)"
                              >
                                <path
                                  id="Path_2366"
                                  data-name="Path 2366"
                                  d="M23.895,7.316V23.526l-4.4-3.358-1.389-1.042-1.389,1.042-4.4,3.358V7.316H23.895M11.158,5A1.158,1.158,0,0,0,10,6.158V25.842A1.158,1.158,0,0,0,11.158,27a1.389,1.389,0,0,0,.811-.289l6.137-4.689,6.137,4.689a1.389,1.389,0,0,0,.811.289,1.158,1.158,0,0,0,1.158-1.158V6.158A1.158,1.158,0,0,0,25.053,5Z"
                                  transform="translate(54 4)"
                                  fill="#0D6EFD"
                                />
                                <path
                                  id="Path_2367"
                                  data-name="Path 2367"
                                  d="M-126.668-6515.948v18.35l6.819-5.212,6.819,5.212v-18.35Z"
                                  transform="translate(192 6527)"
                                  fill="#0D6EFD"
                                />
                              </g>
                              <rect
                                id="Rectangle_2567"
                                data-name="Rectangle 2567"
                                width="22"
                                height="22"
                                fill="none"
                              />
                            </svg>
                            </OverlayTrigger>
                          ) : (
                            <OverlayTrigger
                            overlay={
                              <Tooltip id="InfoText">
                            Bookmark
                              </Tooltip>
                            }
                          >
                            <svg
                              id="Group_2175"
                              data-name="Group 2175"
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                            >
                              <g
                                id="icons_Q2"
                                data-name="icons Q2"
                                transform="translate(-61 -9)"
                              >
                                <path
                                  id="Path_2366"
                                  data-name="Path 2366"
                                  d="M23.895,7.316V23.526l-4.4-3.358-1.389-1.042-1.389,1.042-4.4,3.358V7.316H23.895M11.158,5A1.158,1.158,0,0,0,10,6.158V25.842A1.158,1.158,0,0,0,11.158,27a1.389,1.389,0,0,0,.811-.289l6.137-4.689,6.137,4.689a1.389,1.389,0,0,0,.811.289,1.158,1.158,0,0,0,1.158-1.158V6.158A1.158,1.158,0,0,0,25.053,5Z"
                                  transform="translate(54 4)"
                                />
                              </g>
                              <rect
                                id="Rectangle_2567"
                                data-name="Rectangle 2567"
                                width="22"
                                height="22"
                                fill="none"
                              />
                            </svg>
                            </OverlayTrigger>
                          )}
                        </button>
                            </div>
                          </div>
                        </div>


                      </div>

                    </Col>
                  )):<p className="no-results-wraper">No results found</p>}
                </>}
                {!loading && req.includes("experts") &&
                result&&(<Paginate
                  
                  pageCount={(Math.ceil(count/10))}
                  onPageChange={handlePage}
                  initialPage={currentPage}
                  
                  />)}
            </Row>
          </div>

        </Col>
      </Row>
    </div>
  )
}

export default ExpertsResults;