import React, { useEffect, useState,useRef } from 'react';
import Form from 'react-bootstrap/Form';
import CloseSm from '../../assets/images/icons/close-sm.svg';
import ScrollArea from "react-scrollbar";
import Button from 'react-bootstrap/esm/Button';
import DummyThumb from '../../assets/images/src-dummy.jpg'
import ProfileDummy from '../../assets/images/profile-dummy.png'
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { useSelector,useDispatch,shallowEqual } from "react-redux";
import {shareSingleQuery,usersListQueryShared} from './../../modules/results/services'
import { Formik } from "formik";
import {_removeAccessQuery} from './../../shared/httpservice/apis'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ManageAccess(props) {
    const result = useSelector((state)=>state?.results?.userListShareQuery?.response?.data?.data?.element)
    const resultTitle = useSelector((state) => state?.results?.result_title);
    const sharedListMessage = useSelector((state)=>state?.results?.userListQuerySharedTo?.response?.data?.data?.message)
    const isSaved = useSelector((state)=>state?.results?.userListQuerySharedTo?.response?.data?.data?.is_saved)
    const queryName = useSelector((state)=>state?.results?.userListQuerySharedTo?.response?.data?.data?.title)

    const filterService = useSelector((state) => state?.filter); 
    const filterServiceDev = useSelector((state) => state?.devFilter);
    const filterServiceOther = useSelector((state) => state?.otherFilter);
    const filterServiceData = useSelector((state) => state?.dataFilter);
    const filterServiceMul = useSelector((state)=>state?.mulFilter)
    const filterServiceEval =  useSelector((state)=>state?.evalFilter)
    const filterServiceExperts = useSelector((state)=>state?.expertsFilter)
    const filterServiceEcon = useSelector((state)=>state?.econFilter)

    const results_state = useSelector((state)=>state?.results)

    const type = typeof(sharedListMessage)

    const loading = useSelector((state)=>state?.results?.userListQuerySharedTo?.loading)
    const [userList,setUserList] = useState()
    const [deleteUser,setDeleteUser] = useState(false)
    const searchTerm = useSelector((state)=>state?.results?.search)
    const file = useSelector((state) => state?.results?.file);
    const file_path = useSelector((state)=>state?.results?.keyword_search?.response?.data?.data?.file_path)
    const file_path_fin = useSelector((state)=>state?.results?.keyword_search_fin?.response?.data?.data?.file_path)
    const file_path_dev = useSelector((state)=>state?.results?.keyword_search_dev?.response?.data?.data?.file_path)
    const file_path_other = useSelector((state)=>state?.results?.keyword_search_other?.response?.data?.data?.file_path)
    const file_path_data = useSelector((state)=>state?.results?.keyword_search_data?.response?.data?.data?.file_path)
    const file_path_eval = useSelector((state)=>state?.results?.keyword_search_eval?.response?.data?.data?.file_path)
    const file_path_news = useSelector((state)=>state?.results?.keyword_search_news?.response?.data?.data?.file_path)
    const file_path_mul = useSelector((state)=>state?.results?.keyword_search_mul?.response?.data?.data?.file_path)
    const file_path_econ = useSelector((state)=>state?.results?.keyword_search_econ?.response?.data?.data?.file_path)
    const file_path_experts = useSelector((state)=>state?.results?.keyword_search_experts?.response?.data?.data?.file_path)
    const dispatch = useDispatch()
    const token = useSelector((store)=>store?.auth?.signin?.response?.data?.data?.token,shallowEqual)
    const tokenSso = useSelector(
        (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token,shallowEqual
      );
    const share_query_state = useSelector((state)=>state?.results?.shareSingleQuery)
    const share_query_state_ref = useRef(share_query_state)
    share_query_state_ref.current=share_query_state  
    const errorShareQuery = useSelector((state)=>state?.results?.shareSingleQuery?.hasError)  
      const [clicked,setClicked] = useState(true)
    useEffect(()=>{
        if(result&& type==="string"|| type==='undefined'){
            const userOptions = result?.map((item) => ({
                value: item.id,
                label: item.first_name + " " + item.last_name,
              }));
              setUserList(userOptions)
        }
        else {
            const id = sharedListMessage?.map((item)=>({id:item?.user}))

            const myArray = result?.filter(ar => !id.find(rm => (rm.id === ar.id) ))

            const userFiltered = myArray?.map((item)=>({
                value: item.id,
                label: item.first_name + " " + item.last_name,
            }))
            setUserList(userFiltered)
        }
        
    },[result,sharedListMessage])  
    const [select,setSelect] = useState([])  
    const userMap = select?.map((item) => ({ value: item.value }));

    var userValues = userMap?.map((i) => {
      return i.value;
    });

    const Option = (props) => {
        return (
          <div>
            <components.Option {...props}>
              <input
                className="react-multi-select-item"
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null}
              />{" "}
              <label>{props.label}</label>
            </components.Option>
          </div>
        );
      };
      const querySharedWith = ()=>{
        if(searchTerm!==""&&file===""){
            if(props?.category==="financing"){
                const data = {
                    "query" : {
                        "category":props?.category,
                        "qterm":searchTerm,
                        "org_name" : results_state?.financeSource,
                        "region" : results_state?.financeRegion,
                        "country" : results_state?.financeCountry,
                        "sector" : results_state?.financeSector,
                        "topic" : results_state?.financeTopic,
                        "start_date" : results_state?.financeSdate,
                        "end_date" : results_state?.financeEdate, 
                    },
                    "category" : props?.category
                }
                if(token!==undefined){
                    dispatch(usersListQueryShared(data,token))
                }else if(tokenSso!==undefined){
                    dispatch(usersListQueryShared(data,tokenSso))
                }
            }else if(props?.category==="devpubs"){
                const data = {
                    "query" : {
                        "category":props?.category,
                        "qterm":searchTerm,
                        "org_name" : results_state?.devSource,
                        "region" : results_state?.devRegion,
                        "country" : results_state?.devCountry,
                        "sector" : results_state?.devSector,
                        "topic" : results_state?.devTopic,
                        "start_date" : results_state?.devSdate,
                        "end_date" : results_state?.devEdate,   
                    },
                    "category" : props?.category
                }
                if(token!==undefined){
                    dispatch(usersListQueryShared(data,token))
                }else if(tokenSso!==undefined){
                    dispatch(usersListQueryShared(data,tokenSso))
                }
            }else if(props?.category==="otherpubs"){
                const data = {
                    "query" : {
                        "category":props?.category,
                        "qterm":searchTerm,
                        "org_name" : results_state?.otherSource,
                        "region" : results_state?.otherRegion,
                        "country" : results_state?.otherCountry,
                        "sector" : results_state?.otherSector,
                        "topic" : results_state?.otherTopic,
                        "start_date" : results_state?.otherSdate,
                        "end_date" : results_state?.otherEdate,   
                    },
                    "category" : props?.category
                }
                if(token!==undefined){
                    dispatch(usersListQueryShared(data,token))
                }else if(tokenSso!==undefined){
                    dispatch(usersListQueryShared(data,tokenSso))
                }
            }else if(props?.category==="data"){
                const data = {
                    "query" : {
                        "category":props?.category,
                        "qterm":searchTerm,
                        "org_name" : results_state?.dataSource,
                        "region" : results_state?.dataRegion,
                        "country" : results_state?.dataCountry,
                        "sector" : results_state?.dataSector,
                        "topic" : results_state?.dataTopic,
                        "resource" :results_state?.dataResource      
                    },
                    "category" : props?.category
                }
                if(token!==undefined){
                    dispatch(usersListQueryShared(data,token))
                }else if(tokenSso!==undefined){
                    dispatch(usersListQueryShared(data,tokenSso))
                }
            }else if(props?.category==="multimedia"){
                const data = {
                    "query" : {
                        "category":props?.category,
                        "qterm":searchTerm,
                        "org_name" : results_state?.mulSource,
                        "region" : results_state?.mulRegion,
                        "country" : results_state?.mulCountry,
                        "sector" : results_state?.mulSector,
                        "topic" : results_state?.mulTopic,
                        "start_date" : results_state?.mulSdate,
                        "end_date" : results_state?.mulEdate,     
                    },
                    "category" : props?.category
                }
                if(token!==undefined){
                    dispatch(usersListQueryShared(data,token))
                }else if(tokenSso!==undefined){
                    dispatch(usersListQueryShared(data,tokenSso))
                }
            }else if(props?.category==="evaluations"){
                const data = {
                    "query" : {
                        "category":props?.category,
                        "qterm":searchTerm,
                        "org_name" : results_state?.evalSource,
                        "region" : results_state?.evalRegion,
                        "country" : results_state?.evalCountry,
                        "sector" : results_state?.evalSector,
                        "topic" : results_state?.evalTopic,
                        "start_date" : results_state?.evalSdate,
                        "end_date" : results_state?.evalEdate,    
                    },
                    "category" : props?.category
                }
                if(token!==undefined){
                    dispatch(usersListQueryShared(data,token))
                }else if(tokenSso!==undefined){
                    dispatch(usersListQueryShared(data,tokenSso))
                }
            }else if(props?.category==="experts"){
                const data = {
                    "query" : {
                        "category":props?.category,
                        "qterm":searchTerm,
                        "org_name" : results_state?.source,
                        "region" : results_state?.region,
                        "country" : results_state?.country,
                        "sector" : results_state?.sector,
                        "topic" : results_state?.topic,    
                        "ignore_query" : filterServiceExperts?.experts_ignore_query  
                    },
                    "category" : props?.category
                }
                if(token!==undefined){
                    dispatch(usersListQueryShared(data,token))
                }else if(tokenSso!==undefined){
                    dispatch(usersListQueryShared(data,tokenSso))
                }
            }else if(props?.category==="econpubs"){
                const data = {
                    "query" : {
                        "category":props?.category,
                        "qterm":searchTerm,
                        "org_name" : results_state?.econSource,
                        "region" : results_state?.econRegion,
                        "country" : results_state?.econCountry,
                        "sector" : results_state?.econSector,
                        "topic" : results_state?.econTopic,
                        "ignore_query" : filterServiceEcon?.econ_ignore_query,
                        "start_date" : results_state?.econSdate,
                        "end_date" : results_state?.econEdate,    
                    },
                    "category" : props?.category
                }
                if(token!==undefined){
                    dispatch(usersListQueryShared(data,token))
                }else if(tokenSso!==undefined){
                    dispatch(usersListQueryShared(data,tokenSso))
                }
            }else if(props?.category==="news"){
                const data = {
                    "query" : {
                        "category":props?.category,
                        "qterm":searchTerm,
                    },
                    "category" : props?.category
                }
                if(token!==undefined){
                    dispatch(usersListQueryShared(data,token))
                }else if(tokenSso!==undefined){
                    dispatch(usersListQueryShared(data,tokenSso))
                }
            }
        }else if(file!==""&&searchTerm===""){
            if(props?.category==="financing"){
                const data = {
                    "query" : {
                        "category":props?.category,
                        "qfile":file_path_fin,
                        "org_name" : results_state?.financeSource,
                        "region" : results_state?.financeRegion,
                        "country" : results_state?.financeCountry,
                        "sector" : results_state?.financeSector,
                        "topic" : results_state?.financeTopic,
                        "start_date" : results_state?.financeSdate,
                        "end_date" : results_state?.financeEdate, 
                    },
                    "category" : props?.category
                }
                if(token!==undefined){
                    dispatch(usersListQueryShared(data,token))
                }else if(tokenSso!==undefined){
                    dispatch(usersListQueryShared(data,tokenSso))
                }
            }else if(props?.category==="devpubs"){
                const data = {
                    "query" : {
                        "category":props?.category,
                        "qfile":file_path_dev,
                        "org_name" : results_state?.devSource,
                        "region" : results_state?.devRegion,
                        "country" : results_state?.devCountry,
                        "sector" : results_state?.devSector,
                        "topic" : results_state?.devTopic,
                        "start_date" : results_state?.devSdate,
                        "end_date" : results_state?.devEdate,   
                    },
                    "category" : props?.category
                }
                if(token!==undefined){
                    dispatch(usersListQueryShared(data,token))
                }else if(tokenSso!==undefined){
                    dispatch(usersListQueryShared(data,tokenSso))
                }}
                else if(props?.category==="otherpubs"){
                    const data = {
                        "query" : {
                            "category":props?.category,
                            "qfile":file_path_other,
                            "org_name" : results_state?.otherSource,
                            "region" : results_state?.otherRegion,
                            "country" : results_state?.otherCountry,
                            "sector" : results_state?.otherSector,
                            "topic" : results_state?.otherTopic,
                            "start_date" : results_state?.otherSdate,
                            "end_date" : results_state?.otherEdate,   
                        },
                        "category" : props?.category
                    }
                    if(token!==undefined){
                        dispatch(usersListQueryShared(data,token))
                    }else if(tokenSso!==undefined){
                        dispatch(usersListQueryShared(data,tokenSso))
                    }
                }else if(props?.category==="data"){
                    const data = {
                        "query" : {
                            "category":props?.category,
                            "qfile":file_path_data,
                            "org_name" : results_state?.dataSource,
                            "region" : results_state?.dataRegion,
                            "country" : results_state?.dataCountry,
                            "sector" : results_state?.dataSector,
                            "topic" : results_state?.dataTopic,
                            "resource" :results_state?.dataResource      
                        },
                        "category" : props?.category
                    }
                    if(token!==undefined){
                        dispatch(usersListQueryShared(data,token))
                    }else if(tokenSso!==undefined){
                        dispatch(usersListQueryShared(data,tokenSso))
                    }
                }else if(props?.category==="multimedia"){
                    const data = {
                        "query" : {
                            "category":props?.category,
                            "qfile":file_path_mul,
                            "org_name" : results_state?.mulSource,
                            "region" : results_state?.mulRegion,
                            "country" : results_state?.mulCountry,
                            "sector" : results_state?.mulSector,
                            "topic" : results_state?.mulTopic,
                            "start_date" : results_state?.mulSdate,
                            "end_date" : results_state?.mulEdate,     
                        },
                        "category" : props?.category
                    }
                    if(token!==undefined){
                        dispatch(usersListQueryShared(data,token))
                    }else if(tokenSso!==undefined){
                        dispatch(usersListQueryShared(data,tokenSso))
                    }
                }else if(props?.category==="evaluations"){
                    const data = {
                        "query" : {
                            "category":props?.category,
                            "qfile":file_path_eval,
                            "org_name" : results_state?.evalSource,
                            "region" : results_state?.evalRegion,
                            "country" : results_state?.evalCountry,
                            "sector" : results_state?.evalSector,
                            "topic" : results_state?.evalTopic,
                            "start_date" : results_state?.evalSdate,
                            "end_date" : results_state?.evalEdate,    
                        },
                        "category" : props?.category
                    }
                    if(token!==undefined){
                        dispatch(usersListQueryShared(data,token))
                    }else if(tokenSso!==undefined){
                        dispatch(usersListQueryShared(data,tokenSso))
                    }
                }else if(props?.category==="experts"){
                    const data = {
                        "query" : {
                            "category":props?.category,
                            "qfile":file_path_experts,
                            "org_name" : results_state?.source,
                            "region" : results_state?.region,
                            "country" : results_state?.country,
                            "sector" : results_state?.sector,
                            "topic" : results_state?.topic,    
                            "ignore_query" : filterServiceExperts?.experts_ignore_query  
                        },
                        "category" : props?.category
                    }
                    if(token!==undefined){
                        dispatch(usersListQueryShared(data,token))
                    }else if(tokenSso!==undefined){
                        dispatch(usersListQueryShared(data,tokenSso))
                    }
                }else if(props?.category==="econpubs"){
                    const data = {
                        "query" : {
                            "category":props?.category,
                            "qfile":file_path_econ,
                            "org_name" : results_state?.econSource,
                            "region" : results_state?.econRegion,
                            "country" : results_state?.econCountry,
                            "sector" : results_state?.econSector,
                            "topic" : results_state?.econTopic,
                            "ignore_query" : filterServiceEcon?.econ_ignore_query,
                            "start_date" : results_state?.econSdate,
                            "end_date" : results_state?.econEdate,    
                        },
                        "category" : props?.category
                    }
                    if(token!==undefined){
                        dispatch(usersListQueryShared(data,token))
                    }else if(tokenSso!==undefined){
                        dispatch(usersListQueryShared(data,tokenSso))
                    }
                }else if(props?.category==="news"){
                    const data = {
                        "query" : {
                            "category":props?.category,
                            "qfile":file_path_news,
                        },
                        "category" : props?.category
                    }
                    if(token!==undefined){
                        dispatch(usersListQueryShared(data,token))
                    }else if(tokenSso!==undefined){
                        dispatch(usersListQueryShared(data,tokenSso))
                    }
                }

        }
        
       }

       useEffect(()=>{
        querySharedWith()
       },[clicked,deleteUser])

      const shareQuery =(values)=>{
        if(sharedListMessage==="Query not found for this user"){
            if(props?.category==="financing"){
                if(searchTerm!==""&&file===""){
                    const data = {
                        "title":title,
                        "query":{
                            "category":props.category,
                            "qterm":searchTerm,
                            "org_name" : results_state?.financeSource,
                            "region" : results_state?.financeRegion,
                            "country" : results_state?.financeCountry,
                            "sector" : results_state?.financeSector,
                            "topic" : results_state?.financeTopic,
                            "start_date" : results_state?.financeSdate,
                            "end_date" : results_state?.financeEdate,  
                        },
                        "category":props.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "financefilterarrayregion" : filterService?.finance_region_filter,
                            "financehandlerregion" : filterService?.finance_region_handler,
                            "financedataregion" : filterService?.finance_region_data,
                            "financefilterregion": results_state?.financeRegion,
                            
                            "financefilterarrayorg" : filterService?.finance_org_filter,
                            "financehandlerorg" : filterService?.finanace_org_handler,
                            "financedataorg" : filterService?.finance_org_data,
                            "financefilterorg" : results_state?.financeSource,
        
                            "financefilterarraycountry" : filterService?.finance_country_filter,
                            "financehandlercountry" : filterService?.finance_country_handler,
                            "financedatacountry" : filterService?.finance_country_data,
                            "financefiltercountry" : results_state?.financeCountry,
        
                            "financefilterarraytopic" :filterService?.finance_topic_filter,
                            "financehandlertopic" : filterService?.finance_topic_handler,
                            "financedatatopic" : filterService?.finance_topic_data,
                            "financefiltertopic" : results_state?.financeTopic,
        
                            "financefilterarraysector" : filterService?.finance_sector_filter,
                            "financehandlersector" : filterService?.finance_sector_handler,
                            "financedatasector" : filterService?.finance_sector_data,
                            "financefiltersector" : results_state?.financeSector,
        
                            "financestartdate" : results_state?.financeSdate,
                            "financeenddate" : results_state?.financeEdate,
                            "financeSdate" : filterService?.finance_start_date_handler,
                            "financeEdate" : filterService?.finance_end_date_handler

                        }
                        
                
                    }  
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token)).then(()=>{
                        if(!share_query_state_ref.current?.hasError){
                            querySharedWith()
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        }else{
                            toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                        }
                    })
                }else if(tokenSso!== undefined){
                    dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                        if(!share_query_state_ref.current?.hasError){
                            querySharedWith()
                            setSelect([])
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        }else{
                            toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                        }
                    })
                }
                }else if(file!==""&&searchTerm===""){
                    const data = {
                        "title":title,
                        "query":{
                            "category":props.category,
                            "qfile":file_path_fin,
                            "org_name" : results_state?.financeSource,
                            "region" : results_state?.financeRegion,
                            "country" : results_state?.financeCountry,
                            "sector" : results_state?.financeSector,
                            "topic" : results_state?.financeTopic,
                            "start_date" : results_state?.financeSdate,
                            "end_date" : results_state?.financeEdate,  
                        },
                        "category":props.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "financefilterarrayregion" : filterService?.finance_region_filter,
                            "financehandlerregion" : filterService?.finance_region_handler,
                            "financedataregion" : filterService?.finance_region_data,
                            "financefilterregion": results_state?.financeRegion,
                            
                            "financefilterarrayorg" : filterService?.finance_org_filter,
                            "financehandlerorg" : filterService?.finanace_org_handler,
                            "financedataorg" : filterService?.finance_org_data,
                            "financefilterorg" : results_state?.financeSource,
        
                            "financefilterarraycountry" : filterService?.finance_country_filter,
                            "financehandlercountry" : filterService?.finance_country_handler,
                            "financedatacountry" : filterService?.finance_country_data,
                            "financefiltercountry" : results_state?.financeCountry,
        
                            "financefilterarraytopic" :filterService?.finance_topic_filter,
                            "financehandlertopic" : filterService?.finance_topic_handler,
                            "financedatatopic" : filterService?.finance_topic_data,
                            "financefiltertopic" : results_state?.financeTopic,
        
                            "financefilterarraysector" : filterService?.finance_sector_filter,
                            "financehandlersector" : filterService?.finance_sector_handler,
                            "financedatasector" : filterService?.finance_sector_data,
                            "financefiltersector" : results_state?.financeSector,
        
                            "financestartdate" : results_state?.financeSdate,
                            "financeenddate" : results_state?.financeEdate,
                            "financeSdate" : filterService?.finance_start_date_handler,
                            "financeEdate" : filterService?.finance_end_date_handler
                        }
                
                    }  
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token)).then(()=>{
                        if(!share_query_state_ref.current?.hasError){
                            querySharedWith()
                            setSelect([])
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        }else{
                            toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                        }
                    })
                }else if(tokenSso!== undefined){
                    dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                        if(!share_query_state_ref.current?.hasError){
                            querySharedWith()
                            setSelect([])
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        }else{
                            toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                        }
                    })
                }
                }
                
                
            }else if(props?.category==="devpubs"){
                if(searchTerm!==""&&file===""){
                    const data = {
                        "title":title,
                        "query":{
                            "category":props.category,
                            "qterm":searchTerm,
                            "org_name" : results_state?.devSource,
                            "region" : results_state?.devRegion,
                            "country" : results_state?.devCountry,
                            "sector" : results_state?.devSector,
                            "topic" : results_state?.devTopic,
                            "start_date" : results_state?.devSdate,
                            "end_date" : results_state?.devEdate,    
                        },
                        "category":props.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "devfilterarrayregion" : filterServiceDev?.dev_region_filter,
                            "devhandlerregion" : filterServiceDev?.dev_region_handler,
                            "devdataregion" : filterServiceDev?.dev_region_data,
                            "devfilterregion": results_state?.devRegion,
                            
                            "devfilterarrayorg" : filterServiceDev?.dev_org_filter,
                            "devhandlerorg" : filterServiceDev?.dev_org_handler,
                            "devdataorg" : filterServiceDev?.dev_org_data,
                            "devfilterorg" : results_state?.devSource,
        
                            "devfilterarraycountry" : filterServiceDev?.dev_country_filter,
                            "devhandlercountry" : filterServiceDev?.dev_country_handler,
                            "devdatacountry" : filterServiceDev?.dev_country_data,
                            "devfiltercountry" : results_state?.devCountry,
        
                            "devfilterarraytopic" :filterServiceDev?.dev_topic_filter,
                            "devhandlertopic" : filterServiceDev?.dev_topic_handler,
                            "devdatatopic" : filterServiceDev?.dev_topic_data,
                            "devfiltertopic" : results_state?.devTopic,
        
                            "devfilterarraysector" : filterServiceDev?.dev_sector_filter,
                            "devhandlersector" : filterServiceDev?.dev_sector_handler,
                            "devdatasector" : filterServiceDev?.dev_sector_data,
                            "devfiltersector" : results_state?.devSector,
        
                            "devstartdate" : results_state?.devSdate,
                            "devenddate" : results_state?.devEdate,
                            "devSdate" : filterServiceDev?.dev_start_date_handler,
                            "devEdate" : filterServiceDev?.dev_end_date_handler
                        }
                
                    }  
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token)).then(()=>{
                        if(!share_query_state_ref.current?.hasError){
                            querySharedWith()
                            setSelect([])
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        }else{
                            toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                        }
                    })
                }else if(tokenSso!== undefined){
                    dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                        if(!share_query_state_ref.current?.hasError){
                            querySharedWith()
                            setSelect([])
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        }else{
                            toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                        }
                    })
                }
                } else if(file!==""&&searchTerm===""){
                    const data = {
                        "title":title,
                        "query":{
                            "category":props.category,
                            "qfile":file_path_dev,
                            "org_name" : results_state?.devSource,
                            "region" : results_state?.devRegion,
                            "country" : results_state?.devCountry,
                            "sector" : results_state?.devSector,
                            "topic" : results_state?.devTopic,
                            "start_date" : results_state?.devSdate,
                            "end_date" : results_state?.devEdate,    
                        },
                        "category":props.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "devfilterarrayregion" : filterServiceDev?.dev_region_filter,
                            "devhandlerregion" : filterServiceDev?.dev_region_handler,
                            "devdataregion" : filterServiceDev?.dev_region_data,
                            "devfilterregion": results_state?.devRegion,
                            
                            "devfilterarrayorg" : filterServiceDev?.dev_org_filter,
                            "devhandlerorg" : filterServiceDev?.dev_org_handler,
                            "devdataorg" : filterServiceDev?.dev_org_data,
                            "devfilterorg" : results_state?.devSource,
        
                            "devfilterarraycountry" : filterServiceDev?.dev_country_filter,
                            "devhandlercountry" : filterServiceDev?.dev_country_handler,
                            "devdatacountry" : filterServiceDev?.dev_country_data,
                            "devfiltercountry" : results_state?.devCountry,
        
                            "devfilterarraytopic" :filterServiceDev?.dev_topic_filter,
                            "devhandlertopic" : filterServiceDev?.dev_topic_handler,
                            "devdatatopic" : filterServiceDev?.dev_topic_data,
                            "devfiltertopic" : results_state?.devTopic,
        
                            "devfilterarraysector" : filterServiceDev?.dev_sector_filter,
                            "devhandlersector" : filterServiceDev?.dev_sector_handler,
                            "devdatasector" : filterServiceDev?.dev_sector_data,
                            "devfiltersector" : results_state?.devSector,
        
                            "devstartdate" : results_state?.devSdate,
                            "devenddate" : results_state?.devEdate,
                            "devSdate" : filterServiceDev?.dev_start_date_handler,
                            "devEdate" : filterServiceDev?.dev_end_date_handler
                        }
                
                    }  
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token)).then(()=>{
                        if(!share_query_state_ref.current?.hasError){
                            querySharedWith()
                            setSelect([])
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        }else{
                            toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                        }
                    })
                }else if(tokenSso!== undefined){
                    dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                        if(!share_query_state_ref.current?.hasError){
                            querySharedWith()
                            setSelect([])
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        }else{
                            toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                        }
                    })
                }
                }
            }else if(props?.category==="otherpubs"){
                if(searchTerm!==""&&file===""){
                    const data = {
                        "title":title,
                        "query":{
                            "category":props.category,
                            "qterm":searchTerm,
                            "org_name" : results_state?.otherSource,
                            "region" : results_state?.otherRegion,
                            "country" : results_state?.otherCountry,
                            "sector" : results_state?.otherSector,
                            "topic" : results_state?.otherTopic,
                            "start_date" : results_state?.otherSdate,
                            "end_date" : results_state?.otherEdate,  
                        },
                        "category":props.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "otherfilterarrayregion" : filterServiceOther?.other_region_filter,
                            "otherhandlerregion" : filterServiceOther?.other_region_handler,
                            "otherdataregion" : filterServiceOther?.other_region_data,
                            "otherfilterregion": results_state?.otherRegion,
                            
                            "otherfilterarrayorg" : filterServiceOther?.other_org_filter,
                            "otherhandlerorg" : filterServiceOther?.other_org_handler,
                            "otherdataorg" : filterServiceOther?.other_org_data,
                            "otherfilterorg" : results_state?.otherSource,
        
                            "otherfilterarraycountry" : filterServiceOther?.other_country_filter,
                            "otherhandlercountry" : filterServiceOther?.other_country_handler,
                            "otherdatacountry" : filterServiceOther?.other_country_data,
                            "otherfiltercountry" : results_state?.otherCountry,
        
                            "otherfilterarraytopic" :filterServiceOther?.other_topic_filter,
                            "otherhandlertopic" : filterServiceOther?.other_topic_handler,
                            "otherdatatopic" : filterServiceOther?.other_topic_data,
                            "otherfiltertopic" : results_state?.otherTopic,
        
                            "otherfilterarraysector" : filterServiceOther?.other_sector_filter,
                            "otherhandlersector" : filterServiceOther?.other_sector_handler,
                            "otherdatasector" : filterServiceOther?.other_sector_data,
                            "otherfiltersector" : results_state?.otherSector,
        
                            "otherstartdate" : results_state?.otherSdate,
                            "otherenddate" : results_state?.otherEdate,
                            "otherSdate" : filterServiceOther?.other_start_date_handler,
                            "otherEdate" : filterServiceOther?.other_end_date_handler
                        }
                
                    }  
                    if(token!==undefined){
                        dispatch(shareSingleQuery(data,token)).then(()=>{
                            if(!share_query_state_ref.current?.hasError){
                                querySharedWith()
                                setSelect([])
                                if(!errorShareQuery){
                                    setClicked(!clicked)
                                    setSelect([])
                                }
                            }else{
                                toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                            }
                        })
                    }else if(tokenSso!== undefined){
                        dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                            if(!share_query_state_ref.current?.hasError){
                                querySharedWith()
                                setSelect([])
                                if(!errorShareQuery){
                                    setClicked(!clicked)
                                    setSelect([])
                                }
                            }else{
                                toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                            }
                        })
                    }
                }else if(file!==""&&searchTerm===""){
                    const data = {
                        "title":title,
                        "query":{
                            "category":props.category,
                            "qterm":searchTerm,
                            "org_name" : results_state?.otherSource,
                            "region" : results_state?.otherRegion,
                            "country" : results_state?.otherCountry,
                            "sector" : results_state?.otherSector,
                            "topic" : results_state?.otherTopic,
                            "start_date" : results_state?.otherSdate,
                            "end_date" : results_state?.otherEdate,  
                        },
                        "category":props.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "otherfilterarrayregion" : filterServiceOther?.other_region_filter,
                            "otherhandlerregion" : filterServiceOther?.other_region_handler,
                            "otherdataregion" : filterServiceOther?.other_region_data,
                            "otherfilterregion": results_state?.otherRegion,
                            
                            "otherfilterarrayorg" : filterServiceOther?.other_org_filter,
                            "otherhandlerorg" : filterServiceOther?.other_org_handler,
                            "otherdataorg" : filterServiceOther?.other_org_data,
                            "otherfilterorg" : results_state?.otherSource,
        
                            "otherfilterarraycountry" : filterServiceOther?.other_country_filter,
                            "otherhandlercountry" : filterServiceOther?.other_country_handler,
                            "otherdatacountry" : filterServiceOther?.other_country_data,
                            "otherfiltercountry" : results_state?.otherCountry,
        
                            "otherfilterarraytopic" :filterServiceOther?.other_topic_filter,
                            "otherhandlertopic" : filterServiceOther?.other_topic_handler,
                            "otherdatatopic" : filterServiceOther?.other_topic_data,
                            "otherfiltertopic" : results_state?.otherTopic,
        
                            "otherfilterarraysector" : filterServiceOther?.other_sector_filter,
                            "otherhandlersector" : filterServiceOther?.other_sector_handler,
                            "otherdatasector" : filterServiceOther?.other_sector_data,
                            "otherfiltersector" : results_state?.otherSector,
        
                            "otherstartdate" : results_state?.otherSdate,
                            "otherenddate" : results_state?.otherEdate,
                            "otherSdate" : filterServiceOther?.other_start_date_handler,
                            "otherEdate" : filterServiceOther?.other_end_date_handler
                        }
                
                    }
                    if(token!==undefined){
                        dispatch(shareSingleQuery(data,token)).then(()=>{
                            if(!share_query_state_ref.current?.hasError){
                                querySharedWith()
                                setSelect([])
                                if(!errorShareQuery){
                                    setClicked(!clicked)
                                    setSelect([])
                                }
                            }else{
                                toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                            }
                        })
                    }else if(tokenSso!== undefined){
                        dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                            if(!share_query_state_ref.current?.hasError){
                                querySharedWith()
                                setSelect([])
                                if(!errorShareQuery){
                                    setClicked(!clicked)
                                    setSelect([])
                                }
                            }else{
                                toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                            }
                        })
                    }
                }
            }else if(props?.category==="data"){
                if(searchTerm!==""&&file===""){
                    const data = {
                        "title":title,
                        "query":{
                            "category":props?.category,
                            "qterm":searchTerm,
                            "org_name" : results_state?.dataSource,
                            "region" : results_state?.dataRegion,
                            "country" : results_state?.dataCountry,
                            "sector" : results_state?.dataSector,
                            "topic" : results_state?.dataTopic,
                            "resource" :results_state?.dataResource                   
                        },
                        "category" : props?.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "datafilterarrayregion" : filterServiceData?.data_region_filter,
                            "datahandlerregion" : filterServiceData?.data_region_handler,
                            "datadataregion" : filterServiceData?.data_region_data,
                            "datafilterregion": results_state?.dataRegion,
                            
                            "datafilterarrayorg" : filterServiceData?.data_org_filter,
                            "datahandlerorg" : filterServiceData?.data_org_handler,
                            "datadataorg" : filterServiceData?.data_org_data,
                            "datafilterorg" : results_state?.dataSource,
        
                            "datafilterarraycountry" : filterServiceData?.data_country_filter,
                            "datahandlercountry" : filterServiceData?.data_country_handler,
                            "datadatacountry" : filterServiceData?.data_country_data,
                            "datafiltercountry" : results_state?.dataCountry,
        
                            "datafilterarraytopic" :filterServiceData?.data_topic_filter,
                            "datahandlertopic" : filterServiceData?.data_topic_handler,
                            "datadatatopic" : filterServiceData?.data_topic_data,
                            "datafiltertopic" : results_state?.dataTopic,
        
                            "datafilterarraysector" : filterServiceData?.data_sector_filter,
                            "datahandlersector" : filterServiceData?.data_sector_handler,
                            "datadatasector" : filterServiceData?.data_sector_data,
                            "datafiltersector" : results_state?.dataSector,
        
                            "datafilterarrayresource" : filterServiceData?.data_resource_filter,
                            "datahandlerresource" : filterServiceData?.data_resource_handler,
                            "datadataresource" : filterServiceData?.data_resource_data,
                            "datafilterresource": results_state?.dataresource,
                        }
                
                    } 
                    if(token!==undefined){
                        dispatch(shareSingleQuery(data,token)).then(()=>{
                            if(!share_query_state_ref.current?.hasError){
                                querySharedWith()
                                setSelect([])
                                if(!errorShareQuery){
                                    setClicked(!clicked)
                                    setSelect([])
                                }
                            }else{
                                toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                            }
                        })
                    }else if(tokenSso!== undefined){
                        dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                            if(!share_query_state_ref.current?.hasError){
                                querySharedWith()
                                if(!errorShareQuery){
                                    setClicked(!clicked)
                                    setSelect([])
                                }
                            }else{
                                toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                            }
                        })
                    } 
                }else if(file!==""&&searchTerm===""){
                    const data = {
                        "title":title,
                        "query":{
                            "category":props?.category,
                            "qfile":file_path_data,
                            "org_name" : results_state?.dataSource,
                            "region" : results_state?.dataRegion,
                            "country" : results_state?.dataCountry,
                            "sector" : results_state?.dataSector,
                            "topic" : results_state?.dataTopic,
                            "resource" :results_state?.dataResource                   
                        },
                        "category" : props?.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "datafilterarrayregion" : filterServiceData?.data_region_filter,
                            "datahandlerregion" : filterServiceData?.data_region_handler,
                            "datadataregion" : filterServiceData?.data_region_data,
                            "datafilterregion": results_state?.dataRegion,
                            
                            "datafilterarrayorg" : filterServiceData?.data_org_filter,
                            "datahandlerorg" : filterServiceData?.data_org_handler,
                            "datadataorg" : filterServiceData?.data_org_data,
                            "datafilterorg" : results_state?.dataSource,
        
                            "datafilterarraycountry" : filterServiceData?.data_country_filter,
                            "datahandlercountry" : filterServiceData?.data_country_handler,
                            "datadatacountry" : filterServiceData?.data_country_data,
                            "datafiltercountry" : results_state?.dataCountry,
        
                            "datafilterarraytopic" :filterServiceData?.data_topic_filter,
                            "datahandlertopic" : filterServiceData?.data_topic_handler,
                            "datadatatopic" : filterServiceData?.data_topic_data,
                            "datafiltertopic" : results_state?.dataTopic,
        
                            "datafilterarraysector" : filterServiceData?.data_sector_filter,
                            "datahandlersector" : filterServiceData?.data_sector_handler,
                            "datadatasector" : filterServiceData?.data_sector_data,
                            "datafiltersector" : results_state?.dataSector,
        
                            "datafilterarrayresource" : filterServiceData?.data_resource_filter,
                            "datahandlerresource" : filterServiceData?.data_resource_handler,
                            "datadataresource" : filterServiceData?.data_resource_data,
                            "datafilterresource": results_state?.dataresource,
                        }
                
                    } 
                    if(token!==undefined){
                        dispatch(shareSingleQuery(data,token)).then(()=>{
                            if(!share_query_state_ref.current?.hasError){
                                querySharedWith()
                                setSelect([])
                                if(!errorShareQuery){
                                    setClicked(!clicked)
                                    setSelect([])
                                }
                            }else{
                                toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                            }
                        })
                    }else if(tokenSso!== undefined){
                        dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                            if(!share_query_state_ref.current?.hasError){
                                querySharedWith()
                                setSelect([])
                                if(!errorShareQuery){
                                    setClicked(!clicked)
                                    setSelect([])
                                }
                            }else{
                                toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                            }
                        })
                    } 
                }
            }else if(props?.category==="multimedia"){
                if(searchTerm!==""&&file===""){
                    const data = {
                                        "title":title,
                                        "query":{
                                            "category":props?.category,
                                            "qterm":searchTerm,
                                            "org_name" : results_state?.mulSource,
                                            "region" : results_state?.mulRegion,
                                            "country" : results_state?.mulCountry,
                                            "sector" : results_state?.mulSector,
                                            "topic" : results_state?.mulTopic,
                                            "start_date" : results_state?.mulSdate,
                                            "end_date" : results_state?.mulEdate,                 
                                        },
                                        "category" : props?.category,
                                        "users" : userValues,
                                        "filter_parameters" : {
                                            "mulfilterarrayregion" : filterServiceMul?.mul_region_filter,
                                            "mulhandlerregion" : filterServiceMul?.mul_region_handler,
                                            "muldataregion" : filterServiceMul?.mul_region_data,
                                            "mulfilterregion": results_state?.mulRegion,
                                            
                                            "mulfilterarrayorg" : filterServiceMul?.mul_org_filter,
                                            "mulhandlerorg" : filterServiceMul?.mul_org_handler,
                                            "muldataorg" : filterServiceMul?.mul_org_data,
                                            "mulfilterorg" : results_state?.mulSource,
                        
                                            "mulfilterarraycountry" : filterServiceMul?.mul_country_filter,
                                            "mulhandlercountry" : filterServiceMul?.mul_country_handler,
                                            "muldatacountry" : filterServiceMul?.mul_country_data,
                                            "mulfiltercountry" : results_state?.mulCountry,
                        
                                            "mulfilterarraytopic" :filterServiceMul?.mul_topic_filter,
                                            "mulhandlertopic" : filterServiceMul?.mul_topic_handler,
                                            "muldatatopic" : filterServiceMul?.mul_topic_data,
                                            "mulfiltertopic" : results_state?.mulTopic,
                        
                                            "mulfilterarraysector" : filterServiceMul?.mul_sector_filter,
                                            "mulhandlersector" : filterServiceMul?.mul_sector_handler,
                                            "muldatasector" : filterServiceMul?.mul_sector_data,
                                            "mulfiltersector" : results_state?.mulSector,
                        
                                            "mulstartdate" : results_state?.mulSdate,
                                            "mulenddate" : results_state?.mulEdate,
                                            "mulSdate" : filterServiceMul?.mul_start_date_handler,
                                            "mulEdate" : filterServiceMul?.mul_end_date_handler
                                        }
                                
                                    }  
                                    if(token!==undefined){
                                        dispatch(shareSingleQuery(data,token)).then(()=>{
                                            if(!share_query_state_ref.current?.hasError){
                                                querySharedWith()
                                                setSelect([])
                                                if(!errorShareQuery){
                                                    setClicked(!clicked)
                                                    setSelect([])
                                                }
                                            }else{
                                                toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                                            }
                                        })
                                    }else if(tokenSso!== undefined){
                                        dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                                            if(!share_query_state_ref.current?.hasError){
                                                querySharedWith()
                                                setSelect([])
                                                if(!errorShareQuery){
                                                    setClicked(!clicked)
                                                    setSelect([])
                                                }
                                            }else{
                                                toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                                            }
                                        })
                                    }                 
                }else if(file!==""&&searchTerm===""){
                    const data = {
                        "title":title,
                        "query":{
                            "category":props?.category,
                            "qfile":file_path_mul,
                            "org_name" : results_state?.mulSource,
                            "region" : results_state?.mulRegion,
                            "country" : results_state?.mulCountry,
                            "sector" : results_state?.mulSector,
                            "topic" : results_state?.mulTopic,
                            "start_date" : results_state?.mulSdate,
                            "end_date" : results_state?.mulEdate,                 
                        },
                        "category" : props?.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "mulfilterarrayregion" : filterServiceMul?.mul_region_filter,
                            "mulhandlerregion" : filterServiceMul?.mul_region_handler,
                            "muldataregion" : filterServiceMul?.mul_region_data,
                            "mulfilterregion": results_state?.mulRegion,
                            
                            "mulfilterarrayorg" : filterServiceMul?.mul_org_filter,
                            "mulhandlerorg" : filterServiceMul?.mul_org_handler,
                            "muldataorg" : filterServiceMul?.mul_org_data,
                            "mulfilterorg" : results_state?.mulSource,
        
                            "mulfilterarraycountry" : filterServiceMul?.mul_country_filter,
                            "mulhandlercountry" : filterServiceMul?.mul_country_handler,
                            "muldatacountry" : filterServiceMul?.mul_country_data,
                            "mulfiltercountry" : results_state?.mulCountry,
        
                            "mulfilterarraytopic" :filterServiceMul?.mul_topic_filter,
                            "mulhandlertopic" : filterServiceMul?.mul_topic_handler,
                            "muldatatopic" : filterServiceMul?.mul_topic_data,
                            "mulfiltertopic" : results_state?.mulTopic,
        
                            "mulfilterarraysector" : filterServiceMul?.mul_sector_filter,
                            "mulhandlersector" : filterServiceMul?.mul_sector_handler,
                            "muldatasector" : filterServiceMul?.mul_sector_data,
                            "mulfiltersector" : results_state?.mulSector,
        
                            "mulstartdate" : results_state?.mulSdate,
                            "mulenddate" : results_state?.mulEdate,
                            "mulSdate" : filterServiceMul?.mul_start_date_handler,
                            "mulEdate" : filterServiceMul?.mul_end_date_handler
                        }
                
                    }  
                    if(token!==undefined){
                        dispatch(shareSingleQuery(data,token)).then(()=>{
                            if(!share_query_state_ref.current?.hasError){
                                querySharedWith()
                                setSelect([])
                                if(!errorShareQuery){
                                    setClicked(!clicked)
                                    setSelect([])
                                }
                            }else{
                                toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                            }
                        })
                    }else if(tokenSso!== undefined){
                        dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                            if(!share_query_state_ref.current?.hasError){
                                querySharedWith()
                                setSelect([])
                                if(!errorShareQuery){
                                    setClicked(!clicked)
                                    setSelect([])
                                }
                            }else{
                                toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                            }
                        })
                    } 
                }
            }else if(props?.category==="evaluations"){
                if(searchTerm!==""&&file===""){
                     const data = {
                    "title":title,
                    "query":{
                        "category":props?.category,
                        "qterm":searchTerm,
                        "org_name" : results_state?.evalSource,
                        "region" : results_state?.evalRegion,
                        "country" : results_state?.evalCountry,
                        "sector" : results_state?.evalSector,
                        "topic" : results_state?.evalTopic,
                        "start_date" : results_state?.evalSdate,
                        "end_date" : results_state?.evalEdate,                  
                    },
                    "category" : props?.category,
                    "users" : userValues,
                    "filter_parameters" : {
                        "evalfilterarrayregion" : filterServiceEval?.eval_region_filter,
                        "evalhandlerregion" : filterServiceEval?.eval_region_handler,
                        "evaldataregion" : filterServiceEval?.eval_region_data,
                        "evalfilterregion": results_state?.evalRegion,
                        
                        "evalfilterarrayorg" : filterServiceEval?.eval_org_filter,
                        "evalhandlerorg" : filterServiceEval?.eval_org_handler,
                        "evaldataorg" : filterServiceEval?.eval_org_data,
                        "evalfilterorg" : results_state?.evalSource,
        
                        "evalfilterarraycountry" : filterServiceEval?.eval_country_filter,
                        "evalhandlercountry" : filterServiceEval?.eval_country_handler,
                        "evaldatacountry" : filterServiceEval?.eval_country_data,
                        "evalfiltercountry" : results_state?.evalCountry,
        
                        "evalfilterarraytopic" :filterServiceEval?.eval_topic_filter,
                        "evalhandlertopic" : filterServiceEval?.eval_topic_handler,
                        "evaldatatopic" : filterServiceEval?.eval_topic_data,
                        "evalfiltertopic" : results_state?.evalTopic,
        
                        "evalfilterarraysector" : filterServiceEval?.eval_sector_filter,
                        "evalhandlersector" : filterServiceEval?.eval_sector_handler,
                        "evaldatasector" : filterServiceEval?.eval_sector_data,
                        "evalfiltersector" : results_state?.evalSector,
        
                        "evalstartdate" : results_state?.evalSdate,
                        "evalenddate" : results_state?.evalEdate,
                        "evalSdate" : filterServiceEval?.eval_start_date_handler,
                        "evalEdate" : filterServiceEval?.eval_end_date_handler
                    }
            
                }  
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token)).then(()=>{
                        if(!share_query_state_ref.current?.hasError){
                            querySharedWith()
                            setSelect([])
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        }else{
                            toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                        }
                    })
                }else if(tokenSso!== undefined){
                    dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                        if(!share_query_state_ref.current?.hasError){
                            querySharedWith()
                            setSelect([])
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        }else{
                            toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                        }
                    })
                }
                }else if(file!==""&&searchTerm===""){
                    const data = {
                        "title":title,
                        "query":{
                            "category":props?.category,
                            "qfile":file_path_eval,
                            "org_name" : results_state?.evalSource,
                            "region" : results_state?.evalRegion,
                            "country" : results_state?.evalCountry,
                            "sector" : results_state?.evalSector,
                            "topic" : results_state?.evalTopic,
                            "start_date" : results_state?.evalSdate,
                            "end_date" : results_state?.evalEdate,                  
                        },
                        "category" : props?.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "evalfilterarrayregion" : filterServiceEval?.eval_region_filter,
                            "evalhandlerregion" : filterServiceEval?.eval_region_handler,
                            "evaldataregion" : filterServiceEval?.eval_region_data,
                            "evalfilterregion": results_state?.evalRegion,
                            
                            "evalfilterarrayorg" : filterServiceEval?.eval_org_filter,
                            "evalhandlerorg" : filterServiceEval?.eval_org_handler,
                            "evaldataorg" : filterServiceEval?.eval_org_data,
                            "evalfilterorg" : results_state?.evalSource,
            
                            "evalfilterarraycountry" : filterServiceEval?.eval_country_filter,
                            "evalhandlercountry" : filterServiceEval?.eval_country_handler,
                            "evaldatacountry" : filterServiceEval?.eval_country_data,
                            "evalfiltercountry" : results_state?.evalCountry,
            
                            "evalfilterarraytopic" :filterServiceEval?.eval_topic_filter,
                            "evalhandlertopic" : filterServiceEval?.eval_topic_handler,
                            "evaldatatopic" : filterServiceEval?.eval_topic_data,
                            "evalfiltertopic" : results_state?.evalTopic,
            
                            "evalfilterarraysector" : filterServiceEval?.eval_sector_filter,
                            "evalhandlersector" : filterServiceEval?.eval_sector_handler,
                            "evaldatasector" : filterServiceEval?.eval_sector_data,
                            "evalfiltersector" : results_state?.evalSector,
            
                            "evalstartdate" : results_state?.evalSdate,
                            "evalenddate" : results_state?.evalEdate,
                            "evalSdate" : filterServiceEval?.eval_start_date_handler,
                            "evalEdate" : filterServiceEval?.eval_end_date_handler
                        }
                
                    }  
                    if(token!==undefined){
                        dispatch(shareSingleQuery(data,token)).then(()=>{
                            if(!share_query_state_ref.current?.hasError){
                                querySharedWith()
                                setSelect([])
                                if(!errorShareQuery){
                                    setClicked(!clicked)
                                    setSelect([])
                                }
                            }else{
                                toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                            }
                        })
                    }else if(tokenSso!== undefined){
                        dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                            if(!share_query_state_ref.current?.hasError){
                                querySharedWith()
                                setSelect([])
                                if(!errorShareQuery){
                                    setClicked(!clicked)
                                    setSelect([])
                                }
                            }else{
                                toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                            }
                        })
                    }
                }
            }else if(props?.category==="experts"){
                if(searchTerm!==""&&file===""){
                const data = {
                    "title":title,
                    "query":{
                        "category":props?.category,
                        "qterm":searchTerm,
                        "org_name" : results_state?.source,
                        "region" : results_state?.region,
                        "country" : results_state?.country,
                        "sector" : results_state?.sector,
                        "topic" : results_state?.topic,
                        "ignore_query" : filterServiceExperts?.experts_ignore_query
                    },
                    "category" :props?.category,
                    "users" : userValues,
                    "filter_parameters" : {
                        "expertsfilterarrayregion" : filterServiceExperts?.experts_region_filter,
                        "expertshandlerregion" : filterServiceExperts?.experts_region_handler,
                        "expertsdataregion" : filterServiceExperts?.experts_region_data,
                        "expertsfilterregion": results_state?.region,
                        
                        "expertsfilterarrayorg" : filterServiceExperts?.experts_org_filter,
                        "expertshandlerorg" : filterServiceExperts?.experts_org_handler,
                        "expertsdataorg" : filterServiceExperts?.experts_org_data,
                        "expertsfilterorg" : results_state?.source,
        
                        "expertsfilterarraycountry" : filterServiceExperts?.experts_country_filter,
                        "expertshandlercountry" : filterServiceExperts?.experts_country_handler,
                        "expertsdatacountry" : filterServiceExperts?.experts_country_data,
                        "expertsfiltercountry" : results_state?.country,
        
                        "expertsfilterarraytopic" :filterServiceExperts?.experts_topic_filter,
                        "expertshandlertopic" : filterServiceExperts?.experts_topic_handler,
                        "expertsdatatopic" : filterServiceExperts?.experts_topic_data,
                        "expertsfiltertopic" : results_state?.topic,
        
                        "expertsfilterarraysector" : filterServiceExperts?.experts_sector_filter,
                        "expertshandlersector" : filterServiceExperts?.experts_sector_handler,
                        "expertsdatasector" : filterServiceExperts?.experts_sector_data,
                        "expertsfiltersector" : results_state?.sector,  
                        
                        "ignore_query" : filterServiceExperts?.experts_ignore_query
                    }
            
                }  
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token)).then(()=>{
                        if(!share_query_state_ref.current?.hasError){
                            querySharedWith()
                            setSelect([])
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        }else{
                            toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                        }
                    })
                }else if(tokenSso!== undefined){
                    dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                        if(!share_query_state_ref.current?.hasError){
                            querySharedWith()
                            setSelect([])
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        }else{
                            toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                        }
                    })
                }
            }else if(file!==""&&searchTerm===""){
                const data = {
                    "title":title,
                    "query":{
                        "category":props?.category,
                        "qfile":file_path_experts,
                        "org_name" : results_state?.source,
                        "region" : results_state?.region,
                        "country" : results_state?.country,
                        "sector" : results_state?.sector,
                        "topic" : results_state?.topic,    
                        "ignore_query" : filterServiceExperts?.experts_ignore_query          
                    },
                    "category" : props?.category,
                    "users" : userValues,
                    "filter_parameters" : {
                        "expertsfilterarrayregion" : filterServiceExperts?.experts_region_filter,
                        "expertshandlerregion" : filterServiceExperts?.experts_region_handler,
                        "expertsdataregion" : filterServiceExperts?.experts_region_data,
                        "expertsfilterregion": results_state?.region,
                        
                        "expertsfilterarrayorg" : filterServiceExperts?.experts_org_filter,
                        "expertshandlerorg" : filterServiceExperts?.experts_org_handler,
                        "expertsdataorg" : filterServiceExperts?.experts_org_data,
                        "expertsfilterorg" : results_state?.source,
        
                        "expertsfilterarraycountry" : filterServiceExperts?.experts_country_filter,
                        "expertshandlercountry" : filterServiceExperts?.experts_country_handler,
                        "expertsdatacountry" : filterServiceExperts?.experts_country_data,
                        "expertsfiltercountry" : results_state?.country,
        
                        "expertsfilterarraytopic" :filterServiceExperts?.experts_topic_filter,
                        "expertshandlertopic" : filterServiceExperts?.experts_topic_handler,
                        "expertsdatatopic" : filterServiceExperts?.experts_topic_data,
                        "expertsfiltertopic" : results_state?.topic,
        
                        "expertsfilterarraysector" : filterServiceExperts?.experts_sector_filter,
                        "expertshandlersector" : filterServiceExperts?.experts_sector_handler,
                        "expertsdatasector" : filterServiceExperts?.experts_sector_data,
                        "expertsfiltersector" : results_state?.sector,
        
                        "ignore_query" : filterServiceExperts?.experts_ignore_query
                    }
            
                }  
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token)).then(()=>{
                        if(!share_query_state_ref.current?.hasError){
                            querySharedWith()
                            setSelect([])
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        }else{
                            toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                        }
                    })
                }else if(tokenSso!== undefined){
                    dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                        if(!share_query_state_ref.current?.hasError){
                            querySharedWith()
                            setSelect([])
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        }else{
                            toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                        }
                    })
                }
                }
            }else if(props?.category==="econpubs"){
                if(searchTerm!==""&&file===""){
                const data = {
                    "title":title,
                    "query":{
                        "category":props?.category,
                        "qterm":searchTerm,
                        "org_name" : results_state?.econSource,
                        "region" : results_state?.econRegion,
                        "country" : results_state?.econCountry,
                        "sector" : results_state?.econSector,
                        "topic" : results_state?.econTopic,
                        "ignore_query" : filterServiceEcon?.econ_ignore_query,
                        "start_date" : results_state?.econSdate,
                        "end_date" : results_state?.econEdate, 
                    },
                    "category" : props?.category,
                    "users" : userValues,
                    "filter_parameters" : {
                        "econfilterarrayregion" : filterServiceEcon?.econ_region_filter,
                        "econhandlerregion" : filterServiceEcon?.econ_region_handler,
                        "econdataregion" : filterServiceEcon?.econ_region_data,
                        "econfilterregion": results_state?.econRegion,
                        
                        "econfilterarrayorg" : filterServiceEcon?.econ_org_filter,
                        "econhandlerorg" : filterServiceEcon?.econ_org_handler,
                        "econdataorg" : filterServiceEcon?.econ_org_data,
                        "econfilterorg" : results_state?.econSource,
        
                        "econfilterarraycountry" : filterServiceEcon?.econ_country_filter,
                        "econhandlercountry" : filterServiceEcon?.econ_country_handler,
                        "econdatacountry" : filterServiceEcon?.econ_country_data,
                        "econfiltercountry" : results_state?.econCountry,
        
                        "econfilterarraytopic" :filterServiceEcon?.econ_topic_filter,
                        "econhandlertopic" : filterServiceEcon?.econ_topic_handler,
                        "econdatatopic" : filterServiceEcon?.econ_topic_data,
                        "econfiltertopic" : results_state?.econTopic,
        
                        "econfilterarraysector" : filterServiceEcon?.econ_sector_filter,
                        "econhandlersector" : filterServiceEcon?.econ_sector_handler,
                        "econdatasector" : filterServiceEcon?.econ_sector_data,
                        "econfiltersector" : results_state?.econSector,  
                        
                        "ignore_query" : filterServiceEcon?.econ_ignore_query,
                        "econinitialcountry":filterServiceEcon?.econ_filter_popup_countries,
                        "start_date" : results_state?.econSdate,
                        "end_date" : results_state?.econEdate,  
                        "econSdate" : filterServiceEcon?.econ_start_date_handler,
                        "econEdate" : filterServiceEcon?.econ_end_date_handler 
                    }
            
                }  
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token)).then(()=>{
                        if(!share_query_state_ref.current?.hasError){
                            querySharedWith()
                            setSelect([])
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        }else{
                            toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                        }
                    })
                }else if(tokenSso!== undefined){
                    dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                        if(!share_query_state_ref.current?.hasError){
                            querySharedWith()
                            setSelect([])
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        }else{
                            toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                        }
                    })
                }
                }else if(file!==""&&searchTerm===""){
                    const data = {
                        "title":title,
                        "query":{
                            "category":props?.category,
                            "qfile":file_path_econ,
                            "org_name" : results_state?.econSource,
                            "region" : results_state?.econRegion,
                            "country" : results_state?.econCountry,
                            "sector" : results_state?.econSector,
                            "topic" : results_state?.econTopic,
                            "ignore_query" : filterServiceEcon?.econ_ignore_query,
                            "start_date" : results_state?.econSdate,
                            "end_date" : results_state?.econEdate, 
                        },
                        "category" : props?.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "econfilterarrayregion" : filterServiceEcon?.econ_region_filter,
                            "econhandlerregion" : filterServiceEcon?.econ_region_handler,
                            "econdataregion" : filterServiceEcon?.econ_region_data,
                            "econfilterregion": results_state?.econRegion,
                            
                            "econfilterarrayorg" : filterServiceEcon?.econ_org_filter,
                            "econhandlerorg" : filterServiceEcon?.econ_org_handler,
                            "econdataorg" : filterServiceEcon?.econ_org_data,
                            "econfilterorg" : results_state?.econSource,
            
                            "econfilterarraycountry" : filterServiceEcon?.econ_country_filter,
                            "econhandlercountry" : filterServiceEcon?.econ_country_handler,
                            "econdatacountry" : filterServiceEcon?.econ_country_data,
                            "econfiltercountry" : results_state?.econCountry,
            
                            "econfilterarraytopic" :filterServiceEcon?.econ_topic_filter,
                            "econhandlertopic" : filterServiceEcon?.econ_topic_handler,
                            "econdatatopic" : filterServiceEcon?.econ_topic_data,
                            "econfiltertopic" : results_state?.econTopic,
            
                            "econfilterarraysector" : filterServiceEcon?.econ_sector_filter,
                            "econhandlersector" : filterServiceEcon?.econ_sector_handler,
                            "econdatasector" : filterServiceEcon?.econ_sector_data,
                            "econfiltersector" : results_state?.econSector,  
                            
                            "ignore_query" : filterServiceEcon?.econ_ignore_query,
                            "econinitialcountry":filterServiceEcon?.econ_filter_popup_countries,
                            "start_date" : results_state?.econSdate,
                            "end_date" : results_state?.econEdate,   
                            "econSdate" : filterServiceEcon?.econ_start_date_handler,
                            "econEdate" : filterServiceEcon?.econ_end_date_handler 
                        }
                
                    }  
                    if(token!==undefined){
                        dispatch(shareSingleQuery(data,token)).then(()=>{
                            if(!share_query_state_ref.current?.hasError){
                                querySharedWith()
                                setSelect([])
                                if(!errorShareQuery){
                                    setClicked(!clicked)
                                    setSelect([])
                                }
                            }else{
                                toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                            }
                        })
                    }else if(tokenSso!== undefined){
                        dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                            if(!share_query_state_ref.current?.hasError){
                                querySharedWith()
                                setSelect([])
                                if(!errorShareQuery){
                                    setClicked(!clicked)
                                    setSelect([])
                                }
                            }else{
                                toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                            }
                        })
                    } 
                }
            }else if(props?.category==="news"){
                if(searchTerm!==""&&file===""){
                    const data = {
                    "title":title,
                    "query":{
                        "category":props?.category,
                        "qterm":searchTerm,
                    },
                    "category" : props?.category,   
                    "users" : userValues, 
                } 
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token)).then(()=>{
                        if(!share_query_state_ref.current?.hasError){
                            querySharedWith()
                            setSelect([])
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        }else{
                            toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                        }
                    })
                }else if(tokenSso!== undefined){
                    dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                        if(!share_query_state_ref.current?.hasError){
                            querySharedWith()
                            setSelect([])
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        }else{
                            toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                        }
                    })
                }
                }else if(file!==""&&searchTerm===""){
                    const data = {
                        "title":title,
                        "query":{
                            "category":props?.category,
                            "qfile":file_path_news
                        },
                        "category" : props?.category,   
                        "users" : userValues, 
                    } 
                    if(token!==undefined){
                        dispatch(shareSingleQuery(data,token)).then(()=>{
                            if(!share_query_state_ref.current?.hasError){
                                querySharedWith()
                                setSelect([])
                                if(!errorShareQuery){
                                    setClicked(!clicked)
                                    setSelect([])
                                }
                            }else{
                                toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                            }
                        })
                    }else if(tokenSso!== undefined){
                        dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                            if(!share_query_state_ref.current?.hasError){
                                querySharedWith()
                                setSelect([])
                                if(!errorShareQuery){
                                    setClicked(!clicked)
                                    setSelect([])
                                }
                            }else{
                                toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                            }
                        })
                    }
                }
            }                  
        }else if(sharedListMessage==="Query not shared to anyone"){
            if(props?.category==="financing"){
                if(searchTerm!==""&&file===""){
                    const data = {
                        "title":queryName,
                        "query":{
                            "category":props.category,
                            "qterm":searchTerm,
                            "org_name" : results_state?.financeSource,
                            "region" : results_state?.financeRegion,
                            "country" : results_state?.financeCountry,
                            "sector" : results_state?.financeSector,
                            "topic" : results_state?.financeTopic,
                            "start_date" : results_state?.financeSdate,
                            "end_date" : results_state?.financeEdate,  
                        },
                        "category":props.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "financefilterarrayregion" : filterService?.finance_region_filter,
                            "financehandlerregion" : filterService?.finance_region_handler,
                            "financedataregion" : filterService?.finance_region_data,
                            "financefilterregion": results_state?.financeRegion,
                            
                            "financefilterarrayorg" : filterService?.finance_org_filter,
                            "financehandlerorg" : filterService?.finanace_org_handler,
                            "financedataorg" : filterService?.finance_org_data,
                            "financefilterorg" : results_state?.financeSource,
        
                            "financefilterarraycountry" : filterService?.finance_country_filter,
                            "financehandlercountry" : filterService?.finance_country_handler,
                            "financedatacountry" : filterService?.finance_country_data,
                            "financefiltercountry" : results_state?.financeCountry,
        
                            "financefilterarraytopic" :filterService?.finance_topic_filter,
                            "financehandlertopic" : filterService?.finance_topic_handler,
                            "financedatatopic" : filterService?.finance_topic_data,
                            "financefiltertopic" : results_state?.financeTopic,
        
                            "financefilterarraysector" : filterService?.finance_sector_filter,
                            "financehandlersector" : filterService?.finance_sector_handler,
                            "financedatasector" : filterService?.finance_sector_data,
                            "financefiltersector" : results_state?.financeSector,
        
                            "financestartdate" : results_state?.financeSdate,
                            "financeenddate" : results_state?.financeEdate,
                            "financeSdate" : filterService?.finance_start_date_handler,
                            "finaceEdate" : filterService?.finance_end_date_handler 
                        }
                        
                
                    }  
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token)).then(()=>{
                        querySharedWith()
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }else if(tokenSso!== undefined){
                    dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                        querySharedWith()
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }
                }else if(file!==""&&searchTerm===""){
                    const data = {
                        "title":queryName,
                        "query":{
                            "category":props.category,
                            "qfile":file_path_fin,
                            "org_name" : results_state?.financeSource,
                            "region" : results_state?.financeRegion,
                            "country" : results_state?.financeCountry,
                            "sector" : results_state?.financeSector,
                            "topic" : results_state?.financeTopic,
                            "start_date" : results_state?.financeSdate,
                            "end_date" : results_state?.financeEdate,  
                        },
                        "category":props.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "financefilterarrayregion" : filterService?.finance_region_filter,
                            "financehandlerregion" : filterService?.finance_region_handler,
                            "financedataregion" : filterService?.finance_region_data,
                            "financefilterregion": results_state?.financeRegion,
                            
                            "financefilterarrayorg" : filterService?.finance_org_filter,
                            "financehandlerorg" : filterService?.finanace_org_handler,
                            "financedataorg" : filterService?.finance_org_data,
                            "financefilterorg" : results_state?.financeSource,
        
                            "financefilterarraycountry" : filterService?.finance_country_filter,
                            "financehandlercountry" : filterService?.finance_country_handler,
                            "financedatacountry" : filterService?.finance_country_data,
                            "financefiltercountry" : results_state?.financeCountry,
        
                            "financefilterarraytopic" :filterService?.finance_topic_filter,
                            "financehandlertopic" : filterService?.finance_topic_handler,
                            "financedatatopic" : filterService?.finance_topic_data,
                            "financefiltertopic" : results_state?.financeTopic,
        
                            "financefilterarraysector" : filterService?.finance_sector_filter,
                            "financehandlersector" : filterService?.finance_sector_handler,
                            "financedatasector" : filterService?.finance_sector_data,
                            "financefiltersector" : results_state?.financeSector,
        
                            "financestartdate" : results_state?.financeSdate,
                            "financeenddate" : results_state?.financeEdate,
                            "financeSdate" : filterService?.finance_start_date_handler,
                            "finaceEdate" : filterService?.finance_end_date_handler 
                        }
                
                    }  
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token)).then(()=>{
                        querySharedWith()
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }else if(tokenSso!== undefined){
                    dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                        querySharedWith()
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }
                }
                
                
            }else if(props?.category==="devpubs"){
                if(searchTerm!==""&&file===""){
                    const data = {
                        "title":queryName,
                        "query":{
                            "category":props.category,
                            "qterm":searchTerm,
                            "org_name" : results_state?.devSource,
                            "region" : results_state?.devRegion,
                            "country" : results_state?.devCountry,
                            "sector" : results_state?.devSector,
                            "topic" : results_state?.devTopic,
                            "start_date" : results_state?.devSdate,
                            "end_date" : results_state?.devEdate,    
                        },
                        "category":props.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "devfilterarrayregion" : filterServiceDev?.dev_region_filter,
                            "devhandlerregion" : filterServiceDev?.dev_region_handler,
                            "devdataregion" : filterServiceDev?.dev_region_data,
                            "devfilterregion": results_state?.devRegion,
                            
                            "devfilterarrayorg" : filterServiceDev?.dev_org_filter,
                            "devhandlerorg" : filterServiceDev?.dev_org_handler,
                            "devdataorg" : filterServiceDev?.dev_org_data,
                            "devfilterorg" : results_state?.devSource,
        
                            "devfilterarraycountry" : filterServiceDev?.dev_country_filter,
                            "devhandlercountry" : filterServiceDev?.dev_country_handler,
                            "devdatacountry" : filterServiceDev?.dev_country_data,
                            "devfiltercountry" : results_state?.devCountry,
        
                            "devfilterarraytopic" :filterServiceDev?.dev_topic_filter,
                            "devhandlertopic" : filterServiceDev?.dev_topic_handler,
                            "devdatatopic" : filterServiceDev?.dev_topic_data,
                            "devfiltertopic" : results_state?.devTopic,
        
                            "devfilterarraysector" : filterServiceDev?.dev_sector_filter,
                            "devhandlersector" : filterServiceDev?.dev_sector_handler,
                            "devdatasector" : filterServiceDev?.dev_sector_data,
                            "devfiltersector" : results_state?.devSector,
        
                            "devstartdate" : results_state?.devSdate,
                            "devenddate" : results_state?.devEdate,
                            "devSdate" : filterServiceDev?.dev_start_date_handler,
                            "devEdate" : filterServiceDev?.dev_end_date_handler 
                        }
                
                    }  
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token)).then(()=>{
                        querySharedWith()
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }else if(tokenSso!== undefined){
                    dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                        querySharedWith()
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }
                } else if(file!==""&&searchTerm===""){
                    const data = {
                        "title":queryName,
                        "query":{
                            "category":props.category,
                            "qfile":file_path_dev,
                            "org_name" : results_state?.devSource,
                            "region" : results_state?.devRegion,
                            "country" : results_state?.devCountry,
                            "sector" : results_state?.devSector,
                            "topic" : results_state?.devTopic,
                            "start_date" : results_state?.devSdate,
                            "end_date" : results_state?.devEdate,    
                        },
                        "category":props.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "devfilterarrayregion" : filterServiceDev?.dev_region_filter,
                            "devhandlerregion" : filterServiceDev?.dev_region_handler,
                            "devdataregion" : filterServiceDev?.dev_region_data,
                            "devfilterregion": results_state?.devRegion,
                            
                            "devfilterarrayorg" : filterServiceDev?.dev_org_filter,
                            "devhandlerorg" : filterServiceDev?.dev_org_handler,
                            "devdataorg" : filterServiceDev?.dev_org_data,
                            "devfilterorg" : results_state?.devSource,
        
                            "devfilterarraycountry" : filterServiceDev?.dev_country_filter,
                            "devhandlercountry" : filterServiceDev?.dev_country_handler,
                            "devdatacountry" : filterServiceDev?.dev_country_data,
                            "devfiltercountry" : results_state?.devCountry,
        
                            "devfilterarraytopic" :filterServiceDev?.dev_topic_filter,
                            "devhandlertopic" : filterServiceDev?.dev_topic_handler,
                            "devdatatopic" : filterServiceDev?.dev_topic_data,
                            "devfiltertopic" : results_state?.devTopic,
        
                            "devfilterarraysector" : filterServiceDev?.dev_sector_filter,
                            "devhandlersector" : filterServiceDev?.dev_sector_handler,
                            "devdatasector" : filterServiceDev?.dev_sector_data,
                            "devfiltersector" : results_state?.devSector,
        
                            "devstartdate" : results_state?.devSdate,
                            "devenddate" : results_state?.devEdate,
                            "devSdate" : filterServiceDev?.dev_start_date_handler,
                            "devEdate" : filterServiceDev?.dev_end_date_handler 

                        }
                
                    }  
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token)).then(()=>{
                        querySharedWith()
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }else if(tokenSso!== undefined){
                    dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                        querySharedWith()
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }
                }
            }else if(props?.category==="otherpubs"){
                if(searchTerm!==""&&file===""){
                    const data = {
                        "title":queryName,
                        "query":{
                            "category":props.category,
                            "qterm":searchTerm,
                            "org_name" : results_state?.otherSource,
                            "region" : results_state?.otherRegion,
                            "country" : results_state?.otherCountry,
                            "sector" : results_state?.otherSector,
                            "topic" : results_state?.otherTopic,
                            "start_date" : results_state?.otherSdate,
                            "end_date" : results_state?.otherEdate,  
                        },
                        "category":props.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "otherfilterarrayregion" : filterServiceOther?.other_region_filter,
                            "otherhandlerregion" : filterServiceOther?.other_region_handler,
                            "otherdataregion" : filterServiceOther?.other_region_data,
                            "otherfilterregion": results_state?.otherRegion,
                            
                            "otherfilterarrayorg" : filterServiceOther?.other_org_filter,
                            "otherhandlerorg" : filterServiceOther?.other_org_handler,
                            "otherdataorg" : filterServiceOther?.other_org_data,
                            "otherfilterorg" : results_state?.otherSource,
        
                            "otherfilterarraycountry" : filterServiceOther?.other_country_filter,
                            "otherhandlercountry" : filterServiceOther?.other_country_handler,
                            "otherdatacountry" : filterServiceOther?.other_country_data,
                            "otherfiltercountry" : results_state?.otherCountry,
        
                            "otherfilterarraytopic" :filterServiceOther?.other_topic_filter,
                            "otherhandlertopic" : filterServiceOther?.other_topic_handler,
                            "otherdatatopic" : filterServiceOther?.other_topic_data,
                            "otherfiltertopic" : results_state?.otherTopic,
        
                            "otherfilterarraysector" : filterServiceOther?.other_sector_filter,
                            "otherhandlersector" : filterServiceOther?.other_sector_handler,
                            "otherdatasector" : filterServiceOther?.other_sector_data,
                            "otherfiltersector" : results_state?.otherSector,
        
                            "otherstartdate" : results_state?.otherSdate,
                            "otherenddate" : results_state?.otherEdate,
                            "otherSdate" : filterServiceOther?.other_start_date_handler,
                            "otherEdate" : filterServiceOther?.other_end_date_handler 
                        }
                
                    }  
                    if(token!==undefined){
                        dispatch(shareSingleQuery(data,token)).then(()=>{
                            querySharedWith()
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        })
                    }else if(tokenSso!== undefined){
                        dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                            querySharedWith()
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        })
                    }
                }else if(file!==""&&searchTerm===""){
                    const data = {
                        "title":queryName,
                        "query":{
                            "category":props.category,
                            "qterm":searchTerm,
                            "org_name" : results_state?.otherSource,
                            "region" : results_state?.otherRegion,
                            "country" : results_state?.otherCountry,
                            "sector" : results_state?.otherSector,
                            "topic" : results_state?.otherTopic,
                            "start_date" : results_state?.otherSdate,
                            "end_date" : results_state?.otherEdate,  
                        },
                        "category":props.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "otherfilterarrayregion" : filterServiceOther?.other_region_filter,
                            "otherhandlerregion" : filterServiceOther?.other_region_handler,
                            "otherdataregion" : filterServiceOther?.other_region_data,
                            "otherfilterregion": results_state?.otherRegion,
                            
                            "otherfilterarrayorg" : filterServiceOther?.other_org_filter,
                            "otherhandlerorg" : filterServiceOther?.other_org_handler,
                            "otherdataorg" : filterServiceOther?.other_org_data,
                            "otherfilterorg" : results_state?.otherSource,
        
                            "otherfilterarraycountry" : filterServiceOther?.other_country_filter,
                            "otherhandlercountry" : filterServiceOther?.other_country_handler,
                            "otherdatacountry" : filterServiceOther?.other_country_data,
                            "otherfiltercountry" : results_state?.otherCountry,
        
                            "otherfilterarraytopic" :filterServiceOther?.other_topic_filter,
                            "otherhandlertopic" : filterServiceOther?.other_topic_handler,
                            "otherdatatopic" : filterServiceOther?.other_topic_data,
                            "otherfiltertopic" : results_state?.otherTopic,
        
                            "otherfilterarraysector" : filterServiceOther?.other_sector_filter,
                            "otherhandlersector" : filterServiceOther?.other_sector_handler,
                            "otherdatasector" : filterServiceOther?.other_sector_data,
                            "otherfiltersector" : results_state?.otherSector,
        
                            "otherstartdate" : results_state?.otherSdate,
                            "otherenddate" : results_state?.otherEdate,
                            "otherSdate" : filterServiceOther?.other_start_date_handler,
                            "otherEdate" : filterServiceOther?.other_end_date_handler
                        }
                
                    }
                    if(token!==undefined){
                        dispatch(shareSingleQuery(data,token)).then(()=>{
                            querySharedWith()
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        })
                    }else if(tokenSso!== undefined){
                        dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                            querySharedWith()
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        })
                    }
                }
            }else if(props?.category==="data"){
                if(searchTerm!==""&&file===""){
                    const data = {
                        "title":queryName,
                        "query":{
                            "category":props?.category,
                            "qterm":searchTerm,
                            "org_name" : results_state?.dataSource,
                            "region" : results_state?.dataRegion,
                            "country" : results_state?.dataCountry,
                            "sector" : results_state?.dataSector,
                            "topic" : results_state?.dataTopic,
                            "resource" :results_state?.dataResource                   
                        },
                        "category" : props?.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "datafilterarrayregion" : filterServiceData?.data_region_filter,
                            "datahandlerregion" : filterServiceData?.data_region_handler,
                            "datadataregion" : filterServiceData?.data_region_data,
                            "datafilterregion": results_state?.dataRegion,
                            
                            "datafilterarrayorg" : filterServiceData?.data_org_filter,
                            "datahandlerorg" : filterServiceData?.data_org_handler,
                            "datadataorg" : filterServiceData?.data_org_data,
                            "datafilterorg" : results_state?.dataSource,
        
                            "datafilterarraycountry" : filterServiceData?.data_country_filter,
                            "datahandlercountry" : filterServiceData?.data_country_handler,
                            "datadatacountry" : filterServiceData?.data_country_data,
                            "datafiltercountry" : results_state?.dataCountry,
        
                            "datafilterarraytopic" :filterServiceData?.data_topic_filter,
                            "datahandlertopic" : filterServiceData?.data_topic_handler,
                            "datadatatopic" : filterServiceData?.data_topic_data,
                            "datafiltertopic" : results_state?.dataTopic,
        
                            "datafilterarraysector" : filterServiceData?.data_sector_filter,
                            "datahandlersector" : filterServiceData?.data_sector_handler,
                            "datadatasector" : filterServiceData?.data_sector_data,
                            "datafiltersector" : results_state?.dataSector,
        
                            "datafilterarrayresource" : filterServiceData?.data_resource_filter,
                            "datahandlerresource" : filterServiceData?.data_resource_handler,
                            "datadataresource" : filterServiceData?.data_resource_data,
                            "datafilterresource": results_state?.dataresource,
                        }
                
                    } 
                    if(token!==undefined){
                        dispatch(shareSingleQuery(data,token)).then(()=>{
                            querySharedWith()
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        })
                    }else if(tokenSso!== undefined){
                        dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                            querySharedWith()
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        })
                    } 
                }else if(file!==""&&searchTerm===""){
                    const data = {
                        "title":queryName,
                        "query":{
                            "category":props?.category,
                            "qfile":file_path_other,
                            "org_name" : results_state?.dataSource,
                            "region" : results_state?.dataRegion,
                            "country" : results_state?.dataCountry,
                            "sector" : results_state?.dataSector,
                            "topic" : results_state?.dataTopic,
                            "resource" :results_state?.dataResource                   
                        },
                        "category" : props?.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "datafilterarrayregion" : filterServiceData?.data_region_filter,
                            "datahandlerregion" : filterServiceData?.data_region_handler,
                            "datadataregion" : filterServiceData?.data_region_data,
                            "datafilterregion": results_state?.dataRegion,
                            
                            "datafilterarrayorg" : filterServiceData?.data_org_filter,
                            "datahandlerorg" : filterServiceData?.data_org_handler,
                            "datadataorg" : filterServiceData?.data_org_data,
                            "datafilterorg" : results_state?.dataSource,
        
                            "datafilterarraycountry" : filterServiceData?.data_country_filter,
                            "datahandlercountry" : filterServiceData?.data_country_handler,
                            "datadatacountry" : filterServiceData?.data_country_data,
                            "datafiltercountry" : results_state?.dataCountry,
        
                            "datafilterarraytopic" :filterServiceData?.data_topic_filter,
                            "datahandlertopic" : filterServiceData?.data_topic_handler,
                            "datadatatopic" : filterServiceData?.data_topic_data,
                            "datafiltertopic" : results_state?.dataTopic,
        
                            "datafilterarraysector" : filterServiceData?.data_sector_filter,
                            "datahandlersector" : filterServiceData?.data_sector_handler,
                            "datadatasector" : filterServiceData?.data_sector_data,
                            "datafiltersector" : results_state?.dataSector,
        
                            "datafilterarrayresource" : filterServiceData?.data_resource_filter,
                            "datahandlerresource" : filterServiceData?.data_resource_handler,
                            "datadataresource" : filterServiceData?.data_resource_data,
                            "datafilterresource": results_state?.dataresource,
                        }
                
                    } 
                    if(token!==undefined){
                        dispatch(shareSingleQuery(data,token)).then(()=>{
                            querySharedWith()
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        })
                    }else if(tokenSso!== undefined){
                        dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                            querySharedWith()
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        })
                    } 
                }
            }else if(props?.category==="multimedia"){
                if(searchTerm!==""&&file===""){
                    const data = {
                        "title":queryName,
                                        "query":{
                                            "category":props?.category,
                                            "qterm":searchTerm,
                                            "org_name" : results_state?.mulSource,
                                            "region" : results_state?.mulRegion,
                                            "country" : results_state?.mulCountry,
                                            "sector" : results_state?.mulSector,
                                            "topic" : results_state?.mulTopic,
                                            "start_date" : results_state?.mulSdate,
                                            "end_date" : results_state?.mulEdate,                 
                                        },
                                        "category" : props?.category,
                                        "users" : userValues,
                                        "filter_parameters" : {
                                            "mulfilterarrayregion" : filterServiceMul?.mul_region_filter,
                                            "mulhandlerregion" : filterServiceMul?.mul_region_handler,
                                            "muldataregion" : filterServiceMul?.mul_region_data,
                                            "mulfilterregion": results_state?.mulRegion,
                                            
                                            "mulfilterarrayorg" : filterServiceMul?.mul_org_filter,
                                            "mulhandlerorg" : filterServiceMul?.mul_org_handler,
                                            "muldataorg" : filterServiceMul?.mul_org_data,
                                            "mulfilterorg" : results_state?.mulSource,
                        
                                            "mulfilterarraycountry" : filterServiceMul?.mul_country_filter,
                                            "mulhandlercountry" : filterServiceMul?.mul_country_handler,
                                            "muldatacountry" : filterServiceMul?.mul_country_data,
                                            "mulfiltercountry" : results_state?.mulCountry,
                        
                                            "mulfilterarraytopic" :filterServiceMul?.mul_topic_filter,
                                            "mulhandlertopic" : filterServiceMul?.mul_topic_handler,
                                            "muldatatopic" : filterServiceMul?.mul_topic_data,
                                            "mulfiltertopic" : results_state?.mulTopic,
                        
                                            "mulfilterarraysector" : filterServiceMul?.mul_sector_filter,
                                            "mulhandlersector" : filterServiceMul?.mul_sector_handler,
                                            "muldatasector" : filterServiceMul?.mul_sector_data,
                                            "mulfiltersector" : results_state?.mulSector,
                        
                                            "mulstartdate" : results_state?.mulSdate,
                                            "mulenddate" : results_state?.mulEdate,
                                            "mulSdate" : filterServiceMul?.mul_start_date_handler,
                                            "mulEdate" : filterServiceMul?.mul_end_date_handler
                                        }
                                
                                    }  
                                    if(token!==undefined){
                                        dispatch(shareSingleQuery(data,token)).then(()=>{
                                            querySharedWith()
                                            if(!errorShareQuery){
                                                setClicked(!clicked)
                                                setSelect([])
                                            }
                                        })
                                    }else if(tokenSso!== undefined){
                                        dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                                            querySharedWith()
                                            if(!errorShareQuery){
                                                setClicked(!clicked)
                                                setSelect([])
                                            }
                                        })
                                    }                 
                }else if(file!==""&&searchTerm===""){
                    const data = {
                        "title":queryName,
                        "query":{
                            "category":props?.category,
                            "qfile":file_path_mul,
                            "org_name" : results_state?.mulSource,
                            "region" : results_state?.mulRegion,
                            "country" : results_state?.mulCountry,
                            "sector" : results_state?.mulSector,
                            "topic" : results_state?.mulTopic,
                            "start_date" : results_state?.mulSdate,
                            "end_date" : results_state?.mulEdate,                 
                        },
                        "category" : props?.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "mulfilterarrayregion" : filterServiceMul?.mul_region_filter,
                            "mulhandlerregion" : filterServiceMul?.mul_region_handler,
                            "muldataregion" : filterServiceMul?.mul_region_data,
                            "mulfilterregion": results_state?.mulRegion,
                            
                            "mulfilterarrayorg" : filterServiceMul?.mul_org_filter,
                            "mulhandlerorg" : filterServiceMul?.mul_org_handler,
                            "muldataorg" : filterServiceMul?.mul_org_data,
                            "mulfilterorg" : results_state?.mulSource,
        
                            "mulfilterarraycountry" : filterServiceMul?.mul_country_filter,
                            "mulhandlercountry" : filterServiceMul?.mul_country_handler,
                            "muldatacountry" : filterServiceMul?.mul_country_data,
                            "mulfiltercountry" : results_state?.mulCountry,
        
                            "mulfilterarraytopic" :filterServiceMul?.mul_topic_filter,
                            "mulhandlertopic" : filterServiceMul?.mul_topic_handler,
                            "muldatatopic" : filterServiceMul?.mul_topic_data,
                            "mulfiltertopic" : results_state?.mulTopic,
        
                            "mulfilterarraysector" : filterServiceMul?.mul_sector_filter,
                            "mulhandlersector" : filterServiceMul?.mul_sector_handler,
                            "muldatasector" : filterServiceMul?.mul_sector_data,
                            "mulfiltersector" : results_state?.mulSector,
        
                            "mulstartdate" : results_state?.mulSdate,
                            "mulenddate" : results_state?.mulEdate,
                            "mulSdate" : filterServiceMul?.mul_start_date_handler,
                            "mulEdate" : filterServiceMul?.mul_end_date_handler
                        }
                
                    }  
                    if(token!==undefined){
                        dispatch(shareSingleQuery(data,token)).then(()=>{
                            querySharedWith()
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        })
                    }else if(tokenSso!== undefined){
                        dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                            querySharedWith()
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        })
                    } 
                }
            }else if(props?.category==="evaluations"){
                if(searchTerm!==""&&file===""){
                     const data = {
                    "title":queryName,
                    "query":{
                        "category":props?.category,
                        "qterm":searchTerm,
                        "org_name" : results_state?.evalSource,
                        "region" : results_state?.evalRegion,
                        "country" : results_state?.evalCountry,
                        "sector" : results_state?.evalSector,
                        "topic" : results_state?.evalTopic,
                        "start_date" : results_state?.evalSdate,
                        "end_date" : results_state?.evalEdate,                  
                    },
                    "category" : props?.category,
                    "users" : userValues,
                    "filter_parameters" : {
                        "evalfilterarrayregion" : filterServiceEval?.eval_region_filter,
                        "evalhandlerregion" : filterServiceEval?.eval_region_handler,
                        "evaldataregion" : filterServiceEval?.eval_region_data,
                        "evalfilterregion": results_state?.evalRegion,
                        
                        "evalfilterarrayorg" : filterServiceEval?.eval_org_filter,
                        "evalhandlerorg" : filterServiceEval?.eval_org_handler,
                        "evaldataorg" : filterServiceEval?.eval_org_data,
                        "evalfilterorg" : results_state?.evalSource,
        
                        "evalfilterarraycountry" : filterServiceEval?.eval_country_filter,
                        "evalhandlercountry" : filterServiceEval?.eval_country_handler,
                        "evaldatacountry" : filterServiceEval?.eval_country_data,
                        "evalfiltercountry" : results_state?.evalCountry,
        
                        "evalfilterarraytopic" :filterServiceEval?.eval_topic_filter,
                        "evalhandlertopic" : filterServiceEval?.eval_topic_handler,
                        "evaldatatopic" : filterServiceEval?.eval_topic_data,
                        "evalfiltertopic" : results_state?.evalTopic,
        
                        "evalfilterarraysector" : filterServiceEval?.eval_sector_filter,
                        "evalhandlersector" : filterServiceEval?.eval_sector_handler,
                        "evaldatasector" : filterServiceEval?.eval_sector_data,
                        "evalfiltersector" : results_state?.evalSector,
        
                        "evalstartdate" : results_state?.evalSdate,
                        "evalenddate" : results_state?.evalEdate,
                        "evalSdate" : filterServiceEval?.eval_start_date_handler,
                        "evalEdate" : filterServiceEval?.eval_end_date_handler
                    }
            
                }  
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token)).then(()=>{
                        querySharedWith()
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }else if(tokenSso!== undefined){
                    dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                        querySharedWith()
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }
                }else if(file!==""&&searchTerm===""){
                    const data = {
                        "title":queryName,
                        "query":{
                            "category":props?.category,
                            "qfile":file_path_eval,
                            "org_name" : results_state?.evalSource,
                            "region" : results_state?.evalRegion,
                            "country" : results_state?.evalCountry,
                            "sector" : results_state?.evalSector,
                            "topic" : results_state?.evalTopic,
                            "start_date" : results_state?.evalSdate,
                            "end_date" : results_state?.evalEdate,                  
                        },
                        "category" : props?.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "evalfilterarrayregion" : filterServiceEval?.eval_region_filter,
                            "evalhandlerregion" : filterServiceEval?.eval_region_handler,
                            "evaldataregion" : filterServiceEval?.eval_region_data,
                            "evalfilterregion": results_state?.evalRegion,
                            
                            "evalfilterarrayorg" : filterServiceEval?.eval_org_filter,
                            "evalhandlerorg" : filterServiceEval?.eval_org_handler,
                            "evaldataorg" : filterServiceEval?.eval_org_data,
                            "evalfilterorg" : results_state?.evalSource,
            
                            "evalfilterarraycountry" : filterServiceEval?.eval_country_filter,
                            "evalhandlercountry" : filterServiceEval?.eval_country_handler,
                            "evaldatacountry" : filterServiceEval?.eval_country_data,
                            "evalfiltercountry" : results_state?.evalCountry,
            
                            "evalfilterarraytopic" :filterServiceEval?.eval_topic_filter,
                            "evalhandlertopic" : filterServiceEval?.eval_topic_handler,
                            "evaldatatopic" : filterServiceEval?.eval_topic_data,
                            "evalfiltertopic" : results_state?.evalTopic,
            
                            "evalfilterarraysector" : filterServiceEval?.eval_sector_filter,
                            "evalhandlersector" : filterServiceEval?.eval_sector_handler,
                            "evaldatasector" : filterServiceEval?.eval_sector_data,
                            "evalfiltersector" : results_state?.evalSector,
            
                            "evalstartdate" : results_state?.evalSdate,
                            "evalenddate" : results_state?.evalEdate,
                            "evalSdate" : filterServiceEval?.eval_start_date_handler,
                            "evalEdate" : filterServiceEval?.eval_end_date_handler
                        }
                
                    }  
                    if(token!==undefined){
                        dispatch(shareSingleQuery(data,token)).then(()=>{
                            querySharedWith()
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        })
                    }else if(tokenSso!== undefined){
                        dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                            querySharedWith()
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        })
                    }
                }
            }else if(props?.category==="experts"){
                if(searchTerm!==""&&file===""){
                const data = {
                    "title":queryName,
                    "query":{
                        "category":props?.category,
                        "qterm":searchTerm,
                        "org_name" : results_state?.source,
                        "region" : results_state?.region,
                        "country" : results_state?.country,
                        "sector" : results_state?.sector,
                        "topic" : results_state?.topic,
                        "ignore_query" : filterServiceExperts?.experts_ignore_query
                    },
                    "category" :props?.category,
                    "users" : userValues,
                    "filter_parameters" : {
                        "expertsfilterarrayregion" : filterServiceExperts?.experts_region_filter,
                        "expertshandlerregion" : filterServiceExperts?.experts_region_handler,
                        "expertsdataregion" : filterServiceExperts?.experts_region_data,
                        "expertsfilterregion": results_state?.region,
                        
                        "expertsfilterarrayorg" : filterServiceExperts?.experts_org_filter,
                        "expertshandlerorg" : filterServiceExperts?.experts_org_handler,
                        "expertsdataorg" : filterServiceExperts?.experts_org_data,
                        "expertsfilterorg" : results_state?.source,
        
                        "expertsfilterarraycountry" : filterServiceExperts?.experts_country_filter,
                        "expertshandlercountry" : filterServiceExperts?.experts_country_handler,
                        "expertsdatacountry" : filterServiceExperts?.experts_country_data,
                        "expertsfiltercountry" : results_state?.country,
        
                        "expertsfilterarraytopic" :filterServiceExperts?.experts_topic_filter,
                        "expertshandlertopic" : filterServiceExperts?.experts_topic_handler,
                        "expertsdatatopic" : filterServiceExperts?.experts_topic_data,
                        "expertsfiltertopic" : results_state?.topic,
        
                        "expertsfilterarraysector" : filterServiceExperts?.experts_sector_filter,
                        "expertshandlersector" : filterServiceExperts?.experts_sector_handler,
                        "expertsdatasector" : filterServiceExperts?.experts_sector_data,
                        "expertsfiltersector" : results_state?.sector,  
                        
                        "ignore_query" : filterServiceExperts?.experts_ignore_query
                    }
            
                }  
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token)).then(()=>{
                        querySharedWith()
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }else if(tokenSso!== undefined){
                    dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                        querySharedWith()
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }
            }else if(file!==""&&searchTerm===""){
                const data = {
                    "title":queryName,
                    "query":{
                        "category":props?.category,
                        "qfile":file_path_experts,
                        "org_name" : results_state?.source,
                        "region" : results_state?.region,
                        "country" : results_state?.country,
                        "sector" : results_state?.sector,
                        "topic" : results_state?.topic,    
                        "ignore_query" : filterServiceExperts?.experts_ignore_query          
                    },
                    "category" : props?.category,
                    "users" : userValues,
                    "filter_parameters" : {
                        "expertsfilterarrayregion" : filterServiceExperts?.experts_region_filter,
                        "expertshandlerregion" : filterServiceExperts?.experts_region_handler,
                        "expertsdataregion" : filterServiceExperts?.experts_region_data,
                        "expertsfilterregion": results_state?.region,
                        
                        "expertsfilterarrayorg" : filterServiceExperts?.experts_org_filter,
                        "expertshandlerorg" : filterServiceExperts?.experts_org_handler,
                        "expertsdataorg" : filterServiceExperts?.experts_org_data,
                        "expertsfilterorg" : results_state?.source,
        
                        "expertsfilterarraycountry" : filterServiceExperts?.experts_country_filter,
                        "expertshandlercountry" : filterServiceExperts?.experts_country_handler,
                        "expertsdatacountry" : filterServiceExperts?.experts_country_data,
                        "expertsfiltercountry" : results_state?.country,
        
                        "expertsfilterarraytopic" :filterServiceExperts?.experts_topic_filter,
                        "expertshandlertopic" : filterServiceExperts?.experts_topic_handler,
                        "expertsdatatopic" : filterServiceExperts?.experts_topic_data,
                        "expertsfiltertopic" : results_state?.topic,
        
                        "expertsfilterarraysector" : filterServiceExperts?.experts_sector_filter,
                        "expertshandlersector" : filterServiceExperts?.experts_sector_handler,
                        "expertsdatasector" : filterServiceExperts?.experts_sector_data,
                        "expertsfiltersector" : results_state?.sector,
        
                        "ignore_query" : filterServiceExperts?.experts_ignore_query
                    }
            
                }  
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token)).then(()=>{
                        querySharedWith()
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }else if(tokenSso!== undefined){
                    dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                        querySharedWith()
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }
                }
            }else if(props?.category==="econpubs"){
                if(searchTerm!==""&&file===""){
                const data = {
                    "title":queryName,
                    "query":{
                        "category":props?.category,
                        "qterm":searchTerm,
                        "org_name" : results_state?.econSource,
                        "region" : results_state?.econRegion,
                        "country" : results_state?.econCountry,
                        "sector" : results_state?.econSector,
                        "topic" : results_state?.econTopic,
                        "ignore_query" : filterServiceEcon?.econ_ignore_query,
                        "start_date" : results_state?.econSdate,
                        "end_date" : results_state?.econEdate,   
                    },
                    "category" : props?.category,
                    "users" : userValues,
                    "filter_parameters" : {
                        "econfilterarrayregion" : filterServiceEcon?.econ_region_filter,
                        "econhandlerregion" : filterServiceEcon?.econ_region_handler,
                        "econdataregion" : filterServiceEcon?.econ_region_data,
                        "econfilterregion": results_state?.econRegion,
                        
                        "econfilterarrayorg" : filterServiceEcon?.econ_org_filter,
                        "econhandlerorg" : filterServiceEcon?.econ_org_handler,
                        "econdataorg" : filterServiceEcon?.econ_org_data,
                        "econfilterorg" : results_state?.econSource,
        
                        "econfilterarraycountry" : filterServiceEcon?.econ_country_filter,
                        "econhandlercountry" : filterServiceEcon?.econ_country_handler,
                        "econdatacountry" : filterServiceEcon?.econ_country_data,
                        "econfiltercountry" : results_state?.econCountry,
        
                        "econfilterarraytopic" :filterServiceEcon?.econ_topic_filter,
                        "econhandlertopic" : filterServiceEcon?.econ_topic_handler,
                        "econdatatopic" : filterServiceEcon?.econ_topic_data,
                        "econfiltertopic" : results_state?.econTopic,
        
                        "econfilterarraysector" : filterServiceEcon?.econ_sector_filter,
                        "econhandlersector" : filterServiceEcon?.econ_sector_handler,
                        "econdatasector" : filterServiceEcon?.econ_sector_data,
                        "econfiltersector" : results_state?.econSector,  
                        
                        "ignore_query" : filterServiceEcon?.econ_ignore_query,
                        "econinitialcountry":filterServiceEcon?.econ_filter_popup_countries,
                        "start_date" : results_state?.econSdate,
                        "end_date" : results_state?.econEdate,   
                        "econSdate" : filterServiceEcon?.econ_start_date_handler,
                        "econEdate" : filterServiceEcon?.econ_end_date_handler
                    }
            
                }  
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token)).then(()=>{
                        querySharedWith()
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }else if(tokenSso!== undefined){
                    dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                        querySharedWith()
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }
                }else if(file!==""&&searchTerm===""){
                    const data = {
                        "title":queryName,
                        "query":{
                            "category":props?.category,
                            "qfile":file_path_econ,
                            "org_name" : results_state?.econSource,
                            "region" : results_state?.econRegion,
                            "country" : results_state?.econCountry,
                            "sector" : results_state?.econSector,
                            "topic" : results_state?.econTopic,
                            "ignore_query" : filterServiceEcon?.econ_ignore_query,
                            "start_date" : results_state?.econSdate,
                            "end_date" : results_state?.econEdate,   
                        },
                        "category" : props?.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "econfilterarrayregion" : filterServiceEcon?.econ_region_filter,
                            "econhandlerregion" : filterServiceEcon?.econ_region_handler,
                            "econdataregion" : filterServiceEcon?.econ_region_data,
                            "econfilterregion": results_state?.econRegion,
                            
                            "econfilterarrayorg" : filterServiceEcon?.econ_org_filter,
                            "econhandlerorg" : filterServiceEcon?.econ_org_handler,
                            "econdataorg" : filterServiceEcon?.econ_org_data,
                            "econfilterorg" : results_state?.econSource,
            
                            "econfilterarraycountry" : filterServiceEcon?.econ_country_filter,
                            "econhandlercountry" : filterServiceEcon?.econ_country_handler,
                            "econdatacountry" : filterServiceEcon?.econ_country_data,
                            "econfiltercountry" : results_state?.econCountry,
            
                            "econfilterarraytopic" :filterServiceEcon?.econ_topic_filter,
                            "econhandlertopic" : filterServiceEcon?.econ_topic_handler,
                            "econdatatopic" : filterServiceEcon?.econ_topic_data,
                            "econfiltertopic" : results_state?.econTopic,
            
                            "econfilterarraysector" : filterServiceEcon?.econ_sector_filter,
                            "econhandlersector" : filterServiceEcon?.econ_sector_handler,
                            "econdatasector" : filterServiceEcon?.econ_sector_data,
                            "econfiltersector" : results_state?.econSector,  
                            
                            "ignore_query" : filterServiceEcon?.econ_ignore_query,
                            "econinitialcountry":filterServiceEcon?.econ_filter_popup_countries,
                            "start_date" : results_state?.econSdate,
                            "end_date" : results_state?.econEdate,   
                            "econSdate" : filterServiceEcon?.econ_start_date_handler,
                            "econEdate" : filterServiceEcon?.econ_end_date_handler
                        }
                
                    }  
                    if(token!==undefined){
                        dispatch(shareSingleQuery(data,token)).then(()=>{
                            querySharedWith()
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        })
                    }else if(tokenSso!== undefined){
                        dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                            querySharedWith()
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        })
                    } 
                }
            }else if(props?.category==="news"){
                if(searchTerm!==""&&file===""){
                    const data = {
                    "title":queryName,
                    "query":{
                        "category":props?.category,
                        "qterm":searchTerm,
                    },
                    "category" : props?.category,   
                    "users" : userValues, 
                } 
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token)).then(()=>{
                        querySharedWith()
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }else if(tokenSso!== undefined){
                    dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                        querySharedWith()
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }
                }else if(file!==""&&searchTerm===""){
                    const data = {
                        "title":queryName,
                        "query":{
                            "category":props?.category,
                            "qfile":file_path_news
                        },
                        "category" : props?.category,   
                        "users" : userValues, 
                    } 
                    if(token!==undefined){
                        dispatch(shareSingleQuery(data,token)).then(()=>{
                            querySharedWith()
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        })
                    }else if(tokenSso!== undefined){
                        dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                            querySharedWith()
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        })
                    }
                }
            }   
            }
            
        else{
                const data = {
                    "title":sharedListMessage[0]?.title,
                    "query":
                        sharedListMessage[0]?.get_parameters
                    ,
                    "filter_parameters":sharedListMessage[0]?.filter_parameters,
                    "category" : sharedListMessage[0]?.category,
                    "users" : userValues
                }
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token)).then(()=>{
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }else if(tokenSso!==undefined){
                    dispatch(shareSingleQuery(data,tokenSso)).then(()=>{
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }
                
            
            
        }
      }
      const saveAndShareQuery =(values)=>{
        if(sharedListMessage==="Query not found for this user"){
            if(props?.category==="financing"){
                if(searchTerm!==""&&file===""){
                    const data = {
                        "title":title,
                        "query":{
                            "category":props.category,
                            "qterm":searchTerm,
                            "org_name" : results_state?.financeSource,
                            "region" : results_state?.financeRegion,
                            "country" : results_state?.financeCountry,
                            "sector" : results_state?.financeSector,
                            "topic" : results_state?.financeTopic,
                            "start_date" : results_state?.financeSdate,
                            "end_date" : results_state?.financeEdate,  
                        },
                        "category":props.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "financefilterarrayregion" : filterService?.finance_region_filter,
                            "financehandlerregion" : filterService?.finance_region_handler,
                            "financedataregion" : filterService?.finance_region_data,
                            "financefilterregion": results_state?.financeRegion,
                            
                            "financefilterarrayorg" : filterService?.finance_org_filter,
                            "financehandlerorg" : filterService?.finanace_org_handler,
                            "financedataorg" : filterService?.finance_org_data,
                            "financefilterorg" : results_state?.financeSource,
        
                            "financefilterarraycountry" : filterService?.finance_country_filter,
                            "financehandlercountry" : filterService?.finance_country_handler,
                            "financedatacountry" : filterService?.finance_country_data,
                            "financefiltercountry" : results_state?.financeCountry,
        
                            "financefilterarraytopic" :filterService?.finance_topic_filter,
                            "financehandlertopic" : filterService?.finance_topic_handler,
                            "financedatatopic" : filterService?.finance_topic_data,
                            "financefiltertopic" : results_state?.financeTopic,
        
                            "financefilterarraysector" : filterService?.finance_sector_filter,
                            "financehandlersector" : filterService?.finance_sector_handler,
                            "financedatasector" : filterService?.finance_sector_data,
                            "financefiltersector" : results_state?.financeSector,
        
                            "financestartdate" : results_state?.financeSdate,
                            "financeenddate" : results_state?.financeEdate,
                            "financeSdate" : filterService?.finance_start_date_handler,
                            "financeEdate" : filterService?.finance_end_date_handler
                        }
                        
                
                    }  
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                        if(!share_query_state_ref.current?.hasError){
                            querySharedWith()
                            setSelect([])
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        }else{
                            toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                        }
                    })
                }else if(tokenSso!== undefined){
                    dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                        if(!share_query_state_ref.current?.hasError){
                            querySharedWith()
                            setSelect([])
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        }else{
                            toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                        }
                    })
                }
                }else if(file!==""&&searchTerm===""){
                    const data = {
                        "title":title,
                        "query":{
                            "category":props.category,
                            "qfile":file_path_fin,
                            "org_name" : results_state?.financeSource,
                            "region" : results_state?.financeRegion,
                            "country" : results_state?.financeCountry,
                            "sector" : results_state?.financeSector,
                            "topic" : results_state?.financeTopic,
                            "start_date" : results_state?.financeSdate,
                            "end_date" : results_state?.financeEdate,  
                        },
                        "category":props.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "financefilterarrayregion" : filterService?.finance_region_filter,
                            "financehandlerregion" : filterService?.finance_region_handler,
                            "financedataregion" : filterService?.finance_region_data,
                            "financefilterregion": results_state?.financeRegion,
                            
                            "financefilterarrayorg" : filterService?.finance_org_filter,
                            "financehandlerorg" : filterService?.finanace_org_handler,
                            "financedataorg" : filterService?.finance_org_data,
                            "financefilterorg" : results_state?.financeSource,
        
                            "financefilterarraycountry" : filterService?.finance_country_filter,
                            "financehandlercountry" : filterService?.finance_country_handler,
                            "financedatacountry" : filterService?.finance_country_data,
                            "financefiltercountry" : results_state?.financeCountry,
        
                            "financefilterarraytopic" :filterService?.finance_topic_filter,
                            "financehandlertopic" : filterService?.finance_topic_handler,
                            "financedatatopic" : filterService?.finance_topic_data,
                            "financefiltertopic" : results_state?.financeTopic,
        
                            "financefilterarraysector" : filterService?.finance_sector_filter,
                            "financehandlersector" : filterService?.finance_sector_handler,
                            "financedatasector" : filterService?.finance_sector_data,
                            "financefiltersector" : results_state?.financeSector,
        
                            "financestartdate" : results_state?.financeSdate,
                            "financeenddate" : results_state?.financeEdate,
                            "financeSdate" : filterService?.finance_start_date_handler,
                            "financeEdate" : filterService?.finance_end_date_handler
                        }
                
                    }  
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                        if(!share_query_state_ref.current?.hasError){
                            querySharedWith()
                            setSelect([])
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        }else{
                            toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                        }
                    })
                }else if(tokenSso!== undefined){
                    dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                        if(!share_query_state_ref.current?.hasError){
                            querySharedWith()
                            setSelect([])
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        }else{
                            toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                        }
                    })
                }
                }
                
                
            }else if(props?.category==="devpubs"){
                if(searchTerm!==""&&file===""){
                    const data = {
                        "title":title,
                        "query":{
                            "category":props.category,
                            "qterm":searchTerm,
                            "org_name" : results_state?.devSource,
                            "region" : results_state?.devRegion,
                            "country" : results_state?.devCountry,
                            "sector" : results_state?.devSector,
                            "topic" : results_state?.devTopic,
                            "start_date" : results_state?.devSdate,
                            "end_date" : results_state?.devEdate,    
                        },
                        "category":props.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "devfilterarrayregion" : filterServiceDev?.dev_region_filter,
                            "devhandlerregion" : filterServiceDev?.dev_region_handler,
                            "devdataregion" : filterServiceDev?.dev_region_data,
                            "devfilterregion": results_state?.devRegion,
                            
                            "devfilterarrayorg" : filterServiceDev?.dev_org_filter,
                            "devhandlerorg" : filterServiceDev?.dev_org_handler,
                            "devdataorg" : filterServiceDev?.dev_org_data,
                            "devfilterorg" : results_state?.devSource,
        
                            "devfilterarraycountry" : filterServiceDev?.dev_country_filter,
                            "devhandlercountry" : filterServiceDev?.dev_country_handler,
                            "devdatacountry" : filterServiceDev?.dev_country_data,
                            "devfiltercountry" : results_state?.devCountry,
        
                            "devfilterarraytopic" :filterServiceDev?.dev_topic_filter,
                            "devhandlertopic" : filterServiceDev?.dev_topic_handler,
                            "devdatatopic" : filterServiceDev?.dev_topic_data,
                            "devfiltertopic" : results_state?.devTopic,
        
                            "devfilterarraysector" : filterServiceDev?.dev_sector_filter,
                            "devhandlersector" : filterServiceDev?.dev_sector_handler,
                            "devdatasector" : filterServiceDev?.dev_sector_data,
                            "devfiltersector" : results_state?.devSector,
        
                            "devstartdate" : results_state?.devSdate,
                            "devenddate" : results_state?.devEdate,
                            "devSdate" : filterServiceDev?.dev_start_date_handler,
                            "devEdate" : filterServiceDev?.dev_end_date_handler
                        }
                
                    }  
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                        if(!share_query_state_ref.current?.hasError){
                            querySharedWith()
                            setSelect([])
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        }else{
                            toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                        }
                    })
                }else if(tokenSso!== undefined){
                    dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                        if(!share_query_state_ref.current?.hasError){
                            querySharedWith()
                            setSelect([])
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        }else{
                            toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                        }
                    })
                }
                } else if(file!==""&&searchTerm===""){
                    const data = {
                        "title":title,
                        "query":{
                            "category":props.category,
                            "qfile":file_path_dev,
                            "org_name" : results_state?.devSource,
                            "region" : results_state?.devRegion,
                            "country" : results_state?.devCountry,
                            "sector" : results_state?.devSector,
                            "topic" : results_state?.devTopic,
                            "start_date" : results_state?.devSdate,
                            "end_date" : results_state?.devEdate,    
                        },
                        "category":props.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "devfilterarrayregion" : filterServiceDev?.dev_region_filter,
                            "devhandlerregion" : filterServiceDev?.dev_region_handler,
                            "devdataregion" : filterServiceDev?.dev_region_data,
                            "devfilterregion": results_state?.devRegion,
                            
                            "devfilterarrayorg" : filterServiceDev?.dev_org_filter,
                            "devhandlerorg" : filterServiceDev?.dev_org_handler,
                            "devdataorg" : filterServiceDev?.dev_org_data,
                            "devfilterorg" : results_state?.devSource,
        
                            "devfilterarraycountry" : filterServiceDev?.dev_country_filter,
                            "devhandlercountry" : filterServiceDev?.dev_country_handler,
                            "devdatacountry" : filterServiceDev?.dev_country_data,
                            "devfiltercountry" : results_state?.devCountry,
        
                            "devfilterarraytopic" :filterServiceDev?.dev_topic_filter,
                            "devhandlertopic" : filterServiceDev?.dev_topic_handler,
                            "devdatatopic" : filterServiceDev?.dev_topic_data,
                            "devfiltertopic" : results_state?.devTopic,
        
                            "devfilterarraysector" : filterServiceDev?.dev_sector_filter,
                            "devhandlersector" : filterServiceDev?.dev_sector_handler,
                            "devdatasector" : filterServiceDev?.dev_sector_data,
                            "devfiltersector" : results_state?.devSector,
        
                            "devstartdate" : results_state?.devSdate,
                            "devenddate" : results_state?.devEdate,
                            "devSdate" : filterServiceDev?.dev_start_date_handler,
                            "devEdate" : filterServiceDev?.dev_end_date_handler
                        }
                
                    }  
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                        if(!share_query_state_ref.current?.hasError){
                            querySharedWith()
                            setSelect([])
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        }else{
                            toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                        }
                    })
                }else if(tokenSso!== undefined){
                    dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                        if(!share_query_state_ref.current?.hasError){
                            querySharedWith()
                            setSelect([])
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        }else{
                            toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                        }
                    })
                }
                }
            }else if(props?.category==="otherpubs"){
                if(searchTerm!==""&&file===""){
                    const data = {
                        "title":title,
                        "query":{
                            "category":props.category,
                            "qterm":searchTerm,
                            "org_name" : results_state?.otherSource,
                            "region" : results_state?.otherRegion,
                            "country" : results_state?.otherCountry,
                            "sector" : results_state?.otherSector,
                            "topic" : results_state?.otherTopic,
                            "start_date" : results_state?.otherSdate,
                            "end_date" : results_state?.otherEdate,  
                        },
                        "category":props.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "otherfilterarrayregion" : filterServiceOther?.other_region_filter,
                            "otherhandlerregion" : filterServiceOther?.other_region_handler,
                            "otherdataregion" : filterServiceOther?.other_region_data,
                            "otherfilterregion": results_state?.otherRegion,
                            
                            "otherfilterarrayorg" : filterServiceOther?.other_org_filter,
                            "otherhandlerorg" : filterServiceOther?.other_org_handler,
                            "otherdataorg" : filterServiceOther?.other_org_data,
                            "otherfilterorg" : results_state?.otherSource,
        
                            "otherfilterarraycountry" : filterServiceOther?.other_country_filter,
                            "otherhandlercountry" : filterServiceOther?.other_country_handler,
                            "otherdatacountry" : filterServiceOther?.other_country_data,
                            "otherfiltercountry" : results_state?.otherCountry,
        
                            "otherfilterarraytopic" :filterServiceOther?.other_topic_filter,
                            "otherhandlertopic" : filterServiceOther?.other_topic_handler,
                            "otherdatatopic" : filterServiceOther?.other_topic_data,
                            "otherfiltertopic" : results_state?.otherTopic,
        
                            "otherfilterarraysector" : filterServiceOther?.other_sector_filter,
                            "otherhandlersector" : filterServiceOther?.other_sector_handler,
                            "otherdatasector" : filterServiceOther?.other_sector_data,
                            "otherfiltersector" : results_state?.otherSector,
        
                            "otherstartdate" : results_state?.otherSdate,
                            "otherenddate" : results_state?.otherEdate,
                            "otherSdate" : filterServiceOther?.other_start_date_handler,
                            "otherEdate" : filterServiceOther?.other_end_date_handler
                        }
                
                    }  
                    if(token!==undefined){
                        dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                            if(!share_query_state_ref.current?.hasError){
                                querySharedWith()
                                setSelect([])
                                if(!errorShareQuery){
                                    setClicked(!clicked)
                                    setSelect([])
                                }
                            }else{
                                toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                            }
                        })
                    }else if(tokenSso!== undefined){
                        dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                            if(!share_query_state_ref.current?.hasError){
                                querySharedWith()
                                setSelect([])
                                if(!errorShareQuery){
                                    setClicked(!clicked)
                                    setSelect([])
                                }
                            }else{
                                toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                            }
                        })
                    }
                }else if(file!==""&&searchTerm===""){
                    const data = {
                        "title":title,
                        "query":{
                            "category":props.category,
                            "qterm":searchTerm,
                            "org_name" : results_state?.otherSource,
                            "region" : results_state?.otherRegion,
                            "country" : results_state?.otherCountry,
                            "sector" : results_state?.otherSector,
                            "topic" : results_state?.otherTopic,
                            "start_date" : results_state?.otherSdate,
                            "end_date" : results_state?.otherEdate,  
                        },
                        "category":props.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "otherfilterarrayregion" : filterServiceOther?.other_region_filter,
                            "otherhandlerregion" : filterServiceOther?.other_region_handler,
                            "otherdataregion" : filterServiceOther?.other_region_data,
                            "otherfilterregion": results_state?.otherRegion,
                            
                            "otherfilterarrayorg" : filterServiceOther?.other_org_filter,
                            "otherhandlerorg" : filterServiceOther?.other_org_handler,
                            "otherdataorg" : filterServiceOther?.other_org_data,
                            "otherfilterorg" : results_state?.otherSource,
        
                            "otherfilterarraycountry" : filterServiceOther?.other_country_filter,
                            "otherhandlercountry" : filterServiceOther?.other_country_handler,
                            "otherdatacountry" : filterServiceOther?.other_country_data,
                            "otherfiltercountry" : results_state?.otherCountry,
        
                            "otherfilterarraytopic" :filterServiceOther?.other_topic_filter,
                            "otherhandlertopic" : filterServiceOther?.other_topic_handler,
                            "otherdatatopic" : filterServiceOther?.other_topic_data,
                            "otherfiltertopic" : results_state?.otherTopic,
        
                            "otherfilterarraysector" : filterServiceOther?.other_sector_filter,
                            "otherhandlersector" : filterServiceOther?.other_sector_handler,
                            "otherdatasector" : filterServiceOther?.other_sector_data,
                            "otherfiltersector" : results_state?.otherSector,
        
                            "otherstartdate" : results_state?.otherSdate,
                            "otherenddate" : results_state?.otherEdate,
                            "otherSdate" : filterServiceOther?.other_start_date_handler,
                            "otherEdate" : filterServiceOther?.other_end_date_handler
                        }
                
                    }
                    if(token!==undefined){
                        dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                            if(!share_query_state_ref.current?.hasError){
                                querySharedWith()
                                setSelect([])
                                if(!errorShareQuery){
                                    setClicked(!clicked)
                                    setSelect([])
                                }
                            }else{
                                toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                            }
                        })
                    }else if(tokenSso!== undefined){
                        dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                            if(!share_query_state_ref.current?.hasError){
                                querySharedWith()
                                setSelect([])
                                if(!errorShareQuery){
                                    setClicked(!clicked)
                                    setSelect([])
                                }
                            }else{
                                toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                            }
                        })
                    }
                }
            }else if(props?.category==="data"){
                if(searchTerm!==""&&file===""){
                    const data = {
                        "title":title,
                        "query":{
                            "category":props?.category,
                            "qterm":searchTerm,
                            "org_name" : results_state?.dataSource,
                            "region" : results_state?.dataRegion,
                            "country" : results_state?.dataCountry,
                            "sector" : results_state?.dataSector,
                            "topic" : results_state?.dataTopic,
                            "resource" :results_state?.dataResource                   
                        },
                        "category" : props?.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "datafilterarrayregion" : filterServiceData?.data_region_filter,
                            "datahandlerregion" : filterServiceData?.data_region_handler,
                            "datadataregion" : filterServiceData?.data_region_data,
                            "datafilterregion": results_state?.dataRegion,
                            
                            "datafilterarrayorg" : filterServiceData?.data_org_filter,
                            "datahandlerorg" : filterServiceData?.data_org_handler,
                            "datadataorg" : filterServiceData?.data_org_data,
                            "datafilterorg" : results_state?.dataSource,
        
                            "datafilterarraycountry" : filterServiceData?.data_country_filter,
                            "datahandlercountry" : filterServiceData?.data_country_handler,
                            "datadatacountry" : filterServiceData?.data_country_data,
                            "datafiltercountry" : results_state?.dataCountry,
        
                            "datafilterarraytopic" :filterServiceData?.data_topic_filter,
                            "datahandlertopic" : filterServiceData?.data_topic_handler,
                            "datadatatopic" : filterServiceData?.data_topic_data,
                            "datafiltertopic" : results_state?.dataTopic,
        
                            "datafilterarraysector" : filterServiceData?.data_sector_filter,
                            "datahandlersector" : filterServiceData?.data_sector_handler,
                            "datadatasector" : filterServiceData?.data_sector_data,
                            "datafiltersector" : results_state?.dataSector,
        
                            "datafilterarrayresource" : filterServiceData?.data_resource_filter,
                            "datahandlerresource" : filterServiceData?.data_resource_handler,
                            "datadataresource" : filterServiceData?.data_resource_data,
                            "datafilterresource": results_state?.dataresource,
                        }
                
                    } 
                    if(token!==undefined){
                        dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                            if(!share_query_state_ref.current?.hasError){
                                querySharedWith()
                                setSelect([])
                                if(!errorShareQuery){
                                    setClicked(!clicked)
                                    setSelect([])
                                }
                            }else{
                                toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                            }
                        })
                    }else if(tokenSso!== undefined){
                        dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                            if(!share_query_state_ref.current?.hasError){
                                querySharedWith()
                                setSelect([])
                                if(!errorShareQuery){
                                    setClicked(!clicked)
                                    setSelect([])
                                }
                            }else{
                                toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                            }
                        })
                    } 
                }else if(file!==""&&searchTerm===""){
                    const data = {
                        "title":title,
                        "query":{
                            "category":props?.category,
                            "qfile":file_path_data,
                            "org_name" : results_state?.dataSource,
                            "region" : results_state?.dataRegion,
                            "country" : results_state?.dataCountry,
                            "sector" : results_state?.dataSector,
                            "topic" : results_state?.dataTopic,
                            "resource" :results_state?.dataResource                   
                        },
                        "category" : props?.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "datafilterarrayregion" : filterServiceData?.data_region_filter,
                            "datahandlerregion" : filterServiceData?.data_region_handler,
                            "datadataregion" : filterServiceData?.data_region_data,
                            "datafilterregion": results_state?.dataRegion,
                            
                            "datafilterarrayorg" : filterServiceData?.data_org_filter,
                            "datahandlerorg" : filterServiceData?.data_org_handler,
                            "datadataorg" : filterServiceData?.data_org_data,
                            "datafilterorg" : results_state?.dataSource,
        
                            "datafilterarraycountry" : filterServiceData?.data_country_filter,
                            "datahandlercountry" : filterServiceData?.data_country_handler,
                            "datadatacountry" : filterServiceData?.data_country_data,
                            "datafiltercountry" : results_state?.dataCountry,
        
                            "datafilterarraytopic" :filterServiceData?.data_topic_filter,
                            "datahandlertopic" : filterServiceData?.data_topic_handler,
                            "datadatatopic" : filterServiceData?.data_topic_data,
                            "datafiltertopic" : results_state?.dataTopic,
        
                            "datafilterarraysector" : filterServiceData?.data_sector_filter,
                            "datahandlersector" : filterServiceData?.data_sector_handler,
                            "datadatasector" : filterServiceData?.data_sector_data,
                            "datafiltersector" : results_state?.dataSector,
        
                            "datafilterarrayresource" : filterServiceData?.data_resource_filter,
                            "datahandlerresource" : filterServiceData?.data_resource_handler,
                            "datadataresource" : filterServiceData?.data_resource_data,
                            "datafilterresource": results_state?.dataresource,
                        }
                
                    } 
                    if(token!==undefined){
                        dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                            if(!share_query_state_ref.current?.hasError){
                                querySharedWith()
                                setSelect([])
                                if(!errorShareQuery){
                                    setClicked(!clicked)
                                    setSelect([])
                                }
                            }else{
                                toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                            }
                        })
                    }else if(tokenSso!== undefined){
                        dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                            if(!share_query_state_ref.current?.hasError){
                                querySharedWith()
                                setSelect([])
                                if(!errorShareQuery){
                                    setClicked(!clicked)
                                    setSelect([])
                                }
                            }else{
                                toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                            }
                        })
                    } 
                }
            }else if(props?.category==="multimedia"){
                if(searchTerm!==""&&file===""){
                    const data = {
                                        "title":title,
                                        "query":{
                                            "category":props?.category,
                                            "qterm":searchTerm,
                                            "org_name" : results_state?.mulSource,
                                            "region" : results_state?.mulRegion,
                                            "country" : results_state?.mulCountry,
                                            "sector" : results_state?.mulSector,
                                            "topic" : results_state?.mulTopic,
                                            "start_date" : results_state?.mulSdate,
                                            "end_date" : results_state?.mulEdate,                 
                                        },
                                        "category" : props?.category,
                                        "users" : userValues,
                                        "filter_parameters" : {
                                            "mulfilterarrayregion" : filterServiceMul?.mul_region_filter,
                                            "mulhandlerregion" : filterServiceMul?.mul_region_handler,
                                            "muldataregion" : filterServiceMul?.mul_region_data,
                                            "mulfilterregion": results_state?.mulRegion,
                                            
                                            "mulfilterarrayorg" : filterServiceMul?.mul_org_filter,
                                            "mulhandlerorg" : filterServiceMul?.mul_org_handler,
                                            "muldataorg" : filterServiceMul?.mul_org_data,
                                            "mulfilterorg" : results_state?.mulSource,
                        
                                            "mulfilterarraycountry" : filterServiceMul?.mul_country_filter,
                                            "mulhandlercountry" : filterServiceMul?.mul_country_handler,
                                            "muldatacountry" : filterServiceMul?.mul_country_data,
                                            "mulfiltercountry" : results_state?.mulCountry,
                        
                                            "mulfilterarraytopic" :filterServiceMul?.mul_topic_filter,
                                            "mulhandlertopic" : filterServiceMul?.mul_topic_handler,
                                            "muldatatopic" : filterServiceMul?.mul_topic_data,
                                            "mulfiltertopic" : results_state?.mulTopic,
                        
                                            "mulfilterarraysector" : filterServiceMul?.mul_sector_filter,
                                            "mulhandlersector" : filterServiceMul?.mul_sector_handler,
                                            "muldatasector" : filterServiceMul?.mul_sector_data,
                                            "mulfiltersector" : results_state?.mulSector,
                        
                                            "mulstartdate" : results_state?.mulSdate,
                                            "mulenddate" : results_state?.mulEdate,
                                            "mulSdate" : filterServiceMul?.mul_start_date_handler,
                                            "mulEdate" : filterServiceMul?.mul_end_date_handler
                                        }
                                
                                    }  
                                    if(token!==undefined){
                                        dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                                            if(!share_query_state_ref.current?.hasError){
                                                querySharedWith()
                                                setSelect([])
                                                if(!errorShareQuery){
                                                    setClicked(!clicked)
                                                    setSelect([])
                                                }
                                            }else{
                                                toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                                            }
                                        })
                                    }else if(tokenSso!== undefined){
                                        dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                                            if(!share_query_state_ref.current?.hasError){
                                                querySharedWith()
                                                setSelect([])
                                                if(!errorShareQuery){
                                                    setClicked(!clicked)
                                                    setSelect([])
                                                }
                                            }else{
                                                toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                                            }
                                        })
                                    }                 
                }else if(file!==""&&searchTerm===""){
                    const data = {
                        "title":title,
                        "query":{
                            "category":props?.category,
                            "qfile":file_path_mul,
                            "org_name" : results_state?.mulSource,
                            "region" : results_state?.mulRegion,
                            "country" : results_state?.mulCountry,
                            "sector" : results_state?.mulSector,
                            "topic" : results_state?.mulTopic,
                            "start_date" : results_state?.mulSdate,
                            "end_date" : results_state?.mulEdate,                 
                        },
                        "category" : props?.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "mulfilterarrayregion" : filterServiceMul?.mul_region_filter,
                            "mulhandlerregion" : filterServiceMul?.mul_region_handler,
                            "muldataregion" : filterServiceMul?.mul_region_data,
                            "mulfilterregion": results_state?.mulRegion,
                            
                            "mulfilterarrayorg" : filterServiceMul?.mul_org_filter,
                            "mulhandlerorg" : filterServiceMul?.mul_org_handler,
                            "muldataorg" : filterServiceMul?.mul_org_data,
                            "mulfilterorg" : results_state?.mulSource,
        
                            "mulfilterarraycountry" : filterServiceMul?.mul_country_filter,
                            "mulhandlercountry" : filterServiceMul?.mul_country_handler,
                            "muldatacountry" : filterServiceMul?.mul_country_data,
                            "mulfiltercountry" : results_state?.mulCountry,
        
                            "mulfilterarraytopic" :filterServiceMul?.mul_topic_filter,
                            "mulhandlertopic" : filterServiceMul?.mul_topic_handler,
                            "muldatatopic" : filterServiceMul?.mul_topic_data,
                            "mulfiltertopic" : results_state?.mulTopic,
        
                            "mulfilterarraysector" : filterServiceMul?.mul_sector_filter,
                            "mulhandlersector" : filterServiceMul?.mul_sector_handler,
                            "muldatasector" : filterServiceMul?.mul_sector_data,
                            "mulfiltersector" : results_state?.mulSector,
        
                            "mulstartdate" : results_state?.mulSdate,
                            "mulenddate" : results_state?.mulEdate,
                            "mulSdate" : filterServiceMul?.mul_start_date_handler,
                            "mulEdate" : filterServiceMul?.mul_end_date_handler
                        }
                
                    }  
                    if(token!==undefined){
                        dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                            if(!share_query_state_ref.current?.hasError){
                                querySharedWith()
                                setSelect([])
                                if(!errorShareQuery){
                                    setClicked(!clicked)
                                    setSelect([])
                                }
                            }else{
                                toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                            }
                        })
                    }else if(tokenSso!== undefined){
                        dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                            if(!share_query_state_ref.current?.hasError){
                                querySharedWith()
                                setSelect([])
                                if(!errorShareQuery){
                                    setClicked(!clicked)
                                    setSelect([])
                                }
                            }else{
                                toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                            }
                        })
                    } 
                }
            }else if(props?.category==="evaluations"){
                if(searchTerm!==""&&file===""){
                     const data = {
                    "title":title,
                    "query":{
                        "category":props?.category,
                        "qterm":searchTerm,
                        "org_name" : results_state?.evalSource,
                        "region" : results_state?.evalRegion,
                        "country" : results_state?.evalCountry,
                        "sector" : results_state?.evalSector,
                        "topic" : results_state?.evalTopic,
                        "start_date" : results_state?.evalSdate,
                        "end_date" : results_state?.evalEdate,                  
                    },
                    "category" : props?.category,
                    "users" : userValues,
                    "filter_parameters" : {
                        "evalfilterarrayregion" : filterServiceEval?.eval_region_filter,
                        "evalhandlerregion" : filterServiceEval?.eval_region_handler,
                        "evaldataregion" : filterServiceEval?.eval_region_data,
                        "evalfilterregion": results_state?.evalRegion,
                        
                        "evalfilterarrayorg" : filterServiceEval?.eval_org_filter,
                        "evalhandlerorg" : filterServiceEval?.eval_org_handler,
                        "evaldataorg" : filterServiceEval?.eval_org_data,
                        "evalfilterorg" : results_state?.evalSource,
        
                        "evalfilterarraycountry" : filterServiceEval?.eval_country_filter,
                        "evalhandlercountry" : filterServiceEval?.eval_country_handler,
                        "evaldatacountry" : filterServiceEval?.eval_country_data,
                        "evalfiltercountry" : results_state?.evalCountry,
        
                        "evalfilterarraytopic" :filterServiceEval?.eval_topic_filter,
                        "evalhandlertopic" : filterServiceEval?.eval_topic_handler,
                        "evaldatatopic" : filterServiceEval?.eval_topic_data,
                        "evalfiltertopic" : results_state?.evalTopic,
        
                        "evalfilterarraysector" : filterServiceEval?.eval_sector_filter,
                        "evalhandlersector" : filterServiceEval?.eval_sector_handler,
                        "evaldatasector" : filterServiceEval?.eval_sector_data,
                        "evalfiltersector" : results_state?.evalSector,
        
                        "evalstartdate" : results_state?.evalSdate,
                        "evalenddate" : results_state?.evalEdate,
                        "evalSdate" : filterServiceEval?.eval_start_date_handler,
                        "evalEdate" : filterServiceEval?.eval_end_date_handler
                    }
            
                }  
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                        if(!share_query_state_ref.current?.hasError){
                            querySharedWith()
                            setSelect([])
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        }else{
                            toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                        }
                    })
                }else if(tokenSso!== undefined){
                    dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                        if(!share_query_state_ref.current?.hasError){
                            querySharedWith()
                            setSelect([])
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        }else{
                            toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                        }
                    })
                }
                }else if(file!==""&&searchTerm===""){
                    const data = {
                        "title":title,
                        "query":{
                            "category":props?.category,
                            "qfile":file_path_eval,
                            "org_name" : results_state?.evalSource,
                            "region" : results_state?.evalRegion,
                            "country" : results_state?.evalCountry,
                            "sector" : results_state?.evalSector,
                            "topic" : results_state?.evalTopic,
                            "start_date" : results_state?.evalSdate,
                            "end_date" : results_state?.evalEdate,                  
                        },
                        "category" : props?.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "evalfilterarrayregion" : filterServiceEval?.eval_region_filter,
                            "evalhandlerregion" : filterServiceEval?.eval_region_handler,
                            "evaldataregion" : filterServiceEval?.eval_region_data,
                            "evalfilterregion": results_state?.evalRegion,
                            
                            "evalfilterarrayorg" : filterServiceEval?.eval_org_filter,
                            "evalhandlerorg" : filterServiceEval?.eval_org_handler,
                            "evaldataorg" : filterServiceEval?.eval_org_data,
                            "evalfilterorg" : results_state?.evalSource,
            
                            "evalfilterarraycountry" : filterServiceEval?.eval_country_filter,
                            "evalhandlercountry" : filterServiceEval?.eval_country_handler,
                            "evaldatacountry" : filterServiceEval?.eval_country_data,
                            "evalfiltercountry" : results_state?.evalCountry,
            
                            "evalfilterarraytopic" :filterServiceEval?.eval_topic_filter,
                            "evalhandlertopic" : filterServiceEval?.eval_topic_handler,
                            "evaldatatopic" : filterServiceEval?.eval_topic_data,
                            "evalfiltertopic" : results_state?.evalTopic,
            
                            "evalfilterarraysector" : filterServiceEval?.eval_sector_filter,
                            "evalhandlersector" : filterServiceEval?.eval_sector_handler,
                            "evaldatasector" : filterServiceEval?.eval_sector_data,
                            "evalfiltersector" : results_state?.evalSector,
            
                            "evalstartdate" : results_state?.evalSdate,
                            "evalenddate" : results_state?.evalEdate,
                            "evalSdate" : filterServiceEval?.eval_start_date_handler,
                            "evalEdate" : filterServiceEval?.eval_end_date_handler
                        }
                
                    }  
                    if(token!==undefined){
                        dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                            if(!share_query_state_ref.current?.hasError){
                                querySharedWith()
                                setSelect([])
                                if(!errorShareQuery){
                                    setClicked(!clicked)
                                    setSelect([])
                                }
                            }else{
                                toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                            }
                        })
                    }else if(tokenSso!== undefined){
                        dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                            if(!share_query_state_ref.current?.hasError){
                                querySharedWith()
                                setSelect([])
                                if(!errorShareQuery){
                                    setClicked(!clicked)
                                    setSelect([])
                                }
                            }else{
                                toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                            }
                        })
                    }
                }
            }else if(props?.category==="experts"){
                if(searchTerm!==""&&file===""){
                const data = {
                    "title":title,
                    "query":{
                        "category":props?.category,
                        "qterm":searchTerm,
                        "org_name" : results_state?.source,
                        "region" : results_state?.region,
                        "country" : results_state?.country,
                        "sector" : results_state?.sector,
                        "topic" : results_state?.topic,
                        "ignore_query" : filterServiceExperts?.experts_ignore_query
                    },
                    "category" :props?.category,
                    "users" : userValues,
                    "filter_parameters" : {
                        "expertsfilterarrayregion" : filterServiceExperts?.experts_region_filter,
                        "expertshandlerregion" : filterServiceExperts?.experts_region_handler,
                        "expertsdataregion" : filterServiceExperts?.experts_region_data,
                        "expertsfilterregion": results_state?.region,
                        
                        "expertsfilterarrayorg" : filterServiceExperts?.experts_org_filter,
                        "expertshandlerorg" : filterServiceExperts?.experts_org_handler,
                        "expertsdataorg" : filterServiceExperts?.experts_org_data,
                        "expertsfilterorg" : results_state?.source,
        
                        "expertsfilterarraycountry" : filterServiceExperts?.experts_country_filter,
                        "expertshandlercountry" : filterServiceExperts?.experts_country_handler,
                        "expertsdatacountry" : filterServiceExperts?.experts_country_data,
                        "expertsfiltercountry" : results_state?.country,
        
                        "expertsfilterarraytopic" :filterServiceExperts?.experts_topic_filter,
                        "expertshandlertopic" : filterServiceExperts?.experts_topic_handler,
                        "expertsdatatopic" : filterServiceExperts?.experts_topic_data,
                        "expertsfiltertopic" : results_state?.topic,
        
                        "expertsfilterarraysector" : filterServiceExperts?.experts_sector_filter,
                        "expertshandlersector" : filterServiceExperts?.experts_sector_handler,
                        "expertsdatasector" : filterServiceExperts?.experts_sector_data,
                        "expertsfiltersector" : results_state?.sector,  
                        
                        "ignore_query" : filterServiceExperts?.experts_ignore_query
                    }
            
                }  
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                        if(!share_query_state_ref.current?.hasError){
                            querySharedWith()
                            setSelect([])
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        }else{
                            toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                        }
                    })
                }else if(tokenSso!== undefined){
                    dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                        if(!share_query_state_ref.current?.hasError){
                            querySharedWith()
                            setSelect([])
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        }else{
                            toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                        }
                    })
                }
            }else if(file!==""&&searchTerm===""){
                const data = {
                    "title":title,
                    "query":{
                        "category":props?.category,
                        "qfile":file_path_experts,
                        "org_name" : results_state?.source,
                        "region" : results_state?.region,
                        "country" : results_state?.country,
                        "sector" : results_state?.sector,
                        "topic" : results_state?.topic,    
                        "ignore_query" : filterServiceExperts?.experts_ignore_query          
                    },
                    "category" : props?.category,
                    "users" : userValues,
                    "filter_parameters" : {
                        "expertsfilterarrayregion" : filterServiceExperts?.experts_region_filter,
                        "expertshandlerregion" : filterServiceExperts?.experts_region_handler,
                        "expertsdataregion" : filterServiceExperts?.experts_region_data,
                        "expertsfilterregion": results_state?.region,
                        
                        "expertsfilterarrayorg" : filterServiceExperts?.experts_org_filter,
                        "expertshandlerorg" : filterServiceExperts?.experts_org_handler,
                        "expertsdataorg" : filterServiceExperts?.experts_org_data,
                        "expertsfilterorg" : results_state?.source,
        
                        "expertsfilterarraycountry" : filterServiceExperts?.experts_country_filter,
                        "expertshandlercountry" : filterServiceExperts?.experts_country_handler,
                        "expertsdatacountry" : filterServiceExperts?.experts_country_data,
                        "expertsfiltercountry" : results_state?.country,
        
                        "expertsfilterarraytopic" :filterServiceExperts?.experts_topic_filter,
                        "expertshandlertopic" : filterServiceExperts?.experts_topic_handler,
                        "expertsdatatopic" : filterServiceExperts?.experts_topic_data,
                        "expertsfiltertopic" : results_state?.topic,
        
                        "expertsfilterarraysector" : filterServiceExperts?.experts_sector_filter,
                        "expertshandlersector" : filterServiceExperts?.experts_sector_handler,
                        "expertsdatasector" : filterServiceExperts?.experts_sector_data,
                        "expertsfiltersector" : results_state?.sector,
        
                        "ignore_query" : filterServiceExperts?.experts_ignore_query
                    }
            
                }  
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                        if(!share_query_state_ref.current?.hasError){
                            querySharedWith()
                            setSelect([])
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        }else{
                            toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                        }
                    })
                }else if(tokenSso!== undefined){
                    dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                        if(!share_query_state_ref.current?.hasError){
                            querySharedWith()
                            setSelect([])
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        }else{
                            toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                        }
                    })
                }
                }
            }else if(props?.category==="econpubs"){
                if(searchTerm!==""&&file===""){
                const data = {
                    "title":title,
                    "query":{
                        "category":props?.category,
                        "qterm":searchTerm,
                        "org_name" : results_state?.econSource,
                        "region" : results_state?.econRegion,
                        "country" : results_state?.econCountry,
                        "sector" : results_state?.econSector,
                        "topic" : results_state?.econTopic,
                        "ignore_query" : filterServiceEcon?.econ_ignore_query,
                        "start_date" : results_state?.econSdate,
                        "end_date" : results_state?.econEdate,   
                    },
                    "category" : props?.category,
                    "users" : userValues,
                    "filter_parameters" : {
                        "econfilterarrayregion" : filterServiceEcon?.econ_region_filter,
                        "econhandlerregion" : filterServiceEcon?.econ_region_handler,
                        "econdataregion" : filterServiceEcon?.econ_region_data,
                        "econfilterregion": results_state?.econRegion,
                        
                        "econfilterarrayorg" : filterServiceEcon?.econ_org_filter,
                        "econhandlerorg" : filterServiceEcon?.econ_org_handler,
                        "econdataorg" : filterServiceEcon?.econ_org_data,
                        "econfilterorg" : results_state?.econSource,
        
                        "econfilterarraycountry" : filterServiceEcon?.econ_country_filter,
                        "econhandlercountry" : filterServiceEcon?.econ_country_handler,
                        "econdatacountry" : filterServiceEcon?.econ_country_data,
                        "econfiltercountry" : results_state?.econCountry,
        
                        "econfilterarraytopic" :filterServiceEcon?.econ_topic_filter,
                        "econhandlertopic" : filterServiceEcon?.econ_topic_handler,
                        "econdatatopic" : filterServiceEcon?.econ_topic_data,
                        "econfiltertopic" : results_state?.econTopic,
        
                        "econfilterarraysector" : filterServiceEcon?.econ_sector_filter,
                        "econhandlersector" : filterServiceEcon?.econ_sector_handler,
                        "econdatasector" : filterServiceEcon?.econ_sector_data,
                        "econfiltersector" : results_state?.econSector,  
                        
                        "ignore_query" : filterServiceEcon?.econ_ignore_query,
                        "econinitialcountry":filterServiceEcon?.econ_filter_popup_countries,
                        "start_date" : results_state?.econSdate,
                        "end_date" : results_state?.econEdate,   
                        "econSdate" : filterServiceEcon?.econ_start_date_handler,
                        "econEdate" : filterServiceEcon?.econ_end_date_handler
                    }
            
                }  
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                        if(!share_query_state_ref.current?.hasError){
                            querySharedWith()
                            setSelect([])
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        }else{
                            toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                        }
                    })
                }else if(tokenSso!== undefined){
                    dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                        if(!share_query_state_ref.current?.hasError){
                            querySharedWith()
                            setSelect([])
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        }else{
                            toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                        }
                    })
                }
                }else if(file!==""&&searchTerm===""){
                    const data = {
                        "title":title,
                        "query":{
                            "category":props?.category,
                            "qfile":file_path_econ,
                            "org_name" : results_state?.econSource,
                            "region" : results_state?.econRegion,
                            "country" : results_state?.econCountry,
                            "sector" : results_state?.econSector,
                            "topic" : results_state?.econTopic,
                            "ignore_query" : filterServiceEcon?.econ_ignore_query,
                        "start_date" : results_state?.econSdate,
                        "end_date" : results_state?.econEdate,   
                        },
                        "category" : props?.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "econfilterarrayregion" : filterServiceEcon?.econ_region_filter,
                            "econhandlerregion" : filterServiceEcon?.econ_region_handler,
                            "econdataregion" : filterServiceEcon?.econ_region_data,
                            "econfilterregion": results_state?.econRegion,
                            
                            "econfilterarrayorg" : filterServiceEcon?.econ_org_filter,
                            "econhandlerorg" : filterServiceEcon?.econ_org_handler,
                            "econdataorg" : filterServiceEcon?.econ_org_data,
                            "econfilterorg" : results_state?.econSource,
            
                            "econfilterarraycountry" : filterServiceEcon?.econ_country_filter,
                            "econhandlercountry" : filterServiceEcon?.econ_country_handler,
                            "econdatacountry" : filterServiceEcon?.econ_country_data,
                            "econfiltercountry" : results_state?.econCountry,
            
                            "econfilterarraytopic" :filterServiceEcon?.econ_topic_filter,
                            "econhandlertopic" : filterServiceEcon?.econ_topic_handler,
                            "econdatatopic" : filterServiceEcon?.econ_topic_data,
                            "econfiltertopic" : results_state?.econTopic,
            
                            "econfilterarraysector" : filterServiceEcon?.econ_sector_filter,
                            "econhandlersector" : filterServiceEcon?.econ_sector_handler,
                            "econdatasector" : filterServiceEcon?.econ_sector_data,
                            "econfiltersector" : results_state?.econSector,  
                            
                            "ignore_query" : filterServiceEcon?.econ_ignore_query,
                            "econinitialcountry":filterServiceEcon?.econ_filter_popup_countries,
                        "start_date" : results_state?.econSdate,
                        "end_date" : results_state?.econEdate,   
                        "econSdate" : filterServiceEcon?.econ_start_date_handler,
                        "econEdate" : filterServiceEcon?.econ_end_date_handler
                        }
                
                    }  
                    if(token!==undefined){
                        dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                            if(!share_query_state_ref.current?.hasError){
                                querySharedWith()
                                setSelect([])
                                if(!errorShareQuery){
                                    setClicked(!clicked)
                                    setSelect([])
                                }
                            }else{
                                toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                            }
                        })
                    }else if(tokenSso!== undefined){
                        dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                            if(!share_query_state_ref.current?.hasError){
                                querySharedWith()
                                setSelect([])
                                if(!errorShareQuery){
                                    setClicked(!clicked)
                                    setSelect([])
                                }
                            }else{
                                toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                            }
                        })
                    } 
                }
            }else if(props?.category==="news"){
                if(searchTerm!==""&&file===""){
                    const data = {
                    "title":title,
                    "query":{
                        "category":props?.category,
                        "qterm":searchTerm,
                    },
                    "category" : props?.category,   
                    "users" : userValues, 
                } 
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                        if(!share_query_state_ref.current?.hasError){
                            querySharedWith()
                            setSelect([])
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        }else{
                            toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                        }
                    })
                }else if(tokenSso!== undefined){
                    dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                        if(!share_query_state_ref.current?.hasError){
                            querySharedWith()
                            setSelect([])
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        }else{
                            toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                        }
                    })
                }
                }else if(file!==""&&searchTerm===""){
                    const data = {
                        "title":title,
                        "query":{
                            "category":props?.category,
                            "qfile":file_path_news
                        },
                        "category" : props?.category,   
                        "users" : userValues, 
                    } 
                    if(token!==undefined){
                        dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                            if(!share_query_state_ref.current?.hasError){
                                querySharedWith()
                                setSelect([])
                                if(!errorShareQuery){
                                    setClicked(!clicked)
                                    setSelect([])
                                }
                            }else{
                                toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                            }
                        })
                    }else if(tokenSso!== undefined){
                        dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                            if(!share_query_state_ref.current?.hasError){
                                querySharedWith()
                                setSelect([])
                                if(!errorShareQuery){
                                    setClicked(!clicked)
                                    setSelect([])
                                }
                            }else{
                                toast.error(share_query_state_ref?.current?.error?.response?.data?.message)
                            }
                        })
                    }
                }
            }                  
        }else if(sharedListMessage==="Query not shared to anyone"){
            if(props?.category==="financing"){
                if(searchTerm!==""&&file===""){
                    const data = {
                        "title":queryName,
                        "query":{
                            "category":props.category,
                            "qterm":searchTerm,
                            "org_name" : results_state?.financeSource,
                            "region" : results_state?.financeRegion,
                            "country" : results_state?.financeCountry,
                            "sector" : results_state?.financeSector,
                            "topic" : results_state?.financeTopic,
                            "start_date" : results_state?.financeSdate,
                            "end_date" : results_state?.financeEdate,  
                        },
                        "category":props.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "financefilterarrayregion" : filterService?.finance_region_filter,
                            "financehandlerregion" : filterService?.finance_region_handler,
                            "financedataregion" : filterService?.finance_region_data,
                            "financefilterregion": results_state?.financeRegion,
                            
                            "financefilterarrayorg" : filterService?.finance_org_filter,
                            "financehandlerorg" : filterService?.finanace_org_handler,
                            "financedataorg" : filterService?.finance_org_data,
                            "financefilterorg" : results_state?.financeSource,
        
                            "financefilterarraycountry" : filterService?.finance_country_filter,
                            "financehandlercountry" : filterService?.finance_country_handler,
                            "financedatacountry" : filterService?.finance_country_data,
                            "financefiltercountry" : results_state?.financeCountry,
        
                            "financefilterarraytopic" :filterService?.finance_topic_filter,
                            "financehandlertopic" : filterService?.finance_topic_handler,
                            "financedatatopic" : filterService?.finance_topic_data,
                            "financefiltertopic" : results_state?.financeTopic,
        
                            "financefilterarraysector" : filterService?.finance_sector_filter,
                            "financehandlersector" : filterService?.finance_sector_handler,
                            "financedatasector" : filterService?.finance_sector_data,
                            "financefiltersector" : results_state?.financeSector,
        
                            "financestartdate" : results_state?.financeSdate,
                            "financeenddate" : results_state?.financeEdate,
                            "financeSdate" : filterService?.finance_start_date_handler,
                            "financeEdate" : filterService?.finance_end_date_handler
                        }
                        
                
                    }  
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                        querySharedWith()
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }else if(tokenSso!== undefined){
                    dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                        querySharedWith()
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }
                }else if(file!==""&&searchTerm===""){
                    const data = {
                        "title":queryName,
                        "query":{
                            "category":props.category,
                            "qfile":file_path_fin,
                            "org_name" : results_state?.financeSource,
                            "region" : results_state?.financeRegion,
                            "country" : results_state?.financeCountry,
                            "sector" : results_state?.financeSector,
                            "topic" : results_state?.financeTopic,
                            "start_date" : results_state?.financeSdate,
                            "end_date" : results_state?.financeEdate,  
                        },
                        "category":props.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "financefilterarrayregion" : filterService?.finance_region_filter,
                            "financehandlerregion" : filterService?.finance_region_handler,
                            "financedataregion" : filterService?.finance_region_data,
                            "financefilterregion": results_state?.financeRegion,
                            
                            "financefilterarrayorg" : filterService?.finance_org_filter,
                            "financehandlerorg" : filterService?.finanace_org_handler,
                            "financedataorg" : filterService?.finance_org_data,
                            "financefilterorg" : results_state?.financeSource,
        
                            "financefilterarraycountry" : filterService?.finance_country_filter,
                            "financehandlercountry" : filterService?.finance_country_handler,
                            "financedatacountry" : filterService?.finance_country_data,
                            "financefiltercountry" : results_state?.financeCountry,
        
                            "financefilterarraytopic" :filterService?.finance_topic_filter,
                            "financehandlertopic" : filterService?.finance_topic_handler,
                            "financedatatopic" : filterService?.finance_topic_data,
                            "financefiltertopic" : results_state?.financeTopic,
        
                            "financefilterarraysector" : filterService?.finance_sector_filter,
                            "financehandlersector" : filterService?.finance_sector_handler,
                            "financedatasector" : filterService?.finance_sector_data,
                            "financefiltersector" : results_state?.financeSector,
        
                            "financestartdate" : results_state?.financeSdate,
                            "financeenddate" : results_state?.financeEdate,
                            "financeSdate" : filterService?.finance_start_date_handler,
                            "financeEdate" : filterService?.finance_end_date_handler
                        }
                
                    }  
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                        querySharedWith()
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }else if(tokenSso!== undefined){
                    dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                        querySharedWith()
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }
                }
                
                
            }else if(props?.category==="devpubs"){
                if(searchTerm!==""&&file===""){
                    const data = {
                        "title":queryName,
                        "query":{
                            "category":props.category,
                            "qterm":searchTerm,
                            "org_name" : results_state?.devSource,
                            "region" : results_state?.devRegion,
                            "country" : results_state?.devCountry,
                            "sector" : results_state?.devSector,
                            "topic" : results_state?.devTopic,
                            "start_date" : results_state?.devSdate,
                            "end_date" : results_state?.devEdate,    
                        },
                        "category":props.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "devfilterarrayregion" : filterServiceDev?.dev_region_filter,
                            "devhandlerregion" : filterServiceDev?.dev_region_handler,
                            "devdataregion" : filterServiceDev?.dev_region_data,
                            "devfilterregion": results_state?.devRegion,
                            
                            "devfilterarrayorg" : filterServiceDev?.dev_org_filter,
                            "devhandlerorg" : filterServiceDev?.dev_org_handler,
                            "devdataorg" : filterServiceDev?.dev_org_data,
                            "devfilterorg" : results_state?.devSource,
        
                            "devfilterarraycountry" : filterServiceDev?.dev_country_filter,
                            "devhandlercountry" : filterServiceDev?.dev_country_handler,
                            "devdatacountry" : filterServiceDev?.dev_country_data,
                            "devfiltercountry" : results_state?.devCountry,
        
                            "devfilterarraytopic" :filterServiceDev?.dev_topic_filter,
                            "devhandlertopic" : filterServiceDev?.dev_topic_handler,
                            "devdatatopic" : filterServiceDev?.dev_topic_data,
                            "devfiltertopic" : results_state?.devTopic,
        
                            "devfilterarraysector" : filterServiceDev?.dev_sector_filter,
                            "devhandlersector" : filterServiceDev?.dev_sector_handler,
                            "devdatasector" : filterServiceDev?.dev_sector_data,
                            "devfiltersector" : results_state?.devSector,
        
                            "devstartdate" : results_state?.devSdate,
                            "devenddate" : results_state?.devEdate,
                            "devSdate" : filterServiceDev?.dev_start_date_handler,
                            "devEdate" : filterServiceDev?.dev_end_date_handler
                        }
                
                    }  
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                        querySharedWith()
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }else if(tokenSso!== undefined){
                    dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                        querySharedWith()
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }
                } else if(file!==""&&searchTerm===""){
                    const data = {
                        "title":queryName,
                        "query":{
                            "category":props.category,
                            "qfile":file_path_dev,
                            "org_name" : results_state?.devSource,
                            "region" : results_state?.devRegion,
                            "country" : results_state?.devCountry,
                            "sector" : results_state?.devSector,
                            "topic" : results_state?.devTopic,
                            "start_date" : results_state?.devSdate,
                            "end_date" : results_state?.devEdate,    
                        },
                        "category":props.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "devfilterarrayregion" : filterServiceDev?.dev_region_filter,
                            "devhandlerregion" : filterServiceDev?.dev_region_handler,
                            "devdataregion" : filterServiceDev?.dev_region_data,
                            "devfilterregion": results_state?.devRegion,
                            
                            "devfilterarrayorg" : filterServiceDev?.dev_org_filter,
                            "devhandlerorg" : filterServiceDev?.dev_org_handler,
                            "devdataorg" : filterServiceDev?.dev_org_data,
                            "devfilterorg" : results_state?.devSource,
        
                            "devfilterarraycountry" : filterServiceDev?.dev_country_filter,
                            "devhandlercountry" : filterServiceDev?.dev_country_handler,
                            "devdatacountry" : filterServiceDev?.dev_country_data,
                            "devfiltercountry" : results_state?.devCountry,
        
                            "devfilterarraytopic" :filterServiceDev?.dev_topic_filter,
                            "devhandlertopic" : filterServiceDev?.dev_topic_handler,
                            "devdatatopic" : filterServiceDev?.dev_topic_data,
                            "devfiltertopic" : results_state?.devTopic,
        
                            "devfilterarraysector" : filterServiceDev?.dev_sector_filter,
                            "devhandlersector" : filterServiceDev?.dev_sector_handler,
                            "devdatasector" : filterServiceDev?.dev_sector_data,
                            "devfiltersector" : results_state?.devSector,
        
                            "devstartdate" : results_state?.devSdate,
                            "devenddate" : results_state?.devEdate,
                            "devSdate" : filterServiceDev?.dev_start_date_handler,
                            "devEdate" : filterServiceDev?.dev_end_date_handler
                        }
                
                    }  
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                        querySharedWith()
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }else if(tokenSso!== undefined){
                    dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                        querySharedWith()
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }
                }
            }else if(props?.category==="otherpubs"){
                if(searchTerm!==""&&file===""){
                    const data = {
                        "title":queryName,
                        "query":{
                            "category":props.category,
                            "qterm":searchTerm,
                            "org_name" : results_state?.otherSource,
                            "region" : results_state?.otherRegion,
                            "country" : results_state?.otherCountry,
                            "sector" : results_state?.otherSector,
                            "topic" : results_state?.otherTopic,
                            "start_date" : results_state?.otherSdate,
                            "end_date" : results_state?.otherEdate,  
                        },
                        "category":props.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "otherfilterarrayregion" : filterServiceOther?.other_region_filter,
                            "otherhandlerregion" : filterServiceOther?.other_region_handler,
                            "otherdataregion" : filterServiceOther?.other_region_data,
                            "otherfilterregion": results_state?.otherRegion,
                            
                            "otherfilterarrayorg" : filterServiceOther?.other_org_filter,
                            "otherhandlerorg" : filterServiceOther?.other_org_handler,
                            "otherdataorg" : filterServiceOther?.other_org_data,
                            "otherfilterorg" : results_state?.otherSource,
        
                            "otherfilterarraycountry" : filterServiceOther?.other_country_filter,
                            "otherhandlercountry" : filterServiceOther?.other_country_handler,
                            "otherdatacountry" : filterServiceOther?.other_country_data,
                            "otherfiltercountry" : results_state?.otherCountry,
        
                            "otherfilterarraytopic" :filterServiceOther?.other_topic_filter,
                            "otherhandlertopic" : filterServiceOther?.other_topic_handler,
                            "otherdatatopic" : filterServiceOther?.other_topic_data,
                            "otherfiltertopic" : results_state?.otherTopic,
        
                            "otherfilterarraysector" : filterServiceOther?.other_sector_filter,
                            "otherhandlersector" : filterServiceOther?.other_sector_handler,
                            "otherdatasector" : filterServiceOther?.other_sector_data,
                            "otherfiltersector" : results_state?.otherSector,
        
                            "otherstartdate" : results_state?.otherSdate,
                            "otherenddate" : results_state?.otherEdate,
                            "otherSdate" : filterServiceOther?.other_start_date_handler,
                            "otherEdate" : filterServiceOther?.other_end_date_handler
                        }
                
                    }  
                    if(token!==undefined){
                        dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                            querySharedWith()
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        })
                    }else if(tokenSso!== undefined){
                        dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                            querySharedWith()
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        })
                    }
                }else if(file!==""&&searchTerm===""){
                    const data = {
                        "title":queryName,
                        "query":{
                            "category":props.category,
                            "qterm":searchTerm,
                            "org_name" : results_state?.otherSource,
                            "region" : results_state?.otherRegion,
                            "country" : results_state?.otherCountry,
                            "sector" : results_state?.otherSector,
                            "topic" : results_state?.otherTopic,
                            "start_date" : results_state?.otherSdate,
                            "end_date" : results_state?.otherEdate,  
                        },
                        "category":props.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "otherfilterarrayregion" : filterServiceOther?.other_region_filter,
                            "otherhandlerregion" : filterServiceOther?.other_region_handler,
                            "otherdataregion" : filterServiceOther?.other_region_data,
                            "otherfilterregion": results_state?.otherRegion,
                            
                            "otherfilterarrayorg" : filterServiceOther?.other_org_filter,
                            "otherhandlerorg" : filterServiceOther?.other_org_handler,
                            "otherdataorg" : filterServiceOther?.other_org_data,
                            "otherfilterorg" : results_state?.otherSource,
        
                            "otherfilterarraycountry" : filterServiceOther?.other_country_filter,
                            "otherhandlercountry" : filterServiceOther?.other_country_handler,
                            "otherdatacountry" : filterServiceOther?.other_country_data,
                            "otherfiltercountry" : results_state?.otherCountry,
        
                            "otherfilterarraytopic" :filterServiceOther?.other_topic_filter,
                            "otherhandlertopic" : filterServiceOther?.other_topic_handler,
                            "otherdatatopic" : filterServiceOther?.other_topic_data,
                            "otherfiltertopic" : results_state?.otherTopic,
        
                            "otherfilterarraysector" : filterServiceOther?.other_sector_filter,
                            "otherhandlersector" : filterServiceOther?.other_sector_handler,
                            "otherdatasector" : filterServiceOther?.other_sector_data,
                            "otherfiltersector" : results_state?.otherSector,
        
                            "otherstartdate" : results_state?.otherSdate,
                            "otherenddate" : results_state?.otherEdate,
                            "otherSdate" : filterServiceOther?.other_start_date_handler,
                            "otherEdate" : filterServiceOther?.other_end_date_handler
                        }
                
                    }
                    if(token!==undefined){
                        dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                            querySharedWith()
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        })
                    }else if(tokenSso!== undefined){
                        dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                            querySharedWith()
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        })
                    }
                }
            }else if(props?.category==="data"){
                if(searchTerm!==""&&file===""){
                    const data = {
                        "title":queryName,
                        "query":{
                            "category":props?.category,
                            "qterm":searchTerm,
                            "org_name" : results_state?.dataSource,
                            "region" : results_state?.dataRegion,
                            "country" : results_state?.dataCountry,
                            "sector" : results_state?.dataSector,
                            "topic" : results_state?.dataTopic,
                            "resource" :results_state?.dataResource                   
                        },
                        "category" : props?.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "datafilterarrayregion" : filterServiceData?.data_region_filter,
                            "datahandlerregion" : filterServiceData?.data_region_handler,
                            "datadataregion" : filterServiceData?.data_region_data,
                            "datafilterregion": results_state?.dataRegion,
                            
                            "datafilterarrayorg" : filterServiceData?.data_org_filter,
                            "datahandlerorg" : filterServiceData?.data_org_handler,
                            "datadataorg" : filterServiceData?.data_org_data,
                            "datafilterorg" : results_state?.dataSource,
        
                            "datafilterarraycountry" : filterServiceData?.data_country_filter,
                            "datahandlercountry" : filterServiceData?.data_country_handler,
                            "datadatacountry" : filterServiceData?.data_country_data,
                            "datafiltercountry" : results_state?.dataCountry,
        
                            "datafilterarraytopic" :filterServiceData?.data_topic_filter,
                            "datahandlertopic" : filterServiceData?.data_topic_handler,
                            "datadatatopic" : filterServiceData?.data_topic_data,
                            "datafiltertopic" : results_state?.dataTopic,
        
                            "datafilterarraysector" : filterServiceData?.data_sector_filter,
                            "datahandlersector" : filterServiceData?.data_sector_handler,
                            "datadatasector" : filterServiceData?.data_sector_data,
                            "datafiltersector" : results_state?.dataSector,
        
                            "datafilterarrayresource" : filterServiceData?.data_resource_filter,
                            "datahandlerresource" : filterServiceData?.data_resource_handler,
                            "datadataresource" : filterServiceData?.data_resource_data,
                            "datafilterresource": results_state?.dataresource,
                        }
                
                    } 
                    if(token!==undefined){
                        dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                            querySharedWith()
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        })
                    }else if(tokenSso!== undefined){
                        dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                            querySharedWith()
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        })
                    } 
                }else if(file!==""&&searchTerm===""){
                    const data = {
                        "title":queryName,
                        "query":{
                            "category":props?.category,
                            "qfile":file_path_data,
                            "org_name" : results_state?.dataSource,
                            "region" : results_state?.dataRegion,
                            "country" : results_state?.dataCountry,
                            "sector" : results_state?.dataSector,
                            "topic" : results_state?.dataTopic,
                            "resource" :results_state?.dataResource                   
                        },
                        "category" : props?.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "datafilterarrayregion" : filterServiceData?.data_region_filter,
                            "datahandlerregion" : filterServiceData?.data_region_handler,
                            "datadataregion" : filterServiceData?.data_region_data,
                            "datafilterregion": results_state?.dataRegion,
                            
                            "datafilterarrayorg" : filterServiceData?.data_org_filter,
                            "datahandlerorg" : filterServiceData?.data_org_handler,
                            "datadataorg" : filterServiceData?.data_org_data,
                            "datafilterorg" : results_state?.dataSource,
        
                            "datafilterarraycountry" : filterServiceData?.data_country_filter,
                            "datahandlercountry" : filterServiceData?.data_country_handler,
                            "datadatacountry" : filterServiceData?.data_country_data,
                            "datafiltercountry" : results_state?.dataCountry,
        
                            "datafilterarraytopic" :filterServiceData?.data_topic_filter,
                            "datahandlertopic" : filterServiceData?.data_topic_handler,
                            "datadatatopic" : filterServiceData?.data_topic_data,
                            "datafiltertopic" : results_state?.dataTopic,
        
                            "datafilterarraysector" : filterServiceData?.data_sector_filter,
                            "datahandlersector" : filterServiceData?.data_sector_handler,
                            "datadatasector" : filterServiceData?.data_sector_data,
                            "datafiltersector" : results_state?.dataSector,
        
                            "datafilterarrayresource" : filterServiceData?.data_resource_filter,
                            "datahandlerresource" : filterServiceData?.data_resource_handler,
                            "datadataresource" : filterServiceData?.data_resource_data,
                            "datafilterresource": results_state?.dataresource,
                        }
                
                    } 
                    if(token!==undefined){
                        dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                            querySharedWith()
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        })
                    }else if(tokenSso!== undefined){
                        dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                            querySharedWith()
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        })
                    } 
                }
            }else if(props?.category==="multimedia"){
                if(searchTerm!==""&&file===""){
                    const data = {
                        "title":queryName,
                                        "query":{
                                            "category":props?.category,
                                            "qterm":searchTerm,
                                            "org_name" : results_state?.mulSource,
                                            "region" : results_state?.mulRegion,
                                            "country" : results_state?.mulCountry,
                                            "sector" : results_state?.mulSector,
                                            "topic" : results_state?.mulTopic,
                                            "start_date" : results_state?.mulSdate,
                                            "end_date" : results_state?.mulEdate,                 
                                        },
                                        "category" : props?.category,
                                        "users" : userValues,
                                        "filter_parameters" : {
                                            "mulfilterarrayregion" : filterServiceMul?.mul_region_filter,
                                            "mulhandlerregion" : filterServiceMul?.mul_region_handler,
                                            "muldataregion" : filterServiceMul?.mul_region_data,
                                            "mulfilterregion": results_state?.mulRegion,
                                            
                                            "mulfilterarrayorg" : filterServiceMul?.mul_org_filter,
                                            "mulhandlerorg" : filterServiceMul?.mul_org_handler,
                                            "muldataorg" : filterServiceMul?.mul_org_data,
                                            "mulfilterorg" : results_state?.mulSource,
                        
                                            "mulfilterarraycountry" : filterServiceMul?.mul_country_filter,
                                            "mulhandlercountry" : filterServiceMul?.mul_country_handler,
                                            "muldatacountry" : filterServiceMul?.mul_country_data,
                                            "mulfiltercountry" : results_state?.mulCountry,
                        
                                            "mulfilterarraytopic" :filterServiceMul?.mul_topic_filter,
                                            "mulhandlertopic" : filterServiceMul?.mul_topic_handler,
                                            "muldatatopic" : filterServiceMul?.mul_topic_data,
                                            "mulfiltertopic" : results_state?.mulTopic,
                        
                                            "mulfilterarraysector" : filterServiceMul?.mul_sector_filter,
                                            "mulhandlersector" : filterServiceMul?.mul_sector_handler,
                                            "muldatasector" : filterServiceMul?.mul_sector_data,
                                            "mulfiltersector" : results_state?.mulSector,
                        
                                            "mulstartdate" : results_state?.mulSdate,
                                            "mulenddate" : results_state?.mulEdate,
                                            "mulSdate" : filterServiceMul?.mul_start_date_handler,
                                            "mulEdate" : filterServiceMul?.mul_end_date_handler
                                        }
                                
                                    }  
                                    if(token!==undefined){
                                        dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                                            querySharedWith()
                                            if(!errorShareQuery){
                                                setClicked(!clicked)
                                                setSelect([])
                                            }
                                        })
                                    }else if(tokenSso!== undefined){
                                        dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                                            querySharedWith()
                                            if(!errorShareQuery){
                                                setClicked(!clicked)
                                                setSelect([])
                                            }
                                        })
                                    }                 
                }else if(file!==""&&searchTerm===""){
                    const data = {
                        "title":queryName,
                        "query":{
                            "category":props?.category,
                            "qfile":file_path_mul,
                            "org_name" : results_state?.mulSource,
                            "region" : results_state?.mulRegion,
                            "country" : results_state?.mulCountry,
                            "sector" : results_state?.mulSector,
                            "topic" : results_state?.mulTopic,
                            "start_date" : results_state?.mulSdate,
                            "end_date" : results_state?.mulEdate,                 
                        },
                        "category" : props?.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "mulfilterarrayregion" : filterServiceMul?.mul_region_filter,
                            "mulhandlerregion" : filterServiceMul?.mul_region_handler,
                            "muldataregion" : filterServiceMul?.mul_region_data,
                            "mulfilterregion": results_state?.mulRegion,
                            
                            "mulfilterarrayorg" : filterServiceMul?.mul_org_filter,
                            "mulhandlerorg" : filterServiceMul?.mul_org_handler,
                            "muldataorg" : filterServiceMul?.mul_org_data,
                            "mulfilterorg" : results_state?.mulSource,
        
                            "mulfilterarraycountry" : filterServiceMul?.mul_country_filter,
                            "mulhandlercountry" : filterServiceMul?.mul_country_handler,
                            "muldatacountry" : filterServiceMul?.mul_country_data,
                            "mulfiltercountry" : results_state?.mulCountry,
        
                            "mulfilterarraytopic" :filterServiceMul?.mul_topic_filter,
                            "mulhandlertopic" : filterServiceMul?.mul_topic_handler,
                            "muldatatopic" : filterServiceMul?.mul_topic_data,
                            "mulfiltertopic" : results_state?.mulTopic,
        
                            "mulfilterarraysector" : filterServiceMul?.mul_sector_filter,
                            "mulhandlersector" : filterServiceMul?.mul_sector_handler,
                            "muldatasector" : filterServiceMul?.mul_sector_data,
                            "mulfiltersector" : results_state?.mulSector,
        
                            "mulstartdate" : results_state?.mulSdate,
                            "mulenddate" : results_state?.mulEdate,
                            "mulSdate" : filterServiceMul?.mul_start_date_handler,
                            "mulEdate" : filterServiceMul?.mul_end_date_handler
                        }
                
                    }  
                    if(token!==undefined){
                        dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                            querySharedWith()
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        })
                    }else if(tokenSso!== undefined){
                        dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                            querySharedWith()
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        })
                    } 
                }
            }else if(props?.category==="evaluations"){
                if(searchTerm!==""&&file===""){
                     const data = {
                    "title":queryName,
                    "query":{
                        "category":props?.category,
                        "qterm":searchTerm,
                        "org_name" : results_state?.evalSource,
                        "region" : results_state?.evalRegion,
                        "country" : results_state?.evalCountry,
                        "sector" : results_state?.evalSector,
                        "topic" : results_state?.evalTopic,
                        "start_date" : results_state?.evalSdate,
                        "end_date" : results_state?.evalEdate,                  
                    },
                    "category" : props?.category,
                    "users" : userValues,
                    "filter_parameters" : {
                        "evalfilterarrayregion" : filterServiceEval?.eval_region_filter,
                        "evalhandlerregion" : filterServiceEval?.eval_region_handler,
                        "evaldataregion" : filterServiceEval?.eval_region_data,
                        "evalfilterregion": results_state?.evalRegion,
                        
                        "evalfilterarrayorg" : filterServiceEval?.eval_org_filter,
                        "evalhandlerorg" : filterServiceEval?.eval_org_handler,
                        "evaldataorg" : filterServiceEval?.eval_org_data,
                        "evalfilterorg" : results_state?.evalSource,
        
                        "evalfilterarraycountry" : filterServiceEval?.eval_country_filter,
                        "evalhandlercountry" : filterServiceEval?.eval_country_handler,
                        "evaldatacountry" : filterServiceEval?.eval_country_data,
                        "evalfiltercountry" : results_state?.evalCountry,
        
                        "evalfilterarraytopic" :filterServiceEval?.eval_topic_filter,
                        "evalhandlertopic" : filterServiceEval?.eval_topic_handler,
                        "evaldatatopic" : filterServiceEval?.eval_topic_data,
                        "evalfiltertopic" : results_state?.evalTopic,
        
                        "evalfilterarraysector" : filterServiceEval?.eval_sector_filter,
                        "evalhandlersector" : filterServiceEval?.eval_sector_handler,
                        "evaldatasector" : filterServiceEval?.eval_sector_data,
                        "evalfiltersector" : results_state?.evalSector,
        
                        "evalstartdate" : results_state?.evalSdate,
                        "evalenddate" : results_state?.evalEdate,
                        "evalSdate" : filterServiceEval?.eval_start_date_handler,
                        "evalEdate" : filterServiceEval?.eval_end_date_handler
                    }
            
                }  
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                        querySharedWith()
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }else if(tokenSso!== undefined){
                    dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                        querySharedWith()
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }
                }else if(file!==""&&searchTerm===""){
                    const data = {
                        "title":queryName,
                        "query":{
                            "category":props?.category,
                            "qfile":file_path_eval,
                            "org_name" : results_state?.evalSource,
                            "region" : results_state?.evalRegion,
                            "country" : results_state?.evalCountry,
                            "sector" : results_state?.evalSector,
                            "topic" : results_state?.evalTopic,
                            "start_date" : results_state?.evalSdate,
                            "end_date" : results_state?.evalEdate,                  
                        },
                        "category" : props?.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "evalfilterarrayregion" : filterServiceEval?.eval_region_filter,
                            "evalhandlerregion" : filterServiceEval?.eval_region_handler,
                            "evaldataregion" : filterServiceEval?.eval_region_data,
                            "evalfilterregion": results_state?.evalRegion,
                            
                            "evalfilterarrayorg" : filterServiceEval?.eval_org_filter,
                            "evalhandlerorg" : filterServiceEval?.eval_org_handler,
                            "evaldataorg" : filterServiceEval?.eval_org_data,
                            "evalfilterorg" : results_state?.evalSource,
            
                            "evalfilterarraycountry" : filterServiceEval?.eval_country_filter,
                            "evalhandlercountry" : filterServiceEval?.eval_country_handler,
                            "evaldatacountry" : filterServiceEval?.eval_country_data,
                            "evalfiltercountry" : results_state?.evalCountry,
            
                            "evalfilterarraytopic" :filterServiceEval?.eval_topic_filter,
                            "evalhandlertopic" : filterServiceEval?.eval_topic_handler,
                            "evaldatatopic" : filterServiceEval?.eval_topic_data,
                            "evalfiltertopic" : results_state?.evalTopic,
            
                            "evalfilterarraysector" : filterServiceEval?.eval_sector_filter,
                            "evalhandlersector" : filterServiceEval?.eval_sector_handler,
                            "evaldatasector" : filterServiceEval?.eval_sector_data,
                            "evalfiltersector" : results_state?.evalSector,
            
                            "evalstartdate" : results_state?.evalSdate,
                            "evalenddate" : results_state?.evalEdate,
                            "evalSdate" : filterServiceEval?.eval_start_date_handler,
                            "evalEdate" : filterServiceEval?.eval_end_date_handler
                        }
                
                    }  
                    if(token!==undefined){
                        dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                            querySharedWith()
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        })
                    }else if(tokenSso!== undefined){
                        dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                            querySharedWith()
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        })
                    }
                }
            }else if(props?.category==="experts"){
                if(searchTerm!==""&&file===""){
                const data = {
                    "title":queryName,
                    "query":{
                        "category":props?.category,
                        "qterm":searchTerm,
                        "org_name" : results_state?.source,
                        "region" : results_state?.region,
                        "country" : results_state?.country,
                        "sector" : results_state?.sector,
                        "topic" : results_state?.topic,
                        "ignore_query" : filterServiceExperts?.experts_ignore_query
                    },
                    "category" :props?.category,
                    "users" : userValues,
                    "filter_parameters" : {
                        "expertsfilterarrayregion" : filterServiceExperts?.experts_region_filter,
                        "expertshandlerregion" : filterServiceExperts?.experts_region_handler,
                        "expertsdataregion" : filterServiceExperts?.experts_region_data,
                        "expertsfilterregion": results_state?.region,
                        
                        "expertsfilterarrayorg" : filterServiceExperts?.experts_org_filter,
                        "expertshandlerorg" : filterServiceExperts?.experts_org_handler,
                        "expertsdataorg" : filterServiceExperts?.experts_org_data,
                        "expertsfilterorg" : results_state?.source,
        
                        "expertsfilterarraycountry" : filterServiceExperts?.experts_country_filter,
                        "expertshandlercountry" : filterServiceExperts?.experts_country_handler,
                        "expertsdatacountry" : filterServiceExperts?.experts_country_data,
                        "expertsfiltercountry" : results_state?.country,
        
                        "expertsfilterarraytopic" :filterServiceExperts?.experts_topic_filter,
                        "expertshandlertopic" : filterServiceExperts?.experts_topic_handler,
                        "expertsdatatopic" : filterServiceExperts?.experts_topic_data,
                        "expertsfiltertopic" : results_state?.topic,
        
                        "expertsfilterarraysector" : filterServiceExperts?.experts_sector_filter,
                        "expertshandlersector" : filterServiceExperts?.experts_sector_handler,
                        "expertsdatasector" : filterServiceExperts?.experts_sector_data,
                        "expertsfiltersector" : results_state?.sector,  
                        
                        "ignore_query" : filterServiceExperts?.experts_ignore_query
                    }
            
                }  
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                        querySharedWith()
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }else if(tokenSso!== undefined){
                    dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                        querySharedWith()
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }
            }else if(file!==""&&searchTerm===""){
                const data = {
                    "title":queryName,
                    "query":{
                        "category":props?.category,
                        "qfile":file_path_experts,
                        "org_name" : results_state?.source,
                        "region" : results_state?.region,
                        "country" : results_state?.country,
                        "sector" : results_state?.sector,
                        "topic" : results_state?.topic,    
                        "ignore_query" : filterServiceExperts?.experts_ignore_query          
                    },
                    "category" : props?.category,
                    "users" : userValues,
                    "filter_parameters" : {
                        "expertsfilterarrayregion" : filterServiceExperts?.experts_region_filter,
                        "expertshandlerregion" : filterServiceExperts?.experts_region_handler,
                        "expertsdataregion" : filterServiceExperts?.experts_region_data,
                        "expertsfilterregion": results_state?.region,
                        
                        "expertsfilterarrayorg" : filterServiceExperts?.experts_org_filter,
                        "expertshandlerorg" : filterServiceExperts?.experts_org_handler,
                        "expertsdataorg" : filterServiceExperts?.experts_org_data,
                        "expertsfilterorg" : results_state?.source,
        
                        "expertsfilterarraycountry" : filterServiceExperts?.experts_country_filter,
                        "expertshandlercountry" : filterServiceExperts?.experts_country_handler,
                        "expertsdatacountry" : filterServiceExperts?.experts_country_data,
                        "expertsfiltercountry" : results_state?.country,
        
                        "expertsfilterarraytopic" :filterServiceExperts?.experts_topic_filter,
                        "expertshandlertopic" : filterServiceExperts?.experts_topic_handler,
                        "expertsdatatopic" : filterServiceExperts?.experts_topic_data,
                        "expertsfiltertopic" : results_state?.topic,
        
                        "expertsfilterarraysector" : filterServiceExperts?.experts_sector_filter,
                        "expertshandlersector" : filterServiceExperts?.experts_sector_handler,
                        "expertsdatasector" : filterServiceExperts?.experts_sector_data,
                        "expertsfiltersector" : results_state?.sector,
        
                        "ignore_query" : filterServiceExperts?.experts_ignore_query
                    }
            
                }  
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                        querySharedWith()
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }else if(tokenSso!== undefined){
                    dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                        querySharedWith()
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }
                }
            }else if(props?.category==="econpubs"){
                if(searchTerm!==""&&file===""){
                const data = {
                    "title":queryName,
                    "query":{
                        "category":props?.category,
                        "qterm":searchTerm,
                        "org_name" : results_state?.econSource,
                        "region" : results_state?.econRegion,
                        "country" : results_state?.econCountry,
                        "sector" : results_state?.econSector,
                        "topic" : results_state?.econTopic,
                        "ignore_query" : filterServiceEcon?.econ_ignore_query,
                        "start_date" : results_state?.econSdate,
                        "end_date" : results_state?.econEdate,   
                    },
                    "category" : props?.category,
                    "users" : userValues,
                    "filter_parameters" : {
                        "econfilterarrayregion" : filterServiceEcon?.econ_region_filter,
                        "econhandlerregion" : filterServiceEcon?.econ_region_handler,
                        "econdataregion" : filterServiceEcon?.econ_region_data,
                        "econfilterregion": results_state?.econRegion,
                        
                        "econfilterarrayorg" : filterServiceEcon?.econ_org_filter,
                        "econhandlerorg" : filterServiceEcon?.econ_org_handler,
                        "econdataorg" : filterServiceEcon?.econ_org_data,
                        "econfilterorg" : results_state?.econSource,
        
                        "econfilterarraycountry" : filterServiceEcon?.econ_country_filter,
                        "econhandlercountry" : filterServiceEcon?.econ_country_handler,
                        "econdatacountry" : filterServiceEcon?.econ_country_data,
                        "econfiltercountry" : results_state?.econCountry,
        
                        "econfilterarraytopic" :filterServiceEcon?.econ_topic_filter,
                        "econhandlertopic" : filterServiceEcon?.econ_topic_handler,
                        "econdatatopic" : filterServiceEcon?.econ_topic_data,
                        "econfiltertopic" : results_state?.econTopic,
        
                        "econfilterarraysector" : filterServiceEcon?.econ_sector_filter,
                        "econhandlersector" : filterServiceEcon?.econ_sector_handler,
                        "econdatasector" : filterServiceEcon?.econ_sector_data,
                        "econfiltersector" : results_state?.econSector,  
                        
                        "ignore_query" : filterServiceEcon?.econ_ignore_query,
                        "econinitialcountry":filterServiceEcon?.econ_filter_popup_countries,
                        "start_date" : results_state?.econSdate,
                        "end_date" : results_state?.econEdate,   
                        "econSdate" : filterServiceEcon?.econ_start_date_handler,
                        "econEdate" : filterServiceEcon?.econ_end_date_handler
                    }
            
                }  
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                        querySharedWith()
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }else if(tokenSso!== undefined){
                    dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                        querySharedWith()
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }
                }else if(file!==""&&searchTerm===""){
                    const data = {
                        "title":queryName,
                        "query":{
                            "category":props?.category,
                            "qfile":file_path_econ,
                            "org_name" : results_state?.econSource,
                            "region" : results_state?.econRegion,
                            "country" : results_state?.econCountry,
                            "sector" : results_state?.econSector,
                            "topic" : results_state?.econTopic,
                            "ignore_query" : filterServiceEcon?.econ_ignore_query,
                        "start_date" : results_state?.econSdate,
                        "end_date" : results_state?.econEdate,   
                        },
                        "category" : props?.category,
                        "users" : userValues,
                        "filter_parameters" : {
                            "econfilterarrayregion" : filterServiceEcon?.econ_region_filter,
                            "econhandlerregion" : filterServiceEcon?.econ_region_handler,
                            "econdataregion" : filterServiceEcon?.econ_region_data,
                            "econfilterregion": results_state?.econRegion,
                            
                            "econfilterarrayorg" : filterServiceEcon?.econ_org_filter,
                            "econhandlerorg" : filterServiceEcon?.econ_org_handler,
                            "econdataorg" : filterServiceEcon?.econ_org_data,
                            "econfilterorg" : results_state?.econSource,
            
                            "econfilterarraycountry" : filterServiceEcon?.econ_country_filter,
                            "econhandlercountry" : filterServiceEcon?.econ_country_handler,
                            "econdatacountry" : filterServiceEcon?.econ_country_data,
                            "econfiltercountry" : results_state?.econCountry,
            
                            "econfilterarraytopic" :filterServiceEcon?.econ_topic_filter,
                            "econhandlertopic" : filterServiceEcon?.econ_topic_handler,
                            "econdatatopic" : filterServiceEcon?.econ_topic_data,
                            "econfiltertopic" : results_state?.econTopic,
            
                            "econfilterarraysector" : filterServiceEcon?.econ_sector_filter,
                            "econhandlersector" : filterServiceEcon?.econ_sector_handler,
                            "econdatasector" : filterServiceEcon?.econ_sector_data,
                            "econfiltersector" : results_state?.econSector,  
                            
                            "ignore_query" : filterServiceEcon?.econ_ignore_query,
                            "econinitialcountry":filterServiceEcon?.econ_filter_popup_countries,
                        "start_date" : results_state?.econSdate,
                        "end_date" : results_state?.econEdate,  
                        "econSdate" : filterServiceEcon?.econ_start_date_handler,
                        "econEdate" : filterServiceEcon?.econ_end_date_handler 
                        }
                
                    }  
                    if(token!==undefined){
                        dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                            querySharedWith()
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        })
                    }else if(tokenSso!== undefined){
                        dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                            querySharedWith()
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        })
                    } 
                }
            }else if(props?.category==="news"){
                if(searchTerm!==""&&file===""){
                    const data = {
                    "title":queryName,
                    "query":{
                        "category":props?.category,
                        "qterm":searchTerm,
                    },
                    "category" : props?.category,   
                    "users" : userValues, 
                } 
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                        querySharedWith()
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }else if(tokenSso!== undefined){
                    dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                        querySharedWith()
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }
                }else if(file!==""&&searchTerm===""){
                    const data = {
                        "title":queryName,
                        "query":{
                            "category":props?.category,
                            "qfile":file_path_news
                        },
                        "category" : props?.category,   
                        "users" : userValues, 
                    } 
                    if(token!==undefined){
                        dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                            querySharedWith()
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        })
                    }else if(tokenSso!== undefined){
                        dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                            querySharedWith()
                            if(!errorShareQuery){
                                setClicked(!clicked)
                                setSelect([])
                            }
                        })
                    }
                }
            }   
            }
            
        else{
                const data = {
                    "title":sharedListMessage[0]?.title,
                    "query":
                        sharedListMessage[0]?.get_parameters
                    ,
                    "filter_parameters":sharedListMessage[0]?.filter_parameters,
                    "category" : sharedListMessage[0]?.category,
                    "users" : userValues
                }
                if(token!==undefined){
                    dispatch(shareSingleQuery(data,token,"True")).then(()=>{
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }else if(tokenSso!==undefined){
                    dispatch(shareSingleQuery(data,tokenSso,"True")).then(()=>{
                        if(!errorShareQuery){
                            setClicked(!clicked)
                            setSelect([])
                        }
                    })
                }
                
            
            
        }
      }



      const removeAccessUser =(id)=>{
        if(token!==undefined){
            return _removeAccessQuery(token,id).then(()=>{
                setDeleteUser(!deleteUser)
            })
        }else if(tokenSso!==undefined){
            return _removeAccessQuery(tokenSso,id).then(()=>{
                setDeleteUser(!deleteUser)
            })
        }
      }

      const [title,setTitle] = useState()
      


   useEffect(()=>{
    if(searchTerm!==""&&file===""){
        if(countWords(searchTerm)<4){
            setTitle(searchTerm)
        }else {
            setTitle(searchTerm?.slice(0,thirdOccurance(searchTerm)))
        }
        
    }else if(searchTerm===""&&file!==""){
        if (file?.path){
            setTitle(file?.path)
        }else{
            setTitle(resultTitle);
        }
        
    }
   },[searchTerm,file])

   const countWords =(str)=>{
    const arr = str?.split(' ');

    return arr.filter(word => word !== '').length;
   }
   const thirdOccurance =(str)=>{
    var pos1 = str?.indexOf(" ")
    var pos2 = str?.indexOf(" ",pos1+1)
    var pos3 = str?.indexOf(" ",pos2+1)
    return pos3
   }
      
    return (
        <div className="country-pop-items manage-access-popup">
            {loading ? <div> <div className="loader loader-3">
        <div className="dot dot1">&nbsp;</div>
        <div className="dot dot2">&nbsp;</div>
        <div className="dot dot3">&nbsp;</div>
    </div></div> :
            <div className="country-filter">
                <div className="selected-view-box-share">
                    {/* <h4 className="share-title">Share</h4> */}
                    <Formik
                    //  initialValues={{
                    //     title: title,
                    //   }}
                    //   onSubmit={shareQuery}
                    >
                         {({ handleSubmit, handleChange, values, errors, touched }) => (
                    <Form className="w-100" onSubmit={handleSubmit}>
                        <div className="country-header-search">
                            {sharedListMessage==="Query is not shared to anyone" || sharedListMessage==="Query not found for this user" ?
                        <div className="form-input-wrap m-b-30">
                                <Form.Label>Query name</Form.Label>
                                <Form.Control type="text" placeholder="Title....." className="input-item" value={title} name="title" onChange={(e)=>{
                                    setTitle(e.target.value)
                                }} />
                            </div>
                            :null}
                            <div className="form-input-wrap m-b-30">
                                <Form.Label>Username</Form.Label>
                                {/* <Form.Control type="text" placeholder="Username" className="input-item" /> */}
                                <ReactSelect
                        options={userList}
                        isMulti
                        closeMenuOnSelect={false}
                        blurInputOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                          Option,
                        }}
                        allowSelectAll={true}
                        classNamePrefix="react-select-2-option"
                        className="react-multi-select"
                        selected={select}
                        onChange={setSelect}
                        // values={formik.values.sector}
                        placeholder='Search and select'
                        name="userlist"
                        required
                       
                      />

                            </div>
                           
                        </div>
                        <div className="button-wrap double-btn d-flex justify-content-end m-t-20">
                    <Button
                    disabled={select?.length===0}
                    type='submit' onClick={(e)=>{
                        e.preventDefault()
                        shareQuery()
                    }}>Share</Button>
                    {!isSaved&&<Button disabled={select?.length===0} type='submit'
                    onClick={(e)=>{
                        e.preventDefault()
                        saveAndShareQuery()
                    }}>Save and share</Button>}
                </div>
                    </Form>
                     )}
                    </Formik>
                    {/* <ScrollArea
                        speed={0.8}
                        className="pop-selected-items-scroll"
                        contentClassName="content"
                        horizontal={false}
                    >
                        <div className="selected-item-show-wrap">
                            {[...Array(9)].map((index) => (
                                <div className="selected-item-show">
                                    <span className="selected-name">Paddy O'Furniture</span><span className="selected-close"><img src={CloseSm} alt="closearrow" /></span>
                                </div>
                            ))}
                        </div>
                    </ScrollArea> */}

                </div>
                {type!=="string"  ? <div className="access-box m-b-30">
                  
                        <div className="sharedwith-wrap">
                            <h4 className="share-title">Shared with</h4>
                            <ScrollArea
                        speed={0.8}
                        className="pop-checkboxscroll"
                        contentClassName="content"
                        horizontal={false}
                    >
                            <div className="shared-name-wrap d-flex">
                                {type!== "string" && sharedListMessage&& sharedListMessage.map((item,i) => (
                                    <div className="shared-name-box" key={i}>
                                        <div className="shared-name-box-item d-flex align-items-center justify-content-between">
                                            <div className="shared-access-left d-flex align-items-center">
                                                <div className="shared-img-wrap user-img">
                                                <img src={item?.profile_pic_path!==null?item?.profile_pic_path:ProfileDummy} />
                                                </div>
                                                <div className="shared-man-name">
                                                    {item?.username}
                                                </div>
                                            </div>
                                            <div className="shared-access-right">
                                                <div className="shared-remove-btn" style={{cursor:"pointer"}} onClick={()=>{
                                                        removeAccessUser(item?.id)
                                                        setSelect([])
                                                    }}>
                                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M2.62155 12.875C2.33822 12.875 2.0903 12.7687 1.8778 12.5562C1.6653 12.3437 1.55905 12.0958 1.55905 11.8125V1.71875H0.833008V0.65625H4.16217V0.125H8.83717V0.65625H12.1663V1.71875H11.4403V11.8125C11.4403 12.0958 11.334 12.3437 11.1215 12.5562C10.909 12.7687 10.6611 12.875 10.3778 12.875H2.62155ZM10.3778 1.71875H2.62155V11.8125H10.3778V1.71875ZM4.49863 10.2896H5.56113V3.22396H4.49863V10.2896ZM7.43822 10.2896H8.50072V3.22396H7.43822V10.2896ZM2.62155 1.71875V11.8125V1.71875Z" fill="#5B5B5B" />
                                                    </svg>
                                                    <span >Remove</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                ))}
                            </div>
                            </ScrollArea>
                        </div>
                   
                </div>
 :null}
                
                
               
            </div> }
        </div>
    )
}

export default ManageAccess;