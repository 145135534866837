import React,{useEffect} from 'react';
import LandContactUs from '../../landingpage/components/landcontactus/landcontactus';
import AboutusBanner from '../components/aboutusbanner/aboutusbanner';
import AboutusContent from '../components/aboutuscontents/aboutuscontents';
import {Helmet} from 'react-helmet'

function AboutUs() {

    return (
        <div className="landing-page-wrap">
            <Helmet>
                <title>
                DevDiscovery | About us
                </title>
            </Helmet>
            <section className="landing-section section-1">
                <AboutusBanner />
            </section>
            <section className="landing-section section-1">
                <AboutusContent />
            </section>
            <section className="landing-section section-3">
                <LandContactUs />
            </section>
        </div>
    )
}

export default AboutUs;