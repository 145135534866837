import React,{useState,useEffect} from 'react';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import PhoneInput,  { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber ,isPossiblePhoneNumber} from 'react-phone-number-input'
import {Formik} from 'formik'
import {_staticForm} from './../../../../../shared/httpservice/apis'
import {useSelector,shallowEqual} from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ContactSuccessImg from '../../../../../assets/images/contact-success.svg'
import { Modal, Alert } from "react-bootstrap";
const ButtonMailto = ({ mailto, label }) => {
    return (
        <Link
            to='#'
            onClick={(e) => {
                window.location.href = mailto;
                e.preventDefault();
            }}
        >
            {label}
        </Link>
    );
};


function SalesContent() {
    const [sho, setSho] = useState(false);
    const handleClos = () => setSho(false);
    const handleSho = () => setSho(true);
    const [value,setValue] = useState()
const token = useSelector((store)=>store?.auth?.signin?.response?.data?.data?.token,shallowEqual)
const tokenSso = useSelector(
    (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token,shallowEqual
  );
  const [err,setErr] = useState(false)
  useEffect(()=>{
    if(phoneError==="invalid"){
        setErr(true)
    }else{
        setErr(false)
    }
  },[phoneError])
const register = async (values,{resetForm}) => {
    const form = new FormData();
    form.append("message", values?.message);
    form.append("name", values?.name)
    form.append("email", values?.email)
    if(value&&err!==true){
        form.append("phone",value)
      }
    form.append("organisation_name",values?.org)
    form.append("job_title", values?.job)
    if(err!==true){
        return _staticForm(form).then((response)=>{
            console.log(response);
            if(response?.data?.status==="success"){
                handleSho()
                resetForm()
                setValue()
            }
        }).catch((error)=>{
            toast.error("something went wrong")
        })}
    
    
    }
    const validate =(values)=>{
       const errors={}
       if(!values?.message){
        errors.message = "*required"
       }
       const nameRegex=new RegExp("^[a-zA-Z 0-9 ]*$");
           if(!values?.name){
            errors.name = "*required"
           }else if(!nameRegex.test(values.name)){
            errors.name="*No special characters"
          }
       const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
       if(!values.email){
        errors.email="*please enter your email"
       }else if(!regex.test(values.email)){
        errors.email="*enter a valid email"
       }
       return errors
    }
    const [sub, setSub] = useState(false);
    // console.log(sub,"error");
    const [phoneError, setPhoneError] = useState("");
    // console.log(phoneError,"phone");
    const phoneErrors =()=>{
        if(value!==undefined){
            if(!isValidPhoneNumber(value) &&sub){
            setPhoneError("invalid");
        }else {
            setPhoneError()
        }
        }else{
            setPhoneError()
        }
    }
    useEffect(() => {
        if(value!==undefined){
            if(!isValidPhoneNumber(value)){
            setPhoneError("invalid");
        }else{
            setPhoneError()
        }
        }else {
            setPhoneError()
        }
      });
    return (
        <div className="sales-content-section-wrap">
            <div className="sales-enquiry-box">
                <div className="contact-header">
                    <label> Let’s connect!</label>
                    <h3 className="landing-common-title">Ready to experience the future of research?</h3>
                </div>
                <Formik 
                             initialValues={{
                                name : "",
                                email : "",
                                job : "",
                                org : "",
                                message : ""
                               }}
                               onSubmit={register}
                               validate={validate}
                               >
                                 {({ handleSubmit, handleChange, values, errors, touched,setFieldValue,resetForm}) => (
                <Form className="w-100" onSubmit={handleSubmit}>
                    <div className="form-input-wrap">
                        <Form.Label>Name<span style={{ color: "red" }}>*</span></Form.Label>
                        <Form.Control type="text" placeholder="Name" className="input-item" 
                         name="name"
                         value={values?.name}
                         onChange={handleChange}/>
                          {errors.name && touched.name ? (
                          <span style={{ color: "red" }}>
                          {errors.name}
                          </span>
                          ) : null}
                    </div>
                    <div className="form-input-wrap">
                        <Form.Label>Job title</Form.Label>
                        <Form.Control type="text" placeholder="Job title" className="input-item" 
                         name="job"
                         value={values?.job}
                         onChange={handleChange}/>
                         {errors.job && touched.job ? (
                                             <span style={{ color: "red" }}>
                                             {errors.job}
                                             </span>
                                             ) : null}
                    </div>
                    <div className="form-input-wrap">
                        <Form.Label>Email<span style={{ color: "red" }}>*</span></Form.Label>
                        <Form.Control type="email" placeholder="Email" className="input-item" 
                        name="email"
                        value={values?.email}
                        onChange={handleChange}/>
                        {errors.email && touched.email ? (
                        <span style={{ color: "red" }}>
                                             {errors.email}
                                             </span>
                                             ) : null}
                    </div>
                    <div className="form-input-wrap">
                        <Form.Label>Phone</Form.Label>
                        {/* <Form.Control type="number" placeholder="Phone" className="input-item" /> */}
                        <PhoneInput
                                                // international
                                                addInternationalOption={false}
                                                countryCallingCodeEditable={false}
                                                defaultCountry="US" 
                                                className="input-item"
                                                value={value}
                                                onChange={setValue}
                                                name="phone"
                                                placeholder="Phone"
                                                />
                                                <span style={{ color: "red" }}>{phoneError}</span>
                    </div>
                    <div className="form-input-wrap">
                        <Form.Label>Organisation name</Form.Label>
                        <Form.Control type="text" placeholder="Organisation name" className="input-item" 
                         name="org"
                         value={values?.org}
                         onChange={handleChange}/>
                           {errors.org && touched.org ? (
                                             <span style={{ color: "red" }}>
                                             {errors.org}
                                             </span>
                                             ) : null}
                    </div>
                    <div className="form-input-wrap">
                        <Form.Label>Message<span style={{ color: "red" }}>*</span></Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder="Message" className="textarea-item" 
                         name="message"
                         value={values?.message}
                         onChange={handleChange}/>
                         {errors.message && touched.message ? (
                                             <span style={{ color: "red" }}>
                                             {errors.message}
                                             </span>
                                             ) : null}
                    </div>
                    <div className="button-wrap">
                        <Button
                        type='submit'
                        onClick={() => {
                          setSub(true)
                          phoneErrors()
                         }}>Send</Button>
                    </div>
                </Form>
                )}
                </Formik>
            </div>
            <div className="sales-mail-text">
                {/* <p>You can also reach us by email at <span><ButtonMailto label="support@devdiscovery.com" mailto="support@devdiscovery.com" /></span></p> */}
               <p> You can also reach us by email at <a href="mailto:support@devdiscovery.com" target="_blank">support@devdiscovery.com</a> </p>
            </div>

        <ToastContainer/>
        <Modal
        show={sho}
        size="md"
        centered
        className="common-modal contact-success header-no-text"
        onHide={handleClos}
      >
        <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="success-content-wrap text-center">
              <h3>Thank you for connecting with us.</h3>
              <div className='contact-success-img'>
                <img src={ContactSuccessImg}/>
              </div>
              <p>
              Our customer service team shall contact you in the next <span className='primary-text'>12</span> hours.
              </p>
            </div>
          </Modal.Body>
        
      </Modal>
        </div>
    )
}

export default SalesContent;