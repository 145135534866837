import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/esm/Button';
import background from "../../../../../assets/images/landing-banner-bg.jpg";
import { Link as Li } from 'react-scroll';
import Typewriter from 'typewriter-effect';



function LandvideoFrame() {
    return (
        <div className="landvideo-frame-section p-relative">
            <Container className="text-center">
                <div className='land-videoframe-header-box'>
                    <h3 className="landing-common-title">Transform your research with DevDiscovery</h3>
                    <p className="landing-common-description">See how our cutting-edge AI platform, powered by a vast library of expertly curated content, revolutionizes research. Uncover innovative ideas, save valuable time, and gain deeper insights.</p>
                </div>
                <div className='land-video-box'>
                    <iframe
                        loading="lazy"
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/fOYWYDJHe3g?si=pKBviZuEhWD_lyj6&vq=hd1080"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen>
                    </iframe>

                </div>
            </Container>
        </div>
    )
}

export default LandvideoFrame;