
import React, { useState,useEffect } from 'react';
import AvatarDummy from '../../assets/images/avatar-dummy2.jpg';
import ProfileDummy from '../../assets/images/profile-dummy.png'
import DownArrow from '../../assets/images/icons/down-arrow-notify.png';
import Dropdown from 'react-bootstrap/Dropdown';
import ScrollArea from "react-scrollbar";
import {useSelector,useDispatch,shallowEqual} from 'react-redux'
import {setSearchKey,setCurrentCategory,setViewAll,setFileKey,setResultTitle,setFileVar} from './../../modules/results/services'
import { readSingleNotification, listNotification } from '../../modules/profile/services';
import { _markReadSingleNotification,_markReadAllNotification } from '../httpservice/apis';
import {useNavigate} from 'react-router-dom'
import { _token } from '../httpservice/apis';
import { Button } from 'react-bootstrap';
import {
    setFCountryKey,
    setFRegionKey,
    setFSectorKey,
    setFTopicKey,
    setFSourceKey,
    setFStartDateKey,
    setFEndDateKey,
    setDCountryKey, setDRegionKey, setDSectorKey, setDTopicKey, setDSourceKey,setDStartDateKey,setDEndDateKey,
    setOCountryKey, setORegionKey, setOSectorKey, setOTopicKey, setOSourceKey, setOStartDateKey, setOEndDateKey,
    setDataCountryKey, setDataRegionKey, setDataSectorKey, setDataTopicKey, setDataSourceKey, setDataStartDateKey, setDataEndDateKey, setDataResourceKey,
    setMulCountryKey,setMulRegionKey,setMulSectorKey,setMulTopicKey,setMulSourceKey,setMulStartDateKey,setMulEndDateKey,
    setEvalCountryKey, setEvalRegionKey, setEvalSectorKey, setEvalTopicKey, setEvalSourceKey,setEvalStartDateKey,setEvalEndDateKey,
    setEconCountryKey,
    setEconRegionKey,
    setEconSectorKey,
    setEconTopicKey,
    setEconSourceKey,
    setEconStartDateKey,
    setEconEndDateKey,
    setCountryKey,
    setRegionKey,
    setSectorKey,
    setTopicKey,
    setSourceKey,
    setCurrentTabFn,
  } from "./../../modules/results/services";
import {
    setFinanceOrgFilter,
    setFinanceOrgData,
    setFinanceOrgHandler,
    setFinanceCountryFilter,
    setFinanceCountryData,
    setFinanceCountryHandler,
    setFinanceRegionFilter,
    setFinanceRegionData,
    setFinanceRegionHandler,
    setFinanceSectorData,
    setFinanceSectorFilter,
    setFinanceSectorHandler,
    setFinanceTopicFilter,
    setFinanceTopicData,
    setFinanceTopicHandler,
    setHomeFlagFunction,
    setFinanceSearchCountry,
    setQueryFlag,
    setFinanceStartDate,
    setFinanceEndDate,
    setNotificationCategory
  } from "../../modules/results/filterServices";
  import {
    setDevOrgFilter,
    setDevOrgData,
    setDevOrgHandler,
    setDevCountryFilter,
    setDevCountryData,
    setDevCountryHandler,
    setDevRegionFilter,
    setDevRegionData,
    setDevRegionHandler,
    setDevSectorData,
    setDevSectorFilter,
    setDevSectorHandler,
    setDevTopicFilter,
    setDevTopicData,
    setDevTopicHandler,
    setDevSearchCountry,
    setDateTriggerDev,
    setDevStartDate,
    setDevEndDate
  } from "../../modules/results/components/devpubsresults/devpubsfiltersevices";
  import {
    setOtherOrgFilter,
    setOtherOrgData,
    setOtherOrgHandler,
    setOtherCountryFilter,
    setOtherCountryData,
    setOtherCountryHandler,
    setOtherRegionFilter,
    setOtherRegionData,
    setOtherRegionHandler,
    setOtherSectorData,
    setOtherSectorFilter,
    setOtherSectorHandler,
    setOtherTopicFilter,
    setOtherTopicData,
    setOtherTopicHandler,
    setOtherSearchCountry,
    setOtherStartDate,
    setOtherEndDate,
    
  } from "../../modules/results/components/otherpubsresults/otherpubsfilterservices";
  import {
    setDataOrgFilter,
    setDataOrgData,
    setDataOrgHandler,
    setDataCountryFilter,
    setDataCountryData,
    setDataCountryHandler,
    setDataRegionFilter,
    setDataRegionData,
    setDataRegionHandler,
    setDataSectorData,
    setDataSectorFilter,
    setDataSectorHandler,
    setDataTopicFilter,
    setDataTopicData,
    setDataTopicHandler,
    setDataResourceFilter,
    setDataResourceData,
    setDataResourceHandler,
    setDataSearchCountry
} from "../../modules/results/components/dataresults/datafilterservices";
import {
    setMulOrgFilter,
    setMulOrgData,
    setMulOrgHandler,
    setMulCountryFilter,
    setMulCountryData,
    setMulCountryHandler,
    setMulRegionFilter,
    setMulRegionData,
    setMulRegionHandler,
    setMulSectorData,
    setMulSectorFilter,
    setMulSectorHandler,
    setMulTopicFilter,
    setMulTopicData,
    setMulTopicHandler,
    setMulSearchCountry,
    setDateTriggerMul,
    setMulStartDate,
    setMulEndDate,
    
  } from "../../modules/results/components/multimediaresults/multimediafilterservices";
  import {
    setEvalOrgFilter,
    setEvalOrgData,
    setEvalOrgHandler,
    setEvalCountryFilter,
    setEvalCountryData,
    setEvalCountryHandler,
    setEvalRegionFilter,
    setEvalRegionData,
    setEvalRegionHandler,
    setEvalSectorData,
    setEvalSectorFilter,
    setEvalSectorHandler,
    setEvalTopicFilter,
    setEvalTopicData,
    setEvalTopicHandler,
    setEvalSearchCountry,
    setEvalStartDate,
    setEvalEndDate
    
  } from "../../modules/results/components/evaluationsresults/evaluationfilterservices";
  import {
    setEconOrgFilter,
    setEconOrgData,
    setEconOrgHandler,
    setEconCountryFilter,
    setEconCountryData,
    setEconCountryHandler,
    setEconRegionFilter,
    setEconRegionData,
    setEconRegionHandler,
    setEconSectorData,
    setEconSectorFilter,
    setEconSectorHandler,
    setEconTopicFilter,
    setEconTopicData,
    setEconTopicHandler,
    setEconPopupFunction,
    setEconIntialCountry,
    setEconIgnoreQuery,
    setDateTriggerEcon,
    setEconStartDate,
    setEconEndDate,
  } from "../../modules/results/components/econpubsresults/econfilterservices";
  import {
    setExpertsOrgFilter,
    setExpertsOrgData,
    setExpertsOrgHandler,
    setExpertsCountryFilter,
    setExpertsCountryData,
    setExpertsCountryHandler,
    setExpertsRegionFilter,
    setExpertsRegionData,
    setExpertsRegionHandler,
    setExpertsSectorData,
    setExpertsSectorFilter,
    setExpertsSectorHandler,
    setExpertsTopicFilter,
    setExpertsTopicData,
    setExpertsTopicHandler,
    setExpertsResourceFilter,
    setExpertsResourceData,
    setExpertsResourceHandler,
    setExpertsSearchCountry,
    setExpertsIgnoreQuery
    
  } from "../../modules/results/components/expertsresults/expertsfilterservices";

  
function Notification() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const notificationData = useSelector((state)=>state?.profile?.list_notification?.response?.data?.data?.data,shallowEqual)

    const token = useSelector((store) => store?.auth?.signin?.response?.data?.data?.token, shallowEqual)
    const tokenSso = useSelector(
        (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token, shallowEqual
    );
    // const [viewAll,setViewAll] = useState(false)
    const viewAll = useSelector((state)=>state?.results?.view_all)


    const [view,setView] = useState(false)
    // useEffect(()=>{
    //     setView(viewAll)
    // },[viewAll])

    //fn to clear all the applied filters
    const clearDevFilters =()=>{
        dispatch(setDSourceKey(""))
            dispatch(setDCountryKey(""))
            dispatch(setDRegionKey(""))
            dispatch(setDTopicKey(""))
            dispatch(setDSectorKey(""))
            dispatch(setDStartDateKey(""))
            dispatch(setDEndDateKey(""))
            dispatch(setDevCountryData([]));
            dispatch(setDevOrgData([]));
            dispatch(setDevCountryHandler([]));
            dispatch(setDevOrgHandler([]));
            dispatch(setDevRegionData([]));
            dispatch(setDevRegionHandler([]));
            dispatch(setDevSectorHandler([]));
            dispatch(setDevSectorData([]));
            dispatch(setDevTopicData([]));
            dispatch(setDevTopicHandler([]));
            dispatch(setDevCountryFilter([]))
            dispatch(setDevOrgFilter([]))
            dispatch(setDevRegionFilter([]))
            dispatch(setDevSectorFilter([]))
            dispatch(setDevTopicFilter([]))
            dispatch(setDevSearchCountry([]))
            dispatch(setDateTriggerDev(true))
            dispatch(setDevStartDate(null))
            dispatch(setDevEndDate(null))
      }
    
      const clearDataFilters =()=>{
        dispatch(setDataSourceKey(""))
        dispatch(setDataCountryKey(""))
        dispatch(setDataRegionKey(""))
        dispatch(setDataTopicKey(""))
        dispatch(setDataSectorKey(""))
        dispatch(setDataResourceKey(""))
            dispatch(setDataOrgData([]));
            dispatch(setDataCountryHandler([]));
            dispatch(setDataOrgHandler([]));
            dispatch(setDataRegionData([]));
            dispatch(setDataRegionHandler([]));
            dispatch(setDataSectorHandler([]));
            dispatch(setDataSectorData([]));
            dispatch(setDataTopicData([]));
            dispatch(setDataTopicHandler([]));
            dispatch(setDataCountryFilter([]))
            dispatch(setDataOrgFilter([]))
            dispatch(setDataRegionFilter([]))
            dispatch(setDataSectorFilter([]))
            dispatch(setDataTopicFilter([]))
            dispatch(setDataCountryData([]))
            dispatch(setDataResourceFilter([]))
            dispatch(setDataResourceData([]))
            dispatch(setDataResourceHandler([]))
            dispatch(setDataSearchCountry([]))
      }
    
      const clearEconFilters =()=>{
            dispatch(setEconSourceKey(""))
            dispatch(setEconCountryKey(""))
            dispatch(setEconRegionKey(""))
            dispatch(setEconTopicKey(""))
            dispatch(setEconSectorKey(""))
            dispatch(setEconStartDateKey(""))
            dispatch(setEconEndDateKey(""))
            dispatch(setEconCountryData([]));
            dispatch(setEconOrgData([]));
            dispatch(setEconCountryHandler([]));
            dispatch(setEconOrgHandler([]));
            dispatch(setEconRegionData([]));
            dispatch(setEconRegionHandler([]));
            dispatch(setEconSectorHandler([]));
            dispatch(setEconSectorData([]));
            dispatch(setEconTopicData([]));
            dispatch(setEconTopicHandler([]));
            dispatch(setEconCountryFilter([]))
            dispatch(setEconOrgFilter([]))
            dispatch(setEconRegionFilter([]))
            dispatch(setEconSectorFilter([]))
            dispatch(setEconTopicFilter([]))
            dispatch(setEconIntialCountry([]))
            dispatch(setEconIgnoreQuery(false))
            dispatch(setDateTriggerEcon(true))
            dispatch(setEconStartDate(null))
            dispatch(setEconEndDate(null))
      }
    
      const clearEvalFilters =()=>{
            dispatch(setEvalSourceKey(""))
            dispatch(setEvalCountryKey(""))
            dispatch(setEvalRegionKey(""))
            dispatch(setEvalTopicKey(""))
            dispatch(setEvalSectorKey(""))
            dispatch(setEvalStartDateKey(""))
            dispatch(setEvalEndDateKey(""))
            dispatch(setEvalCountryData([]));
            dispatch(setEvalOrgData([]));
            dispatch(setEvalCountryHandler([]));
            dispatch(setEvalOrgHandler([]));
            dispatch(setEvalRegionData([]));
            dispatch(setEvalRegionHandler([]));
            dispatch(setEvalSectorHandler([]));
            dispatch(setEvalSectorData([]));
            dispatch(setEvalTopicData([]));
            dispatch(setEvalTopicHandler([]));
            dispatch(setEvalCountryFilter([]))
            dispatch(setEvalOrgFilter([]))
            dispatch(setEvalRegionFilter([]))
            dispatch(setEvalSectorFilter([]))
            dispatch(setEvalTopicFilter([]))
            dispatch(setEvalSearchCountry([]))
            dispatch(setDateTriggerEcon(true))
            dispatch(setEvalStartDate(null))
            dispatch(setEvalEndDate(null))
      }
      
      const clearMulFilters =()=>{
            dispatch(setMulSourceKey(""))
            dispatch(setMulCountryKey(""))
            dispatch(setMulRegionKey(""))
            dispatch(setMulTopicKey(""))
            dispatch(setMulSectorKey(""))
            dispatch(setMulStartDateKey(""))
            dispatch(setMulEndDateKey(""))
            dispatch(setMulCountryData([]));
            dispatch(setMulOrgData([]));
            dispatch(setMulCountryHandler([]));
            dispatch(setMulOrgHandler([]));
            dispatch(setMulRegionData([]));
            dispatch(setMulRegionHandler([]));
            dispatch(setMulSectorHandler([]));
            dispatch(setMulSectorData([]));
            dispatch(setMulTopicData([]));
            dispatch(setMulTopicHandler([]));
            dispatch(setMulCountryFilter([]))
            dispatch(setMulOrgFilter([]))
            dispatch(setMulRegionFilter([]))
            dispatch(setMulSectorFilter([]))
            dispatch(setMulTopicFilter([]))
            dispatch(setMulSearchCountry([]))
            dispatch(setDateTriggerMul(true))
            dispatch(setMulStartDate(null))
            dispatch(setMulEndDate(null))
      }
    
      const clearOtherFilters =()=>{
        dispatch(setOSourceKey(""))
        dispatch(setOCountryKey(""))
        dispatch(setORegionKey(""))
        dispatch(setOTopicKey(""))
        dispatch(setOSectorKey(""))
        dispatch(setOStartDateKey(""))
        dispatch(setOEndDateKey(""))
        dispatch(setOtherCountryData([]));
        dispatch(setOtherOrgData([]));
        dispatch(setOtherCountryHandler([]));
        dispatch(setOtherOrgHandler([]));
        dispatch(setOtherRegionData([]));
        dispatch(setOtherRegionHandler([]));
        dispatch(setOtherSectorHandler([]));
        dispatch(setOtherSectorData([]));
        dispatch(setOtherTopicData([]));
        dispatch(setOtherTopicHandler([]));
        dispatch(setOtherCountryFilter([]))
        dispatch(setOtherOrgFilter([]))
        dispatch(setOtherRegionFilter([]))
        dispatch(setOtherSectorFilter([]))
        dispatch(setOtherTopicFilter([]))
        dispatch(setOtherSearchCountry([]))
        dispatch(setDateTriggerMul(true))
        dispatch(setOtherStartDate(null))
        dispatch(setOtherEndDate(null))
      }
    
      const clearFinanceFilters = ()=>{
        dispatch(setFSourceKey(""))
        dispatch(setFCountryKey(""))
        dispatch(setFRegionKey(""))
        dispatch(setFTopicKey(""))
        dispatch(setFSectorKey(""))
        dispatch(setFStartDateKey(""))
        dispatch(setFEndDateKey(""))
        dispatch(setFinanceCountryData([]));
        dispatch(setFinanceOrgData([]));
        dispatch(setFinanceCountryHandler([]));
        dispatch(setFinanceOrgHandler([]));
        dispatch(setFinanceRegionData([]));
        dispatch(setFinanceRegionHandler([]));
        dispatch(setFinanceSectorHandler([]));
        dispatch(setFinanceSectorData([]));
        dispatch(setFinanceTopicData([]));
        dispatch(setFinanceTopicHandler([]));
        dispatch(setFinanceCountryFilter([]))
        dispatch(setFinanceOrgFilter([]))
        dispatch(setFinanceRegionFilter([]))
        dispatch(setFinanceSectorFilter([]))
        dispatch(setFinanceTopicFilter([]))
        dispatch(setFinanceSearchCountry([]));
        dispatch(setFinanceStartDate(null))
        dispatch(setFinanceEndDate(null))
      }
    
      const clearExpertsFilters =()=>{
        dispatch(setSourceKey(""))
        dispatch(setCountryKey(""))
        dispatch(setRegionKey(""))
        dispatch(setTopicKey(""))
        dispatch(setSectorKey(""))
        dispatch(setExpertsIgnoreQuery(false))
        dispatch(setExpertsOrgFilter([]));
        dispatch(setExpertsOrgData([]));
        dispatch(setExpertsOrgHandler([]));
        dispatch(setExpertsCountryFilter([]));
        dispatch(setExpertsCountryData([]));
        dispatch(setExpertsCountryHandler([]));
        dispatch(setExpertsRegionFilter([]));
        dispatch(setExpertsRegionData([]));
        dispatch(setExpertsRegionHandler([]));
        dispatch(setExpertsSectorFilter([]));
        dispatch(setExpertsSectorData([]));
        dispatch(setExpertsSectorHandler([]));
        dispatch(setExpertsTopicFilter([]));
        dispatch(setExpertsTopicData([]));
        dispatch(setExpertsTopicHandler([]));
        
        dispatch(setExpertsSearchCountry([]));
      }

      const clearFilters =()=>{
        clearExpertsFilters()
        clearDevFilters()
        clearDataFilters()
        clearEconFilters()
        clearEvalFilters()
        clearMulFilters()
        clearOtherFilters()
        clearFinanceFilters()
      }

    //fn to check if a query is from a doc upload or a keyword search  
    const searchOrFile =(item)=>{
        if(item?.content_object?.get_parameters?.qterm){
            dispatch(setCurrentTabFn("text"))
           dispatch(setSearchKey(item?.content_object?.get_parameters?.qterm)) 
           dispatch(setFileKey(""))
        }else if(item?.content_object?.get_parameters?.qfile){
            dispatch(setCurrentTabFn("document"))
            dispatch(setCurrentCategory("document"))
            dispatch(setFileKey(item?.content_object?.get_parameters?.qfile))
            dispatch(setSearchKey("")) 
            dispatch(setFileVar(""))
            dispatch(setResultTitle(item?.content_object?.title))
        }
    }


    //fn to list the notifications
    const listNotifications =()=>{
        if(token!==undefined){
            dispatch(listNotification(token))
        }else if(tokenSso!==undefined){
            dispatch(listNotification(tokenSso))
        }
        
    }


    //fn to mark a single notification as read
    const markSingleRead =(id)=>{
        if(token!==undefined){
            dispatch(readSingleNotification(token,id,"")).then(()=>{
                listNotifications()
            })
        }else if(tokenSso!==undefined){
            dispatch(readSingleNotification(tokenSso,id,"")).then(()=>{
                listNotifications()
            })
        }
    }

    //fn to mark all notifications as read
    const markAllRead =()=>{
        if(token!==undefined){
            return _markReadAllNotification(token,"").then(()=>{
                listNotifications()
            })
        }else if(tokenSso!==undefined){
            return _markReadAllNotification(tokenSso,"").then(()=>{
                listNotifications()
            })
        }
    }

    //fn that sets the category filters
    const categoryFilter =(row)=>{
        if(row?.get_parameters?.category==="financing"){
            dispatch(setNotificationCategory("financing"))
            dispatch(setFRegionKey(row?.filter_parameters?.financefilterregion))
            dispatch(setFinanceRegionFilter(row?.filter_parameters?.financefilterarrayregion))
            dispatch(setFinanceRegionData(row?.filter_parameters?.financedataregion))
            dispatch(setFinanceRegionHandler(row?.filter_parameters?.financehandlerregion))

            dispatch(setFSourceKey(row?.filter_parameters?.financefilterorg))
            dispatch(setFinanceOrgFilter(row?.filter_parameters?.financefilterarrayorg))
            dispatch(setFinanceOrgData(row?.filter_parameters?.financedataorg))
            dispatch(setFinanceOrgHandler(row?.filter_parameters?.financehandlerorg))

            dispatch(setFCountryKey(row?.filter_parameters?.financefiltercountry))
            dispatch(setFinanceCountryFilter(row?.filter_parameters?.financefilterarraycountry))
            dispatch(setFinanceCountryData(row?.filter_parameters?.financedatacountry))
            dispatch(setFinanceCountryHandler(row?.filter_parameters?.financehandlercountry))

            dispatch(setFTopicKey(row?.filter_parameters?.financefiltertopic))
            dispatch(setFinanceTopicFilter(row?.filter_parameters?.financefilterarraytopic))
            dispatch(setFinanceTopicData(row?.filter_parameters?.financedatatopic))
            dispatch(setFinanceTopicHandler(row?.filter_parameters?.financehandlertopic))

            dispatch(setFSectorKey(row?.filter_parameters?.financefiltersector))
            dispatch(setFinanceSectorFilter(row?.filter_parameters?.financefilterarraysector))
            dispatch(setFinanceSectorData(row?.filter_parameters?.financedatasector))
            dispatch(setFinanceSectorHandler(row?.filter_parameters?.financehandlersector))

            dispatch(setFStartDateKey(row?.filter_parameters?.financestartdate))
            dispatch(setFEndDateKey(row?.filter_parameters?.financeenddate))
            dispatch(setFinanceStartDate(row?.filter_parameters?.financeSdate))
            dispatch(setFinanceEndDate(row?.filter_parameters?.financeEdate))


        }else if(row?.get_parameters?.category==="devpubs"){
            dispatch(setNotificationCategory("devpubs"))
            dispatch(setDRegionKey(row?.filter_parameters?.devfilterregion))
            dispatch(setDevRegionFilter(row?.filter_parameters?.devfilterarrayregion))
            dispatch(setDevRegionData(row?.filter_parameters?.devdataregion))
            dispatch(setDevRegionHandler(row?.filter_parameters?.devhandlerregion))

            dispatch(setDSourceKey(row?.filter_parameters?.devfilterorg))
            dispatch(setDevOrgFilter(row?.filter_parameters?.devfilterarrayorg))
            dispatch(setDevOrgData(row?.filter_parameters?.devdataorg))
            dispatch(setDevOrgHandler(row?.filter_parameters?.devhandlerorg))

            dispatch(setDCountryKey(row?.filter_parameters?.devfiltercountry))
            dispatch(setDevCountryFilter(row?.filter_parameters?.devfilterarraycountry))
            dispatch(setDevCountryData(row?.filter_parameters?.devdatacountry))
            dispatch(setDevCountryHandler(row?.filter_parameters?.devhandlercountry))

            dispatch(setDTopicKey(row?.filter_parameters?.devfiltertopic))
            dispatch(setDevTopicFilter(row?.filter_parameters?.devfilterarraytopic))
            dispatch(setDevTopicData(row?.filter_parameters?.devdatatopic))
            dispatch(setDevTopicHandler(row?.filter_parameters?.devhandlertopic))

            dispatch(setDSectorKey(row?.filter_parameters?.devfiltersector))
            dispatch(setDevSectorFilter(row?.filter_parameters?.devfilterarraysector))
            dispatch(setDevSectorData(row?.filter_parameters?.devdatasector))
            dispatch(setDevSectorHandler(row?.filter_parameters?.devhandlersector))

            dispatch(setDStartDateKey(row?.filter_parameters?.devstartdate))
            dispatch(setDEndDateKey(row?.filter_parameters?.devenddate))
            dispatch(setDevStartDate(row?.filter_parameters?.devSdate))
            dispatch(setDevEndDate(row?.filter_parameters?.devEdate))
        }else if(row?.get_parameters?.category==="otherpubs"){
            dispatch(setNotificationCategory("otherpubs"))
            dispatch(setORegionKey(row?.filter_parameters?.otherfilterregion))
            dispatch(setOtherRegionFilter(row?.filter_parameters?.otherfilterarrayregion))
            dispatch(setOtherRegionData(row?.filter_parameters?.otherdataregion))
            dispatch(setOtherRegionHandler(row?.filter_parameters?.otherhandlerregion))

            dispatch(setOSourceKey(row?.filter_parameters?.otherfilterorg))
            dispatch(setOtherOrgFilter(row?.filter_parameters?.otherfilterarrayorg))
            dispatch(setOtherOrgData(row?.filter_parameters?.otherdataorg))
            dispatch(setOtherOrgHandler(row?.filter_parameters?.otherhandlerorg))

            dispatch(setOCountryKey(row?.filter_parameters?.otherfiltercountry))
            dispatch(setOtherCountryFilter(row?.filter_parameters?.otherfilterarraycountry))
            dispatch(setOtherCountryData(row?.filter_parameters?.otherdatacountry))
            dispatch(setOtherCountryHandler(row?.filter_parameters?.otherhandlercountry))

            dispatch(setOTopicKey(row?.filter_parameters?.otherfiltertopic))
            dispatch(setOtherTopicFilter(row?.filter_parameters?.otherfilterarraytopic))
            dispatch(setOtherTopicData(row?.filter_parameters?.otherdatatopic))
            dispatch(setOtherTopicHandler(row?.filter_parameters?.otherhandlertopic))

            dispatch(setOSectorKey(row?.filter_parameters?.otherfiltersector))
            dispatch(setOtherSectorFilter(row?.filter_parameters?.otherfilterarraysector))
            dispatch(setOtherSectorData(row?.filter_parameters?.otherdatasector))
            dispatch(setOtherSectorHandler(row?.filter_parameters?.otherhandlersector))

            dispatch(setOStartDateKey(row?.filter_parameters?.otherstartdate))
            dispatch(setOEndDateKey(row?.filter_parameters?.otherenddate))
            dispatch(setOtherStartDate(row?.filter_parameters?.otherSdate))
            dispatch(setOtherEndDate(row?.filter_parameters?.otherEdate))
        }else if(row?.get_parameters?.category==="data"){
            dispatch(setNotificationCategory("data"))
            dispatch(setDataRegionKey(row?.filter_parameters?.datafilterregion))
            dispatch(setDataRegionFilter(row?.filter_parameters?.datafilterarrayregion))
            dispatch(setDataRegionData(row?.filter_parameters?.datadataregion))
            dispatch(setDataRegionHandler(row?.filter_parameters?.datahandlerregion))

            dispatch(setDataSourceKey(row?.filter_parameters?.datafilterorg))
            dispatch(setDataOrgFilter(row?.filter_parameters?.datafilterarrayorg))
            dispatch(setDataOrgData(row?.filter_parameters?.datadataorg))
            dispatch(setDataOrgHandler(row?.filter_parameters?.datahandlerorg))

            dispatch(setDataCountryKey(row?.filter_parameters?.datafiltercountry))
            dispatch(setDataCountryFilter(row?.filter_parameters?.datafilterarraycountry))
            dispatch(setDataCountryData(row?.filter_parameters?.datadatacountry))
            dispatch(setDataCountryHandler(row?.filter_parameters?.datahandlercountry))

            dispatch(setDataTopicKey(row?.filter_parameters?.datafiltertopic))
            dispatch(setDataTopicFilter(row?.filter_parameters?.datafilterarraytopic))
            dispatch(setDataTopicData(row?.filter_parameters?.datadatatopic))
            dispatch(setDataTopicHandler(row?.filter_parameters?.datahandlertopic))

            dispatch(setDataSectorKey(row?.filter_parameters?.datafiltersector))
            dispatch(setDataSectorFilter(row?.filter_parameters?.datafilterarraysector))
            dispatch(setDataSectorData(row?.filter_parameters?.datadatasector))
            dispatch(setDataSectorHandler(row?.filter_parameters?.datahandlersector))

            dispatch(setDataResourceKey(row?.get_parameters?.resource))
            dispatch(setDataResourceFilter(row?.filter_parameters?.datafilterarrayresource))
            dispatch(setDataResourceData(row?.filter_parameters?.datadataresource))
            dispatch(setDataResourceHandler(row?.filter_parameters?.datahandlerresource))
        }
        else if(row?.get_parameters?.category==="multimedia"){
            dispatch(setNotificationCategory("multimedia"))
            dispatch(setMulRegionKey(row?.filter_parameters?.mulfilterregion))
            dispatch(setMulRegionFilter(row?.filter_parameters?.mulfilterarrayregion))
            dispatch(setMulRegionData(row?.filter_parameters?.muldataregion))
            dispatch(setMulRegionHandler(row?.filter_parameters?.mulhandlerregion))

            dispatch(setMulSourceKey(row?.filter_parameters?.mulfilterorg))
            dispatch(setMulOrgFilter(row?.filter_parameters?.mulfilterarrayorg))
            dispatch(setMulOrgData(row?.filter_parameters?.muldataorg))
            dispatch(setMulOrgHandler(row?.filter_parameters?.mulhandlerorg))

            dispatch(setMulCountryKey(row?.filter_parameters?.mulfiltercountry))
            dispatch(setMulCountryFilter(row?.filter_parameters?.mulfilterarraycountry))
            dispatch(setMulCountryData(row?.filter_parameters?.muldatacountry))
            dispatch(setMulCountryHandler(row?.filter_parameters?.mulhandlercountry))

            dispatch(setMulTopicKey(row?.filter_parameters?.mulfiltertopic))
            dispatch(setMulTopicFilter(row?.filter_parameters?.mulfilterarraytopic))
            dispatch(setMulTopicData(row?.filter_parameters?.muldatatopic))
            dispatch(setMulTopicHandler(row?.filter_parameters?.mulhandlertopic))

            dispatch(setMulSectorKey(row?.filter_parameters?.mulfiltersector))
            dispatch(setMulSectorFilter(row?.filter_parameters?.mulfilterarraysector))
            dispatch(setMulSectorData(row?.filter_parameters?.muldatasector))
            dispatch(setMulSectorHandler(row?.filter_parameters?.mulhandlersector))

            dispatch(setMulStartDateKey(row?.filter_parameters?.mulstartdate))
            dispatch(setMulEndDateKey(row?.filter_parameters?.mulenddate))
            dispatch(setMulStartDate(row?.filter_parameters?.mulSdate))
            dispatch(setMulEndDate(row?.filter_parameters?.mulEdate))
        }
        else if(row?.get_parameters?.category==="evaluations"){
            dispatch(setNotificationCategory("evaluations"))
            dispatch(setEvalRegionKey(row?.filter_parameters?.evalfilterregion))
            dispatch(setEvalRegionFilter(row?.filter_parameters?.evalfilterarrayregion))
            dispatch(setEvalRegionData(row?.filter_parameters?.evaldataregion))
            dispatch(setEvalRegionHandler(row?.filter_parameters?.evalhandlerregion))

            dispatch(setEvalSourceKey(row?.filter_parameters?.evalfilterorg))
            dispatch(setEvalOrgFilter(row?.filter_parameters?.evalfilterarrayorg))
            dispatch(setEvalOrgData(row?.filter_parameters?.evaldataorg))
            dispatch(setEvalOrgHandler(row?.filter_parameters?.evalhandlerorg))

            dispatch(setEvalCountryKey(row?.filter_parameters?.evalfiltercountry))
            dispatch(setEvalCountryFilter(row?.filter_parameters?.evalfilterarraycountry))
            dispatch(setEvalCountryData(row?.filter_parameters?.evaldatacountry))
            dispatch(setEvalCountryHandler(row?.filter_parameters?.evalhandlercountry))

            dispatch(setEvalTopicKey(row?.filter_parameters?.evalfiltertopic))
            dispatch(setEvalTopicFilter(row?.filter_parameters?.evalfilterarraytopic))
            dispatch(setEvalTopicData(row?.filter_parameters?.evaldatatopic))
            dispatch(setEvalTopicHandler(row?.filter_parameters?.evalhandlertopic))

            dispatch(setEvalSectorKey(row?.filter_parameters?.evalfiltersector))
            dispatch(setEvalSectorFilter(row?.filter_parameters?.evalfilterarraysector))
            dispatch(setEvalSectorData(row?.filter_parameters?.evaldatasector))
            dispatch(setEvalSectorHandler(row?.filter_parameters?.evalhandlersector))

            dispatch(setEvalStartDateKey(row?.filter_parameters?.evalstartdate))
            dispatch(setEvalEndDateKey(row?.filter_parameters?.evalenddate))
            dispatch(setEvalStartDate(row?.filter_parameters?.evalSdate))
            dispatch(setEvalEndDate(row?.filter_parameters?.evalEdate))
        }
        else if(row?.get_parameters?.category==="econpubs"){
            dispatch(setNotificationCategory("econpubs"))
            dispatch(setEconRegionKey(row?.filter_parameters?.econfilterregion))
            dispatch(setEconRegionFilter(row?.filter_parameters?.econfilterarrayregion))
            dispatch(setEconRegionData(row?.filter_parameters?.econdataregion))
            dispatch(setEconRegionHandler(row?.filter_parameters?.econhandlerregion))

            dispatch(setEconSourceKey(row?.filter_parameters?.econfilterorg))
            dispatch(setEconOrgFilter(row?.filter_parameters?.econfilterarrayorg))
            dispatch(setEconOrgData(row?.filter_parameters?.econdataorg))
            dispatch(setEconOrgHandler(row?.filter_parameters?.econhandlerorg))

            dispatch(setEconCountryKey(row?.filter_parameters?.econfiltercountry))
            dispatch(setEconCountryFilter(row?.filter_parameters?.econfilterarraycountry))
            dispatch(setEconCountryData(row?.filter_parameters?.econdatacountry))
            dispatch(setEconCountryHandler(row?.filter_parameters?.econhandlercountry))

            dispatch(setEconTopicKey(row?.filter_parameters?.econfiltertopic))
            dispatch(setEconTopicFilter(row?.filter_parameters?.econfilterarraytopic))
            dispatch(setEconTopicData(row?.filter_parameters?.econdatatopic))
            dispatch(setEconTopicHandler(row?.filter_parameters?.econhandlertopic))

            dispatch(setEconSectorKey(row?.filter_parameters?.econfiltersector))
            dispatch(setEconSectorFilter(row?.filter_parameters?.econfilterarraysector))
            dispatch(setEconSectorData(row?.filter_parameters?.econdatasector))
            dispatch(setEconSectorHandler(row?.filter_parameters?.econhandlersector))

            // dispatch(setEconStartDateKey(row?.filter_parameters?.econstartdate))
            // dispatch(setEconEndDateKey(row?.filter_parameters?.econenddate))
            // dispatch(setEconIgnoreQuery(row?.filter_parameters?.ignore_query))
            dispatch(setEconStartDateKey(row?.filter_parameters?.start_date))
            dispatch(setEconEndDateKey(row?.filter_parameters?.end_date))
            dispatch(setEconIgnoreQuery(row?.filter_parameters?.ignore_query))
            dispatch(setEconIntialCountry(row?.filter_parameters?.econinitialcountry))
            dispatch(setEconStartDate(row?.filter_parameters?.econSdate))
            dispatch(setEconEndDate(row?.filter_parameters?.econEdate))
        }
        else if(row?.get_parameters?.category==="experts"){
            dispatch(setNotificationCategory("experts"))
            dispatch(setRegionKey(row?.filter_parameters?.expertsfilterregion))
            dispatch(setExpertsRegionFilter(row?.filter_parameters?.expertsfilterarrayregion))
            dispatch(setExpertsRegionData(row?.filter_parameters?.expertsdataregion))
            dispatch(setExpertsRegionHandler(row?.filter_parameters?.expertshandlerregion))

            dispatch(setSourceKey(row?.filter_parameters?.expertsfilterorg))
            dispatch(setExpertsOrgFilter(row?.filter_parameters?.expertsfilterarrayorg))
            dispatch(setExpertsOrgData(row?.filter_parameters?.expertsdataorg))
            dispatch(setExpertsOrgHandler(row?.filter_parameters?.expertshandlerorg))

            dispatch(setCountryKey(row?.filter_parameters?.expertsfiltercountry))
            dispatch(setExpertsCountryFilter(row?.filter_parameters?.expertsfilterarraycountry))
            dispatch(setExpertsCountryData(row?.filter_parameters?.expertsdatacountry))
            dispatch(setExpertsCountryHandler(row?.filter_parameters?.expertshandlercountry))

            dispatch(setTopicKey(row?.filter_parameters?.expertsfiltertopic))
            dispatch(setExpertsTopicFilter(row?.filter_parameters?.expertsfilterarraytopic))
            dispatch(setExpertsTopicData(row?.filter_parameters?.expertsdatatopic))
            dispatch(setExpertsTopicHandler(row?.filter_parameters?.expertshandlertopic))

            dispatch(setSectorKey(row?.filter_parameters?.expertsfiltersector))
            dispatch(setExpertsSectorFilter(row?.filter_parameters?.expertsfilterarraysector))
            dispatch(setExpertsSectorData(row?.filter_parameters?.expertsdatasector))
            dispatch(setExpertsSectorHandler(row?.filter_parameters?.expertshandlersector))

            dispatch(setExpertsIgnoreQuery(row?.filter_parameters?.ignore_query))
        }
       }

    
    return (
        <div className="notification-wrap">
            {notificationData?.length===0?
                 <div className="no-item">
                        Notification box is empty
                    </div> :
            <div className="notification-body">
                <div className="notification-btn">
                    <Button onClick={()=>{
                        markAllRead()
                    }} className="btn-sm">Mark all as read</Button>
                </div>
                { notificationData&&view===false?
                <>
                     {/* <ScrollArea
                     speed={0.8}
                     className="notification-scroll"
                     contentClassName="content"
                     horizontal={false}
                 > */}
                    
                   
                          <div className="notification-list"> 
                          {notificationData?.slice(0,4)?.map((item,key)=>(
                        <>
                        {item?.message?.includes("record")&&
                                  <Dropdown.Item href={item?.content_object?.link_html} target="_blank"
                  className={item?.is_read ? "read-notif" : "unread-notif"}>
                      <div className="notification-content-row d-flex" onClick={()=>{
                                markSingleRead(item?.id)
                              }}>
                          <div className="notifi-img-left">
                              <img src={item?.sender?.profile_pic_path?item?.sender?.profile_pic_path:ProfileDummy} alt="user-image" />
                          </div>
                          <div className="notifi-content-right">
                              <h3>
                                  {item?.message}
                                  
                              </h3>
                              <div className='notification-time'>
                                  <span>{item?.created}</span>
                              </div>
                          </div>
                      </div>
                      </Dropdown.Item>
                        }
                        {item?.message?.includes("query")&&
                            <Dropdown.Item  className={item?.is_read ? "read-notif" : "unread-notif"}>
                            <div className="notification-content-row d-flex" onClick={()=>{
                                clearFilters()
                                      markSingleRead(item?.id)
                                      dispatch(setQueryFlag(true))
                                      searchOrFile(item)
                                      {item?.content_object?.filter_parameters!==null&&categoryFilter(item?.content_object)}
                                      dispatch(setCurrentCategory(item?.content_object?.get_parameters?.category))
                                      navigate('/results')
                                    }}>
                                <div className="notifi-img-left">
                                    <img src={item?.sender?.profile_pic_path?item?.sender?.profile_pic_path:ProfileDummy} alt="user-image" />
                                </div>
                                <div className="notifi-content-right">
                                    <h3>
                                        <span className='notify-user-name'>{item?.sender?.first_name} </span>
                                        {item?.message?.includes("query")&&
                                        <span
                                        style ={{cursor:"pointer"}} onClick={()=>{
                                          //  dispatch(setSearchKey(item?.content_object?.get_parameters?.qterm))
                                          clearFilters()
                                           dispatch(setQueryFlag(true))
                                           searchOrFile(item)
                                           {item?.content_object?.filter_parameters!==null&&categoryFilter(item?.content_object)}
                                           dispatch(setCurrentCategory(item?.content_object?.get_parameters?.category))
                                           navigate('/results')
                                       }}>has shared a query with you</span>
                                        }

                                        
                                    </h3>
                                    <div className='notification-time'>
                                        <span>{item?.created}</span>
                                    </div>
                                </div>
                            </div>
                            </Dropdown.Item>
                        }
                        </>
                           ))}
  
                      </div>
                     
                   
                   
                 {/* </ScrollArea> */}
                 {notificationData?.length>4&&
                  <div className='notification-footer'>
                  <span 
                  onClick={()=>{
                      dispatch(setViewAll(true))
                      setView(true)
                  }}>View all notifications<img src={DownArrow} alt="" /></span>
              </div>}
                
                </>
                
                 :
                notificationData&&view===true&&
                <>
                     <ScrollArea
                     speed={0.8}
                     className="notification-scroll"
                     contentClassName="content"
                     horizontal={false}
                 >
                    
                    <div className="notification-list"> 
                            {notificationData?.map((item,key)=>(
                                <>
                                {item?.message?.includes("record")&&
                                          <Dropdown.Item href={item?.content_object?.link_html} target="_blank"
                          className={item?.is_read ? "read-notif" : "unread-notif"}>
                              <div className="notification-content-row d-flex" onClick={()=>{
                                        markSingleRead(item?.id)
                                      }}>
                                  <div className="notifi-img-left">
                                      <img src={item?.sender?.profile_pic_path?item?.sender?.profile_pic_path:ProfileDummy} alt="user-image" />
                                  </div>
                                  <div className="notifi-content-right">
                                      <h3 >
                                          {/* <span className='notify-user-name'>{item?.sender?.first_name} </span>
                                          
                                          {item?.message?.includes("record")&&
                                          <a href={item?.content_object?.link_html} target="_blank" style={{textDecoration:"none",color:"inherit"}}>
                                          <span>has shared a record with you</span>
                                          </a>
                                          } */}
                                          {item?.message}
                                          
                                      </h3>
                                      <div className='notification-time'>
                                          <span>{item?.created}</span>
                                      </div>
                                  </div>
                              </div>
                              </Dropdown.Item>
                                }
                                 {item?.message?.includes("query")&&
                            <Dropdown.Item  className={item?.is_read ? "read-notif" : "unread-notif"}>
                            <div className="notification-content-row d-flex" onClick={()=>{
                                      clearFilters()
                                      markSingleRead(item?.id)
                                      dispatch(setQueryFlag(true))
                                      searchOrFile(item)
                                      {item?.content_object?.filter_parameters!==null&&categoryFilter(item?.content_object)}
                                      dispatch(setCurrentCategory(item?.content_object?.get_parameters?.category))
                                      navigate('/results')
                                    }}>
                                <div className="notifi-img-left">
                                    <img src={item?.sender?.profile_pic_path?item?.sender?.profile_pic_path:ProfileDummy} alt="user-image" />
                                </div>
                                <div className="notifi-content-right">
                                    <h3>
                                        <span className='notify-user-name'>{item?.sender?.first_name} </span>
                                        {item?.message?.includes("query")&&
                                        <span
                                        style ={{cursor:"pointer"}} onClick={()=>{
                                          //  dispatch(setSearchKey(item?.content_object?.get_parameters?.qterm))
                                          clearFilters()
                                          dispatch(setQueryFlag(true))
                                           searchOrFile(item)
                                           {item?.content_object?.filter_parameters!==null&&categoryFilter(item?.content_object)}
                                           dispatch(setCurrentCategory(item?.content_object?.get_parameters?.category))
                                           navigate('/results')
                                       }}>has shared a query with you</span>
                                        }
                                        
                                    </h3>
                                    <div className='notification-time'>
                                        <span>{item?.created}</span>
                                    </div>
                                </div>
                            </div>
                            </Dropdown.Item>
                        }
                                </>
                        
                              ))}
                          
  
                      </div>
                    
                   
                 </ScrollArea>
                 {
                    notificationData?.length>4&&
                    <div className='notification-footer'>
                    <span 
                    onClick={()=>{
                        setView(false)
                    }}>View less<span className='view-less-arrow'><img src={DownArrow} alt="" /></span></span>
                </div>
                 }
                
                </>
                    
                   
                }
                
            </div>}
            {/* {viewAll===false?
            <div className='notification-footer'>
            <span 
            onClick={()=>{
                dispatch(setViewAll(true))
            }}>View all notifications<img src={DownArrow} alt="" /></span>
        </div>
            :null} */}
            
        </div>
    )
}

export default Notification;