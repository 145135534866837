import {createAction,handleActions} from 'redux-actions';
import ActionTypes from '~shared/constants/actionTypes';

// import {store} from  '../../../redux/store';

const initialState = {
    finance_org_filter : [],
    finance_org_data : [],
    finanace_org_handler : [],
    finance_country_filter : [],
    finance_country_data : [],
    finance_country_handler : [],
    finance_region_filter : [],
    finance_region_data : [],
    finance_region_handler : [],
    finance_sector_filter : [],
    finance_sector_data : [],
    finance_sector_handler : [],
    finance_topic_filter : [],
    finance_topic_data : [],
    finance_topic_handler : [],
    from_home : true,
    data_home_flag : true,
    dev_home_flag : true,
    econ_home_flag : true,
    eval_home_flag : true,
    search_country_array : [],
    query_flag : false,
    date_var : true,
    download_file_path : "",
    finance_start_date_handler : null,
    finance_end_date_handler : null,
    notification_category : null,
    saved_tab: "",
    bookmark_tab : ""
    
  }

//finance 
const setFinanceOrgArray = createAction(ActionTypes.SET_FINANCE_ORG_FILTER, (data) => data);

const setFinanceCountryArray = createAction(ActionTypes.SET_FINANCE_COUNTRY_FILTER)

export const setFinanceCountryFilter = (data) => (dispatch) => {
  dispatch(setFinanceCountryArray(data))
}

export const setFinanceOrgFilter = (data) => (dispatch) => {
  dispatch(setFinanceOrgArray(data));
};

const setFinanceCountry = createAction(ActionTypes.SET_FINANCE_COUNTRY_DATA)

export const setFinanceCountryData = (data) => (dispatch) => {
  dispatch(setFinanceCountry(data))
}

const setFinanceCountryHandlerArray = createAction(ActionTypes.SET_FINANCE_COUNTRY_HANDLER)

export const setFinanceCountryHandler = (data) => (dispatch) =>{
  dispatch(setFinanceCountryHandlerArray(data))
}

const setFinanceOrg = createAction(ActionTypes.SET_FINANCE_ORG_DATA)

export const setFinanceOrgData = (data) => (dispatch) => {
  dispatch(setFinanceOrg(data))
}

const setFinanceOrgHandlerArray = createAction(ActionTypes.SET_FINANCE_ORG_HANDLER)

export const setFinanceOrgHandler = (data) => (dispatch) => {
  dispatch(setFinanceOrgHandlerArray(data))
}

const setFinanceRegionArray =  createAction(ActionTypes.SET_FINANCE_REGION_FILTER)

export const setFinanceRegionFilter = (data) =>(dispatch) =>{
  dispatch(setFinanceRegionArray(data))
}

const setFinanceRegionAction = createAction(ActionTypes.SET_FINANCE_REGION_DATA)

export const setFinanceRegionData = (data) => (dispatch) =>{
  dispatch(setFinanceRegionAction(data))
}

const setFinanceRegionHandlerAction = createAction(ActionTypes.SET_FINANCE_REGION_HANDLER)

export const setFinanceRegionHandler = (data) =>(dispatch)=>{
  dispatch(setFinanceRegionHandlerAction(data))
}

const setFinanceSectorArray =  createAction(ActionTypes.SET_FINANCE_SECTOR_FILTER)

export const setFinanceSectorFilter = (data) =>(dispatch) =>{
  dispatch(setFinanceSectorArray(data))
}

const setFinanceSectorAction = createAction(ActionTypes.SET_FINANCE_SECTOR_DATA)

export const setFinanceSectorData = (data) => (dispatch) =>{
  dispatch(setFinanceSectorAction(data))
}

const setFinanceSectorHandlerAction = createAction(ActionTypes.SET_FINANCE_SECTOR_HANDLER)

export const setFinanceSectorHandler = (data) =>(dispatch)=>{
  dispatch(setFinanceSectorHandlerAction(data))
}

const setFinanceTopicArray =  createAction(ActionTypes.SET_FINANCE_TOPIC_FILTER)

export const setFinanceTopicFilter = (data) =>(dispatch) =>{
  dispatch(setFinanceTopicArray(data))
}

const setFinanceTopicAction = createAction(ActionTypes.SET_FINANCE_TOPIC_DATA)

export const setFinanceTopicData = (data) => (dispatch) =>{
  dispatch(setFinanceTopicAction(data))
}

const setFinanceTopicHandlerAction = createAction(ActionTypes.SET_FINANCE_TOPIC_HANDLER)

export const setFinanceTopicHandler = (data) =>(dispatch)=>{
  dispatch(setFinanceTopicHandlerAction(data))
}

const flagHomeAction = createAction(ActionTypes.SET_HOME_FLAG)

export const setHomeFlagFunction = (data) =>(dispatch)=>{
  dispatch(flagHomeAction(data))
}

const setDataHomeFlagAction = createAction(ActionTypes.DATA_HOME_FLAG)

export const setDataHomeFlagFunction = (data) =>(dispatch)=>{
  dispatch(setDataHomeFlagAction(data))
}

const setDevHomeFlagAction = createAction(ActionTypes.DEV_HOME_FLAG)

export const setDevHomeFlagFunction = (data) =>(dispatch)=>{
  dispatch(setDevHomeFlagAction(data))
}

const setEconHomeFlagAction = createAction(ActionTypes.ECON_HOME_FLAG)

export const setEconHomeFlagFunction = (data) =>(dispatch)=>{
  dispatch(setEconHomeFlagAction(data))
}

const setEvalHomeFlagAction = createAction(ActionTypes.EVAL_HOME_FLAG)

export const setEvalHomeFlagFunction = (data) =>(dispatch)=>{
  dispatch(setEvalHomeFlagAction(data))
}

const setFinanceSearchCountryAction = createAction(ActionTypes.SET_FINANCE_SEARCH_COUNTRIES)
export const setFinanceSearchCountry = (data)=>(dispatch)=>{
  dispatch(setFinanceSearchCountryAction(data))
}

const setQueryFlagAction = createAction(ActionTypes.SET_QUERY_FLAG)
export const setQueryFlag = (data)=>(dispatch)=>{
  dispatch(setQueryFlagAction(data))
}

const setDateTriggerAction = createAction(ActionTypes.SET_DATE_TRIGGER)
export const setDateTrigger = (data)=>(dispatch)=>{
  dispatch(setDateTriggerAction(data))
}

const setDownloadFilePathAction = createAction(ActionTypes.SET_DOWNLOAD_FILE_PATH)
export const setDownloadFilePath = (data)=>(dispatch)=>{
  dispatch(setDownloadFilePathAction(data))
}

const setFinanceStartDateAction = createAction(ActionTypes.SET_FINANCE_START_DATE)
export const setFinanceStartDate = (data)=>(dispatch)=>{
  dispatch(setFinanceStartDateAction(data))
}

const setFinanceEndDateAction = createAction(ActionTypes.SET_FINANCE_END_DATE)
export const setFinanceEndDate = (data)=>(dispatch)=>{
  dispatch(setFinanceEndDateAction(data))
}

const setNotificationCategoryAction = createAction(ActionTypes.SET_NOTIFICATION_CATEGORY)
export const setNotificationCategory = (data)=>(dispatch)=>{
  dispatch(setNotificationCategoryAction(data))
}

const setSavedTabAction = createAction(ActionTypes.SET_SAVED_TAB)
export const setSavedTab =(data)=>(dispatch)=>{
  dispatch(setSavedTabAction(data))
}

const setBookmarkedTabAction = createAction(ActionTypes.SET_BOOKMARK_TAB)
export const setBookmarkedTab =(data)=>(dispatch)=>{
  dispatch(setBookmarkedTabAction(data))
}

const reducer = handleActions({
   
[ActionTypes.SET_FINANCE_ORG_FILTER]: (state, action) => ({
        ...state,
        finance_org_filter: action.payload,
      }),
[ActionTypes.SET_FINANCE_COUNTRY_FILTER]: (state, action) => ({
        ...state,
        finance_country_filter : action.payload,
      }),
[ActionTypes.SET_FINANCE_COUNTRY_DATA]: (state, action) => ({
        ...state,
        finance_country_data : action.payload,
      }),
  [ActionTypes.SET_FINANCE_COUNTRY_HANDLER]: (state, action) => ({
        ...state,
        finance_country_handler : action.payload,
      }),   
  [ActionTypes.SET_FINANCE_ORG_DATA]: (state, action) => ({
        ...state,
        finance_org_data : action.payload,
      }),            
  [ActionTypes.SET_FINANCE_ORG_HANDLER]: (state, action) => ({
        ...state,
        finance_org_handler : action.payload,
      }),
  [ActionTypes.SET_FINANCE_REGION_FILTER]: (state, action) => ({
        ...state,
        finance_region_filter : action.payload,
      }),  
  [ActionTypes.SET_FINANCE_REGION_DATA]: (state, action) => ({
        ...state,
        finance_region_data : action.payload,
      }),                     
  [ActionTypes.SET_FINANCE_REGION_HANDLER]: (state, action) => ({
        ...state,
        finance_region_handler : action.payload,
      }),
  [ActionTypes.SET_FINANCE_SECTOR_FILTER]: (state, action) => ({
        ...state,
        finance_sector_filter : action.payload,
      }),  
  [ActionTypes.SET_FINANCE_SECTOR_DATA]: (state, action) => ({
        ...state,
        finance_sector_data : action.payload,
      }),                     
  [ActionTypes.SET_FINANCE_SECTOR_HANDLER]: (state, action) => ({
        ...state,
        finance_sector_handler : action.payload,
      }), 
  [ActionTypes.SET_FINANCE_TOPIC_FILTER]: (state, action) => ({
        ...state,
        finance_topic_filter : action.payload,
      }),  
  [ActionTypes.SET_FINANCE_TOPIC_DATA]: (state, action) => ({
        ...state,
        finance_topic_data : action.payload,
      }),                     
  [ActionTypes.SET_FINANCE_TOPIC_HANDLER]: (state, action) => ({
        ...state,
        finance_topic_handler : action.payload,
      }),  
  [ActionTypes.SET_HOME_FLAG]: (state, action) => ({
        ...state,
        from_home : action.payload,
      }), 
  [ActionTypes.DATA_HOME_FLAG]: (state, action) => ({
        ...state,
        data_home_flag : action.payload,
      }),
  [ActionTypes.DEV_HOME_FLAG]: (state, action) => ({
        ...state,
        dev_home_flag : action.payload,
      }),  
  [ActionTypes.ECON_HOME_FLAG]: (state, action) => ({
        ...state,
        econ_home_flag : action.payload,
      }),    
  [ActionTypes.EVAL_HOME_FLAG]: (state, action) => ({
        ...state,
        eval_home_flag : action.payload,
      }),
  [ActionTypes.SET_FINANCE_SEARCH_COUNTRIES]: (state, action) => ({
        ...state,
        search_country_array : action.payload,
      }), 
  [ActionTypes.SET_QUERY_FLAG]: (state, action) => ({
        ...state,
        query_flag : action.payload,
      }), 
  [ActionTypes.SET_DATE_TRIGGER]: (state, action) => ({
        ...state,
        date_var : action.payload,
      }), 
  [ActionTypes.SET_DOWNLOAD_FILE_PATH]: (state, action) => ({
        ...state,
        download_file_path : action.payload,
      }), 
  [ActionTypes.SET_FINANCE_START_DATE]: (state, action) => ({
        ...state,
        finance_start_date_handler : action.payload,
      }),
  [ActionTypes.SET_FINANCE_END_DATE]: (state, action) => ({
        ...state,
        finance_end_date_handler : action.payload,
      }), 
      
  [ActionTypes.SET_NOTIFICATION_CATEGORY]: (state, action) => ({
        ...state,
        notification_category : action.payload,
      }),
  [ActionTypes.SET_SAVED_TAB]: (state, action) => ({
        ...state,
        saved_tab : action.payload,
      }),
  [ActionTypes.SET_BOOKMARK_TAB]: (state, action) => ({
        ...state,
        bookmark_tab : action.payload,
      }),                    
},initialState);


export default reducer;

