import React, { useState } from "react";
import CustomContents from "../components/customcontents/container";
import ChatResults from "../components/chatresults";
import {useSelector} from 'react-redux'

function ChatContent() {
  const chatResponse = useSelector((state) => state?.chat?.chatResponse);
  const isNewChat = useSelector((state)=>state?.chat?.new_chat)
  return (
    <div className="chat-switching-wrap h-100">
     {isNewChat===true?<CustomContents />:<ChatResults />}
    </div>
  );
}

export default ChatContent;
