import React from "react";

function NewChatIcon() {
  return (
    <span className="icon-wrap">
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Layer 2">
          <g id="Layer 1">
            <path
              id="Vector"
              d="M20.7632 8.31355C19.4747 2.448 13.6651 -1.17753 7.8597 0.349571C1.01149 2.15207 -2.05191 9.87958 1.47379 15.8442C1.52749 15.921 1.55946 16.0109 1.56631 16.1044C1.57317 16.1978 1.55465 16.2914 1.51273 16.3752C1.03131 17.4995 0.571129 18.6322 0.096788 19.76C-0.0448065 20.1013 -0.0235673 20.4192 0.219267 20.6988C0.462102 20.9785 0.754494 21.0337 1.10636 20.9516C2.60726 20.6011 4.1117 20.2648 5.6126 19.9144C5.73907 19.8846 5.87208 19.903 5.9857 19.9661C7.38898 20.6403 8.92589 20.9904 10.4827 20.9905C11.2772 20.9881 12.0689 20.8986 12.8438 20.7236C18.3809 19.4917 21.9802 13.8527 20.7632 8.31355ZM13.8067 11.3451C13.0647 11.3451 12.3199 11.355 11.5822 11.3401C11.3755 11.3359 11.3394 11.3989 11.3429 11.5886C11.3543 12.3299 11.3493 13.0711 11.3465 13.8131C11.3465 14.3497 10.9925 14.7334 10.5026 14.7334C10.0126 14.7334 9.653 14.3511 9.653 13.8131C9.653 13.0626 9.6445 12.3122 9.65724 11.5624C9.66078 11.3699 9.60202 11.3338 9.4222 11.3366C8.68095 11.3472 7.9397 11.3423 7.19846 11.3401C6.64411 11.3401 6.25685 10.9826 6.26252 10.4828C6.26818 9.99427 6.6519 9.64737 7.1935 9.64595C7.93475 9.64595 8.67599 9.63675 9.41724 9.65162C9.62468 9.65586 9.66432 9.59285 9.65654 9.40241C9.64096 9.02364 9.65229 8.64346 9.65229 8.26399C9.65229 7.88452 9.64804 7.50504 9.65229 7.12557C9.65937 6.62999 10.0275 6.25689 10.4969 6.25618C10.6097 6.25616 10.7214 6.27862 10.8255 6.32224C10.9295 6.36586 11.0239 6.42978 11.103 6.51025C11.182 6.59072 11.2443 6.68614 11.2861 6.79093C11.3279 6.89573 11.3484 7.0078 11.3465 7.12062C11.3528 7.88806 11.3557 8.65621 11.3465 9.42436C11.3465 9.61622 11.4017 9.65303 11.5808 9.6502C12.3221 9.63958 13.064 9.64454 13.8053 9.64595C14.3596 9.64595 14.7469 10.0042 14.7412 10.504C14.727 10.9968 14.344 11.3437 13.8067 11.3451Z"
              fill="#1A73E8"
            />
          </g>
        </g>
      </svg>
    </span>
  );
}

export default NewChatIcon;
