import React,{useState,useEffect,useRef} from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SlickNext from '../../../../assets/images/icons/arrow-right.png';
import SlickPrev from '../../../../assets/images/icons/arrow-left.png';
import DevpubsSlider from './components/devpubsslider';
import { Link } from "react-router-dom";
import ViewSimilar from '../../../../assets/images/icons/viewsimilar.png';
import {Col,Row} from 'react-bootstrap'
import moment from "moment";
import Tippy from "@tippy.js/react";
import "tippy.js/dist/tippy.css";
import {useSelector,shallowEqual,useDispatch} from 'react-redux'
import {recordDetails,bookmarkRecords, userListRecordShared,UserListSaveQuery,voteUp,setViewSimilarId,
  setFCountryKey,
  setFRegionKey,
  setFSectorKey,
  setFTopicKey,
  setFSourceKey,
  setFStartDateKey,
  setFEndDateKey} from './../../../results/services'
  import {setFinanceOrgFilter,
    setFinanceOrgData,
    setFinanceOrgHandler,
    setFinanceCountryFilter,
    setFinanceCountryData,
    setFinanceCountryHandler,
    setFinanceRegionFilter,
    setFinanceRegionData,
    setFinanceRegionHandler,
    setFinanceSectorData,
    setFinanceSectorFilter,
    setFinanceSectorHandler,
    setFinanceTopicFilter,
    setFinanceTopicData,
    setFinanceTopicHandler,
    setFinanceSearchCountry,
  setDateTrigger,
setFinanceEndDate,
setFinanceStartDate} from './../../../results/filterServices'
import ShareRecordPopup from "./../../../../shared/shareRecordPopup";
import { Modal } from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import ShowMoreText from "react-show-more-text";
import ScrollArea from "react-scrollbar";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ShimmerLoading from '../../../../shared/shimmerloading/shimmerloading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ShimmerLoadingWithText from '../../../../shared/shimmerloadingwithtext/shimmerloadingwithtext';
function Devpubs() {
    const result  = useSelector((state)=>state?.results?.userRecomendations?.response?.data?.data?.devpubs,shallowEqual)
    const loading = useSelector((state)=>state?.results?.userRecomendations?.loading)
    const [records,setRecords] = useState([])
    const navigate = useNavigate()
    useEffect(()=>{
        if(result){setRecords(result)}
    },[result])
    const [liked,setLiked] = useState(true)
    const [bookmarked,setBookmarked] = useState(true)
    const [projectInfo,setProjectInfo] = useState()
    const dispatch = useDispatch()
    const token = useSelector((store)=>store?.auth?.signin?.response?.data?.data?.token,shallowEqual)
    const recordStatus = useSelector((state)=>state?.results?.recordDetails?.response?.data?.data?.element,shallowEqual)
    const [recordsWithStatus,setRecordsWithStatus] = useState(recordsWithStatus)
    const [AccessShow, AccessSetShow] = useState(false);
    const AccesshandleClose = () => AccessSetShow(false);
    const AccesshandleShow = () => AccessSetShow(true);
    const [projId,setProjId] = useState()
    const[projTitle,setProjTitle] = useState()
    const [proAbs,setProjAbs] = useState()
    const [projLink,setProjLink] = useState()
    const [projOrgId,setProOrgId] = useState()
    const tokenSso = useSelector(
        (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token,shallowEqual
      );

    useEffect(()=>{
        if(result) {
            const projectId =  result.map((item)=>{return item?._id})
            setProjectInfo(projectId)
        }
    },[])

    const bookmark_state = useSelector((state)=>state?.results?.bookmark)
    const bookmark_ref = useRef(bookmark_state)
    bookmark_ref.current = bookmark_state  
   
    //vote fn
    const toggleVote =(project_id,name,short_description,project_link,category,org_id,like)=>{
        const data={
            "project_id":project_id,
            "title":name,
            "abstract":short_description,
            "link_html":project_link,
            "category":category,
            "like":like,
            "organisation_id":org_id
        }
        if(token!==undefined){
            dispatch(voteUp(data,token)).then(()=>{
                setLiked(!liked)
                
            })
        }else if(tokenSso!==undefined){
            dispatch(voteUp(data,tokenSso)).then(()=>{
                setLiked(!liked)
                
            })
        }
       

    }  
    const afterVote=(project_id)=>{
        let recordTemp = [...(records||[])]
        for(let i =0;i<recordTemp.length;i++){
            const p=recordTemp[i]
            
            if(p.project_id===project_id){
               
                
               
                    recordTemp[i].record_details.vote_up_count=recordTemp[i].record_details.vote_up_count+1
                    recordTemp[i].record_details.vote_up = true
                
            }
        }
        setRecords(recordTemp)   
    }
    const afterUnVote=(project_id)=>{
        let recordTemp = [...(records||[])]
        for(let i =0;i<recordTemp.length;i++){
            const p=recordTemp[i]
            
            if(p.project_id===project_id){
                
               
                    recordTemp[i].record_details.vote_up_count=recordTemp[i].record_details.vote_up_count-1
                    recordTemp[i].record_details.vote_up = false
                
            }
        }
        setRecords(recordTemp)   
    }

    const afterBookmark=(project_id)=>{
        let recordTemp = [...(records||[])]
        for(let i =0;i<recordTemp.length;i++){
            const p=recordTemp[i]
            
            if(p.project_id===project_id){
                 recordTemp[i].record_details.bookmark = true
                }
        }
        setRecords(recordTemp)   
    }
    const afterUnBookmark=(project_id)=>{
        let recordTemp = [...(records||[])]
        for(let i =0;i<recordTemp.length;i++){
            const p=recordTemp[i]
            
            if(p.project_id===project_id){
                 recordTemp[i].record_details.bookmark = false
                }
        }
        setRecords(recordTemp)   
    }

    //bookmark fn
    const toggleBookmark = (project_id,title,abstract,link_html,category,bookmarks,organisation_id)=>{

        const data = {
            "project_id" : project_id,
            "title" : title,
            "abstract" : abstract,
            "link_html" : link_html,
            "category" : category,
            "bookmark" : bookmarks,
            "organisation_id" : organisation_id

        }
        if(token!==undefined){
            dispatch(bookmarkRecords(data,token)).then(()=>{
                setBookmarked(!bookmarked)
                if(!bookmark_ref?.current?.hasError&&bookmark_ref?.current?.response?.data?.data?.bookmark==="Record Bookmarked"){
                  toast.success("Bookmarked successfully, please find it in the Bookmarks tab!")
                }
               
            })
        }else if(tokenSso!==undefined){
            dispatch(bookmarkRecords(data,tokenSso)).then(()=>{
                setBookmarked(!bookmarked)
                if(!bookmark_ref?.current?.hasError&&bookmark_ref?.current?.response?.data?.data?.bookmark==="Record Bookmarked"){
                  toast.success("Bookmarked successfully, please find it in the Bookmarks tab!")
                }
               
            })
        }
       

    }
    const userList =()=>{
        if(token!==undefined){
            dispatch(UserListSaveQuery(token,"","","","","False"))
        }else if(tokenSso!==undefined){
            dispatch(UserListSaveQuery(tokenSso,"","","","","False"))
        }
       }
       const recordUserList =(projectId)=>{
        if(token!==undefined){
            dispatch(userListRecordShared(projectId,token))
        }else if(tokenSso!==undefined){
            dispatch(userListRecordShared(projectId,tokenSso))
        }
        
    }

    const clearFinanceFilters = ()=>{
      dispatch(setFSourceKey(""))
      dispatch(setFCountryKey(""))
      dispatch(setFRegionKey(""))
      dispatch(setFTopicKey(""))
      dispatch(setFSectorKey(""))
      dispatch(setFStartDateKey(""))
      dispatch(setFEndDateKey(""))
      dispatch(setFinanceCountryData([]));
      dispatch(setFinanceOrgData([]));
      dispatch(setFinanceCountryHandler([]));
      dispatch(setFinanceOrgHandler([]));
      dispatch(setFinanceRegionData([]));
      dispatch(setFinanceRegionHandler([]));
      dispatch(setFinanceSectorHandler([]));
      dispatch(setFinanceSectorData([]));
      dispatch(setFinanceTopicData([]));
      dispatch(setFinanceTopicHandler([]));
      dispatch(setFinanceCountryFilter([]))
      dispatch(setFinanceOrgFilter([]))
      dispatch(setFinanceRegionFilter([]))
      dispatch(setFinanceSectorFilter([]))
      dispatch(setFinanceTopicFilter([]))
      dispatch(setFinanceSearchCountry([]));
      dispatch(setDateTrigger(true))
      dispatch(setFinanceEndDate(null))
      dispatch(setFinanceStartDate(null))
    }

    function NextArrow(props) {
      const { className, style, onClick } = props;
      return (
        <div  className={className} onClick={onClick}>
          <img src={SlickNext} alt="" />
          </div>
      );
    }
    function PrevArrow(props) {
      const { className, style, onClick } = props;
      return (
        <div  className={className} onClick={onClick}>
          <img src={SlickPrev} alt="" />
          </div>
      );
    }
    const sliderSettings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow:3,
      nextArrow:<NextArrow/>,
      prevArrow:<PrevArrow/>,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    const sliderSettingsTwo = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 2,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
        <div className="finance-wrap">
            <h4 className="sec-sub-head primary-color sub-head-margin">Publications</h4>
            {loading?
      <Row className="rec-shimmer-row">
      <Col className="shimmer-col-1">
        <ShimmerLoading/>
      </Col>
      <Col className="shimmer-col-2">
      <ShimmerLoading/>
      </Col>
      <Col className="shimmer-col-3">
      <ShimmerLoading/>
      </Col>
    </Row>:
    <>
     {!result&&
      <Row className="rec-shimmer-row">
      <Col className="shimmer-col-1">
        <ShimmerLoading/>
      </Col>
      <Col className="shimmer-col-2">
      <ShimmerLoading/>
      </Col>
      <Col className="shimmer-col-3">
      <ShimmerLoading/>
      </Col>
    </Row>
    }
    {result?.length>2&&
    <Slider {...sliderSettings} className="homeslider">
                {result&&result.map((item,i)=>{
                    return(
                        <div className="slider-item">
                        <div className="d-flex slider-wrap" >
                            <div className="sourcebox source-1">
                        <a
                        href={item?.link_html}
                        style={{ textDecoration: "none", color: "inherit" }}
                        target="_blank"
                      >
                      <h3>{item?.title.length>70?item.title.substring(0,70)+"...":item?.title}</h3>
                      </a>
                        <div className="source-content">
                        <div className="d-flex multi-label-wrap source-label-split d-flex justify-content-between align-items-center m-b-20">
                      {/* <label className="source-label">{item?.org_name}</label>
                      <div className="reco-date">Dec 2022</div> */}
                       {item?.org_name && (
                            <Tippy content={item?.org_name}>
                              <label className="source-label">
                                {item?.org_name?.length>36?item?.org_name.substring(0,35)+"...":item?.org_name}
                              </label>
                            </Tippy>
                          )}
                      <div className="reco-date">{moment(item?.date).format("MMM YYYY")}</div>
                    </div>
                            {/* <p>{item?.abstract}</p> */}
                            <ShowMoreText
                          lines={2}
                          more="View more"
                          less="View less"
                          className="more-text-wrap"
                          anchorClass="showmore-button"
                          expanded={false}
                          truncatedEndingComponent={"..."}
                        >
                               <ScrollArea
                        speed={0.8}
                        className="home-desc-scroll"
                        contentClassName="content"
                        horizontal={false}
                    >
                          {item?.abstract}
                          </ScrollArea>
                        </ShowMoreText>
                        </div>
                        <div className="source-footer d-flex justify-content-between align-items-center">
                            <div className="action-buttons-wrap d-flex">
                             
                            <button
                          type="button"
                          className="icon-button liked"
                          onClick={() => {
                            item?.record_details?.vote_up
                              ? (toggleVote(
                                  item?.project_id,
                                  item?.title,
                                  item?.abstract,
                                  item?.link_html,
                                  "devpubs",
                                  item?.org_id,
                                  0
                                ),
                                (item.record_details.vote_up = false),
                                (item.record_details.vote_up_count =
                                  item.record_details.vote_up_count - 1))
                              : (toggleVote(
                                  item?.project_id,
                                  item?.title,
                                  item?.abstract,
                                  item?.link_html,
                                  "devpubs",
                                  item?.org_id,
                                  1
                                ),
                                (item.record_details.vote_up = true),
                                (item.record_details.vote_up_count =
                                  item.record_details.vote_up_count + 1));
                          }}
                        >
                          <span className="like-count">
                            {item?.record_details?.vote_up_count}
                          </span>
                          {item.record_details?.vote_up ? (
                             <OverlayTrigger
                             overlay={
                               <Tooltip id="InfoText">
                                 Vote up
                               </Tooltip>
                             }
                           >
                            <svg
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ fill: "#1A73E8" }}
                            >
                              <path d="M18.3279 7.15H14.6623L14.8718 5.335C15.186 2.64 14.6623 0.495 12.9866 0H12.8295C12.6564 0.0319322 12.4937 0.109127 12.3564 0.224469C12.2192 0.339811 12.1117 0.48959 12.0441 0.66C12.0441 0.66 9.05923 7.26 7.33117 8.58V19.8H8.01192C8.1254 19.7899 8.23965 19.8042 8.34769 19.842C8.45574 19.8798 8.5553 19.9404 8.64031 20.02C9.37343 20.57 11.8346 22 13.0914 22H15.7097C18.7992 22 21.4699 19.8 21.7317 15.455L21.9935 11.055C22.0235 10.5387 21.9496 10.0215 21.7767 9.53721C21.6037 9.05287 21.3357 8.6122 20.9899 8.24385C20.6442 7.8755 20.2284 7.58774 19.7698 7.39928C19.3112 7.21082 18.8199 7.12588 18.3279 7.15ZM0 9.9V18.7C0 18.9917 0.110341 19.2715 0.30675 19.4778C0.503159 19.6841 0.769546 19.8 1.04731 19.8H5.23655V8.8H1.04731C0.769546 8.8 0.503159 8.91589 0.30675 9.12218C0.110341 9.32847 0 9.60826 0 9.9Z" />
                            </svg>
                            </OverlayTrigger>
                          ) : (
                            <OverlayTrigger
                            overlay={
                              <Tooltip id="InfoText">
                                Vote up
                              </Tooltip>
                            }
                          >
                            <svg
                              width="23"
                              height="22"
                              viewBox="0 0 23 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19.1635 7.15H15.3308L15.5498 5.335C15.8783 2.64 15.3308 0.495 13.5787 0H13.4144C13.2334 0.0319322 13.0633 0.109127 12.9197 0.224469C12.7762 0.339811 12.6639 0.48959 12.5931 0.66C12.5931 0.66 8.76044 8.8 7.11786 8.8H1.09505C0.804628 8.8 0.526097 8.91589 0.320734 9.12218C0.115371 9.32847 0 9.60826 0 9.9V18.7C0 18.9917 0.115371 19.2715 0.320734 19.4778C0.526097 19.6841 0.804628 19.8 1.09505 19.8H8.37717C8.49582 19.7899 8.61528 19.8042 8.72825 19.842C8.84121 19.8798 8.94532 19.9404 9.0342 20.02C9.80074 20.57 12.3741 22 13.6882 22H16.4258C19.6562 22 22.4486 19.8 22.7224 15.455L22.9961 11C23.0195 10.4882 22.9364 9.97707 22.752 9.49934C22.5677 9.02161 22.2862 8.58774 21.9255 8.22544C21.5649 7.86314 21.133 7.58038 20.6574 7.39521C20.1818 7.21003 19.673 7.12652 19.1635 7.15ZM2.19011 11H5.47527V17.6H2.19011V11ZM20.5323 15.29C20.3133 18.15 18.7802 19.8 16.4258 19.8H13.6882C13.0311 19.745 11.0601 18.755 10.3483 18.26C9.78349 17.8242 9.08929 17.5918 8.37717 17.6H7.66538V11C8.70568 10.78 10.403 9.79 13.4144 3.96C13.4211 4.34578 13.4028 4.7316 13.3597 5.115L13.1407 6.93L12.8669 9.35H19.1635C19.3805 9.3249 19.6004 9.34844 19.8073 9.41891C20.0141 9.48938 20.2029 9.60503 20.36 9.75756C20.5171 9.91008 20.6385 10.0957 20.7157 10.301C20.7928 10.5063 20.8237 10.7263 20.806 10.945L20.5323 15.29Z"
                                fill="#373737"
                              />
                            </svg>
                            </OverlayTrigger>
                          )}
                        </button>
                        <button
                          type="button"
                          className="icon-button"
                          onClick={() => {
                            AccesshandleShow(),
                              recordUserList(item?.project_id),
                              setProjId(item?.project_id);
                            setProjTitle(item?.title);
                            setProjAbs(item?.abstract);
                            setProjLink(item?.link_html);
                            setProOrgId(item?.org_id);
                            userList();
                          }}
                        >
                             <OverlayTrigger
                             overlay={
                               <Tooltip id="InfoText">
                                 Share
                               </Tooltip>
                             }
                           >
                          <svg
                            width="22"
                            height="20"
                            viewBox="0 0 22 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.606 4.55599L19.508 10.0556L14.606 15.4996V11.5554L12.5051 11.6665C9.10373 11.8332 6.50267 12.222 4.45182 12.9998C6.25256 10.8888 9.10373 9.22228 12.8052 8.83343L14.606 8.66677V4.55599ZM13.1554 0.000794509C13.0765 0.00779243 12.9996 0.0320624 12.9291 0.0722063C12.8587 0.11235 12.7961 0.167575 12.7449 0.2347C12.6938 0.301825 12.6551 0.379523 12.6311 0.463321C12.6071 0.547118 12.5983 0.635359 12.6052 0.722959V6.61138C5.00205 7.44465 0.100041 13.222 0 19.0548C0 19.3881 0.100041 19.6103 0.300123 19.6103C0.500205 19.6103 0.650267 19.4437 0.850349 18.9993C2.05084 15.9995 4.75195 14.2774 12.6052 13.8886V19.277C12.5983 19.3646 12.6071 19.4529 12.6311 19.5367C12.6551 19.6205 12.6938 19.6982 12.7449 19.7653C12.7961 19.8324 12.8587 19.8876 12.9291 19.9278C12.9996 19.9679 13.0765 19.9922 13.1554 19.9992C13.2337 20.0041 13.3119 19.9862 13.3821 19.9472C13.4523 19.9082 13.5121 19.8496 13.5556 19.777L21.8089 10.6111C21.932 10.4583 22 10.2605 22 10.0556C22 9.85059 21.932 9.65281 21.8089 9.50004L13.5556 0.222999C13.5121 0.150438 13.4523 0.0917596 13.3821 0.0527823C13.3119 0.013805 13.2337 -0.00411673 13.1554 0.000794509Z"
                              fill="#373737"
                            />
                          </svg>
                          </OverlayTrigger>
                        </button>
                        <button
                          type="button"
                          className="icon-button"
                          onClick={() => {
                            item?.record_details?.bookmark
                              ? (toggleBookmark(
                                  item?.project_id,
                                  item?.title,
                                  item?.abstract,
                                  item?.link_html,
                                  "devpubs",
                                  0,
                                  item?.org_id
                                ),
                                (item.record_details.bookmark = false))
                              : (toggleBookmark(
                                  item?.project_id,
                                  item?.title,
                                  item?.abstract,
                                  item?.link_html,
                                  "devpubs",
                                  1,
                                  item?.org_id
                                ),
                                (item.record_details.bookmark = true));
                          }}
                        >
                          {item?.record_details?.bookmark ? (
                              <OverlayTrigger
                              overlay={
                                <Tooltip id="InfoText">
                                  Bookmark
                                </Tooltip>
                              }
                            >
                            <svg
                              id="Group_2176"
                              data-name="Group 2176"
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                            >
                              <g
                                id="icons_Q2"
                                data-name="icons Q2"
                                transform="translate(-61 -9)"
                              >
                                <path
                                  id="Path_2366"
                                  data-name="Path 2366"
                                  d="M23.895,7.316V23.526l-4.4-3.358-1.389-1.042-1.389,1.042-4.4,3.358V7.316H23.895M11.158,5A1.158,1.158,0,0,0,10,6.158V25.842A1.158,1.158,0,0,0,11.158,27a1.389,1.389,0,0,0,.811-.289l6.137-4.689,6.137,4.689a1.389,1.389,0,0,0,.811.289,1.158,1.158,0,0,0,1.158-1.158V6.158A1.158,1.158,0,0,0,25.053,5Z"
                                  transform="translate(54 4)"
                                  fill="#0D6EFD"
                                />
                                <path
                                  id="Path_2367"
                                  data-name="Path 2367"
                                  d="M-126.668-6515.948v18.35l6.819-5.212,6.819,5.212v-18.35Z"
                                  transform="translate(192 6527)"
                                  fill="#0D6EFD"
                                />
                              </g>
                              <rect
                                id="Rectangle_2567"
                                data-name="Rectangle 2567"
                                width="22"
                                height="22"
                                fill="none"
                              />
                            </svg>
                            </OverlayTrigger>
                          ) : (
                            <OverlayTrigger
                            overlay={
                              <Tooltip id="InfoText">
                                Bookmark
                              </Tooltip>
                            }
                          >
                            <svg
                              id="Group_2175"
                              data-name="Group 2175"
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                            >
                              <g
                                id="icons_Q2"
                                data-name="icons Q2"
                                transform="translate(-61 -9)"
                              >
                                <path
                                  id="Path_2366"
                                  data-name="Path 2366"
                                  d="M23.895,7.316V23.526l-4.4-3.358-1.389-1.042-1.389,1.042-4.4,3.358V7.316H23.895M11.158,5A1.158,1.158,0,0,0,10,6.158V25.842A1.158,1.158,0,0,0,11.158,27a1.389,1.389,0,0,0,.811-.289l6.137-4.689,6.137,4.689a1.389,1.389,0,0,0,.811.289,1.158,1.158,0,0,0,1.158-1.158V6.158A1.158,1.158,0,0,0,25.053,5Z"
                                  transform="translate(54 4)"
                                />
                              </g>
                              <rect
                                id="Rectangle_2567"
                                data-name="Rectangle 2567"
                                width="22"
                                height="22"
                                fill="none"
                              />
                            </svg>
                            </OverlayTrigger>
                          )}
                        </button>  
                            </div>
                            <div className="link-button-wrap">
                                <Link to="/devpubs-recomendations" onClick={()=>{
                                    dispatch(setViewSimilarId(item?.project_id))
                                    clearFinanceFilters()
                                }} className="view-similar-btn d-flex"><img src={ViewSimilar} alt="viewsimilar" /><span>View Similar</span></Link>
                            </div>
                        </div>
                    </div>
                   
                    </div>
                    
                        </div>
                    )
                })}
            </Slider>
}
{result&&result?.length===1&&result?.map((item,i)=>{
        return(
          
          <Row>
           <Col md={4}>
           <div className="slider-item">
                <div className="d-flex slider-wrap" >
                  <div className="sourcebox source-1">
                    <a
                      href={item?.link_html}
                      style={{ textDecoration: "none", color: "inherit" }}
                      target="_blank"
                    >
                      <h3>
                        {item?.title.length > 70 ? item.title.substring(0, 70) + "..." : item?.title}
                      </h3>

                    </a>

                    <div className="source-content">
                    <div className="d-flex multi-label-wrap source-label-split d-flex justify-content-between align-items-center m-b-20">
                      <label className="source-label">{item?.org_name}</label>
                      <div className="reco-date">Dec 2022</div>
                    </div>
                      <ShowMoreText
                        lines={2}
                        more="View more"
                        less="View less"
                        className="more-text-wrap"
                        anchorClass="showmore-button"
                        expanded={false}
                        truncatedEndingComponent={"..."}
                      >
                        <ScrollArea
                          speed={0.8}
                          className="home-desc-scroll"
                          contentClassName="content"
                          horizontal={false}
                        >
                          {item.abstract}
                        </ScrollArea>
                      </ShowMoreText>
                    </div>
                    <div className="source-footer d-flex justify-content-between align-items-center">
                      <div className="action-buttons-wrap d-flex">
                        <button
                          type="button"
                          className="icon-button liked"
                          onClick={() => {
                            item?.record_details?.vote_up
                              ? (toggleVote(
                                item?.project_id,
                                item?.title,
                                item?.abstract,
                                item?.link_html,
                                "devpubs",
                                item?.org_id,
                                0
                              ),
                                (item.record_details.vote_up = false),
                                (item.record_details.vote_up_count =
                                  item.record_details.vote_up_count - 1))
                              : (toggleVote(
                                item?.project_id,
                                item?.title,
                                item?.abstract,
                                item?.link_html,
                                "devpubs",
                                item?.org_id,
                                1
                              ),
                                (item.record_details.vote_up = true),
                                (item.record_details.vote_up_count =
                                  item.record_details.vote_up_count + 1));
                          }}
                        >
                          <span className="like-count">
                            {item?.record_details?.vote_up_count}
                          </span>
                          {item.record_details?.vote_up ? (
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="InfoText">
                                  Vote up
                                </Tooltip>
                              }
                            >
                              <svg
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ fill: "#1A73E8" }}
                              >
                                <path d="M18.3279 7.15H14.6623L14.8718 5.335C15.186 2.64 14.6623 0.495 12.9866 0H12.8295C12.6564 0.0319322 12.4937 0.109127 12.3564 0.224469C12.2192 0.339811 12.1117 0.48959 12.0441 0.66C12.0441 0.66 9.05923 7.26 7.33117 8.58V19.8H8.01192C8.1254 19.7899 8.23965 19.8042 8.34769 19.842C8.45574 19.8798 8.5553 19.9404 8.64031 20.02C9.37343 20.57 11.8346 22 13.0914 22H15.7097C18.7992 22 21.4699 19.8 21.7317 15.455L21.9935 11.055C22.0235 10.5387 21.9496 10.0215 21.7767 9.53721C21.6037 9.05287 21.3357 8.6122 20.9899 8.24385C20.6442 7.8755 20.2284 7.58774 19.7698 7.39928C19.3112 7.21082 18.8199 7.12588 18.3279 7.15ZM0 9.9V18.7C0 18.9917 0.110341 19.2715 0.30675 19.4778C0.503159 19.6841 0.769546 19.8 1.04731 19.8H5.23655V8.8H1.04731C0.769546 8.8 0.503159 8.91589 0.30675 9.12218C0.110341 9.32847 0 9.60826 0 9.9Z" />
                              </svg>
                            </OverlayTrigger>
                          ) : (
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="InfoText">
                                  Vote up
                                </Tooltip>
                              }
                            >
                              <svg
                                width="23"
                                height="22"
                                viewBox="0 0 23 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19.1635 7.15H15.3308L15.5498 5.335C15.8783 2.64 15.3308 0.495 13.5787 0H13.4144C13.2334 0.0319322 13.0633 0.109127 12.9197 0.224469C12.7762 0.339811 12.6639 0.48959 12.5931 0.66C12.5931 0.66 8.76044 8.8 7.11786 8.8H1.09505C0.804628 8.8 0.526097 8.91589 0.320734 9.12218C0.115371 9.32847 0 9.60826 0 9.9V18.7C0 18.9917 0.115371 19.2715 0.320734 19.4778C0.526097 19.6841 0.804628 19.8 1.09505 19.8H8.37717C8.49582 19.7899 8.61528 19.8042 8.72825 19.842C8.84121 19.8798 8.94532 19.9404 9.0342 20.02C9.80074 20.57 12.3741 22 13.6882 22H16.4258C19.6562 22 22.4486 19.8 22.7224 15.455L22.9961 11C23.0195 10.4882 22.9364 9.97707 22.752 9.49934C22.5677 9.02161 22.2862 8.58774 21.9255 8.22544C21.5649 7.86314 21.133 7.58038 20.6574 7.39521C20.1818 7.21003 19.673 7.12652 19.1635 7.15ZM2.19011 11H5.47527V17.6H2.19011V11ZM20.5323 15.29C20.3133 18.15 18.7802 19.8 16.4258 19.8H13.6882C13.0311 19.745 11.0601 18.755 10.3483 18.26C9.78349 17.8242 9.08929 17.5918 8.37717 17.6H7.66538V11C8.70568 10.78 10.403 9.79 13.4144 3.96C13.4211 4.34578 13.4028 4.7316 13.3597 5.115L13.1407 6.93L12.8669 9.35H19.1635C19.3805 9.3249 19.6004 9.34844 19.8073 9.41891C20.0141 9.48938 20.2029 9.60503 20.36 9.75756C20.5171 9.91008 20.6385 10.0957 20.7157 10.301C20.7928 10.5063 20.8237 10.7263 20.806 10.945L20.5323 15.29Z"
                                  fill="#373737"
                                />
                              </svg>
                            </OverlayTrigger>
                          )}
                        </button>
                        <button
                          type="button"
                          className="icon-button"
                          onClick={() => {
                            AccesshandleShow(),
                              recordUserList(item?.project_id),
                              setProjId(item?.project_id);
                            setProjTitle(item?.title);
                            setProjAbs(item?.abstract);
                            setProjLink(item?.link_html);
                            setProOrgId(item?.org_id);
                            userList();
                          }}
                        >
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="InfoText">
                                Share
                              </Tooltip>
                            }
                          >
                            <svg
                              width="22"
                              height="20"
                              viewBox="0 0 22 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14.606 4.55599L19.508 10.0556L14.606 15.4996V11.5554L12.5051 11.6665C9.10373 11.8332 6.50267 12.222 4.45182 12.9998C6.25256 10.8888 9.10373 9.22228 12.8052 8.83343L14.606 8.66677V4.55599ZM13.1554 0.000794509C13.0765 0.00779243 12.9996 0.0320624 12.9291 0.0722063C12.8587 0.11235 12.7961 0.167575 12.7449 0.2347C12.6938 0.301825 12.6551 0.379523 12.6311 0.463321C12.6071 0.547118 12.5983 0.635359 12.6052 0.722959V6.61138C5.00205 7.44465 0.100041 13.222 0 19.0548C0 19.3881 0.100041 19.6103 0.300123 19.6103C0.500205 19.6103 0.650267 19.4437 0.850349 18.9993C2.05084 15.9995 4.75195 14.2774 12.6052 13.8886V19.277C12.5983 19.3646 12.6071 19.4529 12.6311 19.5367C12.6551 19.6205 12.6938 19.6982 12.7449 19.7653C12.7961 19.8324 12.8587 19.8876 12.9291 19.9278C12.9996 19.9679 13.0765 19.9922 13.1554 19.9992C13.2337 20.0041 13.3119 19.9862 13.3821 19.9472C13.4523 19.9082 13.5121 19.8496 13.5556 19.777L21.8089 10.6111C21.932 10.4583 22 10.2605 22 10.0556C22 9.85059 21.932 9.65281 21.8089 9.50004L13.5556 0.222999C13.5121 0.150438 13.4523 0.0917596 13.3821 0.0527823C13.3119 0.013805 13.2337 -0.00411673 13.1554 0.000794509Z"
                                fill="#373737"
                              />
                            </svg>
                          </OverlayTrigger>
                        </button>
                        <button
                          type="button"
                          className="icon-button"
                          onClick={() => {
                            item?.record_details?.bookmark
                              ? (toggleBookmark(
                                item?.project_id,
                                item?.title,
                                item?.abstract,
                                item?.link_html,
                                "devpubs",
                                0,
                                item?.org_id
                              ),
                                (item.record_details.bookmark = false))
                              : (toggleBookmark(
                                item?.project_id,
                                item?.title,
                                item?.abstract,
                                item?.link_html,
                                "devpubs",
                                1,
                                item?.org_id
                              ),
                                (item.record_details.bookmark = true));
                          }}
                        >
                          {item?.record_details?.bookmark ? (
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="InfoText">
                                  Bookmark
                                </Tooltip>
                              }
                            >
                              <svg
                                id="Group_2176"
                                data-name="Group 2176"
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                              >
                                <g
                                  id="icons_Q2"
                                  data-name="icons Q2"
                                  transform="translate(-61 -9)"
                                >
                                  <path
                                    id="Path_2366"
                                    data-name="Path 2366"
                                    d="M23.895,7.316V23.526l-4.4-3.358-1.389-1.042-1.389,1.042-4.4,3.358V7.316H23.895M11.158,5A1.158,1.158,0,0,0,10,6.158V25.842A1.158,1.158,0,0,0,11.158,27a1.389,1.389,0,0,0,.811-.289l6.137-4.689,6.137,4.689a1.389,1.389,0,0,0,.811.289,1.158,1.158,0,0,0,1.158-1.158V6.158A1.158,1.158,0,0,0,25.053,5Z"
                                    transform="translate(54 4)"
                                    fill="#0D6EFD"
                                  />
                                  <path
                                    id="Path_2367"
                                    data-name="Path 2367"
                                    d="M-126.668-6515.948v18.35l6.819-5.212,6.819,5.212v-18.35Z"
                                    transform="translate(192 6527)"
                                    fill="#0D6EFD"
                                  />
                                </g>
                                <rect
                                  id="Rectangle_2567"
                                  data-name="Rectangle 2567"
                                  width="22"
                                  height="22"
                                  fill="none"
                                />
                              </svg>
                            </OverlayTrigger>
                          ) : (
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="InfoText">
                                  Bookmark
                                </Tooltip>
                              }
                            >
                              <svg
                                id="Group_2175"
                                data-name="Group 2175"
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                              >
                                <g
                                  id="icons_Q2"
                                  data-name="icons Q2"
                                  transform="translate(-61 -9)"
                                >
                                  <path
                                    id="Path_2366"
                                    data-name="Path 2366"
                                    d="M23.895,7.316V23.526l-4.4-3.358-1.389-1.042-1.389,1.042-4.4,3.358V7.316H23.895M11.158,5A1.158,1.158,0,0,0,10,6.158V25.842A1.158,1.158,0,0,0,11.158,27a1.389,1.389,0,0,0,.811-.289l6.137-4.689,6.137,4.689a1.389,1.389,0,0,0,.811.289,1.158,1.158,0,0,0,1.158-1.158V6.158A1.158,1.158,0,0,0,25.053,5Z"
                                    transform="translate(54 4)"
                                  />
                                </g>
                                <rect
                                  id="Rectangle_2567"
                                  data-name="Rectangle 2567"
                                  width="22"
                                  height="22"
                                  fill="none"
                                />
                              </svg>
                            </OverlayTrigger>
                          )}
                        </button>
                      </div>
                      <div className="link-button-wrap">
                        <Link onClick={() => {
                          // viewSimilarFinancing(item?.project_id)
                          clearFinanceFilters()
                          dispatch(setViewSimilarId(result[0]?.project_id))
                        }} to="/finance-recomendations" className="view-similar-btn d-flex"><img src={ViewSimilar} alt="viewsimilar" /><span>View Similar</span></Link>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
           </Col> 
          <Col md={4} className="shimmerloadingtext">
           <ShimmerLoadingWithText/>
          </Col>
          <Col md={4} className="shimmerloadingtext">
           <ShimmerLoadingWithText/>
          </Col>
          </Row>
          
        )
      
      })

      }
           {result?.length===2&&
           <Row>
            <Col md={8}>
            <Slider {...sliderSettingsTwo} className="homeslider">
              {result?.map((item,i)=>(
                <div className="slider-item">
                <div className="d-flex slider-wrap" >
                  <div className="sourcebox source-1">
                    <a
                      href={item?.link_html}
                      style={{ textDecoration: "none", color: "inherit" }}
                      target="_blank"
                    >
                      <h3>
                        {item?.title.length > 70 ? item.title.substring(0, 70) + "..." : item?.title}
                      </h3>

                    </a>

                    <div className="source-content">
                    <div className="d-flex multi-label-wrap source-label-split d-flex justify-content-between align-items-center m-b-20">
                      <label className="source-label">{item?.org_name}</label>
                      <div className="reco-date">Dec 2022</div>
                    </div>
                      <ShowMoreText
                        lines={2}
                        more="View more"
                        less="View less"
                        className="more-text-wrap"
                        anchorClass="showmore-button"
                        expanded={false}
                        truncatedEndingComponent={"..."}
                      >
                        <ScrollArea
                          speed={0.8}
                          className="home-desc-scroll"
                          contentClassName="content"
                          horizontal={false}
                        >
                          {item.abstract}
                        </ScrollArea>
                      </ShowMoreText>
                    </div>
                    <div className="source-footer d-flex justify-content-between align-items-center">
                      <div className="action-buttons-wrap d-flex">
                        <button
                          type="button"
                          className="icon-button liked"
                          onClick={() => {
                            item?.record_details?.vote_up
                              ? (toggleVote(
                                item?.project_id,
                                item?.title,
                                item?.abstract,
                                item?.link_html,
                                "devpubs",
                                item?.org_id,
                                0
                              ),
                                (item.record_details.vote_up = false),
                                (item.record_details.vote_up_count =
                                  item.record_details.vote_up_count - 1))
                              : (toggleVote(
                                item?.project_id,
                                item?.title,
                                item?.abstract,
                                item?.link_html,
                                "devpubs",
                                item?.org_id,
                                1
                              ),
                                (item.record_details.vote_up = true),
                                (item.record_details.vote_up_count =
                                  item.record_details.vote_up_count + 1));
                          }}
                        >
                          <span className="like-count">
                            {item?.record_details?.vote_up_count}
                          </span>
                          {item.record_details?.vote_up ? (
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="InfoText">
                                  Vote up
                                </Tooltip>
                              }
                            >
                              <svg
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ fill: "#1A73E8" }}
                              >
                                <path d="M18.3279 7.15H14.6623L14.8718 5.335C15.186 2.64 14.6623 0.495 12.9866 0H12.8295C12.6564 0.0319322 12.4937 0.109127 12.3564 0.224469C12.2192 0.339811 12.1117 0.48959 12.0441 0.66C12.0441 0.66 9.05923 7.26 7.33117 8.58V19.8H8.01192C8.1254 19.7899 8.23965 19.8042 8.34769 19.842C8.45574 19.8798 8.5553 19.9404 8.64031 20.02C9.37343 20.57 11.8346 22 13.0914 22H15.7097C18.7992 22 21.4699 19.8 21.7317 15.455L21.9935 11.055C22.0235 10.5387 21.9496 10.0215 21.7767 9.53721C21.6037 9.05287 21.3357 8.6122 20.9899 8.24385C20.6442 7.8755 20.2284 7.58774 19.7698 7.39928C19.3112 7.21082 18.8199 7.12588 18.3279 7.15ZM0 9.9V18.7C0 18.9917 0.110341 19.2715 0.30675 19.4778C0.503159 19.6841 0.769546 19.8 1.04731 19.8H5.23655V8.8H1.04731C0.769546 8.8 0.503159 8.91589 0.30675 9.12218C0.110341 9.32847 0 9.60826 0 9.9Z" />
                              </svg>
                            </OverlayTrigger>
                          ) : (
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="InfoText">
                                  Vote up
                                </Tooltip>
                              }
                            >
                              <svg
                                width="23"
                                height="22"
                                viewBox="0 0 23 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19.1635 7.15H15.3308L15.5498 5.335C15.8783 2.64 15.3308 0.495 13.5787 0H13.4144C13.2334 0.0319322 13.0633 0.109127 12.9197 0.224469C12.7762 0.339811 12.6639 0.48959 12.5931 0.66C12.5931 0.66 8.76044 8.8 7.11786 8.8H1.09505C0.804628 8.8 0.526097 8.91589 0.320734 9.12218C0.115371 9.32847 0 9.60826 0 9.9V18.7C0 18.9917 0.115371 19.2715 0.320734 19.4778C0.526097 19.6841 0.804628 19.8 1.09505 19.8H8.37717C8.49582 19.7899 8.61528 19.8042 8.72825 19.842C8.84121 19.8798 8.94532 19.9404 9.0342 20.02C9.80074 20.57 12.3741 22 13.6882 22H16.4258C19.6562 22 22.4486 19.8 22.7224 15.455L22.9961 11C23.0195 10.4882 22.9364 9.97707 22.752 9.49934C22.5677 9.02161 22.2862 8.58774 21.9255 8.22544C21.5649 7.86314 21.133 7.58038 20.6574 7.39521C20.1818 7.21003 19.673 7.12652 19.1635 7.15ZM2.19011 11H5.47527V17.6H2.19011V11ZM20.5323 15.29C20.3133 18.15 18.7802 19.8 16.4258 19.8H13.6882C13.0311 19.745 11.0601 18.755 10.3483 18.26C9.78349 17.8242 9.08929 17.5918 8.37717 17.6H7.66538V11C8.70568 10.78 10.403 9.79 13.4144 3.96C13.4211 4.34578 13.4028 4.7316 13.3597 5.115L13.1407 6.93L12.8669 9.35H19.1635C19.3805 9.3249 19.6004 9.34844 19.8073 9.41891C20.0141 9.48938 20.2029 9.60503 20.36 9.75756C20.5171 9.91008 20.6385 10.0957 20.7157 10.301C20.7928 10.5063 20.8237 10.7263 20.806 10.945L20.5323 15.29Z"
                                  fill="#373737"
                                />
                              </svg>
                            </OverlayTrigger>
                          )}
                        </button>
                        <button
                          type="button"
                          className="icon-button"
                          onClick={() => {
                            AccesshandleShow(),
                              recordUserList(item?.project_id),
                              setProjId(item?.project_id);
                            setProjTitle(item?.title);
                            setProjAbs(item?.abstract);
                            setProjLink(item?.link_html);
                            setProOrgId(item?.org_id);
                            userList();
                          }}
                        >
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="InfoText">
                                Share
                              </Tooltip>
                            }
                          >
                            <svg
                              width="22"
                              height="20"
                              viewBox="0 0 22 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14.606 4.55599L19.508 10.0556L14.606 15.4996V11.5554L12.5051 11.6665C9.10373 11.8332 6.50267 12.222 4.45182 12.9998C6.25256 10.8888 9.10373 9.22228 12.8052 8.83343L14.606 8.66677V4.55599ZM13.1554 0.000794509C13.0765 0.00779243 12.9996 0.0320624 12.9291 0.0722063C12.8587 0.11235 12.7961 0.167575 12.7449 0.2347C12.6938 0.301825 12.6551 0.379523 12.6311 0.463321C12.6071 0.547118 12.5983 0.635359 12.6052 0.722959V6.61138C5.00205 7.44465 0.100041 13.222 0 19.0548C0 19.3881 0.100041 19.6103 0.300123 19.6103C0.500205 19.6103 0.650267 19.4437 0.850349 18.9993C2.05084 15.9995 4.75195 14.2774 12.6052 13.8886V19.277C12.5983 19.3646 12.6071 19.4529 12.6311 19.5367C12.6551 19.6205 12.6938 19.6982 12.7449 19.7653C12.7961 19.8324 12.8587 19.8876 12.9291 19.9278C12.9996 19.9679 13.0765 19.9922 13.1554 19.9992C13.2337 20.0041 13.3119 19.9862 13.3821 19.9472C13.4523 19.9082 13.5121 19.8496 13.5556 19.777L21.8089 10.6111C21.932 10.4583 22 10.2605 22 10.0556C22 9.85059 21.932 9.65281 21.8089 9.50004L13.5556 0.222999C13.5121 0.150438 13.4523 0.0917596 13.3821 0.0527823C13.3119 0.013805 13.2337 -0.00411673 13.1554 0.000794509Z"
                                fill="#373737"
                              />
                            </svg>
                          </OverlayTrigger>
                        </button>
                        <button
                          type="button"
                          className="icon-button"
                          onClick={() => {
                            item?.record_details?.bookmark
                              ? (toggleBookmark(
                                item?.project_id,
                                item?.title,
                                item?.abstract,
                                item?.link_html,
                                "devpubs",
                                0,
                                item?.org_id
                              ),
                                (item.record_details.bookmark = false))
                              : (toggleBookmark(
                                item?.project_id,
                                item?.title,
                                item?.abstract,
                                item?.link_html,
                                "devpubs",
                                1,
                                item?.org_id
                              ),
                                (item.record_details.bookmark = true));
                          }}
                        >
                          {item?.record_details?.bookmark ? (
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="InfoText">
                                  Bookmark
                                </Tooltip>
                              }
                            >
                              <svg
                                id="Group_2176"
                                data-name="Group 2176"
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                              >
                                <g
                                  id="icons_Q2"
                                  data-name="icons Q2"
                                  transform="translate(-61 -9)"
                                >
                                  <path
                                    id="Path_2366"
                                    data-name="Path 2366"
                                    d="M23.895,7.316V23.526l-4.4-3.358-1.389-1.042-1.389,1.042-4.4,3.358V7.316H23.895M11.158,5A1.158,1.158,0,0,0,10,6.158V25.842A1.158,1.158,0,0,0,11.158,27a1.389,1.389,0,0,0,.811-.289l6.137-4.689,6.137,4.689a1.389,1.389,0,0,0,.811.289,1.158,1.158,0,0,0,1.158-1.158V6.158A1.158,1.158,0,0,0,25.053,5Z"
                                    transform="translate(54 4)"
                                    fill="#0D6EFD"
                                  />
                                  <path
                                    id="Path_2367"
                                    data-name="Path 2367"
                                    d="M-126.668-6515.948v18.35l6.819-5.212,6.819,5.212v-18.35Z"
                                    transform="translate(192 6527)"
                                    fill="#0D6EFD"
                                  />
                                </g>
                                <rect
                                  id="Rectangle_2567"
                                  data-name="Rectangle 2567"
                                  width="22"
                                  height="22"
                                  fill="none"
                                />
                              </svg>
                            </OverlayTrigger>
                          ) : (
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="InfoText">
                                  Bookmark
                                </Tooltip>
                              }
                            >
                              <svg
                                id="Group_2175"
                                data-name="Group 2175"
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                              >
                                <g
                                  id="icons_Q2"
                                  data-name="icons Q2"
                                  transform="translate(-61 -9)"
                                >
                                  <path
                                    id="Path_2366"
                                    data-name="Path 2366"
                                    d="M23.895,7.316V23.526l-4.4-3.358-1.389-1.042-1.389,1.042-4.4,3.358V7.316H23.895M11.158,5A1.158,1.158,0,0,0,10,6.158V25.842A1.158,1.158,0,0,0,11.158,27a1.389,1.389,0,0,0,.811-.289l6.137-4.689,6.137,4.689a1.389,1.389,0,0,0,.811.289,1.158,1.158,0,0,0,1.158-1.158V6.158A1.158,1.158,0,0,0,25.053,5Z"
                                    transform="translate(54 4)"
                                  />
                                </g>
                                <rect
                                  id="Rectangle_2567"
                                  data-name="Rectangle 2567"
                                  width="22"
                                  height="22"
                                  fill="none"
                                />
                              </svg>
                            </OverlayTrigger>
                          )}
                        </button>
                      </div>
                      <div className="link-button-wrap">
                        <Link onClick={() => {
                          // viewSimilarFinancing(item?.project_id)
                          clearFinanceFilters()
                          dispatch(setViewSimilarId(result[0]?.project_id))
                        }} to="/finance-recomendations" className="view-similar-btn d-flex"><img src={ViewSimilar} alt="viewsimilar" /><span>View Similar</span></Link>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
              
              ))}
              </Slider>
            </Col>
            <Col md={4} className="shimmerloadingtext">
           <ShimmerLoadingWithText/>
          </Col>
           </Row>

           }
    </>
      }

            
            <Modal show={AccessShow} size="md" className='common-modal side-popup'>
                <Modal.Header closeButton onClick={AccesshandleClose}>
                    <Modal.Title>Manage access</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="country-pop-wraper">
                        <ShareRecordPopup projectId = {projId} title={projTitle} abstract={proAbs} link={projLink} orgId={projOrgId} category="devpubs" />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Devpubs;
