import React from 'react';
import { Helmet } from 'react-helmet';
import PrivacyBanner from '../components/privacybanner/privacybanner';
import PrivacyContent from '../components/privacycontents/privacycontents';


function PrivacyNotice() {
    return (
        <div className="landing-page-wrap">
            <Helmet>
                <title>
                DevDiscovery | Privacy notice
                </title>
            </Helmet>
            <section className="landing-section section-1">
                <PrivacyBanner />
            </section>
            <section className="landing-section section-1">
                <PrivacyContent />
            </section>

        </div>
    )
}

export default PrivacyNotice;