import React from "react";

function DislikeIcon(props) {
  return (
    <span className="icon-wrap">
      <svg
        width="16"
        height="15"
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.6689 10.125L5.33512 10.125L5.18277 11.3625C4.95423 13.2 5.33512 14.6625 6.55396 15H6.66823C6.79417 14.9782 6.91252 14.9256 7.01236 14.847C7.11221 14.7683 7.19034 14.6662 7.23956 14.55C7.23956 14.55 9.90578 9 11.0484 9H15.2382C15.4403 9 15.634 8.92098 15.7769 8.78033C15.9197 8.63968 16 8.44891 16 8.25V2.25C16 2.05109 15.9197 1.86032 15.7769 1.71967C15.634 1.57902 15.4403 1.5 15.2382 1.5H10.1724C10.0899 1.50689 10.0068 1.49715 9.92818 1.47136C9.84959 1.44557 9.77717 1.40427 9.71534 1.35C9.1821 0.974999 7.39192 0 6.47779 0H4.57334C2.3261 0 0.38357 1.5 0.193125 4.4625L0.00268078 7.5C-0.0135803 7.84897 0.0442543 8.19745 0.172491 8.52318C0.300729 8.84891 0.49655 9.14472 0.74745 9.39175C0.998349 9.63877 1.29881 9.83156 1.62966 9.95781C1.9605 10.0841 2.31445 10.141 2.6689 10.125ZM14.4764 7.5L12.1911 7.5L12.1911 3H14.4764L14.4764 7.5ZM1.71668 4.575C1.86903 2.625 2.93552 1.5 4.57334 1.5H6.47779C6.93485 1.5375 8.30605 2.2125 8.80121 2.55C9.19409 2.84713 9.67702 3.00562 10.1724 3H10.6676L10.6676 7.5C9.94387 7.65 8.76312 8.325 6.66823 12.3C6.66359 12.037 6.67632 11.7739 6.70632 11.5125L6.85868 10.275L7.04912 8.625L2.6689 8.625C2.51792 8.64211 2.36497 8.62607 2.22104 8.57802C2.07712 8.52997 1.94579 8.45111 1.83652 8.34712C1.72726 8.24313 1.64276 8.11658 1.5891 7.97659C1.53545 7.8366 1.51398 7.68664 1.52623 7.5375L1.71668 4.575Z"
          fill={props?.iconColor}
        />
      </svg>
    </span>
  );
}

export default DislikeIcon;
