import axios from 'axios';
import URLS from '../../constants/urls';
import API from '../config';

export const _ssoLogin = async (data) => {
    const response = await API.post(URLS.SSO_LOGIN, data);
    return response;
  };
 
export const _ssoLoginCallback = async (code) => {

  

  // axios.get(`https://9abf-103-165-167-120.in.ngrok.io/sso/sso-auth/callback/?code=${code}`,)
  // .then(response => console.log(response))
  // .catch(error => {
  
  //     console.error('There was an error!', error);
  // });
  const response = await API.get(`${URLS.SSO_LOGIN_APPROVAL}callback/?code=${code}`,{timeout:20000});
  return response;
 
};

// export const _token = async (token) =>{
//   const response = await API.get v1/record/counts/
// }

export const _token = async (token) =>{
  const response = await API.get(URLS.TOKEN,{
    headers: {
      'Authorization' : `token ${token}`
    }
  })
  return response
}
export const _countryList = async (token) =>{
  const response = await API.get(URLS.COUNTRY_LIST,{ headers: {
    'Authorization': `token ${token}`
  }})
  return response
}

export const _topicList = async (token) =>{
  const response = await API.get(URLS.TOPIC_LIST,{ headers: {
    'Authorization': `token ${token}`
  }})
  return response
}
export const _regionList = async (token) =>{
  const response = await API.get(URLS.REGION_LIST,{ headers: {
    'Authorization': `token ${token}`
  }})
  return response
}

export const _profileUpdate = async (token,data) =>{
  const response = await API.patch(`${URLS.PROFILE_UPDATE}`,data,{headers: {
    'Authorization': `Token ${token}`
  }})
  return response
}

export const _tokenUser = async () =>{
  const response = await API.get(URLS.TOKEN)
  return response
}
export const _countryListUser = async () =>{
  const response = await API.get(URLS.COUNTRY_LIST)
  return response
}

export const _topicListUser = async () =>{
  const response = await API.get(URLS.TOPIC_LIST)
  return response
}
export const _regionListUser = async () =>{
  const response = await API.get(URLS.REGION_LIST)
  return response
}

export const _profileRegistration = async(data)=>{
  const response = await API.post(URLS.PROFILE_REGISTRATION,data)
  return response
}

export const _signUpVerify = async (code) => {
  const response = await API.get(`${URLS.SIGNUP_VERIFY}?code=${code}`);
  return response;
};

export const _signIn = async (data) => {
  const response = await API.post(`${URLS.SIGNUP}`,data);
  return response;
};

export const _logout = async (token) =>{
  const response = await API.get(URLS.LOGOUT,{headers: {
    'Authorization': `token ${token}`}});
  return response
}

export const _resetPassword = async (data,token)=>{
  const response = await API.put(`${URLS.RESET_PASSWORD}`,data,{headers: {
    'Authorization': `token ${token}`}})
  return response
}

export const _forgotPassword = async(data)=>{
  const response = await API.post(`${URLS.FORGOT_PASSWORD}`,data)
  return response
}

export const _resetPasswordVerify = async(code,email,data)=>{
  const response = await API.put(`${URLS.RESET_PASSWORD_VERIFY}${code}/?email=${email}`,data)
  return response
}

export const _keyWordSearch = async(data,token,category)=>{
  const response = await API.post(`${URLS.KEYWORD_SEARCH}?category=${category}`,data,
  {headers: {
    'Authorization': `Token ${token}`}})
  return response
}

export const _chatresults = async(data,cancelSource,token)=>{
  const response = await API.post(`${URLS.CHAT_RESULTS}`,data,
  { cancelToken: cancelSource.current.token,headers: {
    'Authorization': `Token ${token}`}
  })
  return response
}



export const _likeDislikeChat = async (data,token)=>{
  const response = await API.post(`${URLS.LIKE_DISLIKE_CHAT}`,data,{
    headers: {
      'Authorization': `Token ${token}`}
  })
  return response
}



export const _chatRelatedSearch = async(data,token)=>{
  const response = await API.post(`${URLS.KEYWORD_SEARCH}`,data,
  {headers: {
    'Authorization': `Token ${token}`
  }
})
  return response
}

export const _keyWordSearchFinancing = async(data,token)=>{
  const response = await API.post(`${URLS.KEYWORD_SEARCH_FIN}`,data,
  {headers: {
    'Authorization': `Token ${token}`
  }
})
  return response
}
export const _keyWordSearchDevpubs = async(data,token)=>{
  const response = await API.post(`${URLS.KEYWORD_SEARCH_DEV}`,data,
  {headers: {
    'Authorization': `Token ${token}`}})
  return response
}
export const _keyWordSearchOtherpubs = async(data,token)=>{
  const response = await API.post(`${URLS.KEYWORD_SEARCH_OTHER}`,data,
  {headers: {
    'Authorization': `Token ${token}`}})
  return response
}
export const _keyWordSearchData = async(data,token)=>{
  const response = await API.post(`${URLS.KEYWORD_SEARCH_DATA}`,data,
  {headers: {
    'Authorization': `Token ${token}`}})
  return response
}
export const _keyWordSearchMultimedia = async(data,token)=>{
  const response = await API.post(`${URLS.KEYWORD_SEARCH_MUL}`,data,
  {headers: {
    'Authorization': `Token ${token}`}})
  return response
}
export const _keyWordSearchNews = async(data,token)=>{
  const response = await API.post(`${URLS.KEYWORD_SEARCH_NEWS}`,data,
  {headers: {
    'Authorization': `Token ${token}`}})
  return response
}
export const _keyWordSearchEvaluations = async(data,token)=>{
  const response = await API.post(`${URLS.KEYWORD_SEARCH_EVAL}`,data,
  {headers: {
    'Authorization': `Token ${token}`}})
  return response
}
export const _keyWordSearchEconpubs = async(data,token)=>{
  const response = await API.post(`${URLS.KEYWORD_SEARCH_ECON}`,data,
  {headers: {
    'Authorization': `Token ${token}`}})
  return response
}
export const _keyWordSearchExperts = async(data,token)=>{
  const response = await API.post(`${URLS.KEYWORD_SEARCH_EXPERTS}`,data,
  {headers: {
    'Authorization': `Token ${token}`}})
  return response
}

export const _saveQuery = async(data,token)=>{
  const response = await API.post(`${URLS.SAVE_QUERY}`,data,
  {headers: {
    'Authorization': `Token ${token}`}})
  return response

}

export const _voteUp = async(data,token)=>{
  const response = await API.post(`${URLS.VOTE_UP}`,data,
  {headers: {
    'Authorization': `Token ${token}`}})
  return response  
}

export const _listUserstoShareQuery = async(token,search,sector,country,region,flag,offset)=>{
  const response = await API.get(`${URLS.USERS_LIST}?search=${search}&pref_sector__name=${sector}&pref_country=${country}&pref_region=${region}&pagination=${flag}&offset=${offset}`,{
    headers : {
      'Authorization': `Token ${token}`
    }
  })
  return response
}

export const _recordDetails = async(data,token)=>{
  const response = await API.post(`${URLS.RECORD_DETAILS}`,data,
  {
    headers : {
      'Authorization': `Token ${token}`
    }
  })
  return response
}

export const _userListQuerySharedTo = async(data,token)=>{
  const response = await API.post(`${URLS.USER_LIST_SHARE_QUERY}`,data,
  {
    headers : {
      'Authorization': `Token ${token}`
    }
  })
  return response
}

export const _shareSingleQuery = async (data,token,flag)=>{
  const response = await API.post(`${URLS.SHARE_SINGLE_QUERY}?save=${flag}`,data,
  {
    headers : {
      'Authorization': `Token ${token}`
    }
  }
  )
  return response
}
export const _bookmark = async (data,token) =>{
  const response = await API.post(`${URLS.BOOKMARK}`,data,
  {
    headers : {
      'Authorization': `Token ${token}`
    }
  })
  return response
}
export const _listUsersRecord = async (project_id,token)=>{
  const response = await API.get(`${URLS.LIST_USERS_RECORD}users/?project_id=${project_id}`,
  {
    headers : {
      'Authorization': `Token ${token}`
    }
  })
  return response
}

export const _shareRecord = async (data,token)=>{
  const response = await API.post(`${URLS.SHARE_RECORD}`,data,
  {
    headers : {
      'Authorization': `Token ${token}`
    }
  }
  )
  return response
}

export const _userRecomendations = async (token)=>{
  const response = await  API.get(`${URLS.USER_RECOMENDATIONS}`,
  {
    headers : {
      'Authorization': `Token ${token}`
    }
  }
  )
  return response
}

export const _userProfile = async (token)=>{
  const response = await API.get(`${URLS.USER_PROFILE}`,{
    headers : {
      'Authorization': `Token ${token}`
    }
  })
  return response
}

export const _userInfo = async (token,userId) =>{
  const response = await API.get(`${URLS.PROFILE_UPDATE}`,{headers: {
    'Authorization': `Token ${token}`
  }})
  return response
}

export const _listSavedQuery = async(token,title,page)=>{
  const response = await API.get(`${URLS.LIST_SAVED_QUERY}?limit=10&offset=${page}&search=${title}`,{
    headers : {
      'Authorization': `Token ${token}`
    }
  })
  return response
}

export const _shareMultipleQuery = async (data,token)=>{
  const response = await API.post(`${URLS.SHARE_MULTIPLE_QUERY}`,data,
  {
    headers : {
      'Authorization': `Token ${token}`
    }
  }
  )
  return response
}

export const _removeMultipleQuery = async (data,token) =>{
  const response = await API.delete(`${URLS.DELETE_MULTIPLE_QUERIES}?ids=${data}`,{
    headers : {
      'Authorization': `Token ${token}`
    }
  })
  return response
}
export const _removeSingleQuery = async (data,token) =>{
  const response = await API.delete(`${URLS.DELETE_SINGLE_QUERY}${data}/`,{
    headers : {
      'Authorization': `Token ${token}`
    }
  })
  return response
}

export const _removeSingleSavedQuery = async (data,token) =>{
  const response = await API.delete(`${URLS.DELETE_SINGLE_SAVED_QUERY}${data}/`,{
    headers : {
      'Authorization': `Token ${token}`
    }
  })
  return response
}


export const _listReceivedQuery = async(token,title,page)=>{
  const response = await API.get(`${URLS.LIST_RECIEVED_QUERY}?limit=10&offset=${page}&search=${title}`,{
    headers : {
      'Authorization': `Token ${token}`
    }
  })
  return response
}

export const _reshareQuery = async(data,flag,token)=>{
  const response = await API.post(`${URLS.RESHARE_QUERY}?save=${flag}`, data,{headers : {
    'Authorization': `Token ${token}`
  }
})
return response
  
}

export const _removeMultipleRecievedQuery = async (data,token) =>{
  const response = await API.delete(`${URLS.DELETE_MULTIPLE_RECEIVED_QUERY}?ids=${data}`,{
    headers : {
      'Authorization': `Token ${token}`
    }
  })
  return response
}

export const _removeSingleReceivedQuery = async (data,token,save) =>{
  const response = await API.delete(`${URLS.DELETE_SINGLE_RECEIVED_QUERY}${data}/?save_query_id=${save}`,{
    headers : {
      'Authorization': `Token ${token}`
    }
  })
  return response
}

export const _listBookmarks = async (token,title,page) =>{
  const response = await API.get(`${URLS.LIST_BOOKMARKS}?limit=10&offset=${page}&search=${title}`,{
    headers : {
      'Authorization': `Token ${token}`
    }
  })
  return response
}

export const _getHistory = async (token) =>{
  const response = await API.get(`${URLS.CHAT_HISTORY}`,{
    headers : {
      'Authorization': `Token ${token}`
    }
  })
  return response
}

export const _getHistoryOther = async (token,page,search) =>{
  const response = await API.get(`${URLS.CHAT_HISTORY_OTHER}?limit=10&offset=${page}&search=${search}`,{
    headers : {
      'Authorization': `Token ${token}`
    }
  })
  return response
}



export const _removeSingleBookmark = async (data,token) =>{
  const response = await API.delete(`${URLS.DELETE_SINGLE_BOOKMARK}${data}/`,{
    headers : {
      'Authorization': `Token ${token}`
    }
  })
  return response
}

export const _shareMultipleRecords = async(data,token)=>{
  const response = await API.post(`${URLS.SHARE_MULTIPE_RECORDS}`,data,{
    headers : {
      'Authorization': `Token ${token}`
    }
  })
  return response
}

export const _deleteMultipleRecords = async(data,token)=>{
  const response = await API.delete(`${URLS.DELETE_MULTIPLE_RECORDS}?ids=${data}`,{
    headers : {
      'Authorization': `Token ${token}`
    }
  })
  return response
}

export const _listReceivedBookmarks = async (token,title,page) =>{
  const response = await API.get(`${URLS.LIST_RECIEVED_BOOKMARKS}?limit=10&offset=${page}&search=${title}`,{
    headers : {
      'Authorization': `Token ${token}`
    }
  })
  return response
}

export const _reshareBookmark= async(data,token)=>{
  const response = await API.post(`${URLS.FORWARD_BOOKMARKS}`, data,{headers : {
    'Authorization': `Token ${token}`
  }
})
return response
  
}

export const _deleteMultipleSharedRecords = async(data,token)=>{
  const response = await API.delete(`${URLS.REMOVE_MULTIPLE_SHARED_RECORD}?ids=${data}`,{
    headers : {
      'Authorization': `Token ${token}`
    }
  })
  return response
}

export const _removeSharedSingleBookmark = async (data,token) =>{
  const response = await API.delete(`${URLS.REMOVE_SINGLE_SHARED_RECORD}${data}/`,{
    headers : {
      'Authorization': `Token ${token}`
    }
  })
  return response
}

export const _listFaq = async (token,value,page)=>{
  const response = await API.get(`${URLS.LIST_FAQ}?limit=3&offset=${page}&search=${value}&is_chat=0`,{
    headers : {
      'Authorization': `Token ${token}`
    }
  })
  return response
}

export const _listFaqChat = async (token,value,page)=>{
  const response = await API.get(`${URLS.LIST_FAQ}?limit=2&offset=${page}&search=${value}&is_chat=1`,{
    headers : {
      'Authorization': `Token ${token}`
    }
  })
  return response
}

export const _addFaq = async (token,data)=>{
  const response = await API.post(`${URLS.ADD_FAQ}`,data,{
    headers : {
      'Authorization': `Token ${token}`
    }
  })
  return response
}

export const _staticForm = async (data)=>{
  const response = await API.post(`${URLS.STATIC_FORM}`,data)
  return response
}

export const _viewSimilar = async (token,category,qid,org_name,region,country,sector,topic,start_date,end_date,rows,skip)=>{
  const response = await API.get(`${URLS.VIEW_SIMILAR}?category=${category}&qid=${qid}&org_name=${org_name}&region=${region}&country=${country}&sector=${sector}&topic=${topic}&start_date=${start_date}&end_date=${end_date}&rows=${rows}&skip=${skip}`,
  {
    headers : {
      'Authorization': `Token ${token}`
    }
  })
  return response
} 

export const _currentPasswordCheck = async (token,data)=>{
  const response = await API.post(`${URLS.CURRENT_PASSWORD}`,data,
  {
    headers : {
      'Authorization': `Token ${token}`
    }
  })
  return response
}

export const _removeAccessQuery = async (token,id)=>{
  const response = await API.delete(`${URLS.REMOVE_ACCESS_QUERY}${id}/`,
  {
    headers : {
      'Authorization': `Token ${token}`
    }
  })
  return response
}

export const _removeAccessRecord = async (token,id)=>{
  const response = await API.delete(`${URLS.REMOVE_ACCESS_RECORD}${id}/`,
  {
    headers : {
      'Authorization': `Token ${token}`
    }
  })
  return response
}

export const _docSearch = async (token,data)=>{
  const response = await API.post(`${URLS.DOC_UPLOAD}`,data,{
    headers : {
      'Authorization': `Token ${token}`
    }
  })
  return response
}

export const _recordDownload = async (token,data)=>{
  const response = await API.post(`${URLS.RECORD_DOWNLOAD}`,data,{
    headers : {
      'Authorization': `Token ${token}`
    }
  })
  return response
}

export const _notificationList = async (token) =>{
  const response = await API.get(`${URLS.NOTIFICATION_LIST}`,{
    headers : {
      'Authorization': `Token ${token}`
    }
  })
  return response
}

export const _markReadSingleNotification = async (token,id,data)=>{
  const response = await API.put(`${URLS.MARK_READ}${id}/`,data,{headers: {
    'Authorization': `token ${token}`}})
  return response
}

export const _markReadAllNotification = async (token,data)=>{
  const response = await API.put(`${URLS.MARK_ALL_READ}`,data,{headers: {
    'Authorization': `token ${token}`}})
  return response
}


export const _staticCount = async (token)=>{
  const response = await API.get(`${URLS.STATIC_COUNT}`)
  return response
}

export const _userTierInfo = async (token) =>{
  const response = await API.get(`${URLS.USER_TIER_INFO}`,
  {headers: {
    'Authorization': `token ${token}`}}
  )
  return response
}

export const _userTierInfoDoc = async (token,data) =>{
  const response = await API.get(`${URLS.USER_TIER_INFO_DOC}?feature=${data}`,
  {headers: {
    'Authorization': `token ${token}`}}
  )
  return response
}

export const _chatHistoryContents = async(data,token)=>{
  const response = await API.post(`${URLS.CHAT_HISTORY_CONTENT}`,data,
  {headers: {
    'Authorization': `Token ${token}`}})
  return response
}

export const _editChatHistory = async (token,data)=>{
  const response = await API.put(`${URLS.EDIT_CHAT}`,data,{headers: {
    'Authorization': `token ${token}`}})
  return response
}

// export const _deleteChatHistory = async(token,id)=>{
//   console.log(id,"req data");
//   const response = await API.delete(`${URLS.CHAT_HISTORY_CONTENT}`,
//   {headers: {
//     'Authorization': `Token ${token}`},
//     data: {
//       "chat_id" : id
//     } 
//   })
//   return response
// }

export const _deleteChatHistory  = async(data,token)=>{
  const response = await API.delete(`${URLS.CHAT_HISTORY_CONTENT}?chat_id=${data}`,{
    headers : {
      'Authorization': `Token ${token}`
    }
  })
  return response
}


export const _chatDocUpload = async(data,token,file, callback, cancelUploadRef)=>{
  const response = await API.post(`${URLS.CHAT_DOC_UPLOAD}`,data,
  {cancelToken: cancelUploadRef.current.token, headers: {
    'Authorization': `Token ${token}`},
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      callback({ filename:file.name, value: percentCompleted });
    }
  },
  )
  return response
}

export const _chatremoveDoc = async(data,token)=>{
  const response = await API.delete(`${URLS.CHAT_DOC_UPLOAD}`,
  {data,
   headers: {
    'Authorization': `Token ${token}`}
  })
  return response
}

export const _chatCompleteUpload = async(data,token)=>{
  const response = await API.post(`${URLS.CHAT_DOC_UPLOAD}`,data,
  {headers: {
    'Authorization': `Token ${token}`}
  })
  return response
}


export const _chatCancelUpload = async(data,token)=>{
  const response = await API.post(`${URLS.CHAT_DOC_UPLOAD}`,data,
  {headers: {
    'Authorization': `Token ${token}`}
  })
  return response
}






