import React,{useEffect} from 'react';
import { Helmet } from 'react-helmet';
import SalesBanner from '../components/salesbanner/salesbanner';
import SalesContent from '../components/salescontents/salescontents';

function SalesEnquiry() {
    return (
        <div className="landing-page-wrap">
            <Helmet>
                <title>
                DevDiscovery | Sales enquiry
                </title>
            </Helmet>
            <section className="landing-section section-1">
                <SalesBanner />
            </section>
            <section className="landing-section section-1">
                <SalesContent />
            </section>

        </div>
    )
}

export default SalesEnquiry;