import React,{useState} from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Logo from '../../../../assets/images/logo.png';
import FgtImg from '../../../../assets/images/forgotpwd-img.png';
import background from "../../../../assets/images/signin-left-bg.png";
import {_resetPasswordVerify} from './../../../../shared/httpservice/apis'
import {Formik} from 'formik'
import { useNavigate } from 'react-router-dom';
import Eye from "../../../../assets/images/icons/eye.png";
import HiddenEye from "../../../../assets/images/icons/hidden.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Helmet} from 'react-helmet'
// import './login.css';


function UserResetPassword() {
    const [passwordShown, setPasswordShown] = useState(false);
  const [cpasswordShown, setCPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const toggleCPassword = () => {
    setCPasswordShown(!cpasswordShown);
  };
    const queryString = location.search;
    const params = new URLSearchParams(queryString);
    const navigate = useNavigate()
    const handleSubmit = async (values)=>{
        const formData = new FormData()
        formData.append("password",values.password)
        if(params?.get("code")?.length){
            let code = params?.get("code");
            let email = params?.get("email")
            return _resetPasswordVerify(code,email,formData).then((response)=>{               
                toast.success("Password reset successful!",
                {hideProgressBar: true})
    
                navigate("/")
            })
        }
       
    }
    const validate =(values)=>{
        const errors={}
        const mediumRegex = new RegExp(
            "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[+!@#$%^&*])(?=.{8,})"
          );
        if(!values.password){
            errors.password="*required"
        }
        else if (!mediumRegex.test(values.password)) {
            errors.password =
              "*password must contain atleast 8 characters and must include special characters,uppercases and numbers";
          }
        if(!values.confirmPassword){
            errors.confirmPassword="*required"
        }
        else if (!mediumRegex.test(values.confirmPassword)) {
            errors.confirmPassword =
              "*password must contain atleast 8 characters and must include special characters,uppercases and numbers";
          }
        if(values.password!==values.confirmPassword){
            errors.password="*password doesnt match"
            errors.confirmPassword="*password doesnt match"
        }
        return errors;
    }
    return (
        <div className="auth-page-wrap">
            <Helmet>
                <title>
                DevDiscovery | Reset password
                </title>
            </Helmet>
            <div className="container">
                <div className="auth-container d-flex">
                    <div className="auth-left" style={{ backgroundImage: `url(${background})` }}>
                        <div className="auth-logo-wrap">
                            <img src={Logo} alt="" />
                        </div>
                        <div className="auth-signin-img">
                            <img src={FgtImg} alt="" />
                        </div>
                    </div>
                    <div className="auth-right">
                        <div className="auth-right-content">
                            <h3 className="sec-title m-b-60">Reset Password</h3>
                            <Formik
                            initialValues={{
                                password : "",
                                confirmPassword : ""
                            }}
                            onSubmit={handleSubmit}
                            validate={validate}
                            >
                                {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors,
                            touched
                            }) => (
                            <Form className="w-100" onSubmit={handleSubmit}>
                                <div className="form-input-wrap m-b-25 p-relative eye-icon-inputwrap">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type={passwordShown?"text":"password"} name='password' placeholder="* * * * * * * *" className="input-item"
                                    value={values.password}
                                    onChange={handleChange}
                                    />
                                    {passwordShown ? <span className="eye-hidden" onClick={togglePassword}><img src={HiddenEye} alt=""/></span> : <span className="eye-show" onClick={togglePassword}><img src={Eye} alt=""/></span>}
                                    
                                     {errors.password && touched.password ? (
                        <span style={{ color: "red" }}>
                          {errors.password}
                        </span>
                      ) : null}
                                </div>
                                <div className="form-input-wrap m-b-25 p-relative eye-icon-inputwrap">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control type={cpasswordShown?"text":"password"} name='confirmPassword' placeholder="* * * * * * * *" className="input-item" 
                                    value={values.confirmPassword}
                                    onChange={handleChange}
                                    />
                                     {cpasswordShown ? <span className="eye-hidden" onClick={toggleCPassword}><img src={HiddenEye} alt=""/></span> : <span className="eye-show" onClick={toggleCPassword}><img src={Eye} alt=""/></span>}
                                    {/* {cpasswordShown?<span onClick={toggleCPassword}>Dshow</span>:<span onClick={toggleCPassword}>show</span>} */}
                                    {errors.confirmPassword && touched.confirmPassword ? (
                        <span style={{ color: "red" }}>
                          {errors.confirmPassword}
                        </span>
                      ) : null}
                                </div>
                                <div className="button-wrap">
                                    <Button type="submit">Reset Password</Button>
                                </div>
                            </Form>
                             )}
                            </Formik>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default UserResetPassword;
