import React,{useState,useEffect} from "react";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import LandingFooter from "../landingfooter/landingfooter";
import LandingPageWrapper from "../landingpagewrapper/landingpagewrapper";
import { useDispatch,useSelector,shallowEqual } from "react-redux";
import { userLogOut, resetStore } from './../../modules/auth/services';

const LandingRoute = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const notState = useSelector((state)=>state?.profile?.list_notification?.error?.response?.statusText,shallowEqual) 
  const invalid = useSelector((state)=>state?.profile?.list_notification?.error?.response?.data?.message,shallowEqual) 
  // console.log(notState,"user auth"); 
  
  useEffect(()=>{
    if(notState==="Unauthorized"||invalid==="Invalid token"){
      navigate('/signin')
      localStorage.setItem("status", false)
      dispatch(resetStore())
      localStorage.removeItem("persist:kush.ai");
      localStorage.removeItem("change")
      
    }
  },[notState,invalid])
    return  (
    <>
      <LandingPageWrapper />
      <Outlet />
      <LandingFooter/>
    </>
    )
    
}
  


export default LandingRoute;