import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Logo from "../../../../assets/images/logo.png";
import UploadIcons from "../../../../assets/images/icons/uploadicon.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
// import ProfilePic from "./components/profilepic/container";

import background from "../../../../assets/images/signup-left-bg.png";
import { Modal, Alert } from "react-bootstrap";
import tickIcon from "../../../../assets/images/icons/tick-icon.png";
import { ssoLoginCallBack } from "./../../services";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {useNavigate} from 'react-router-dom'
import {
  _ssoLogin,
  _ssoLoginCallback,
  _token,
  _countryList,
  _topicList,
  _regionList,
  _profileUpdate,
} from "~shared/httpservice/apis";
import { Formik, useFormik, ErrorMessage, Field } from "formik";
// import './login.css';
import ProfileDummy from "./../../../../assets/images/profile-dummy.png";
import {Helmet} from 'react-helmet'

function Dummy() {
  const navigate = useNavigate()
  const [imageFile, setImageFile] = useState();
  const [files, setFiles] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };
  const [images, setImages] = useState(ProfileDummy);
  const [sho, setSho] = useState(false);
  const handleClos = () => setSho(false);
  const handleSho = () => setSho(true);
  const queryString = location.search;
  const params = new URLSearchParams(queryString);
  const codeVar = params?.get("code");
  const dispatch = useDispatch();
  const [country, setCountry] = useState([]);
  const [sectors, setSector] = useState([]);
  const [topic, setTopic] = useState([]);
  const [region, setRegion] = useState([]);

  //function to verify the user that has been logged in with sso mode
  const codeCallback = () => {
    if (params?.get("code")?.length) {
      let code = params?.get("code");
      dispatch(ssoLoginCallBack(code))
    }
  };

  const token = useSelector(
    (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token
  );
  const user = useSelector(
    (state) => state?.auth?.sso_login_callback?.response?.data?.data,
    shallowEqual
  );
  const state = useSelector((state)=>state)

  //function to list the dropdown datas
  const listCountry = () => {
    return _countryList(token).then((response) => {
      setCountry(response?.data?.data?.element);
    });
  };
  const listSectors = () => {
    return _token(token).then((response) => {
      setSector(response?.data?.data?.element);
    });
  };
  const topicList = () => {
    return _topicList(token).then((response) => {
      setTopic(response?.data?.data?.element);
    });
  };
  const regionList = async () => {
    return _regionList(token).then((response) => {
      setRegion(response?.data?.data?.element);
    });
  };
  useEffect(() => {
    if (codeVar) codeCallback();
  }, [codeVar]);

  useEffect(() => {
    if (token) listCountry();
  }, [token]);
  useEffect(() => {
    if (token) topicList(), listSectors(), regionList();
  }, [token]);

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            className="react-multi-select-item"
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  //mapping the dropdown datas that we get from the api after user verification
  const sectorOptions = sectors.map((item) => ({
    value: item.id,
    label: item.name,
  }));
  const topics = topic.map((item) => ({ value: item.id, label: item.name }));
  const regions = region.map((item) => ({ value: item.id, label: item.name }));
  const cou = country.map((item) => ({ value: item.code, label: item.name }));

  const countries = country.map((item, i) => {
    return (
      <option key={i} value={item.code}>
        {item.name}
      </option>
    );
  });
  const [select, setSelect] = useState([]);
  const [focus, setFocus] = useState([]);
  const newFocus = focus.map((item) => ({ value: item.value }));
  var focusValues = newFocus.map((i) => {
    return i.value;
  });
  const [reg, setReg] = useState([]);
  const newReg = focus.map((item) => ({ value: item.value }));
  var regionValues = newReg.map((i) => {
    return i.value;
  });
  const newSector = select.map((item) => ({ value: item.value }));

  var sectorValues = newSector.map((i) => {
    return i.value;
  });
  console.log(sectorValues);
  const [mulcou, setMulcou] = useState([]);
  const newMulcou = mulcou.map((item) => ({ value: item.value }));
  var countryValues = newMulcou.map((i) => {
    return i.value;
  });

  //function to convert image blob to image file
  useEffect(() => {
    if (imageFile !== undefined) {
      const file = new File([imageFile], "filename.png", {
        type: imageFile.type,
        lastModified: new Date().getTime(),
      });
      setFiles(file);
      console.log(file, "fileee");
    }
  }, [imageFile]);

  //validation for dropdowns
  const [sub, setSub] = useState(false);
  const [sectorError, setSectorError] = useState("");
  const [focusError, setFocusError] = useState("");
  const [regionError, setRegionError] = useState("");
  const [countryError, setCountryError] = useState("");
  const onSignUp = () => {
    if (select.length === 0) {
      setSectorError("*required");
    }
    if (focus.length === 0) {
      setFocusError("*required");
    }
    if (reg.length === 0) {
      setRegionError("*required");
    }
    if (mulcou.length === 0) {
      setCountryError("*required");
    }
  };
  useEffect(() => {
    setSectorError("");
    if (select.length === 0 && sub) {
      setSectorError("*required");
    }
  }, [select]);

  useEffect(() => {
    setFocusError("");
    if (focus.length === 0 && sub) {
      setFocusError("*required");
    }
  }, [focus]);
  useEffect(() => {
    setRegionError("");
    if (reg.length === 0 && sub) {
      setRegionError("*required");
    }
  }, [reg]);

  useEffect(() => {
    setCountryError("");
    if (mulcou.length === 0 && sub) {
      setCountryError("*required");
    }
  }, [mulcou]);
  //onsubmit function to handle and pass the data to the backend
  const onSubmit = async (values) => {
    const form = new FormData();
    form.append("username", formik?.values?.email);
    form.append("first_name", formik?.values?.first_name);
    form.append("last_name", formik?.values?.lastName);
    form.append("email", formik?.values?.email);
    form.append("country", formik?.values?.country);
    form.append("state", formik?.values?.state);
    form.append("organisation", 1);
    form.append("designation", formik?.values?.jobTitle);
    form.append("resume_path", formik?.values?.resume || "");

    sectorValues.forEach((sectorValue) =>
      form.append("pref_sector", sectorValue)
    );
    countryValues.forEach((countryValue) =>
      form.append("pref_country", countryValue)
    );
    focusValues.forEach((focusValue) => form.append("pref_topic", focusValue));
    regionValues.forEach((regionValue) =>
      form.append("pref_region", regionValue)
    );
    form.append("is_profile_set", true);
    form.append("profile_pic_path", files || "");
    form.append("city", formik?.values?.city);

    if (!sectorError && !focusError && !regionError && !countryError) {
      return _profileUpdate(token, form).then((response) => {

        handleSho();
        setTimeout(()=>{
          navigate('/')
        },3000)               
      });
    }
  };
  //validation schema for other fields
  const validate = (values) => {
    const errors = {};
    const regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;
    const re = /(ftp|http|https):\/\/?(?:www\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    if (!values.country) {
      errors.country = "*required";
    }
    if (!values.state) {
      errors.state = "*required";
    }
    if (!values.city) {
      errors.city = "*required";
    }
    if(values.linkedin){
      if(!re.test(values.linkedin)){
        errors.linkedin="*enter a valid link"
      }
    }
   

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      ...user,
      firstName: user?.first_name,
      lastName: user?.last_name,
      country: "",
      state: "",
      city: "",
      orgName: user?.organisation_name,
      jobTitle: "",
      focusArea: [],
      countries: [],
      region: [],
      resume: null,
      linkedin: "",
      sector: "",
      email: user?.email,
      profile: null,
    },
    onSubmit: onSubmit,
    validate,
    enableReinitialize: true,
  });

  return (
    <div>
      <Helmet>
        <title>
          DevDiscovery | Authentication
        </title>
      </Helmet>
        <Container>
        <div className="loader-middle-align">
              <div className="loader loader-3">
                <div className="dot dot1">&nbsp;</div>
                <div className="dot dot2">&nbsp;</div>
                <div className="dot dot3">&nbsp;</div>
              </div>
            </div>
        </Container>
        
    </div>
  );
}

export default Dummy;
