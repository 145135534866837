import { getCountryCallingCode } from 'react-phone-number-input';
import { create } from 'react-test-renderer';
import {createAction,handleActions} from 'redux-actions';
import ActionTypes from '~shared/constants/actionTypes';
import { _keyWordSearch,_saveQuery,_voteUp,_listUserstoShareQuery,_recordDetails,_userListQuerySharedTo,
  _shareSingleQuery,_bookmark,_listUsersRecord,_shareRecord,_userRecomendations,_userProfile,_viewSimilar,
  _docSearch,_recordDownload,_keyWordSearchData,_keyWordSearchDevpubs,_keyWordSearchEconpubs,_keyWordSearchEvaluations,
  _keyWordSearchExperts,_keyWordSearchFinancing,_keyWordSearchMultimedia,_keyWordSearchOtherpubs,_keyWordSearchNews
 } from '../../../shared/httpservice/apis';

const initialState = {
  keyword_search:{ loading: false, response: {}, hasError: false, error: {} },
  keyword_search_fin:{ loading: false, response: {}, hasError: false, error: {} },
  keyword_search_dev:{ loading: false, response: {}, hasError: false, error: {} },
  keyword_search_other:{ loading: false, response: {}, hasError: false, error: {} },
  keyword_search_data:{ loading: false, response: {}, hasError: false, error: {} },
  keyword_search_mul:{ loading: false, response: {}, hasError: false, error: {} },
  keyword_search_news:{ loading: false, response: {}, hasError: false, error: {} },
  keyword_search_experts:{ loading: false, response: {}, hasError: false, error: {} },
  keyword_search_econ:{ loading: false, response: {}, hasError: false, error: {} },
  keyword_search_eval:{ loading: false, response: {}, hasError: false, error: {} },
  file:"",
  search:"",
  source:"",
  region:"",
  country:"",
  sector:"",
  topic:"",
  startDates:"",
  endDates:"",
  profilePic : null,
  saveQuery : { loading: false, response: {}, hasError: false, error: {} },
  voteUp : {loading: false, response:{},hasError:false,error:{}},
  userListShareQuery : {loading: false, response:{},hasError:false,error:{}},
  recordDetails :{loading: false, response:{},hasError:false,error:{}},
  userListQuerySharedTo :{loading: false, response:{},hasError:false,error:{}},
  shareSingleQuery : {loading: false, response:{},hasError:false,error:{}},
  bookmark : {loading: false, response:{},hasError:false,error:{}},
  listUsersRecord : {loading: false, response:{},hasError:false,error:{}},
  shareRecord : {loading: false, response:{},hasError:false,error:{}},
  userRecomendations : {loading: false, response:{},hasError:false,error:{}},
  userProfile : {loading: false, response:{},hasError:false,error:{}},
  view_similar : {loading: false, response:{},hasError:false,error:{}},
  doc_search : {loading: false, response:{},hasError:false,error:{}},
  record_download : {loading: false, response:{},hasError:false,error:{}},
  financeSource : "",
  financeRegion : "",
  financeCountry : "",
  financeSector : "",
  financeTopic : "",
  financeSdate : "",
  financeEdate : "",
  devSource : "",
  devRegion : "",
  devCountry : "",
  devSector : "",
  devTopic : "",
  devSdate : "",
  devEdate : "",
  otherSource : "",
  otherRegion : "",
  otherCountry : "",
  otherSector : "",
  otherTopic : "",
  otherSdate : "",
  otherEdate : "",
  dataSource : "",
  dataRegion : "",
  dataCountry : "",
  dataSector : "",
  dataTopic : "",
  dataResource : "",
  dataSdate : "",
  dataEdate : "",
  econSource : "",
  econRegion : "",
  econCountry : "",
  econSector : "",
  econTopic : "",
  econSdate : "",
  econEdate : "",
  mulSource : "",
  mulRegion : "",
  mulCountry : "",
  mulSector : "",
  mulTopic : "",
  mulSdate : "",
  mulEdate : "",
  evalSource : "",
  evalRegion : "",
  evalCountry : "",
  evalSector : "",
  evalTopic : "",
  evalSdate : "",
  evalEdate : "",
  viewSimilarId : "",
  currentCategory : "",
  search_state : true,
  view_all : false,
  result_title : "",
  file_path : false,
  current_tab : "",
  file_var : ""

 
}



export const keywordSearchStart = createAction(ActionTypes.KEYWORD_SEARCH);
export const keywordSearchSuccess = createAction(ActionTypes.KEYWORD_SEARCH_SUCCESS,response=>response);
export const keywordSearchError = createAction(ActionTypes.KEYWORD_SEARCH_ERROR)

export const keywordSearch=(data,token,category)=>async (dispatch)=>{
  dispatch(keywordSearchStart());
  try {
    const response = await _keyWordSearch(data,token,category);
    dispatch(keywordSearchSuccess(response));

  } catch (error) {
    dispatch(keywordSearchError(error));
  }
}

export const keywordSearchFinStart = createAction(ActionTypes.KEYWORD_SEARCH_FIN);
export const keywordSearchFinSuccess = createAction(ActionTypes.KEYWORD_SEARCH_FIN_SUCCESS,response=>response);
export const keywordSearchFinError = createAction(ActionTypes.KEYWORD_SEARCH_FIN_ERROR)

export const keywordSearchFin=(data,token)=>async (dispatch)=>{
  dispatch(keywordSearchFinStart());
  try {
    const response = await _keyWordSearchFinancing(data,token);
    dispatch(keywordSearchFinSuccess(response));

  } catch (error) {
    dispatch(keywordSearchFinError(error));
  }
}

export const keywordSearchDevStart = createAction(ActionTypes.KEYWORD_SEARCH_DEV);
export const keywordSearchDevSuccess = createAction(ActionTypes.KEYWORD_SEARCH_DEV_SUCCESS,response=>response);
export const keywordSearchDevError = createAction(ActionTypes.KEYWORD_SEARCH_DEV_ERROR)

export const keywordSearchDev=(data,token)=>async (dispatch)=>{
  dispatch(keywordSearchDevStart());
  try {
    const response = await _keyWordSearchDevpubs(data,token);
    dispatch(keywordSearchDevSuccess(response));

  } catch (error) {
    dispatch(keywordSearchDevError(error));
  }
}

export const keywordSearchOtherStart = createAction(ActionTypes.KEYWORD_SEARCH_OTHER);
export const keywordSearchOtherSuccess = createAction(ActionTypes.KEYWORD_SEARCH_OTHER_SUCCESS,response=>response);
export const keywordSearchOtherError = createAction(ActionTypes.KEYWORD_SEARCH_OTHER_ERROR)

export const keywordSearchOther=(data,token)=>async (dispatch)=>{
  dispatch(keywordSearchOtherStart());
  try {
    const response = await _keyWordSearchOtherpubs(data,token);
    dispatch(keywordSearchOtherSuccess(response));

  } catch (error) {
    dispatch(keywordSearchOtherError(error));
  }
}

export const keywordSearchDataStart = createAction(ActionTypes.KEYWORD_SEARCH_DATA);
export const keywordSearchDataSuccess = createAction(ActionTypes.KEYWORD_SEARCH_DATA_SUCCESS,response=>response);
export const keywordSearchDataError = createAction(ActionTypes.KEYWORD_SEARCH_DATA_ERROR)

export const keywordSearchData=(data,token)=>async (dispatch)=>{
  dispatch(keywordSearchDataStart());
  try {
    const response = await _keyWordSearchData(data,token);
    dispatch(keywordSearchDataSuccess(response));

  } catch (error) {
    dispatch(keywordSearchDataError(error));
  }
}

export const keywordSearchMulStart = createAction(ActionTypes.KEYWORD_SEARCH_MUL);
export const keywordSearchMulSuccess = createAction(ActionTypes.KEYWORD_SEARCH_MUL_SUCCESS,response=>response);
export const keywordSearchMulError = createAction(ActionTypes.KEYWORD_SEARCH_MUL_ERROR)

export const keywordSearchMul=(data,token)=>async (dispatch)=>{
  dispatch(keywordSearchMulStart());
  try {
    const response = await _keyWordSearchMultimedia(data,token);
    dispatch(keywordSearchMulSuccess(response));

  } catch (error) {
    dispatch(keywordSearchMulError(error));
  }
}

export const keywordSearchNewsStart = createAction(ActionTypes.KEYWORD_SEARCH_NEWS);
export const keywordSearchNewsSuccess = createAction(ActionTypes.KEYWORD_SEARCH_NEWS_SUCCESS,response=>response);
export const keywordSearchNewsError = createAction(ActionTypes.KEYWORD_SEARCH_NEWS_ERROR)

export const keywordSearchNews=(data,token)=>async (dispatch)=>{
  dispatch(keywordSearchNewsStart());
  try {
    const response = await _keyWordSearchNews(data,token);
    dispatch(keywordSearchNewsSuccess(response));

  } catch (error) {
    dispatch(keywordSearchNewsError(error));
  }
}

export const keywordSearchEconStart = createAction(ActionTypes.KEYWORD_SEARCH_ECON);
export const keywordSearchEconSuccess = createAction(ActionTypes.KEYWORD_SEARCH_ECON_SUCCESS,response=>response);
export const keywordSearchEconError = createAction(ActionTypes.KEYWORD_SEARCH_ECON_ERROR)

export const keywordSearchEcon=(data,token)=>async (dispatch)=>{
  dispatch(keywordSearchEconStart());
  try {
    const response = await _keyWordSearchEconpubs(data,token);
    dispatch(keywordSearchEconSuccess(response));

  } catch (error) {
    dispatch(keywordSearchEconError(error));
  }
}

export const keywordSearchExpertsStart = createAction(ActionTypes.KEYWORD_SEARCH_EXPERTS);
export const keywordSearchExpertsSuccess = createAction(ActionTypes.KEYWORD_SEARCH_EXPERTS_SUCCESS,response=>response);
export const keywordSearchExpertsError = createAction(ActionTypes.KEYWORD_SEARCH_EXPERTS_ERROR)

export const keywordSearchExperts=(data,token)=>async (dispatch)=>{
  dispatch(keywordSearchExpertsStart());
  try {
    const response = await _keyWordSearchExperts(data,token);
    dispatch(keywordSearchExpertsSuccess(response));

  } catch (error) {
    dispatch(keywordSearchExpertsError(error));
  }
}

export const keywordSearchEvalStart = createAction(ActionTypes.KEYWORD_SEARCH_EVAL);
export const keywordSearchEvalSuccess = createAction(ActionTypes.KEYWORD_SEARCH_EVAL_SUCCESS,response=>response);
export const keywordSearchEvalError = createAction(ActionTypes.KEYWORD_SEARCH_EVAL_ERROR)

export const keywordSearchEval=(data,token)=>async (dispatch)=>{
  dispatch(keywordSearchEvalStart());
  try {
    const response = await _keyWordSearchEvaluations(data,token);
    dispatch(keywordSearchEvalSuccess(response));

  } catch (error) {
    dispatch(keywordSearchEvalError(error));
  }
}

export const docSearchStart = createAction(ActionTypes.DOC_SEARCH)
export const docSearchSuccess = createAction(ActionTypes.DOC_SEARCH_SUCCESS)
export const docSearchError = createAction(ActionTypes.DOC_SEARCH_ERROR)

export const docSearch =(token,data) => async (dispatch) =>{
  dispatch(docSearchStart())
  try {
    const response = await _docSearch(token,data)
    dispatch(docSearchSuccess(response))
  } catch (error) {
    dispatch(docSearchError(error))
  }
}

export const downloadStart = createAction(ActionTypes.REC_DOWNLOAD)
export const downloadSuccess = createAction(ActionTypes.REC_DOWNLOAD_SUCCESS)
export const downloadError = createAction(ActionTypes.REC_DOWNLOAD_ERROR)

export const recDowload =(token,data) => async (dispatch) =>{
  dispatch(downloadStart())
  try {
    const response = await _recordDownload(token,data)
    dispatch(downloadSuccess(response))
  } catch (error) {
    dispatch(downloadError(error))
  }
}

export const viewSimilarStart = createAction(ActionTypes.VIEW_SIMILAR)
export const viewSimilarSuccess = createAction(ActionTypes.VIEW_SIMILAR_SUCCESS)
export const viewSimilarError = createAction(ActionTypes.VIEW_SIMILAR_ERROR)

export const viewSimilar = (token,category,qid,org_name,region,country,sector,topic,start_date,end_date,rows,skip)=>async(dispatch)=>{
  dispatch(viewSimilarStart())
  try{
    const response = await _viewSimilar(token,category,qid,org_name,region,country,sector,topic,start_date,end_date,rows,skip)
    dispatch(viewSimilarSuccess(response))
  }catch(error){
    dispatch(viewSimilarError(error))
  }
}

export const saveQueryStart = createAction(ActionTypes.SAVE_QUERY)
export const saveQuerySuccess = createAction(ActionTypes.SAVE_QUERY_SUCCESS)
export const saveQueryError = createAction(ActionTypes.SAVE_QUERY_ERROR)

export const saveQuery = (data,token) =>async(dispatch)=>{
  dispatch(saveQueryStart())
  return _saveQuery(data,token) .then((response)=>{
    dispatch(saveQuerySuccess(response))

}).catch((error)=>{
    dispatch(saveQueryError(error))
})
}

export const voteUpStart = createAction(ActionTypes.VOTE_UP)
export const voteUpSuccess = createAction(ActionTypes.VOTE_UP_SUCCESS)
export const voteUpError = createAction(ActionTypes.VOTE_UP_ERROR)

export const voteUp=(data,token)=>(dispatch)=>{
  dispatch(voteUpStart())
  return _voteUp(data,token).then((response)=>{
    dispatch(voteUpSuccess(response))

  }).catch((error)=>{
    dispatch(voteUpError(error))
  })
}

export const userListSaveQueryStart = createAction(ActionTypes.USER_LIST)
export const userListSaveQuerySuccess = createAction(ActionTypes.USER_LIST_SUCCESS)
export const userListSaveQueryError = createAction(ActionTypes.USER_LIST_ERROR)

export const UserListSaveQuery = (token,search,sector,country,region,flag,offset) =>(dispatch)=>{
  dispatch(userListSaveQueryStart())
  return _listUserstoShareQuery(token,search,sector,country,region,flag,offset).then((response)=>{
    dispatch(userListSaveQuerySuccess(response))
  }).catch((error)=>{
    dispatch(userListSaveQueryError(error))
  })
}

export const recordDetailsStart = createAction(ActionTypes.RECORD_DETAILS)
export const recordDetailsSuccess = createAction(ActionTypes.RECORD_DETAILS_SUCCESS,response => response)
export const recordDetailsError = createAction(ActionTypes.RECORD_DETAILS_ERROR,error=>error)

export const recordDetails = (data,token) =>async (dispatch)=>{
  dispatch(recordDetailsStart())
  try {
    const response = await _recordDetails(data, token);
    dispatch(recordDetailsSuccess(response));
  } catch (error) {
    dispatch(recordDetailsError(error));
  }
}

export const usersListQuerySharedStart = createAction(ActionTypes.USER_LIST_QUERY_SHARED)
export const usersListQuerySharedSuccess = createAction(ActionTypes.USER_LIST_QUERY_SHARED_SUCCESS,response=>response)
export const usersListQuerySharedError = createAction(ActionTypes.USER_LIST_QUERY_SHARED_ERROR,error=>error)

export const usersListQueryShared = (data,token) => async(dispatch)=>{
  dispatch(usersListQuerySharedStart())
  return _userListQuerySharedTo(data,token).then((response)=>{
    dispatch(usersListQuerySharedSuccess(response))
  }).catch((error)=>{
    dispatch(usersListQuerySharedError(error))
  })
}

export const shareSingleQueryStart = createAction(ActionTypes.SHARE_SINGLE_QUERY)
export const shareSingleQuerySuccess = createAction(ActionTypes.SHARE_SINGLE_QUERY_SUCCESS,response=>response)
export const shareSingleQueryError = createAction(ActionTypes.SHARE_SINGLE_QUERY_ERROR,error=>error)

export const shareSingleQuery = (data,token,flag) => async (dispatch)=>{
  dispatch(shareSingleQueryStart())
  return _shareSingleQuery(data,token,flag).then((response)=>{
    dispatch(shareSingleQuerySuccess(response))
  }).catch((error)=>{
    dispatch(shareSingleQueryError(error))
  })
}

export const bookmarkStart = createAction(ActionTypes.BOOKMARK)
export const bookmarkSuccess = createAction(ActionTypes.BOOKMARK_SUCCESS,response=>response)
export const bookmarkError = createAction(ActionTypes.BOOKMARK_ERROR,error=>error) 

export const bookmarkRecords = (data,token) =>async(dispatch)=>{
  dispatch(bookmarkStart())
  return _bookmark(data,token).then((response)=>{
    dispatch(bookmarkSuccess(response))
  }).catch((error)=>{
    dispatch(bookmarkError(error))
  })
}

export const listUsersRecordStart = createAction(ActionTypes.LIST_USERS_RECORD)
export const listUsersRecordSuccess = createAction(ActionTypes.LIST_USERS_RECORD_SUCCESS)
export const listUsersRecordError = createAction(ActionTypes.LIST_USERS_RECORD_ERROR)

export const userListRecordShared = (project_id,token) => async(dispatch)=>{
  dispatch(listUsersRecordStart())
  return _listUsersRecord(project_id,token).then((response)=>{
    dispatch(listUsersRecordSuccess(response))
  }).catch((error)=>{
    dispatch(listUsersRecordError(error))
  })
}

export const shareRecordStart = createAction(ActionTypes.SHARE_RECORD)
export const shareRecordSuccess = createAction(ActionTypes.SHARE_RECORD_SUCCESS)
export const shareRecordError = createAction(ActionTypes.SHARE_RECORD_ERROR)

export const shareRecords = (data,token)=>async(dispatch)=>{
  dispatch(shareRecordStart())
  return _shareRecord(data,token).then((response)=>{
    dispatch(shareRecordSuccess(response))
  }).catch((error)=>{
    dispatch(shareRecordError(error))
  })
}

export const userRecomendationStart = createAction(ActionTypes.USER_RECOMENDATIONS)
export const userRecomendationsSuccess = createAction(ActionTypes.USER_RECOMENDATIONS_SUCCESS)
export const userRecomendationsError = createAction(ActionTypes.USER_RECOMENDATIONS_ERROR)

export const userRecomendation = (token)=>async(dispatch)=>{
  dispatch(userRecomendationStart())
  return _userRecomendations(token).then((response)=>{
    dispatch(userRecomendationsSuccess(response))
  }).catch((error)=>{
    dispatch(userRecomendationsError(error))
  })
}

export const userProfileStart = createAction(ActionTypes.USER_PROFILE);
export const userProfileSuccess = createAction(ActionTypes.USER_PROFILE_SUCCESS,response=>response);
export const userProfileError = createAction(ActionTypes.USER_PROFILE_ERROR);

export const userProfileInfo = (token)=>async(dispatch)=>{
    dispatch(userProfileStart())
    return _userProfile(token).then((response)=>{
        dispatch(userProfileSuccess(response))
    }).catch((error)=>{
        dispatch(userProfileError(error))
    })
}

const viewSimilarId = createAction(ActionTypes.SET_VIEW_SIMILAR_ID,(data)=>data);
export const setViewSimilarId = (data) => (dispatch) => {
  dispatch(viewSimilarId(data));
};

const viewAll = createAction(ActionTypes.SET_VIEW_ALL,(data)=>data);
export const setViewAll = (data) => (dispatch) => {
  dispatch(viewAll(data));
};
const setSearchAct = createAction(ActionTypes.SET_SEARCH, (data) => data);

export const setSearchKey = (data) => (dispatch) => {
  dispatch(setSearchAct(data));
};

const setFileAct = createAction(ActionTypes.SET_FILE,(data)=>data)

export const setFileKey = (data) => (dispatch) => {
  dispatch(setFileAct(data))
}

const category = createAction(ActionTypes.SET_CURRENT_CATEGORY,(data)=>data);
export const setCurrentCategory =(data)=>(dispatch)=>{
  dispatch(category(data))
}

const setSourceAct = createAction(ActionTypes.SET_SOURCE,(data)=>data)
const setRegionAct = createAction(ActionTypes.SET_REGION,(data)=>data)
const setCountryAct = createAction(ActionTypes.SET_COUNTRY,(data)=>data)
const setSectorAct = createAction(ActionTypes.SET_SECTOR,(data)=>data)
const setTopicAct = createAction(ActionTypes.SET_TOPIC,(data)=>data)
const setStartDateAct = createAction(ActionTypes.SET_START_DATE,(date)=>date)
const setEndDateAct = createAction(ActionTypes.SET_END_DATE,(date)=>date)
const setProfileImageAct = createAction(ActionTypes.SET_PROFILE_PIC,(data)=>data)

export const setProfileImage = (data) =>(dispatch)=>{
  dispatch(setProfileImageAct(data))
}

export const setSourceKey = (data) => (dispatch) =>{
  dispatch(setSourceAct(data))
}

export const setRegionKey = (data) => (dispatch) =>{
  dispatch(setRegionAct(data))
}

export const setCountryKey = (data) => (dispatch) =>{
  dispatch(setCountryAct(data))
}

export const setSectorKey = (data) => (dispatch) =>{
  dispatch(setSectorAct(data))
}

export const setTopicKey = (data) => (dispatch) =>{
  dispatch(setTopicAct(data))
}

export const setStartDateKey =(data) =>(dispatch)=>{
  dispatch(setStartDateAct(data))
}

export const setEndDateKey =(data)=>(dispatch)=>{
  dispatch(setEndDateAct(data))
}

const setResultTitleAction = createAction(ActionTypes.SET_RESULT_TITLE,(data)=>data)
export const setResultTitle = (data) =>(dispatch) =>{
  dispatch(setResultTitleAction(data))
}

const setFSourceAct = createAction(ActionTypes.SET_FSOURCE,(data)=>data)
const setFRegionAct = createAction(ActionTypes.SET_FREGION,(data)=>data)
const setFCountryAct = createAction(ActionTypes.SET_FCOUNTRY,(data)=>data)
const setFSectorAct = createAction(ActionTypes.SET_FSECTOR,(data)=>data)
const setFTopicAct = createAction(ActionTypes.SET_FTOPIC,(data)=>data)
const setFStartDateAct = createAction(ActionTypes.SET_FSDATE,(date)=>date)
const setFEndDateAct = createAction(ActionTypes.SET_FEDATE,(date)=>date)

export const setFSourceKey = (data) => (dispatch) =>{
  dispatch(setFSourceAct(data))
}

export const setFRegionKey = (data) => (dispatch) =>{
  dispatch(setFRegionAct(data))
}

export const setFCountryKey = (data) => (dispatch) =>{
  dispatch(setFCountryAct(data))
}

export const setFSectorKey = (data) => (dispatch) =>{
  dispatch(setFSectorAct(data))
}

export const setFTopicKey = (data) => (dispatch) =>{
  dispatch(setFTopicAct(data))
}

export const setFStartDateKey =(data) =>(dispatch)=>{
  dispatch(setFStartDateAct(data))
}

export const setFEndDateKey =(data)=>(dispatch)=>{
  dispatch(setFEndDateAct(data))
}

const setDSourceAct = createAction(ActionTypes.SET_DSOURCE,(data)=>data)
const setDRegionAct = createAction(ActionTypes.SET_DREGION,(data)=>data)
const setDCountryAct = createAction(ActionTypes.SET_DCOUNTRY,(data)=>data)
const setDSectorAct = createAction(ActionTypes.SET_DSECTOR,(data)=>data)
const setDTopicAct = createAction(ActionTypes.SET_DTOPIC,(data)=>data)
const setDStartDateAct = createAction(ActionTypes.SET_DSDATE,(date)=>date)
const setDEndDateAct = createAction(ActionTypes.SET_DEDATE,(date)=>date)

export const setDSourceKey = (data) => (dispatch) =>{
  dispatch(setDSourceAct(data))
}

export const setDRegionKey = (data) => (dispatch) =>{
  dispatch(setDRegionAct(data))
}

export const setDCountryKey = (data) => (dispatch) =>{
  dispatch(setDCountryAct(data))
}

export const setDSectorKey = (data) => (dispatch) =>{
  dispatch(setDSectorAct(data))
}

export const setDTopicKey = (data) => (dispatch) =>{
  dispatch(setDTopicAct(data))
}

export const setDStartDateKey =(data) =>(dispatch)=>{
  dispatch(setDStartDateAct(data))
}

export const setDEndDateKey =(data)=>(dispatch)=>{
  dispatch(setDEndDateAct(data))
}

const setOSourceAct = createAction(ActionTypes.SET_OSOURCE,(data)=>data)
const setORegionAct = createAction(ActionTypes.SET_OREGION,(data)=>data)
const setOCountryAct = createAction(ActionTypes.SET_OCOUNTRY,(data)=>data)
const setOSectorAct = createAction(ActionTypes.SET_OSECTOR,(data)=>data)
const setOTopicAct = createAction(ActionTypes.SET_OTOPIC,(data)=>data)
const setOStartDateAct = createAction(ActionTypes.SET_OSDATE,(date)=>date)
const setOEndDateAct = createAction(ActionTypes.SET_OEDATE,(date)=>date)

export const setOSourceKey = (data) => (dispatch) =>{
  dispatch(setOSourceAct(data))
}

export const setORegionKey = (data) => (dispatch) =>{
  dispatch(setORegionAct(data))
}

export const setOCountryKey = (data) => (dispatch) =>{
  dispatch(setOCountryAct(data))
}

export const setOSectorKey = (data) => (dispatch) =>{
  dispatch(setOSectorAct(data))
}

export const setOTopicKey = (data) => (dispatch) =>{
  dispatch(setOTopicAct(data))
}

export const setOStartDateKey =(data) =>(dispatch)=>{
  dispatch(setOStartDateAct(data))
}

export const setOEndDateKey =(data)=>(dispatch)=>{
  dispatch(setOEndDateAct(data))
}

const setDataSourceAct = createAction(ActionTypes.SET_DATASOURCE,(data)=>data)
const setDataRegionAct = createAction(ActionTypes.SET_DATAREGION,(data)=>data)
const setDataCountryAct = createAction(ActionTypes.SET_DATACOUNTRY,(data)=>data)
const setDataSectorAct = createAction(ActionTypes.SET_DATASECTOR,(data)=>data)
const setDataTopicAct = createAction(ActionTypes.SET_DATATOPIC,(data)=>data)
const setDataResourceAct = createAction(ActionTypes.SET_DATARESOURCE,(data)=>data)
const setDataStartDateAct = createAction(ActionTypes.SET_DATASDATE,(date)=>date)
const setDataEndDateAct = createAction(ActionTypes.SET_DATAEDATE,(date)=>date)

export const setDataSourceKey = (data) => (dispatch) =>{
  dispatch(setDataSourceAct(data))
}

export const setDataRegionKey = (data) => (dispatch) =>{
  dispatch(setDataRegionAct(data))
}

export const setDataCountryKey = (data) => (dispatch) =>{
  dispatch(setDataCountryAct(data))
}

export const setDataSectorKey = (data) => (dispatch) =>{
  dispatch(setDataSectorAct(data))
}

export const setDataTopicKey = (data) => (dispatch) =>{
  dispatch(setDataTopicAct(data))
}

export const setDataResourceKey = (data) => (dispatch) =>{
  dispatch(setDataResourceAct(data))
}

export const setDataStartDateKey =(data) =>(dispatch)=>{
  dispatch(setDataStartDateAct(data))
}

export const setDataEndDateKey =(data)=>(dispatch)=>{
  dispatch(setDataEndDateAct(data))
}

const setEconSourceAct = createAction(ActionTypes.SET_ECONSOURCE,(data)=>data)
const setEconRegionAct = createAction(ActionTypes.SET_ECONREGION,(data)=>data)
const setEconCountryAct = createAction(ActionTypes.SET_ECONCOUNTRY,(data)=>data)
const setEconSectorAct = createAction(ActionTypes.SET_ECONSECTOR,(data)=>data)
const setEconTopicAct = createAction(ActionTypes.SET_ECONTOPIC,(data)=>data)
const setEconStartDateAct = createAction(ActionTypes.SET_ECONSDATE,(date)=>date)
const setEconEndDateAct = createAction(ActionTypes.SET_ECONEDATE,(date)=>date)

export const setEconSourceKey = (data) => (dispatch) =>{
  dispatch(setEconSourceAct(data))
}

export const setEconRegionKey = (data) => (dispatch) =>{
  dispatch(setEconRegionAct(data))
}

export const setEconCountryKey = (data) => (dispatch) =>{
  dispatch(setEconCountryAct(data))
}

export const setEconSectorKey = (data) => (dispatch) =>{
  dispatch(setEconSectorAct(data))
}

export const setEconTopicKey = (data) => (dispatch) =>{
  dispatch(setEconTopicAct(data))
}

export const setEconStartDateKey =(data) =>(dispatch)=>{
  dispatch(setEconStartDateAct(data))
}

export const setEconEndDateKey =(data)=>(dispatch)=>{
  dispatch(setEconEndDateAct(data))
}

const setMulSourceAct = createAction(ActionTypes.SET_MULSOURCE,(data)=>data)
const setMulRegionAct = createAction(ActionTypes.SET_MULREGION,(data)=>data)
const setMulCountryAct = createAction(ActionTypes.SET_MULCOUNTRY,(data)=>data)
const setMulSectorAct = createAction(ActionTypes.SET_MULSECTOR,(data)=>data)
const setMulTopicAct = createAction(ActionTypes.SET_MULTOPIC,(data)=>data)
const setMulStartDateAct = createAction(ActionTypes.SET_MULSDATE,(date)=>date)
const setMulEndDateAct = createAction(ActionTypes.SET_MULEDATE,(date)=>date)

export const setMulSourceKey = (data) => (dispatch) =>{
  dispatch(setMulSourceAct(data))
}

export const setMulRegionKey = (data) => (dispatch) =>{
  dispatch(setMulRegionAct(data))
}

export const setMulCountryKey = (data) => (dispatch) =>{
  dispatch(setMulCountryAct(data))
}

export const setMulSectorKey = (data) => (dispatch) =>{
  dispatch(setMulSectorAct(data))
}

export const setMulTopicKey = (data) => (dispatch) =>{
  dispatch(setMulTopicAct(data))
}

export const setMulStartDateKey =(data) =>(dispatch)=>{
  dispatch(setMulStartDateAct(data))
}

export const setMulEndDateKey =(data)=>(dispatch)=>{
  dispatch(setMulEndDateAct(data))
}

const setEvalSourceAct = createAction(ActionTypes.SET_EVALSOURCE,(data)=>data)
const setEvalRegionAct = createAction(ActionTypes.SET_EVALREGION,(data)=>data)
const setEvalCountryAct = createAction(ActionTypes.SET_EVALCOUNTRY,(data)=>data)
const setEvalSectorAct = createAction(ActionTypes.SET_EVALSECTOR,(data)=>data)
const setEvalTopicAct = createAction(ActionTypes.SET_EVALTOPIC,(data)=>data)
const setEvalStartDateAct = createAction(ActionTypes.SET_EVALSDATE,(date)=>date)
const setEvalEndDateAct = createAction(ActionTypes.SET_EVALEDATE,(date)=>date)

const setSearchStateAct = createAction(ActionTypes.SET_SEARCH_STATE)
export const setSearchStateKey = (data) => (dispatch) =>{
  dispatch(setSearchStateAct(data))
}

export const setEvalSourceKey = (data) => (dispatch) =>{
  dispatch(setEvalSourceAct(data))
}

export const setEvalRegionKey = (data) => (dispatch) =>{
  dispatch(setEvalRegionAct(data))
}

export const setEvalCountryKey = (data) => (dispatch) =>{
  dispatch(setEvalCountryAct(data))
}

export const setEvalSectorKey = (data) => (dispatch) =>{
  dispatch(setEvalSectorAct(data))
}

export const setEvalTopicKey = (data) => (dispatch) =>{
  dispatch(setEvalTopicAct(data))
}

export const setEvalStartDateKey =(data) =>(dispatch)=>{
  dispatch(setEvalStartDateAct(data))
}

export const setEvalEndDateKey =(data)=>(dispatch)=>{
  dispatch(setEvalEndDateAct(data))
}

const setFilePathAction = createAction(ActionTypes.SET_FILE_PATH,(data)=>data)

export const setFilePathFn =(data)=>(dispatch)=>{
  dispatch(setFilePathAction(data))
}

const setCurrentTabAction = createAction(ActionTypes.SET_CURRENT_TAB,(data)=>data)

export const setCurrentTabFn =(data)=>(dispatch)=>{
  dispatch(setCurrentTabAction(data))
}

const setFilepathVarAction = createAction(ActionTypes.SET_FILE_VAR)

export const setFileVar = (data) => (dispatch) => {
  dispatch(setFilepathVarAction(data))
}





const reducer = handleActions({
  [ActionTypes.KEYWORD_SEARCH]: (state) => ({
      ...state,
      keyword_search: {
          ...state. keyword_search,
          loading: true, hasError: false, error: {}
      }
  }),
  [ActionTypes.KEYWORD_SEARCH_SUCCESS]: (state, action) => ({
      ...state,
      keyword_search: {
          ...state.  keyword_search,
          response: action.payload, loading: false, hasError: false, error: {}
      },

  }),
  [ActionTypes.KEYWORD_SEARCH_ERROR]: (state, action) => ({
      ...state,
      keyword_search: {
          ...state.  keyword_search,
          response: {}, loading: false, hasError: true, error: action.payload
      },

  }),
  [ActionTypes.KEYWORD_SEARCH_FIN]: (state) => ({
    ...state,
    keyword_search_fin: {
        ...state. keyword_search_fin,
        loading: true, hasError: false, error: {}
    }
}),
[ActionTypes.KEYWORD_SEARCH_FIN_SUCCESS]: (state, action) => ({
    ...state,
    keyword_search_fin: {
        ...state.  keyword_search_fin,
        response: action.payload, loading: false, hasError: false, error: {}
    },

}),
[ActionTypes.KEYWORD_SEARCH_FIN_ERROR]: (state, action) => ({
    ...state,
    keyword_search_fin: {
        ...state.  keyword_search_fin,
        response: {}, loading: false, hasError: true, error: action.payload
    },

}),
[ActionTypes.KEYWORD_SEARCH_DEV]: (state) => ({
  ...state,
  keyword_search_dev: {
      ...state. keyword_search_dev,
      loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.KEYWORD_SEARCH_DEV_SUCCESS]: (state, action) => ({
  ...state,
  keyword_search_dev: {
      ...state.  keyword_search_dev,
      response: action.payload, loading: false, hasError: false, error: {}
  },

}),
[ActionTypes.KEYWORD_SEARCH_DEV_ERROR]: (state, action) => ({
  ...state,
  keyword_search_dev: {
      ...state.  keyword_search_dev,
      response: {}, loading: false, hasError: true, error: action.payload
  },

}),
[ActionTypes.KEYWORD_SEARCH_OTHER]: (state) => ({
  ...state,
  keyword_search_other: {
      ...state. keyword_search_other,
      loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.KEYWORD_SEARCH_OTHER_SUCCESS]: (state, action) => ({
  ...state,
  keyword_search_other: {
      ...state.  keyword_search_other,
      response: action.payload, loading: false, hasError: false, error: {}
  },

}),
[ActionTypes.KEYWORD_SEARCH_OTHER_ERROR]: (state, action) => ({
  ...state,
  keyword_search_other: {
      ...state.  keyword_search_other,
      response: {}, loading: false, hasError: true, error: action.payload
  },

}),
[ActionTypes.KEYWORD_SEARCH_DATA]: (state) => ({
  ...state,
  keyword_search_data: {
      ...state. keyword_search_data,
      loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.KEYWORD_SEARCH_DATA_SUCCESS]: (state, action) => ({
  ...state,
  keyword_search_data: {
      ...state.  keyword_search_data,
      response: action.payload, loading: false, hasError: false, error: {}
  },

}),
[ActionTypes.KEYWORD_SEARCH_DATA_ERROR]: (state, action) => ({
  ...state,
  keyword_search_data: {
      ...state.  keyword_search_data,
      response: {}, loading: false, hasError: true, error: action.payload
  },

}),
[ActionTypes.KEYWORD_SEARCH_MUL]: (state) => ({
  ...state,
  keyword_search_mul: {
      ...state. keyword_search_mul,
      loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.KEYWORD_SEARCH_MUL_SUCCESS]: (state, action) => ({
  ...state,
  keyword_search_mul: {
      ...state.  keyword_search_mul,
      response: action.payload, loading: false, hasError: false, error: {}
  },

}),
[ActionTypes.KEYWORD_SEARCH_MUL_ERROR]: (state, action) => ({
  ...state,
  keyword_search_mul: {
      ...state.  keyword_search_mul,
      response: {}, loading: false, hasError: true, error: action.payload
  },

}),

[ActionTypes.KEYWORD_SEARCH_EVAL]: (state) => ({
  ...state,
  keyword_search_eval: {
      ...state. keyword_search_eval,
      loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.KEYWORD_SEARCH_EVAL_SUCCESS]: (state, action) => ({
  ...state,
  keyword_search_eval: {
      ...state.  keyword_search_eval,
      response: action.payload, loading: false, hasError: false, error: {}
  },

}),
[ActionTypes.KEYWORD_SEARCH_EVAL_ERROR]: (state, action) => ({
  ...state,
  keyword_search_eval: {
      ...state.  keyword_search_eval,
      response: {}, loading: false, hasError: true, error: action.payload
  },

}),
[ActionTypes.KEYWORD_SEARCH_NEWS]: (state) => ({
  ...state,
  keyword_search_news: {
      ...state. keyword_search_news,
      loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.KEYWORD_SEARCH_NEWS_SUCCESS]: (state, action) => ({
  ...state,
  keyword_search_news: {
      ...state.  keyword_search_news,
      response: action.payload, loading: false, hasError: false, error: {}
  },

}),
[ActionTypes.KEYWORD_SEARCH_NEWS_ERROR]: (state, action) => ({
  ...state,
  keyword_search_news: {
      ...state.  keyword_search_news,
      response: {}, loading: false, hasError: true, error: action.payload
  },

}),
[ActionTypes.KEYWORD_SEARCH_ECON]: (state) => ({
  ...state,
  keyword_search_econ: {
      ...state. keyword_search_econ,
      loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.KEYWORD_SEARCH_ECON_SUCCESS]: (state, action) => ({
  ...state,
  keyword_search_econ: {
      ...state.  keyword_search_econ,
      response: action.payload, loading: false, hasError: false, error: {}
  },

}),
[ActionTypes.KEYWORD_SEARCH_ECON_ERROR]: (state, action) => ({
  ...state,
  keyword_search_econ: {
      ...state.  keyword_search_econ,
      response: {}, loading: false, hasError: true, error: action.payload
  },

}),
[ActionTypes.KEYWORD_SEARCH_EXPERTS]: (state) => ({
  ...state,
  keyword_search_experts: {
      ...state. keyword_search_experts,
      loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.KEYWORD_SEARCH_EXPERTS_SUCCESS]: (state, action) => ({
  ...state,
  keyword_search_experts: {
      ...state.  keyword_search_experts,
      response: action.payload, loading: false, hasError: false, error: {}
  },

}),
[ActionTypes.KEYWORD_SEARCH_EXPERTS_ERROR]: (state, action) => ({
  ...state,
  keyword_search_experts: {
      ...state.  keyword_search_experts,
      response: {}, loading: false, hasError: true, error: action.payload
  },

}),
[ActionTypes.DOC_SEARCH]: (state) => ({
  ...state,
  doc_search: {
      ...state. doc_search,
      loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.DOC_SEARCH_SUCCESS]: (state, action) => ({
    ...state,
    doc_search: {
        ...state.  doc_search,
        response: action.payload, loading: false, hasError: false, error: {}
    },

}),
[ActionTypes.DOC_SEARCH_ERROR]: (state, action) => ({
    ...state,
    doc_search: {
        ...state.  doc_search,
        response: {}, loading: false, hasError: true, error: action.payload
    },

}),
[ActionTypes.REC_DOWNLOAD]: (state) => ({
  ...state,
  record_download: {
      ...state. doc_search,
      loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.REC_DOWNLOAD_SUCCESS]: (state, action) => ({
  ...state,
  record_download: {
      ...state.  doc_search,
      response: action.payload, loading: false, hasError: false, error: {}
  },

}),
[ActionTypes.REC_DOWNLOAD_ERROR]: (state, action) => ({
  ...state,
  record_download: {
      ...state.  doc_search,
      response: {}, loading: false, hasError: true, error: action.payload
  },

}),
  [ActionTypes.SET_SEARCH]: (state, action) => ({
    ...state,
    search: action.payload,
  }),
  [ActionTypes.SET_SOURCE]: (state, action) => ({
    ...state,
    source: action.payload,
  }),
  [ActionTypes.SET_REGION]: (state, action) => ({
    ...state,
    region: action.payload,
  }),
  [ActionTypes.SET_COUNTRY]: (state, action) => ({
    ...state,
    country: action.payload,
  }),
  [ActionTypes.SET_SECTOR]: (state, action) => ({
    ...state,
    sector: action.payload,
  }),
  [ActionTypes.SET_TOPIC]: (state, action) => ({
    ...state,
    topic: action.payload,
  }),
  [ActionTypes.SET_START_DATE]: (state, action) => ({
    ...state,
    startDates: action.payload,
  }),
  [ActionTypes.SET_END_DATE]: (state, action) => ({
    ...state,
    endDates: action.payload,
  }),
  [ActionTypes.SET_PROFILE_PIC]: (state, action) => ({
    ...state,
    profilePic: action.payload,
  }),

  [ActionTypes.SAVE_QUERY]: (state) => ({
    ...state,
    saveQuery: {
        ...state.saveQuery,
        loading: true, hasError: false, error: {}
    }
}),
[ActionTypes.SAVE_QUERY_SUCCESS]: (state, action) => ({
    ...state,
    saveQuery: {
        ...state.saveQuery,
        response: action.payload, loading: false, hasError: false, error: {}
    },

}),
[ActionTypes.SAVE_QUERY_ERROR]: (state, action) => ({
    ...state,
    saveQuery: {
        ...state.saveQuery,
        response: {}, loading: false, hasError: true, error: action.payload
    },

}),
[ActionTypes.VOTE_UP]: (state) => ({
  ...state,
  voteUp: {
      ...state. voteUp,
      loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.VOTE_UP_SUCCESS]: (state, action) => ({
  ...state,
  voteUp: {
      ...state.  voteUp,
      response: action.payload, loading: false, hasError: false, error: {}
  },

}),
[ActionTypes.VOTE_UP_ERROR]: (state, action) => ({
  ...state,
  voteUp: {
      ...state.  voteUp,
      response: {}, loading: false, hasError: true, error: action.payload
  },

}),
[ActionTypes.USER_LIST]: (state) => ({
  ...state,
  userListShareQuery: {
      ...state. userListShareQuery,
      loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.USER_LIST_SUCCESS]: (state, action) => ({
  ...state,
  userListShareQuery: {
      ...state.  userListShareQuery,
      response: action.payload, loading: false, hasError: false, error: {}
  },

}),
[ActionTypes.USER_LIST_ERROR]: (state, action) => ({
  ...state,
  userListShareQuery: {
      ...state.  userListShareQuery,
      response: {}, loading: false, hasError: true, error: action.payload
  },

}),

[ActionTypes.RECORD_DETAILS]: (state) => ({
  ...state,
  recordDetails: {
      ...state. recordDetails,
      loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.RECORD_DETAILS_SUCCESS]: (state, action) => ({
  ...state,
  recordDetails: {
      ...state.  recordDetails,
      response: action.payload, loading: false, hasError: false, error: {}
  },

}),
[ActionTypes.RECORD_DETAILS_ERROR]: (state, action) => ({
  ...state,
  recordDetails: {
      ...state.  recordDetails,
      response: {}, loading: false, hasError: true, error: action.payload
  },

}),
[ActionTypes.USER_LIST_QUERY_SHARED]: (state) => ({
  ...state,
  userListQuerySharedTo: {
      ...state. userListQuerySharedTo,
      loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.USER_LIST_QUERY_SHARED_SUCCESS]: (state, action) => ({
  ...state,
  userListQuerySharedTo: {
      ...state.  userListQuerySharedTo,
      response: action.payload, loading: false, hasError: false, error: {}
  },

}),
[ActionTypes.USER_LIST_QUERY_SHARED_ERROR]: (state, action) => ({
  ...state,
  userListQuerySharedTo: {
      ...state.  userListQuerySharedTo,
      response: {}, loading: false, hasError: true, error: action.payload
  },

}),
[ActionTypes.SHARE_SINGLE_QUERY]: (state) => ({
  ...state,
  shareSingleQuery: {
      ...state. shareSingleQuery,
      loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.SHARE_SINGLE_QUERY_SUCCESS]: (state, action) => ({
  ...state,
  shareSingleQuery: {
      ...state.  shareSingleQuery,
      response: action.payload, loading: false, hasError: false, error: {}
  },

}),
[ActionTypes.SHARE_SINGLE_QUERY_ERROR]: (state, action) => ({
  ...state,
  shareSingleQuery: {
      ...state.  shareSingleQuery,
      response: {}, loading: false, hasError: true, error: action.payload
  },

}),
[ActionTypes.BOOKMARK]: (state) => ({
  ...state,
  bookmark: {
      ...state. bookmark,
      loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.BOOKMARK_SUCCESS]: (state, action) => ({
  ...state,
  bookmark: {
      ...state.  bookmark,
      response: action.payload, loading: false, hasError: false, error: {}
  },

}),
[ActionTypes.BOOKMARK_ERROR]: (state, action) => ({
  ...state,
  bookmark: {
      ...state.  bookmark,
      response: {}, loading: false, hasError: true, error: action.payload
  },

}),
[ActionTypes.LIST_USERS_RECORD]: (state) => ({
  ...state,
  listUsersRecord: {
      ...state. listUsersRecord,
      loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.LIST_USERS_RECORD_SUCCESS]: (state, action) => ({
  ...state,
  listUsersRecord: {
      ...state.  listUsersRecord,
      response: action.payload, loading: false, hasError: false, error: {}
  },

}),
[ActionTypes.LIST_USERS_RECORD_ERROR]: (state, action) => ({
  ...state,
  listUsersRecord: {
      ...state.  listUsersRecord,
      response: {}, loading: false, hasError: true, error: action.payload
  },

}),
[ActionTypes.SHARE_RECORD]: (state) => ({
  ...state,
  shareRecord: {
      ...state. shareRecord,
      loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.SHARE_RECORD_SUCCESS]: (state, action) => ({
  ...state,
  shareRecord: {
      ...state. shareRecord,
      response: action.payload, loading: false, hasError: false, error: {}
  },

}),
[ActionTypes.SHARE_RECORD_ERROR]: (state, action) => ({
  ...state,
  shareRecord: {
      ...state.  shareRecord,
      response: {}, loading: false, hasError: true, error: action.payload
  },

}),
[ActionTypes.USER_RECOMENDATIONS]: (state) => ({
  ...state,
  userRecomendations: {
      ...state. userRecomendations,
      loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.USER_RECOMENDATIONS_SUCCESS]: (state, action) => ({
  ...state,
  userRecomendations: {
      ...state. userRecomendations,
      response: action.payload, loading: false, hasError: false, error: {}
  },

}),
[ActionTypes.USER_RECOMENDATIONS_ERROR]: (state, action) => ({
  ...state,
  userRecomendations: {
      ...state.  userRecomendations,
      response: {}, loading: false, hasError: true, error: action.payload
  },

}),
[ActionTypes.USER_PROFILE]: (state) => ({
  ...state,
  userProfile: {
      ...state. userProfile,
      loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.USER_PROFILE_SUCCESS]: (state, action) => ({
  ...state,
  userProfile: {
      ...state. userProfile,
      response: action.payload, loading: false, hasError: false, error: {}
  },

}),
[ActionTypes.USER_PROFILE_ERROR]: (state, action) => ({
  ...state,
  userProfile: {
      ...state.  userProfile,
      response: {}, loading: false, hasError: true, error: action.payload
  },

}),
[ActionTypes.VIEW_SIMILAR]: (state) => ({
  ...state,
  view_similar: {
      ...state. view_similar,
      loading: true, hasError: false, error: {}
  }
}),
[ActionTypes.VIEW_SIMILAR_SUCCESS]: (state, action) => ({
  ...state,
  view_similar: {
      ...state. view_similar,
      response: action.payload, loading: false, hasError: false, error: {}
  },

}),
[ActionTypes.VIEW_SIMILAR_ERROR]: (state, action) => ({
  ...state,
  view_similar: {
      ...state.  view_similar,
      response: {}, loading: false, hasError: true, error: action.payload
  },

}),
[ActionTypes.SET_FSOURCE]: (state, action) => ({
  ...state,
  financeSource: action.payload,
}),
[ActionTypes.SET_FREGION]: (state, action) => ({
  ...state,
  financeRegion: action.payload,
}),
[ActionTypes.SET_FCOUNTRY]: (state, action) => ({
  ...state,
  financeCountry: action.payload,
}),
[ActionTypes.SET_FSECTOR]: (state, action) => ({
  ...state,
  financeSector: action.payload,
}),
[ActionTypes.SET_FTOPIC]: (state, action) => ({
  ...state,
  financeTopic: action.payload,
}),
[ActionTypes.SET_FSDATE]: (state, action) => ({
  ...state,
  financeSdate: action.payload,
}),
[ActionTypes.SET_FEDATE]: (state, action) => ({
  ...state,
  financeEdate: action.payload,
}),
[ActionTypes.SET_DSOURCE]: (state, action) => ({
  ...state,
  devSource: action.payload,
}),
[ActionTypes.SET_DREGION]: (state, action) => ({
  ...state,
  devRegion: action.payload,
}),
[ActionTypes.SET_DCOUNTRY]: (state, action) => ({
  ...state,
  devCountry: action.payload,
}),
[ActionTypes.SET_DSECTOR]: (state, action) => ({
  ...state,
  devSector: action.payload,
}),
[ActionTypes.SET_DTOPIC]: (state, action) => ({
  ...state,
  devTopic: action.payload,
}),
[ActionTypes.SET_DSDATE]: (state, action) => ({
  ...state,
  devSdate: action.payload,
}),
[ActionTypes.SET_DEDATE]: (state, action) => ({
  ...state,
  devEdate: action.payload,
}),
[ActionTypes.SET_OSOURCE]: (state, action) => ({
  ...state,
  otherSource: action.payload,
}),
[ActionTypes.SET_OREGION]: (state, action) => ({
  ...state,
  otherRegion: action.payload,
}),
[ActionTypes.SET_OCOUNTRY]: (state, action) => ({
  ...state,
  otherCountry: action.payload,
}),
[ActionTypes.SET_OSECTOR]: (state, action) => ({
  ...state,
  otherSector: action.payload,
}),
[ActionTypes.SET_OTOPIC]: (state, action) => ({
  ...state,
  otherTopic: action.payload,
}),
[ActionTypes.SET_OSDATE]: (state, action) => ({
  ...state,
  otherSdate: action.payload,
}),
[ActionTypes.SET_OEDATE]: (state, action) => ({
  ...state,
  otherEdate: action.payload,
}),

[ActionTypes.SET_DATASOURCE]: (state, action) => ({
  ...state,
  dataSource: action.payload,
}),
[ActionTypes.SET_DATAREGION]: (state, action) => ({
  ...state,
  dataRegion: action.payload,
}),
[ActionTypes.SET_DATACOUNTRY]: (state, action) => ({
  ...state,
  dataCountry: action.payload,
}),
[ActionTypes.SET_DATASECTOR]: (state, action) => ({
  ...state,
  dataSector: action.payload,
}),
[ActionTypes.SET_DATATOPIC]: (state, action) => ({
  ...state,
  dataTopic: action.payload,
}),
[ActionTypes.SET_DATARESOURCE]: (state, action) => ({
  ...state,
  dataResource: action.payload,
}),
[ActionTypes.SET_DATASDATE]: (state, action) => ({
  ...state,
  dataSdate: action.payload,
}),
[ActionTypes.SET_DATAEDATE]: (state, action) => ({
  ...state,
  dataEdate: action.payload,
}),
[ActionTypes.SET_ECONSOURCE]: (state, action) => ({
  ...state,
  econSource: action.payload,
}),

[ActionTypes.SET_ECONREGION]: (state, action) => ({
  ...state,
  econRegion: action.payload,
}),
[ActionTypes.SET_ECONCOUNTRY]: (state, action) => ({
  ...state,
  econCountry: action.payload,
}),
[ActionTypes.SET_ECONSECTOR]: (state, action) => ({
  ...state,
  econSector: action.payload,
}),
[ActionTypes.SET_ECONTOPIC]: (state, action) => ({
  ...state,
  econTopic: action.payload,
}),
[ActionTypes.SET_ECONSDATE]: (state, action) => ({
  ...state,
  econSdate: action.payload,
}),
[ActionTypes.SET_ECONEDATE]: (state, action) => ({
  ...state,
  econEdate: action.payload,
}),
[ActionTypes.SET_MULSOURCE]: (state, action) => ({
  ...state,
  mulSource: action.payload,
}),

[ActionTypes.SET_MULREGION]: (state, action) => ({
  ...state,
  mulRegion: action.payload,
}),
[ActionTypes.SET_MULCOUNTRY]: (state, action) => ({
  ...state,
  mulCountry: action.payload,
}),
[ActionTypes.SET_MULSECTOR]: (state, action) => ({
  ...state,
  mulSector: action.payload,
}),
[ActionTypes.SET_MULTOPIC]: (state, action) => ({
  ...state,
  mulTopic: action.payload,
}),
[ActionTypes.SET_MULSDATE]: (state, action) => ({
  ...state,
  mulSdate: action.payload,
}),
[ActionTypes.SET_MULEDATE]: (state, action) => ({
  ...state,
  mulEdate: action.payload,
}),
[ActionTypes.SET_EVALSOURCE]: (state, action) => ({
  ...state,
  evalSource: action.payload,
}),

[ActionTypes.SET_EVALREGION]: (state, action) => ({
  ...state,
  evalRegion: action.payload,
}),
[ActionTypes.SET_EVALCOUNTRY]: (state, action) => ({
  ...state,
  evalCountry: action.payload,
}),
[ActionTypes.SET_EVALSECTOR]: (state, action) => ({
  ...state,
  evalSector: action.payload,
}),
[ActionTypes.SET_EVALTOPIC]: (state, action) => ({
  ...state,
  evalTopic: action.payload,
}),
[ActionTypes.SET_EVALSDATE]: (state, action) => ({
  ...state,
  evalSdate: action.payload,
}),
[ActionTypes.SET_EVALEDATE]: (state, action) => ({
  ...state,
  evalEdate: action.payload,
}),
[ActionTypes.SET_VIEW_SIMILAR_ID]: (state, action) => ({
  ...state,
  viewSimilarId: action.payload,
}),
[ActionTypes.SET_CURRENT_CATEGORY]: (state, action) => ({
  ...state,
  currentCategory: action.payload,
}),
[ActionTypes.SET_FILE]: (state, action) => ({
  ...state,
  file: action.payload,
}),

[ActionTypes.SET_SEARCH_STATE]: (state, action) => ({
  ...state,
  search_state: action.payload,
}),

[ActionTypes.SET_VIEW_ALL]: (state, action) => ({
  ...state,
  view_all: action.payload,
}),

[ActionTypes.SET_RESULT_TITLE]: (state, action) => ({
  ...state,
  result_title: action.payload,
}),

[ActionTypes.SET_FILE_PATH]: (state, action) => ({
  ...state,
  file_path: action.payload,
}),

[ActionTypes.SET_CURRENT_TAB]: (state, action) => ({
  ...state,
  current_tab: action.payload,
}),
[ActionTypes.SET_FILE_VAR]: (state, action) => ({
  ...state,
  file_var: action.payload,
}),
 
},initialState);

export default reducer;





