import React, { useEffect, useRef, useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import ClipIcon from "../../../../../../assets/images/icons/upload-clip-icon.png";
import dropZoneImg from "../../../../../../assets/images/icons/upload-icon.png";
import UploadedTick from "../../../../../../assets/images/icons/dpzn-tick-icon.png";
import UploadedRemoveIcon from "../../../../../../assets/images/icons/binIcon2.svg";
import UploadClose from "../../../../../../assets/images/icons/upload-close-icon.svg";
import ScrollArea from "react-scrollbar";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { _chatCancelUpload, _chatCompleteUpload, _chatDocUpload, _chatremoveDoc } from '../../../../../../shared/httpservice/apis';
import { addChatResponse, setDocurl, setNewChat, setPersonality, setDocSubmitLoading, setUploadedDocs , setUploadDocLoading} from '../../../../services';
import { toast } from 'react-toastify';
import { personalities } from '../../../../chatConfig';
import axios from 'axios';

const FileUploadModal = ({ show, handleClose }) => {
    const CancelToken = axios.CancelToken;
    const cancelUploadRef = useRef(null);

    const uploadeddocs = useSelector(state => state.chat.uploadedDocs);
    const uploadDocLoading = useSelector(state => state.chat.uploadDocLoading);
    const docSubmitLoading = useSelector(state => state.chat.docSubmitLoading);

    const tokenSso = useSelector(
        (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token,
        shallowEqual
    );
    const token = useSelector(
    (store) => store?.auth?.signin?.response?.data?.data?.token,
    shallowEqual
    );
    const dispatch = useDispatch()
    const [uploadProgress, setUploadProgress] = useState({});
    const [fileStatus , setFileStatus] = useState({});
    const [error , setError ] = useState('');
    const uniquekey = useRef(Date.now());

    useEffect(() => {
        dispatch(setUploadDocLoading(false));
        dispatch(setDocSubmitLoading(false));
    }, [])


    const onDrop = async (acceptedFiles, rejectedFiles) => {
        if(rejectedFiles?.length > 0 ){
            const message = rejectedFiles?.map((item) => {
                return item?.file?.name
            })
            toast.error("Unsupported File - "+ message.join(', '),{hideProgressBar: true})   
        }
        const newFiles = acceptedFiles?.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                progress: 0,
                isUploaded: false
            })
        );
        
  
        dispatch(setUploadDocLoading(true));
        setError('')
        setFileStatus({
            total:newFiles.length,
            currentFile:1
        })  
         
            for (let index = 0; index < newFiles.length; index++) {
                try { 
                    setFileStatus({
                        total:newFiles.length,
                        currentFile:index + 1
                    }) 
            
                    const formData = new FormData();
                    formData.append('documents', newFiles[index]);
                    formData.append('unique_key', uniquekey.current);
                    cancelUploadRef.current = CancelToken.source();
                    const res = await _chatDocUpload(formData, token || tokenSso,newFiles[index], progressCallback, cancelUploadRef)
                    dispatch(setUploadedDocs(res?.data?.data?.files));
                    
                } catch (err) {
                    console.log(err)
                    setError({
                        message:err?.response?.data?.message,
                        filename: newFiles[index]['name']
                    })
                } 
           }
       
        dispatch(setUploadDocLoading(false));
       
    };

    const progressCallback = (item) => {
        const progress = {}
        progress[item.filename] = item.value;
        setUploadProgress(progress);
    }


    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'application/pdf': ['.pdf'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
            'text/plain': ['.txt']
        },
        multiple: true
    });

    const convertToXml = async() => {
        dispatch(setDocSubmitLoading(true));
        try {
            const res = await _chatCompleteUpload( 
                {
                    "unique_key": uniquekey.current,
                    "upload": 'True',
                },
                token || tokenSso)
            dispatch(setDocurl(res?.data?.data?.xml_file_path || ''))
            toast.success("Upload successful!",{hideProgressBar: true})   
            dispatch(setPersonality(personalities.find((item) => item.value === "document")));
            dispatch(setNewChat(false));
            dispatch(
                addChatResponse({
                  files:uploadeddocs,
                  sender: "user",
                })
              );
            handleClose();  
   
        } catch (err) {
            console.log(err)
        } finally {
            dispatch(setDocSubmitLoading(false));
        }
      }

    const removeDoc = async (filename) => {
        try {
            await _chatremoveDoc({file_name: filename, unique_key:uniquekey.current}, token || tokenSso)
            const docs = uploadeddocs.filter(doc => doc.name !== filename);
            dispatch(setUploadedDocs(docs));
        } catch (error) {
            console.log(error);
        }
      
    }

    const handlePopupClose = () => {
        try {
            _chatCancelUpload({cancel_upload:'True', unique_key:uniquekey.current}, token || tokenSso);
            dispatch(setUploadedDocs([]));
            handleClose();

        } catch (error) {
            console.log(error);
        }
       
    }



    const cancelCurrentUpload = () => {
        cancelUploadRef.current?.cancel();
    }

    const closeErrorMessage = () => {
        setError('')
    }



    return (
        <>
            <Modal show={show} onHide={handlePopupClose} centered className='common-modal dropzone-modal' size="lg">
                <Modal.Header closeButton>
                    <Modal.Title className='text-center flex-1'></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h2 className='dpzone-title'>Upload Files</h2>
                    <div {...getRootProps({ className: 'dropzone' })} className='dropzone-box'>
                        <input {...getInputProps()} />
                        <div className='dropzone-icon'>
                            <img src={dropZoneImg} alt="upload" />
                        </div>
                        <h3>Drag & drop files or <span>Browse</span></h3>
                        <p>Supported formats: PDF, DOCX, DOC, PPTX, TXT</p>
                    </div>
                  
                       {uploadDocLoading  && Object.keys(uploadProgress)?.length > 0 ? (
                        <div className='dpzone-upload-status-sec'>
                        <h3 className="dpzone-sec-title">Uploading {fileStatus.currentFile} / {fileStatus.total} files</h3>
                        <ScrollArea
                            speed={0.8}
                            className="dpzn-upload-scroll"
                            contentClassName="content"
                            horizontal={false}
                        >
                            {Object.keys(uploadProgress)?.map((item) => 
                                { return  uploadProgress[item] ? (
                                    <div key={item} className='uploading-progress-box uploading-status-show-outer'>
                                    <div className="d-flex uploaded-item-name-box">
                                        <div className='uploaded-item-nameshow p-l-0'>{item}</div>
                                        <div className='uploaded-item-delete' onClick={cancelCurrentUpload}><img src={UploadClose} alt="delete" /></div>
                                    </div>
                                    <div className='progress-bar'>
                                        <div className='progress-bar-show'>
                                            <div style={{
                                                width: `${uploadProgress[item] || 0}%`,
                                                height: '3px',
                                                background: 'rgba(26, 115, 232, 1)',
                                                borderRadius: '20px'
                                            }} />
                                        </div>
                                    </div>
                                    {uploadProgress[item] < 100 && (
                                        <p className='upload-error-text'>Uploading... {uploadProgress[item]}%</p>
                                    )}
                                    
                                </div>
                                ) : ''
                            
                            } 
                               
                            )}
                        </ScrollArea>
                        
                                          
                    </div>
                    ): ''
                    }
                    {error && (<div>
                            <div  className='uploading-progress-box uploading-status-show-outer'>
                                    <div className="d-flex uploaded-item-name-box">
                                        <div className='uploaded-item-nameshow p-l-0'>{error.filename}</div>
                                        <div className='uploaded-item-delete' onClick={closeErrorMessage}><img src={UploadClose} alt="delete" /></div>
                                    </div>
                                    <div className='progress-bar'>
                                        <div className='progress-bar-show'>
                                            <div style={{
                                                width: `100%`,
                                                height: '3px',
                                                background: 'red',
                                                borderRadius: '20px'
                                            }} />
                                        </div>
                                    </div>
                                </div>
                               
                                <p style={{color: 'red'}}>{error.message}</p>
                        </div>)} 
                      
                   
                   { uploadeddocs?.length > 0 ? (
                    <div className='dpzone-upload-status-sec'>
                    <h3 className="dpzone-sec-title">Uploaded</h3>
                    <div className='uploading-progress-box'>
                        <ScrollArea
                            speed={0.8}
                            className="dpzn-upload-scroll"
                            contentClassName="content"
                            horizontal={false}
                        >
                            {uploadeddocs?.map((item, index) => (
                                <div key={index} className="d-flex uploaded-item-name-box">
                                    <div className='uploaded-item-tickicon'><img src={UploadedTick} alt="uploaded" /></div>
                                    <div className='uploaded-item-nameshow'>{item?.name}</div>
                                    {/* <span>Content Length:{item?.content_length}</span> */}
                                    <div className='uploaded-item-delete' onClick={() => removeDoc(item?.name)}><img src={UploadedRemoveIcon} alt="delete" /></div>
                                </div>
                            ))}
                        </ScrollArea>
                    </div>
                   </div>
                   ) : ''}                     
                    
                </Modal.Body>
                <div className='btn-wrap dropzone-modal-footer'>
                    <Button className='btn btn-primary w-100' onClick={convertToXml} disabled={uploadeddocs?.length === 0 || uploadDocLoading}>
                       {docSubmitLoading ? <Spinner animation="border" size="sm" /> : 'Submit Files'} 
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default FileUploadModal;
