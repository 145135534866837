import React, { useState,useEffect,useCallback } from 'react';
import DataTable from 'react-data-table-component';
import Pagination from 'react-bootstrap/Pagination';
import { tableData } from './dummyTableData';
import deleteIcon from '../../../../assets/images/icons/delete-icon.svg';
import ShareIcon from '../../../../assets/images/icons/share-icon.png';
import SharedIcon from '../../../../assets/images/icons/shared.svg';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Modal } from 'react-bootstrap';
import CustomPagination from '../../../../shared/pagination/pagination';
import ManageAccess from '../../../../shared/manageaccesspopup/manageaccesspopup';
import StatusPop from './../../../../shared/statuspopup'
import PrivatePopup from '../../../../shared/privatepopup';
import MultipleQueryPopup from './../../../../shared/multipleQueryPopup'
import { useDispatch,useSelector,shallowEqual } from 'react-redux';
import {savedQueriesList,removeMultipleQuery} from './../../../profile/services'
import {UserListSaveQuery,usersListQueryShared,setSearchKey,setCurrentCategory,setFileKey,setResultTitle,setFileVar} from './../../../results/services'
import {_removeSingleQuery,_removeSingleSavedQuery} from './../../../../shared/httpservice/apis'

import TablePaginate from './../../../../shared/tablePaginate'
import {useNavigate} from 'react-router-dom'
import Paginate from '../../../../shared/dataTablePagination';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    setFCountryKey,
    setFRegionKey,
    setFSectorKey,
    setFTopicKey,
    setFSourceKey,
    setFStartDateKey,
    setFEndDateKey,
    setDCountryKey, setDRegionKey, setDSectorKey, setDTopicKey, setDSourceKey,setDStartDateKey,setDEndDateKey,
    setOCountryKey, setORegionKey, setOSectorKey, setOTopicKey, setOSourceKey, setOStartDateKey, setOEndDateKey,
    setDataCountryKey, setDataRegionKey, setDataSectorKey, setDataTopicKey, setDataSourceKey, setDataStartDateKey, setDataEndDateKey, setDataResourceKey,
    setMulCountryKey,setMulRegionKey,setMulSectorKey,setMulTopicKey,setMulSourceKey,setMulStartDateKey,setMulEndDateKey,
    setEvalCountryKey, setEvalRegionKey, setEvalSectorKey, setEvalTopicKey, setEvalSourceKey,setEvalStartDateKey,setEvalEndDateKey,
    setEconCountryKey,
    setEconRegionKey,
    setEconSectorKey,
    setEconTopicKey,
    setEconSourceKey,
    setEconStartDateKey,
    setEconEndDateKey,
    setCountryKey,
    setRegionKey,
    setSectorKey,
    setTopicKey,
    setSourceKey,
    setCurrentTabFn,
  } from "./../../../results/services";
import {
    setFinanceOrgFilter,
    setFinanceOrgData,
    setFinanceOrgHandler,
    setFinanceCountryFilter,
    setFinanceCountryData,
    setFinanceCountryHandler,
    setFinanceRegionFilter,
    setFinanceRegionData,
    setFinanceRegionHandler,
    setFinanceSectorData,
    setFinanceSectorFilter,
    setFinanceSectorHandler,
    setFinanceTopicFilter,
    setFinanceTopicData,
    setFinanceTopicHandler,
    setHomeFlagFunction,
    setFinanceSearchCountry,
    setQueryFlag,
    setFinanceStartDate,
    setFinanceEndDate
  } from "../../../results/filterServices";
  import {
    setDevOrgFilter,
    setDevOrgData,
    setDevOrgHandler,
    setDevCountryFilter,
    setDevCountryData,
    setDevCountryHandler,
    setDevRegionFilter,
    setDevRegionData,
    setDevRegionHandler,
    setDevSectorData,
    setDevSectorFilter,
    setDevSectorHandler,
    setDevTopicFilter,
    setDevTopicData,
    setDevTopicHandler,
    setDevSearchCountry,
    setDateTriggerDev,
    setDevStartDate,
    setDevEndDate
  } from "../../../results/components/devpubsresults/devpubsfiltersevices";
  import {
    setOtherOrgFilter,
    setOtherOrgData,
    setOtherOrgHandler,
    setOtherCountryFilter,
    setOtherCountryData,
    setOtherCountryHandler,
    setOtherRegionFilter,
    setOtherRegionData,
    setOtherRegionHandler,
    setOtherSectorData,
    setOtherSectorFilter,
    setOtherSectorHandler,
    setOtherTopicFilter,
    setOtherTopicData,
    setOtherTopicHandler,
    setOtherSearchCountry,
    setOtherStartDate,
    setOtherEndDate,
  } from "../../../results/components/otherpubsresults/otherpubsfilterservices";
  import {
    setDataOrgFilter,
    setDataOrgData,
    setDataOrgHandler,
    setDataCountryFilter,
    setDataCountryData,
    setDataCountryHandler,
    setDataRegionFilter,
    setDataRegionData,
    setDataRegionHandler,
    setDataSectorData,
    setDataSectorFilter,
    setDataSectorHandler,
    setDataTopicFilter,
    setDataTopicData,
    setDataTopicHandler,
    setDataResourceFilter,
    setDataResourceData,
    setDataResourceHandler,
    setDataSearchCountry
} from "../../../results/components/dataresults/datafilterservices";
import {
    setMulOrgFilter,
    setMulOrgData,
    setMulOrgHandler,
    setMulCountryFilter,
    setMulCountryData,
    setMulCountryHandler,
    setMulRegionFilter,
    setMulRegionData,
    setMulRegionHandler,
    setMulSectorData,
    setMulSectorFilter,
    setMulSectorHandler,
    setMulTopicFilter,
    setMulTopicData,
    setMulTopicHandler,
    setMulSearchCountry,
    setDateTriggerMul,
    setMulStartDate,
    setMulEndDate,
  } from "../../../results/components/multimediaresults/multimediafilterservices";
  import {
    setEvalOrgFilter,
    setEvalOrgData,
    setEvalOrgHandler,
    setEvalCountryFilter,
    setEvalCountryData,
    setEvalCountryHandler,
    setEvalRegionFilter,
    setEvalRegionData,
    setEvalRegionHandler,
    setEvalSectorData,
    setEvalSectorFilter,
    setEvalSectorHandler,
    setEvalTopicFilter,
    setEvalTopicData,
    setEvalTopicHandler,
    setEvalSearchCountry,
    setEvalStartDate,
    setEvalEndDate
    
  } from "../../../results/components/evaluationsresults/evaluationfilterservices";
  import {
    setEconOrgFilter,
    setEconOrgData,
    setEconOrgHandler,
    setEconCountryFilter,
    setEconCountryData,
    setEconCountryHandler,
    setEconRegionFilter,
    setEconRegionData,
    setEconRegionHandler,
    setEconSectorData,
    setEconSectorFilter,
    setEconSectorHandler,
    setEconTopicFilter,
    setEconTopicData,
    setEconTopicHandler,
    setEconPopupFunction,
    setEconIntialCountry,
    setEconIgnoreQuery,
    setDateTriggerEcon,
    setEconStartDate,
    setEconEndDate,
  } from "../../../results/components/econpubsresults/econfilterservices";
  import {
    setExpertsOrgFilter,
    setExpertsOrgData,
    setExpertsOrgHandler,
    setExpertsCountryFilter,
    setExpertsCountryData,
    setExpertsCountryHandler,
    setExpertsRegionFilter,
    setExpertsRegionData,
    setExpertsRegionHandler,
    setExpertsSectorData,
    setExpertsSectorFilter,
    setExpertsSectorHandler,
    setExpertsTopicFilter,
    setExpertsTopicData,
    setExpertsTopicHandler,
    setExpertsResourceFilter,
    setExpertsResourceData,
    setExpertsResourceHandler,
    setExpertsSearchCountry,
    setExpertsIgnoreQuery
    
  } from "../../../results/components/expertsresults/expertsfilterservices";

function MyQueries() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [bulkShow,setBulkShow] = useState(false)
    const handleCloses =()=> setBulkShow(false)
    const handleShows = ()=>setBulkShow(true)

    const [Countryshows, CountrysetShow] = useState(false);
    const CountryhandleCloses = () => CountrysetShow(false);
    const CountryhandleShow = () => CountrysetShow(true);

    const [AccessShow, AccessSetShow] = useState(false);
    const AccesshandleClose = () => AccessSetShow(false);
    const AccesshandleShow = () => AccessSetShow(true);

    const [AccessShows, AccessSetShows] = useState(false);
    const AccesshandleCloses = () => AccessSetShows(false);
    const AccesshandleShows = () => AccessSetShows(true);

    const [shareState,setShareState] = useState(false)


    const token = useSelector((store)=>store?.auth?.signin?.response?.data?.data?.token,shallowEqual)
    const tokenSso = useSelector(
        (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token,shallowEqual
      );
    const state = useSelector((state)=>state?.profile?.savedQueryList?.response?.data?.data?.results)  
    const count = useSelector((state)=>state?.profile?.savedQueryList?.response?.data?.data?.count)

    const loading = useSelector((state)=>state?.profile?.savedQueryList?.loading)  
    const states=useSelector((state)=>state?.profile?.savedQueryList) 


    const [category,setCategory] = useState()

    const [qterm,setQterm] = useState()
    const [qfile,setQFile] = useState()

    const [title,setTitle] = useState()
    const [cat,setCat] = useState()
    const [qt,setQt] = useState()
    const [qf,setQf] = useState()
    const [clear,setClear] = useState(false)
    const [queryParam,setQueryParam] = useState()


    const [selected,setSelected] = useState([])


    const [page,setPage] = useState(1)
    
    const [offset,setOffset] = useState(0)

    const handleChangePage = useCallback((page)=>{
        setPage(page)
    },[])
    const handleOffsetChange =(offset)=>{
        setOffset(offset)
    }

    const [skip, setSkip] = useState(0);
 

  const [currentPage,setCurrentPage] = useState(0)


  const handlePage =(data)=>{
    setOffset((data?.selected)*10)
    setCurrentPage(data?.selected)
  }
  //fn that returns the list of saved queries.
    const savedQueries =()=>{
        if(token!==undefined){
        dispatch(savedQueriesList(token,"",offset)).then(()=>{
            setClear(false)
        })
        }else if(tokenSso!==undefined){
            dispatch(savedQueriesList(tokenSso,"",offset)).then(()=>{
                setClear(false)
            })
        }
    }

    const [queryId,setQuerId] = useState()


    const removeSingleQuery =()=>{
        if(token!==undefined){
            return _removeSingleQuery(queryId,token).then(()=>{
                dispatch(savedQueries())
            })
        }else if(tokenSso!==undefined){
            return _removeSingleQuery(queryId,tokenSso).then(()=>{
                dispatch(savedQueries())
            })
        }
    }

    //fn to remove a single saved query from the list
    const removeSingleSavedQuery =()=>{
        if(token!==undefined){
            return _removeSingleSavedQuery(queryId,token).then(()=>{
                dispatch(savedQueries())
            })
        }else if(tokenSso!==undefined){
            return _removeSingleSavedQuery(queryId,tokenSso).then(()=>{
                dispatch(savedQueries())
            })
        }
    }

    useEffect(()=>{
        savedQueries()
    },[offset])

    const [query,setQuery] = useState()

    const querySharedWith = (getParams,category)=>{
        const data = {
            "query" : getParams,
            "category" : category
        }
        if(token!==undefined){
            dispatch(usersListQueryShared(data,token))
        }else if(tokenSso!==undefined){
            dispatch(usersListQueryShared(data,tokenSso))
        }
       }
       const [search,setSearch] = useState("")
    //    console.log(search,"search");
    const onSearchValueChange = (e) => {
        const value = e.target.value
        setSearch(value)

        if(value){
            if(token!==undefined){
                dispatch(savedQueriesList(token,value,offset))
            }
            else if(tokenSso!==undefined){
                dispatch(savedQueriesList(tokenSso,value,offset))
            }
           
        }else{
            if(token!==undefined){
                dispatch(savedQueriesList(token,"",offset))
            }
            else if(tokenSso!==undefined){
                dispatch(savedQueriesList(tokenSso,"",offset))
            }
        }
      };

      useEffect(()=>{
        setOffset(0)
        setCurrentPage(0)
      },[search])

    useEffect(()=>{
        if(state){
            setData(state)
        }
    },[state])

    //fn to list the organizational users
    const userList =()=>{
        if(token!==undefined){
            dispatch(UserListSaveQuery(token,"","","","","False"))
        }else if(tokenSso!==undefined){
            dispatch(UserListSaveQuery(tokenSso,"","","","","False"))
        }
       }

    //fns to clear all the applied filters   
     const clearDevFilters =()=>{
        dispatch(setDSourceKey(""))
            dispatch(setDCountryKey(""))
            dispatch(setDRegionKey(""))
            dispatch(setDTopicKey(""))
            dispatch(setDSectorKey(""))
            dispatch(setDStartDateKey(""))
            dispatch(setDEndDateKey(""))
            dispatch(setDevCountryData([]));
            dispatch(setDevOrgData([]));
            dispatch(setDevCountryHandler([]));
            dispatch(setDevOrgHandler([]));
            dispatch(setDevRegionData([]));
            dispatch(setDevRegionHandler([]));
            dispatch(setDevSectorHandler([]));
            dispatch(setDevSectorData([]));
            dispatch(setDevTopicData([]));
            dispatch(setDevTopicHandler([]));
            dispatch(setDevCountryFilter([]))
            dispatch(setDevOrgFilter([]))
            dispatch(setDevRegionFilter([]))
            dispatch(setDevSectorFilter([]))
            dispatch(setDevTopicFilter([]))
            dispatch(setDevSearchCountry([]))
            dispatch(setDateTriggerDev(true))
            dispatch(setDevStartDate(null))
            dispatch(setDevEndDate(null))
      }
    
      const clearDataFilters =()=>{
        dispatch(setDataSourceKey(""))
        dispatch(setDataCountryKey(""))
        dispatch(setDataRegionKey(""))
        dispatch(setDataTopicKey(""))
        dispatch(setDataSectorKey(""))
        dispatch(setDataResourceKey(""))
            dispatch(setDataOrgData([]));
            dispatch(setDataCountryHandler([]));
            dispatch(setDataOrgHandler([]));
            dispatch(setDataRegionData([]));
            dispatch(setDataRegionHandler([]));
            dispatch(setDataSectorHandler([]));
            dispatch(setDataSectorData([]));
            dispatch(setDataTopicData([]));
            dispatch(setDataTopicHandler([]));
            dispatch(setDataCountryFilter([]))
            dispatch(setDataOrgFilter([]))
            dispatch(setDataRegionFilter([]))
            dispatch(setDataSectorFilter([]))
            dispatch(setDataTopicFilter([]))
            dispatch(setDataCountryData([]))
            dispatch(setDataResourceFilter([]))
            dispatch(setDataResourceData([]))
            dispatch(setDataResourceHandler([]))
            dispatch(setDataSearchCountry([]))
      }
    
      const clearEconFilters =()=>{
            dispatch(setEconSourceKey(""))
            dispatch(setEconCountryKey(""))
            dispatch(setEconRegionKey(""))
            dispatch(setEconTopicKey(""))
            dispatch(setEconSectorKey(""))
            dispatch(setEconStartDateKey(""))
            dispatch(setEconEndDateKey(""))
            dispatch(setEconCountryData([]));
            dispatch(setEconOrgData([]));
            dispatch(setEconCountryHandler([]));
            dispatch(setEconOrgHandler([]));
            dispatch(setEconRegionData([]));
            dispatch(setEconRegionHandler([]));
            dispatch(setEconSectorHandler([]));
            dispatch(setEconSectorData([]));
            dispatch(setEconTopicData([]));
            dispatch(setEconTopicHandler([]));
            dispatch(setEconCountryFilter([]))
            dispatch(setEconOrgFilter([]))
            dispatch(setEconRegionFilter([]))
            dispatch(setEconSectorFilter([]))
            dispatch(setEconTopicFilter([]))
            dispatch(setEconIntialCountry([]))
            dispatch(setEconIgnoreQuery(false))
            dispatch(setDateTriggerEcon(true))
            dispatch(setEconStartDate(null))
            dispatch(setEconEndDate(null))
      }
    
      const clearEvalFilters =()=>{
            dispatch(setEvalSourceKey(""))
            dispatch(setEvalCountryKey(""))
            dispatch(setEvalRegionKey(""))
            dispatch(setEvalTopicKey(""))
            dispatch(setEvalSectorKey(""))
            dispatch(setEvalStartDateKey(""))
            dispatch(setEvalEndDateKey(""))
            dispatch(setEvalCountryData([]));
            dispatch(setEvalOrgData([]));
            dispatch(setEvalCountryHandler([]));
            dispatch(setEvalOrgHandler([]));
            dispatch(setEvalRegionData([]));
            dispatch(setEvalRegionHandler([]));
            dispatch(setEvalSectorHandler([]));
            dispatch(setEvalSectorData([]));
            dispatch(setEvalTopicData([]));
            dispatch(setEvalTopicHandler([]));
            dispatch(setEvalCountryFilter([]))
            dispatch(setEvalOrgFilter([]))
            dispatch(setEvalRegionFilter([]))
            dispatch(setEvalSectorFilter([]))
            dispatch(setEvalTopicFilter([]))
            dispatch(setEvalSearchCountry([]))
            dispatch(setDateTriggerEcon(true))
            dispatch(setEvalStartDate(null))
            dispatch(setEvalEndDate(null))
      }
      
      const clearMulFilters =()=>{
            dispatch(setMulSourceKey(""))
            dispatch(setMulCountryKey(""))
            dispatch(setMulRegionKey(""))
            dispatch(setMulTopicKey(""))
            dispatch(setMulSectorKey(""))
            dispatch(setMulStartDateKey(""))
            dispatch(setMulEndDateKey(""))
            dispatch(setMulCountryData([]));
            dispatch(setMulOrgData([]));
            dispatch(setMulCountryHandler([]));
            dispatch(setMulOrgHandler([]));
            dispatch(setMulRegionData([]));
            dispatch(setMulRegionHandler([]));
            dispatch(setMulSectorHandler([]));
            dispatch(setMulSectorData([]));
            dispatch(setMulTopicData([]));
            dispatch(setMulTopicHandler([]));
            dispatch(setMulCountryFilter([]))
            dispatch(setMulOrgFilter([]))
            dispatch(setMulRegionFilter([]))
            dispatch(setMulSectorFilter([]))
            dispatch(setMulTopicFilter([]))
            dispatch(setMulSearchCountry([]))
            dispatch(setDateTriggerMul(true))
            dispatch(setMulStartDate(null))
            dispatch(setMulEndDate(null))
      }
    
      const clearOtherFilters =()=>{
        dispatch(setOSourceKey(""))
        dispatch(setOCountryKey(""))
        dispatch(setORegionKey(""))
        dispatch(setOTopicKey(""))
        dispatch(setOSectorKey(""))
        dispatch(setOStartDateKey(""))
        dispatch(setOEndDateKey(""))
        dispatch(setOtherCountryData([]));
        dispatch(setOtherOrgData([]));
        dispatch(setOtherCountryHandler([]));
        dispatch(setOtherOrgHandler([]));
        dispatch(setOtherRegionData([]));
        dispatch(setOtherRegionHandler([]));
        dispatch(setOtherSectorHandler([]));
        dispatch(setOtherSectorData([]));
        dispatch(setOtherTopicData([]));
        dispatch(setOtherTopicHandler([]));
        dispatch(setOtherCountryFilter([]))
        dispatch(setOtherOrgFilter([]))
        dispatch(setOtherRegionFilter([]))
        dispatch(setOtherSectorFilter([]))
        dispatch(setOtherTopicFilter([]))
        dispatch(setOtherSearchCountry([]))
        dispatch(setDateTriggerMul(true))
        dispatch(setOtherStartDate(null))
        dispatch(setOtherEndDate(null))
      }
    
      const clearFinanceFilters = ()=>{
        dispatch(setFSourceKey(""))
        dispatch(setFCountryKey(""))
        dispatch(setFRegionKey(""))
        dispatch(setFTopicKey(""))
        dispatch(setFSectorKey(""))
        dispatch(setFStartDateKey(""))
        dispatch(setFEndDateKey(""))
        dispatch(setFinanceCountryData([]));
        dispatch(setFinanceOrgData([]));
        dispatch(setFinanceCountryHandler([]));
        dispatch(setFinanceOrgHandler([]));
        dispatch(setFinanceRegionData([]));
        dispatch(setFinanceRegionHandler([]));
        dispatch(setFinanceSectorHandler([]));
        dispatch(setFinanceSectorData([]));
        dispatch(setFinanceTopicData([]));
        dispatch(setFinanceTopicHandler([]));
        dispatch(setFinanceCountryFilter([]))
        dispatch(setFinanceOrgFilter([]))
        dispatch(setFinanceRegionFilter([]))
        dispatch(setFinanceSectorFilter([]))
        dispatch(setFinanceTopicFilter([]))
        dispatch(setFinanceSearchCountry([]));
        dispatch(setFinanceStartDate(null))
        dispatch(setFinanceEndDate(null))
      }
    
      const clearExpertsFilters =()=>{
        dispatch(setSourceKey(""))
        dispatch(setCountryKey(""))
        dispatch(setRegionKey(""))
        dispatch(setTopicKey(""))
        dispatch(setSectorKey(""))
        dispatch(setExpertsIgnoreQuery(false))
        dispatch(setExpertsOrgFilter([]));
        dispatch(setExpertsOrgData([]));
        dispatch(setExpertsOrgHandler([]));
        dispatch(setExpertsCountryFilter([]));
        dispatch(setExpertsCountryData([]));
        dispatch(setExpertsCountryHandler([]));
        dispatch(setExpertsRegionFilter([]));
        dispatch(setExpertsRegionData([]));
        dispatch(setExpertsRegionHandler([]));
        dispatch(setExpertsSectorFilter([]));
        dispatch(setExpertsSectorData([]));
        dispatch(setExpertsSectorHandler([]));
        dispatch(setExpertsTopicFilter([]));
        dispatch(setExpertsTopicData([]));
        dispatch(setExpertsTopicHandler([]));
        
        dispatch(setExpertsSearchCountry([]));
      }
    
      //fn that tells if a query is from a doc upload or a keyword search
       const fileOrSearch =(row)=>{
            if(row?.get_parameters?.qterm){
                dispatch(setSearchKey(row?.get_parameters?.qterm))
                dispatch(setFileKey(""))
                dispatch(setCurrentTabFn("text"))
                dispatch(setResultTitle(row?.title))
            }else if(row?.get_parameters?.qfile){
                dispatch(setCurrentTabFn("document"))
                dispatch(setFileKey(row?.get_parameters?.qfile))
                dispatch(setSearchKey(""))
                dispatch(setResultTitle(row?.title))
                }
       }

       //fn that sets the category filters
       const categoryFilter =(row)=>{
        if(row?.get_parameters?.category==="financing"){
            dispatch(setFRegionKey(row?.filter_parameters?.financefilterregion))
            dispatch(setFinanceRegionFilter(row?.filter_parameters?.financefilterarrayregion))
            dispatch(setFinanceRegionData(row?.filter_parameters?.financedataregion))
            dispatch(setFinanceRegionHandler(row?.filter_parameters?.financehandlerregion))

            dispatch(setFSourceKey(row?.filter_parameters?.financefilterorg))
            dispatch(setFinanceOrgFilter(row?.filter_parameters?.financefilterarrayorg))
            dispatch(setFinanceOrgData(row?.filter_parameters?.financedataorg))
            dispatch(setFinanceOrgHandler(row?.filter_parameters?.financehandlerorg))

            dispatch(setFCountryKey(row?.filter_parameters?.financefiltercountry))
            dispatch(setFinanceCountryFilter(row?.filter_parameters?.financefilterarraycountry))
            dispatch(setFinanceCountryData(row?.filter_parameters?.financedatacountry))
            dispatch(setFinanceCountryHandler(row?.filter_parameters?.financehandlercountry))

            dispatch(setFTopicKey(row?.filter_parameters?.financefiltertopic))
            dispatch(setFinanceTopicFilter(row?.filter_parameters?.financefilterarraytopic))
            dispatch(setFinanceTopicData(row?.filter_parameters?.financedatatopic))
            dispatch(setFinanceTopicHandler(row?.filter_parameters?.financehandlertopic))

            dispatch(setFSectorKey(row?.filter_parameters?.financefiltersector))
            dispatch(setFinanceSectorFilter(row?.filter_parameters?.financefilterarraysector))
            dispatch(setFinanceSectorData(row?.filter_parameters?.financedatasector))
            dispatch(setFinanceSectorHandler(row?.filter_parameters?.financehandlersector))

            dispatch(setFStartDateKey(row?.filter_parameters?.financestartdate))
            dispatch(setFEndDateKey(row?.filter_parameters?.financeenddate))
            dispatch(setFinanceStartDate(row?.filter_parameters?.financeSdate))
            dispatch(setFinanceEndDate(row?.filter_parameters?.financeEdate))


        }else if(row?.get_parameters?.category==="devpubs"){
            dispatch(setDRegionKey(row?.filter_parameters?.devfilterregion))
            dispatch(setDevRegionFilter(row?.filter_parameters?.devfilterarrayregion))
            dispatch(setDevRegionData(row?.filter_parameters?.devdataregion))
            dispatch(setDevRegionHandler(row?.filter_parameters?.devhandlerregion))

            dispatch(setDSourceKey(row?.filter_parameters?.devfilterorg))
            dispatch(setDevOrgFilter(row?.filter_parameters?.devfilterarrayorg))
            dispatch(setDevOrgData(row?.filter_parameters?.devdataorg))
            dispatch(setDevOrgHandler(row?.filter_parameters?.devhandlerorg))

            dispatch(setDCountryKey(row?.filter_parameters?.devfiltercountry))
            dispatch(setDevCountryFilter(row?.filter_parameters?.devfilterarraycountry))
            dispatch(setDevCountryData(row?.filter_parameters?.devdatacountry))
            dispatch(setDevCountryHandler(row?.filter_parameters?.devhandlercountry))

            dispatch(setDTopicKey(row?.filter_parameters?.devfiltertopic))
            dispatch(setDevTopicFilter(row?.filter_parameters?.devfilterarraytopic))
            dispatch(setDevTopicData(row?.filter_parameters?.devdatatopic))
            dispatch(setDevTopicHandler(row?.filter_parameters?.devhandlertopic))

            dispatch(setDSectorKey(row?.filter_parameters?.devfiltersector))
            dispatch(setDevSectorFilter(row?.filter_parameters?.devfilterarraysector))
            dispatch(setDevSectorData(row?.filter_parameters?.devdatasector))
            dispatch(setDevSectorHandler(row?.filter_parameters?.devhandlersector))

            dispatch(setDStartDateKey(row?.filter_parameters?.devstartdate))
            dispatch(setDEndDateKey(row?.filter_parameters?.devenddate))
            dispatch(setDevStartDate(row?.filter_parameters?.devSdate))
            dispatch(setDevEndDate(row?.filter_parameters?.devEdate))
        }else if(row?.get_parameters?.category==="otherpubs"){
            dispatch(setORegionKey(row?.filter_parameters?.otherfilterregion))
            dispatch(setOtherRegionFilter(row?.filter_parameters?.otherfilterarrayregion))
            dispatch(setOtherRegionData(row?.filter_parameters?.otherdataregion))
            dispatch(setOtherRegionHandler(row?.filter_parameters?.otherhandlerregion))

            dispatch(setOSourceKey(row?.filter_parameters?.otherfilterorg))
            dispatch(setOtherOrgFilter(row?.filter_parameters?.otherfilterarrayorg))
            dispatch(setOtherOrgData(row?.filter_parameters?.otherdataorg))
            dispatch(setOtherOrgHandler(row?.filter_parameters?.otherhandlerorg))

            dispatch(setOCountryKey(row?.filter_parameters?.otherfiltercountry))
            dispatch(setOtherCountryFilter(row?.filter_parameters?.otherfilterarraycountry))
            dispatch(setOtherCountryData(row?.filter_parameters?.otherdatacountry))
            dispatch(setOtherCountryHandler(row?.filter_parameters?.otherhandlercountry))

            dispatch(setOTopicKey(row?.filter_parameters?.otherfiltertopic))
            dispatch(setOtherTopicFilter(row?.filter_parameters?.otherfilterarraytopic))
            dispatch(setOtherTopicData(row?.filter_parameters?.otherdatatopic))
            dispatch(setOtherTopicHandler(row?.filter_parameters?.otherhandlertopic))

            dispatch(setOSectorKey(row?.filter_parameters?.otherfiltersector))
            dispatch(setOtherSectorFilter(row?.filter_parameters?.otherfilterarraysector))
            dispatch(setOtherSectorData(row?.filter_parameters?.otherdatasector))
            dispatch(setOtherSectorHandler(row?.filter_parameters?.otherhandlersector))

            dispatch(setOStartDateKey(row?.filter_parameters?.otherstartdate))
            dispatch(setOEndDateKey(row?.filter_parameters?.otherenddate))
            dispatch(setOtherStartDate(row?.filter_parameters?.otherSdate))
            dispatch(setOtherEndDate(row?.filter_parameters?.otherEdate))
        }else if(row?.get_parameters?.category==="data"){
            dispatch(setDataRegionKey(row?.filter_parameters?.datafilterregion))
            dispatch(setDataRegionFilter(row?.filter_parameters?.datafilterarrayregion))
            dispatch(setDataRegionData(row?.filter_parameters?.datadataregion))
            dispatch(setDataRegionHandler(row?.filter_parameters?.datahandlerregion))

            dispatch(setDataSourceKey(row?.filter_parameters?.datafilterorg))
            dispatch(setDataOrgFilter(row?.filter_parameters?.datafilterarrayorg))
            dispatch(setDataOrgData(row?.filter_parameters?.datadataorg))
            dispatch(setDataOrgHandler(row?.filter_parameters?.datahandlerorg))

            dispatch(setDataCountryKey(row?.filter_parameters?.datafiltercountry))
            dispatch(setDataCountryFilter(row?.filter_parameters?.datafilterarraycountry))
            dispatch(setDataCountryData(row?.filter_parameters?.datadatacountry))
            dispatch(setDataCountryHandler(row?.filter_parameters?.datahandlercountry))

            dispatch(setDataTopicKey(row?.filter_parameters?.datafiltertopic))
            dispatch(setDataTopicFilter(row?.filter_parameters?.datafilterarraytopic))
            dispatch(setDataTopicData(row?.filter_parameters?.datadatatopic))
            dispatch(setDataTopicHandler(row?.filter_parameters?.datahandlertopic))

            dispatch(setDataSectorKey(row?.filter_parameters?.datafiltersector))
            dispatch(setDataSectorFilter(row?.filter_parameters?.datafilterarraysector))
            dispatch(setDataSectorData(row?.filter_parameters?.datadatasector))
            dispatch(setDataSectorHandler(row?.filter_parameters?.datahandlersector))

            dispatch(setDataResourceKey(row?.get_parameters?.resource))
            dispatch(setDataResourceFilter(row?.filter_parameters?.datafilterarrayresource))
            dispatch(setDataResourceData(row?.filter_parameters?.datadataresource))
            dispatch(setDataResourceHandler(row?.filter_parameters?.datahandlerresource))
            
        }
        else if(row?.get_parameters?.category==="multimedia"){
            dispatch(setMulRegionKey(row?.filter_parameters?.mulfilterregion))
            dispatch(setMulRegionFilter(row?.filter_parameters?.mulfilterarrayregion
                ))
            dispatch(setMulRegionData(row?.filter_parameters?.muldataregion))
            dispatch(setMulRegionHandler(row?.filter_parameters?.mulhandlerregion))

            dispatch(setMulSourceKey(row?.filter_parameters?.mulfilterorg))
            dispatch(setMulOrgFilter(row?.filter_parameters?.mulfilterarrayorg))
            dispatch(setMulOrgData(row?.filter_parameters?.muldataorg))
            dispatch(setMulOrgHandler(row?.filter_parameters?.mulhandlerorg))

            dispatch(setMulCountryKey(row?.filter_parameters?.mulfiltercountry))
            dispatch(setMulCountryFilter(row?.filter_parameters?.mulfilterarraycountry))
            dispatch(setMulCountryData(row?.filter_parameters?.muldatacountry))
            dispatch(setMulCountryHandler(row?.filter_parameters?.mulhandlercountry))

            dispatch(setMulTopicKey(row?.filter_parameters?.mulfiltertopic))
            dispatch(setMulTopicFilter(row?.filter_parameters?.mulfilterarraytopic))
            dispatch(setMulTopicData(row?.filter_parameters?.muldatatopic))
            dispatch(setMulTopicHandler(row?.filter_parameters?.mulhandlertopic))

            dispatch(setMulSectorKey(row?.filter_parameters?.mulfiltersector))
            dispatch(setMulSectorFilter(row?.filter_parameters?.mulfilterarraysector))
            dispatch(setMulSectorData(row?.filter_parameters?.muldatasector))
            dispatch(setMulSectorHandler(row?.filter_parameters?.mulhandlersector))

            dispatch(setMulStartDateKey(row?.filter_parameters?.mulstartdate))
            dispatch(setMulEndDateKey(row?.filter_parameters?.mulenddate))
            dispatch(setMulStartDate(row?.filter_parameters?.mulSdate))
            dispatch(setMulEndDate(row?.filter_parameters?.mulEdate))
        }
        else if(row?.get_parameters?.category==="evaluations"){
            dispatch(setEvalRegionKey(row?.filter_parameters?.evalfilterregion))
            dispatch(setEvalRegionFilter(row?.filter_parameters?.evalfilterarrayregion))
            dispatch(setEvalRegionData(row?.filter_parameters?.evaldataregion))
            dispatch(setEvalRegionHandler(row?.filter_parameters?.evalhandlerregion))

            dispatch(setEvalSourceKey(row?.filter_parameters?.evalfilterorg))
            dispatch(setEvalOrgFilter(row?.filter_parameters?.evalfilterarrayorg))
            dispatch(setEvalOrgData(row?.filter_parameters?.evaldataorg))
            dispatch(setEvalOrgHandler(row?.filter_parameters?.evalhandlerorg))

            dispatch(setEvalCountryKey(row?.filter_parameters?.evalfiltercountry))
            dispatch(setEvalCountryFilter(row?.filter_parameters?.evalfilterarraycountry))
            dispatch(setEvalCountryData(row?.filter_parameters?.evaldatacountry))
            dispatch(setEvalCountryHandler(row?.filter_parameters?.evalhandlercountry))

            dispatch(setEvalTopicKey(row?.filter_parameters?.evalfiltertopic))
            dispatch(setEvalTopicFilter(row?.filter_parameters?.evalfilterarraytopic))
            dispatch(setEvalTopicData(row?.filter_parameters?.evaldatatopic))
            dispatch(setEvalTopicHandler(row?.filter_parameters?.evalhandlertopic))

            dispatch(setEvalSectorKey(row?.filter_parameters?.evalfiltersector))
            dispatch(setEvalSectorFilter(row?.filter_parameters?.evalfilterarraysector))
            dispatch(setEvalSectorData(row?.filter_parameters?.evaldatasector))
            dispatch(setEvalSectorHandler(row?.filter_parameters?.evalhandlersector))

            dispatch(setEvalStartDateKey(row?.filter_parameters?.evalstartdate))
            dispatch(setEvalEndDateKey(row?.filter_parameters?.evalenddate))
            dispatch(setEvalStartDate(row?.filter_parameters?.evalSdate))
            dispatch(setEvalEndDate(row?.filter_parameters?.evalEdate))
        }
        else if(row?.get_parameters?.category==="econpubs"){
            dispatch(setEconRegionKey(row?.filter_parameters?.econfilterregion))
            dispatch(setEconRegionFilter(row?.filter_parameters?.econfilterarrayregion))
            dispatch(setEconRegionData(row?.filter_parameters?.econdataregion))
            dispatch(setEconRegionHandler(row?.filter_parameters?.econhandlerregion))

            dispatch(setEconSourceKey(row?.filter_parameters?.econfilterorg))
            dispatch(setEconOrgFilter(row?.filter_parameters?.econfilterarrayorg))
            dispatch(setEconOrgData(row?.filter_parameters?.econdataorg))
            dispatch(setEconOrgHandler(row?.filter_parameters?.econhandlerorg))

            dispatch(setEconCountryKey(row?.filter_parameters?.econfiltercountry))
            dispatch(setEconCountryFilter(row?.filter_parameters?.econfilterarraycountry))
            dispatch(setEconCountryData(row?.filter_parameters?.econdatacountry))
            dispatch(setEconCountryHandler(row?.filter_parameters?.econhandlercountry))

            dispatch(setEconTopicKey(row?.filter_parameters?.econfiltertopic))
            dispatch(setEconTopicFilter(row?.filter_parameters?.econfilterarraytopic))
            dispatch(setEconTopicData(row?.filter_parameters?.econdatatopic))
            dispatch(setEconTopicHandler(row?.filter_parameters?.econhandlertopic))

            dispatch(setEconSectorKey(row?.filter_parameters?.econfiltersector))
            dispatch(setEconSectorFilter(row?.filter_parameters?.econfilterarraysector))
            dispatch(setEconSectorData(row?.filter_parameters?.econdatasector))
            dispatch(setEconSectorHandler(row?.filter_parameters?.econhandlersector))

            dispatch(setEconStartDateKey(row?.filter_parameters?.start_date))
            dispatch(setEconEndDateKey(row?.filter_parameters?.end_date))
            dispatch(setEconIgnoreQuery(row?.filter_parameters?.ignore_query))
            dispatch(setEconIntialCountry(row?.filter_parameters?.econinitialcountry))
            dispatch(setEconStartDate(row?.filter_parameters?.econSdate))
            dispatch(setEconEndDate(row?.filter_parameters?.econEdate))
        }
        else if(row?.get_parameters?.category==="experts"){
            dispatch(setRegionKey(row?.filter_parameters?.expertsfilterregion))
            dispatch(setExpertsRegionFilter(row?.filter_parameters?.expertsfilterarrayregion))
            dispatch(setExpertsRegionData(row?.filter_parameters?.expertsdataregion))
            dispatch(setExpertsRegionHandler(row?.filter_parameters?.expertshandlerregion))

            dispatch(setSourceKey(row?.filter_parameters?.expertsfilterorg))
            dispatch(setExpertsOrgFilter(row?.filter_parameters?.expertsfilterarrayorg))
            dispatch(setExpertsOrgData(row?.filter_parameters?.expertsdataorg))
            dispatch(setExpertsOrgHandler(row?.filter_parameters?.expertshandlerorg))

            dispatch(setCountryKey(row?.filter_parameters?.expertsfiltercountry))
            dispatch(setExpertsCountryFilter(row?.filter_parameters?.expertsfilterarraycountry))
            dispatch(setExpertsCountryData(row?.filter_parameters?.expertsdatacountry))
            dispatch(setExpertsCountryHandler(row?.filter_parameters?.expertshandlercountry))

            dispatch(setTopicKey(row?.filter_parameters?.expertsfiltertopic))
            dispatch(setExpertsTopicFilter(row?.filter_parameters?.expertsfilterarraytopic))
            dispatch(setExpertsTopicData(row?.filter_parameters?.expertsdatatopic))
            dispatch(setExpertsTopicHandler(row?.filter_parameters?.expertshandlertopic))

            dispatch(setSectorKey(row?.filter_parameters?.expertsfiltersector))
            dispatch(setExpertsSectorFilter(row?.filter_parameters?.expertsfilterarraysector))
            dispatch(setExpertsSectorData(row?.filter_parameters?.expertsdatasector))
            dispatch(setExpertsSectorHandler(row?.filter_parameters?.expertshandlersector))

            dispatch(setExpertsIgnoreQuery(row?.filter_parameters?.ignore_query))
        }
       }

    const tableColumns = [
        {
            name: 'Name',
            selector: row=>row.title,
            sortable: true,
            center: false,
            cell: row => <span className="column-title" style ={{cursor:"pointer"}} onClick={()=>{
                // dispatch(setSearchKey(row?.get_parameters?.qterm))
                clearExpertsFilters()
                clearDevFilters()
                clearDataFilters()
                clearEconFilters()
                clearEvalFilters()
                clearMulFilters()
                clearOtherFilters()
                clearFinanceFilters()
                dispatch(setQueryFlag(true))
                dispatch(setFileVar(""))
                {row?.filter_parameters!==null&&categoryFilter(row)}
                fileOrSearch(row)
                dispatch(setCurrentCategory(row?.get_parameters?.category))
                navigate('/results')
            }}>{row.title?.length>100?row.title?.slice(0,100):row?.title}<span className='authors-name'>{row?.is_received
                ===true?" (received)":null}</span></span>,
            grow: 6
        },
        {
            name: 'Date',
            selector: row=>row.created,
            sortable: true,
            center: false,
            grow: 2,
            cell: row => <span>{row.created.slice(0,10)}</span>,
        },
        {
            name: 'Status',
            selector: row=>`${row.is_shared}` `${row.get_parameters}` `${row.id}` `${row.title}`,
            sortable: false,
            center: false,
            cell: row => <a className="share-icon-cell flex align-items-center">{row.is_shared?<span  onClick={()=>{
                userList()
                AccesshandleShow()
                querySharedWith(row.get_parameters,row.get_parameters.category)
                setQuery(row.get_parameters)
                setCategory(row.get_parameters.category)
                // setQterm(row.get_parameters.qterm)
                row?.get_parameters?.qterm&&(setQterm(row.get_parameters.qterm),setQFile())
                row?.get_parameters?.qfile&&(setQFile(row.get_parameters?.qfile),setQterm())
                setQuerId(row.id)
                setTitle(row.title)
            }} style={{cursor:"pointer"}}><img src={SharedIcon} />Shared</span>:<span onClick={()=>{
                userList()
                AccesshandleShows()
                querySharedWith(row.get_parameters,row.get_parameters.category)
                setQueryParam(row.get_parameters)
                setCat(row.get_parameters.category)
                // setQt(row.get_parameters.qterm)
                row?.get_parameters?.qterm&&(setQt(row.get_parameters.qterm),setQf())
                row?.get_parameters?.qfile&&(setQf(row.get_parameters?.qfile),setQt())
                setQuerId(row.id)
                setTitle(row.title)
            }} style={{cursor:"pointer"}}><img src={ShareIcon} />Private</span>}</a>,
            grow: 2,
        },
        {
            name: '',
            selector: row=>row.id,
            sortable: false,
            center: false,
            cell: row => <div data-tag="allowRowEvents" className='d-flex' >
                <div className="action-icon-cell" onClick={() => {handleShow()
                setQuerId(row.id)}} >
                    <OverlayTrigger
                        overlay={
                            <Tooltip id="InfoText">
                                Remove
                            </Tooltip>
                        }
                    >
                        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.62155 12.875C2.33822 12.875 2.0903 12.7687 1.8778 12.5562C1.6653 12.3437 1.55905 12.0958 1.55905 11.8125V1.71875H0.833008V0.65625H4.16217V0.125H8.83717V0.65625H12.1663V1.71875H11.4403V11.8125C11.4403 12.0958 11.334 12.3437 11.1215 12.5562C10.909 12.7687 10.6611 12.875 10.3778 12.875H2.62155ZM10.3778 1.71875H2.62155V11.8125H10.3778V1.71875ZM4.49863 10.2896H5.56113V3.22396H4.49863V10.2896ZM7.43822 10.2896H8.50072V3.22396H7.43822V10.2896ZM2.62155 1.71875V11.8125V1.71875Z" fill="#5B5B5B" />
                        </svg>
                    </OverlayTrigger>
                </div> </div>,
            grow: 1,
        },

    ];


    const [data, setData] = useState([]);
    const handleChange = ({ selectedRows }) => {
        // You can set state or dispatch with something like Redux so we can use the retrieved data

        setSelected(selectedRows)
      };
      const rows = selected.map((item) => ({ value: item.id }));
      var rowId = rows.map((i) => {
        return i.value;
      });

      //fn to remove queries in bulk
      const removeBulKQuery = ()=>{
        if(token!==undefined){
            dispatch(removeMultipleQuery(rowId,token)).then(()=>{
                savedQueries()
                setSelected([])
                setClear(true)
            })
        }else if(tokenSso!==undefined){
            dispatch(removeMultipleQuery(rowId,tokenSso)).then(()=>{
                savedQueries()
                setSelected([])
                setClear(true)
            })
        }
        
      }

      const setShareFunction=()=>{
        if(shareState===true){
            setClear(true)
            setShareState(!shareState)
            setSelected([])
        }
      }

      const onChangeShare =(state)=>{
        setShareState(state)
      }

      useEffect(()=>{
        if(clear===true){
            setClear(false)
        }
      },[clear])
    return (
        <div className="datatable-wrap checkbox-table">
            <div className="datatable-header-area d-flex m-b-20">
                <div className="datatable-hedaer-left d-flex align-items-center">
                    <div className="selectall-wrap">
                        <div className="checkbox-wrapper">
                            {/* <input id="Check1" type="checkbox" />
                            <label htmlFor="Check1">
                                <span>Select all</span>
                            </label> */}
                        </div>
                    </div>
                    <div className="table-buttons-wrap double-btn d-flex">
                        <button className="btn grey-border-btn btn-sm" 
                        disabled={rowId?.length===0}
                        onClick={()=>{
                            userList()
                            CountryhandleShow()
                        }}>
                            <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.29472 2.96139L12.4142 6.53611L9.29472 10.0747V7.51103L7.95781 7.58325C5.79328 7.69157 4.13806 7.94433 2.83298 8.44984C3.9789 7.07773 5.79328 5.99448 8.1488 5.74173L9.29472 5.6334V2.96139ZM8.37161 0.000516431C8.32139 0.00506508 8.27245 0.0208406 8.22762 0.0469341C8.1828 0.0730277 8.14296 0.108924 8.11042 0.152555C8.07787 0.196186 8.05326 0.24669 8.03799 0.301158C8.02273 0.355627 8.01711 0.412983 8.02147 0.469924V4.2974C3.18312 4.83902 0.0636625 8.59428 0 12.3856C0 12.6023 0.0636624 12.7467 0.190987 12.7467C0.318312 12.7467 0.413806 12.6384 0.541131 12.3495C1.30508 10.3997 3.02397 9.28033 8.02147 9.02758V12.5301C8.01711 12.587 8.02273 12.6444 8.03799 12.6988C8.05326 12.7533 8.07787 12.8038 8.11042 12.8474C8.14296 12.8911 8.1828 12.927 8.22762 12.9531C8.27245 12.9792 8.32139 12.9949 8.37161 12.9995C8.42147 13.0027 8.47121 12.991 8.51587 12.9657C8.56054 12.9404 8.5986 12.9022 8.62626 12.855L13.8784 6.89719C13.9567 6.79789 14 6.66934 14 6.53611C14 6.40288 13.9567 6.27433 13.8784 6.17503L8.62626 0.144949C8.5986 0.097785 8.56054 0.0596438 8.51587 0.0343085C8.47121 0.00897325 8.42147 -0.00267587 8.37161 0.000516431Z" fill="#1A73E8" />
                            </svg>
                            Share Queries
                        </button>
                        <button
                        disabled={rowId?.length===0}
                        className="btn grey-border-btn btn-sm" onClick={() => handleShows()}>
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.62155 12.875C2.33822 12.875 2.0903 12.7687 1.8778 12.5562C1.6653 12.3437 1.55905 12.0958 1.55905 11.8125V1.71875H0.833008V0.65625H4.16217V0.125H8.83717V0.65625H12.1663V1.71875H11.4403V11.8125C11.4403 12.0958 11.334 12.3437 11.1215 12.5562C10.909 12.7687 10.6611 12.875 10.3778 12.875H2.62155ZM10.3778 1.71875H2.62155V11.8125H10.3778V1.71875ZM4.49863 10.2896H5.56113V3.22396H4.49863V10.2896ZM7.43822 10.2896H8.50072V3.22396H7.43822V10.2896ZM2.62155 1.71875V11.8125V1.71875Z" fill="#5B5B5B" />
                            </svg> Remove</button>
                    </div>
                </div>
                <div className="datatable-hedaer-right">
                    <Form className="w-100" onSubmit={(e)=>{e.preventDefault()}}>
                        <div className="search-input-wrap p-relative">
                            <Form.Control type="text" placeholder="Search" className="input-item" onChange={onSearchValueChange}/>
                            <span className="search-svg-wrap">
                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.9355 14L9.99193 10.6633C9.50806 11.0678 8.94355 11.3828 8.29839 11.6083C7.65323 11.8339 6.96774 11.9467 6.24194 11.9467C4.5 11.9467 3.02419 11.3633 1.81452 10.1967C0.604839 9.03 0 7.62222 0 5.97333C0 4.32444 0.604839 2.91667 1.81452 1.75C3.02419 0.583333 4.49194 0 6.21774 0C7.92742 0 9.38306 0.583333 10.5847 1.75C11.7863 2.91667 12.3871 4.32444 12.3871 5.97333C12.3871 6.64222 12.2742 7.28778 12.0484 7.91C11.8226 8.53222 11.4839 9.11556 11.0323 9.66L15 12.9733L13.9355 14ZM6.21774 10.5467C7.52419 10.5467 8.6371 10.0994 9.55645 9.205C10.4758 8.31055 10.9355 7.23333 10.9355 5.97333C10.9355 4.71333 10.4758 3.63611 9.55645 2.74167C8.6371 1.84722 7.52419 1.4 6.21774 1.4C4.89516 1.4 3.77016 1.84722 2.84274 2.74167C1.91532 3.63611 1.45161 4.71333 1.45161 5.97333C1.45161 7.23333 1.91532 8.31055 2.84274 9.205C3.77016 10.0994 4.89516 10.5467 6.21774 10.5467Z" fill="#A5A5A5" />
                                </svg>
                            </span>
                        </div>
                    </Form>
                </div>
            </div>
            {clear===false&&
            <div className="data-table-responsive">
            <DataTable
                data={data}
                columns={tableColumns}
                selectableRows
                center={true}
                noHeader={true}
                onSelectedRowsChange={handleChange}
                noDataComponent={loading||clear? <div className="loader-middle-align">
                <div className="loader loader-3">
                  <div className="dot dot1">&nbsp;</div>
                  <div className="dot dot2">&nbsp;</div>
                  <div className="dot dot3">&nbsp;</div>
                </div></div>:search===""?<div className="loader-middle-align">No queries have been saved yet.</div>:
                <div className="loader-middle-align">Nothing turned up! You may want to try using different keywords, or checking for typos please.</div>}
            />
            {/* <CustomPagination /> */}
            {/* <TablePaginate total={(Math.ceil(count/10))} current={page} onChangePage={handleChangePage} onChangeOffset={handleOffsetChange}/> */}
            {data?.length>0&&<Paginate
            pageCount={(Math.ceil(count / 10))}
            onPageChange={handlePage}
            initialPage={currentPage}
            />}
        </div>
           }
            
            <Modal show={show} size="sm" centered className='common-modal'>
                <Modal.Header closeButton onClick={handleClose} className="m-b-15">
                    <Modal.Title>Remove</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="delete-confirm-wrap">
                        <p>Are you sure you want to remove this item?</p>
                    </div>
                    <div className="savequery-pop-footer">
                        <div className="d-flex justify-content-end double-btn m-t-15">
                            <button className="btn grey-border-btn" onClick={handleClose}>Cancel</button>
                            <button type="submit" className="btn btn-primary" onClick={()=>{
                                handleClose()
                                removeSingleSavedQuery()
                            }}>Remove</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={bulkShow} size="sm" centered className='common-modal'>
                <Modal.Header closeButton onClick={handleCloses} className="m-b-15">
                    <Modal.Title>Remove</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="delete-confirm-wrap">
                        <p>Are you sure you want to remove these items?</p>
                    </div>
                    <div className="savequery-pop-footer">
                        <div className="d-flex justify-content-end double-btn m-t-15">
                            <button className="btn grey-border-btn" onClick={handleCloses}>Cancel</button>
                            <button type="submit" className="btn btn-primary" onClick={()=>{removeBulKQuery()
                            handleCloses()}}>Remove</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={Countryshows} size="md" className='common-modal side-popup'>
                <Modal.Header closeButton onClick={()=>{
                    CountryhandleCloses()
                    setShareFunction()
                }}>
                    <Modal.Title>Manage access</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="country-pop-wraper">
                        <MultipleQueryPopup onChangeShare={onChangeShare} rowId={rowId} CountryhandleCloses={CountryhandleCloses} offset={offset}/>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={AccessShow} size="md" className='common-modal side-popup'>
                <Modal.Header closeButton onClick={AccesshandleClose}>
                    <Modal.Title>Manage access</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="country-pop-wraper">
                        < StatusPop query={query} category={category} qterm={qterm} qfile={qfile} queryId={queryId} title={title} />
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={AccessShows} size="md" className='common-modal side-popup'>
                <Modal.Header closeButton onClick={AccesshandleCloses}>
                    <Modal.Title>Manage access</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="country-pop-wraper">
                        < PrivatePopup query={queryParam} cat={cat} qt={qt} qf={qf} queryId={queryId} title={title} />
                    </div>
                </Modal.Body>
            </Modal>
            {/* <ToastContainer/> */}
        </div>

    );
};

export default MyQueries;
