import { createAction, handleActions } from 'redux-actions';
import ActionTypes from '~shared/constants/actionTypes';

const initialState = {
  chatResponse: [], // Initialize chatResponse as an empty array
  loading: false,
  personality : "",
  conversation_id:null,
  tab_id : null,
  search_query:"",
  history : [],
  history_loading : null,
  new_chat : true,
  country : "",
  region : "",
  topic : "",
  previous_text : "",
  paginate_count : 0,
  generate_more : false,
  cancel_api : false,
  permission : false,
  uploadedDocs:[],
  docSubmitLoading : false,
  docUrl:'',
  uploadDocLoading : false
};

const setChatResponse = createAction(ActionTypes.SET_CHAT_RESPONSE);

export const addChatResponse = (data) => (dispatch) => {
  dispatch(setChatResponse(data));
};

const setChatHistory = createAction(ActionTypes.SET_CHAT_HISTORY);

export const getChatHistory = (data) => (dispatch) => {
  dispatch(setChatHistory(data));
};

const setClearChat = createAction(ActionTypes.SET_CLEAR_CHAT);

export const clearChat = () =>(dispatch)=>{
  dispatch(setClearChat())
}

const setLoadingAction = createAction(ActionTypes.SET_LOADING);

export const setLoading = (data) =>(dispatch) =>{
  dispatch(setLoadingAction(data))
}

const setPersonalityAction = createAction(ActionTypes.SET_PERSONALITY);

export const setPersonality = (data) =>(dispatch) =>{
  dispatch(setPersonalityAction(data))
}

const setConversationIdAction = createAction(ActionTypes.SET_CONVERSATION_ID);

export const setConversationId = (data) =>(dispatch) =>{
  dispatch(setConversationIdAction(data))
}

const setTabIdAction = createAction(ActionTypes.SET_TAB_ID);

export const setTabId = (data) =>(dispatch) =>{
  dispatch(setTabIdAction(data))
}

const setSearchQueryAction = createAction(ActionTypes.SET_SEARCH_QUERY);

export const setSearchQuery = (data) =>(dispatch) =>{
  dispatch(setSearchQueryAction(data))
}

const historyLoadingAction = createAction(ActionTypes.SET_HISTORY_LOADING);

export const setHistoryLoading = (data) =>(dispatch) =>{
  dispatch(historyLoadingAction(data))
}

const newChatAction = createAction(ActionTypes.SET_NEW_CHAT);

export const setNewChat = (data) =>(dispatch) =>{
  dispatch(newChatAction(data))
}

const chatCountry = createAction(ActionTypes.SET_CHAT_COUNTRY);
export const setChatCountry = (data) =>(dispatch) =>{
  dispatch(chatCountry(data))
}

const chatRegion = createAction(ActionTypes.SET_CHAT_REGION);
export const setChatRegion = (data) =>(dispatch) =>{
  dispatch(chatRegion(data))
}

const chatTopic = createAction(ActionTypes.SET_CHAT_TOPIC);
export const setChatTopic = (data) =>(dispatch) =>{
  dispatch(chatTopic(data))
}

const previousText = createAction(ActionTypes.SET_PREVIOUS_TEXT);
export const setPreviousText = (data) =>(dispatch) =>{
  dispatch(previousText(data))
}

const paginateCount = createAction(ActionTypes.SET_PAGINATE_COUNT);
export const setPaginateCount = (data) =>(dispatch) =>{
  dispatch(paginateCount(data))
}

const generateMore = createAction(ActionTypes.SET_GENERATE_MORE);
export const setGenerateMore = (data) =>(dispatch) =>{
  dispatch(generateMore(data))
}

const cancelApi = createAction(ActionTypes.SET_CANCEL_API);
export const setCancelApi = (data) =>(dispatch) =>{
  dispatch(cancelApi(data))
}

const chatPermission = createAction(ActionTypes.SET_CHAT_PERMISSION);
export const setChatPermission = (data) =>(dispatch) =>{
  dispatch(chatPermission(data))
}

const docSubmitLoading = createAction(ActionTypes.SET_DOCSUBMIT_LOADING);

export const setDocSubmitLoading = (data) =>(dispatch) =>{
  dispatch(docSubmitLoading(data))
}

const uploadDocLoading = createAction(ActionTypes.SET_UPLOADDOC_LOADING);

export const setUploadDocLoading = (data) =>(dispatch) =>{
  dispatch(uploadDocLoading(data))
}

const uploadedDocs = createAction(ActionTypes.SET_UPLOADEDDOC);

export const setUploadedDocs = (data) =>(dispatch) =>{
  dispatch(uploadedDocs(data))
}


const docurl = createAction(ActionTypes.SET_DOC_URL);

export const setDocurl = (data) =>(dispatch) =>{
  dispatch(docurl(data))
}



const reducer = handleActions(
    {
      [ActionTypes.SET_CHAT_RESPONSE]: (state, action) => ({
        ...state,
        chatResponse : [...state.chatResponse,action.payload],
      }),
      [ActionTypes.SET_CLEAR_CHAT]: (state, action) => ({
        ...state,
        chatResponse : [],
      }),
      [ActionTypes.SET_LOADING]: (state, action) => ({
        ...state,
        loading : action.payload
      }),
      [ActionTypes.SET_PERSONALITY]: (state, action) => ({
        ...state,
        personality : action.payload
      }),
      [ActionTypes.SET_CONVERSATION_ID]: (state, action) => ({
        ...state,
        conversation_id : action.payload
      }),
      [ActionTypes.SET_SEARCH_QUERY]: (state, action) => ({
        ...state,
        search_query : action.payload
      }),
      [ActionTypes.SET_TAB_ID]: (state, action) => ({
        ...state,
        tab_id : action.payload
      }),
      [ActionTypes.SET_CHAT_HISTORY]: (state, action) => ({
        ...state,
        history : action.payload
      }),
      [ActionTypes.SET_HISTORY_LOADING]: (state, action) => ({
        ...state,
        history_loading : action.payload
      }),
      [ActionTypes.SET_NEW_CHAT]: (state, action) => ({
        ...state,
        new_chat : action.payload
      }),
      [ActionTypes.SET_CHAT_COUNTRY]: (state, action) => ({
        ...state,
        country : action.payload
      }),
      [ActionTypes.SET_CHAT_REGION]: (state, action) => ({
        ...state,
        region : action.payload
      }),
      [ActionTypes.SET_CHAT_TOPIC]: (state, action) => ({
        ...state,
        topic : action.payload
      }),
      [ActionTypes.SET_PREVIOUS_TEXT]: (state, action) => ({
        ...state,
        previous_text : action.payload
      }),
      [ActionTypes.SET_PAGINATE_COUNT]: (state, action) => ({
        ...state,
        paginate_count : action.payload
      }),
      [ActionTypes.SET_GENERATE_MORE]: (state, action) => ({
        ...state,
        generate_more : action.payload
      }),
      [ActionTypes.SET_CANCEL_API]: (state, action) => ({
        ...state,
        cancel_api : action.payload
      }),
      [ActionTypes.SET_CHAT_PERMISSION]: (state, action) => ({
        ...state,
        permission : action.payload
      }),
      [ActionTypes.SET_UPLOADDOC_LOADING]: (state, action) => ({
        ...state,
        uploadDocLoading : action.payload
      }),
      [ActionTypes.SET_UPLOADEDDOC]: (state, action) => ({
        ...state,
        uploadedDocs : action.payload || []
      }),
      [ActionTypes.SET_DOCSUBMIT_LOADING]: (state, action) => ({
        ...state,
        docSubmitLoading : action.payload 
      }),
      [ActionTypes.SET_DOC_URL]: (state, action) => ({
        ...state,
        docUrl : action.payload || []
      }),
      
    },
    initialState
  );

export default reducer;
