import React, { useState } from "react";
import FeedTiles from "../feedtiles";


function CustomContents() {

  return (
<div className="custom-content-wrap">
   <div className="chat-feed-header text-center">
    <div className="chat-header-inline d-inline-flex align-items-center"><h4>Chat</h4><span className="chat-beta-text">BETA</span></div>
    <p>Your personalised AI-powered chatbot</p>
   </div>
   <div className="feed-section">
    <FeedTiles/>
   </div>

   </div>
  );
}

export default CustomContents;
