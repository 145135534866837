import React from 'react';
import { Container } from 'react-bootstrap';
function Footer() {
    return (
        <div className="footer m-t-40">
            <Container>
                <div className="footer-content text-center">
                    <p>
                    KushAI LLC. is not responsible for content on external websites. © 2022 KushAI LLC. All rights reserved.
                    </p>
                </div>
            </Container>
        </div>
    );
};

export default Footer;
