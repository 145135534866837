import React, { useEffect, useState } from "react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  ChatContainer,
  MessageList,
  Message,
  TypingIndicator,
  MainContainer,
} from "@chatscope/chat-ui-kit-react";
import { useSelector,shallowEqual,useDispatch } from "react-redux";
import { Tooltip } from "react-tooltip";
import AvatarIcon from "../../../../../../../../assets/images/icons/avatarlogo.svg";
import ProfileDummmy from "../../../../../../../../assets/images/profile-dummy.png";
import CopyIcon from "../../../../../../../../shared/icons/copyicon";
import LikeIcon from "../../../../../../../../shared/icons/likeicon";
import DislikeIcon from "../../../../../../../../shared/icons/dislikeicon";
import BotLogo from "../../../../../../../../assets/images/icons/botlogo.svg";
import ImageResults from "./components/imageresult";
import LearnMore from "./components/learnmore";
import RelatedArticles from "./components/relatedarticles";
import {_likeDislikeChat,_getHistory} from '../../../../../../../../shared/httpservice/apis'
import {addChatResponse,clearChat,getChatHistory} from '../../../../../../services'
function ChatResults() {
  const dispatch = useDispatch()
  const chatResponses = useSelector((state) => state?.chat?.chatResponse);
  const [clicked,setClicked] = useState(false)
  const [chatResponse,setChatResponse] = useState(chatResponses)
  useEffect(()=>{
    setChatResponse(chatResponses)
  },[chatResponses,clicked])
  const personality = useSelector((state) => state?.chat?.personality);
  const search_query = useSelector((state)=>state?.chat?.search_query)
  const userInfo = useSelector((state) => state?.profile?.userProfile?.response?.data?.data?.results[0]);
  const tokenSso = useSelector(
    (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token,
    shallowEqual
  );
  const token = useSelector(
    (store) => store?.auth?.signin?.response?.data?.data?.token,
    shallowEqual
  );
  const copyToClipboard = (msg) => {
    navigator.clipboard.writeText(msg);
  };

  const getHistory = async () => {
    try {
      if (token !== undefined) {
        const chatData = await _getHistory(token);
        dispatch(getChatHistory(chatData?.data?.data));
      } else if (tokenSso !== undefined) {
        const chatData = await _getHistory(tokenSso);
        dispatch(getChatHistory(chatData?.data?.data));
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const onLikeClick = (item) =>{
    item.like = true 
    item.dislike = false
    setClicked(!clicked)
    dispatch(clearChat())
    chatResponse?.map((item)=>{
      dispatch(addChatResponse(item))
    })
   const data = {
      "conversation": item?.id,
      "like_dislike": 1
      }
    if(token!==undefined){
      return _likeDislikeChat(data,token).then(()=>{
        getHistory()
      })
    }else if(tokenSso!==undefined){
      return _likeDislikeChat(data,tokenSso).then(()=>{
        getHistory()
      })
    }
    
  }

  const onDislikeClick = (item) =>{
    item.dislike = true 
    item.like = false
    setClicked(!clicked)
    dispatch(clearChat())
    chatResponse?.map((item)=>{
      dispatch(addChatResponse(item))
    })
    const data = {
      "conversation": item?.id,
      "like_dislike": 0
      }
    if(token!==undefined){
      return _likeDislikeChat(data,token).then(()=>{
        getHistory()
      })
    }else if(tokenSso!==undefined){
      return _likeDislikeChat(data,tokenSso).then(()=>{
        getHistory()
      })
    }
  }


  return (
    <div className="feed-whole-box h-100">
       <Tooltip
        id="my-tooltip-click"
        content="Copied"
        openOnClick={["Copied"]}
        data-tooltip-offset={10}
        delayHide={1000}
        place="right"
      />
      <ChatContainer>
        <MessageList className="chatbot-message-list h-100">
          {chatResponse &&
            chatResponse?.map((item) => (
              <Message>
                {item?.sender === "user" ? (
                  <Message.CustomContent className="query-input-wrap">
                    <div className="query-input-row d-flex">
                      <div className="query-user-logo user-img">
                      <img  src={userInfo?.profile_pic_path ? userInfo?.profile_pic_path : ProfileDummmy} alt="" />
                      </div>
                      <div className="query-input-message">
                        <p className="m-0">{item?.message}</p>
                      </div>
                    </div>
                  </Message.CustomContent>
                ) : (
                  <Message.CustomContent>
                    <div className="query-output-wrap">
                      <div className="query-output-row d-flex">
                        <div className="query-bot-logo">
                          <img src={BotLogo} alt="" />
                        </div>
                        <div className="query-output-message">
                          {/* <p className="m-0">{item?.message}</p> */}
                          <div className="query-output-message-flex-wrap">
                            <div className="query-output-box">
                              <div className="query-output-paragraph-wrap">
                              <p className="m-0">
                                {item?.message}
                              </p></div>
                              <ImageResults indicators={item?.indicators} />
                              <LearnMore references = {item?.references} />
                            </div>
                          </div>
                        </div>
                        {item?.message==="Server Error: We apologize, but our service is currently experiencing technical difficulties. Please try again later."||
                       item?.message=== "It seems like your message is empty. If you have any questions or need assistance with something, please feel free to ask, and I'll be happy to help!"||
                       item?.message==="It seems like your message is incomplete. How can I assist you today? Please provide more details or let me know what you'd like to discuss or ask about, and I'll be happy to help."?null: <div className="query-output-action-icons">
                          <span onClick={() => copyToClipboard(item?.message)} data-tooltip-id="my-tooltip-click" className="query-action-icon query-action-copy">
                            <CopyIcon />
                          </span>
                          <span onClick={()=>{item?.like!==true&&
                            onLikeClick(item)
                          }} className="query-action-icon query-action-like">
                            <LikeIcon iconColor = {item?.like===true?"#1A73E8":"#373737"} />
                          </span>
                          <span onClick = {()=>{item?.dislike!==true&&
                            onDislikeClick(item)
                          }} className="query-action-icon query-action-dislike">
                            <DislikeIcon iconColor = {item?.dislike===true?"#1A73E8":"#373737"}/>
                          </span>
                         
                          
                        </div>}
                       
                      </div>
                    </div>
                    {item?.search_results?<RelatedArticles results={item?.search_results} facets={item?.facets} count={item?.total_results} query={item?.search_query} personality={personality} country={item?.countries} 
                    region = {item?.regions} major_concepts={item?.major_concepts} org = {item?.org}/>:null}
                    
                  </Message.CustomContent>
                )}
              </Message>
            ))}
        </MessageList>
      </ChatContainer>
    </div>
  );
}

export default ChatResults;
