import { combineReducers } from "redux";
import auth from './../../modules/auth/services'
import results from './../../modules/results/services'
import profile from './../../modules/profile/services'
import faq from './../../modules/helpandsupport/services'
import ActionTypes from './../../shared/constants/actionTypes'
import count from './../../modules/staticpages/service'
import filter from './../../modules/results/filterServices'
import econFilter from './../../modules/results/components/econpubsresults/econfilterservices'
import evalFilter from './../../modules/results/components/evaluationsresults/evaluationfilterservices'
import devFilter from './../../modules/results/components/devpubsresults/devpubsfiltersevices'
import otherFilter from './../../modules/results/components/otherpubsresults/otherpubsfilterservices'
import mulFilter from './../../modules/results/components/multimediaresults/multimediafilterservices'
import dataFilter from './../../modules/results/components/dataresults/datafilterservices'
import expertsFilter from './../../modules/results/components/expertsresults/expertsfilterservices'
import chat from './../../modules/chatai/services'



const rootReducer = combineReducers({
  auth,
  results,
  profile,
  faq,
  count,
  filter,
  econFilter,
  evalFilter,
  devFilter,
  otherFilter,
  mulFilter,
  dataFilter,
  expertsFilter,
  chat
});

const AppReducer = (state, action) => {
  // if(action.type === ActionTypes.USER_LOGOUT_SUCCESS || action.type === ActionTypes.USER_LOGOUT){
  //   state = undefined;

  // }
  if(action.type === ActionTypes.RESET_STORE){
    state = undefined
    localStorage.removeItem("persist:kush.ai");
  }
  return rootReducer(state, action);
};

export default AppReducer;
