import { createElement } from 'react';
import {createAction,handleActions} from 'redux-actions';
import ActionTypes from '~shared/constants/actionTypes';

// import {store} from  '../../../redux/store';

const initialState = {
    eval_org_filter : [],
    eval_org_data : [],
    eval_org_handler : [],
    eval_country_filter : [],
    eval_country_data : [],
    eval_country_handler : [],
    eval_region_filter : [],
    eval_region_data : [],
    eval_region_handler : [],
    eval_sector_filter : [],
    eval_sector_data : [],
    eval_sector_handler : [],
    eval_topic_filter : [],
    eval_topic_data : [],
    eval_topic_handler : [],
    eval_search_country : [],
    date_var_eval : true,
    eval_start_date_handler : null,
    eval_end_date_handler : null,
    
    
  }

//Econ 
const setEvalOrgArray = createAction(ActionTypes.SET_EVAL_ORG_FILTER, (data) => data);

const setEvalCountryArray = createAction(ActionTypes.SET_EVAL_COUNTRY_FILTER)

export const setEvalCountryFilter = (data) => (dispatch) => {
  dispatch(setEvalCountryArray(data))
}

export const setEvalOrgFilter = (data) => (dispatch) => {
  dispatch(setEvalOrgArray(data));
};

const setEvalCountry = createAction(ActionTypes.SET_EVAL_COUNTRY_DATA)

export const setEvalCountryData = (data) => (dispatch) => {
  dispatch(setEvalCountry(data))
}

const setEvalCountryHandlerArray = createAction(ActionTypes.SET_EVAL_COUNTRY_HANDLER)

export const setEvalCountryHandler = (data) => (dispatch) =>{
  dispatch(setEvalCountryHandlerArray(data))
}

const setEvalOrg = createAction(ActionTypes.SET_EVAL_ORG_DATA)

export const setEvalOrgData = (data) => (dispatch) => {
  dispatch(setEvalOrg(data))
}

const setEvalOrgHandlerArray = createAction(ActionTypes.SET_EVAL_ORG_HANDLER)

export const setEvalOrgHandler = (data) => (dispatch) => {
  dispatch(setEvalOrgHandlerArray(data))
}

const setEvalRegionArray =  createAction(ActionTypes.SET_EVAL_REGION_FILTER)

export const setEvalRegionFilter = (data) =>(dispatch) =>{
  dispatch(setEvalRegionArray(data))
}

const setEvalRegionAction = createAction(ActionTypes.SET_EVAL_REGION_DATA)

export const setEvalRegionData = (data) => (dispatch) =>{
  dispatch(setEvalRegionAction(data))
}

const setEvalRegionHandlerAction = createAction(ActionTypes.SET_EVAL_REGION_HANDLER)

export const setEvalRegionHandler = (data) =>(dispatch)=>{
  dispatch(setEvalRegionHandlerAction(data))
}

const setEvalSectorArray =  createAction(ActionTypes.SET_EVAL_SECTOR_FILTER)

export const setEvalSectorFilter = (data) =>(dispatch) =>{
  dispatch(setEvalSectorArray(data))
}

const setEvalSectorAction = createAction(ActionTypes.SET_EVAL_SECTOR_DATA)

export const setEvalSectorData = (data) => (dispatch) =>{
  dispatch(setEvalSectorAction(data))
}

const setEvalSectorHandlerAction = createAction(ActionTypes.SET_EVAL_SECTOR_HANDLER)

export const setEvalSectorHandler = (data) =>(dispatch)=>{
  dispatch(setEvalSectorHandlerAction(data))
}

const setEvalTopicArray =  createAction(ActionTypes.SET_EVAL_TOPIC_FILTER)

export const setEvalTopicFilter = (data) =>(dispatch) =>{
  dispatch(setEvalTopicArray(data))
}

const setEvalTopicAction = createAction(ActionTypes.SET_EVAL_TOPIC_DATA)

export const setEvalTopicData = (data) => (dispatch) =>{
  dispatch(setEvalTopicAction(data))
}

const setEvalTopicHandlerAction = createAction(ActionTypes.SET_EVAL_TOPIC_HANDLER)

export const setEvalTopicHandler = (data) =>(dispatch)=>{
  dispatch(setEvalTopicHandlerAction(data))
}

const setEvalSearchCountryAction = createAction(ActionTypes.SET_EVAL_SEARCH_COUNTRY)

export const setEvalSearchCountry = (data) =>(dispatch)=>{
  dispatch(setEvalSearchCountryAction(data))
}

const setDateTriggerActionEval = createAction(ActionTypes.SET_DATE_TRIGGER_EVAL)
export const setDateTriggerEval = (data)=>(dispatch)=>{
  dispatch(setDateTriggerActionEval(data))
}

const setEvalStartDateAction = createAction(ActionTypes.SET_EVAL_START_DATE)
export const setEvalStartDate = (data)=>(dispatch)=>{
  dispatch(setEvalStartDateAction(data))
}

const setEvalEndDateAction = createAction(ActionTypes.SET_EVAL_END_DATE)
export const setEvalEndDate = (data)=>(dispatch)=>{
  dispatch(setEvalEndDateAction(data))
}


const reducer = handleActions({
   
[ActionTypes.SET_EVAL_ORG_FILTER]: (state, action) => ({
        ...state,
        eval_org_filter: action.payload,
      }),
[ActionTypes.SET_EVAL_COUNTRY_FILTER]: (state, action) => ({
        ...state,
        eval_country_filter : action.payload,
      }),
[ActionTypes.SET_EVAL_COUNTRY_DATA]: (state, action) => ({
        ...state,
        eval_country_data : action.payload,
      }),
  [ActionTypes.SET_EVAL_COUNTRY_HANDLER]: (state, action) => ({
        ...state,
        eval_country_handler : action.payload,
      }),   
  [ActionTypes.SET_EVAL_ORG_DATA]: (state, action) => ({
        ...state,
        eval_org_data : action.payload,
      }),            
  [ActionTypes.SET_EVAL_ORG_HANDLER]: (state, action) => ({
        ...state,
        eval_org_handler : action.payload,
      }),
  [ActionTypes.SET_EVAL_REGION_FILTER]: (state, action) => ({
        ...state,
        eval_region_filter : action.payload,
      }),  
  [ActionTypes.SET_EVAL_REGION_DATA]: (state, action) => ({
        ...state,
        eval_region_data : action.payload,
      }),                     
  [ActionTypes.SET_EVAL_REGION_HANDLER]: (state, action) => ({
        ...state,
        eval_region_handler : action.payload,
      }),
  [ActionTypes.SET_EVAL_SECTOR_FILTER]: (state, action) => ({
        ...state,
        eval_sector_filter : action.payload,
      }),  
  [ActionTypes.SET_EVAL_SECTOR_DATA]: (state, action) => ({
        ...state,
        eval_sector_data : action.payload,
      }),                     
  [ActionTypes.SET_EVAL_SECTOR_HANDLER]: (state, action) => ({
        ...state,
        eval_sector_handler : action.payload,
      }), 
  [ActionTypes.SETEVAL_TOPIC_FILTER]: (state, action) => ({
        ...state,
        eval_topic_filter : action.payload,
      }),  
  [ActionTypes.SET_EVAL_TOPIC_DATA]: (state, action) => ({
        ...state,
        eval_topic_data : action.payload,
      }),                     
  [ActionTypes.SET_EVAL_TOPIC_HANDLER]: (state, action) => ({
        ...state,
        eval_topic_handler : action.payload,
      }),  
  [ActionTypes.SET_EVAL_SEARCH_COUNTRY]: (state, action) => ({
        ...state,
        eval_search_country : action.payload,
      }),  
  [ActionTypes.SET_DATE_TRIGGER_EVAL]: (state, action) => ({
        ...state,
        date_var_eval : action.payload,
      }),
  [ActionTypes.SET_EVAL_START_DATE]: (state, action) => ({
        ...state,
        eval_start_date_handler : action.payload,
      }),
  [ActionTypes.SET_EVAL_END_DATE]: (state, action) => ({
        ...state,
        eval_end_date_handler : action.payload,
      }),                                        
},initialState);

export default reducer;