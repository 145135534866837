import React, { useEffect, useState, useRef, useCallback } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Scrollbar from "react-perfect-scrollbar-z";
import SideQueryDoc from "../../../../../../assets/images/icons/sidedocicon.svg";
import SideQueryText from "../../../../../../assets/images/icons/sidetexticon.svg";
import {
  _chatRelatedSearch,
  _chatresults,
  _getHistory,
  _getHistoryOther,
  _chatHistoryContents,
  _editChatHistory,
  _deleteChatHistory
} from "../../../../../../shared/httpservice/apis";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  clearChat,
  addChatResponse,
  setLoading,
  getChatHistory,
  setConversationId,
  setTabId,
  setHistoryLoading,
  setNewChat,
  setPersonality,
  setPreviousText,
  setPaginateCount,
  setGenerateMore,
  setCancelApi,
  setDocurl,
  setUploadedDocs
} from "../../../../services";
import InfiniteScroll from "react-infinite-scroll-component";
import CloseIcon from "../../../../../../shared/icons/closeicon";
import TickIcon from "../../../../../../shared/icons/tickicon";
import { personalities, relatedArticlesCategory } from '../../../../chatConfig'
import MoreIcon from "../../../../../../shared/icons/moreicon";
import { Tooltip as ReactTooltip } from "react-tooltip";
function PreviousChat({ search, onClose }) {
  const dispatch = useDispatch();
  // const scrollRef = useRef(null);
  const chatResponses = useSelector((state) => state?.chat?.chatResponse);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const loading = useSelector((state) => state?.chat?.loading);
  const [editClicked, setEditClicked] = useState(null);
  const [title, setTitle] = useState(null);
  const [next, setNext] = useState();
  const tokenSso = useSelector(
    (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token,
    shallowEqual
  );
  const token = useSelector(
    (store) => store?.auth?.signin?.response?.data?.data?.token,
    shallowEqual
  );
  const historyResponse = useSelector((state) => state?.chat?.history);
  const [otherHistory, setOtherHistory] = useState([]);
  const [totalResults, setTotalResults] = useState(null);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  useEffect(() => {
    if (next === null) {
      setHasMore(false)
    } else {
      setHasMore(true)
    }
  }, [next])
  const [today, setToday] = useState([]);
  const [yesterday, setYesterday] = useState([]);
  const [week, setWeek] = useState([]);
  useEffect(() => {
    if (historyResponse) {
      setToday(historyResponse?.today);
    }
    if (historyResponse) {
      setYesterday(historyResponse?.yesterday);
    }
    if (historyResponse) {
      setWeek(historyResponse?.this_week);
    }
  }, [historyResponse]);

  useEffect(() => {
    if (search !== "") {
      const searchLower = search?.toLowerCase();
      const newToday = today?.filter((item) => item?.title?.toLowerCase()?.includes(searchLower));
      setToday(newToday);
      const newYesterday = yesterday?.filter((item) =>
        item?.title?.toLowerCase()?.includes(searchLower)
      );
      setYesterday(newYesterday);
      const newWeek = week?.filter((item) => item?.title?.toLowerCase()?.includes(searchLower));
      setWeek(newWeek);
    } else {
      setToday(historyResponse?.today);
      setYesterday(historyResponse?.yesterday);
      setWeek(historyResponse?.this_week);
    }
  }, [search]);

  // useEffect(()=>{
  //   if(option!==""){
  //     if(option==="FINANCING INSIGHTS"){
  //       console.log("financing");
  //       dispatch(setPersonality("index.fin"))
  //       dispatch(setPaginateCount(10));
  //     }
  //     else if(option==="DEVPUB NOTES"){
  //       dispatch(setPersonality("index.dev"))
  //       dispatch(setPaginateCount(10));
  //     }else if(option==="ECONOMY NOTES"){
  //       dispatch(setPersonality("database.econ"))
  //       dispatch(setPaginateCount(15));
  //     }else if(option==="SECTOR NOTES"){
  //       dispatch(setPersonality("database.sec"))
  //       dispatch(setPaginateCount(15));
  //     }else if(option==="WIKI FACTOIDS"){
  //       dispatch(setPersonality("wiki.wiki"))
  //     }
  //   }
  // },[option])

  const changePersonality = (item) => {
    const selectedPersonality = personalities.find((list) => list.value === item)
    dispatch(setPersonality(selectedPersonality))
  }

  const onClick = async (chat) => {
    const { id, chat_doc_url, file_names } = chat;
    dispatch(setCancelApi(true))
    onClose(false)
    setEditClicked(null)
    dispatch(setHistoryLoading(true));
    dispatch(setGenerateMore(false))
    dispatch(setNewChat(false));
    dispatch(setDocurl(chat_doc_url));
    const data = {
      chat_id: id,
    };
    if (token !== undefined || tokenSso !== undefined) {
      try {
        const response = await _chatHistoryContents(data, token || tokenSso);
        const history = response?.data?.data?.message;
        if (Array.isArray(history) && history?.length) {
          dispatch(setPreviousText(history[history?.length - 1]?.question))
        }
        dispatch(setLoading(true));

        dispatch(clearChat());
        if (file_names?.length) {
          dispatch(
            addChatResponse({
              files: file_names,
              sender: "user",
            })
          );
        }
        for (const item of history) {
          const question = {};
          const answer = {};
          question.message = item?.question;
          question.sender = "user";
          answer.message = item?.answer;
          answer.sender = "bot";
          answer.search_query = item?.search_query;
          answer.org = item?.additional_data?.org_names;
          answer.countries = item?.additional_data?.countries;
          answer.regions = item?.additional_data?.regions;
          answer.major_concepts =
            item?.additional_data?.major_concepts;
          answer.id = item?.id;
          answer.references = item?.references;
          answer.indicators = item?.indicators
          answer.is_answer_links_valid = item?.is_answer_links_valid || false;
          if (item?.chat_responses[0]?.like_dislike === true) {
            answer.like = true;
            answer.dislike = false;
          } else if (item?.chat_responses[0]?.like_dislike === false) {
            answer.like = false;
            answer.dislike = true;
          } else if (item?.chatResponse?.length === 0) {
            answer.like = false;
            answer.dislike = false;
          }
          dispatch(setConversationId(item?.chat?.client_conversation_id));
          dispatch(setTabId(item?.chat?.id));
          item.chat.category = item.chat.category ? item.chat.category : 'devpubs'
          changePersonality(item?.chat?.category)
          if (relatedArticlesCategory[item?.chat?.category] && item?.search_query) {
            try {
              const form = new FormData();
              if (item?.chat?.category) {
                form.append("category", relatedArticlesCategory[item?.chat?.category]);
              }
              form.append("qterm", item?.search_query);
              if (
                Array.isArray(answer.org) &&
                answer.org?.length > 0
              ) {
                form.append("org_name", answer.org?.join("^"));
              }
              if (
                Array.isArray(answer.countries) &&
                answer.countries?.length > 0
              ) {
                form.append("country", answer.countries?.join("^"));
              }
              if (Array.isArray(answer.regions) && answer.regions?.length > 0) {
                form.append("region", answer.regions?.join("^"));
              }
              if (
                Array.isArray(answer.major_concepts) &&
                answer.major_concepts?.length > 0
              ) {
                form.append("topic", answer.major_concepts?.join("^"));
              }
              form.append("rows", 10);
              form.append("skip", 0);
              const searchData = await _chatRelatedSearch(form, token || tokenSso);
              answer.search_results = searchData?.data?.data?.items;
              answer.facets = searchData?.data?.data?.facets
              answer.total_results = searchData?.data?.data?.total_results;
            } catch (error) {
              console.log(error, "error");
            }
          }
          dispatch(addChatResponse(question));
          dispatch(addChatResponse(answer));
        }
        // });
        dispatch(setLoading(false));
        dispatch(setHistoryLoading(false));
      } catch (error) {
        console.log(error);
        dispatch(setLoading(false));
        dispatch(setHistoryLoading(false));
      }
    }
  };

  const getHistory = async () => {
    setLoadingHistory(true);
    try {
      if (token !== undefined || tokenSso !== undefined) {
        const chatData = await _getHistory(token || tokenSso);
        dispatch(getChatHistory(chatData?.data?.data));
        setLoadingHistory(false);
      }
    } catch (error) {
      console.log(error, "error");
      setLoadingHistory(false);
    }
  };

  const editChatHistory = (id) => {
    if (title !== "") {
      const data = {
        "chat_id": id,
        "title": title
      }
      try {
        if (token !== undefined || tokenSso !== undefined) {
          return _editChatHistory(token || tokenSso, data).then(async () => {
            const chatData = await _getHistory(token || tokenSso);
            dispatch(getChatHistory(chatData?.data?.data));
            const filteredArray = otherHistory?.filter((item) => item?.id !== id);
            setOtherHistory(filteredArray)
            setEditClicked(null)
          })
        }
      } catch (error) {
        console.log(error);
        setEditClicked(null)
      }
    }
  }

  const deleteChatHistory = (id) => {
    try {
      if (token !== undefined || tokenSso !== undefined) {
        return _deleteChatHistory(id, token || tokenSso).then(async () => {
          const chatData = await _getHistory(token || tokenSso);
          dispatch(getChatHistory(chatData?.data?.data));
          const filteredArray = otherHistory?.filter((item) => item?.id !== id);
          setOtherHistory(filteredArray)
        })
      }
    } catch (error) {
      console.log(error);
    }
  }

  const incrementPage = () => {
    setPage((prevPage) => prevPage + 10);
  };

  const getAllHistory = async () => {
    if (token !== undefined || tokenSso !== undefined) {
      try {
        const allHistoryData = await _getHistoryOther(token || tokenSso, page, search);
        setTotalResults(allHistoryData?.data?.data?.count);
        setOtherHistory([
          ...otherHistory,
          ...allHistoryData?.data?.data?.results,
        ]);
        setNext(allHistoryData?.data?.data?.next)
      } catch (error) {
        console.log(error);
      }
    }
  };



  useEffect(() => {
    getHistory();
  }, []);


  useEffect(() => {
    if (page < totalResults) {
      getAllHistory();
    }
  }, [page]);


  const handleChange = async () => {
    if (search !== "") {
      if (token !== undefined || tokenSso !== undefined) {
        try {
          const allHistoryData = await _getHistoryOther(token || tokenSso, 0, search);
          setTotalResults(allHistoryData?.data?.data?.count);
          setOtherHistory(allHistoryData?.data?.data?.results);
          setNext(allHistoryData?.data?.data?.next)
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      setPage(0)
      if (token !== undefined) {
        try {
          const allHistoryData = await _getHistoryOther(token, page, "");
          setTotalResults(allHistoryData?.data?.data?.count);
          setOtherHistory(allHistoryData?.data?.data?.results);
          setNext(allHistoryData?.data?.data?.next)
        } catch (error) {
          console.log(error);
        }
      } else if (tokenSso !== undefined) {
        try {
          const allHistoryData = await _getHistoryOther(tokenSso, page, "");
          setTotalResults(allHistoryData?.data?.data?.count);
          setOtherHistory(allHistoryData?.data?.data?.results);
          setNext(allHistoryData?.data?.data?.next)
        } catch (error) {
          console.log(error);
        }
      }
    }
  }



  useEffect(() => {
    handleChange()
  }, [search])

  const onEditClick = (item, text) => {
    setEditClicked(item);
    setTitle(text);
  };
  return (
    <div className="previous-chat-section">
      <div className="previous-chat-text">Previous Chats</div>
      {loadingHistory ? (
        <div className="chat-side-loader-middle">
          <div className="loader loader-3">
            <div className="dot dot1">&nbsp;</div>
            <div className="dot dot2">&nbsp;</div>
            <div className="dot dot3">&nbsp;</div>
          </div>
        </div>

      ) : historyResponse?.today?.length +
        historyResponse?.yesterday?.length +
        historyResponse?.this_week?.length +
        otherHistory?.length ===
        0 && !loadingHistory ? (
        <div className="no-history-box text-center">
          <h3>No chat history found</h3>
          {/* <p>Lorem Ipsum is simply dummy text of
            the printing and typesetting industry</p> */}
        </div>
      ) : (
        <div className="previous-chat-boxes" id="scrollableDiv">

          {/* <Scrollbar id="scrollableDiv"
            onYReachEnd={incrementPage}
            speed={0.8}
            always
          > */}
          <InfiniteScroll dataLength={otherHistory?.length}
            hasMore={hasMore}
            next={() => {
              incrementPage()
            }}
            scrollableTarget="scrollableDiv"
          >
            {today && today?.length > 0 ? (
              <div className="previous-chat-box">
                <h3>Today</h3>
                {today?.map((item) => (
                  <div className="side-query-row d-flex justify-content-between align-items-center">
                    {editClicked === item?.id ? (
                      <div className="d-flex side-query-edit-row">
                        <div className="query-edit-input">
                          <input
                            type="text"
                            value={title}
                            onChange={(e) => {
                              setTitle(e.target.value);
                            }}
                            className="side-query-edit-input"
                          />
                        </div>

                        <div className="query-edit-action-icons d-flex align-items-center">
                          <span onClick={() => {
                            editChatHistory(item?.id)
                          }} className="query-edit-action-icon tick-icon">
                            <TickIcon />
                          </span>
                          <span onClick={() => {
                            setEditClicked(null)
                          }} className="query-edit-action-icon close-icon">
                            <CloseIcon />
                          </span>
                        </div>
                      </div>
                    ) : (
                      <>


                        <div
                          onClick={() => {
                            onClick(item);
                          }}
                          className="side-query-text"
                          style={{ "cursor": "pointer" }}
                        >
                          {item?.category === "document" ? (
                            <div className="side-query-icon">
                              <img src={SideQueryDoc} alt="doc" />
                            </div>

                          ) : (
                            <div className="side-query-icon">
                              <img src={SideQueryText} alt="doc" />
                            </div>
                          )}

                          <div className="side-query-text-content">{item?.title}</div>
                        </div>
                        <div className="side-query-more">
                          <Dropdown>
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              className="chat-more-dpdn"
                            >
                                <span data-tooltip-id="SidebarTooltip">
                                  <MoreIcon />
                                </span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => {
                                  onEditClick(item?.id, item?.title);
                                }}
                              >
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item onClick={() => {
                                deleteChatHistory(item?.id)
                              }}>Delete</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            ) : null}
            {yesterday && yesterday?.length > 0 ? (
              <div className="previous-chat-box">
                <h3>Yesterday</h3>
                {yesterday?.map((item) => (
                  <div className="side-query-row d-flex justify-content-between align-items-center">
                    {editClicked === item?.id ? (
                      <div className="d-flex side-query-edit-row">
                        <div className="query-edit-input">
                          <input
                            type="text"
                            value={title}
                            onChange={(e) => {
                              setTitle(e.target.value);
                            }}
                            className="side-query-edit-input"
                          />
                        </div>
                        <div className="query-edit-action-icons d-flex align-items-center">
                          <span onClick={() => {
                            editChatHistory(item?.id)
                          }} className="query-edit-action-icon tick-icon">
                            <TickIcon />
                          </span>
                          <span onClick={() => {
                            setEditClicked(null)
                          }} className="query-edit-action-icon close-icon">
                            <CloseIcon />
                          </span>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div
                          onClick={() => {
                            onClick(item);
                          }}
                          className="side-query-text"
                          style={{ "cursor": "pointer" }}
                        >
                          {item?.category === "document" ? (
                            <div className="side-query-icon">
                              <img src={SideQueryDoc} alt="doc" />
                            </div>

                          ) : (
                            <div className="side-query-icon">
                              <img src={SideQueryText} alt="doc" />
                            </div>
                          )}
                          <div className="side-query-text-content">{item?.title}</div>
                        </div>
                        <div className="side-query-more">
                          <Dropdown>
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              className="chat-more-dpdn"
                            >
                               <span data-tooltip-id="SidebarTooltip">
                                  <MoreIcon />
                                </span>

                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => {
                                  onEditClick(item?.id, item?.title);
                                }}
                              >
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item onClick={() => {
                                deleteChatHistory(item?.id)
                              }}>Delete</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            ) : null}
            {week && week?.length > 0 ? (
              <div className="previous-chat-box">
                <h3>This Week</h3>
                {week?.map((item) => (
                  <div className="side-query-row d-flex justify-content-between align-items-center">
                    {editClicked === item?.id ? (
                      <div className="d-flex side-query-edit-row">
                        <div className="query-edit-input">
                          <input
                            type="text"
                            value={title}
                            onChange={(e) => {
                              setTitle(e.target.value);
                            }}
                            className="side-query-edit-input"
                          />
                        </div>
                        <div className="query-edit-action-icons d-flex align-items-center">
                          <span onClick={() => {
                            editChatHistory(item?.id)
                          }} className="query-edit-action-icon tick-icon">
                            <TickIcon />
                          </span>
                          <span onClick={() => {
                            setEditClicked(null)
                          }} className="query-edit-action-icon close-icon">
                            <CloseIcon />
                          </span>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div
                          onClick={() => {
                            onClick(item);
                          }}
                          className="side-query-text"
                          style={{ "cursor": "pointer" }}
                        >
                          {item?.category === "document" ? (
                            <div className="side-query-icon">
                              <img src={SideQueryDoc} alt="doc" />
                            </div>

                          ) : (
                            <div className="side-query-icon">
                              <img src={SideQueryText} alt="doc" />
                            </div>
                          )}
                          <div className="side-query-text-content">{item?.title}</div>
                        </div>
                        <div className="side-query-more">
                          <Dropdown>
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              className="chat-more-dpdn"
                            >
                              <span data-tooltip-id="SidebarTooltip">
                                  <MoreIcon />
                                </span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => {
                                  onEditClick(item?.id, item?.title);
                                }}
                              >
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item onClick={() => {
                                deleteChatHistory(item?.id)
                              }}>Delete</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            ) : null}
            {otherHistory && otherHistory?.length > 0 ? (
              <div className="previous-chat-box">
                <h3>Older</h3>
                {otherHistory?.map((item) => (
                  <div className="side-query-row d-flex justify-content-between align-items-center">
                    {editClicked === item?.id ? (
                      <div className="d-flex side-query-edit-row">
                        <div className="query-edit-input">
                          <input
                            type="text"
                            value={title}
                            onChange={(e) => {
                              setTitle(e.target.value);
                            }}
                            className="side-query-edit-input"
                          />
                        </div>
                        <div className="query-edit-action-icons d-flex align-items-center">
                          <span onClick={() => {
                            editChatHistory(item?.id)
                          }} className="query-edit-action-icon tick-icon">
                            <TickIcon />
                          </span>
                          <span onClick={() => {
                            setEditClicked(null)
                          }} className="query-edit-action-icon close-icon">
                            <CloseIcon />
                          </span>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div
                          onClick={() => {
                            onClick(item);
                          }}
                          className="side-query-text"
                          style={{ "cursor": "pointer" }}
                        >
                          {item?.category === "document" ? (
                            <div className="side-query-icon">
                              <img src={SideQueryDoc} alt="doc" />
                            </div>

                          ) : (
                            <div className="side-query-icon">
                              <img src={SideQueryText} alt="doc" />
                            </div>
                          )}
                          <div className="side-query-text-content">{item?.title}</div>
                        </div>
                        <div className="side-query-more">
                          <Dropdown>
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              className="chat-more-dpdn"
                            >
                              <span data-tooltip-id="SidebarTooltip">
                                  <MoreIcon />
                                </span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => {
                                  onEditClick(item?.id, item?.title);
                                }}
                              >
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item onClick={() => {
                                deleteChatHistory(item?.id)
                              }}>Delete</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            ) : null}
          </InfiniteScroll>
          {/* </Scrollbar> */}
        </div>
      )}
       <ReactTooltip
        id="SidebarTooltip"
        place="top"
        content="Options"
      />
    </div>
  );
}

export default PreviousChat;
