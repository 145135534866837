import { applyMiddleware, compose, createStore } from "redux";
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware from "redux-thunk"
import appReducer from "../reducers";

const persistConfig = {
    key: 'kush.ai',
    storage
};

const persistedReducer = persistReducer(
    persistConfig,
    appReducer
);

function configureStore(initialState) {
    const enhancer =
      (typeof window !== 'undefined' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
      compose;
      return createStore(
        persistedReducer,
        initialState,
        enhancer(applyMiddleware(thunkMiddleware))
      );
}


export const store = configureStore({});
export const persistor = persistStore(store);


