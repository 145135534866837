import {createAction,handleActions} from 'redux-actions';
import ActionTypes from '~shared/constants/actionTypes';
import {_staticCount } from '../../../shared/httpservice/apis';


const initialState = {
  

  static_count:{ loading: false, response: {}, hasError: false, error: {} }
  

}







export const staticCountStart = createAction(ActionTypes.STATIC_COUNT);
export const staticCountSuccess = createAction(ActionTypes.STATIC_COUNT_SUCCESS,response=>response);
export const staticCountError = createAction(ActionTypes.STATIC_COUNT_ERROR)

export const staticCount=()=>async (dispatch)=>{
    dispatch( staticCountStart());
    try {
      const response = await _staticCount();
      dispatch(staticCountSuccess(response));
      // console.log(response);
    } catch (error) {
      dispatch(staticCountError(error));
    }
  }



const reducer = handleActions({
 

  [ActionTypes.STATIC_COUNT]: (state) => ({
    ...state,
    static_count: {
        ...state. static_count,
        loading: true, hasError: false, error: {}
    }
}),
[ActionTypes.STATIC_COUNT_SUCCESS]: (state, action) => ({
    ...state,
    static_count: {
        ...state.  static_count,
        response: action.payload, loading: false, hasError: false, error: {}
    },

}),
[ActionTypes.STATIC_COUNT_ERROR]: (state, action) => ({
    ...state,
    static_count: {
        ...state.  static_count,
        response: {}, loading: false, hasError: true, error: action.payload
    },

}),
  
 
},initialState);

export default reducer;





