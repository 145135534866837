import React, { useState, useEffect, useCallback, useRef } from "react";
import { Container,Button } from "react-bootstrap";
import Footer from "../../../shared/footer";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Form from "react-bootstrap/Form";
import {
  listFaqs,
  addFaq,
  setSavedFaqTab,
  listFaqsChat,
  setIsFeedBack,
  setSearch
} from "./../services";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import SearchEngineFaq from "../components/searchengine";
import AiChatFaq from "../components/aichat";
function HelpAndSupport() {
  const dispatch = useDispatch();
  const isFeedBack = useSelector((state) => state?.faq?.feedBack, shallowEqual);
  const searchFaq = useSelector((state) => state?.faq?.search, shallowEqual);
  useEffect(() => {
    if (isFeedBack) {
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
      dispatch(setIsFeedBack(false));
    }
  }, []);

  const active = useSelector((state) => state?.faq?.saved_tab);
  const token = useSelector(
    (store) => store?.auth?.signin?.response?.data?.data?.token,
    shallowEqual
  );
  const tokenSso = useSelector(
    (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token,
    shallowEqual
  );
  const result = useSelector(
    (state) => state?.faq?.list_faq?.response?.data?.data?.results,
    shallowEqual
  );
  const data = useSelector(
    (state) => state?.faq?.list_faq?.response?.data?.data,
    shallowEqual
  );
  const loading = useSelector((state) => state?.faq?.list_faq?.loading);
  const add_faq_state = useSelector((state) => state?.faq?.add_faq);
  const add_faq_state_ref = useRef(add_faq_state);
  add_faq_state_ref.current = add_faq_state;

  const [offset, setOffset] = useState(0);

  const [searchValueChat, setSearchValueChat] = useState("");
  const [searchValueSearch, setSearchValueSearch] = useState("");
  const onSearchAi = (e) => {
    const value = e.target.value;
    if (value) {
      if (token !== undefined) {
        dispatch(listFaqsChat(token, value, offset));
      } else if (tokenSso !== undefined) {
        dispatch(listFaqsChat(tokenSso, value, offset));
      }
      setSearchValueChat(value);
      dispatch(setSearch(value))
    } else {
      if (token !== undefined) {
        dispatch(listFaqsChat(token, "", 0));
        setSearchValueChat("");
        dispatch(setSearch(""))
      } else if (tokenSso !== undefined) {
        dispatch(listFaqsChat(tokenSso, "", 0));
        setSearchValueChat("");
        dispatch(setSearch(""))
      }
    }
  };

  const onClear = () =>{
    if (token !== undefined) {
      dispatch(listFaqsChat(token, "", 0));
      setSearchValueChat("");
      dispatch(setSearch(""))
    } else if (tokenSso !== undefined) {
      dispatch(listFaqsChat(tokenSso, "", 0));
      setSearchValueChat("");
      dispatch(setSearch(""))
    }
  }

  const onSearchEngine = (e) => {
    const value = e.target.value;
    if (value) {
      if (token !== undefined) {
        dispatch(listFaqs(token, value, offset));
      } else if (tokenSso !== undefined) {
        dispatch(listFaqs(tokenSso, value, offset));
      }
      setSearchValueSearch(value);
    } else {
      if (token !== undefined) {
        dispatch(listFaqs(token, "", 0));
        setSearchValueSearch("");
      } else if (tokenSso !== undefined) {
        dispatch(listFaqs(tokenSso, "", 0));
        setSearchValueSearch("");
      }
    }
  };

  const handleSelect = (key) => {
    if (key === "SeFaq") {
      dispatch(setSavedFaqTab("SeFaq"));
    } else if (key === "AiFaq") {
      dispatch(setSavedFaqTab("AiFaq"));
    }
  };

  return (
    <div className="help-and-support-wrap m-t-30">
      <Helmet>
        <title>DevDiscovery | Help And Support</title>
      </Helmet>
      <Container>
        <div className="whitebox p-30">
          <div className="faq-header">
            <div className="d-flex align-items-center">
              <h4>Frequently Asked Questions</h4>
              {active === "SeFaq" || active === "" ? (
                <div className="faq-header-right">
                  <Form
                    className="w-100"
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div className="search-input-wrap p-relative">
                      <Form.Control
                        type="text"
                        placeholder="Search"
                        className="input-item"
                        onChange={onSearchEngine}
                        value={searchValueSearch}
                      />
                      <span className="search-svg-wrap">
                        <svg
                          width="15"
                          height="14"
                          viewBox="0 0 15 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.9355 14L9.99193 10.6633C9.50806 11.0678 8.94355 11.3828 8.29839 11.6083C7.65323 11.8339 6.96774 11.9467 6.24194 11.9467C4.5 11.9467 3.02419 11.3633 1.81452 10.1967C0.604839 9.03 0 7.62222 0 5.97333C0 4.32444 0.604839 2.91667 1.81452 1.75C3.02419 0.583333 4.49194 0 6.21774 0C7.92742 0 9.38306 0.583333 10.5847 1.75C11.7863 2.91667 12.3871 4.32444 12.3871 5.97333C12.3871 6.64222 12.2742 7.28778 12.0484 7.91C11.8226 8.53222 11.4839 9.11556 11.0323 9.66L15 12.9733L13.9355 14ZM6.21774 10.5467C7.52419 10.5467 8.6371 10.0994 9.55645 9.205C10.4758 8.31055 10.9355 7.23333 10.9355 5.97333C10.9355 4.71333 10.4758 3.63611 9.55645 2.74167C8.6371 1.84722 7.52419 1.4 6.21774 1.4C4.89516 1.4 3.77016 1.84722 2.84274 2.74167C1.91532 3.63611 1.45161 4.71333 1.45161 5.97333C1.45161 7.23333 1.91532 8.31055 2.84274 9.205C3.77016 10.0994 4.89516 10.5467 6.21774 10.5467Z"
                            fill="#A5A5A5"
                          />
                        </svg>
                      </span>
                    </div>
                  </Form>
                </div>
              ) : (
                active === "AiFaq" && (
                  <>
                   <div className="faq-header-right">
                    <Form
                      className="w-100"
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <div className="search-input-wrap p-relative">
                        <Form.Control
                          type="text"
                          placeholder="Search"
                          className="input-item"
                          onChange={onSearchAi}
                          value={searchFaq}
                        />
                        <span className="search-svg-wrap">
                          <svg
                            width="15"
                            height="14"
                            viewBox="0 0 15 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.9355 14L9.99193 10.6633C9.50806 11.0678 8.94355 11.3828 8.29839 11.6083C7.65323 11.8339 6.96774 11.9467 6.24194 11.9467C4.5 11.9467 3.02419 11.3633 1.81452 10.1967C0.604839 9.03 0 7.62222 0 5.97333C0 4.32444 0.604839 2.91667 1.81452 1.75C3.02419 0.583333 4.49194 0 6.21774 0C7.92742 0 9.38306 0.583333 10.5847 1.75C11.7863 2.91667 12.3871 4.32444 12.3871 5.97333C12.3871 6.64222 12.2742 7.28778 12.0484 7.91C11.8226 8.53222 11.4839 9.11556 11.0323 9.66L15 12.9733L13.9355 14ZM6.21774 10.5467C7.52419 10.5467 8.6371 10.0994 9.55645 9.205C10.4758 8.31055 10.9355 7.23333 10.9355 5.97333C10.9355 4.71333 10.4758 3.63611 9.55645 2.74167C8.6371 1.84722 7.52419 1.4 6.21774 1.4C4.89516 1.4 3.77016 1.84722 2.84274 2.74167C1.91532 3.63611 1.45161 4.71333 1.45161 5.97333C1.45161 7.23333 1.91532 8.31055 2.84274 9.205C3.77016 10.0994 4.89516 10.5467 6.21774 10.5467Z"
                              fill="#A5A5A5"
                            />
                          </svg>
                        </span>
                      </div>
                    </Form>
                  </div>
                  {/* {active==="AiFaq"&&searchFaq!==""?<Button size="sm" onClick={onClear}>clear</Button>:null} */}
                  </>
                )
              )}
            </div>
          </div>
          <div className="faq-tab-wrap">
            <Tabs
              // defaultActiveKey="SeFaq"
              activeKey={active !== "" ? active : "SeFaq"}
              id="justify-tab-example"
              onSelect={handleSelect}
            >
              <Tab eventKey="SeFaq" title="Search">
                <SearchEngineFaq search={searchValueSearch} />
              </Tab>
              <Tab eventKey="AiFaq" title="Chat">
                <AiChatFaq search={searchValueChat} />
              </Tab>
            </Tabs>
          </div>
        </div>
      </Container>
      <Footer />
      <ToastContainer />
    </div>
  );
}

export default HelpAndSupport;
