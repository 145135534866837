import React,{useEffect} from 'react';
import LandContactUs from '../components/landcontactus/landcontactus';
import LandSection1 from '../components/landsection1/landsection1';
import LandSection2 from '../components/landsection2/landsection2';
import LandSection3 from '../components/landsection3/landsection3';
import LandSection4 from '../components/landsection4/landsection4';
import LandSection5 from '../components/landsection5/landsection5';
import LandSection6 from '../components/landsection6/landsection6';
import LandSection7 from '../components/landsection7/landsection7';
import LandSection8 from '../components/landsection8/landsection8';
import LandSectionAi from '../components/landsectionAi/landsectionAi';
import LandvideoFrame from '../components/landvideoframe/landvideoframe';



function LandingPage() {
   
    return (
        <div className="landing-page-wrap">
            <section className="landing-section section-1">
                <LandSection1 />
            </section>
            <section className="landing-section video-frame-section">
              <LandvideoFrame/>
            </section>   
            <section className="landing-section section-2">
                <LandSection2 />
            </section>
            <section className="landing-section section-3">
                <LandSection3 />
            </section>
            <section className="landing-section section-3">
                <LandSection4 />
            </section>
            <section className="landing-section section-3">
                <LandSectionAi />
            </section>
            <section className="landing-section section-3">
                <LandSection5 />
            </section>
            <section className="landing-section section-3">
                <LandSection6 />
            </section>
            <section className="landing-section section-3">
                <LandSection7 />
            </section>
            <section className="landing-section section-3">
                <LandSection8 />
            </section> 
            <section className="landing-section section-3">
                <LandContactUs />
            </section>
        </div>
    )
}

export default LandingPage;