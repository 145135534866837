import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Logo from '../../assets/images/logo-home.png';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/esm/Button';
import {useNavigate} from 'react-router-dom'
import { isAuthenticated } from "../utils";
import {resetStore} from './../../modules/auth/services';
import {useDispatch,useSelector,shallowEqual} from 'react-redux';
import ProfPic from '../../assets/images/profile-icon.png';
import Dropdown from 'react-bootstrap/Dropdown';
import { userProfileInfo,listNotification } from './../../modules/profile/services';
import ProfileDummy from '../../assets/images/profile-dummy.png'
import mobileMenu from '../../assets/images/icons/mobile-menu.png';


function LandingHeader() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const token = useSelector((store) => store?.auth?.signin?.response?.data?.data?.token, shallowEqual)
    const tokenSso = useSelector(
        (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token, shallowEqual
    );
    const userInfo = useSelector((state) => state?.profile?.userProfile?.response?.data?.data?.results[0])
    const userSignout = async () => {
        // dispatch(userLogOut(token))
        // const response = await _logout(token).then(()=>{
        //     localStorage.clear()
        // });
        // navigate('/');
        localStorage.setItem("status", false)
        dispatch(resetStore())
        localStorage.removeItem("persist:kush.ai");
        localStorage.removeItem("change")
        navigate('/')
        // navigate('/signin')
    }
    useEffect(() => {
        if(isAuthenticated){
            if (token !== undefined) {
                dispatch(userProfileInfo(token))
            } else if (tokenSso !== undefined) {
                dispatch(userProfileInfo(tokenSso))
            }
        }
       

    }, [])
    const listNotifications =()=>{
        if(isAuthenticated){
            if(token!==undefined){
                dispatch(listNotification(token))
            }else if(tokenSso!==undefined){
                dispatch(listNotification(tokenSso))
            }
        }
       
        
    }
    useEffect(()=>{
        listNotifications()
    },[])
    useEffect(() => {
        let interval = setInterval(() => {
            listNotifications()
        }, 15000);
        return () => {
            clearInterval(interval);
        };
    }, []);
    const [isActive, setActive] = useState("false");
    const ToggleClass = () => {
        setActive(!isActive);
    };
    return (
        <div className="landing-header">
            <Container>
                <div className="landing-menu-items d-flex align-items-center justify-content-between">
                    <h1 className="logo"><Link to="/"><img src={Logo} alt="logo" /></Link></h1>
                    <div className={isActive ? "mobilemenu-hidden" : "mobilemenu-active"}>
                    <div className="btn-wrap double-btn d-flex">
                    {isAuthenticated()?<button type="button" className="btn btn-sm btn-primary m-r-15" onClick={()=>{
                        navigate('/chatai')
                    }} >Home</button>:<button type="button" className="btn btn-sm grey-border-btn"
                    onClick={()=>{
                        navigate("/salesenquiry")
                    }}>Contact us</button> }
                    {!isAuthenticated()&&<Button  onClick={()=>{
                        navigate("/signin")
                    }} 
                    className="btn-sm">Sign-in</Button>}
                    {isAuthenticated()&&
                         <Dropdown align="end">
                                    <Dropdown.Toggle variant="success" id="dropdown-basic" className="btn-sm">
                                        {/* <span> <span className="user-img-landing"> <img src={ProfPic} />
                                        </span>Username here</span> */}
                                         <span> <span className="user-img"> <img src={userInfo?.profile_pic_path?userInfo?.profile_pic_path:ProfileDummy} />
                                        </span>
                                        <span className="land-user-name">
                                        {userInfo?.first_name?userInfo?.first_name + " " + userInfo?.last_name:"user"}
                                        </span>
                                        </span>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => {
                                            navigate('/profile')
                                        }}>Profile</Dropdown.Item>
                                        {token!==undefined&&
                                         <Dropdown.Item onClick={() => {
                                            navigate('/changepassword')
                                        }}>Change Password</Dropdown.Item>
                                        }
                                       
                                        <Dropdown.Item onClick={userSignout}>Logout</Dropdown.Item>

                                    </Dropdown.Menu>
                                </Dropdown>}
                   
                    </div>
                    </div>
                    <button onClick={ToggleClass} className="mobile-menu">
                            <img src={mobileMenu} alt="" />
                        </button>
                </div>
            </Container>
        </div>
    )
}

export default LandingHeader;