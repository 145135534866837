import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/esm/Button';
import background from "../../../../../assets/images/getresults-bg.jpg";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import { Tween } from 'react-gsap';


function LandSection3() {
    return (
        <div className="landing-section-3 p-relative transforming-animation-wrap">
            <div className="landing-section-bg landing-section-bg-left" style={{ backgroundImage: `url(${background})` }}>
                &nbsp;
            </div>
            <Container>
                <Row className="bg-over-text land-section-3-row">
                    <Col md={5}>
                        &nbsp;
                    </Col>
                    <Col md={7} className="d-flex">
                        <div className="getresults-content">
                            <h3>Get search results organized in pertinent knowledge categories.</h3>
                            <p className="landing-common-description">We share your passion for development and have worked hard to <br/>understand your knowledge needs.</p>

                        </div>
                    </Col>
                    <Col md={12} className="trigger-animation-wrap">
                        <Tween
                            from={{ x: '48%' }}
                            to={{
                                x: '0px',
                                scrollTrigger: {
                                    trigger: '.animation-section-big-row',
                                    start: 'top 95%',
                                    end: '70% center',
                                    scrub: 3,
                                    markers: false,
                                },
                            }}
                        >
                            {/* <div className="square" style={{ width: '100px', height: '100px', background: '#ccc' }} /> */}

                            <div className="animation-section-big-row">
                                <div className="animation-box-row d-flex">
                                    <div className="animation-box animation-box-1 text-center">
                                        <div className="animation-box-content">
                                            <h4>Financing</h4>
                                            <p>Financing or lending projects from over a dozen leading global and regional development organizations.</p>
                                        </div>
                                    </div>
                                    <div className="animation-box animation-box-2 text-center">
                                        <div className="animation-box-content">
                                            <h4>Publications</h4>
                                            <p>Knowledge and analytical work published by top development organizations and research institutions.</p>
                                        </div>
                                    </div>
                                    <div className="animation-box animation-box-3 text-center">
                                        <div className="animation-box-content">
                                            <h4>Scholarly</h4>
                                            <p>Development focused publications, journal articles, and more from universities and other knowledge creators.</p>
                                        </div>
                                    </div>
                                    <div className="animation-box animation-box-5 text-center">
                                        <div className="animation-box-content">
                                            <h4>Economics</h4>
                                            <p>Economic updates and macroeconomic research published by some of the world’s leading economic research teams.</p>
                                        </div>
                                    </div>
                                    <div className="animation-box animation-box-4 text-center">
                                        <div className="animation-box-content">
                                            <h4>Evaluations</h4>
                                            <p>Development impact evaluations published by researches and impact assessment agencies from across the globe.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tween>
                    </Col>
                </Row>

            </Container>
        </div>
    )
}

export default LandSection3;