import React, { useState,useEffect,useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ServerErrorImg from '../../../assets/images/chang-pswd.png';
import BackArrow from '../../../assets/images/icons/backarrow.svg';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {currentPasswordValidation} from './../../auth/services'
import {_resetPassword} from './../../../shared/httpservice/apis'
import {useDispatch,useSelector,shallowEqual} from 'react-redux'
import { userLogOut, resetStore } from './../../auth/services'
import {Formik, formik} from 'formik'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Eye from "../../../assets/images/icons/eye.png";
import HiddenEye from "../../../assets/images/icons/hidden.png";
import {useNavigate} from 'react-router-dom'
import {Helmet} from 'react-helmet'
function ChangePassword() {
    const navigate = useNavigate()
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
      };
    const [rpasswordShown,setRPasswordShown] = useState(false)  
    const toggleRPassword =()=>{
        setRPasswordShown(!rpasswordShown)
    }
    const [rcpasswordShown,setRcPasswordShown] = useState(false)  
    const toggleRcPassword =()=>{
        setRcPasswordShown(!rcpasswordShown)
    }
    const [currentPass,setCurrentPass] = useState()
    const url = window.location.href;
    const dispatch = useDispatch()
    const token = useSelector((store)=>store?.auth?.signin?.response?.data?.data?.token,shallowEqual)
    const tokenSso = useSelector(
        (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token,
        shallowEqual
      );
    const validation_state = useSelector((state)=>state?.auth?.current_password?.response?.data?.data?.message,shallowEqual)
    const validation_ref = useRef(validation_state)
    validation_ref.current = validation_state
    const userId = useSelector((state)=>state?.profile?.userProfile?.response?.data?.data?.results[0]?.user_id
    )
    const [passwordMessage,setPasswordMessage] = useState("")
    useEffect(()=>{
       if(validation_state) setPasswordMessage(validation_state)
    },[validation_state])
    useEffect(()=>{
        if(passwordMessage==="Correct password"){
            if(!url.includes("change")){
                passwordMessage("")
            }
        }
    },[url])
    
    const handleSubmitPassword = async (values) => {
        let formData = new FormData();
        formData.append("password", values.currentpassword);
        if(token!==undefined){
            dispatch(currentPasswordValidation(token,formData)).then(()=>{
                if(validation_ref.current==="Incorrect password"){
                    toast.error("Incorrect password! Please try again")
                }else {
                    setCurrentPass(values.currentpassword)
                }
            })
        }else if(tokenSso!==undefined){
            dispatch(currentPasswordValidation(tokenSso,formData))
        }
        
        
       
      };

      const userSignout = async () => {
        // dispatch(userLogOut(token))
        // const response = await _logout(token).then(()=>{
        //     localStorage.clear()
        // });
        // navigate('/');
        // localStorage.setItem("status", false)
        
        localStorage.removeItem("persist:kush.ai");
        localStorage.setItem("change",true)
        
        dispatch(resetStore())

            
    }

      const handleSubmit = async (values)=>{
        const formData = new FormData()
        formData.append("new_password",values.password)
        formData.append("current_password",currentPass)
        const data = {
            "password" : values.password,
            "confirm_password" : values.confirmPassword
        }
        if(token!==undefined){
            return _resetPassword(formData,token).then((response)=>{
                toast.success("Password changed succesfully",{
                    hideProgressBar: true
                })
                setTimeout(()=>{
                    userSignout()
                },2000)
                
            }).catch((error)=>{
                toast.error("something went wrong",{
                    hideProgressBar: true
                })
            })
        }else if(tokenSso!==undefined){
            return _resetPassword(userId,formData,tokenSso).then((response)=>{
                toast.success("Password changed succesfully",{
                    hideProgressBar: true
                })
                userSignout()
            }).catch((error)=>{
                toast.error("something went wrong",{
                    hideProgressBar: true
                })
            })
        }
        
    }
    const validate =(values)=>{
        const mediumRegex = new RegExp(
            "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[+!@#$%^&*])(?=.{8,})"
          );
        const errors={}
        if(!values.password){
            errors.password="*required"
        }else if (!mediumRegex.test(values.password)) {
            errors.password =
              "*password must contain atleast 8 characters and must include special characters,uppercases and numbers";
          }
        if(!values.confirmPassword){
            errors.confirmPassword="*required"
        }else if (!mediumRegex.test(values.confirmPassword)) {
            errors.confirmPassword =
              "*password must contain atleast 8 characters and must include special characters,uppercases and numbers";
          }
        if(values.password!==values.confirmPassword){
            errors.password="*password doesnt match"
            errors.confirmPassword="*password doesnt match"
        }
        return errors;
    } 
    return (
        <div className="change-password-wrap">
            <Helmet>
                <title>
                DevDiscovery | Change Password
                </title>
            </Helmet>
            <Container>
                <div className="whitebox p-30">
                    {passwordMessage==="Correct password"?
                     <h3 className="section-title"><Link><span> <img src={BackArrow} onClick={()=>{
                        setPasswordMessage("")
                     }} /></span>Change password</Link></h3>:
                     <h3 className="section-title"> <Link to="/chatai"><span><img src={BackArrow}/></span></Link>Change password</h3>
                     }
                     {/* <h3 className="section-title"> <Link to="/home"><span><img src={BackArrow}/></span></Link>Change password</h3> */}
                    <div className="changepassword-contents">
                        <Row>
                            <Col md={6} className="d-flex align-items-center"> 
                            <Formik
                            onSubmit={handleSubmitPassword}
                            initialValues={{
                                currentpassword: "",
                            }}
                            >
                                {({ handleSubmit, handleChange, values, errors, touched }) => (
                                <Form className={passwordMessage==="Correct password"?"w-100 d-none": "w-100"} onSubmit={handleSubmit}>{/* Add d-none class to hide this*/}
                                    <div className="form-input-wrap m-b-25 p-relative eye-icon-inputwrap">
                                        <Form.Label>Current password</Form.Label>
                                        <Form.Control 
                                        type={passwordShown?"text":"password"}
                                        name='currentpassword' placeholder="* * * * * * * *" className="input-item"
                                        value={values.currentpassword}
                                        onChange={handleChange}
                                        />
                                        {passwordShown ? <span className="eye-hidden" onClick={togglePassword}><img src={HiddenEye} alt=""/></span> : <span className="eye-show" onClick={togglePassword}><img src={Eye} alt=""/></span>}
                                    </div>
                                    <div className="button-wrap">
                                        <Button type="submit">Submit</Button>
                                    </div>
                                </Form>
                                )}
                            </Formik>
                            <Formik
                            initialValues={{
                                password : "",
                                confirmPassword : ""
                            }}
                            onSubmit={handleSubmit}
                            validate={validate}
                            >
                                {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors,
                            touched
                            }) => (
                            
                                <Form className={passwordMessage==="Correct password"?"w-100": "w-100 d-none"}  onSubmit={handleSubmit}>{/* Remove d-none class to view this*/}
                                    <div className="form-input-wrap m-b-25 p-relative eye-icon-inputwrap" >
                                        <Form.Label>New Password</Form.Label>
                                        <Form.Control 
                                        type={rpasswordShown?"text":"password"}
                                        name='password' placeholder="* * * * * * * *" className="input-item"
                                         value={values.password}
                                         onChange={handleChange}
                                        />
                                        {rpasswordShown ? <span className="eye-hidden" onClick={toggleRPassword}><img src={HiddenEye} alt=""/></span> : <span className="eye-show" onClick={toggleRPassword}><img src={Eye} alt=""/></span>}
                                          {errors.password && touched.password ? (
                        <span style={{ color: "red" }}>
                          {errors.password}
                        </span>
                      ) : null}
                                    </div>
                                    <div className="form-input-wrap m-b-25 p-relative eye-icon-inputwrap">
                                        <Form.Label>Confirm Password</Form.Label>
                                        <Form.Control
                                        type={rcpasswordShown?"text":"password"}
                                        name='confirmPassword' placeholder="* * * * * * * *" className="input-item"
                                            value={values.confirmPassword}
                                            onChange={handleChange}
                                        />
                                        {rcpasswordShown ? <span className="eye-hidden" onClick={toggleRcPassword}><img src={HiddenEye} alt=""/></span> : <span className="eye-show" onClick={toggleRcPassword}><img src={Eye} alt=""/></span>}
                                          {errors.confirmPassword && touched.confirmPassword ? (
                        <span style={{ color: "red" }}>
                          {errors.confirmPassword}
                        </span>
                      ) : null}
                                    </div>
                                    <div className="button-wrap">
                                        <Button type="submit">Change My Password</Button>
                                    </div>
                                </Form>
                                )}
                                </Formik>
                            </Col>
                          
                            <Col md={6}>
                                <div className="change-psw-img">
                                    <img src={ServerErrorImg} alt="" />
                                </div>

                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
            <ToastContainer/>
        </div>
    );
};

export default ChangePassword;
