import React, { useState } from "react";
import ChatTextarea from "../components/chattextarea";
import ChatContent from "../components/chatcontent/container";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";

function ChatFeed() {
  const isNewChat = useSelector((state) => state?.chat?.new_chat);
  return (
    // <div className={`chat-feed-wrap h-100 p-relative ${isNewChat? 'newchat-center-fix' : ''}`}>
    <div className='chat-feed-wrap h-100 p-relative'>
      <div className="chat-height-control">
        <ChatContent />
          <ChatTextarea />
      </div>
      <ToastContainer />
    </div>
  );
}

export default ChatFeed;
