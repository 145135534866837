import {createAction,handleActions} from 'redux-actions';
import ActionTypes from '~shared/constants/actionTypes';
import {
_ssoLogin,_ssoLoginCallback,_signIn,_logout,_profileUpdate,_token,_countryList, _topicList,_currentPasswordCheck,
_regionList,_signUpVerify
} from '~shared/httpservice/apis';

// import {store} from  '../../../redux/store';

const initialState = {
    sso_login:{ loading: false, response: {}, hasError: false, error: {} },
    sso_login_callback:{ loading: false, response: {}, hasError: false, error: {} },
    signin : { loading: false, response: {}, hasError: false, error: {} },
    logout : { loading: false, response: {}, hasError: false, error: {} },
    profile_update : { loading: false, response: {}, hasError: false, error: {} },
    sectors : { loading: false, response: {}, hasError: false, error: {} },
    country : { loading: false, response: {}, hasError: false, error: {} },
    region : { loading: false, response: {}, hasError: false, error: {} },
    topic : { loading: false, response: {}, hasError: false, error: {} },
    current_password : { loading: false, response: {}, hasError: false, error: {} },
    verify_invite : { loading: false, response: {}, hasError: false, error: {} },
}

export const resetStore = () => {
    return {
        type: ActionTypes.RESET_STORE
    }
}

export const ssoLoginStart = createAction(ActionTypes.SSO_LOGIN);
export const ssoLoginSuccess = createAction(ActionTypes.SSO_LOGIN_SUCCESS, response => response);
export const ssoLoginError = createAction(ActionTypes.SSO_LOGIN_ERROR, error => error);

export const ssoLogin = (data) => (dispatch) => {
    dispatch(ssoLoginStart());
    return _ssoLogin(data).then((response) => {
        dispatch(ssoLoginSuccess(response));
        if(response?.data?.data !==null){
            
            window.location.href = response?.data?.data?.url
        }
    }).catch((error) => {
        dispatch(ssoLoginError(error))
    });
};

export const ssoLoginCallBackStart = createAction(ActionTypes.SIGNIN);
export const ssoLoginCallBackSuccess = createAction(ActionTypes.SIGNIN_SUCCESS, response=>response);
export const ssoLoginCallBackError = createAction(ActionTypes.SIGNIN_ERROR, error=>error)

export const ssoLoginCallBack =(code) =>(dispatch) =>{
    dispatch(ssoLoginCallBackStart());
    return _ssoLoginCallback(code).then((response)=>{
        dispatch(ssoLoginCallBackSuccess(response));
        if(response?.data?.data?.profile_set===true){
            window.location.href = '/home'
        }else {
            window.location.href = '/enterdetails'
        }

    }).catch((error)=>{
        dispatch(ssoLoginCallBackError(error))
    })
};

export const signinStart = createAction(ActionTypes.USER_SIGNIN);
export const signinSuccess = createAction(ActionTypes.USER_SIGNIN_SUCCESS, response=>response);
export const signinError= createAction(ActionTypes.USER_SIGNIN_ERROR, error=>error)

export const userSignIn =(data)=>async (dispatch)=>{
    dispatch(signinStart());
    return _signIn(data).then((response)=>{
        dispatch(signinSuccess(response))
    }).catch((error)=>{
        dispatch(signinError(error))
    })
}

export const userLogOutStart = createAction(ActionTypes.USER_LOGOUT);
export const userLogOutSuccess = createAction(ActionTypes.USER_LOGOUT_SUCCESS);
export const userLogOutError = createAction(ActionTypes.USER_LOGOUT_ERROR);

export const userLogOut =(token)=>(dispatch)=>{
    dispatch(userLogOutStart());
    return _logout(token).then((response)=>{
        dispatch(userLogOutSuccess(response))
        if(response)localStorage.clear()
    }).catch((error)=>{
        dispatch(userLogOutError(error))
    })
}

export const profileUpdateStart = createAction(ActionTypes.PROFILE_UPDATE);
export const profileUpdateSuccess = createAction(ActionTypes.PROFILE_UPDATE_SUCCESS);
export const profileUpdateError = createAction(ActionTypes.PROFILE_UPDATE_ERROR)

export const profileUpdate =(token,data)=>(dispatch)=>{
    dispatch(profileUpdateStart());
    return _profileUpdate(token,data).then((response)=>{
        dispatch(profileUpdateSuccess(response))
    }).catch((error)=>{
        dispatch(profileUpdateError(error))
    })
}

export const listSectorsStart = createAction(ActionTypes.LIST_SECTORS)
export const listSectorsSuccess = createAction(ActionTypes.LIST_SECTORS_SUCCESS)
export const listSectorsError = createAction(ActionTypes.LIST_SECTORS_ERROR)

export const listSector =(token)=>(dispatch)=>{
    dispatch(listSectorsStart())
    return _token(token).then((response)=>{
        dispatch(listSectorsSuccess(response))
    }).catch((error)=>{
        dispatch(listSectorsError(error))
    })
}

export const listCountryStart = createAction(ActionTypes.LIST_COUNTRY)
export const listCountrySuccess = createAction(ActionTypes.LIST_COUNTRY_SUCCESS)
export const listCountryError = createAction(ActionTypes.LIST_COUNTRY_ERROR)

export const listCountry =(token)=>(dispatch)=>{
    dispatch(listCountryStart())
    return _countryList(token).then((response)=>{
        dispatch(listCountrySuccess(response))
    }).catch((error)=>{
        dispatch(listCountryError(error))
    })
}

export const listTopicStart = createAction(ActionTypes.LIST_TOPIC)
export const listTopicSuccess = createAction(ActionTypes.LIST_TOPIC_SUCCESS)
export const listTopicError = createAction(ActionTypes.LIST_TOPIC_ERROR)

export const listTopic =(token)=>(dispatch)=>{
    dispatch(listTopicStart())
    return _topicList(token).then((response)=>{
        dispatch(listTopicSuccess(response))
    }).catch((error)=>{
        dispatch(listTopicError(error))
    })
}

export const listRegionStart = createAction(ActionTypes.LIST_REGION)
export const listRegionSuccess = createAction(ActionTypes.LIST_REGION_SUCCESS)
export const listRegionError = createAction(ActionTypes.LIST_REGION_ERROR)

export const listRegion =(token)=>(dispatch)=>{
    dispatch(listRegionStart())
    return _regionList(token).then((response)=>{
        dispatch(listRegionSuccess(response))
    }).catch((error)=>{
        dispatch(listRegionError(error))
    })
}

export const currentPasswordStart = createAction(ActionTypes.CURRENT_PASSWORD)
export const currentPasswordSuccess = createAction(ActionTypes.CURRENT_PASSWORD_SUCCESS)
export const currentPasswordError = createAction(ActionTypes.CURRENT_PASSWORD_ERROR)

export const currentPasswordValidation =(token,data)=>(dispatch)=>{
    dispatch(currentPasswordStart())
    return _currentPasswordCheck(token,data).then((response)=>{
        dispatch(currentPasswordSuccess(response))
    }).catch((error)=>{
        dispatch(currentPasswordError(error))
    })
}

export const verifyInviteStart = createAction(ActionTypes.VERIFY_INVITE)
export const verifyInviteSuccess = createAction(ActionTypes.VERIFY_INVITE_SUCCESS)
export const verifyInviteError = createAction(ActionTypes.VERIFY_INVITE_ERROR)

export const verifyInvite =(code)=>(dispatch)=>{
    dispatch(verifyInviteStart())
    return _signUpVerify(code).then((response)=>{
        dispatch(verifyInviteSuccess(response))
    }).catch((error)=>{
        dispatch(verifyInviteError(error))
    })
}


const reducer = handleActions({
    [ActionTypes.SSO_LOGIN]: (state) => ({
        ...state,
        sso_login: {
            ...state. sso_login,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.SSO_LOGIN_SUCCESS]: (state, action) => ({
        ...state,
        sso_login: {
            ...state.  sso_login,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.SSO_LOGIN_ERROR]: (state, action) => ({
        ...state,
        sso_login: {
            ...state.  sso_login,
            response: {}, loading: false, hasError: true, error: action.payload
        },

    }),
    [ActionTypes.SIGNIN]: (state) => ({
        ...state,
        sso_login_callback: {
            ...state. sso_login_callback,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.SIGNIN_SUCCESS]: (state, action) => ({
        ...state,
        sso_login_callback: {
            ...state. sso_login_callback  ,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.SIGNIN_ERROR]: (state, action) => ({
        ...state,
        sso_login_callback: {
            ...state.  sso_login_callback,
            response: {}, loading: false, hasError: true, error: action.payload
        },

    }),
    [ActionTypes.USER_SIGNIN]:(state)=>({
        ...state,
        signin : {
            ...state.signin,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.USER_SIGNIN_SUCCESS]: (state, action) => ({
        ...state,
        signin: {
            ...state. signin  ,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.USER_SIGNIN_ERROR]: (state, action) => ({
        ...state,
        signin: {
            ...state.  signin,
            response: {}, loading: false, hasError: true, error: action.payload
        },
    }),

    [ActionTypes.USER_LOGOUT]:(state)=>({
        ...state,
        logout : {
            ...state.logout,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.USER_LOGOUT_SUCCESS]: (state, action) => ({
        ...state,
        logout: {
            ...state. logout  ,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.USER_LOGOUT_ERROR]: (state, action) => ({
        ...state,
        logout: {
            ...state.  logout,
            response: {}, loading: false, hasError: true, error: action.payload
        },
    }),
    [ActionTypes.PROFILE_UPDATE]:(state)=>({
        ...state,
        profile_update : {
            ...state.profile_update,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.PROFILE_UPDATE_SUCCESS]: (state, action) => ({
        ...state,
        profile_update: {
            ...state. profile_update  ,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.PROFILE_UPDATE_ERROR]: (state, action) => ({
        ...state,
        profile_update: {
            ...state.  profile_update,
            response: {}, loading: false, hasError: true, error: action.payload
        },
    }),
    [ActionTypes.LIST_SECTORS]:(state)=>({
        ...state,
        sectors : {
            ...state.sectors,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.LIST_SECTORS_SUCCESS]: (state, action) => ({
        ...state,
        sectors: {
            ...state. sectors  ,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.LIST_SECTORS_ERROR]: (state, action) => ({
        ...state,
        sectors: {
            ...state.  sectors,
            response: {}, loading: false, hasError: true, error: action.payload
        },
    }),

    [ActionTypes.LIST_COUNTRY]:(state)=>({
        ...state,
        country : {
            ...state.country,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.LIST_COUNTRY_SUCCESS]: (state, action) => ({
        ...state,
        country: {
            ...state. country  ,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.LIST_COUNTRY_ERROR]: (state, action) => ({
        ...state,
        country: {
            ...state.  country,
            response: {}, loading: false, hasError: true, error: action.payload
        },
    }),
    [ActionTypes.LIST_TOPIC]:(state)=>({
        ...state,
        topic : {
            ...state.topic,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.LIST_TOPIC_SUCCESS]: (state, action) => ({
        ...state,
        topic: {
            ...state. topic  ,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.LIST_TOPIC_ERROR]: (state, action) => ({
        ...state,
        topic: {
            ...state.  topic,
            response: {}, loading: false, hasError: true, error: action.payload
        },
    }),
    [ActionTypes.LIST_REGION]:(state)=>({
        ...state,
        region : {
            ...state.region,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.LIST_REGION_SUCCESS]: (state, action) => ({
        ...state,
        region: {
            ...state. region  ,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.LIST_REGION_ERROR]: (state, action) => ({
        ...state,
        region: {
            ...state.  region,
            response: {}, loading: false, hasError: true, error: action.payload
        },
    }),
    [ActionTypes.CURRENT_PASSWORD]:(state)=>({
        ...state,
        current_password : {
            ...state.current_password,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.CURRENT_PASSWORD_SUCCESS]: (state, action) => ({
        ...state,
        current_password: {
            ...state. current_password  ,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.CURRENT_PASSWORD_ERROR]: (state, action) => ({
        ...state,
        current_password: {
            ...state.  current_password,
            response: {}, loading: false, hasError: true, error: action.payload
        },
    }),

    [ActionTypes.VERIFY_INVITE]:(state)=>({
        ...state,
        verify_invite : {
            ...state.verify_invite,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.VERIFY_INVITE_SUCCESS]: (state, action) => ({
        ...state,
        verify_invite: {
            ...state. verify_invite  ,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.VERIFY_INVITE_ERROR]: (state, action) => ({
        ...state,
        verify_invite: {
            ...state.  verify_invite,
            response: {}, loading: false, hasError: true, error: action.payload
        },
    }),

},initialState);

export default reducer;

