import React from 'react';
import PropTypes from 'prop-types';
import LandingHeader from '../landingheader/landingheader';
// import LandingFooter from '../landingfooter/landingfooter';
// import Header from '../header/index';

const LandingPageWrapper = (props) => {
    return (
        <div className="landing-page-main-wrapper">
            <LandingHeader />
            <div className="page-contents">
                {props.children}
            </div>
            {/* <LandingFooter/> */}
        </div>
    )
}

export default LandingPageWrapper;