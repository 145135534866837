import React, { useEffect, useState } from 'react';
import { Link,NavLink } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import Twitter from '../../assets/images/icons/twitter.svg';
import LinkedIn from '../../assets/images/icons/linkedin.svg';
import Logo from '../../assets/images/logo-home.png';


function LandingFooter() {
    const [isActive,setIsActive] = useState()
    const url = window.location.href
    const currentYear = new Date().getFullYear();
    useEffect(()=>{
        if(url?.includes("about")){
            setIsActive("about")
        }else if(url?.includes("careers")){
            setIsActive("careers")
        }else if(url?.includes("privacy")){
            setIsActive("privacy")
        }else if(url?.includes("terms")){
            setIsActive("terms")
        }else if(url?.includes("sales")){
            setIsActive("sales")
        }
    },[url]) 
    return (
        <div className="land-footer-wrap">
            <Container>
                <div className="d-flex land-footer-content justify-content-between">
                    <div className="footer-left">
                        <Row>
                        <Col md={5} lg={5}>
                                <h4 className="footer-title">Company</h4>
                                <ul className="footer-menu-list">
                                    <li><NavLink  to="/aboutus" onClick={()=>{
                                        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
                                        setIsActive("about")
                                    }}>About Us</NavLink></li>
                                    <li><NavLink  to="/careers" onClick={()=>{
                                        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
                                        setIsActive("careers")
                                    }}>Careers</NavLink></li>
                                    <li><NavLink to="/privacynotice" onClick={()=>{
                                        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
                                        setIsActive("privacy")
                                    }}>Privacy Notice</NavLink></li>
                                    <li><NavLink to="/termsofuse" onClick={()=>{
                                        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
                                        setIsActive("terms")
                                    }}>Terms of use</NavLink></li>
                                </ul>
                            </Col>
                            <Col md={5} lg={5}>
                                <h4 className="footer-title">Contact</h4>
                                <ul className="footer-menu-list">
                                    <li><NavLink to="/salesenquiry" onClick={()=>{
                                        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
                                        setIsActive("sales")
                                    }}>Sales Enquiry</NavLink></li>
                                </ul>
                            </Col>
                        </Row>
                    </div>
                    <div className="footer-right">
                        <h1 className="logo"><Link to="/" onClick={()=>{
                                        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
                                        setIsActive("sales")
                                    }}><img src={Logo} alt="logo" /></Link></h1>
                        <p className="copyright-text">© {currentYear} KushAI LLC. All rights reserved.</p>
                        <div className="social-icons">
                            <a href="https://twitter.com/DevDiscovery_" target="_blank"><img src={Twitter} alt="Twitter" /></a>
                            <a href="https://www.linkedin.com/company/devdiscovery/" target="_blank"><img src={LinkedIn} alt="LinkedIn" /></a>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default LandingFooter;