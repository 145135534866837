import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';



function TermsContent() {

    return (
        <div className="termsofuse-content">
            <div className="terms-sections">
                <Container>
                    <div className="terms-header">
                        <h3 className="terms-title">VERSION 1.0</h3>
                        <p>Last Updated: September 1, 2022</p>
                    </div>
                    <div className='terms-contents'>
                        <p>The website located at www.devdiscovery.com (the <b>“Site”</b>) is a copyrighted work belonging to KushAI LLC. <b>(“Company”, “us”, “our”, and “we”)</b>. Certain features of the Site may be subject to additional guidelines, terms, or rules,
                            which will be posted on the Site in connection with such features. All such additional terms, guidelines, and rules are incorporated by reference into these Terms.</p>
                        <p>
                            THESE TERMS OF USE (THESE <b>“TERMS”</b>) SET FORTH THE LEGALLY BINDING TERMS AND CONDITIONS THAT GOVERN YOUR USE OF THE SITE. BY ACCESSING OR USING THE SITE, YOU ARE ACCEPTING THESE TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT), AND YOU
                            REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, AUTHORITY, AND CAPACITY TO ENTER INTO THESE TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT). YOU MAY NOT ACCESS OR USE THE SITE OR ACCEPT THE TERMS IF YOU ARE NOT AT LEAST 18 YEARS OLD. IF YOU DO
                            NOT AGREE WITH ALL OF THE PROVISIONS OF THESE TERMS, DO NOT ACCESS AND/OR USE THE SITE.</p>
                        <p>THESE TERMS REQUIRE THE USE OF ARBITRATION (SECTION 8.2) ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS, AND ALSO LIMIT THE REMEDIES AVAILABLE TO YOU IN THE
                            EVENT OF A DISPUTE.</p>
                    </div>
                </Container>
            </div>
            <div className="terms-sections terms-gray-sec">
                <Container>
                    <div className="terms-sub-header">
                        <h3 className="terms-title"><span>1.</span> ACCOUNTS</h3>
                    </div>
                    <div className='terms-contents'>
                        <p><span className="terms-content-sticking">1.1. Account Creation.</span> To use certain features of the Site, you may be required to register for an account (“Account”) and provide certain information about
                            yourself as prompted by the account registration form. You represent and warrant that: (a) all required registration information you submit is truthful and accurate; (b) you will maintain the accuracy of such information.</p>

                        <p><span className="terms-content-sticking">1.2. Account Responsibilities.</span> You are responsible for maintaining the confidentiality of your Account login information and are fully responsible for all activities that occur under your Account. You agree to immediately notify
                            Company of any unauthorized use, or suspected unauthorized use of your Account or any other breach of security. Company cannot and will not be liable for any loss or damage arising from your failure to comply with the above requirements.</p>
                    </div>
                </Container>
            </div>
            <div className="terms-sections">
                <Container>
                    <div className="terms-sub-header">
                        <h3 className="terms-title"><span>2.</span>ACCESS TO THE SITE</h3>
                    </div>
                    <div className='terms-contents'>
                        <p><span className="terms-content-sticking">2.1. License.</span> Subject to these Terms, Company grants you a non-transferable,
                            non-exclusive, revocable, limited license to use and access the Site solely for
                            your or for use for purposes defined by your sponsor.</p>
                        <p>
                            <span className="terms-content-sticking">2.2. Certain Restrictions.</span> The rights granted to you in these Terms are subject
                            to the following restrictions: (a) you shall not license, sell, rent, lease, transfer,
                            assign, distribute, host, or otherwise commercially exploit the Site, whether in
                            whole or in part, or any content displayed on the Site; (b) you shall not modify,
                            make derivative works of, disassemble, reverse compile or reverse engineer
                            any part of the Site; (c) you shall not access the Site in order to build a similar
                            or competitive website, product, or service; and (d) except as expressly stated
                            herein, no part of the Site may be copied, reproduced, distributed,
                            republished, downloaded, displayed, posted or transmitted in any form or by
                            any means. Unless otherwise indicated, any future release, update, or other
                            addition to functionality of the Site shall be subject to these Terms. All
                            copyright and other proprietary notices on the Site (or on any content
                            displayed on the Site) must be retained on all copies thereof.</p>
                        <p>
                            <span className="terms-content-sticking">2.3. Modification.</span> Company reserves the right, at any time, to modify, the Site
                            (in whole or in part) with or without notice to you. You agree that Company
                            will not be liable to you or to any third party for any modification of the Site or
                            any part thereof.</p>
                        <p>
                            <span className="terms-content-sticking">2.4. No Support or Maintenance.</span> You acknowledge and agree that Company
                            will have no obligation to provide you with any support or maintenance in
                            connection with the Site.</p>
                        <p>
                            <span className="terms-content-sticking">2.5. Ownership.</span> You acknowledge that all the intellectual property rights,
                            including copyrights, patents, trademarks, and trade secrets, in the Site and
                            its content are owned by Company or Company’s suppliers. Neither these
                            Terms (nor your access to the Site) transfers to you or any third party any
                            rights, title, or interest in or to such intellectual property rights, except for the
                            limited access rights expressly set forth in Section 2.1. Company and its
                            suppliers reserve all rights not granted in these Terms. There are no implied
                            licenses granted under these Terms.</p>
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default TermsContent;