import React, { useEffect, useState,useRef } from 'react';
import Form from 'react-bootstrap/Form';
import CloseSm from '../../assets/images/icons/close-sm.svg';
import ScrollArea from "react-scrollbar";
import Button from 'react-bootstrap/esm/Button';
import DummyThumb from '../../assets/images/src-dummy.jpg'
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { useSelector,useDispatch,shallowEqual } from "react-redux";
import {shareSingleQuery,usersListQueryShared,shareRecords,userListRecordShared} from './../../modules/results/services'
import { shareMultipleQuery,savedQueriesList } from '../../modules/profile/services';
import { Formik } from "formik";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function MultipleQueryPopup(props) {
    const result = useSelector((state)=>state?.results?.userListShareQuery?.response?.data?.data?.element)

    const share_state = useSelector((state)=>state?.profile?.shareMultipleQuery)
    const share_ref = useRef(share_state)

    share_ref.current = share_state

    const message = useSelector((state)=>state)

    
    const [userList,setUserList] = useState()
   
    const dispatch = useDispatch()
    const token = useSelector((store)=>store?.auth?.signin?.response?.data?.data?.token,shallowEqual)
    const tokenSso = useSelector(
        (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token,shallowEqual
      );

   
    useEffect(()=>{
        if(result){
            const userOptions = result.map((item) => ({
                value: item.id,
                label: item.first_name + " "+ item.last_name
              }));
              setUserList(userOptions)
        }
        
    },[result])  
    const [select,setSelect] = useState([])  
    const userMap = select.map((item) => ({ value: item.value }));

    var userValues = userMap.map((i) => {
      return i.value;
    });

    const savedQueries =()=>{
      if(token!==undefined){
      dispatch(savedQueriesList(token,"",props?.offset))
      }else if(tokenSso!==undefined){
          dispatch(savedQueriesList(tokenSso,"",props?.offset)) 
      }
  }
    const Option = (props) => {
        return (
          <div>
            <components.Option {...props}>
              <input
                className="react-multi-select-item"
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null}
              />{" "}
              <label>{props.label}</label>
            </components.Option>
          </div>
        );
      };

      const handleSubmit =()=>{

            const data ={
                "users": userValues,
                "queries": props.rowId
            }
            if(token!==undefined){
                dispatch(shareMultipleQuery(data,token)).then(()=>{
                    if(!share_ref.current?.hasError){
                      toast.success("Query shared successfully")
                      dispatch(savedQueries())
                      props.CountryhandleCloses()
                    }else{
                      toast.error("Query sharing failed")
                    }
                 
                  
                })
            }else if(tokenSso!==undefined){
                dispatch(shareMultipleQuery(data,tokenSso)).then(()=>{
                  if(!share_ref.current?.hasError){
                    toast.success("Query shared successfully")
                    dispatch(savedQueries())
                    props.CountryhandleCloses()
                  }else{
                    toast.error("Query sharing failed")
                  }
                })
            }
      }
      

    

     
    return (
        <div className="country-pop-items manage-access-popup">
            <div className="country-filter">
               
                
                <div className="selected-view-box-share">
                    {/* <h4 className="share-title">Share</h4> */}
                    {/* <Formik
                    onSubmit={handleSubmit}
                    >
                         {({ handleSubmit, handleChange, values, errors, touched }) => ( */}
                    <Form className="w-100" >
                        <div className="country-header-search">
                          
                            <div className="form-input-wrap m-b-30">
                                <Form.Label>Username</Form.Label>
                                <ReactSelect
                        options={userList}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                          Option,
                        }}
                        allowSelectAll={true}
                        classNamePrefix="react-select-2-option"
                        className="react-multi-select"
                        placeholder='Search and select'
                        selected={select}
                        onChange={setSelect}
                        name="userlist"
                        required
                      />

                            </div>
                           
                        </div>
                        <div className="button-wrap d-flex justify-content-end m-t-20">
                    <Button type='submit' disabled={select?.length===0} onClick={(e)=>{
                        e.preventDefault()
                        handleSubmit()
                        props?.onChangeShare(true)
                        props.CountryhandleCloses()
                    }}>Share</Button>
                </div>
                    </Form>
                       
                    {/* </Formik> */}
                  

                </div>
               
            </div> 
            <ToastContainer/>
        </div>
    )
}

export default MultipleQueryPopup;