import React, { useState,useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { tableData } from './dummyTableData';
import Form from 'react-bootstrap/Form';
import CustomPagination from '../../../shared/pagination/pagination';
import { Container } from 'react-bootstrap';
import { components } from "react-select";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { default as ReactSelect } from "react-select";
import BackArrow from '../../../assets/images/icons/backarrow.svg';
import userImg from '../../../assets/images/avatar-dummy.png';
import ProfieDummy from '../../../assets/images/profile-dummy.png'
import { Link } from 'react-router-dom';
import {setViewAll, UserListSaveQuery} from './../../results/services'
import {useSelector,useDispatch,shallowEqual,} from 'react-redux'
import {listCountry,listRegion} from './../../auth/services'
import {Helmet} from 'react-helmet'
import Paginate from '../../../shared/dataTablePagination';

function ViewAllMembers() {
    const token = useSelector((store)=>store?.auth?.signin?.response?.data?.data?.token,shallowEqual)
    const tokenSso = useSelector(
        (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token,shallowEqual
      )

    const users = useSelector((state)=>state?.results?.userListShareQuery?.response?.data?.data?.results) 
    const loading = useSelector((state)=>state?.results?.userListShareQuery?.loading) 
    const count = useSelector((state)=>state?.results?.userListShareQuery?.response?.data?.data?.count)  
    const country = useSelector((store)=>store?.auth?.country?.response?.data?.data?.element,shallowEqual)
    const region = useSelector((store)=>store?.auth?.region?.response?.data?.data?.element,shallowEqual)
    const dispatch = useDispatch()
    const [search,setSearch] = useState("")
    const [offset,setOffset] = useState(0)
    // console.log(offset);
    const [currentPage,setCurrentPage] = useState(0)
    // console.log(currentPage,"currentpage");
    const handlePage =(data)=>{
        setOffset((data?.selected)*10)
        setCurrentPage(data?.selected)
      }
    const [countryOptions,setCountryOptions] = useState([])
    useEffect(()=>{
        if(country){
            const countryOptions = country.map((item)=>({
                label : item.name,
                value : item.code
            }));
            setCountryOptions(countryOptions)
        }
    },[country])
    const [regionOptions,setRegionOptions] = useState([])
    useEffect(()=>{
        if(region){
            const regionOptions = region.map((item)=>({
                label : item.name,
                value : item.id
            }))
            setRegionOptions(regionOptions)
        }
    },[region])
    const [countrySelect,setCountrySelect] = useState([])
   
    const newCountry = countrySelect.map((item) => ({ value: item.value }));
    var countryValues = newCountry.map((i) => {
      return i.value;
    });
  
    let csv = countryValues.map(e => JSON.stringify(e)).join(",")
    const [regionSelect,setRegionSelect] = useState([])

    const newRegion = regionSelect.map((item) => ({ value: item.value }));
    var regionValues = newRegion.map((i) => {
      return i.value;
    });
   
    let csr = regionValues.map(e => JSON.stringify(e)).join(",")
    const userList =()=>{
        if(token!==undefined){
            dispatch(UserListSaveQuery(token,search,"",countryValues,regionValues,"True",offset))
        }else if(tokenSso!==undefined){
            dispatch(UserListSaveQuery(tokenSso,search,"",countryValues,regionValues,"True",offset))
        }
       }
    useEffect(()=>{
        userList()
    },[offset])
   

    useEffect(()=>{
        userList()
        setCurrentPage(0)
        setOffset(0)
      },[csr,csv,search])
      
      useEffect(()=>{
        if(users){
            setData(users)
        }
    },[users])

    const listCountries=()=>{
        if(token!==undefined){
            dispatch(listCountry(token))
        }else if(tokenSso!==undefined){
            dispatch(listCountry(tokenSso))
        }
        
      }
      const listRegions =()=>{
        if(token!==undefined){
            dispatch(listRegion(token))
        }else if(tokenSso!==undefined){
            dispatch(listRegion(tokenSso))
        }
        
      }
      useEffect(() => {
        { listCountries() ,listRegions()}
      }, []);
    // useEffect(()=>{
    //     if(countryValues&&regionValues){
    //         if(token!==undefined){
    //             dispatch(UserListSaveQuery(token,search,"",countryValues,regionValues,"True",offset))
    //         }else if(tokenSso!==undefined){
    //             dispatch(UserListSaveQuery(tokenSso,"","",countryValues,regionValues,"True",offset))
    //         }
    //     }
    // },[csv,offset])

    //   useEffect(()=>{
    //     if(regionValues&&countryValues){
    //         if(token!==undefined){
    //             dispatch(UserListSaveQuery(token,search,"",countryValues,regionValues,"True",offset))
    //         }else if(tokenSso!==undefined){
    //             dispatch(UserListSaveQuery(tokenSso,search,"",countryValues,regionValues,"True",offset))
    //         }
    //     }
        
    //   },[csr,offset])


    const Option = (props) => {
        return (
            <div>
                <components.Option {...props}>
                    <input
                        className="react-multi-select-item"
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };

    


    const tableColumns = [
        {
            name: 'Name',
            selector: row => `${ row.first_name } ${ row.last_name }`,
            sortable: true,
            center: false,
            cell: row => 
            <div className="d-flex align-items-center membername-cell">
                <span className="user-img"><img src={row.profile_pic_path?row.profile_pic_path:ProfieDummy} alt="user_image"/></span><h4>{row.first_name} {row.last_name}</h4>
            </div>,
            grow: 3
        },
        {
            name: 'Email',
            selector: row=>row.email,
            sortable: true,
            center: false,
            grow: 3,
            cell: row => <span>{row.email}</span>,
        },
        {
            name: 'Job title',
            selector: row=>row.designation,
            sortable: true,
            center: false,
            cell: row => <span>{row.designation}</span>,
            grow: 2,
        },
        {
            name: 'Sector',
            selector: row=>row.pref_sector,
            sortable: true,
            center: false,
            cell: row =>row.pref_sector.map((item)=>(
                <span data-tag="allowRowEvents" className='sector-item' >{`${item}`}</span>
            )),
            grow: 4,
        },

    ];

    const [data, setData] = useState([]);
    const onSearchValueChange = (e) => {
        const value = e.target.value
        if(value){
            if(token!==undefined){
                dispatch(UserListSaveQuery(token,value,"",countryValues,regionValues,"True"))
            }
            else if(tokenSso!==undefined){
                dispatch(UserListSaveQuery(tokenSso,value,"",countryValues,regionValues,"True"))
            }
           
        }else{
            if(token!==undefined){
                dispatch(UserListSaveQuery(token,"","",countryValues,regionValues,"True"))
            }else if(tokenSso!==undefined){
                dispatch(UserListSaveQuery(tokenSso,"","",countryValues,regionValues,"True"))
            }
            
        }
      };



    return (
        <div className="viewallmembers-wrap">
            <Helmet>
                <title>
                DevDiscovery | Organisational Members
                </title>
            </Helmet>
            <Container>
                <div className="whitebox p-30">
                    <div className="datatable-wrap">
                        <div className="backarrow-wrap d-flex align-items-center m-b-30">
                            <Link to="/profile"><span><img src={BackArrow}/></span></Link><h4>Organisation Members</h4>
                        </div>
                        <div className="viewallmembers-header m-b-20">
                            <Row>
                                <Col lg={6}>
                                    <Form className="w-100" onSubmit={(e)=>{e.preventDefault()}}>
                                        <div className="search-input-wrap p-relative">
                                            <Form.Control type="text" placeholder="Search" className="input-item" value={search} onChange={(e)=>{
                                                onSearchValueChange(e),
                                                setSearch(e.target.value)
                                            }}/>
                                            <span className="search-svg-wrap">
                                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.9355 14L9.99193 10.6633C9.50806 11.0678 8.94355 11.3828 8.29839 11.6083C7.65323 11.8339 6.96774 11.9467 6.24194 11.9467C4.5 11.9467 3.02419 11.3633 1.81452 10.1967C0.604839 9.03 0 7.62222 0 5.97333C0 4.32444 0.604839 2.91667 1.81452 1.75C3.02419 0.583333 4.49194 0 6.21774 0C7.92742 0 9.38306 0.583333 10.5847 1.75C11.7863 2.91667 12.3871 4.32444 12.3871 5.97333C12.3871 6.64222 12.2742 7.28778 12.0484 7.91C11.8226 8.53222 11.4839 9.11556 11.0323 9.66L15 12.9733L13.9355 14ZM6.21774 10.5467C7.52419 10.5467 8.6371 10.0994 9.55645 9.205C10.4758 8.31055 10.9355 7.23333 10.9355 5.97333C10.9355 4.71333 10.4758 3.63611 9.55645 2.74167C8.6371 1.84722 7.52419 1.4 6.21774 1.4C4.89516 1.4 3.77016 1.84722 2.84274 2.74167C1.91532 3.63611 1.45161 4.71333 1.45161 5.97333C1.45161 7.23333 1.91532 8.31055 2.84274 9.205C3.77016 10.0994 4.89516 10.5467 6.21774 10.5467Z" fill="#A5A5A5" />
                                                </svg>
                                            </span>
                                        </div>
                                    </Form>
                                </Col>
                                <Col lg={6}>
                                    <Row>
                                        <Col lg={6} className="mebers-select-wrap">
                                            <ReactSelect
                                                options={countryOptions}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                components={{
                                                    Option,
                                                }}
                                                allowSelectAll={true}
                                                classNamePrefix="react-select-2-option"
                                                className="react-multi-select"
                                                name="country"
                                                required
                                                placeholder="Countries of interest"
                                                selected={countrySelect}
                                                onChange={setCountrySelect}
                                                value={countrySelect}
                                                blurInputOnSelect={false}

                                            />
                                        </Col>
                                        <Col lg={6} className="mebers-select-wrap">
                                            <ReactSelect
                                                options={regionOptions}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                components={{
                                                    Option,
                                                }}
                                                allowSelectAll={true}
                                                classNamePrefix="react-select-2-option"
                                                className="react-multi-select"
                                                name="sector"
                                                placeholder="Regions of interest"
                                                required
                                                selected={regionSelect}
                                                onChange={setRegionSelect}
                                                value={regionSelect}
                                                blurInputOnSelect={false}
                                            /></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
            <div className="data-table-responsive">
            <DataTable
                data={data}
                columns={tableColumns}
                center={true}
                noHeader={true}
                noDataComponent={loading?<div className="loader-middle-align">
                <div className="loader loader-3">
                  <div className="dot dot1">&nbsp;</div>
                  <div className="dot dot2">&nbsp;</div>
                  <div className="dot dot3">&nbsp;</div>
                </div></div>:<div className="loader-middle-align">No organisational members found.</div>}
            />
            {/* <CustomPagination /> */}
            {data?.length>0&&
             <Paginate
             pageCount={(Math.ceil(count / 10))}
             onPageChange={handlePage}
             initialPage={currentPage}/>
            }
           
        </div>
                        

                    </div>
                </div>
            </Container>
        </div>
    );
};

export default ViewAllMembers;
