import {createAction,handleActions} from 'redux-actions';
import ActionTypes from '~shared/constants/actionTypes';
import {
_listFaq,_addFaq,_listFaqChat
} from '~shared/httpservice/apis';

// import {store} from  '../../../redux/store';

const initialState = {
    list_faq : { loading: false, response: {}, hasError: false, error: {} },
    list_faq_chat : { loading: false, response: {}, hasError: false, error: {} },
    add_faq : {loading: false, response: {}, hasError: false, error: {} },
    saved_tab: "",
    feedBack : false,
    search : ""
   }

export const listFaqStart = createAction(ActionTypes.LIST_FAQ)
export const listFaqSuccess = createAction(ActionTypes.LIST_FAQ_SUCCESS)
export const listFaqError = createAction(ActionTypes.LIST_FAQ_ERROR)

export const listFaqs = (token,value,page) =>(dispatch)=>{
    dispatch(listFaqStart())
    return _listFaq(token,value,page).then((response)=>{
        dispatch(listFaqSuccess(response))
    }).catch((error)=>{
        dispatch(listFaqError(error))
    })
}

export const listFaqChatStart = createAction(ActionTypes.LIST_FAQ_CHAT)
export const listFaqChatSuccess = createAction(ActionTypes.LIST_FAQ_SUCCESS_CHAT)
export const listFaqChatError = createAction(ActionTypes.LIST_FAQ_ERROR_CHAT)

export const listFaqsChat = (token,value,page) =>(dispatch)=>{
    dispatch(listFaqChatStart())
    return _listFaqChat(token,value,page).then((response)=>{
        dispatch(listFaqChatSuccess(response))
    }).catch((error)=>{
        dispatch(listFaqChatError(error))
    })
}

export const addFaqStart = createAction(ActionTypes.ADD_FAQ)
export const addFaqSuccess = createAction(ActionTypes.ADD_FAQ_SUCCESS)
export const addFaqError = createAction(ActionTypes.ADD_FAQ_ERROR)

export const addFaq = (token,data) =>(dispatch)=>{
    dispatch(addFaqStart())
    return _addFaq(token,data).then((response)=>{
        dispatch(addFaqSuccess(response))
    }).catch((error)=>{
        dispatch(addFaqError(error))
    })
}

const setSavedTabFaqAction = createAction(ActionTypes.SET_SAVED_TAB_FAQ)
export const setSavedFaqTab =(data)=>(dispatch)=>{
  dispatch(setSavedTabFaqAction(data))
}

const setIsFeedBackAction = createAction(ActionTypes.SET_IS_FEEDBACK)
export const setIsFeedBack =(data)=>(dispatch)=>{
  dispatch(setIsFeedBackAction(data))
}

const setSearchAction = createAction(ActionTypes.SET_FAQ_SEARCH)
export const setSearch =(data)=>(dispatch)=>{
  dispatch(setSearchAction(data))
}
const reducer = handleActions({
   
    [ActionTypes.LIST_FAQ]:(state)=>({
        ...state,
        list_faq : {
            ...state.list_faq,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.LIST_FAQ_SUCCESS]: (state, action) => ({
        ...state,
        list_faq: {
            ...state.  list_faq,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.LIST_FAQ_ERROR]: (state, action) => ({
        ...state,
        list_faq: {
            ...state. list_faq,
            response: {}, loading: false, hasError: true, error: action.payload
        },
    }),
    [ActionTypes.LIST_FAQ_CHAT]:(state)=>({
        ...state,
        list_faq_chat : {
            ...state.list_faq_chat,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.LIST_FAQ_SUCCESS_CHAT]: (state, action) => ({
        ...state,
        list_faq_chat: {
            ...state.  list_faq_chat,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.LIST_FAQ_ERROR_CHAT]: (state, action) => ({
        ...state,
        list_faq_chat: {
            ...state. list_faq_chat,
            response: {}, loading: false, hasError: true, error: action.payload
        },
    }),
    [ActionTypes.ADD_FAQ]:(state)=>({
        ...state,
        add_faq : {
            ...state.add_faq,
            loading: true, hasError: false, error: {}
        }
    }),
    [ActionTypes.ADD_FAQ_SUCCESS]: (state, action) => ({
        ...state,
        add_faq: {
            ...state. add_faq,
            response: action.payload, loading: false, hasError: false, error: {}
        },

    }),
    [ActionTypes.ADD_FAQ_ERROR]: (state, action) => ({
        ...state,
        add_faq: {
            ...state. add_faq,
            response: {}, loading: false, hasError: true, error: action.payload
        },
    }),
    [ActionTypes.SET_SAVED_TAB_FAQ]: (state, action) => ({
        ...state,
        saved_tab : action.payload,
      }),
    [ActionTypes.SET_IS_FEEDBACK]: (state, action) => ({
        ...state,
        feedBack : action.payload,
      }),
    [ActionTypes.SET_FAQ_SEARCH]: (state, action) => ({
        ...state,
        search : action.payload,
      }),  

},initialState);

export default reducer;

