import React, { useRef, useState } from 'react';

import {  Container } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import ChatSlideLeftImg from '../../../../../assets/images/chat-slide-left-img.png';
import ChatSlideRightImg1 from '../../../../../assets/images/chat-slide-right-img1.png';
import ChatSlideRightImg2 from '../../../../../assets/images/chat-slide-right-img2.png';
import ChatSlideRightImg3 from '../../../../../assets/images/chat-slide-right-img3.png';
import Logo from '../../../../../assets/images/news-home.png';
import ChatSlideTyperIcon from '../../../../../assets/images/icons/chat-slide-typer-area-icon.svg';
import SearchSlideTopImg from '../../../../../assets/images/search-slide-top-img.png';
import SearchSlideLeftImg from '../../../../../assets/images/search-slide-left-img.png';
import SearchSlideRightImg from '../../../../../assets/images/search-slide-right-img.png';
import GenerateGraphImg from '../../../../../assets/images/generate-graph.png';
import GenerateSlideRightImg1 from '../../../../../assets/images/generate-slide-right-img1.png';
import GenerateSlideRightImg2 from '../../../../../assets/images/generate-slide-right-img2.png';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
// import required modules
import { FreeMode, Autoplay, EffectFade, Navigation, Thumbs } from 'swiper/modules';

import Typewriter from 'typewriter-effect';

function LandSection2() {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    return (
        <div className="section landing-section-2" id="read-more">
            <Container className="text-center">
                <h3 className="landing-common-title">Combining the power of curated content and AI.</h3>
                <p className="landing-common-description">We will help you supercharge your research with the most relevant content and the latest in AI technologies.</p>

                <div className="thumb-slider-with-animation mt-5 pt-2 pt-sm-4">
                    <Swiper
                        onSwiper={setThumbsSwiper}
                        spaceBetween={10}
                        slidesPerView={4}
                        freeMode={true}
                        watchSlidesProgress={true}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className="thumb-content-area">
                                <span className="thumb-line"></span>
                                <div className="thumb-cont">
                                    <label>Search</label>
                                    <p>Experience our AI powered search combined with curated content from sources most relevant to you.</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="thumb-content-area">
                                <span className="thumb-line"></span>
                                <div className="thumb-cont">
                                    <label>Chat</label>
                                    <p>Converse with project documents and publications from across development organizations and other sources.</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="thumb-content-area">
                                <span className="thumb-line"></span>
                                <div className="thumb-cont">
                                    <label>Generate</label>
                                    <p>Write economy and sector notes based on latest socio-economy data from a variety of relevant sources.</p>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    <Swiper
                        style={{
                        '--swiper-navigation-color': '#fff',
                        '--swiper-pagination-color': '#fff',
                        }}
                        spaceBetween={10}
                        navigation={false}
                        thumbs={{ swiper: thumbsSwiper }}
                        modules={[FreeMode, Autoplay, EffectFade, Navigation, Thumbs]}
                        effect={'fade'}
                        autoplay={{
                            delay: 5500,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true,
                          }}
                        className="mySwiper2"
                    >
                        
                        <SwiperSlide>
                            <div className="top-slide">
                                 <img className="img-fluid search-slide-top-img" src={SearchSlideTopImg}/>
                            </div>
                            <div className="row">
                                <div className="col-4 left-slide pe-0 pe-sm-2">
                                  <img className="img-fluid search-slide-left-img" src={SearchSlideLeftImg}/> 
                                </div>
                                <div className="col-8 right-slide">
                                    <div className="search-slide-typer-area">
                                        <img className="img-fluid" src={SearchSlideRightImg}/>                  
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="row">
                                <div className="col-4 col-sm-4 left-slide pe-0 pe-sm-2">
                                    <img className="img-fluid chat-slide-left-img" src={ChatSlideLeftImg}/>
                                </div>
                                <div className="col-8 col-sm-8 right-slide d-flex flex-column justify-content-between">
                                    <div className="d-flex flex-column h-100">
                                        <img className="img-fluid chat-slide-right-img1 mb-3" src={ChatSlideRightImg1}/>
                                        <div className="chat-slide-typer-area d-flex h-100">
                                            <div className="d-none d-sm-block">
                                                <img width="23" className="" src={Logo}/>
                                            </div>
                                            <div className="col">
                                                <Typewriter
                                                    options={{
                                                        strings: ['Here are some lessons learned from maternal health projects: 1. Improving access to healthcare services: Maternal health projects ...'],
                                                        autoStart: true,
                                                        loop: true,
                                                    }}
                                                />
                                            </div>
                                            <div className="d-none d-sm-block">
                                                <img width="13" className="" src={ChatSlideTyperIcon}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <img className="img-fluid chat-slide-right-img2 mt-4 mb-3" src={ChatSlideRightImg2}/>
                                        <img className="img-fluid chat-slide-right-img3" src={ChatSlideRightImg3}/>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="row">
                                <div className="col-4 col-sm-4 left-slide pe-0 pe-sm-2">
                                    <div className="general-slide-left-area">
                                        <img className="img-fluid" src={ChatSlideLeftImg}/>
                                    </div>
                                </div>
                                <div className="col-8 col-sm-8 right-slide d-flex flex-column justify-content-between">
                                    <div className="d-flex flex-column h-100">
                                        <img className="img-fluid chat-slide-right-img1 mb-3" src={GenerateSlideRightImg1}/>
                                        <div className="chat-slide-typer-area d-flex h-100 p-0 align-items-center">
                                            {/* <div className="d-none d-sm-block">
                                                <img width="23" className="" src={Logo}/>
                                            </div> */}
                                            <div className="">
                                                <img className="img-fluid" src={GenerateGraphImg}/>
                                            </div>
                                            {/* <div className="d-none d-sm-block">
                                                <img width="13" className="" src={ChatSlideTyperIcon}/>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div>
                                        <img className="img-fluid chat-slide-right-img2 mt-4 mb-3" src={GenerateSlideRightImg2}/>
                                        <img className="img-fluid chat-slide-right-img3" src={ChatSlideRightImg3}/>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        
                    </Swiper>

                </div>
            
            </Container>
        </div>
    )
}

export default LandSection2;