import React,{useState,useEffect,useRef} from 'react';
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import {recDowload} from './../../services'
import {useSelector,useDispatch,shallowEqual} from 'react-redux'
import FileDownload from 'js-file-download'
import {_recordDownload} from './../../../../shared/httpservice/apis'
import CountryPopup from "../countrypopup/countrypopup";
import axios from 'axios';
import { Alert, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fileSaver } from 'file-saver';
import { fi } from 'date-fns/locale';
import {Link} from 'react-router-dom'
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import InfoImg from '../../../../assets/images/icons/infoicon.png'

function CatogoryPopup({onClose}) {
    const dispatch = useDispatch()
    const load = useSelector((state)=>state?.results?.record_download?.loading)
    const result_state = useSelector((state)=>state?.results?.record_download)
    const filterServiceExperts = useSelector((state)=>state?.expertsFilter)
    const filterServiceEcon = useSelector((state)=>state?.econFilter)
    const filePathVar = useSelector((state)=>state?.results?.file_var)
    const result_ref = useRef(result_state);
    result_ref.current = result_state;
    const [fin,setFin] = useState(false)
    const [dev,setDev] = useState(false)
    const [other,setOther] = useState(false)
    const [econ,setEcon] = useState(false)
    const [data,setData] = useState(false)
    const [news,setNews] = useState(false)
    const [evals,setEvals] = useState(false)
    const [mul,setMul] = useState(false)
    const [experts,setExperts] = useState(false)
    const [all,setAll] = useState(false)
    const [disable,setDisable] = useState(false)
    const [status,setStatus] = useState(false)
    const [url,setUrl] = useState("")
    const [Countryshow, CountrysetShow] = useState(false);
    const CountryhandleClose = () => CountrysetShow(false);
    const CountryhandleShow = () => CountrysetShow(true);
    //financing checkbox
    const financing = ["Financing"]
    const [financeChecked,setFinanceChecked] = useState([false])
    const handleOnChangeFinance = (position) => {
        const updatedCheckedState = financeChecked.map((item, index) =>
          index === position ? !item : item
        );
        setFinanceChecked(updatedCheckedState);
      };
    //devpubs checkbox
    const devpubs = ["DevPubs"]  
    const [devChecked,setDevChecked] = useState([false])
    const handleOnChangeDev = (position) => {
        const updatedCheckedState = devChecked.map((item, index) =>
          index === position ? !item : item
        );
        setDevChecked(updatedCheckedState);
      };
    //otherpubs checkbox  
    const otherpubs = ["OtherPubs"]
    const [otherChecked,setOtherChecked] = useState([false])
    const handleOnChangeOther = (position) =>{
        const updatedCheckedState = otherChecked.map((item, index) =>
        index === position ? !item : item
      );
      setOtherChecked(updatedCheckedState);
    }
    //data checkbox
    const datapubs = ["Data"]
    const [dataChecked,setDataChecked] = useState([false])
    const handleOnChangeData = (position) =>{
        const updatedCheckedState = dataChecked.map((item, index) =>
        index === position ? !item : item
      );
      setDataChecked(updatedCheckedState);
    }
    //multimedia checkbox
    const multimedia = ["Multimedia"]
    const [mulChecked,setMulChecked] = useState([false])
    const handleOnChangeMul = (position) =>{
        const updatedCheckedState = mulChecked.map((item, index) =>
        index === position ? !item : item
      );
      setMulChecked(updatedCheckedState);
    }
    //news checkbox
    const newspubs = ["News"]
    const [newsChecked,setNewsChecked] = useState([false])
    // console.log(newsChecked[0],"news");
    const handleOnChangeNews = (position) =>{
        const updatedCheckedState = newsChecked.map((item, index) =>
        index === position ? !item : item
      );
      setNewsChecked(updatedCheckedState);
    }
    //evaluations checkbox
    const evalpubs = ["Evaluations"]
    const [evalChecked,setEvalChecked] = useState([false])
    const handleOnChangeEval = (position) =>{
        const updatedCheckedState = evalChecked.map((item, index) =>
        index === position ? !item : item
      );
      setEvalChecked(updatedCheckedState);
    }
    //experts checkbox
    const [dummyChecked,setDummyChecked] = useState(false)
    const expertspubs = ["Experts"]
    const [expertsChecked,setExpertsChecked] = useState([false])
    const handleOnChangeExperts = (position) =>{
        const updatedCheckedState = expertsChecked.map((item, index) =>
        index === position ? !item : item
      );
      setExpertsChecked(updatedCheckedState);
    }
    //econpubs checkbox
    const econpubs = ["EconPubs"]
    const [econChecked,setEconChecked] = useState([false])
    const handleOnChangeEcon = (position) =>{
        const updatedCheckedState = econChecked.map((item, index) =>
        index === position ? !item : item
      );
      setEconChecked(updatedCheckedState);
    }
    //all checkbox
    const allData = ["All"]
    const [allChecked,setAllChecked] = useState([false])
    const handleOnChangeAll = (position) =>{
        const updatedCheckedState = allChecked.map((item, index) =>
        index === position ? !item : item
      );
      setAllChecked(updatedCheckedState);
    }

    
    useEffect(()=>{
        if(allChecked[0]===true){
            setFinanceChecked([true])
            setDevChecked([true])
            setOtherChecked([true])
            setDataChecked([true])
            setMulChecked([true])
            setNewsChecked([true])
            setEvalChecked([true])
            setExpertsChecked([true])
            setEconChecked([true])
        }else if(allChecked[0]===false&&financeChecked[0]===true&&devChecked[0]===true&&otherChecked[0]===true
            &&dataChecked[0]===true&&mulChecked[0]===true&&newsChecked[0]===true&&evalChecked[0]===true
            &&expertsChecked[0]===true&&econChecked[0]===true){
            setFinanceChecked([false])
            setDevChecked([false])
            setOtherChecked([false])
            setDataChecked([false])
            setMulChecked([false])
            setNewsChecked([false])
            setEvalChecked([false])
            setExpertsChecked([false])
            setEconChecked([false])
        }
    },[all])  

    useEffect(()=>{
        if(allChecked[0]===true&&(financeChecked[0]===false)){
            setAllChecked([false])
        }
    },[financeChecked])
    useEffect(()=>{
        if(allChecked[0]===true&&(devChecked[0]===false)){
            setAllChecked([false])
        }
    },[devChecked])
    useEffect(()=>{
        if(allChecked[0]===true&&(otherChecked[0]===false)){
            setAllChecked([false])
        }
    },[otherChecked])
    useEffect(()=>{
        if(allChecked[0]===true&&(econChecked[0]===false)){
            setAllChecked([false])
        }
    },[econChecked])
    useEffect(()=>{
        if(allChecked[0]===true&&(evalChecked[0]===false)){
            setAllChecked([false])
        }
    },[evalChecked])
    useEffect(()=>{
        if(allChecked[0]===true&&(dataChecked[0]===false)){
            setAllChecked([false])
        }
    },[dataChecked])
    useEffect(()=>{
        if(allChecked[0]===true&&(expertsChecked[0]===false)){
            setAllChecked([false])
        }
    },[expertsChecked])
    useEffect(()=>{
        if(allChecked[0]===true&&(mulChecked[0]===false)){
            setAllChecked([false])
        }
    },[mulChecked])
    useEffect(()=>{
        if(allChecked[0]===true&&(newsChecked[0]===false)){
            setAllChecked([false])
        }
    },[newsChecked])

    useEffect(()=>{
        if(status){
            setDisable(false)
        }
    },[status])

    const handleFinancing = () =>{
        setFin(!fin)
    }
    const handleDev = () =>{
        setDev(!dev)        
    }
    const handleOther = () =>{
        setOther(!other)        
    }
    const handleEcon = () =>{
        // if(econCountry===""){
        //     CountryhandleShow()
        //     setDisable(true)
        // }
        setEcon(!econ)        
    }
    const handleNews = () =>{
        setNews(!news)        
    }
    const handleEval = () =>{
        setEvals(!evals)        
    }
    const handleMul = () =>{
        setMul(!mul)        
    }
    const handleData = () =>{
        setData(!data)        
    }
    const handleExperts = () =>{
        setExperts(!experts)
    }
    const handleAll =()=>{    
        setAll(!all)
       
    }
    const token = useSelector(
        (store) => store?.auth?.signin?.response?.data?.data?.token,
        shallowEqual
      );
    const tokenSso = useSelector(
        (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token,
        shallowEqual
      );  
    const qterm = useSelector((state) => state?.results?.search);
    const file = useSelector((state) => state?.results?.file);
    const results_state = useSelector((state) => state?.results);
    const finanacingOrg = results_state?.financeSource
    const financingRegion = results_state?.financeRegion
    const financingCountry = results_state?.financeCountry
    const financingSector = results_state?.financeSector
    const financingTopic = results_state?.financeTopic
    const finanacingSdate = results_state?.financeSdate
    const finanacingEdate = results_state?.financeEdate
    const econCountry = results_state?.econCountry
    const [categoryData,setCategoryData] = useState([])
    // console.log(categoryData,"data category");
    const [uniqueData,setUniqueData] = useState([])
    // console.log(uniqueData,"data");
    useEffect(() => {
        // let uniqueChars = categoryData?.filter((c, index) => {
        //   return categoryData.indexOf(c) === index;
        // });
        // setUniqueData(uniqueChars);
        const unique= [...new Map(categoryData.map(item => [item["category"], item])).values()]
        setUniqueData(unique)
      }, [categoryData]);
    const [dataObject,setDataObject] = useState({})
    useEffect(()=>{
        if(econCountry===""){
            setStatus(false)
        }else{
            setStatus(true)
        }

    },[econCountry])
    useEffect(()=>{
        if(econChecked[0]===true){
            CountryhandleShow()
            // setDisable(true)
        }else if(econChecked[0]===false){
            CountryhandleClose()
        }
    },[econChecked])
    // useEffect(()=>{
    //     if(allChecked[0]===true){
    //         CountryhandleShow()
    //     }else if(allChecked[0]===false&&econChecked[0]===false){
    //         CountryhandleClose()
    //     }
    // },[allChecked,econChecked])
    useEffect(()=>{
        const submitData = {
            "qterm" : qterm,
            "filter" : JSON.stringify(categoryData)
        }
        setDataObject(submitData)
    },[categoryData])

    
    useEffect(()=>{
        if(financeChecked[0]===true){
            setCategoryData([...categoryData,{
                "category" : "financing",
                "org_name" : finanacingOrg,
                "region"   : financingRegion,
                "country" : financingCountry,
                "sector" : financingSector,
                "topic": financingTopic,
                "start_date" : finanacingSdate,
                "end_date" : finanacingEdate


            }
            ])
        }else if(financeChecked[0]===false){
            let filteredArray = categoryData?.filter(item => item?.category !== "financing"
              )
            setCategoryData(filteredArray)
        } 
        
       
    },[financeChecked])
    useEffect(()=>{
        if(devChecked[0]===true){
            setCategoryData([...categoryData,{
                "category" : "devpubs",
                "org_name" : results_state?.devSource,
                "region"   : results_state?.devRegion,
                "country" : results_state?.devCountry,
                "sector" : results_state?.devSector,
                "topic": results_state?.devTopic,
                "start_date" : results_state?.devSdate,
                "end_date" : results_state?.devEdate
            }])
        }else if(devChecked[0]===false){
            let filteredArray = categoryData?.filter(item => item?.category !== "devpubs" )
            setCategoryData(filteredArray)
        }
    },[devChecked])
    useEffect(()=>{
        if(otherChecked[0]===true){
            setCategoryData([...categoryData,{
                "category" : "otherpubs",
                "org_name" : results_state?.otherSource,
                "region"   : results_state?.otherRegion,
                "country" : results_state?.otherCountry,
                "sector" : results_state?.otherSector,
                "topic": results_state?.otherTopic,
                "start_date" : results_state?.otherSdate,
                "end_date" : results_state?.otherEdate,
                
            }])
        }
        else if(otherChecked[0]===false){
            let filteredArray = categoryData?.filter(item => item?.category !== "otherpubs")
            setCategoryData(filteredArray)
        }
    },[otherChecked])
    useEffect(()=>{
        if(econChecked[0]===true&&status){
            setCategoryData([...categoryData,{
                "category" : "econpubs",
                "org_name" : results_state?.econSource,
                "region"   : results_state?.econRegion,
                "country" : results_state?.econCountry,
                "sector" : results_state?.econSector,
                "topic": results_state?.econTopic,
                "start_date" : results_state?.econSdate,
                "end_date" : results_state?.econEdate,
                "ignore_query" : filterServiceEcon?.econ_ignore_query
            }])
        }else if(econChecked[0]===false){
            let filteredArray = categoryData?.filter(item => item?.category !== "econpubs")
            setCategoryData(filteredArray)
        }
    },[econChecked,status,results_state?.econCountry])
    useEffect(()=>{
        if(dataChecked[0]===true){
            setCategoryData([...categoryData,{
                "category" : "data",
                "org_name" : results_state?.dataSource,
                "region"   : results_state?.dataRegion,
                "country" : results_state?.dataCountry,
                "sector" : results_state?.dataSector,
                "topic": results_state?.dataTopic,
                "start_date" : results_state?.dataSdate,
                "end_date" : results_state?.dataEdate,
                "resource_type":results_state?.dataResource
            }])
        }else if(dataChecked[0]===false){
            let filteredArray = categoryData?.filter(item => item?.category !== "data")
            setCategoryData(filteredArray)
        }
    },[dataChecked])
    useEffect(()=>{
        if(newsChecked[0]===true){
            setCategoryData([...categoryData,{
                "category" : "news",
            }])
        }else if(newsChecked[0]===false){
            let filteredArray = categoryData?.filter(item => item?.category !== "news")
            setCategoryData(filteredArray)
        }
    },[newsChecked])
    useEffect(()=>{
        if(evalChecked[0]===true){
            setCategoryData([...categoryData,{
                "category" : "evaluations",
                "org_name" : results_state?.evalSource,
                "region"   : results_state?.evalRegion,
                "country" : results_state?.evalCountry,
                "sector" : results_state?.evalSector,
                "topic": results_state?.evalTopic,
                "start_date" : results_state?.evalSdate,
                "end_date" : results_state?.evalEdate
            }])
        }else if(evalChecked[0]===false){
            let filteredArray = categoryData?.filter(item => item?.category !== "evaluations")
            setCategoryData(filteredArray)
        }
    },[evalChecked])
    useEffect(()=>{
        if(mulChecked[0]===true){
            setCategoryData([...categoryData,{
                "category" : "multimedia",
                "org_name" : results_state?.mulSource,
                "region"   : results_state?.mulRegion,
                "country" : results_state?.mulCountry,
                "sector" : results_state?.mulSector,
                "topic": results_state?.mulTopic,
                "start_date" : results_state?.mulSdate,
                "end_date" : results_state?.mulEdate
            }])
         
        }else if(mulChecked[0]===false){
            let filteredArray = categoryData?.filter(item => item?.category !== "multimedia")
            setCategoryData(filteredArray)
        }
    },[mulChecked[0]])
    useEffect(()=>{
        if(expertsChecked[0]===true){
            setCategoryData([...categoryData,{
                "category" : "experts",
                "org_name" : results_state?.source,
                "region"   : results_state?.region,
                "country" : results_state?.country,
                "sector" : results_state?.sector,
                "topic": results_state?.topic,
                "ignore_query" : filterServiceExperts?.experts_ignore_query
                
            }])
        }else if(expertsChecked[0]===false){
            let filteredArray = categoryData?.filter(item => item?.category !== "experts")
            setCategoryData(filteredArray)
        }
    },[expertsChecked[0]])



    useEffect(()=>{
        if(allChecked[0]===true){
                    setCategoryData([{
                "category" : "financing",
                "org_name" : finanacingOrg,
                "region"   : financingRegion,
                "country" : financingCountry,
                "sector" : financingSector,
                "topic": financingTopic,
                "start_date" : finanacingSdate,
                "end_date" : finanacingEdate


            },
            {
                "category" : "devpubs",
                "org_name" : results_state?.devSource,
                "region"   : results_state?.devRegion,
                "country" : results_state?.devCountry,
                "sector" : results_state?.devSector,
                "topic": results_state?.devTopic,
                "start_date" : results_state?.devSdate,
                "end_date" : results_state?.devEdate
            },
            {
                "category" : "otherpubs",
                "org_name" : results_state?.otherSource,
                "region"   : results_state?.otherRegion,
                "country" : results_state?.otherCountry,
                "sector" : results_state?.otherSector,
                "topic": results_state?.otherTopic,
                "start_date" : results_state?.otherSdate,
                "end_date" : results_state?.otherEdate
            },
            {
                "category" : "econpubs",
                "org_name" : results_state?.econSource,
                "region"   : results_state?.econRegion,
                "country" : results_state?.econCountry,
                "sector" : results_state?.econSector,
                "topic": results_state?.econTopic,
                "start_date" : results_state?.econSdate,
                "end_date" : results_state?.econEdate,
                "ignore_query" : filterServiceEcon?.econ_ignore_query
            },
            {
                "category" : "data",
                "org_name" : results_state?.dataSource,
                "region"   : results_state?.dataRegion,
                "country" : results_state?.dataCountry,
                "sector" : results_state?.dataSector,
                "topic": results_state?.dataTopic,
                "start_date" : results_state?.dataSdate,
                "end_date" : results_state?.dataEdate,
                "resource_type":results_state?.dataResource
            },
            {
                "category" : "news",
            },
            {
                "category" : "evaluations",
                "org_name" : results_state?.evalSource,
                "region"   : results_state?.evalRegion,
                "country" : results_state?.evalCountry,
                "sector" : results_state?.evalSector,
                "topic": results_state?.evalTopic,
                "start_date" : results_state?.evalSdate,
                "end_date" : results_state?.evalEdate
            },
            {
                "category" : "multimedia",
                "org_name" : results_state?.mulSource,
                "region"   : results_state?.mulRegion,
                "country" : results_state?.mulCountry,
                "sector" : results_state?.mulSector,
                "topic": results_state?.mulTopic,
                "start_date" : results_state?.mulSdate,
                "end_date" : results_state?.mulEdate
            },
            {
                "category" : "experts",
                "org_name" : results_state?.source,
                "region"   : results_state?.region,
                "country" : results_state?.country,
                "sector" : results_state?.sector,
                "topic": results_state?.topic,
                "ignore_query" : filterServiceExperts?.experts_ignore_query
            }
        ])
        }
        // else if(allChecked[0]===false){
        //     setCategoryData([])
        // }          
    },[allChecked,results_state?.econCountry])
   

  
    useEffect(()=>{
        if(allChecked[0]===false&&financeChecked[0]===true&&devChecked[0]===true&&
            otherChecked[0]===true&&econChecked[0]===true&&dataChecked[0]===true&&newsChecked[0]===true&&mulChecked[0]===true&&
            evalChecked[0]===true&&expertsChecked[0]===true){
                // setCategoryData(categoryData)
                setCategoryData([{
                    "category" : "financing",
                    "org_name" : finanacingOrg,
                    "region"   : financingRegion,
                    "country" : financingCountry,
                    "sector" : financingSector,
                    "topic": financingTopic,
                    "start_date" : finanacingSdate,
                    "end_date" : finanacingEdate
    
    
                },
                {
                    "category" : "devpubs",
                    "org_name" : results_state?.devSource,
                    "region"   : results_state?.devRegion,
                    "country" : results_state?.devCountry,
                    "sector" : results_state?.devSector,
                    "topic": results_state?.devTopic,
                    "start_date" : results_state?.devSdate,
                    "end_date" : results_state?.devEdate
                },
                {
                    "category" : "otherpubs",
                    "org_name" : results_state?.otherSource,
                    "region"   : results_state?.otherRegion,
                    "country" : results_state?.otherCountry,
                    "sector" : results_state?.otherSector,
                    "topic": results_state?.otherTopic,
                    "start_date" : results_state?.otherSdate,
                    "end_date" : results_state?.otherEdate
                },
                {
                    "category" : "econpubs",
                    "org_name" : results_state?.econSource,
                    "region"   : results_state?.econRegion,
                    "country" : results_state?.econCountry,
                    "sector" : results_state?.econSector,
                    "topic": results_state?.econTopic,
                    "start_date" : results_state?.econSdate,
                    "end_date" : results_state?.econEdate,
                    "ignore_query" : filterServiceEcon?.econ_ignore_query
                },
                {
                    "category" : "data",
                    "org_name" : results_state?.dataSource,
                    "region"   : results_state?.dataRegion,
                    "country" : results_state?.dataCountry,
                    "sector" : results_state?.dataSector,
                    "topic": results_state?.dataTopic,
                    "start_date" : results_state?.dataSdate,
                    "end_date" : results_state?.dataEdate,
                    "resource_type":results_state?.dataResource
                },
                {
                    "category" : "news",
                },
                {
                    "category" : "evaluations",
                    "org_name" : results_state?.evalSource,
                    "region"   : results_state?.evalRegion,
                    "country" : results_state?.evalCountry,
                    "sector" : results_state?.evalSector,
                    "topic": results_state?.evalTopic,
                    "start_date" : results_state?.evalSdate,
                    "end_date" : results_state?.evalEdate
                },
                {
                    "category" : "multimedia",
                    "org_name" : results_state?.mulSource,
                    "region"   : results_state?.mulRegion,
                    "country" : results_state?.mulCountry,
                    "sector" : results_state?.mulSector,
                    "topic": results_state?.mulTopic,
                    "start_date" : results_state?.mulSdate,
                    "end_date" : results_state?.mulEdate
                },
                {
                    "category" : "experts",
                    "org_name" : results_state?.source,
                    "region"   : results_state?.region,
                    "country" : results_state?.country,
                    "sector" : results_state?.sector,
                    "topic": results_state?.topic,
                    "ignore_query" : filterServiceExperts?.experts_ignore_query
                }
            ])
            }
            else if(allChecked[0]===false&&financeChecked[0]===false&&devChecked[0]===false&&
                otherChecked[0]===false&&econChecked[0]===false&&dataChecked[0]===false&&newsChecked[0]===false&&mulChecked[0]===false&&
                evalChecked[0]===false&&expertsChecked[0]===false){
                    setCategoryData([])
                } 
    },[allChecked,financeChecked,devChecked,otherChecked,econChecked,dataChecked,newsChecked,mulChecked,evalChecked,expertsChecked,results_state?.econCountry])

    const [econClose,setEconClose] = useState(false)
    const econClosed =()=>{
        if(results_state?.econCountry===""){
            setEconClose(true)
        }
    }
    const [datas,setDatas] = useState(null)
    // console.log(datas,"data");
    useEffect(()=>{
        if(econClose===true){
            setEconChecked([false])
            setAllChecked([false])
            let filteredArray = uniqueData?.filter(item => item?.category !== "econpubs")
            setDatas(filteredArray)
            setEconClose(false)
        }
    },[econClose])

   
   
    const downloadRecord = () =>{
        const form = new FormData()
        if(file!==""){
            form.append("qfile",filePathVar)
            form.append("is_file",true)
        }
        form.append("qterm",qterm)
        // if(results_state?.econCountry===""){
        //     form.append("filter",JSON.stringify(datas))
        // }else{
        //     form.append("filter",JSON.stringify(uniqueData))
        // }
        form.append("filter",JSON.stringify(uniqueData))
        
        if(categoryData?.length>0){
          if(token!==undefined){
            dispatch(recDowload(token,form)).then(()=>{
                if(!result_ref?.current?.hasError){
                    setUrl(result_ref?.current?.response?.data?.data?.file_url)
                }else{
                    setUrl("")
                    toast.error("Data not found")
                }
            })
          }else if(tokenSso!==undefined){
            dispatch(recDowload(tokenSso,form)).then(()=>{
                if(!result_ref?.current?.hasError){
                    setUrl(result_ref?.current?.response?.data?.data?.file_url)
                }else{
                    setUrl("")
                    toast.error("Data not found")
                }
            })
          }
            
    }}
    const linkDownload = (e)=>{
        e.preventDefault()
        window.location.href = url
    }
   
    return (
        <div className="catogory-checkbox-wrap">
            <div className="country-filter">
                <Form className="w-100">
                    <Row className="catogory-checkbox">
                        <Col md={3}>
                            {allData?.map((item,i)=>(
                                 <div className="checkbox-wrapper">
                                 {/* <input id="catCheck1" type="checkbox" onChange={handleAll} disabled={
                                     fin===true||dev===true||other===true||econ===true||mul===true||data===true||
                                     experts===true||evals===true||news===true||disable===true
                                 }/>
                                 <label htmlFor="catCheck1">
                                     <span>All</span>
                                 </label> */}
                                  <input
                              type="checkbox"
                              value={item}
                              id={`all${i}`}
                              onClick={handleAll}
                              checked={allChecked[i]}
                              onChange={() => {
                                handleOnChangeAll(i);
                              }}
                            />
                             <label htmlFor={`all${i}`}>
                              <span>{item}</span>
                            </label>
                             </div>
                            ))}
                           
                        </Col>
                        <Col md={3}>
                            {financing?.map((item,i)=>(
                                 <div className="checkbox-wrapper">
                                 {/* <input id="catCheck2" type="checkbox" onChange={handleFinancing} disabled={all===true||disable===true}
                                 />
                                 <label htmlFor="catCheck2">
                                     <span>Financing</span>
                                 </label> */}
                                 <input
                              type="checkbox"
                              value={item}
                              id={`finance${i}`}
                              onClick={handleFinancing}
                              checked={financeChecked[i]}
                              onChange={() => {
                                handleOnChangeFinance(i);
                              }}
                            />
                             <label htmlFor={`finance${i}`}>
                              <span>{item}</span>
                            </label>
                             </div>
                            ))}
                           
                        </Col>
                        <Col md={3}>
                            {devpubs?.map((item,i)=>(
                                 <div className="checkbox-wrapper">
                                 {/* <input id="catCheck3" type="checkbox" onChange={handleDev} disabled={all===true||disable===true}/>
                                 <label htmlFor="catCheck3">
                                     <span>DevPubs</span>
                                 </label> */}
                                  <input
                              type="checkbox"
                              value={item}
                              id={`dev${i}`}
                              onClick={handleDev}
                              checked={devChecked[i]}
                              onChange={() => {
                                handleOnChangeDev(i);
                              }}
                            />
                             <label htmlFor={`dev${i}`}>
                              <span>{item}</span>
                            </label>
                             </div>
                            ))}
                           
                        </Col>
                        <Col md={3}>
                            {/* {otherpubs?.map((item,i)=>{
                                <div className="checkbox-wrapper">
                                <input id="catCheck4" type="checkbox" onChange={handleOther} disabled={all===true||disable===true}/>
                                <label htmlFor="catCheck4">
                                    <span>OtherPubs</span>
                                </label>
                                 <input
                              type="checkbox"
                              value={item}
                              id={`other${i}`}
                              onClick={handleOther}
                              checked={otherChecked[i]}
                              onChange={() => {
                                handleOnChangeOther(i);
                              }}
                            />
                             <label htmlFor={`other${i}`}>
                              <span>{item}</span>
                            </label>
                            </div>
                            })} */}
                            {otherpubs?.map((item,i)=>(
                                 <div className="checkbox-wrapper">
                                 {/* <input id="catCheck3" type="checkbox" onChange={handleDev} disabled={all===true||disable===true}/>
                                 <label htmlFor="catCheck3">
                                     <span>DevPubs</span>
                                 </label> */}
                                  <input
                              type="checkbox"
                              value={item}
                              id={`other${i}`}
                              onClick={handleOther}
                              checked={otherChecked[i]}
                              onChange={() => {
                                handleOnChangeOther(i);
                              }}
                            />
                             <label htmlFor={`other${i}`}>
                              <span>{item}</span>
                            </label>
                             </div>
                            ))}
                            
                        </Col>
                        <Col md={3}>
                            {datapubs?.map((item,i)=>(
                                <div className="checkbox-wrapper">
                                {/* <input id="catCheck5" type="checkbox" onChange={handleData} disabled={all===true||disable===true}/>
                                <label htmlFor="catCheck5">
                                    <span>Data</span>
                                </label> */}
                                 <input
                              type="checkbox"
                              value={item}
                              id={`data${i}`}
                              onClick={handleData}
                              checked={dataChecked[i]}
                              onChange={() => {
                                handleOnChangeData(i);
                              }}
                            />
                             <label htmlFor={`data${i}`}>
                              <span>{item}</span>
                            </label>
                            </div>
                            ))}
                            
                        </Col>
                        <Col md={3}>
                            {econpubs?.map((item,i)=>(
                                 <div className="checkbox-wrapper">
                                 {/* <input id="catCheck10" type="checkbox" onChange={handleEcon} disabled={all===true||disable===true}/>
                                 <label htmlFor="catCheck10">
                                     <span>EconPubs</span>
                                 </label> */}
                                 <input
                              type="checkbox"
                              value={item}
                              id={`econ${i}`}
                              onClick={handleEcon}
                              checked={econChecked[i]}
                              onChange={() => {
                                handleOnChangeEcon(i);
                              }}
                            />
                             <label htmlFor={`econ${i}`}>
                              <span>{item}</span>
                            </label>
                             </div>
                            ))}
                           
                        </Col>
                        <Col md={3}>
                            {multimedia?.map((item,i)=>(
                                 <div className="checkbox-wrapper">
                                 {/* <input id="catCheck6" type="checkbox" onChange={handleMul} disabled={all===true||disable===true}/>
                                 <label htmlFor="catCheck6">
                                     <span>Multimedia</span>
                                 </label> */}
                                 <input
                              type="checkbox"
                              value={item}
                              id={`mul${i}`}
                              onClick={handleMul}
                              checked={mulChecked[i]}
                              onChange={() => {
                                handleOnChangeMul(i);
                              }}
                            />
                             <label htmlFor={`mul${i}`}>
                              <span>{item}</span>
                            </label>
                             </div>
                            ))}
                           
                        </Col>
                        <Col md={3}>
                            {newspubs?.map((item,i)=>(
                                <div className="checkbox-wrapper">
                                {/* <input id="catCheck7" type="checkbox" onChange={handleNews} disabled={all===true||disable===true}/>
                                <label htmlFor="catCheck7">
                                    <span>News</span>
                                </label> */}
                                <input
                              type="checkbox"
                              value={item}
                              id={`news${i}`}
                              onClick={handleNews}
                              checked={newsChecked[i]}
                              onChange={() => {
                                handleOnChangeNews(i);
                              }}
                            />
                             <label htmlFor={`news${i}`}>
                              <span>{item}</span>
                            </label>
                            </div>
                            ))}
                            
                        </Col>
                        <Col md={3}>
                            {evalpubs?.map((item,i)=>(
                                <div className="checkbox-wrapper">
                                {/* <input id="catCheck8" type="checkbox" onChange={handleEval} disabled={all===true||disable===true}/>
                                <label htmlFor="catCheck8">
                                    <span>Evaluations</span>
                                </label> */}
                                <input
                              type="checkbox"
                              value={item}
                              id={`eval${i}`}
                              onClick={handleEval}
                              checked={evalChecked[i]}
                              onChange={() => {
                                handleOnChangeEval(i);
                              }}
                            />
                             <label htmlFor={`eval${i}`}>
                              <span>{item}</span>
                            </label>
                            </div>
                            ))}
                            
                        </Col>
                        <Col md={3}>
                            {expertspubs?.map((item,i)=>(
                                <div className="checkbox-wrapper">
                                {/* <input id="catCheck9" type="checkbox" onChange={handleExperts} disabled={all===true||disable===true}/>
                                <label htmlFor="catCheck9">
                                    <span>Experts</span>
                                </label> */}
                                <input
                              type="checkbox"
                              value={item}
                              id={`experts${i}`}
                              onClick={handleExperts}
                              checked={expertsChecked[i]}
                              onChange={() => {
                                handleOnChangeExperts(i);
                              }}
                            />
                             <label htmlFor={`experts${i}`}>
                              <span>{item}</span>
                            </label>
                            </div>
                            ))}
                            
                        </Col>
                        <Col md={12}>
                            <div className="btn-wrap d-flex justify-content-end m-t-10">
                                {url===""?<Button disabled={disable===true} onClick={downloadRecord}>{!load?"Get Results":"loading..."}</Button>:<Button onClick={(e)=>{
                                    linkDownload(e)
                                    onClose()
                                }}>Download</Button>}                                
                            </div>
                        </Col>
                    </Row>
                </Form>

            </div>
            <Modal show={Countryshow} size="md" className='common-modal side-popup country-pop-with-toolitip'>
                <Modal.Header closeButton onClick={()=>{
                    CountryhandleClose()
                    econClosed()
                }}>
                </Modal.Header>
                <div className="country-tooltip-outer">
        <Modal.Title>
            <div className="country-title-tooltipwrap"><span className="with-tooltip-title no-events">Choose countries<span className="country-modal-inotext">
        <OverlayTrigger  placement="bottom" 
              overlay={
                <Tooltip id="InfoText">
                  You might want to find content for your country on interest in this category.
                </Tooltip>
              }
            >
              <img src={InfoImg} />

            </OverlayTrigger>
        </span></span>
      
            </div>
            </Modal.Title>
            </div>
                <Modal.Body>
                    <div className="country-pop-wraper">
                        <CountryPopup countryClose={CountryhandleClose}/>
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer/>
        </div>
    )
}

export default CatogoryPopup;