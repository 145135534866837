import React from 'react';
import Router from './app/router';
import { BrowserRouter } from 'react-router-dom';

const App = () => (
   
<React.Fragment>
   <Router />
</React.Fragment>
  );
  
  export default App;