import React,{useEffect} from 'react';
import { Container } from 'react-bootstrap';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Members from '../../profile/components/members/members';
import ProfileDetailsEdit from '../components/profiledetailsedit/profiledetailsedit';
import ProfilePicEdit from '../components/profilepicedit/profilepicedit';
import {Helmet} from 'react-helmet'
function ProfileEdit() {
    useEffect(()=>{
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
      },[])
    return (
        <div className="innerwrapper profile-wrap">
            <Helmet>
                <title>
                DevDiscovery | Profile|Edit
                </title>
            </Helmet>
            <Container>
                <Row>
                    <div className="prof-left">
                        <ProfilePicEdit />
                    </div>
                    <div className="prof-right">
                        <ProfileDetailsEdit />
                    </div>
                    <Col md={12} className="m-t-50">
                        <Members />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ProfileEdit;
