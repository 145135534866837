import React,{useEffect} from 'react';
import { Container } from 'react-bootstrap';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProfileDetails from '../components/profiledetails/profiledetails';
import Members from '../components/members/members';
import ProfilePic from '../components/profilepic/profilepic';
import {Helmet} from 'react-helmet'

function Profile() {
    useEffect(()=>{
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
      },[])
    return (
        <div className="innerwrapper profile-wrap">
            <Helmet>
                <title>DevDiscovery | Profile</title>
            </Helmet>
            <Container>
                <Row>
                    <div className="prof-left">
                        <ProfilePic />
                    </div>
                    <div className="prof-right">
                        <ProfileDetails />
                    </div>
                    <Col md={12} className="m-t-50">
                        <Members />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Profile;
