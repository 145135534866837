import React from 'react';
import { Container } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import MyBookmark from '../components/mybookmark/mybookmark';
import ReceivedBookmark from '../components/receivedbookmark/receivedbookmark';
import {Helmet} from 'react-helmet'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {setBookmarkedTab} from './../../results/filterServices'
import {useSelector,useDispatch} from 'react-redux'
function Bookmarks() {
    const dispatch = useDispatch()

    //fn to get selected tab
    const handleSelect =(key)=>{
        if(key==="Bookmark"){
            dispatch(setBookmarkedTab("Bookmark"))
        }else if(key==="ReceivedBookmark"){
            dispatch(setBookmarkedTab("ReceivedBookmark"))
        }
    }
    const active = useSelector((state)=>state?.filter?.bookmark_tab)
    // console.log(active,"active");
    return (
        <div className="innerwrapper savedquery-wrap">
            <Helmet>
                <title>
                DevDiscovery | Bookmarks
                </title>
            </Helmet>
            <Container>
                <div className="whitebox padding-box results-tab-wrap">
                    <Tabs 
                    // defaultActiveKey="Bookmark" 
                    activeKey={active!==""?active:"Bookmark"}
                    id="justify-tab-example"
                    onSelect={handleSelect}>
                        <Tab eventKey="Bookmark" title="Bookmarks"  >
                            <MyBookmark />
                        </Tab>
                        <Tab eventKey="ReceivedBookmark" title="Received Bookmarks"  >
                            <ReceivedBookmark/>
                        </Tab>
                    </Tabs>
                </div>
            </Container>
            <ToastContainer/>
        </div>
    );
};

export default Bookmarks;
