import React from "react";

function RefreshIcon() {
  return (
    <span className="icon-wrap">
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
        <g clip-path="url(#clip0_3_12733)">
          <path d="M44 5.0999V13.9999C44 14.5303 43.7893 15.039 43.4142 15.4141C43.0391 15.7892 42.5304 15.9999 42 15.9999H33C32.7101 16.0032 32.4229 15.9434 32.1584 15.8247C31.8939 15.706 31.6584 15.5312 31.4681 15.3124C31.2779 15.0936 31.1375 14.8361 31.0567 14.5576C30.9758 14.2792 30.9565 13.9865 31 13.6999C31.094 13.2147 31.3562 12.7783 31.7403 12.4673C32.1244 12.1564 32.6059 11.9909 33.1 11.9999H37.4C35.0705 9.40567 32.0377 7.54384 28.6701 6.64061C25.3025 5.73739 21.745 5.83165 18.43 6.91196C15.1149 7.99226 12.185 10.0121 9.99612 12.7261C7.80727 15.44 6.45374 18.7313 6.09999 22.1999C6.05025 22.6949 5.81776 23.1537 5.44795 23.4865C5.07813 23.8193 4.59753 24.0024 4.09999 23.9999C3.81964 24.0013 3.54212 23.9437 3.28545 23.831C3.02877 23.7182 2.79868 23.5527 2.61009 23.3453C2.4215 23.1378 2.27863 22.893 2.19075 22.6268C2.10288 22.3606 2.07195 22.0788 2.09999 21.7999C2.52003 17.601 4.1388 13.6115 6.76327 10.3071C9.38775 7.0027 12.9072 4.52279 16.902 3.16309C20.8968 1.80339 25.1983 1.62127 29.2937 2.63844C33.3891 3.65561 37.1056 5.82915 40 8.8999V4.9999C39.9967 4.70999 40.0565 4.42283 40.1752 4.15832C40.2939 3.89382 40.4687 3.65828 40.6875 3.46804C40.9063 3.27779 41.1638 3.13739 41.4422 3.05656C41.7207 2.97572 42.0133 2.95639 42.3 2.9999C42.7852 3.09395 43.2216 3.35609 43.5326 3.7402C43.8435 4.12431 44.009 4.60578 44 5.0999Z" fill="black" />
          <path d="M3.99998 42.9V34C3.99998 33.4696 4.2107 32.9609 4.58577 32.5858C4.96084 32.2107 5.46955 32 5.99998 32H15C15.2899 31.9967 15.577 32.0565 15.8416 32.1752C16.1061 32.294 16.3416 32.4688 16.5318 32.6875C16.7221 32.9063 16.8625 33.1638 16.9433 33.4423C17.0242 33.7207 17.0435 34.0134 17 34.3C16.9059 34.7852 16.6438 35.2216 16.2597 35.5326C15.8756 35.8435 15.3941 36.0091 14.9 36H10.6C12.9295 38.5943 15.9623 40.4561 19.3299 41.3593C22.6975 42.2625 26.255 42.1683 29.57 41.088C32.8851 40.0077 35.815 37.9878 38.0039 35.2739C40.1927 32.5599 41.5462 29.2687 41.9 25.8C41.9497 25.305 42.1822 24.8462 42.552 24.5134C42.9219 24.1806 43.4025 23.9975 43.9 24C44.1803 23.9986 44.4579 24.0562 44.7145 24.1689C44.9712 24.2817 45.2013 24.4472 45.3899 24.6547C45.5785 24.8621 45.7214 25.1069 45.8092 25.3731C45.8971 25.6393 45.928 25.9211 45.9 26.2C45.4799 30.3989 43.8612 34.3884 41.2367 37.6928C38.6122 40.9972 35.0928 43.4771 31.098 44.8368C27.1032 46.1965 22.8017 46.3787 18.7063 45.3615C14.6109 44.3443 10.8944 42.1708 7.99998 39.1V43C8.00327 43.2899 7.94348 43.5771 7.82477 43.8416C7.70605 44.1061 7.53125 44.3416 7.31247 44.5319C7.09369 44.7221 6.83616 44.8625 6.55773 44.9434C6.2793 45.0242 5.98663 45.0435 5.69998 45C5.21482 44.906 4.77837 44.6438 4.46742 44.2597C4.15647 43.8756 3.99095 43.3941 3.99998 42.9Z" fill="black" />
        </g>
        <defs>
          <clipPath id="clip0_3_12733">
            <rect width="48" height="48" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
}

export default RefreshIcon;
