import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import technoImg1 from '../../../../../assets/images/icons/tech-icon-1.svg';
import technoImg2 from '../../../../../assets/images/icons/tech-icon-2.svg';
import technoImg3 from '../../../../../assets/images/icons/tech-icon-3.svg';
import technoImg4 from '../../../../../assets/images/icons/tech-icon-4.svg';



function LandSection5() {
    return (
        <div className="section landing-section-5">
            <Container className="text-center">
                <h3 className="landing-common-title">Leverage the power of content, technology, and design.</h3>
                <p className="landing-common-description">We’ve assembled a team of development professionals, researchers, AI/ML experts, and software engineers, to empower you to
                    deliver more. </p>
                <Row className="technology-show-row text-left">
                    <Col md={6} className="technology-show-box-left">
                        <div className="technology-show-box d-flex align-items-center">
                            <div className="technology-cont-icon">
                                <img src={technoImg1} alt="technology-1" />
                            </div>
                            <div className="technology-cont-text">
                                <h4>Curated</h4>
                                <p>Collected from leading development organisations and other sources, handpicked by seasoned development professionals.</p>
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className="technology-show-box-right">
                        <div className="technology-show-box d-flex align-items-center">
                            <div className="technology-cont-icon">
                                <img src={technoImg2} alt="technology-1" />
                            </div>
                            <div className="technology-cont-text">
                                <h4>AI-powered</h4>
                                <p>Our AI algorithms help identify the most relevant content for your queries with near human accuracy.</p>
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className="technology-show-box-left">
                        <div className="technology-show-box d-flex align-items-center">
                            <div className="technology-cont-icon">
                                <img src={technoImg3} alt="technology-1" />
                            </div>
                            <div className="technology-cont-text">
                                <h4>Interactive</h4>
                                <p>Recommendations can be filtered using a variety of attributes; save queries, share queries, bookmark results, and more.</p>
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className="technology-show-box-right">
                        <div className="technology-show-box d-flex align-items-center">
                            <div className="technology-cont-icon">
                                <img src={technoImg4} alt="technology-1" />
                            </div>
                            <div className="technology-cont-text">
                                <h4>Flexible</h4>
                                <p>User queries can be simple keywords, abstracts, program descriptions, or entire documents.</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default LandSection5;