
import React from 'react';

function ShimmerLoadingNews() {
  return (
    <div className="shimmer-loading-box">
      <div class="shimmer-card">
        <div class="p-10">
          <div class="shimmerBG big-title-line title-line"><span className='no-other-reco'>No other recommendation found</span></div>
          {/* <div class="shimmerBG title-line end"></div> */}
          <div class="shimmerBG content-line m-t-30"></div>
          <div class="shimmerBG content-line end"></div>
        </div>
      </div>
    </div>
  )
}

export default ShimmerLoadingNews;