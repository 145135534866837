import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import CountUp from "react-countup";
import VisibilitySensor from 'react-visibility-sensor';
import background from "../../../../../assets/images/discover-catogory-bg.jpg";
// import { staticCount } from '../../../service';
import {_staticCount} from '../../../../../shared/httpservice/apis'
import {useSelector,shallowEqual,useDispatch} from 'react-redux'
import { staticCount } from '../../../service';

function LandSection6() {


// const count = useSelector((state)=>state?.count?.Static_Count?.response?.data?.data?.count)
//     console.log(count);


//     const Count =()=>{
//     if(token!==undefined){
//         dispatch(staticCount(token))
    
// }
// useEffect(()=>{
//     staticCount()
// })
const dispatch = useDispatch()
// const token = useSelector((store)=>store?.auth?.signin?.response?.data?.data?.token,shallowEqual)
// const tokenSso = useSelector((state) => state?.auth?.sso_login_callback?.response?.data?.data?.token,shallowEqual);
const state= useSelector((state)=>state?.count?.static_count?.response?.data?.data)
// console.log(state,'countstate')
const [data,setData]= useState()
useEffect(()=>{
    if(state){
        setData(state)
    }
},[state])
// console.log(data,'count');
const count =() =>{
    // if(data!==undefined){
    //     dispatch(staticCount())
    // }
    // else if(setData!==undefined){
    //     dispatch(staticCount())
    // }
 dispatch (staticCount())
}
useEffect(() =>{
    count()
},[])
    return (
        <div className="landing-section-6 p-relative">
            <div className="landing-section-bg landing-section-bg-right" style={{ backgroundImage: `url(${background})` }}>
                &nbsp;
            </div>
            <Container>
                <Row className="bg-over-text land-section-6-row">
                    <Col md={6}>
                        <div className="discoversec-content-box-wrap">
                            <div className="discoversec-content-box">
                                <div className="text-center">
                                    <h3>Discover tons of content in each category.</h3>
                                    <p className="landing-common-description">We add new content every day.</p>
                                </div>
                                <Row className="discover-count-row d-flex">
                                    <Col md={3} xs={6}>
                                        <div className="discover-count-box">
                                            <h4>
                                                <CountUp start={(data?.financing/2)} end={data?.financing} duration={3} redraw={true} >
                                                    {({ countUpRef, start }) => (
                                                        <VisibilitySensor onChange={start} delayedCall>
                                                            <span ref={countUpRef} />
                                                        </VisibilitySensor>
                                                    )}
                                                </CountUp>
                                            </h4>
                                            <h6>Financing</h6>
                                        </div>
                                    </Col>
                                    <Col md={3} xs={6}>
                                        <div className="discover-count-box">
                                            <h4>
                                                <CountUp start={(data?.devpubs/2)} end={data?.devpubs} duration={3} redraw={true}>
                                                    {({ countUpRef, start }) => (
                                                        <VisibilitySensor onChange={start} delayedCall>
                                                            <span ref={countUpRef} />
                                                        </VisibilitySensor>
                                                    )}
                                                </CountUp>
                                            </h4>
                                            <h6>Publications</h6>
                                        </div>
                                    </Col>
                                    <Col md={3} xs={6}>
                                        <div className="discover-count-box">
                                            <h4>
                                                <CountUp start={(data?.otherpubs/2)} end={data?.otherpubs} duration={3} redraw={true}>
                                                    {({ countUpRef, start }) => (
                                                        <VisibilitySensor onChange={start} delayedCall>
                                                            <span ref={countUpRef} />
                                                        </VisibilitySensor>
                                                    )}
                                                </CountUp>
                                            </h4>
                                            <h6>Scholarly</h6>
                                        </div>
                                    </Col>
                                    <Col md={3} xs={6}>
                                        <div className="discover-count-box">
                                            <h4>
                                                <CountUp start={(data?.data/2)} end={data?.data} duration={3} redraw={true}>
                                                    {({ countUpRef, start }) => (
                                                        <VisibilitySensor onChange={start} delayedCall>
                                                            <span ref={countUpRef} />
                                                        </VisibilitySensor>
                                                    )}
                                                </CountUp>
                                            </h4>
                                            <h6>Data</h6>
                                            <label className="d-block">(Datasets and Indicators)</label>
                                        </div>
                                    </Col>
                                    <Col md={3} xs={6}>
                                        <div className="discover-count-box">
                                            <h4>
                                                <CountUp start={data?.econpubs/2} end={data?.econpubs} duration={3} redraw={true}>
                                                    {({ countUpRef, start }) => (
                                                        <VisibilitySensor onChange={start} delayedCall>
                                                            <span ref={countUpRef} />
                                                        </VisibilitySensor>
                                                    )}
                                                </CountUp>
                                            </h4>
                                            <h6>Economics</h6>
                                        </div>
                                    </Col>
                                    <Col md={3} xs={6}>
                                        <div className="discover-count-box">
                                            <h4>
                                                <CountUp end={data?.evaluations} start={(data?.evaluations/2)} duration={3} redraw={true}>
                                                    {({ countUpRef, start }) => (
                                                        <VisibilitySensor onChange={start} delayedCall>
                                                            <span ref={countUpRef} />
                                                        </VisibilitySensor>
                                                    )}
                                                </CountUp>
                                            </h4>
                                            <h6>Evaluations</h6>
                                        </div>
                                    </Col>
                                    <Col md={3} xs={6}>
                                        <div className="discover-count-box">
                                            <h4>
                                                <CountUp end={data?.multimedia} start={(data?.multimedia/2)} duration={3} redraw={true}>
                                                    {({ countUpRef, start }) => (
                                                        <VisibilitySensor onChange={start} delayedCall>
                                                            <span ref={countUpRef} />
                                                        </VisibilitySensor>
                                                    )}
                                                </CountUp>
                                            </h4>

                                            <h6>Multimedia</h6>
                                            <label className="d-block">(Videos)</label>
                                        </div>
                                    </Col>
                                    <Col md={3} xs={6}>
                                        <div className="discover-count-box">
                                            <h4>Bing</h4>
                                            <h6>News</h6>
                                        </div>
                                    </Col>
                                </Row>

                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default LandSection6;