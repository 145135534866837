import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';


function AboutusContent() {
    return (
        <div className="content-section">

            <div className="about-content-sec about-content-sec-1">
                <Container>
                    <Row>
                        <Col md={4}>
                            <div className="aboutus-head-wrap">
                                <h3 className="aboutus-title">About us</h3>
                            </div>
                        </Col>
                        <Col md={8}>
                            <div className="aboutus-desc-wrap">
                                <p> <b>DevDiscovery</b> is a platform developed by KushAI, designed specifically for development professionals, to help them find the knowledge, information, and data, they need to design impactful development interventions and research.</p>
                                <p>To achieve this, we’ve assembled a team of development professionals, researchers, data scientists, and software developers to build a platform that empowers development professionals with the right content, intuitive design, and cutting edge technology.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="about-content-sec about-content-sec-2">
                <Container>
                    <Row>
                        <Col md={4}>
                            <div className="aboutus-head-wrap">
                                <h3 className="aboutus-title">Our beginning</h3>
                            </div>
                        </Col>
                        <Col md={8}>
                            <div className="aboutus-desc-wrap">
                                <p>Our founders have worked at the intersection of development research and technology for over
                                    two decades. Before they came together to build <b>DevDiscovery</b>, they saw development
                                    professionals wasting their valuable time on finding relevant research, publications, project
                                    documents etc. to inform the design of their own development interventions and research. This
                                    certainly wasn’t the best use of their time, and our founders wanted to change that. <b>DevDiscovery</b> is the outcome of that desire and their passion to empower those who’ve setout
                                    to empower others.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="about-content-sec about-content-sec-3">
                <Container>
                    <Row>
                        <Col md={4}>
                            <div className="aboutus-head-wrap">
                                <h3 className="aboutus-title">Our vision</h3>
                            </div>
                        </Col>
                        <Col md={8}>
                            <div className="aboutus-desc-wrap">
                                <p>We want <b>DevDiscovery</b> to be the go-to platform for all knowledge, information, and data related to socio-economic development. We want our platform to be the knowledge engine for our clients and partners in this sector.</p>
                                <p>We want to be the first to bring the latest content to our users. We also want to be the first to bring the latest in artificial intelligence and machine learning (AI/ML) to our users. We want to leverage the content that we have to contribute to advancements in the areas of Natural Language Processing (NLP), Natural Language Understanding (NLU), and Semantic Analysis.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default AboutusContent;