import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Logo from '../../assets/images/logo-home.png';
import ProfPic from '../../assets/images/profile-icon.png';
import mobileMenu from '../../assets/images/icons/mobile-menu.png';
import { Link, NavLink } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { userLogOut, resetStore } from './../../modules/auth/services';
import { _logout,_userTierInfoDoc } from '../httpservice/apis';
import { useDispatch, useSelector, shallowEqual } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import ProfileDummy from '../../assets/images/profile-dummy.png'
import Button from "react-bootstrap/Button";
import { userProfileInfo,listNotification } from './../../modules/profile/services';
import Notification from '../notificationss';
import {setViewAll} from './../../modules/results/services'
import upgradeImg from '../../assets/images/icons/upgrade-icon.png'
import HeaderChatIcon from '../../assets/images/icons/header-chat-icon.svg'
import {setChatPermission} from '../../modules/chatai/services'

function Header() {
    const navigate = useNavigate()
    const enabled = useSelector((state)=>state?.chat?.permission)
    // const token = localStorage.getItem("userToken")
    const token = useSelector((store) => store?.auth?.signin?.response?.data?.data?.token, shallowEqual)
    const userName = useSelector((store) => store?.auth?.signin?.response?.data?.data?.username, shallowEqual)
    const userNameSso = useSelector((store) => store?.auth?.sso_login_callback?.response?.data?.data?.username)
    const tokenSso = useSelector(
        (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token, shallowEqual
    );
    const userInfo = useSelector((state) => state?.profile?.userProfile?.response?.data?.data?.results[0])
    const notificationCount = useSelector((state)=>state?.profile?.list_notification?.response?.data?.data?.count,shallowEqual)
    const notState = useSelector((state)=>state?.profile?.list_notification?.error?.response?.statusText,shallowEqual)

    const viewAll = useSelector((state)=>state?.results?.view_all)

    const [initialCount,setInitialCount] = useState(notificationCount)
    const [countDiff,setCountDiff] = useState(0)
    const [countStatus,setCountStatus] = useState()
    const [UpgradeShows, UpgradeSetShows] = useState(false);
    const UpgradehandleCloses = () => UpgradeSetShows(false);
    const UpgradehandleShows = () => UpgradeSetShows(true);

    useEffect(()=>{
        const fetchData = async (token) => {
            try {
              const tireInfo = await _userTierInfoDoc(token, "chat_ai");
              dispatch(setChatPermission(true));
            } catch (error) {
              dispatch(setChatPermission(false));
            }
          };
        
          if (token !== undefined) {
            fetchData(token);
          } else if (tokenSso !== undefined) {
            fetchData(tokenSso);
          }
    },[])
   
    const setViewAllNotification =()=>{
        if(viewAll===true){
            dispatch(setViewAll(false))
        }
    }
    useEffect(() => {
        if (notificationCount !==initialCount ) {
            setCountDiff(notificationCount-initialCount)
            
        }
      }, [notificationCount]);

    useEffect(()=>{
        if(countStatus===true){
            setInitialCount(notificationCount);
        }
    },[countStatus])  
    

    const [profileImage, setProfileImage] = useState()
    const listNotifications =()=>{
        if(token!==undefined){
            dispatch(listNotification(token))
        }else if(tokenSso!==undefined){
            dispatch(listNotification(tokenSso))
        }
        
    }
    useEffect(()=>{
        listNotifications()
    },[])
    useEffect(() => {
        let interval = setInterval(() => {
            listNotifications()
        }, 12000);
        return () => {
            clearInterval(interval);
        };
    }, []);
    useEffect(() => {
        if (token !== undefined) {
            dispatch(userProfileInfo(token))
        } else if (tokenSso !== undefined) {
            dispatch(userProfileInfo(tokenSso))
        }

    }, [])
    useEffect(() => {
        if (userInfo?.profile_pic_path === null) {
            setProfileImage(ProfPic)
        }
        else {
            setProfileImage(userInfo?.profile_pic_path)
        }
    }, [userInfo?.profile_pic_path])
    const dispatch = useDispatch()
    const userSignout = async () => {
        // dispatch(userLogOut(token))
        // const response = await _logout(token).then(()=>{
        //     localStorage.clear()
        // });
        // navigate('/');
        localStorage.setItem("status", false)
        dispatch(resetStore())
        localStorage.removeItem("persist:kush.ai");
        localStorage.removeItem("change")
        if(token!==undefined){
            dispatch(userLogOut(token))
        }else if(tokenSso!==undefined){
            dispatch(userLogOut(tokenSso))
        }
        
        navigate('/signin')
    }
    const [isActive, setActive] = useState(false);
    const ToggleClass = () => {
        setActive(!isActive);
    };
    return (
        <div className="header-wrap">
            <div className="header-top">
                <Container className='full-width-container'>
                    <div className="d-flex justify-content-md-end justify-content-between double-btn">
                        <button type="button" className="btn btn-sm border-btn white-border-btn" onClick={() => {
                            navigate("/")
                        }}>About</button>
                        <button type="button" className="btn btn-sm white-btn" onClick={() => { navigate('/helpandsupport') }}>Help & support</button>
                    </div>
                </Container>
            </div>
            <div className="header-content">
                <Container className='full-width-container'>
                    <div className="d-flex justify-content-between align-items-center menu-section-wrap">
                        <h1 className="logo"><Link to="/"><img src={Logo} alt="logo" /></Link></h1>
                  
                        <div className={!isActive ? "mobilemenu-hidden" : "mobilemenu-active"}>
                            <div className="menu-items d-flex align-items-center">
                             
                                {enabled? <NavLink to="/chatai"><span onClick={()=>{
                                    setActive(false)
                                }} className='p-relative'>AI Chat<span className='aichat-icon'><img src={HeaderChatIcon} alt="chat"/></span></span></NavLink>:<Link ><span onClick={()=>{
                                    UpgradehandleShows()
                                    setActive(false)
                                }}>Chat</span></Link>}
                                   <NavLink to="/ai-search"><span onClick={()=>{
                                    setActive(false)
                                }}>AI Search</span></NavLink>
                                <NavLink to="/savedqueries"><span onClick={()=>{
                                    setActive(false)
                                }}>Saved Queries</span></NavLink>
                                <NavLink to="/bookmarks"><span
                                onClick={()=>{
                                    setActive(false)
                                }}
                                >Bookmarks</span></NavLink>
                                <Dropdown onClick={()=>{
                                        setCountDiff(0)
                                        setCountStatus(true)
                                        setViewAllNotification()
                                    }} align="end" className="notification-dropdown">
                                <Dropdown.Toggle variant="success" id="dropdown-basic" className='notification-button'>
                                    {notificationCount!==0&&notificationCount&&
                                    <span className="noti-count">{notificationCount}</span>
                                    }
                                    
                                    <svg width="18" height="23" viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 19.55V17.825H2.3625V9.0275C2.3625 7.4175 2.82656 5.98479 3.75469 4.72937C4.68281 3.47396 5.90625 2.68333 7.425 2.3575V1.52375C7.425 1.08292 7.57969 0.71875 7.88906 0.43125C8.19844 0.14375 8.56875 0 9 0C9.43125 0 9.80156 0.14375 10.1109 0.43125C10.4203 0.71875 10.575 1.08292 10.575 1.52375V2.3575C12.0938 2.68333 13.3219 3.47396 14.2594 4.72937C15.1969 5.98479 15.6656 7.4175 15.6656 9.0275V17.825H18V19.55H0ZM9 23C8.4 23 7.875 22.7748 7.425 22.3244C6.975 21.874 6.75 21.3325 6.75 20.7H11.25C11.25 21.3325 11.0297 21.874 10.5891 22.3244C10.1484 22.7748 9.61875 23 9 23ZM4.05 17.825H13.9781V9.0275C13.9781 7.60917 13.5 6.40167 12.5437 5.405C11.5875 4.40833 10.4156 3.91 9.02812 3.91C7.64062 3.91 6.46406 4.40833 5.49844 5.405C4.53281 6.40167 4.05 7.60917 4.05 9.0275V17.825Z" fill="#5B5B5B" />
                                    </svg>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Notification/>                                        
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown align="end">
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        <span> <span className="user-img"> <img src={userInfo?.profile_pic_path ? userInfo?.profile_pic_path : ProfileDummy} />
                                        </span>{userInfo?.first_name ? userInfo?.first_name + " " + userInfo?.last_name : "user"}</span>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => {
                                            navigate('/profile')
                                            setActive(false)
                                        }}>Profile</Dropdown.Item>
                                        {token!==undefined&&
                                        <Dropdown.Item onClick={() => {
                                            navigate('/changepassword')
                                            setActive(false)
                                        }}>Change Password</Dropdown.Item>
                                        }
                                        
                                        <Dropdown.Item onClick={userSignout}>Logout</Dropdown.Item>

                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>
                        </div>
                        <div className="mobile-menu-buttons-wrap d-flex align-items-center">
                        <div className="notification-mobile-only ">
                        <Dropdown onClick={()=>{
                                        setCountDiff(0)
                                        setCountStatus(true)
                                        setViewAllNotification()
                                    }} align="end" className="notification-dropdown">
                                <Dropdown.Toggle variant="success" id="dropdown-basic" className='notification-button'>
                                    {notificationCount!==0&&notificationCount&&
                                    <span className="noti-count">{notificationCount}</span>
                                    }
                                    
                                    <svg width="18" height="23" viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 19.55V17.825H2.3625V9.0275C2.3625 7.4175 2.82656 5.98479 3.75469 4.72937C4.68281 3.47396 5.90625 2.68333 7.425 2.3575V1.52375C7.425 1.08292 7.57969 0.71875 7.88906 0.43125C8.19844 0.14375 8.56875 0 9 0C9.43125 0 9.80156 0.14375 10.1109 0.43125C10.4203 0.71875 10.575 1.08292 10.575 1.52375V2.3575C12.0938 2.68333 13.3219 3.47396 14.2594 4.72937C15.1969 5.98479 15.6656 7.4175 15.6656 9.0275V17.825H18V19.55H0ZM9 23C8.4 23 7.875 22.7748 7.425 22.3244C6.975 21.874 6.75 21.3325 6.75 20.7H11.25C11.25 21.3325 11.0297 21.874 10.5891 22.3244C10.1484 22.7748 9.61875 23 9 23ZM4.05 17.825H13.9781V9.0275C13.9781 7.60917 13.5 6.40167 12.5437 5.405C11.5875 4.40833 10.4156 3.91 9.02812 3.91C7.64062 3.91 6.46406 4.40833 5.49844 5.405C4.53281 6.40167 4.05 7.60917 4.05 9.0275V17.825Z" fill="#5B5B5B" />
                                    </svg>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Notification/>                                        
                                    </Dropdown.Menu>
                                </Dropdown>
                        </div>
                        <button onClick={ToggleClass} className="mobile-menu">
                            <img src={mobileMenu} alt="" />
                        </button>
                        </div>
                    </div>
                </Container>
            </div>
            <Modal show={UpgradeShows} size="md" centered className='common-modal'>
          <Modal.Body>
            <div className="upgrade-pop-wrap">
              <div className='upgrade-img'>
                <img src={upgradeImg} alt="" />
              </div>
              <h3 className='upgrade-title'>Premium feature!</h3>
              <p>Please contact your admin to enable this feature.</p>
              <div className='upgrade-btn'>
                <Button onClick={UpgradehandleCloses}>OK</Button>
              </div>
            </div>
          </Modal.Body>
        </Modal> 
        </div>
    );
};

export default Header;
