import React,{useState,useEffect} from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/esm/Button';
import background from "../../../../../assets/images/contactus-bg.jpg";
import Form from 'react-bootstrap/Form';
import PhoneInput,  { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
import {Formik} from 'formik'
import {_staticForm} from './../../../../../shared/httpservice/apis'
import {useSelector,shallowEqual} from 'react-redux';
import { Modal, Alert } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ContactSuccessImg from '../../../../../assets/images/contact-success.svg'


function LandContactUs() {
    const [sho, setSho] = useState(false);
    const handleClos = () => setSho(false);
    const handleSho = () => setSho(true);
    const [value,setValue] = useState()
    const [phoneError, setPhoneError] = useState();
    // console.log(value,"value length");

    // console.log(value?.length);
    const token = useSelector((store)=>store?.auth?.signin?.response?.data?.data?.token,shallowEqual)
    const tokenSso = useSelector(
        (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token,shallowEqual
      );
      const [err,setErr] = useState(false)
      useEffect(()=>{
        if(phoneError==="invalid"){
            setErr(true)
        }else{
            setErr(false)
        }
      },[phoneError])
    const register = async (values,{ resetForm }) => {
        const form = new FormData();
        form.append("message", values?.message);
        form.append("name", values?.name)
        form.append("email", values?.email)
        if(value&&(err!==true)){
            form.append("phone",value)
          }
        form.append("organisation_name",values?.org)
        form.append("job_title", values?.job)
        if(err!==true){
            return _staticForm(form).then((response)=>{
                if(response?.data?.status==="success"){
                    handleSho()
                    resetForm()
                    setValue()
                }
            }).catch((error)=>{
                toast.error("something went wrong")
            })
        }
     
        
        }
        const validate =(values)=>{
           
           const errors={}
           if(!values?.message){
            errors.message = "*required"
           }
           const nameRegex=new RegExp("^[a-zA-Z 0-9]*$");
           if(!values?.name){
            errors.name = "*required"
           }else if(!nameRegex.test(values.name)){
            errors.name="*No special characters"
          }
           const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
           if(!values.email){
            errors.email="*please enter your email"
           }else if(!regex.test(values.email)){
            errors.email="*enter a valid email"
           }
           return errors
        }
        const [sub, setSub] = useState(false);
        // console.log(sub,"error");
       
        // console.log(phoneError,"phone");
        const phoneErrors =()=>{
            if(value!==undefined){
                if(!isValidPhoneNumber(value) &&sub){
                setPhoneError("invalid");
            }else {
                setPhoneError()
            }
            }else{
                setPhoneError()
            }
        }
        useEffect(() => {
            if(value!==undefined){
                if(!isValidPhoneNumber(value)){
                setPhoneError("invalid");
            }else{
                setPhoneError()
            }
            }else {
                setPhoneError()
            }
          });
    return (
        <div className="land-contact-section p-relative">
            <div className="land-contact-section-bg landing-section-bg-left" style={{ backgroundImage: `url(${background})` }}>
                &nbsp;
            </div>
            <Container>
                <Row className="bg-over-text land-contact-row">
                    <Col md={6} className="land-contact-space">
                        &nbsp;
                    </Col>
                    <Col md={12} lg={6}>
<div className="land-contact-formwrap">
                        <div className="contact-header">
                            <label> Let’s connect!</label>
                            <h3 className="landing-common-title">Ready to experience the future of research?</h3>
                            </div>
                            <Formik 
                             initialValues={{
                                name : "",
                                email : "",
                                job : "",
                                org : "",
                                message : ""
                               }}
                               onSubmit={register}
                               
                               validate={validate}
                               >
                                 {({ handleSubmit, handleChange, values, errors, touched,setFieldValue,resetForm}) => (
                            <Form className="d-block" onSubmit={handleSubmit}>
                                <Row>
                                    <Col md={6}>
                                        <div className="form-input-wrap m-b-25">
                                            <Form.Label>Name<em style={{ color: "red" }}>*</em></Form.Label>
                                            <Form.Control type="text" className="input-item" 
                                             name="name" placeholder='Name'
                                             value={values?.name}
                                             onChange={handleChange}
                                            />
                                            {errors.name && touched.name ? (
                                             <span style={{ color: "red" }}>
                                             {errors.name}
                                             </span>
                                             ) : null}
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form-input-wrap m-b-25">
                                            <Form.Label>Email address<em style={{ color: "red" }}>*</em></Form.Label>
                                            <Form.Control type="text" className="input-item" 
                                             name="email" placeholder='Email address'
                                             value={values?.email}
                                             onChange={handleChange}
                                            />
                                             {errors.email && touched.email ? (
                                             <span style={{ color: "red" }}>
                                             {errors.email}
                                             </span>
                                             ) : null}
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className="form-input-wrap m-b-25">
                                            <Form.Label>Phone</Form.Label>
                                            {/* <Form.Control type="text" className="input-item" /> */}
                                            <PhoneInput
                                                // international
                                                addInternationalOption={false}
                                                countryCallingCodeEditable={false}
                                                defaultCountry="US" 
                                                className="input-item"  placeholder="Phone"
                                                value={value}
                                                onChange={value=>setValue(value)}
                                                name="phone"
                                                />
                                                <span style={{ color: "red" }}>{phoneError}</span>
                                                
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form-input-wrap m-b-25">
                                            <Form.Label>Job title</Form.Label>
                                            <Form.Control type="text" className="input-item" 
                                             name="job" placeholder="Job title"
                                             value={values?.job}
                                             onChange={handleChange}/>
                                             {errors.job && touched.job ? (
                                             <span style={{ color: "red" }}>
                                             {errors.job}
                                             </span>
                                             ) : null}
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form-input-wrap m-b-25">
                                            <Form.Label>Organisation name</Form.Label>
                                            <Form.Control type="text" className="input-item" 
                                             name="org"  placeholder='Organisation name'
                                             value={values?.org}
                                             onChange={handleChange}/>
                                             {errors.org && touched.org ? (
                                             <span style={{ color: "red" }}>
                                             {errors.org}
                                             </span>
                                             ) : null}
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className="form-input-wrap m-b-25">
                                            <Form.Label>Message<em style={{ color: "red" }}>*</em></Form.Label>
                                            <Form.Control as="textarea" className="textarea-item" 
                                             name="message" placeholder='Message'
                                             value={values?.message}
                                             onChange={handleChange}
                                             />
                                             {errors.message && touched.message ? (
                                             <span style={{ color: "red" }}>
                                             {errors.message}
                                             </span>
                                             ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Button type='submit'
                                 onClick={() => {
                                   
                                    setSub(true)
                                    // resetForm(value)
                                   phoneErrors( )
                                   
                                   
                                  }}
                                >Send</Button>
                                 
                            </Form>
                        
                             )}
                            </Formik>
                            </div>
                    </Col>
                </Row>
            </Container>
            <ToastContainer/>
            <Modal
        show={sho}
        size="md"
        centered
        className="common-modal contact-success header-no-text"
        onHide={handleClos}
      >
        <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="success-content-wrap text-center">
              <h3>Thank you for connecting with us.</h3>
              <div className='contact-success-img'>
                <img src={ContactSuccessImg}/>
              </div>
              <p>
              Our customer service team  shall contact you in the next <span className='primary-text'>12</span> hours.
              </p>
            </div>
          </Modal.Body>
        
      </Modal>
        </div>
    )
}

export default LandContactUs;