import React from 'react';
import { Helmet } from 'react-helmet';
import TermsBanner from '../components/termsbanner/termsbanner';
import TermsContent from '../components/termscontents/termscontents';

function TermsOfUse() {
    return (
        <div className="landing-page-wrap">
            <Helmet>
                <title>
                DevDiscovery | Terms of use
                </title>
            </Helmet>
            <section className="landing-section section-1">
                <TermsBanner />
            </section>
            <section className="landing-section section-1">
                <TermsContent />
            </section>

        </div>
    )
}

export default TermsOfUse;