import React,{useState,useEffect} from 'react'
import ReactPaginate from 'react-paginate'

const Paginate = ({pageCount,onPageChange,initialPage}) => {
  return (
    <div>
        <ReactPaginate
                  previousLabel={'«'}
                  nextLabel={'»'}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  onPageChange={onPageChange}
                  initialPage={initialPage}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  containerClassName={"react-new-pagination pagination justify-content-center d-flex "}
                  pageClassName={'page-item'}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item page-item-arrow"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item page-item-arrow"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                  />
    </div>
  )
}

export default Paginate