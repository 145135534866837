import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';



function PrivacyContent() {

    return (
        <div className="termsofuse-content">
            <div className="terms-sections">
                <Container>

                    <div className='terms-contents'>
                        <p>This “Notice” describes how KushAI LLC. (“DevDiscovery”, “we”, “us” and “our”, “the company”, “kushAI”) collect, use, share, secure, and dispose of personal
                            data. Personal data is any information that does, or could, identify you. This Notice applies to personal data collected by our websites, apps, and products
                            (collectively “services”), and when you use our services and share your data with us. Additional information on our personal data practices may be provided
                            in offer descriptions, supplemental privacy notices or notices provided prior to or at the time of data collection. To the extent a notice provided at the time
                            of collection or service specific privacy notice conflict with this Notice, such specific notice or supplemental privacy statement will control.</p>

                    </div>
                </Container>
            </div>
            <div className="terms-sections terms-gray-sec">
                <Container>
                    <div className="terms-sub-header">
                        <h3 className="terms-title">Who is this notice for?</h3>
                    </div>
                    <div className='terms-contents'>
                        <p>This notice is primarily for our customer representatives, service users (including visitors to our sites and trial users),
                            supplier representatives, research participants, test users, and prospective customers. </p>
                    </div>
                </Container>
            </div>
            <div className="terms-sections">
                <Container>
                    <div className="terms-sub-header">
                        <h3 className="terms-title">When do we collect personal information?</h3>
                    </div>
                    <div className='terms-contents'>
                        <p>We collect personal data directly from you:</p>
                        <ol>
                            <li>When you use our services, e.g., when you purchase or have access to our services, you are usually required to provide us with your name and email
                                address. You will also be required to provide other information necessary for your use of our services or additional information that you choose to disclose
                                to us, such as your professional details or your user preferences.</li>
                            <li>When you interact with us, e.g., when you fill in a form online, contact us or request information from us, or seek technical support.</li>
                            <li>Through cookies and similar technologies included in our services, e.g., when you access our services, we collect device and information relating to
                                your usage of our services. </li>
                        </ol>
                    </div>
                </Container>
            </div>
            <div className="terms-sections terms-gray-sec">
                <Container>
                    <div className="terms-sub-header">
                        <h3 className="terms-title">What types of personal information do we capture and process?</h3>
                    </div>
                    <div className='terms-contents'>
                        <p>We collect personal data directly from you:</p>
                        <ol>
                            <li> Personal identifiers, e.g., name, username, e-mail address, etc.</li>
                            <li> Contact information, e.g., e-mail, etc.</li>
                            <li>Demographic information, e.g., location, etc.</li>
                            <li>Authentication data, e.g., password, etc.</li>
                            <li>Professional information, e.g., employer, location, credentials, work history, education history, specialties, expertise, content preferences, etc.</li>
                            <li>Indirect identifiers, e.g., cookies, IP address, computer device information, etc.</li>
                            <li>Usage data, e.g., information about your use of our services, such as date and time of visits, the page views, time spent on our website, etc.</li>
                            <li>Commercial information, e.g., purchase history, subscription information, transaction details, information about sanctions and restrictions, etc.</li>
                            <li>Communication data, e.g., information communicated from or to you through messages, emails, etc.</li>
                            <li>Feedback, e.g., thoughts, concerns, preferences, feedback, opinions, and other information provided through surveys, etc.</li>
                            <li>Sensory data, e.g., photographs, etc.</li>
                        </ol>
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default PrivacyContent;