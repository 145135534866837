import React, { useState, useEffect, useRef } from "react";
import TextareaAutosize from "react-textarea-autosize";
import SendBtn from "../../../../../../assets/images/icons/send.svg";
import SelectArrow from "../../../../../../assets/images/icons/select-arrow.svg";
import Form from "react-bootstrap/Form";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  addChatResponse,
  clearChat,
  setLoading,
  setPersonality,
  setConversationId,
  setTabId,
  setSearchQuery,
  getChatHistory,
  setNewChat,
  setPaginateCount,
  setPreviousText,
  setGenerateMore,
  setCancelApi
} from "./../../../../services";
import NewChatIcon from "../../../../../../shared/icons/newchaticon";
import {
  _chatRelatedSearch,
  _chatresults,
  _getHistory,
} from "./../../../../../../shared/httpservice/apis";
import axios from "axios";
import { DateRange } from "react-date-range";
import RefreshIcon from "../../../../../../shared/icons/refreshicon";

function ChatTextarea() {
  const dispatch = useDispatch();
  const CancelToken = axios.CancelToken;
  const cancelSource = React.useRef(null);
  const personality = useSelector((state) => state?.chat?.personality);
  const previousText = useSelector((state) => state?.chat?.previous_text);
  const paginateCount = useSelector((state) => state?.chat?.paginate_count);
  const isCancelApi = useSelector((state) => state?.chat?.cancel_api);
  const isNewChat = useSelector((state)=>state?.chat?.new_chat);
  const isGenerateMore = useSelector((state)=>state?.chat?.generate_more);
  const loading = useSelector((state) => state?.chat?.loading);
  const client_id = useSelector((state) => state?.chat?.conversation_id);
  const chat_id = useSelector((state) => state?.chat?.tab_id);
  const historyLoading = useSelector((state) => state?.chat?.history_loading);
  const tokenSso = useSelector(
    (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token,
    shallowEqual
  );
  const token = useSelector(
    (store) => store?.auth?.signin?.response?.data?.data?.token,
    shallowEqual
  );
  const btnStyle = {
    backgroundImage: `url(${SendBtn})`, // Use the imported image
  };
  const getTimestamp = () => {
    const currentDate = new Date();
    const dateAsString = currentDate.toISOString();
    return dateAsString;
  };

  const options = [
    { value: "devpubs", label: "DevPub Notes" },
    { value: "economy", label: "Economy Notes" },
    { value: "financing", label: "Financing Insights" }, 
    { value: "sector", label: "Sector Notes" },
    { value: "factoids", label: "Wiki Factoids" },
  ];

  const stopWords = [
    "a",
    "an",
    "the",
    "in",
    "on",
    "at",
    "to",
    "with",
    "is",
    "as",
    "it",
    "and",
    "or",
    "but",
    "of",
    "for",
    "by",
    "from",
    "that",
    "this",
    "which",
    "was",
    "were",
    "been",
    "are",
    "have",
    "has",
    "do",
    "does",
    "did",
    "not",
    "I",
    "you",
    "he",
    "she",
    "it",
    "we",
    "they",
    "my",
    "your",
    "his",
    "its",
    "our",
    "their",
    "me",
    "him",
    "us",
    "them",
    "am",
    "is",
    "are",
    "was",
    "were",
    "be",
    "being",
    "been",
    "will",
    "shall",
    "can",
    "could",
    "should",
    "would",
    "may",
    "might",
    "must",
    "ought",
    "shall",
    "will",
    "now",
    "then",
    "so",
    "well",
  ];

  const [selectedOption, setSelectedOption] = useState(
    personality !== "" ? personality : "devpubs"
  );

  useEffect(()=>{
    if(personality!==""){
      setSelectedOption(personality)
    }
  },[personality])

  const handleOptionChange = (event) => {
    cancelSource?.current?.cancel();
    dispatch(setNewChat(true));
    setSelectedOption(event.target.value);
    dispatch(setPersonality(event.target.value));
    dispatch(clearChat());
    dispatch(setConversationId(null));
    dispatch(setTabId(null));
    dispatch(setGenerateMore(false))
  };

  const textareaRef = useRef(null);
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);

  useEffect(()=>{
    if(isCancelApi){
      cancelSource?.current?.cancel();
      dispatch(setCancelApi(false))
    }
  },[isCancelApi])

  const [limt,setLimit] = useState(1000)
  useEffect(()=>{
    if(personality==="factoids"){
      setLimit(300)
    }else{
      setLimit(1000)
    }
  },[personality])

  const [text, setText] = useState("");
  const onTextareaChange = (e) => {
    const inputText = e.target.value
    if (inputText.length <= limt) {
      setText(inputText);
    }
  };
  // const handlePaste = (event) => {
  //   event.preventDefault(); // Prevent the default paste behavior
  //   const pastedText = event.clipboardData.getData('text/plain');
  //   const inputText = text + pastedText;

  //   if (inputText.length <= 1000) {
  //     setText(inputText);
  //   } else {
  //     // Trim the text to the character limit
  //     const trimmedText = inputText.slice(0, 1000);
  //     setText(trimmedText);
  //   }
  // };
  const handlePaste = (event) => {
    event.preventDefault(); // Prevent the default paste behavior
    const pastedText = event.clipboardData.getData('text/plain');
    const inputText = text;
    
    // Get the selected text
    const selectedText = inputText.slice(textareaRef.current.selectionStart, textareaRef.current.selectionEnd);
  
    // Replace the selected text with the pasted text
    const newText = inputText.slice(0, textareaRef.current.selectionStart) + pastedText + inputText.slice(textareaRef.current.selectionEnd);
  
    if (newText.length <= limt) {
      setText(newText);
    } else {
      // Trim the text to the character limit
      const trimmedText = newText.slice(0, limt);
      setText(trimmedText);
    }
  };

  useEffect(()=>{
    if(personality==="factoids"){
      const trimmed = text.slice(0,300);
      setText(trimmed)
    }else {
      const trimmed = text.slice(0,1000);
      setText(trimmed)
    }
  },[personality])
  

  const getHistory = async () => {
    try {
      if (token !== undefined) {
        const chatData = await _getHistory(token);
        dispatch(getChatHistory(chatData?.data?.data));
      } else if (tokenSso !== undefined) {
        const chatData = await _getHistory(tokenSso);
        dispatch(getChatHistory(chatData?.data?.data));
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const generateBotResponse = async () => {
    const answer = {};
    dispatch(setLoading(true));
    const data = {};
    if (client_id !== null) {
      if(personality==="financing"){
        data.personality = "Financing Insights"
      }else if(personality==="devpubs"||personality===""){
        data.personality = "DevPub Notes"
      }else if(personality==="economy"){
        data.personality = "Economy Notes"
      }else if(personality === "sector"){
        data.personality = "Sector Notes"
      }else if(personality === "factoids"){
        data.personality = "Wiki Factoids"
      }
      data.message_category = selectedOption
      data.question = text;
      data.conversation_id = client_id;
      data.chat = chat_id;
      data.skip = 0;
    } else {
      if(personality==="financing"){
        data.personality = "Financing Insights"
      }else if(personality==="devpubs"||personality===""){
        data.personality = "DevPub Notes"
      }else if(personality==="economy"){
        data.personality = "Economy Notes"
      }else if(personality === "sector"){
        data.personality = "Sector Notes"
      }else if(personality === "factoids"){
        data.personality = "Wiki Factoids"
      }
      data.message_category = selectedOption
      data.question = text;
      data.conversation_id = null;
      data.skip = 0;
    }
    if (token !== undefined) {
      cancelSource.current = CancelToken.source();
      try {
        const chatData = await _chatresults(data, cancelSource, token).then(
          async (response) => {
            answer.message = response?.data?.data?.answer;
            answer.sender = "bot";
            answer.search_query = response?.data?.data?.search_query;
            answer.countries = response?.data?.data?.additional_data?.countries;
            answer.regions = response?.data?.data?.additional_data?.regions;
            answer.org = response?.data?.data?.additional_data?.org_names;
            answer.major_concepts =
              response?.data?.data?.additional_data?.major_concepts;
            answer.references = response?.data?.data?.references;
            answer.id = response?.data?.data?.id;
            answer.chat_response = response?.data?.data?.chat_responses;
            answer.indicators = response?.data?.data?.indicators;
            answer.like = false;
            answer.dislike = false;
            dispatch(
              setConversationId(
                response?.data?.data?.chat?.client_conversation_id
              )
            );
            dispatch(setTabId(response?.data?.data?.chat?.id));
            dispatch(setSearchQuery(response?.data?.data?.search_query));
            getHistory();
            try {
              const form = new FormData();
              if (selectedOption === "financing") {
                form.append("category", "financing");
              } else if (selectedOption === "devpubs") {
                form.append("category", "devpubs");
              } else if (selectedOption === "economy") {
                form.append("category", "econpubs");
              } else if (selectedOption === "sector") {
                form.append("category", "devpubs");
              } else if (selectedOption === "factoids") {
                form.append("category", "news");
              }

              form.append("qterm", answer?.search_query);
              if (
                Array.isArray(answer.org) &&
                answer.org?.length > 0
              ) {
                form.append("org_name", answer.org?.join("^"));
              }
              if (
                Array.isArray(answer.countries) &&
                answer.countries?.length > 0
              ) {
                form.append("country", answer.countries?.join("^"));
              }
              if (Array.isArray(answer.regions) && answer.regions?.length > 0) {
                form.append("region", answer.regions?.join("^"));
              }
              if (
                Array.isArray(answer.major_concepts) &&
                answer.major_concepts?.length > 0
              ) {
                form.append("topic", answer.major_concepts?.join("^"));
              }
              form.append("rows", 10);
              form.append("skip", 0);
              const searchData = await _chatRelatedSearch(form, token);
              answer.search_results = searchData?.data?.data?.items;
              answer.facets = searchData?.data?.data?.facets;
              answer.total_results = searchData?.data?.data?.total_results;
              dispatch(addChatResponse(answer));
              dispatch(setLoading(false));
            } catch (error) {
              dispatch(addChatResponse(answer));
              dispatch(setLoading(false));
            }
          }
        );
      } catch (error) {
        if (error?.message !== "canceled") {
          answer.message =
            "Server Error: We apologize, but our service is currently experiencing technical difficulties. Please try again later.";
          answer.sender = "bot";
          dispatch(addChatResponse(answer));
        }
        dispatch(setLoading(false));
      }
    } else if (tokenSso !== undefined) {
      cancelSource.current = CancelToken.source();
      try {
        const chatData = await _chatresults(data, cancelSource, tokenSso).then(
          async (response) => {
            answer.message = response?.data?.data?.answer;
            answer.sender = "bot";
            answer.search_query = response?.data?.data?.search_query;
            answer.countries = response?.data?.data?.additional_data?.countries;
            answer.regions = response?.data?.data?.additional_data?.regions;
            answer.org = response?.data?.data?.additional_data?.org_names;
            answer.major_concepts =
              response?.data?.data?.additional_data?.major_concepts;
            answer.references = response?.data?.data?.references;
            answer.id = response?.data?.data?.id;
            answer.chat_response = response?.data?.data?.chat_responses;
            answer.indicators = response?.data?.data?.indicators
            answer.like = false;
            answer.dislike = false;
            dispatch(
              setConversationId(
                response?.data?.data?.chat?.client_conversation_id
              )
            );
            dispatch(setTabId(response?.data?.data?.chat?.id));
            dispatch(setSearchQuery(response?.data?.data?.search_query));
            getHistory();
            try {
              const form = new FormData();
              if (selectedOption === "financing") {
                form.append("category", "financing");
              } else if (selectedOption === "devpubs") {
                form.append("category", "devpubs");
              } else if (selectedOption === "economy") {
                form.append("category", "econpubs");
              } else if (selectedOption === "sector") {
                form.append("category", "devpubs");
              } else if (selectedOption === "factoids") {
                form.append("category", "news");
              }

              form.append("qterm", answer?.search_query);
              if (
                Array.isArray(answer.org) &&
                answer.org?.length > 0
              ) {
                form.append("org_name", answer.org?.join("^"));
              }
              if (
                Array.isArray(answer.countries) &&
                answer.countries?.length > 0
              ) {
                form.append("country", answer.countries?.join("^"));
              }
              if (Array.isArray(answer.regions) && answer.regions?.length > 0) {
                form.append("region", answer.regions?.join("^"));
              }
              if (
                Array.isArray(answer.major_concepts) &&
                answer.major_concepts?.length > 0
              ) {
                form.append("topic", answer.major_concepts?.join("^"));
              }
              form.append("rows", 10);
              form.append("skip", 0);
              const searchData = await _chatRelatedSearch(form, tokenSso);
              answer.search_results = searchData?.data?.data?.items;
              answer.facets = searchData?.data?.data?.facets;
              answer.total_results = searchData?.data?.data?.total_results;
              dispatch(addChatResponse(answer));
              dispatch(setLoading(false));
            } catch (error) {
              dispatch(addChatResponse(answer));
              dispatch(setLoading(false));
            }
          }
        );
      } catch (error) {
        if (error?.message !== "canceled") {
          answer.message =
            "Server Error: We apologize, but our service is currently experiencing technical difficulties. Please try again later.";
          answer.sender = "bot";
          dispatch(addChatResponse(answer));
        }
        dispatch(setLoading(false));
      }
    }
  };

  const generateMoreResponse = async () =>{
    let partNumber;
    if(personality==="financing"||personality===""||personality==="devpubs"){
      partNumber = paginateCount/10 +1
    }else if(personality==="economy"||personality === "sector"){
      partNumber = paginateCount/15 +1
    }
    dispatch(
      addChatResponse({
        message: previousText+` (part ${partNumber})`,
        sender: "user",
        time: getTimestamp(),
      })
    );
    const answer = {};
    dispatch(setLoading(true));
    const data = {};
    if (client_id !== null) {
      if(personality==="financing"){
        data.personality = "Financing Insights"
      }else if(personality==="devpubs"||personality===""){
        data.personality = "DevPub Notes"
      }else if(personality==="economy"){
        data.personality = "Economy Notes"
      }else if(personality === "sector"){
        data.personality = "Sector Notes"
      }else if(personality === "factoids"){
        data.personality = "Wiki Factoids"
      }
      data.message_category = selectedOption
      data.question = previousText+`(part ${partNumber})`;
      data.conversation_id = client_id;
      data.chat = chat_id;
      data.skip = paginateCount;
    } else {
      if(personality==="financing"){
        data.personality = "Financing Insights"
      }else if(personality==="devpubs"||personality===""){
        data.personality = "DevPub Notes"
      }else if(personality==="economy"){
        data.personality = "Economy Notes"
      }else if(personality === "sector"){
        data.personality = "Sector Notes"
      }else if(personality === "factoids"){
        data.personality = "Wiki Factoids"
      }
      data.message_category = selectedOption
      data.question = previousText+`(part ${partNumber})`;
      data.conversation_id = null;
      data.skip = paginateCount;
    }
    if (token !== undefined) {
      cancelSource.current = CancelToken.source();
      try {
        const chatData = await _chatresults(data, cancelSource, token).then(
          async (response) => {
            answer.message = response?.data?.data?.answer;
            answer.sender = "bot";
            answer.search_query = response?.data?.data?.search_query;
            answer.countries = response?.data?.data?.additional_data?.countries;
            answer.org = response?.data?.data?.additional_data?.org_names;
            answer.regions = response?.data?.data?.additional_data?.regions;
            answer.major_concepts =
              response?.data?.data?.additional_data?.major_concepts;
            answer.references = response?.data?.data?.references;
            answer.id = response?.data?.data?.id;
            answer.chat_response = response?.data?.data?.chat_responses;
            answer.indicators = response?.data?.data?.indicators;
            answer.like = false;
            answer.dislike = false;
            dispatch(
              setConversationId(
                response?.data?.data?.chat?.client_conversation_id
              )
            );
            dispatch(setTabId(response?.data?.data?.chat?.id));
            dispatch(setSearchQuery(response?.data?.data?.search_query));
            getHistory();
            try {
              const form = new FormData();
              if (selectedOption === "financing") {
                form.append("category", "financing");
              } else if (selectedOption === "devpubs") {
                form.append("category", "devpubs");
              } else if (selectedOption === "economy") {
                form.append("category", "econpubs");
              } else if (selectedOption === "sector") {
                form.append("category", "devpubs");
              } else if (selectedOption === "factoids") {
                form.append("category", "news");
              }

              form.append("qterm", answer?.search_query);
              if (
                Array.isArray(answer.org) &&
                answer.org?.length > 0
              ) {
                form.append("org_name", answer.org?.join("^"));
              }
              if (
                Array.isArray(answer.countries) &&
                answer.countries?.length > 0
              ) {
                form.append("country", answer.countries?.join("^"));
              }
              if (Array.isArray(answer.regions) && answer.regions?.length > 0) {
                form.append("region", answer.regions?.join("^"));
              }
              if (
                Array.isArray(answer.major_concepts) &&
                answer.major_concepts?.length > 0
              ) {
                form.append("topic", answer.major_concepts?.join("^"));
              }
              form.append("rows", 10);
              form.append("skip", 0);
              const searchData = await _chatRelatedSearch(form, token);
              answer.search_results = searchData?.data?.data?.items;
              answer.facets = searchData?.data?.data?.facets
              answer.total_results = searchData?.data?.data?.total_results;
              dispatch(addChatResponse(answer));
              dispatch(setLoading(false));
            } catch (error) {
              dispatch(addChatResponse(answer));
              dispatch(setLoading(false));
            }
          }
        );
      } catch (error) {
        if (error?.message !== "canceled") {
          answer.message =
            "Server Error: We apologize, but our service is currently experiencing technical difficulties. Please try again later.";
          answer.sender = "bot";
          dispatch(addChatResponse(answer));
        }
        dispatch(setLoading(false));
      }
    } else if (tokenSso !== undefined) {
      cancelSource.current = CancelToken.source();
      try {
        const chatData = await _chatresults(data, cancelSource, tokenSso).then(
          async (response) => {
            answer.message = response?.data?.data?.answer;
            answer.sender = "bot";
            answer.search_query = response?.data?.data?.search_query;
            answer.countries = response?.data?.data?.additional_data?.countries;
            answer.org = response?.data?.data?.additional_data?.org_names;
            answer.regions = response?.data?.data?.additional_data?.regions;
            answer.major_concepts =
              response?.data?.data?.additional_data?.major_concepts;
            answer.references = response?.data?.data?.references;
            answer.id = response?.data?.data?.id;
            answer.chat_response = response?.data?.data?.chat_responses;
            answer.indicators = response?.data?.data?.indicators
            answer.like = false;
            answer.dislike = false;
            dispatch(
              setConversationId(
                response?.data?.data?.chat?.client_conversation_id
              )
            );
            dispatch(setTabId(response?.data?.data?.chat?.id));
            dispatch(setSearchQuery(response?.data?.data?.search_query));
            getHistory();
            try {
              const form = new FormData();
              if (selectedOption === "financing") {
                form.append("category", "financing");
              } else if (selectedOption === "devpubs") {
                form.append("category", "devpubs");
              } else if (selectedOption === "economy") {
                form.append("category", "econpubs");
              } else if (selectedOption === "sector") {
                form.append("category", "devpubs");
              } else if (selectedOption === "factoids") {
                form.append("category", "news");
              }

              form.append("qterm", answer?.search_query);
              if (
                Array.isArray(answer.org) &&
                answer.org?.length > 0
              ) {
                form.append("org_name", answer.org?.join("^"));
              }
              if (
                Array.isArray(answer.countries) &&
                answer.countries?.length > 0
              ) {
                form.append("country", answer.countries?.join("^"));
              }
              if (Array.isArray(answer.regions) && answer.regions?.length > 0) {
                form.append("region", answer.regions?.join("^"));
              }
              if (
                Array.isArray(answer.major_concepts) &&
                answer.major_concepts?.length > 0
              ) {
                form.append("topic", answer.major_concepts?.join("^"));
              }
              form.append("rows", 10);
              form.append("skip", 0);
              const searchData = await _chatRelatedSearch(form, tokenSso);
              answer.search_results = searchData?.data?.data?.items;
              answer.facets = searchData?.data?.data?.facets;
              answer.total_results = searchData?.data?.data?.total_results;
              dispatch(addChatResponse(answer));
              dispatch(setLoading(false));
            } catch (error) {
              dispatch(addChatResponse(answer));
              dispatch(setLoading(false));
            }
          }
        );
      } catch (error) {
        if (error?.message !== "canceled") {
          answer.message =
            "Server Error: We apologize, but our service is currently experiencing technical difficulties. Please try again later.";
          answer.sender = "bot";
          dispatch(addChatResponse(answer));
        }
        dispatch(setLoading(false));
      }
    }
    if(personality==="financing"||personality===""||personality==="devpubs"){
      dispatch(setPaginateCount(paginateCount+10))
    }else if(personality==="economy"||personality === "sector"){
      dispatch(setPaginateCount(paginateCount+15))
    }
  }

  function isStringOnlySpaces(inputString) {
    return /^\s*$/.test(inputString);
  }

  const onMessageSent = (event) => {
    event.preventDefault();
    dispatch(setPreviousText(text))
    dispatch(setGenerateMore(true))
    if(personality==="financing"||personality===""||personality==="devpubs"){
      dispatch(setPaginateCount(10));
    }else if(personality==="economy"||personality === "sector"){
      dispatch(setPaginateCount(15));
    }
    if (
      !isStringOnlySpaces(text) &&
      !stopWords.includes(text.toLocaleLowerCase())
    ) {
      dispatch(setNewChat(false));
      dispatch(
        addChatResponse({
          message: text,
          sender: "user",
          time: getTimestamp(),
        })
      );
      generateBotResponse();
      setText("");
    } else if (isStringOnlySpaces(text)) {
      dispatch(setNewChat(false));
      dispatch(
        addChatResponse({
          message: text,
          sender: "user",
          time: getTimestamp(),
        })
      );
      dispatch(setLoading(true));
      setTimeout(() => {
        dispatch(
          addChatResponse({
            message:
              "It seems like your message is empty. If you have any questions or need assistance with something, please feel free to ask, and I'll be happy to help!",
            sender: "bot",
            time: getTimestamp(),
          })
        );
        dispatch(setLoading(false));
      }, 1000);
      setText("");
    } else if (stopWords.includes(text.toLocaleLowerCase())) {
      dispatch(setNewChat(false));
      dispatch(
        addChatResponse({
          message: text,
          sender: "user",
          time: getTimestamp(),
        })
      );
      dispatch(setLoading(true));
      setTimeout(() => {
        dispatch(
          addChatResponse({
            message:
              "It seems like your message is incomplete. How can I assist you today? Please provide more details or let me know what you'd like to discuss or ask about, and I'll be happy to help.",
            sender: "bot",
            time: getTimestamp(),
          })
        );
        dispatch(setLoading(false));
      }, 1000);
      setText("");
    }
  };
  const onClearChat = () => {
    cancelSource?.current?.cancel();
    dispatch(setGenerateMore(false));
    dispatch(setNewChat(true));
    dispatch(setLoading(false));
    dispatch(clearChat());
    dispatch(setPersonality("devpubs"));
    dispatch(setConversationId(null));
    dispatch(setTabId(null));
    setSelectedOption("devpubs");
    dispatch(setPaginateCount(0));
    dispatch(setPreviousText(""));
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (text !== "") {
        onMessageSent(e);
      }
    }
  };
  return (
    <div className="chat-textarea-wrap">
      <div className="chat-textarea-form-outer">
        <div className="d-flex align-items-center chat-forms-btns-wrap">
          {/* Hide this section when new chat button does not need */}
          <div className="chat-textarea-new-chat-wrap">
            <button onClick={onClearChat} className="new-chat-btn">
              <span className="new-chat-btn-img">
                <NewChatIcon />
              </span>
              <span className="new-chat-btn-text">New Chat</span>
            </button>
          </div>

          <div className="chat-textarea-form-wrap d-inline-flex chat-select-box align-items-center">
            <span className="select-label-text">Personality:</span>
            <span className="cht-select-box-form p-relative">
              <Form.Select
                aria-label="Default select example"
                onChange={handleOptionChange}
                value={selectedOption}
              >
                {options.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
              <span className="select-arrow">
                <img src={SelectArrow} />
              </span>
            </span>
          </div>
        </div>
        <form onSubmit={onMessageSent} className="chat-textarea-form d-flex">
          {loading || historyLoading ? (
            <div className="generate-loading generate-loading-anim d-flex align-items-center">
              <div class="dot-elastic"></div>
              <span>Generating Response</span>
            </div>
          ) : null} 
          {personality==="factoids"?null : <>
          {!loading&&isGenerateMore===true ? (
            <div onClick={generateMoreResponse} className="generate-loading generate-more-btn d-flex align-items-center">
              <RefreshIcon/><span>Generate More</span>
            </div>
          ) : null}  
          </>}
            
          <TextareaAutosize
            className="textarea-item"
            placeholder="Ask Me Anything!"
            value={text}
            ref={textareaRef}
            onChange={onTextareaChange}
            onPaste={handlePaste}
            onKeyDown={handleEnter}
            disabled={loading}
          />
          <button
            disabled={text === ""||loading}
            type="submit"
            className="send-btn"
            style={btnStyle}
          >
            click
          </button>
          <span className="text-length-show">
        {text.length}/{limt}
      </span>
        </form>
      </div>
    </div>
  );
}

export default ChatTextarea;
