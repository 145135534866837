import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ServerErrorImg from '../../../assets/images/servererror.svg';
import BackArrow from '../../../assets/images/icons/backarrow.svg';
import Footer from '../../footer';
import {Helmet} from 'react-helmet'
function ServerError() {
    return (
        <div className="error-pages-wrap pagenot-found">
            <Helmet>
                <title>
                DevDiscovery | Server Error
                </title>
            </Helmet>
            <Container>
                <div className="whitebox padding-box d-flex align-items-center justify-content-center text-center m-t-40 p-relative">
                    <div className="backarrow-wrap positioned-backarrow">
                        <Link to="#"><span><img src={BackArrow} /></span></Link>
                    </div>
                    <div className="errorpages-content">
                        <div className="error-image">
                            <img src={ServerErrorImg} alt="404" />
                        </div>
                        <h3 className="error-title m-t-30">Internal server error</h3>
                        <p> the server has been deserted for a while please try again later</p>
                        <div className="btn-wrap text-center m-t-30">
                            <Link to="/" className="btn btn-primary link-btn">Back to Home</Link>
                        </div>
                    </div>
                </div>
            </Container>
            <Footer/>
        </div>
    );
};

export default ServerError;
