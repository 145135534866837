import React, { useEffect, useState, useRef } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NewsDummy from '../../../../assets/images/news-dummy.jpg';
import SmallNewsImg from '../../../../assets/images/news-img.png';
import { useSelector, shallowEqual } from 'react-redux'
import { useDispatch } from 'react-redux'
import ShareRecordPopup from "./../../../../shared/shareRecordPopup";
import { Modal } from 'react-bootstrap';
import { userRecomendation } from './../../../profile/services'
import { useNavigate } from 'react-router-dom'
import { Button } from 'bootstrap';
import ShowMoreText from "react-show-more-text";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ShimmerLoading from '../../../../shared/shimmerloading/shimmerloading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { recordDetails, bookmarkRecords, userListRecordShared, UserListSaveQuery, voteUp, viewSimilar, setViewSimilarId } from './../../../results/services'
import ShimmerLoadingNews from '../../../../shared/shimmerloadingnews/shimmerloadingnews';
function NewsSec() {
    const result = useSelector((state) => state?.results?.userRecomendations?.response?.data?.data?.news, shallowEqual)
    const loading = useSelector((state) => state?.results?.userRecomendations?.loading)
    const navigate = useNavigate()
    const [bookmarked, setBookmarked] = useState(true)

    const dispatch = useDispatch()
    const token = useSelector((store) => store?.auth?.signin?.response?.data?.data?.token, shallowEqual)
    const [AccessShow, AccessSetShow] = useState(false);
    const AccesshandleClose = () => AccessSetShow(false);
    const AccesshandleShow = () => AccessSetShow(true);
    const [projId, setProjId] = useState()
    const [projTitle, setProjTitle] = useState()
    const [proAbs, setProjAbs] = useState()
    const [projLink, setProjLink] = useState()
    const [projOrgId, setProOrgId] = useState()
    const tokenSso = useSelector(
        (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token, shallowEqual
    );


    const bookmark_state = useSelector((state) => state?.results?.bookmark)
    const bookmark_ref = useRef(bookmark_state)
    bookmark_ref.current = bookmark_state





    const toggleBookmark = (project_id, title, abstract, link_html, category, bookmarks, organisation_id) => {

        const data = {
            "project_id": project_id,
            "title": title,
            "abstract": abstract,
            "link_html": link_html,
            "category": category,
            "bookmark": bookmarks,
            "organisation_id": organisation_id

        }
        if (token !== undefined) {
            dispatch(bookmarkRecords(data, token)).then(() => {
                setBookmarked(!bookmarked)
                if (!bookmark_ref?.current?.hasError && bookmark_ref?.current?.response?.data?.data?.bookmark === "Record Bookmarked") {
                    toast.success("Bookmarked successfully, please find it in the Bookmarks tab!")
                }

            })
        } else if (tokenSso !== undefined) {
            dispatch(bookmarkRecords(data, tokenSso)).then(() => {
                setBookmarked(!bookmarked)
                if (!bookmark_ref?.current?.hasError && bookmark_ref?.current?.response?.data?.data?.bookmark === "Record Bookmarked") {
                    toast.success("Bookmarked successfully, please find it in the Bookmarks tab!")
                }

            })
        }

    }
    const userList = () => {
        if (token !== undefined) {
            dispatch(UserListSaveQuery(token, "", "", "", "", "False"))
        } else if (tokenSso !== undefined) {
            dispatch(UserListSaveQuery(tokenSso, "", "", "", "", "False"))
        }
    }
    const recordUserList = (projectId) => {
        if (token !== undefined) {
            dispatch(userListRecordShared(projectId, token))
        } else if (tokenSso !== undefined) {
            dispatch(userListRecordShared(projectId, tokenSso))
        }
    }

    const viewSimilarFinancing = (project_id) => {
        if (token !== undefined) {
            dispatch(viewSimilar(token, "financing", project_id, ""))
        } else if (tokenSso !== undefined) {
            dispatch(viewSimilar(tokenSso, "financing", project_id, ""))
        }
    }

    return (
        <div className="news-wrap">
            <h4 className="sec-sub-head primary-color sub-head-margin">News</h4>
            {loading ?
                <Row className="rec-shimmer-row">
                    <Col className="shimmer-col-1">
                        <ShimmerLoading />
                    </Col>
                    <Col className="shimmer-col-2">
                        <ShimmerLoading />
                    </Col>
                    <Col className="shimmer-col-3">
                        <ShimmerLoading />
                    </Col>
                </Row> :
                <>
                    {!result &&
                        <Row className="rec-shimmer-row">
                            <Col className="shimmer-col-1">
                                <ShimmerLoading />
                            </Col>
                            <Col className="shimmer-col-2">
                                <ShimmerLoading />
                            </Col>
                            <Col className="shimmer-col-3">
                                <ShimmerLoading />
                            </Col>
                        </Row>
                    }
                    {result &&
                        <Row>
                            <Col lg={result?.length <= 6 ? 12 : 8}>
                                <Row>
                                    {result?.slice(0, 6)?.map((item, i) => (
                                        <Col lg={result?.length <= 6 ? 4 : 6} className="news-big-repeat">
                                            <div className="new-big-sec">
                                                <div className='new-small-thumb'>

                                                    <div className="news-img-sec">
                                                        {/* <img src={item?.thunbnailUrl} alt="news" /> */}
                                                        <img src={item?.thunbnailUrl ? item?.thunbnailUrl : SmallNewsImg} alt="news" />
                                                    </div>


                                                    <div className="sourcebox source-1 news-auther-box">
                                                        <a target="_blank" href={item?.url} style={{ textDecoration: "none", color: "inherit" }}>
                                                            <h3>
                                                                {/* {item?.name} */}
                                                                {item?.name?.length > 70 ? item?.name?.substring(0, 69) + "..." : item?.name}
                                                            </h3></a>
                                                        <div className='authors-name'>
                                                            <span>Source:</span>
                                                            {item?.provider}</div>
                                                    </div>
                                                </div>
                                                <div className="source-content">
                                                    <p>{item?.snippet}</p>
                                                </div>
                                                <div className="source-footer d-flex justify-content-between">
                                                    <div className="news-footer-left">
                                                        <button type="button" className="icon-button" onClick={() => {
                                                            AccesshandleShow(),
                                                                recordUserList(item?.url),
                                                                setProjId(item?.url)
                                                            setProjTitle(item?.name)
                                                            setProjAbs(item?.abstract)
                                                            setProjLink(item?.url)
                                                            setProOrgId(item?.provider)
                                                            userList()
                                                        }}>
                                                            <OverlayTrigger
                                                                overlay={
                                                                    <Tooltip id="InfoText">
                                                                        Share
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M14.606 4.55599L19.508 10.0556L14.606 15.4996V11.5554L12.5051 11.6665C9.10373 11.8332 6.50267 12.222 4.45182 12.9998C6.25256 10.8888 9.10373 9.22228 12.8052 8.83343L14.606 8.66677V4.55599ZM13.1554 0.000794509C13.0765 0.00779243 12.9996 0.0320624 12.9291 0.0722063C12.8587 0.11235 12.7961 0.167575 12.7449 0.2347C12.6938 0.301825 12.6551 0.379523 12.6311 0.463321C12.6071 0.547118 12.5983 0.635359 12.6052 0.722959V6.61138C5.00205 7.44465 0.100041 13.222 0 19.0548C0 19.3881 0.100041 19.6103 0.300123 19.6103C0.500205 19.6103 0.650267 19.4437 0.850349 18.9993C2.05084 15.9995 4.75195 14.2774 12.6052 13.8886V19.277C12.5983 19.3646 12.6071 19.4529 12.6311 19.5367C12.6551 19.6205 12.6938 19.6982 12.7449 19.7653C12.7961 19.8324 12.8587 19.8876 12.9291 19.9278C12.9996 19.9679 13.0765 19.9922 13.1554 19.9992C13.2337 20.0041 13.3119 19.9862 13.3821 19.9472C13.4523 19.9082 13.5121 19.8496 13.5556 19.777L21.8089 10.6111C21.932 10.4583 22 10.2605 22 10.0556C22 9.85059 21.932 9.65281 21.8089 9.50004L13.5556 0.222999C13.5121 0.150438 13.4523 0.0917596 13.3821 0.0527823C13.3119 0.013805 13.2337 -0.00411673 13.1554 0.000794509Z" fill="#373737" />
                                                                </svg>
                                                            </OverlayTrigger>
                                                        </button>
                                                        <button type="button" className="icon-button" onClick={() => {
                                                            (item?.record_details?.bookmark ? (toggleBookmark(item?.url, item?.name, item?.abstract, item?.url, "news", 0, item?.provider), item.record_details.bookmark = false) : (toggleBookmark(item?.url, item?.name, item?.abstract, item?.url, "news", 1, item?.provider), item.record_details.bookmark = true))

                                                        }}>

                                                            {item?.record_details?.bookmark ?
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="InfoText">
                                                                            Bookmark
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <svg id="Group_2176" data-name="Group 2176" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                                                                        <g id="icons_Q2" data-name="icons Q2" transform="translate(-61 -9)">
                                                                            <path id="Path_2366" data-name="Path 2366" d="M23.895,7.316V23.526l-4.4-3.358-1.389-1.042-1.389,1.042-4.4,3.358V7.316H23.895M11.158,5A1.158,1.158,0,0,0,10,6.158V25.842A1.158,1.158,0,0,0,11.158,27a1.389,1.389,0,0,0,.811-.289l6.137-4.689,6.137,4.689a1.389,1.389,0,0,0,.811.289,1.158,1.158,0,0,0,1.158-1.158V6.158A1.158,1.158,0,0,0,25.053,5Z" transform="translate(54 4)" fill="#0D6EFD" />
                                                                            <path id="Path_2367" data-name="Path 2367" d="M-126.668-6515.948v18.35l6.819-5.212,6.819,5.212v-18.35Z" transform="translate(192 6527)" fill="#0D6EFD" />
                                                                        </g>
                                                                        <rect id="Rectangle_2567" data-name="Rectangle 2567" width="22" height="22" fill="none" />
                                                                    </svg></OverlayTrigger> :
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="InfoText">
                                                                            Bookmark
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <svg id="Group_2175" data-name="Group 2175" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                                                                        <g id="icons_Q2" data-name="icons Q2" transform="translate(-61 -9)">
                                                                            <path id="Path_2366" data-name="Path 2366" d="M23.895,7.316V23.526l-4.4-3.358-1.389-1.042-1.389,1.042-4.4,3.358V7.316H23.895M11.158,5A1.158,1.158,0,0,0,10,6.158V25.842A1.158,1.158,0,0,0,11.158,27a1.389,1.389,0,0,0,.811-.289l6.137-4.689,6.137,4.689a1.389,1.389,0,0,0,.811.289,1.158,1.158,0,0,0,1.158-1.158V6.158A1.158,1.158,0,0,0,25.053,5Z" transform="translate(54 4)" />
                                                                        </g>
                                                                        <rect id="Rectangle_2567" data-name="Rectangle 2567" width="22" height="22" fill="none" />
                                                                    </svg></OverlayTrigger>}
                                                        </button>
                                                    </div>
                                                    <div className="date-text">{moment(item?.datePublished).format("DD MMM YYYY")}</div>
                                                </div>

                                            </div>
                                        </Col>
                                    ))}


                                </Row>
                            </Col>
                            {result?.length >= 6 && result?.length>=9&&
                                <Col lg={4} className="p-l-0 new-right-wrap">

                                    <div className="news-right">
                                        <ul>
                                            {result?.slice(6, 9)?.map((item, i) => (
                                                <li>

                                                    <h4><a target="_blank" href={item?.url} style={{ textDecoration: "none", color: "inherit" }}>
                                                        {item?.name?.length > 80 ? item?.name?.substring(0, 79) + "..." : item?.name}</a>
                                                        <div className='authors-name'>
                                                            <span>Source:</span>
                                                            {item?.provider}</div>
                                                    </h4>
                                                    <div className="source-footer d-flex justify-content-between w-100 m-t-20">
                                                        <div className="news-footer-left">
                                                            <button type="button" className="icon-button" onClick={() => {
                                                                AccesshandleShow(),
                                                                    recordUserList(item?.url),
                                                                    setProjId(item?.url)
                                                                setProjTitle(item?.name)
                                                                setProjAbs(item?.abstract)
                                                                setProjLink(item?.url)
                                                                setProOrgId(item?.provider)
                                                                userList()
                                                            }}>
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="InfoText">
                                                                            Share
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M14.606 4.55599L19.508 10.0556L14.606 15.4996V11.5554L12.5051 11.6665C9.10373 11.8332 6.50267 12.222 4.45182 12.9998C6.25256 10.8888 9.10373 9.22228 12.8052 8.83343L14.606 8.66677V4.55599ZM13.1554 0.000794509C13.0765 0.00779243 12.9996 0.0320624 12.9291 0.0722063C12.8587 0.11235 12.7961 0.167575 12.7449 0.2347C12.6938 0.301825 12.6551 0.379523 12.6311 0.463321C12.6071 0.547118 12.5983 0.635359 12.6052 0.722959V6.61138C5.00205 7.44465 0.100041 13.222 0 19.0548C0 19.3881 0.100041 19.6103 0.300123 19.6103C0.500205 19.6103 0.650267 19.4437 0.850349 18.9993C2.05084 15.9995 4.75195 14.2774 12.6052 13.8886V19.277C12.5983 19.3646 12.6071 19.4529 12.6311 19.5367C12.6551 19.6205 12.6938 19.6982 12.7449 19.7653C12.7961 19.8324 12.8587 19.8876 12.9291 19.9278C12.9996 19.9679 13.0765 19.9922 13.1554 19.9992C13.2337 20.0041 13.3119 19.9862 13.3821 19.9472C13.4523 19.9082 13.5121 19.8496 13.5556 19.777L21.8089 10.6111C21.932 10.4583 22 10.2605 22 10.0556C22 9.85059 21.932 9.65281 21.8089 9.50004L13.5556 0.222999C13.5121 0.150438 13.4523 0.0917596 13.3821 0.0527823C13.3119 0.013805 13.2337 -0.00411673 13.1554 0.000794509Z" fill="#373737" />
                                                                    </svg>
                                                                </OverlayTrigger>
                                                            </button>
                                                            <button type="button" className="icon-button" onClick={() => {
                                                                (item?.record_details?.bookmark ? (toggleBookmark(item?.url, item?.name, item?.abstract, item?.url, "news", 0, item?.provider), item.record_details.bookmark = false) : (toggleBookmark(item?.url, item?.name, item?.abstract, item?.url, "news", 1, item?.provider), item.record_details.bookmark = true))

                                                            }}>

                                                                {item?.record_details?.bookmark ?
                                                                    <OverlayTrigger
                                                                        overlay={
                                                                            <Tooltip id="InfoText">
                                                                                Bookmark
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <svg id="Group_2176" data-name="Group 2176" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                                                                            <g id="icons_Q2" data-name="icons Q2" transform="translate(-61 -9)">
                                                                                <path id="Path_2366" data-name="Path 2366" d="M23.895,7.316V23.526l-4.4-3.358-1.389-1.042-1.389,1.042-4.4,3.358V7.316H23.895M11.158,5A1.158,1.158,0,0,0,10,6.158V25.842A1.158,1.158,0,0,0,11.158,27a1.389,1.389,0,0,0,.811-.289l6.137-4.689,6.137,4.689a1.389,1.389,0,0,0,.811.289,1.158,1.158,0,0,0,1.158-1.158V6.158A1.158,1.158,0,0,0,25.053,5Z" transform="translate(54 4)" fill="#0D6EFD" />
                                                                                <path id="Path_2367" data-name="Path 2367" d="M-126.668-6515.948v18.35l6.819-5.212,6.819,5.212v-18.35Z" transform="translate(192 6527)" fill="#0D6EFD" />
                                                                            </g>
                                                                            <rect id="Rectangle_2567" data-name="Rectangle 2567" width="22" height="22" fill="none" />
                                                                        </svg></OverlayTrigger> :
                                                                    <OverlayTrigger
                                                                        overlay={
                                                                            <Tooltip id="InfoText">
                                                                                Bookmark
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <svg id="Group_2175" data-name="Group 2175" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                                                                            <g id="icons_Q2" data-name="icons Q2" transform="translate(-61 -9)">
                                                                                <path id="Path_2366" data-name="Path 2366" d="M23.895,7.316V23.526l-4.4-3.358-1.389-1.042-1.389,1.042-4.4,3.358V7.316H23.895M11.158,5A1.158,1.158,0,0,0,10,6.158V25.842A1.158,1.158,0,0,0,11.158,27a1.389,1.389,0,0,0,.811-.289l6.137-4.689,6.137,4.689a1.389,1.389,0,0,0,.811.289,1.158,1.158,0,0,0,1.158-1.158V6.158A1.158,1.158,0,0,0,25.053,5Z" transform="translate(54 4)" />
                                                                            </g>
                                                                            <rect id="Rectangle_2567" data-name="Rectangle 2567" width="22" height="22" fill="none" />
                                                                        </svg></OverlayTrigger>}
                                                            </button>
                                                        </div>
                                                        <div className="date-text">{moment(item?.datePublished).format("DD MMM YYYY")}</div>
                                                    </div>
                                                </li>))}
                                            {/* <li className='news-right-shimmer-box'>
                                                <ShimmerLoadingNews />
                                            </li> */}
                                        </ul>
                                    </div>



                                </Col>
                            }
                             {result?.length >= 6 && result?.length===8&&
                                <Col lg={4} className="p-l-0 new-right-wrap">

                                    <div className="news-right">
                                        <ul>
                                            {result?.slice(6, 8)?.map((item, i) => (
                                                <li>

                                                    <h4><a target="_blank" href={item?.url} style={{ textDecoration: "none", color: "inherit" }}>
                                                        {item?.name?.length > 80 ? item?.name?.substring(0, 79) + "..." : item?.name}</a>
                                                        <div className='authors-name'>
                                                            <span>Source:</span>
                                                            {item?.provider}</div>
                                                    </h4>
                                                    <div className="source-footer d-flex justify-content-between w-100 m-t-20">
                                                        <div className="news-footer-left">
                                                            <button type="button" className="icon-button" onClick={() => {
                                                                AccesshandleShow(),
                                                                    recordUserList(item?.url),
                                                                    setProjId(item?.url)
                                                                setProjTitle(item?.name)
                                                                setProjAbs(item?.abstract)
                                                                setProjLink(item?.url)
                                                                setProOrgId(item?.provider)
                                                                userList()
                                                            }}>
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="InfoText">
                                                                            Share
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M14.606 4.55599L19.508 10.0556L14.606 15.4996V11.5554L12.5051 11.6665C9.10373 11.8332 6.50267 12.222 4.45182 12.9998C6.25256 10.8888 9.10373 9.22228 12.8052 8.83343L14.606 8.66677V4.55599ZM13.1554 0.000794509C13.0765 0.00779243 12.9996 0.0320624 12.9291 0.0722063C12.8587 0.11235 12.7961 0.167575 12.7449 0.2347C12.6938 0.301825 12.6551 0.379523 12.6311 0.463321C12.6071 0.547118 12.5983 0.635359 12.6052 0.722959V6.61138C5.00205 7.44465 0.100041 13.222 0 19.0548C0 19.3881 0.100041 19.6103 0.300123 19.6103C0.500205 19.6103 0.650267 19.4437 0.850349 18.9993C2.05084 15.9995 4.75195 14.2774 12.6052 13.8886V19.277C12.5983 19.3646 12.6071 19.4529 12.6311 19.5367C12.6551 19.6205 12.6938 19.6982 12.7449 19.7653C12.7961 19.8324 12.8587 19.8876 12.9291 19.9278C12.9996 19.9679 13.0765 19.9922 13.1554 19.9992C13.2337 20.0041 13.3119 19.9862 13.3821 19.9472C13.4523 19.9082 13.5121 19.8496 13.5556 19.777L21.8089 10.6111C21.932 10.4583 22 10.2605 22 10.0556C22 9.85059 21.932 9.65281 21.8089 9.50004L13.5556 0.222999C13.5121 0.150438 13.4523 0.0917596 13.3821 0.0527823C13.3119 0.013805 13.2337 -0.00411673 13.1554 0.000794509Z" fill="#373737" />
                                                                    </svg>
                                                                </OverlayTrigger>
                                                            </button>
                                                            <button type="button" className="icon-button" onClick={() => {
                                                                (item?.record_details?.bookmark ? (toggleBookmark(item?.url, item?.name, item?.abstract, item?.url, "news", 0, item?.provider), item.record_details.bookmark = false) : (toggleBookmark(item?.url, item?.name, item?.abstract, item?.url, "news", 1, item?.provider), item.record_details.bookmark = true))

                                                            }}>

                                                                {item?.record_details?.bookmark ?
                                                                    <OverlayTrigger
                                                                        overlay={
                                                                            <Tooltip id="InfoText">
                                                                                Bookmark
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <svg id="Group_2176" data-name="Group 2176" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                                                                            <g id="icons_Q2" data-name="icons Q2" transform="translate(-61 -9)">
                                                                                <path id="Path_2366" data-name="Path 2366" d="M23.895,7.316V23.526l-4.4-3.358-1.389-1.042-1.389,1.042-4.4,3.358V7.316H23.895M11.158,5A1.158,1.158,0,0,0,10,6.158V25.842A1.158,1.158,0,0,0,11.158,27a1.389,1.389,0,0,0,.811-.289l6.137-4.689,6.137,4.689a1.389,1.389,0,0,0,.811.289,1.158,1.158,0,0,0,1.158-1.158V6.158A1.158,1.158,0,0,0,25.053,5Z" transform="translate(54 4)" fill="#0D6EFD" />
                                                                                <path id="Path_2367" data-name="Path 2367" d="M-126.668-6515.948v18.35l6.819-5.212,6.819,5.212v-18.35Z" transform="translate(192 6527)" fill="#0D6EFD" />
                                                                            </g>
                                                                            <rect id="Rectangle_2567" data-name="Rectangle 2567" width="22" height="22" fill="none" />
                                                                        </svg></OverlayTrigger> :
                                                                    <OverlayTrigger
                                                                        overlay={
                                                                            <Tooltip id="InfoText">
                                                                                Bookmark
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <svg id="Group_2175" data-name="Group 2175" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                                                                            <g id="icons_Q2" data-name="icons Q2" transform="translate(-61 -9)">
                                                                                <path id="Path_2366" data-name="Path 2366" d="M23.895,7.316V23.526l-4.4-3.358-1.389-1.042-1.389,1.042-4.4,3.358V7.316H23.895M11.158,5A1.158,1.158,0,0,0,10,6.158V25.842A1.158,1.158,0,0,0,11.158,27a1.389,1.389,0,0,0,.811-.289l6.137-4.689,6.137,4.689a1.389,1.389,0,0,0,.811.289,1.158,1.158,0,0,0,1.158-1.158V6.158A1.158,1.158,0,0,0,25.053,5Z" transform="translate(54 4)" />
                                                                            </g>
                                                                            <rect id="Rectangle_2567" data-name="Rectangle 2567" width="22" height="22" fill="none" />
                                                                        </svg></OverlayTrigger>}
                                                            </button>
                                                        </div>
                                                        <div className="date-text">{moment(item?.datePublished).format("DD MMM YYYY")}</div>
                                                    </div>
                                                </li>))}
                                          
                                            <li className='news-right-shimmer-box'>
                                                <ShimmerLoadingNews />
                                            </li>
                                        </ul>
                                    </div>



                                </Col>
                            }
                             {result?.length >= 6 && result?.length===7&&
                                <Col lg={4} className="p-l-0 new-right-wrap">

                                    <div className="news-right">
                                        <ul>
                                            {result?.slice(6, 7)?.map((item, i) => (
                                                <li>

                                                    <h4><a target="_blank" href={item?.url} style={{ textDecoration: "none", color: "inherit" }}>
                                                        {item?.name?.length > 80 ? item?.name?.substring(0, 79) + "..." : item?.name}</a>
                                                        <div className='authors-name'>
                                                            <span>Source:</span>
                                                            {item?.provider}</div>
                                                    </h4>
                                                    <div className="source-footer d-flex justify-content-between w-100 m-t-20">
                                                        <div className="news-footer-left">
                                                            <button type="button" className="icon-button" onClick={() => {
                                                                AccesshandleShow(),
                                                                    recordUserList(item?.url),
                                                                    setProjId(item?.url)
                                                                setProjTitle(item?.name)
                                                                setProjAbs(item?.abstract)
                                                                setProjLink(item?.url)
                                                                setProOrgId(item?.provider)
                                                                userList()
                                                            }}>
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="InfoText">
                                                                            Share
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M14.606 4.55599L19.508 10.0556L14.606 15.4996V11.5554L12.5051 11.6665C9.10373 11.8332 6.50267 12.222 4.45182 12.9998C6.25256 10.8888 9.10373 9.22228 12.8052 8.83343L14.606 8.66677V4.55599ZM13.1554 0.000794509C13.0765 0.00779243 12.9996 0.0320624 12.9291 0.0722063C12.8587 0.11235 12.7961 0.167575 12.7449 0.2347C12.6938 0.301825 12.6551 0.379523 12.6311 0.463321C12.6071 0.547118 12.5983 0.635359 12.6052 0.722959V6.61138C5.00205 7.44465 0.100041 13.222 0 19.0548C0 19.3881 0.100041 19.6103 0.300123 19.6103C0.500205 19.6103 0.650267 19.4437 0.850349 18.9993C2.05084 15.9995 4.75195 14.2774 12.6052 13.8886V19.277C12.5983 19.3646 12.6071 19.4529 12.6311 19.5367C12.6551 19.6205 12.6938 19.6982 12.7449 19.7653C12.7961 19.8324 12.8587 19.8876 12.9291 19.9278C12.9996 19.9679 13.0765 19.9922 13.1554 19.9992C13.2337 20.0041 13.3119 19.9862 13.3821 19.9472C13.4523 19.9082 13.5121 19.8496 13.5556 19.777L21.8089 10.6111C21.932 10.4583 22 10.2605 22 10.0556C22 9.85059 21.932 9.65281 21.8089 9.50004L13.5556 0.222999C13.5121 0.150438 13.4523 0.0917596 13.3821 0.0527823C13.3119 0.013805 13.2337 -0.00411673 13.1554 0.000794509Z" fill="#373737" />
                                                                    </svg>
                                                                </OverlayTrigger>
                                                            </button>
                                                            <button type="button" className="icon-button" onClick={() => {
                                                                (item?.record_details?.bookmark ? (toggleBookmark(item?.url, item?.name, item?.abstract, item?.url, "news", 0, item?.provider), item.record_details.bookmark = false) : (toggleBookmark(item?.url, item?.name, item?.abstract, item?.url, "news", 1, item?.provider), item.record_details.bookmark = true))

                                                            }}>

                                                                {item?.record_details?.bookmark ?
                                                                    <OverlayTrigger
                                                                        overlay={
                                                                            <Tooltip id="InfoText">
                                                                                Bookmark
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <svg id="Group_2176" data-name="Group 2176" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                                                                            <g id="icons_Q2" data-name="icons Q2" transform="translate(-61 -9)">
                                                                                <path id="Path_2366" data-name="Path 2366" d="M23.895,7.316V23.526l-4.4-3.358-1.389-1.042-1.389,1.042-4.4,3.358V7.316H23.895M11.158,5A1.158,1.158,0,0,0,10,6.158V25.842A1.158,1.158,0,0,0,11.158,27a1.389,1.389,0,0,0,.811-.289l6.137-4.689,6.137,4.689a1.389,1.389,0,0,0,.811.289,1.158,1.158,0,0,0,1.158-1.158V6.158A1.158,1.158,0,0,0,25.053,5Z" transform="translate(54 4)" fill="#0D6EFD" />
                                                                                <path id="Path_2367" data-name="Path 2367" d="M-126.668-6515.948v18.35l6.819-5.212,6.819,5.212v-18.35Z" transform="translate(192 6527)" fill="#0D6EFD" />
                                                                            </g>
                                                                            <rect id="Rectangle_2567" data-name="Rectangle 2567" width="22" height="22" fill="none" />
                                                                        </svg></OverlayTrigger> :
                                                                    <OverlayTrigger
                                                                        overlay={
                                                                            <Tooltip id="InfoText">
                                                                                Bookmark
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <svg id="Group_2175" data-name="Group 2175" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                                                                            <g id="icons_Q2" data-name="icons Q2" transform="translate(-61 -9)">
                                                                                <path id="Path_2366" data-name="Path 2366" d="M23.895,7.316V23.526l-4.4-3.358-1.389-1.042-1.389,1.042-4.4,3.358V7.316H23.895M11.158,5A1.158,1.158,0,0,0,10,6.158V25.842A1.158,1.158,0,0,0,11.158,27a1.389,1.389,0,0,0,.811-.289l6.137-4.689,6.137,4.689a1.389,1.389,0,0,0,.811.289,1.158,1.158,0,0,0,1.158-1.158V6.158A1.158,1.158,0,0,0,25.053,5Z" transform="translate(54 4)" />
                                                                            </g>
                                                                            <rect id="Rectangle_2567" data-name="Rectangle 2567" width="22" height="22" fill="none" />
                                                                        </svg></OverlayTrigger>}
                                                            </button>
                                                        </div>
                                                        <div className="date-text">{moment(item?.datePublished).format("DD MMM YYYY")}</div>
                                                    </div>
                                                </li>))}
                                          
                                            <li className='news-right-shimmer-box'>
                                                <ShimmerLoadingNews />
                                            </li>
                                            <li className='news-right-shimmer-box'>
                                                <ShimmerLoadingNews />
                                            </li>
                                        </ul>
                                    </div>



                                </Col>
                            }

                        </Row>
                    }
                </>}

            <Modal show={AccessShow} size="md" className='common-modal side-popup'>
                <Modal.Header closeButton onClick={AccesshandleClose}>
                    <Modal.Title>Manage access</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="country-pop-wraper">
                        <ShareRecordPopup projectId={projId} title={projTitle} abstract={proAbs} link={projLink} orgId={projOrgId} category="news" />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default NewsSec;
