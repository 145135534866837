import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/esm/Button';
import background from "../../../../../assets/images/aboutbanner.svg";


function AboutusBanner() {
    return (
        <div className="landing-section-1 p-relative">
            <div className="landing-section-bg landing-section-bg-right" style={{ backgroundImage: `url(${background})` }}>
                &nbsp;
            </div>
            <Container>
                <Row className="bg-over-text land-section-1-row">
                    <Col md={6} className="d-flex align-items-center">
                        <div className="sec1-content">
                            <h2>Empowering you<br /> empower others.</h2>
                            <h5 className="banner-desc">Our mission is to help development professionals design life
                                changing interventions and research.</h5>

                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AboutusBanner;