export default {
    SSO_LOGIN : "sso/sso-auth/",
    SSO_LOGIN_APPROVAL : "sso/sso-auth/",
    TOKEN : "/v1/profile/sector_list",
    COUNTRY_LIST : "/v1/profile/country_list",
    TOPIC_LIST : "/v1/profile/topic_list",
    REGION_LIST : "v1/profile/region_list",
    PROFILE_UPDATE : "v1/profile/profileupdate/",
    PROFILE_REGISTRATION :"v1/profile/user_registration",
    SIGNUP_VERIFY : "v1/profile/verify_invite/",
    SIGNUP: "v1/profile/user-login/",
    LOGOUT : "v1/profile/user-logout/",
    RESET_PASSWORD : "v1/profile/reset-password/",
    FORGOT_PASSWORD : "v1/profile/user-forgot-password/",
    KEYWORD_SEARCH : "v1/record/search/",
    CHAT_RESULTS : "v1/chat/conversation",
    CHAT_RELATED_SEARCH : "v1/record/search/",
    KEYWORD_SEARCH_FIN : "v1/record/search/?category=financing",
    KEYWORD_SEARCH_DEV :  "v1/record/search/?category=devpubs",
    KEYWORD_SEARCH_OTHER :  "v1/record/search/?category=otherpubs",
    KEYWORD_SEARCH_DATA :  "v1/record/search/?category=data",
    KEYWORD_SEARCH_MUL :  "v1/record/search/?category=multimedia",
    KEYWORD_SEARCH_EVAL :  "v1/record/search/?category=evaluations",
    KEYWORD_SEARCH_NEWS :  "v1/record/search/?category=news",
    KEYWORD_SEARCH_ECON :  "v1/record/search/?category=econpubs",
    KEYWORD_SEARCH_EXPERTS :  "v1/record/search/?category=experts",
    
    RESET_PASSWORD_VERIFY : 'v1/profile/change-password/',
    SAVE_QUERY : "v1/record/save-queries/",
    VOTE_UP : "v1/record/vote/",
    USERS_LIST : "v1/profile/list-users",
    RECORD_DETAILS : "v1/record/record-details/",
    USER_LIST_SHARE_QUERY : "v1/record/shared-query/users/",
    SHARE_SINGLE_QUERY : "v1/record/share/single-query/",
    BOOKMARK : "v1/record/bookmark/",
    LIST_USERS_RECORD : "v1/record/shared-record/",
    SHARE_RECORD : "v1/record/share-record/",
    USER_RECOMENDATIONS : "v1/record/user-recommendations/",
    USER_PROFILE : "v1/profile/user-information/",
    USER_INFO : "v1/profile/profileupdate/",
    LIST_SAVED_QUERY : "v1/record/save-queries/",
    SHARE_MULTIPLE_QUERY : "v1/record/share/queries/",
    DELETE_MULTIPLE_QUERIES : "v1/record/remove-bulk-saved-query/",
    DELETE_SINGLE_QUERY : "v1/record/remove-shared-query/",
    DELETE_SINGLE_SAVED_QUERY : "/v1/record/remove-saved-query/",
    LIST_RECIEVED_QUERY : "v1/record/share/single-query/",
    RESHARE_QUERY : "v1/record/forward-queries/",
    DELETE_MULTIPLE_RECEIVED_QUERY : "v1/record/remove-bulk-shared-query/",
    DELETE_SINGLE_RECEIVED_QUERY : "v1/record/remove-shared-query/",
    LIST_BOOKMARKS : "v1/record/bookmark/",
    DELETE_SINGLE_BOOKMARK : "v1/record/remove-bookmark/",
    SHARE_MULTIPE_RECORDS : "v1/record/share/bookmarks/",
    DELETE_MULTIPLE_RECORDS : "v1/record/remove-bulk-bookmark/",
    LIST_RECIEVED_BOOKMARKS : "v1/record/share-record/",
    FORWARD_BOOKMARKS : "v1/record/forward-bookmarks/",
    REMOVE_MULTIPLE_SHARED_RECORD : "v1/record/remove-bulk-shared-bookmark/",
    REMOVE_SINGLE_SHARED_RECORD : "v1/record/remove-shared-record/",
    LIST_FAQ : "v1/faq/list/",
    ADD_FAQ : "v1/faq/send-faq/",
    STATIC_FORM : "v1/help-support/connect-us/",
    VIEW_SIMILAR : "v1/record/view-similar/",
    CURRENT_PASSWORD : "v1/profile/password-check/",
    REMOVE_ACCESS_QUERY : "v1/record/remove-query-shared-user/",
    REMOVE_ACCESS_RECORD : "v1/record/remove-bookmark-shared-user/",
    DOC_UPLOAD : "v1/record/doc/",
    STATIC_COUNT:"v1/record/counts/",

    RECORD_DOWNLOAD : "v1/record/download/",
    NOTIFICATION_LIST : "v1/record/list-notifications/",
    MARK_READ : "v1/record/mark-read/",
    MARK_ALL_READ : "v1/record/mark-all-read/",

    USER_TIER_INFO : "v1/profile/user-permission/?feature=document_download",
    USER_TIER_INFO_DOC : "v1/profile/user-permission/",

    LIKE_DISLIKE_CHAT : "v1/chat/like-dislike",
    CHAT_HISTORY : "v1/chat/history",
    CHAT_HISTORY_OTHER : "v1/chat/other-history",
    CHAT_HISTORY_CONTENT : "v1/chat/conversations",
    EDIT_CHAT : "v1/chat/update",




    
  };
  