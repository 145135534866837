import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import designIcon from '../../../../../assets/images/icons/design-icon.png';
import noteIcon from '../../../../../assets/images/icons/note-icon.png';
import chatIcon from '../../../../../assets/images/icons/chat-icon.png';
import languageIcon from '../../../../../assets/images/icons/language-icon.png';



function LandSectionAi() {
    return (
        <div className="section landing-section-ai">
            <Container>
                <Row className="align-items-center">
                    <Col lg={5} className="mb-3 mb-md-5 mb-lg-0">
                        <h3 className="landing-common-title">Experience the power of Generative AI.</h3>
                        <p className="landing-common-description">We'll empower you to unlock the full potential of Generative AI, catalyzing a paradigm shift in your approach to development research.</p>
                    </Col>
                    <Col lg={7}>
                        <Row>
                            <Col sm={6} className="mb-4">
                                <div className="icon-cont-box">
                                    <div className="img-area">
                                        <img height="64" src={designIcon}/>
                                    </div>
                                    <div className="cont-area">
                                        <p className="mb-0">Design lending interventions, mine lessons, identify implementation risks, and much more.</p> 
                                    </div>
                                </div>
                            </Col>
                            <Col sm={6} className="mb-4">
                                <div className="icon-cont-box">
                                    <div className="img-area">
                                        <img height="54" src={noteIcon}/>
                                    </div>
                                    <div className="cont-area">
                                        <p className="mb-0">Generate detailed economy and sector notes with visualizations based on latest 
socio-economic data.</p> 
                                    </div>
                                </div>
                            </Col>
                            <Col sm={6} className="mb-4">
                                <div className="icon-cont-box">
                                    <div className="img-area">
                                        <img height="57" src={chatIcon}/>
                                    </div>
                                    <div className="cont-area">
                                        <p className="mb-0">Chat with infinite conversational memory and elastic context.</p> 
                                    </div>
                                </div>
                            </Col>
                            <Col sm={6} className="mb-4">
                                <div className="icon-cont-box">
                                    <div className="img-area">
                                        <img height="50" src={languageIcon}/>
                                    </div>
                                    <div className="cont-area">
                                        <p className="mb-0">Converse in multiple languages including French, Spanish, Arabic, and many more.</p> 
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>


    )
}

export default LandSectionAi;