import React,{useEffect} from 'react';
import CareersBanner from '../components/careersbanner/careersbanner';
import CareersContent from '../components/careerscontents/careerscontents';
import {Helmet, helmet} from 'react-helmet'

function Careers() {
   
    return (
        <div className="landing-page-wrap">
            <Helmet>
                <title>
                DevDiscovery | Careers
                </title>
            </Helmet>
            <section className="landing-section section-1">
                <CareersBanner />
            </section>
            <section className="landing-section section-1">
                <CareersContent />
            </section>

        </div>
    )
}

export default Careers;