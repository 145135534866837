import React from 'react';
import PropTypes from 'prop-types';
import Header from '../header/index';

const PageWrapper = (props) => {
    return (
        <div className="page-main-wrapper">
            <Header />
            <div className="page-contents">
                {props.children}
            </div>
        </div>
    )
}
PageWrapper.propTypes = {
    children: PropTypes.node,
};
export default PageWrapper;