import React, { useEffect, useState,useRef } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Logo from "../../../../assets/images/logo.png";
import SignInImg from "../../../../assets/images/login-left-image.png";
import { Link } from "react-router-dom";
import { _ssoLogin, _ssoLoginCallback, _token } from "~shared/httpservice/apis";
import { ssoLogin, ssoLoginCallBack } from "./../../services";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import background from "../../../../assets/images/signin-left-bg.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Helmet} from 'react-helmet'

// import './login.css';

function Ssologin() {
  const dispatch = useDispatch();
  const sso_login_state = useSelector((state)=>state?.auth?.sso_login)
  const sso_login_state_ref = useRef(sso_login_state)
  sso_login_state_ref.current = sso_login_state
  const queryString = location.search;
  const params = new URLSearchParams(queryString);
  const handleSubmit = async (values) => {
    let formData = new FormData();
    formData.append("email", values.email);

    dispatch(ssoLogin(formData)).then(()=>{
      if(sso_login_state_ref?.current?.hasError){
        toast.error("Invalid email")
      }
    })
  };
  const validate =(value)=>{
    const errors={}
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if(!value.email){
       errors.email="*please enter your email"
    }else if(!regex.test(value.email)){
       errors.email="*enter a valid email"
    }
    
    return errors

 }

  return (
    <div className="auth-page-wrap">
      <Helmet>
        <title>
        DevDiscovery | Sign in
        </title>
      </Helmet>
      <div className="container">
        <div className="auth-container d-flex">
          <div
            className="auth-left"
            style={{ backgroundImage: `url(${background})` }}
          >
            <div className="auth-logo-wrap">
              <img src={Logo} alt="" />
            </div>
            <div className="auth-signin-img">
              <img src={SignInImg} alt="" />
            </div>
          </div>
          <div className="auth-right">
            <div className="auth-right-content">
              <h3 className="sec-title m-b-60">Login with SSO</h3>
              <Formik
                onSubmit={handleSubmit}
                initialValues={{
                  email: "",
                }}
                validate={validate}
              >
                {({ handleSubmit, handleChange, values, errors, touched }) => (
                  <Form className="w-100" onSubmit={handleSubmit}>
                    <div className="form-input-wrap m-b-30">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        className="input-item"
                        type="email"
                        placeholder="Your Email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        isInvalid={errors.email && touched.email}
                      />
                      {errors.email && touched.email ? (
                        <span style={{ color: "red" }}>{errors.email}</span>
                      ) : null}
                    </div>
                    <div className="button-wrap">
                      <Button type="submit">Login with SSO</Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </div>
  );
}

export default Ssologin;
