import React, { useEffect, useState } from "react";
import GuidIcon from "../../../../../../../../../assets/images/icons/guid.svg";
import ExampleIcon from "../../../../../../../../../assets/images/icons/example-icon.svg";
import NoteIcon from "../../../../../../../../../assets/images/icons/note.svg";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {addChatResponse,setNewChat,setConversationId,setTabId,setSearchQuery,getChatHistory,setLoading,setGenerateMore,setPreviousText,setPaginateCount} from '../../../../../../../services'
import { _chatresults,_getHistory,_chatRelatedSearch } from "../../../../../../../../../shared/httpservice/apis";
import { setSavedFaqTab,setIsFeedBack,setSearch } from "../../../../../../../../helpandsupport/services";
import {useNavigate} from 'react-router-dom'
import axios from "axios";

function FeedTiles() {
  const questions = {
    guides: [
      {
        question: "Explore our various AI assistant personalities",
      },
      {
        question: "How best to frame follow-up questions?",
      },
    ],
    example: [
      {
        question:
          "Write a note on Bolivia's economy.",
          personality: "economy_notes"
      },
      {
        question:
          "Write a note on Nigeria's economy with a special focus on monetary policy.",
          personality: "economy_notes"
      },
      {
        question:
          "Write a note on Thailand's health sector.",
          personality: "sector_notes"
      },
      {
        question:
          "Write a note on Kenya's information technology sector.",
          personality: "sector_notes"
      },
      {
        question:
          "What are some of the lessons learnt from maternal health and child nutrition lending projects?",
          personality: "financing_insights"
      },
      {
        question:
          "What components can I use to design a broadband connectivity project?",
          personality: "financing_insights"
      },
      {
        question:
          "Tell me about any in-depth findings about the Philippines digital economy.",
          personality: "devpubs_notes"
      },
      {
        question:
          "What are some of the policy recommendations for central banks grappling with high inflation?",
          personality: "devpubs_notes"
      },
      {
        question:
          "What are neural networks?",
          personality: "wiki_factoids"
      },
      {
        question:
          "What are landing stations in a broadband network?",
          personality: "wiki_factoids"
      },
    ],
    patch_notes: [
      {
        question: "Our Chat responds using latest information and data",
      },
      {
        question: "Now generate country and sector notes in seconds",
      },
    ],
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const CancelToken = axios.CancelToken;
  const cancelSource = React.useRef(null);
  const personality = useSelector((state) => state?.chat?.personality);
  const loading = useSelector((state) => state?.chat?.loading);
  const client_id = useSelector((state) => state?.chat?.conversation_id);
  const chat_id = useSelector((state) => state?.chat?.tab_id);
  const tokenSso = useSelector(
    (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token,
    shallowEqual
  );
  const token = useSelector(
    (store) => store?.auth?.signin?.response?.data?.data?.token,
    shallowEqual
  );
  const selectedOption = personality
  const [examples,setExamples] = useState([])
  useEffect(()=>{
    if(personality==="financing"){
      setExamples(questions?.example?.filter((item)=>item?.personality==="financing_insights"))
    }else if(personality==="devpubs"||personality===""){
      setExamples(questions?.example?.filter((item)=>item?.personality==="devpubs_notes"))
    }else if(personality==="economy"){
      setExamples(questions?.example?.filter((item)=>item?.personality==="economy_notes"))
    }else if(personality==="sector"){
      setExamples(questions?.example?.filter((item)=>item?.personality==="sector_notes"))
    }else if(personality==="factoids"){
      setExamples(questions?.example?.filter((item)=>item?.personality==="wiki_factoids"))
    }
  },[personality])
  const getHistory = async () => {
    try {
      if (token !== undefined) {
        const chatData = await _getHistory(token);
        dispatch(getChatHistory(chatData?.data?.data));
      } else if (tokenSso !== undefined) {
        const chatData = await _getHistory(tokenSso);
        dispatch(getChatHistory(chatData?.data?.data));
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  const generateBotResponse = async (text) => {
    const answer = {};
    dispatch(setLoading(true));
    const data = {};
    if (client_id !== null) {
      if(personality==="financing"){
        data.personality = "Financing Insights"
      }else if(personality==="devpubs"||personality===""){
        data.personality = "DevPub Notes"
      }else if(personality==="economy"){
        data.personality = "Economy Notes"
      }else if(personality === "sector"){
        data.personality = "Sector Notes"
      }else if(personality === "factoids"){
        data.personality = "Wiki Factoids"
      }
      data.message_category = selectedOption
      data.question = text;
      data.conversation_id = client_id;
      data.chat = chat_id;
    } else {
      if(personality==="financing"){
        data.personality = "Financing Insights"
      }else if(personality==="devpubs"||personality===""){
        data.personality = "DevPub Notes"
      }else if(personality==="economy"){
        data.personality = "Economy Notes"
      }else if(personality === "sector"){
        data.personality = "Sector Notes"
      }else if(personality === "factoids"){
        data.personality = "Wiki Factoids"
      }
      data.message_category = selectedOption
      data.question = text;
      data.conversation_id = null;
    }
    if (token !== undefined) {
      cancelSource.current = CancelToken.source();
      try {
        const chatData = await _chatresults(data, cancelSource, token).then(
          async (response) => {
            answer.message = response?.data?.data?.answer;
            answer.sender = "bot";
            answer.search_query = response?.data?.data?.search_query;
            answer.countries = response?.data?.data?.additional_data?.countries;
            answer.regions = response?.data?.data?.additional_data?.regions;
            answer.org = response?.data?.data?.additional_data?.org_names;
            answer.major_concepts =
              response?.data?.data?.additional_data?.major_concepts;
            answer.references = response?.data?.data?.references;
            answer.id = response?.data?.data?.id;
            answer.chat_response = response?.data?.data?.chat_responses;
            answer.indicators = response?.data?.data?.indicators;
            answer.like = false;
            answer.dislike = false;
            dispatch(
              setConversationId(
                response?.data?.data?.chat?.client_conversation_id
              )
            );
            dispatch(setTabId(response?.data?.data?.chat?.id));
            dispatch(setSearchQuery(response?.data?.data?.search_query));
            getHistory()
            try {
              const form = new FormData();
              if(selectedOption==="financing"){
                form.append("category", "financing");
              }else if(selectedOption==="devpubs"||selectedOption===""){
                form.append("category", "devpubs");
              }else if(selectedOption==="economy"){
                form.append("category", "econpubs");
              }else if(selectedOption==="sector"){
                form.append("category", "devpubs");
              }else if(selectedOption==="factoids"){
                form.append("category", "news");
              }
              
              form.append("qterm", answer?.search_query);
              if (
                Array.isArray(answer.org) &&
                answer.org?.length > 0
              ) {
                form.append("org_name", answer.org?.join("^"));
              }
              if (
                Array.isArray(answer.countries) &&
                answer.countries?.length > 0
              ) {
                form.append("country", answer.countries?.join("^"));
              }
              if (Array.isArray(answer.regions) && answer.regions?.length > 0) {
                form.append("region", answer.regions?.join("^"));
              }
              if (
                Array.isArray(answer.major_concepts) &&
                answer.major_concepts?.length > 0
              ) {
                form.append("topic", answer.major_concepts?.join("^"));
              }
              form.append("rows", 3);
              form.append("skip", 0);
              const searchData = await _chatRelatedSearch(form, token);
              answer.search_results = searchData?.data?.data?.items;
              answer.facets = searchData?.data?.data?.facets
              answer.total_results = searchData?.data?.data?.total_results;
              dispatch(addChatResponse(answer));
              dispatch(setLoading(false));
            } catch (error) {
              dispatch(addChatResponse(answer));
              dispatch(setLoading(false));
            }
          }
        );
      } catch (error) {
        if (error?.message !== "canceled") {
          answer.message = "Server Error: We apologize, but our service is currently experiencing technical difficulties. Please try again later.";
          answer.sender = "bot";
          dispatch(addChatResponse(answer));
        }
        dispatch(setLoading(false));
      }
    } else if (tokenSso !== undefined) {
      cancelSource.current = CancelToken.source();
      try {
        const chatData = await _chatresults(data, cancelSource, tokenSso).then(
          async (response) => {
            answer.message = response?.data?.data?.answer;
            answer.sender = "bot";
            answer.search_query = response?.data?.data?.search_query;
            answer.org = response?.data?.data?.additional_data?.org_names;
            answer.countries = response?.data?.data?.additional_data?.countries;
            answer.regions = response?.data?.data?.additional_data?.regions;
            answer.major_concepts =
              response?.data?.data?.additional_data?.major_concepts;
            answer.references = response?.data?.data?.references;
            answer.id = response?.data?.data?.id;
            answer.chat_response = response?.data?.data?.chat_responses;
            answer.indicators = response?.data?.data?.indicators;
            answer.like = false;
            answer.dislike = false;
            dispatch(
              setConversationId(
                response?.data?.data?.chat?.client_conversation_id
              )
            );
            dispatch(setTabId(response?.data?.data?.chat?.id));
            dispatch(setSearchQuery(response?.data?.data?.search_query));
            getHistory();
            try {
              const form = new FormData();
              if(selectedOption==="financing"){
                form.append("category", "financing");
              }else if(selectedOption==="devpubs"||selectedOption===""){
                form.append("category", "devpubs");
              }else if(selectedOption==="economy"){
                form.append("category", "econpubs");
              }else if(selectedOption==="sector"){
                form.append("category", "devpubs");
              }else if(selectedOption==="factoids"){
                form.append("category", "news");
              }
              
              form.append("qterm", answer?.search_query);
              if (
                Array.isArray(answer.org) &&
                answer.org?.length > 0
              ) {
                form.append("org_name", answer.org?.join("^"));
              }
              if (
                Array.isArray(answer.countries) &&
                answer.countries?.length > 0
              ) {
                form.append("country", answer.countries?.join("^"));
              }
              if (Array.isArray(answer.regions) && answer.regions?.length > 0) {
                form.append("region", answer.regions?.join("^"));
              }
              if (
                Array.isArray(answer.major_concepts) &&
                answer.major_concepts?.length > 0
              ) {
                form.append("topic", answer.major_concepts?.join("^"));
              }
              form.append("rows", 3);
              form.append("skip", 0);
              const searchData = await _chatRelatedSearch(form, tokenSso);
              answer.search_results = searchData?.data?.data?.items;
              answer.facets = searchData?.data?.data?.facets
              answer.total_results = searchData?.data?.data?.total_results;
              dispatch(addChatResponse(answer));
              dispatch(setLoading(false));
            } catch (error) {
              dispatch(addChatResponse(answer));
              dispatch(setLoading(false));
            }
          }
        );
      } catch (error) {
        if (error?.message !== "canceled") {
          answer.message = "Server Error: We apologize, but our service is currently experiencing technical difficulties. Please try again later.";
          answer.sender = "bot";
          dispatch(addChatResponse(answer));
        }
        dispatch(setLoading(false));
      }
    }
  };
  const onMessageSent = (text) => {
      dispatch(setNewChat(false))
      dispatch(setGenerateMore(true))
      dispatch(setPreviousText(text))
      if(personality==="financing"||personality===""||personality==="devpubs"){
        dispatch(setPaginateCount(10));
      }else if(personality==="economy"||personality === "sector"){
        dispatch(setPaginateCount(15));
      }
      dispatch(
        addChatResponse({
          message: text,
          sender: "user",
        })
      );
      generateBotResponse(text);
  };
  return (
    <div className="feed-tiles-wrap">
      <div className="feed-tiles-row d-flex">
        <div className="feed-tiles-box">
          <div className="feed-tile-box-header">
            <span className="feed-tile-icon">
              <img src={GuidIcon} />
            </span>
            <h4>Guides</h4>
          </div>
          <div className="tile-sample-item-wrap">
            {questions.guides.map((item) => (
              <div style={{"cursor":"pointer"}} 
              onClick={()=>{
                dispatch(setSavedFaqTab("AiFaq"))
                dispatch(setSearch(item?.question))
                navigate("/helpandsupport")
              }} 
              className="tile-sample-item">
                <p>{item?.question}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="feed-tiles-box">
          <div className="feed-tile-box-header">
            <span className="feed-tile-icon">
              <img src={ExampleIcon} />
            </span>
            <h4>Examples</h4>
          </div>
          <div className="tile-sample-item-wrap">
            {examples?.map((item) => (
              <div style={{"cursor":"pointer"}} 
              onClick={()=>{
                onMessageSent(item.question)
              }} 
              className="tile-sample-item">
                <p>{item.question}</p>
              </div>
            ))}
          </div>
        </div>
        {/* <div className="feed-tiles-box">
          <div className="feed-tile-box-header">
            <span className="feed-tile-icon">
              <img src={NoteIcon} />
            </span>
            <h4>Patch Notes</h4>
          </div>
          <div className="tile-sample-item-wrap">
            {questions.patch_notes.map((item) => (
              <div style={{"cursor":"pointer"}} 
              onClick={()=>{
                dispatch(setSavedFaqTab("AiFaq"))
                navigate("/helpandsupport")
              }} 
              className="tile-sample-item">
                <p>{item.question}</p>
              </div>
            ))}
          </div>
        </div> */}
      </div>
      <div className="feed-tiles-infobox text-center">
        <p>
          Let’s learn together! Chat is powered by AI that can understand natural language and
          generate text and charts, so surprises and mistakes are possible. Make
          sure to check the facts, and <a className="feedback-link" onClick={()=>{
                dispatch(setSavedFaqTab("AiFaq"))
                dispatch(setIsFeedBack(true))
                navigate("/helpandsupport")
              }}>share feedback</a> so we can
          learn and improve!
        </p>
      </div>
    </div>
  );
}

export default FeedTiles;
