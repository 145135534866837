import React, { Fragment } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import ForgotPassword from "../modules/auth/forgotpassword/container";
import ResetPassword from "../modules/auth/resetpassword/container";
import SignIn from "../modules/auth/signin/container";
import SignUp from "../modules/auth/signup/container";
import Home from "../modules/home/container";
import Results from "../modules/results/container";
import PageWrapper from "../shared/pagewrapper/pagewrapper";
import Ssologin from "../modules/auth/ssologin/container";
import UserSignUp from "../modules/auth/usersignup/container";
import UserResetPassword from "../modules/auth/userResetPassword/container";
import SavedQueries from '../modules/savedqueries/container/savedqueries';
import Bookmarks from '../modules/bookmarks/container/bookmarks';
import Profile from '../modules/profile/container';
import PrivateRoute from "../shared/privateRoutes";
import ProfileEdit from '../modules/profile_edit/container';
import ViewAllMembers from "../modules/profile/viewallmembers/viewallmembers";
import FinanceRecomendation from "../modules/recomendations/financingrecomendation/financingrecomendation";
import MultimediaRecomendation from "../modules/recomendations/multimediarecomendation/multimediarecomendation";
import DevpubsRecomendation from "../modules/recomendations/devpubsrecomendation/devpubsrecomendations";
import EconpubsRecomendation from "../modules/recomendations/econpubsrecommendations/econpubsrecomendations";
import OtherpubsRecomendation from "../modules/recomendations/otherpubsrecomendations/otherpubsrecomendations";
import EvaluationsRecomendation from "../modules/recomendations/evaluationsrecomendations/evaluationsrecomendations";
import PageNotFound from "../shared/errorpages/pagenotfound/pagenotfound";
import ServerError from "../shared/errorpages/servererror/servererror";
import HelpAndSupport from "../modules/helpandsupport/container";
import LandingRoute from "../shared/landingRoute";
import LandingPage from "../modules/staticpages/landingpage/container";
import AboutUs from "../modules/staticpages/aboutus/container";
import Careers from "../modules/staticpages/careers/container";
import Dummy from "../modules/auth/authenticationdummy/container";
import SalesEnquiry from "../modules/staticpages/salesenquiry/container";
import ChangePassword from "../modules/changepassword/container";
import TermsOfUse from "../modules/staticpages/termsofuse/container";
import PrivacyNotice from "../modules/staticpages/privacynotice/container";
import LinkExpired from "../shared/errorpages/linkexpired/linkexpired";
import LinkInvalid from "../shared/errorpages/invalidlink/invalidlink";
import { Helmet } from "react-helmet";
import ChatAi from "../modules/chatai/container";
const Router = () => {
  return (
    <div className={"content-wrap"}>
      <BrowserRouter>
      <Helmet>
        <title>DevDiscovery</title>
        <meta name="description" content="Get info"/>
      </Helmet>
        <Routes>
          <Route path="/signin" exact element={<SignIn />} />
          <Route path="/enterdetails" exact element={<SignUp />} />
          <Route path="/ssologin" exact element={<Ssologin />} />
          <Route path="/usersignup" exact element={<UserSignUp />} />
          <Route path='/signup' excat element ={<Dummy/>}/>
          <Route path="/forgotpassword" exact element={<ForgotPassword />} />
          <Route path="/resetpassword" exact element={<ResetPassword />} />
          <Route path='/linkexpired' exact element={<LinkExpired />} />
          <Route path = '/invalidlink' exact element={<LinkInvalid/>}/>
          <Route
            path="/userresetpassword"
            excat
            element={<UserResetPassword />}
          />
           <Route path='*' exact element={<PageNotFound />} />
           <Route path='/servererror' exact element={<ServerError />} />
          <Route element={<PrivateRoute />}>
            <Route path="/home" exact element={<Home />} />
            <Route path="/results" exact element={<Results />} />
            <Route path='/savedqueries' exact element={<SavedQueries />} />
            <Route path='/bookmarks' exact element={<Bookmarks />} />
            <Route path='/profile' exact element={<Profile />} />
            <Route path='/profile-edit' exact element={<ProfileEdit />} />
             <Route path='/viewallmembers' exact element={<ViewAllMembers />} />
             <Route path='/viewallmembers' exact element={<ViewAllMembers />} />
             <Route path='/finance-recomendations' exact element={<FinanceRecomendation />} />
             <Route path='/devpubs-recomendations' exact element={<DevpubsRecomendation />} />
             <Route path='/econpubs-recomendations' exact element={<EconpubsRecomendation />} />
             <Route path='/otherpubs-recomendations' exact element={<OtherpubsRecomendation />} />
             <Route path='/multimedia-recomendations' exact element={<MultimediaRecomendation />} />
             <Route path='/evaluations-recomendations' exact element={<EvaluationsRecomendation/>} />
             <Route path='/helpandsupport' exact element={<HelpAndSupport />} />
             <Route path='/changepassword' exact element={<ChangePassword />} />
             <Route path='/chatai' exact element={<ChatAi />} />
          </Route>
          <Route element={<LandingRoute/>}>
          <Route path="/" exact element={<LandingPage />} />
          <Route path="/aboutus" exact element={<AboutUs />} />
          <Route path="/careers" exact element={<Careers />} />
          <Route path="/salesenquiry" exact element={<SalesEnquiry />} />
          <Route path="/termsofuse" exact element={<TermsOfUse />} />
          <Route path="/privacynotice" exact element={<PrivacyNotice />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default Router;
