import React from 'react';
import { Container } from 'react-bootstrap';



function LandSection4() {
    return (
        <div className="section landing-section-4">
            <Container className="text-center">
                <h3 className="landing-common-title">Also find multimedia and more - all in one place!</h3>
                <p className="landing-common-description">We follow the latest trends in knowledge dissemination, so that your teams are always ahead.</p>
                <div className="find-relevent-rwo d-flex">
                    <div className="relevent-box">
                        <div className="relevent-box-content">
                            <h4>Data</h4>
                            <p>Socio-economic indicators, data sources, micro datasets, geospatial data sources and more.</p>
                        </div>
                    </div>
                    <div className="relevent-box">
                        <div className="relevent-box-content">
                            <h4>Multimedia</h4>
                            <p>Development focused videos published by leading development agencies.</p>
                        </div>
                    </div>
                    <div className="relevent-box">
                        <div className="relevent-box-content">
                            <h4>News</h4>
                            <p>Powered by Bing News, our platform identifies the most relevant news content for you.</p>
                        </div>
                    </div>
                    <div className="relevent-box">
                        <div className="relevent-box-content">
                            <h4>Experts</h4>
                            <p>Experts from within your organisation who have experience relevant to your search.</p>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default LandSection4;