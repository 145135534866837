import React, { useEffect, useState } from "react";
import FeedTiles from "../feedtiles";
import { setDocurl, setUploadedDocs } from "../../../../../../../services";
import { useDispatch } from "react-redux";


function CustomContents() {
  const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setUploadedDocs([]));
        dispatch(setDocurl(''));
    },[])
  return (
<div className="custom-content-wrap">
   <div className="chat-feed-header text-center">
    <div className="chat-header-inline d-inline-flex align-items-center"><h4>Chat | Generate</h4><span className="chat-beta-text">BETA</span></div>
    <p>Your Development Research Companion</p>
   </div>
   <div className="feed-section">
    <FeedTiles/>
   </div>

   </div>
  );
}

export default CustomContents;
