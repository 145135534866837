import React, { useState, useEffect, useRef } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ShowMoreText from "react-show-more-text";
import NewsDummy from '../../../../assets/images/news-dummy.jpg';
import save from '../../../../assets/images/icons/save.svg';
import { useSelector, shallowEqual } from 'react-redux'
import { FadeLoader } from "react-spinners";
import { useDispatch } from 'react-redux'
import { recordDetails, bookmarkRecords, userListRecordShared, UserListSaveQuery, keywordSearchNews, setFileVar } from './../../services'
import ShareRecordPopup from "../../../../shared/shareRecordPopup";
import Paginate from "../../../../shared/newspagination";
import upgradeImg from '../../../../assets/images/icons/upgrade-icon.png'
import { Button, Modal } from 'react-bootstrap';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
function NewsResults({ category }) {
  const searchTerm = useSelector((state) => state?.results?.search);
  const file = useSelector((state) => state?.results?.file);
  const file_path = useSelector((state)=>state?.results?.keyword_search_news?.response?.data?.data?.file_path)
  const filePathVar = useSelector((state)=>state?.results?.file_var)
  const [initialFile, setInitialFile] = useState(file);
  const [AccessShow, AccessSetShow] = useState(false);
  const AccesshandleClose = () => AccessSetShow(false);
  const AccesshandleShow = () => AccessSetShow(true);

  const [UpgradeShow, UpgradeSetShow] = useState(false);
  const UpgradehandleClose = () => UpgradeSetShow(false);
  const UpgradehandleShow = () => UpgradeSetShow(true);

  const dispatch = useDispatch()
  const [bookmarked, setBookmarked] = useState(true)
  const result = useSelector((state) => state?.results?.keyword_search_news?.response?.data?.data?.items, shallowEqual)
  const loading = useSelector((state) => state?.results?.keyword_search_news?.loading)
  const req = useSelector((state) => state?.results?.keyword_search_news?.response?.request?.responseURL) || ""
  const forbidden = useSelector((state) => state?.results?.keyword_search_news?.error?.response?.data?.code)
  const token = useSelector((store) => store?.auth?.signin?.response?.data?.data?.token, shallowEqual)

  const [projId, setProjId] = useState()
  const [projTitle, setProjTitle] = useState()
  const [proAbs, setProjAbs] = useState()
  const [projLink, setProjLink] = useState()
  const [projOrgId, setProOrgId] = useState()
  const tokenSso = useSelector(
    (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token, shallowEqual
  );

  
  const bookmark_state = useSelector((state)=>state?.results?.bookmark)
  const bookmark_ref = useRef(bookmark_state)
  bookmark_ref.current = bookmark_state

  const shared = localStorage.getItem("shared")
  const count = useSelector(
    (state) =>
      state?.results?.keyword_search_news?.response?.data?.data?.total_results
  );

  useEffect(() => {
    if (initialFile !== file) {
      setInitialFile(file);
    }
  }, [file]);
  useEffect(()=>{
    if(file_path!==undefined&&file_path!==null&&file_path!==""){
      dispatch(setFileVar(file_path))
    }
  },[file_path])

  useEffect(()=>{
    if(forbidden===403){
      UpgradehandleShow()
    }
  },[forbidden])

  const [skip, setSkip] = useState(0);


  const [currentPage, setCurrentPage] = useState(0)

  const handlePage = (data) => {
    setSkip((data?.selected) * 12)
    setCurrentPage(data?.selected)
  }

  useEffect(()=>{
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
  },[skip])



  const searchFunction = () => {
    const form = new FormData()
    form.append("category", "news")
    form.append("qterm", searchTerm)
    if(file!==""&&filePathVar===""){
      form.append("qfile",file)
      form.append("is_file",true)
    }else if(file!==""&&filePathVar!==""){
      form.append("qfile",filePathVar)
      form.append("is_file",true)
    }
    form.append("org_name", "")
    form.append("region", "")
    form.append("country", "")
    form.append("sector", "")
    form.append("topic", "")
    form.append("start_date", "")
    form.append("end_date", "")
    form.append("rows", 12)
    form.append("skip", skip)
    if (token !== undefined) {
      dispatch(
        keywordSearchNews(
          form, token, "news"
        )
      );
    } else if (tokenSso !== undefined) {
      dispatch(
        keywordSearchNews(
          form, tokenSso, "news"
        )
      );
    }
  };
  useEffect(() => {
    if (searchTerm && category === "news") {
      searchFunction();
      setSkip(0)
      setCurrentPage(0)
    }
  }, [
    searchTerm,
    "", "", "", "", "", "", "",
  ]);
  useEffect(() => {
    if (searchTerm && category === "news") searchFunction();
  }, [
    skip,
    category,
  ]);

  useEffect(() => {
    if (file !== "" && category === "news") {
      searchFunction()
      setSkip(0)
      setCurrentPage(0)
    }
  }, [
    file,
    "", "", "", "", "", "", "",
  ]);

  useEffect(() => {
    if (file !== "" && category === "news") searchFunction();
  }, [
    skip,
    category,
  ]);
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "black",
  };
  const toggleBookmark = (project_id, title, abstract, link_html, category, bookmarks, organisation_id) => {

    const data = {
      "project_id": project_id,
      "title": title,
      "abstract": abstract,
      "link_html": link_html,
      "category": category,
      "bookmark": bookmarks,
      "organisation_id": organisation_id

    }
    if (token !== undefined) {
      dispatch(bookmarkRecords(data, token)).then(() => {
        setBookmarked(!bookmarked)
        if(!bookmark_ref?.current?.hasError&&bookmark_ref?.current?.response?.data?.data?.bookmark==="Record Bookmarked"&&req.includes("news")){
          toast.success("Bookmarked successfully, please find it in the Bookmarks tab!")
        }
      })
    } else if (tokenSso !== undefined) {
      dispatch(bookmarkRecords(data, tokenSso)).then(() => {
        setBookmarked(!bookmarked)
        if(!bookmark_ref?.current?.hasError&&bookmark_ref?.current?.response?.data?.data?.bookmark==="Record Bookmarked"&&req.includes("news")){
          toast.success("Bookmarked successfully, please find it in the Bookmarks tab!")
        }
      })
    }


  }
  const userList = () => {
    if (token !== undefined) {
      dispatch(UserListSaveQuery(token, "", "", "", "","False"))
    } else if (tokenSso !== undefined) {
      dispatch(UserListSaveQuery(tokenSso, "", "", "", "","False"))
    }
  }
  const recordUserList = (projectId) => {
    if (token !== undefined) {
      dispatch(userListRecordShared(projectId, token))
    } else if (tokenSso !== undefined) {
      dispatch(userListRecordShared(projectId, tokenSso))
    }

  }


  return (
    <div className="tab-content-wrap news-container">

      <div className="news-result-contents">
        <Row>
          {loading ? <div className="loader-middle-align">
            <div className="loader loader-3">
              <div className="dot dot1">&nbsp;</div>
              <div className="dot dot2">&nbsp;</div>
              <div className="dot dot3">&nbsp;</div>
            </div></div> :
            <>
              {req.includes("news") && result && result?.length > 0 ? result.map((item, i) => (
                <Col lg={4} md={6} className="news-repeat-indetail">
                  <div className="new-big-sec">
                    <div className='new-small-thumb'>
                      {item?.thunbnailUrl &&
                        <div className="news-img-sec">
                          <img src={item?.thunbnailUrl} alt="news" />
                        </div>
                      }


                      <div className="sourcebox source-1 news-auther-box">
                        <a target="_blank" href={item?.url} style={{ textDecoration: "none", color: "inherit" }}>
                          {item?.thunbnailUrl&&
                           <h3>
                           {item.name?.length>70?item?.name?.substring(0,70)+"...":item?.name}
                         </h3>
                          }
                          {!item?.thunbnailUrl&&
                          <h3>
                          {item.name?.length>100?item?.name?.substring(0,100)+"...":item?.name}
                        </h3>
                          }
                         
                          </a>
                          <div className='authors-name'>
                             <span>Source:</span>
                            {item?.provider}</div>
                      </div>
                      
                    </div>

                    <div className="source-content">
                      <p className='news-snippet'>{item.snippet}</p>
                      <div>
                        <ShowMoreText
                          lines={2}
                          more="View more"
                          less="View less"
                          className="more-text-wrap"
                          anchorClass="showmore-button"
                          expanded={false}
                          truncatedEndingComponent={"... "}
                        >
                          {item.abstract}
                        </ShowMoreText>
                      </div>
                    </div>
                    <div className="source-footer d-flex justify-content-between">
                      <div className="news-footer-left">
                      <button type="button" className="icon-button" onClick={() => {
                        AccesshandleShow(),
                          recordUserList(item?.url),
                          setProjId(item?.url)
                        setProjTitle(item?.name)
                        setProjAbs(item?.abstract)
                        setProjLink(item?.url)
                        setProOrgId(item?.provider)
                        userList()
                      }}>
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="InfoText">
                                Share
                              </Tooltip>
                            }
                          >
                        <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14.606 4.55599L19.508 10.0556L14.606 15.4996V11.5554L12.5051 11.6665C9.10373 11.8332 6.50267 12.222 4.45182 12.9998C6.25256 10.8888 9.10373 9.22228 12.8052 8.83343L14.606 8.66677V4.55599ZM13.1554 0.000794509C13.0765 0.00779243 12.9996 0.0320624 12.9291 0.0722063C12.8587 0.11235 12.7961 0.167575 12.7449 0.2347C12.6938 0.301825 12.6551 0.379523 12.6311 0.463321C12.6071 0.547118 12.5983 0.635359 12.6052 0.722959V6.61138C5.00205 7.44465 0.100041 13.222 0 19.0548C0 19.3881 0.100041 19.6103 0.300123 19.6103C0.500205 19.6103 0.650267 19.4437 0.850349 18.9993C2.05084 15.9995 4.75195 14.2774 12.6052 13.8886V19.277C12.5983 19.3646 12.6071 19.4529 12.6311 19.5367C12.6551 19.6205 12.6938 19.6982 12.7449 19.7653C12.7961 19.8324 12.8587 19.8876 12.9291 19.9278C12.9996 19.9679 13.0765 19.9922 13.1554 19.9992C13.2337 20.0041 13.3119 19.9862 13.3821 19.9472C13.4523 19.9082 13.5121 19.8496 13.5556 19.777L21.8089 10.6111C21.932 10.4583 22 10.2605 22 10.0556C22 9.85059 21.932 9.65281 21.8089 9.50004L13.5556 0.222999C13.5121 0.150438 13.4523 0.0917596 13.3821 0.0527823C13.3119 0.013805 13.2337 -0.00411673 13.1554 0.000794509Z" fill="#373737" />
                        </svg>
                        </OverlayTrigger>
                      </button>
                      <button type="button" className="icon-button" onClick={() => {
                        (item?.record_details?.bookmark ? (toggleBookmark(item?.url, item?.name, item?.abstract, item?.url, "news", 0, item?.provider), item.record_details.bookmark = false) : (toggleBookmark(item?.url, item?.name, item?.abstract, item?.url, "news", 1, item?.provider), item.record_details.bookmark = true))

                      }}>

                        {item?.record_details?.bookmark ?
                         <OverlayTrigger
                         overlay={
                           <Tooltip id="InfoText">
                             Bookmark
                           </Tooltip>
                         }
                       >
                        <svg id="Group_2176" data-name="Group 2176" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                          <g id="icons_Q2" data-name="icons Q2" transform="translate(-61 -9)">
                            <path id="Path_2366" data-name="Path 2366" d="M23.895,7.316V23.526l-4.4-3.358-1.389-1.042-1.389,1.042-4.4,3.358V7.316H23.895M11.158,5A1.158,1.158,0,0,0,10,6.158V25.842A1.158,1.158,0,0,0,11.158,27a1.389,1.389,0,0,0,.811-.289l6.137-4.689,6.137,4.689a1.389,1.389,0,0,0,.811.289,1.158,1.158,0,0,0,1.158-1.158V6.158A1.158,1.158,0,0,0,25.053,5Z" transform="translate(54 4)" fill="#0D6EFD" />
                            <path id="Path_2367" data-name="Path 2367" d="M-126.668-6515.948v18.35l6.819-5.212,6.819,5.212v-18.35Z" transform="translate(192 6527)" fill="#0D6EFD" />
                          </g>
                          <rect id="Rectangle_2567" data-name="Rectangle 2567" width="22" height="22" fill="none" />
                        </svg> 
                        </OverlayTrigger>
                        :
                        <OverlayTrigger
                        overlay={
                          <Tooltip id="InfoText">
                            Bookmark
                          </Tooltip>
                        }
                      >
                          <svg id="Group_2175" data-name="Group 2175" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                            <g id="icons_Q2" data-name="icons Q2" transform="translate(-61 -9)">
                              <path id="Path_2366" data-name="Path 2366" d="M23.895,7.316V23.526l-4.4-3.358-1.389-1.042-1.389,1.042-4.4,3.358V7.316H23.895M11.158,5A1.158,1.158,0,0,0,10,6.158V25.842A1.158,1.158,0,0,0,11.158,27a1.389,1.389,0,0,0,.811-.289l6.137-4.689,6.137,4.689a1.389,1.389,0,0,0,.811.289,1.158,1.158,0,0,0,1.158-1.158V6.158A1.158,1.158,0,0,0,25.053,5Z" transform="translate(54 4)" />
                            </g>
                            <rect id="Rectangle_2567" data-name="Rectangle 2567" width="22" height="22" fill="none" />
                          </svg>
                          </OverlayTrigger>
                          }
                      </button>
                    </div>
                    <div className="date-text"> {moment(item?.datePublished).format("DD MMM YYYY")}</div>
                    {/* <button type="button" onClick={UpgradehandleShow} className="test-btn">test</button> */}
                    </div>

                    
                  </div>

                </Col>
              )) : <p className="no-results-wraper">No results found</p>}
            </>
          }
          {!loading && req.includes("news") &&
            result && (<Paginate

              pageCount={(Math.ceil(count / 12))}
              onPageChange={handlePage}
              initialPage={currentPage}

            />)}

        </Row>
        <Modal show={AccessShow} size="md" className='common-modal side-popup'>
          <Modal.Header closeButton onClick={AccesshandleClose}>
            <Modal.Title>Manage access</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="country-pop-wraper">
              <ShareRecordPopup projectId={projId} title={projTitle} abstract={proAbs} link={projLink} orgId={projOrgId} category="news" />
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={UpgradeShow} size="md" centered className='common-modal'>
          <Modal.Body>
            <div className="upgrade-pop-wrap">
              <div className='upgrade-img'>
                <img src={upgradeImg} alt="" />
              </div>
              <h3 className='upgrade-title'>Premium feature!</h3>
              <p>Please contact your admin to enable this feature.</p>
              <div className='upgrade-btn'>
                <Button onClick={UpgradehandleClose}>OK</Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  )
}

export default NewsResults;