import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector,shallowEqual } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Logo from '../../../../assets/images/logo.png';
import SignInImg from '../../../../assets/images/login-left-image.png';
import background from "../../../../assets/images/signin-left-bg.png";
import { Link } from 'react-router-dom';
import {_signIn} from './../../../../shared/httpservice/apis'
import {userSignIn} from './../../services'
import { Formik,  Field } from 'formik';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Eye from "../../../../assets/images/icons/eye.png";
import HiddenEye from "../../../../assets/images/icons/hidden.png";
import * as Yup from 'yup';
import {Helmet} from 'react-helmet'
// import './login.css';


function SignIn() {
  
   const navigate= useNavigate()
   const dispatch = useDispatch()
   const login_state = useSelector((store)=>store?.auth?.signin,shallowEqual)
   const states = useSelector((state)=>state)

   const [passwordShown, setPasswordShown] = useState(false);
   //  console.log(passwordShown);
  const [cpasswordShown, setCPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const toggleCPassword = () => {
    setCPasswordShown(!cpasswordShown);
  };
  
   const login_ref = useRef(login_state);
   // console.log(login_ref?.error);
   login_ref.current = login_state;
   // console.log( login_ref.current);
   const validate =(value)=>{
      const errors={}
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
      if(!value.email){
         errors.email="*please enter your email"
      }else if(!regex.test(value.email)){
         errors.email="*enter a valid email"
      }
      
      if(!value.password){
         errors.password="*please enter your password"
      }
      return errors

   }

    const handleSubmit = async (values)=>{
      let formData = new FormData();
      const text = values.email;
      formData.append('email', text.toLowerCase());
      formData.append('password',values.password)
      dispatch(userSignIn(formData)).then(()=>{
         if(!login_ref?.current?.hasError){
            navigate('/chatai')
            localStorage.setItem("status",true)
         }
         else{
            toast.error(login_ref.current?.error?.response?.data?.message)
         }
      }).catch(()=>{
         toast.error(login_ref.current?.error?.response?.data?.message)
      })

    }
   
   return (
      <>
      <Helmet>
         <title>
         DevDiscovery | Sign in
         </title>
      </Helmet>
      <div className="auth-page-wrap">
         <div className="container">
            <div className="auth-container d-flex">
               <div className="auth-left" style={{backgroundImage: `url(${background})`}}>
                  <div className="auth-logo-wrap">
                     <img src={Logo } alt="" />
                  </div>
                  <div className="auth-signin-img">
                     <img src={SignInImg} alt="" />
                  </div>
               </div>
               <div className="auth-right">
                  <div className="auth-right-content">
                     <h3 className="sec-title m-b-60">Login</h3>
                     <Formik
                      initialValues = {{
                        email: '',
                        password: '',
                      }}
                      onSubmit={handleSubmit}
                      validate={validate}
                     >
                     {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors,
                            touched
                            }) => (
                     <Form className="w-100" onSubmit={handleSubmit}>
                        <div className="form-input-wrap m-b-30">
                           <Form.Label>Email Address</Form.Label>
                           <Form.Control type="text" placeholder="Allie Grater@gmail.com" className="input-item"
                           name='email'
                           value={values.email}
                           onChange={handleChange}
                           isInvalid={
                              errors.email && touched.email
                            }
                           />
                             {errors.email && touched.email ? (
                        <span style={{ color: "red" }}>
                          {errors.email}
                        </span>
                      ) : null}
                        </div>
                        <div className="form-input-wrap  p-relative eye-icon-inputwrap">
                           <Form.Label>Password</Form.Label>
                           <Form.Control 
                           // type="password" 
                           type={passwordShown?"text":"password"}
                           placeholder="* * * * * * * *" className="input-item"
                            name='password'
                            value={values.password}
                            onChange={handleChange}
                            isInvalid={
                              errors.password && touched.password
                            }
                           />
                           {passwordShown ? <span className="eye-hidden" onClick={togglePassword}><img src={HiddenEye} alt=""/></span> : <span className="eye-show" onClick={togglePassword}><img src={Eye} alt=""/></span>}
                            {errors.password && touched.password ? (
                        <span style={{ color: "red" }}>
                          {errors.password}
                        </span>
                      ) : null}
                        </div>
                        <p className="text-end forgot-pwd-link">
                           <Link to='/forgotpassword'> <span className="forgot-password-text">Forgot Password?</span></Link>
                        </p>
                        <div className="button-wrap">
                           <Button type="submit">Login</Button>
                        </div>
                        <p className="already-have-link m-t-15">
                          <span className="forgot-password-text">Already have a corporate account?</span> <Link to='/ssologin'>Login </Link>
                        </p>
                     </Form>
                       )}
                     </Formik>
                     
                  </div>
               </div>
            </div>
         </div>
      </div>
      <ToastContainer/>
      </>
      
   );
}


export default SignIn;
